import React, { useState, useEffect, useRef } from "react";
import {
	Layout,
	message,
	Form,
	Button,
	Switch,
	Row,
	Col,
	notification,
	Checkbox,
	Space,
	Collapse,
	Alert,
	Input,
	Divider,
	Modal,
	Radio,
} from "antd";
import ImgCrop from "antd-img-crop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import {
	SaveOutlined,
	MinusCircleOutlined,
	StarOutlined,
	PlusOutlined,
	EyeOutlined,
	UpOutlined,
	DownOutlined,
	ArrowUpOutlined,
} from "@ant-design/icons";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";

import { Link, useHistory } from "react-router-dom";

import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatTimePicker from "../../../../providers/FloatTimePicker";
import FloatDateTimePicker from "../../../../providers/FloatDateTimePicker";
import FloatAutoComplete from "../../../../providers/FloatAutoComplete";
import FloatInputRate from "../../../../providers/FloatInputRate";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatInputNumber from "../../../../providers/FloatInputNumber";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";

import countryList from "react-select-country-list";
import states from "states-us";
import optionCourseTopic from "../../../../providers/optionCourseTopic";
import timezones from "timezones-list";

import getUserData from "../../../../providers/getUserData";
import CheckSubscription from "../../../../providers/CheckSubscription";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import ModalVenusList from "./Modals/ModalVenusList";
import { TextLoop } from "react-text-loop-next";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";

// import Marquee from "react-fast-marquee";
import moment, { isMoment } from "moment";

export default function PageCreateEvent({ match, permission }) {
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
	const [rsvpDisable, SetRsvpDisable] = useState();
	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		form.resetFields(["state", "zip"]);

		// let venue = JSON.parse(venueSelected);

		if (venueSelectedJSON.length > 0) {
			console.log("handleCountry", val);
			console.log("handleCountry", venueSelectedJSON[0].country);
			if (val === venueSelectedJSON[0].country) {
				form.setFieldsValue({
					state: venueSelectedJSON[0].state,
					zip: venueSelectedJSON[0].zip,
				});
			}
		}
	};

	const { Panel } = Collapse;
	const sub_title = "Create A";
	const userdata = getUserData();
	let country_list = countryList().getData();
	const [state, setState] = useState([]);
	const [timeZone, setTimeZone] = useState([]);
	const [venueTBD, setVenueTBD] = useState(1);
	const [awaiting, setAwaiting] = useState(0);
	const [seatCapacity, setSeatCapacity] = useState(0);
	const [status, setStatus] = useState("Draft");
	const [statusFinal, setStatusFinal] = useState("Draft");
	const history = useHistory();

	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);

		let arrT = [];
		timezones.map((row, key) => {
			arrT.push({
				value: row.tzCode,
				label: row.label,
			});
		});
		setTimeZone(arrT);
	}, []);

	const [venueModalSelected, setVenueModalSelected] = useState();
	function convertToPlain(html) {
		var tempDivElement = document.createElement("div");
		tempDivElement.innerHTML = html;
		return tempDivElement.textContent || tempDivElement.innerText || "";
	}

	const [selectSpeaker, setSelectSpeaker] = useState([]);
	const [selectSpeakerOrig, setSelectSpeakerOrig] = useState([]);
	const { data: dataSpeaker, refetch: refetchSpeaker } = GET(
		"api/v1/user_speaker",
		"user_speaker",
		(res) => {
			if (res.success) {
				console.log(res);
				let arr = [];
				res.data.map((raw, index) => {
					arr.push({
						value: raw.id,
						label: raw.first_name + " " + raw.last_name,
						json: raw,
					});
				});
				setSelectSpeaker(arr);
				setSelectSpeakerOrig(arr);
			}
		}
	);

	useEffect(() => {
		if (venueModalSelected) {
			if (venueModalSelected.country === "United States") {
				setStateLabel("State");
				setZipLabel("Zip Code");
				setOptionState(stateUS);
				setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
			} else if (venueModalSelected.country === "Mexico") {
				setStateLabel("State");
				setZipLabel("Zip Code");
				setOptionState(stateMX);
				setOptionZip(/(^\d{5}$)/);
			} else if (venueModalSelected.country === "Canada") {
				setStateLabel("County");
				setZipLabel("Postal Code");
				setOptionState(stateCA);
				setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
			} else {
				setStateLabel("State");
				setZipLabel("Zip Code");
				setOptionState(stateUS);
				setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
			}

			let direction_details = venueModalSelected.direction_details
				? convertToPlain(venueModalSelected.direction_details)
				: "";
			form.setFieldsValue({
				venue_name: venueModalSelected.venue_name,
				country: venueModalSelected.country,
				address_1: venueModalSelected.address_1,
				address_2: venueModalSelected.address_2,
				city: venueModalSelected.city,
				state: venueModalSelected.state,
				zip: venueModalSelected.zip,
				map_link: venueModalSelected.map_link,
				direction_details: direction_details,
				seat_available: venueModalSelected.capacity,
			});
			setSeatCapacity(venueModalSelected.capacity);
		}
		if (venueTBD === 0) {
			form.resetFields([
				"venue_name",
				"country",
				"address_1",
				"address_2",
				"city",
				"state",
				"zip",
				"map_link",
				"direction_details",
				"seat_available",
			]);
		}
	}, [venueModalSelected]);

	const [fileList, setFileList] = useState([]);

	const onChange = ({ fileList: newFileList }) => {
		var _file = newFileList;
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileList(_file);
		} else {
			setFileList([]);
		}
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const beforeUpload = (file) => {
		const isJpgOrPng =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "image/gif" ||
			file.type === "image/jpg";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG, PNG, GIF, JPEG file!");
			setFileList([]);
			return;
		}
		const isLt2M = file.size / 102400 / 102400 < 10;
		if (!isLt2M) {
			message.error("Image must smaller than 10MB!");
			setFileList([]);
			return;
		}
		return isJpgOrPng && isLt2M;
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		require_false: {
			required: false,
			message: "Required",
		},
		email: {
			type: "email",
			message: "please enter a valid email",
		},
	};

	const [form] = Form.useForm();
	const [venueSelected, setVenueSelected] = useState();
	const [venueSelectedJSON, setVenueSelectedJSON] = useState([]);
	const onChangeAutoComplete = (value, option) => {
		if (value && option != undefined) {
			setVenueSelected(option["data-id"]);
			console.log("venue", option);
			if (option["data-json"]) {
				let venue = JSON.parse(option["data-json"]);
				setVenueSelectedJSON([venue]);
				form.setFieldsValue({
					venue_name: venue.venue_name,
					country: venue.country,
					address_1: venue.address_1,
					address_2: venue.address_2,
					city: venue.city,
					state: venue.state,
					zip: venue.zip,
					map_link: venue.map_link,
					direction_details: convertToPlain(venue.direction_details),
					seat_available: venue.capacity,
				});
				if (venue.country === "United States") {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateUS);
					setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
				} else if (venue.country === "Mexico") {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateMX);
					setOptionZip(/(^\d{5}$)/);
				} else if (venue.country === "Canada") {
					setStateLabel("County");
					setZipLabel("Postal Code");
					setOptionState(stateCA);
					setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
				} else {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateUS);
					setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
				}
				setSeatCapacity(venue.capacity);
			}
		} else {
			setVenueSelected();
			form.resetFields([
				"venue_name",
				"country",
				"address_1",
				"address_2",
				"city",
				"state",
				"zip",
				"map_link",
				"direction_details",
				"seat_available",
			]);
		}
	};

	const [modalFinish, setModalFinish] = useState(false);
	const [modalFinishData, setModalFinishData] = useState([]);
	const handleFinish = (values) => {
		console.log("handleFinish", values);
		const data = new FormData();
		data.append(
			"event",
			JSON.stringify({
				user_id: userdata.id,
				event_type: values.event_type,
				rscp_datetime: isMoment(values.rscp_datetime)
					? values.rscp_datetime.format("YYYY-MM-DD HH:mm")
					: values.rscp_datetime,
				cost: values.cost ? values.cost : 0,
				// event_save_status: values.published_status,
				event_save_status: status,
				venue_tbd: venueTBD === 1 ? 0 : 1,
				time_zone: values.time_zone,
				date_from: isMoment(values.date_from)
					? values.date_from.format("YYYY-MM-DD")
					: moment(values.date_from),
				date_to: isMoment(values.date_to)
					? values.date_to.format("YYYY-MM-DD")
					: moment(values.date_to),
				meeting_details: values.meeting_details,
			})
		);

		let event_time = values.event_time;
		event_time.map((time, key) => {
			event_time[key]["date"] = time.date.format("YYYY-MM-DD");
			event_time[key]["datetime_start"] = `${
				event_time[key]["date"]
			} ${time.datetime_start.format("HH:mm:ss")}`;
			event_time[key]["datetime_end"] = `${
				event_time[key]["date"]
			} ${time.datetime_end.format("HH:mm:ss")}`;
		});
		console.log(event_time);

		data.append(
			"event_schedule",
			JSON.stringify({
				event_time_array: values.event_time ? values.event_time : [],
				// duration: values.ce_credit_hours,
			})
		);
		data.append(
			"event_venue",
			JSON.stringify({
				venue_tbd: venueTBD,
				venue_id: venueSelected ? venueSelected : "",
				venue_name: values.venue_name ? values.venue_name : "",
				country: values.country ? values.country : "",
				address_1: values.address_1 ? values.address_1 : "",
				address_2: values.address_2 ? values.address_2 : "",
				city: values.city ? values.city : "",
				state: values.state ? values.state : "",
				zip: values.zip ? values.zip : "",
				map_link: values.map_link ? values.map_link : "",
				direction_details: values.direction_details
					? values.direction_details
					: "",
				meeting_url: values.meeting_url ? values.meeting_url : "",
			})
		);
		data.append(
			"event_details",
			JSON.stringify({
				title: values.title ? values.title : "",
				venue_tbd: venueTBD,
				sub_title: values.sub_title ? values.sub_title : "",
				director: values.director ? values.director : "",
				facilitator: values.facilitator ? values.facilitator : "",
				summary: values.summary ? values.summary : "",
				description: values.description ? values.description : "",
				restriction: values.restriction ? values.restriction : "",
				what_to_bring: values.what_to_bring ? values.what_to_bring : "",
				ce_credit_hours: values.ce_credit_hours ? values.ce_credit_hours : "",
				seat_available: values.seat_available ? values.seat_available : "",
				completion_code: values.completion_code ? values.completion_code : "",
				subject_code: values.subject_code ? values.subject_code : "",
				course_topic: values.course_topic ? values.course_topic : "",
				allow_waiting_list: awaiting,
				attending_participants: values.attending_participants
					? values.attending_participants
					: "",
				your_company: values.your_company ? values.your_company : "",
				your_phone: values.your_phone ? values.your_phone : "",
				your_email: values.your_email ? values.your_email : "",
				verification: values.verification ? values.verification : "",
				joint_event_provider_1: values.joint_event_provider_1
					? values.joint_event_provider_1
					: "",
				joint_event_provider_2: values.joint_event_provider_2
					? values.joint_event_provider_2
					: "",
				joint_event_provider_3: values.joint_event_provider_3
					? values.joint_event_provider_3
					: "",
			})
		);
		data.append(
			"event_speakers",
			JSON.stringify({
				speaker_list: values.speaker_list ? values.speaker_list : [],
				// speaker_id: venueSelectedSpeaker ? venueSelectedSpeaker : "",
				// speaker_name: values.speaker_name,
				// speaker_credentials: values.speaker_credentials,
				// address_1: values.address_1 ? values.address_1 : "",
				// address_2: values.address_2 ? values.address_2 : "",
				// city: values.city ? values.city : "",
				// state: values.state ? values.state : "",
				// zip: values.zip ? values.zip : "",
				// speaker_list: values.speaker_list ? values.speaker_list : "",
			})
		);
		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append("upload", fileList[0].originFileObj, fileList[0].name);
			}
		}

		// console.log(fileList.length);
		if (fileList.length !== 0) {
			mutateEvent(data, {
				onSuccess: (res) => {
					if (res.success) {
						console.log("mutateEvent", res);
						notification.success({
							message: "Success",
							description: "Successfully Submitted Event",
						});

						setModalFinish(true);
						setModalFinishData({
							id: res.data.id,
							title: res.data.certificate_id,
						});
						form.resetFields();
						setStatusFinal(status);
					} else {
						notification.warning({
							message: "Warning",
							description: "Please create certificate template",
						});
					}
				},
				onError: (err) => {},
			});
		} else {
			notification.error({
				message: "Error",
				description: "Please select Event Image!",
			});
		}
	};

	const { mutate: mutateEvent, isLoading: isLoadingEvent } = POST(
		"api/v1/event",
		"event"
	);

	const editor = useRef(null);
	const [content, setContent] = useState("Start writing");
	const configTextEditor = {
		readonly: false,
		height: 400,
	};

	const handleUpdate = (event) => {
		const editorContent = event.target.innerHTML;
		setContent(editorContent);
	};

	// const [checkBoxAP, setCheckBoxAP] = useState([]);
	const handleCheckbox = (props) => {
		if (props.length > 0) {
			if (props[props.length - 1] == "No Verification Needed") {
				form.setFieldsValue({ verification: ["No Verification Needed"] });
			} else {
				form.setFieldsValue({
					verification: props.filter((p) => p != "No Verification Needed"),
				});
			}
		}
	};

	const [selectVenue, setSelectVenue] = useState([]);
	const { data: dataVenueSelect, isLoading: isLoadingVenueSelect } = GET(
		"api/v1/event_venue_select",
		"event_venue_select",
		(res) => {
			if (res.success) {
				let arr = [];
				console.log("venues", res);
				res.data.map((row, index) => {
					arr.push({
						value: row.id,
						label: row.venue_name,
						json: JSON.stringify(row),
					});
				});
				setSelectVenue(arr);
			}
		}
	);

	const onRemoveSpeaker = (key) => {
		var val = form.getFieldsValue(true);
		var arr = [];
		selectSpeakerOrig.forEach((item) => {
			var x = val["speaker_list"].findIndex((_item) => {
				return _item.speaker_id == item.value;
			});
			if (x === -1) {
				arr.push(item);
			}
		});

		setSelectSpeaker(arr);
	};

	const [venueSelectedSpeaker, setVenueSelectedSpeaker] = useState([]);
	const onChangeAutoCompleteSpeaker = (value, option) => {
		if (value && option != undefined) {
			var data_id = option["data-id"];
			if (option["data-json"]) {
				var val = form.getFieldsValue(true);
				console.log(val);
				val["speaker_list"][option["fieldKey"]]["speaker_credentials"] = option[
					"data-json"
				].credentials
					? option["data-json"].credentials
					: "";
				val["speaker_list"][option["fieldKey"]]["speaker_id"] =
					option["data-json"].id;
				form.setFieldsValue(val);

				var arr = [];
				selectSpeaker.forEach((item) => {
					if (item.value != data_id) {
						arr.push(item);
					}
				});
				setSelectSpeaker(arr);
			}
		} else {
			setSelectSpeaker(selectSpeakerOrig);
		}
	};

	const onChangeForm1 = (change, allval) => {
		if (change[0].name[0] == "date_from" || change[0].name[0] == "date_to") {
			var val = form.getFieldsValue(true);
			// console.log(val);

			var _datefrom = isMoment(val["date_from"])
				? val["date_from"]
				: val["date_from"]
				? moment(val["date_from"])
				: null;
			var _dateto = isMoment(val["date_to"])
				? val["date_to"]
				: val["date_to"]
				? moment(val["date_to"])
				: null;

			if (_datefrom && _dateto) {
				var result = _dateto.diff(_datefrom, "days");

				var arr = [];
				for (let i = 0; i <= result; i++) {
					// console.log("awdwd", val["event_time"][i]);
					let obj = {
						date: moment(_datefrom).add(i, "days"),
						datetime_end: val["event_time"][i]
							? val["event_time"][i]["datetime_end"]
							: null,
						datetime_start: val["event_time"][i]
							? val["event_time"][i]["datetime_start"]
							: null,
					};
					arr.push(obj);
				}
				// console.log(arr);
				form.setFieldsValue({
					...val,
					event_time: arr,
				});
			}
		}
	};

	useEffect(() => {
		form.setFieldsValue({
			event_time: [
				{
					date: null,
					datetime_start: null,
					datetime_end: null,
				},
			],
			speaker_list: [
				{
					speaker_name: null,
					speaker_credentials: null,
				},
			],
		});
	}, []);

	const [venueShow, setVenueShow] = useState(false);

	const handleVenueShow = () => {
		setVenueShow(true);
	};

	const { data: dataUser, isLoading: isLoadingUser } = GET(
		`api/v1/get_by_id?id=${userdata.id}`,
		"get_by_id",
		(res) => {
			if (res.success) {
			}
		}
	);

	const [isFree, setIsFree] = useState(false);
	const {
		mutate: mutatecheckNoBankDetails,
		isLoading: isLoadingcheckNoBankDetails,
	} = POST("api/v1/check_no_bank_details", "check_no_bank_details");

	const [subscription, setSubscription] = useState(true);
	const [subscriptionMessage, setSubscriptionMessage] = useState("");

	const { data: dataPlan, refetch: refetchPlan } = GET(
		`api/v1/account_subscription?user_id=${userdata.id}`,
		"account_subscription",
		(res) => {
			console.log("account_subscription", res);
			if (res.success) {
				if (res.up_to == 0) {
					setSubscription(false);
					mutatecheckNoBankDetails(
						{ user_id: userdata.id },
						{
							onSuccess: (res) => {
								if (res.success) {
									console.log("checkNoBankDetails", res);
									if (res.data) {
										setIsFree(false);
									} else {
										// // disable free;
										setIsFree(true);
										form.setFieldsValue({
											cost: null,
										});
									}
								}
							},
						}
					);
				} else {
					if (res.up_to <= res.event) {
						setSubscription(true);
						setSubscriptionMessage(
							"Your Subscription Plan Exceeds to it's Limit"
						);
					} else {
						setSubscription(false);
						mutatecheckNoBankDetails(
							{ user_id: userdata.id },
							{
								onSuccess: (res) => {
									if (res.success) {
										console.log("checkNoBankDetails", res);
										if (res.data) {
											setIsFree(false);
										} else {
											// // disable free;
											setIsFree(true);
											form.setFieldsValue({
												cost: null,
											});
										}
									}
								},
							}
						);
					}
				}
			} else {
				setSubscription(true);
				setSubscriptionMessage("No Subcriptiption Found");
			}
		}
	);

	const onChangeForm = (change, allval) => {
		if (
			change[0].name[0] == "speaker_list" &&
			change[0].name[2] == "speaker_name"
		) {
			var key = change[0].name[1];
			var val = form.getFieldsValue(true);

			var speaker_name = val["speaker_list"][key]["speaker_name"]
				? val["speaker_list"][key]["speaker_name"]
				: null;

			if (speaker_name) {
				// setSpeakerName(change);
				let name = speaker_name.split(" ");
				mutateGetHours(
					{ first_name: name[0], last_name: name[1] },
					{
						onSuccess: (res) => {
							if (res.success) {
								console.log("onChangeForm", res.data);
								// console.log("key", key);
								// console.log("val", val);
								// console.log("credentials", res.credentials);
								if (res.data.length > 0) {
									val["speaker_list"][key]["speaker_credentials"] =
										res.data[0].credentials;
									val["speaker_list"][key]["speaker_id"] = res.data[0].id;
									form.setFieldsValue(val);
								} else {
									val["speaker_list"][key]["speaker_id"] = 0;
									form.setFieldsValue(val);
								}
							} else {
								val["speaker_list"][key]["speaker_id"] = 0;
								form.setFieldsValue(val);
							}
						},
					}
				);
			}
		}
	};

	const { mutate: mutateGetHours, isLoading: isLoadingGetHours } = POST(
		"api/v1/find_speaker",
		"find_speaker"
	);

	const handleOk = () => {
		setModalFinish(false);
		window.location.href =
			"/event-provider/certificate-template?id=" + modalFinishData.title;
		setModalFinishData([]);
	};

	const handleCancel = () => {
		setModalFinish(false);
		window.location.href =
			"/event-provider/ce-calendar/edit-event/" + modalFinishData.id;
		setModalFinishData([]);
	};

	const [eventType, setEventType] = useState("Live In-Person");
	const handleEventType = (val, opt) => {
		console.log("handleEventType", val);
		setEventType(val);
	};

	const business_cat = [
		{
			label: "Dental Student",
			value: "Dental Student",
		},
		{
			label: "Dental Assistants",
			value: "Dental Assistants",
		},
		{
			label: "Front Office",
			value: "Front Office",
		},
		{
			label: "Office Manager",
			value: "Office Manager",
		},
		{
			label: "Office Coordinator",
			value: "Office Coordinator",
		},
		{
			label: "Dental Practice Owner",
			value: "Dental Practice Owner",
		},
		{
			label: "Retired Dentist",
			value: "Retired Dentist",
		},
		{
			label: "Denturist",
			value: "Denturist",
		},
		{
			label: "Dental Hygienist",
			value: "Dental Hygienist",
		},
		{
			label: "Dental Health Aid Therapist (DHAT)",
			value: "Dental Health Aid Therapist (DHAT)",
		},
		{
			label: "Dental Professor/Instructor",
			value: "Dental Professor/Instructor",
		},
		{
			label: "Dental Lab Techs",
			value: "Dental Lab Techs",
		},
		{
			label: "Dentists",
			value: "Dentists",
		},
		{
			label: "Dental Specialist",
			value: "Dental Specialist",
		},
		{
			label: "Endodontist",
			value: "Endodontist",
		},
		{
			label: "Oral Surgeon",
			value: "Oral Surgeon",
		},
		{
			label: "Periodontist",
			value: "Periodontist",
		},
		{
			label: "Pediatric Dentist",
			value: "Pediatric Dentist",
		},
		{
			label: "Prosthodontist",
			value: "Prosthodontist",
		},
		{
			label: "Dental Anesthesiologist",
			value: "Dental Anesthesiologist",
		},
		{
			label: "Dental Public Health",
			value: "Dental Public Health",
		},
		{
			label: "Oral Maxillofacial Pathologist",
			value: "Oral Maxillofacial Pathologist",
		},
		{
			label: "Oral Maxillofacial Radiologist",
			value: "Oral Maxillofacial Radiologist",
		},
		{
			label: "Oral Medicine",
			value: "Oral Medicine",
		},
		{
			label: "Oral Facial Pain Specialist",
			value: "Oral Facial Pain Specialist",
		},
		{
			label: "Other",
			value: "Other",
		},
	];

	const sortCat = (business_category) => {
		return business_category.sort((a, b) => a.label.localeCompare(b.label));
	};

	const [warningCert, setWarningCert] = useState(false);
	const {} = GET("api/v1/check_certificate", "check_certificate", (res) => {
		if (res.success) {
			setWarningCert(false);
		} else {
			setWarningCert(true);
		}
	});

	const [radioData, setRadioData] = useState(1);
	const [imageCrop, setImageCrop] = useState({
		width: 1,
		height: 1,
	});

	const handleResize = (val) => {
		console.log("val", val.target.value);
		setRadioData(val.target.value);
		if (val.target.value === 1) {
			setImageCrop({
				width: 1,
				height: 1,
			});
		} else if (val.target.value === 2) {
			setImageCrop({
				width: 3.9,
				height: 2.6,
			});
		} else if (val.target.value === 3) {
			setImageCrop({
				width: 1,
				height: 1.5,
			});
		}
	};
	const changeStatus = (val) => {
		setStatus(val);
		// setFormData("val");
		form.submit();
		console.log(val);
	};

	const handleDateFrom = (e) => {
		SetRsvpDisable(e);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageCreateEvent"
		>
			<CheckSubscription user_id={userdata.id} />
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<StarOutlined />}
			/>
			<br></br>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={handleFinish}
					onFieldsChange={(changedValues, allValues) =>
						onChangeForm1(changedValues, allValues)
					}
					initialValues={{
						event_type: "Live In-Person",
						published_status: "Draft",
						your_email: userdata.email,
					}}
				>
					{subscriptionMessage != "" && (
						<Alert
							message={
								<>
									<div className="textLoopDiv">
										{subscriptionMessage + ", "}{" "}
										<Link to="/event-provider/my-account/invoices/subscription/upgrade">
											Upgrade Subscription
										</Link>
									</div>{" "}
								</>
							}
							type="error"
							showIcon
						/>
					)}
					{isFree && (
						<Alert
							message={
								<>
									<div className="textLoopDiv">
										All created events will be Free , to enable events cost
										Please fill up your{" "}
										<Link to="/event-provider/my-account/profile/bank-details">
											{" "}
											Bank details
										</Link>
									</div>{" "}
								</>
							}
							type="warning"
							showIcon
						/>
					)}
					<br />
					<Row gutter={24}>
						<Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 1 - Event Type"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="event_type"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="Select Live In-Person or Virtual Event"
													placeholder="Select Live In-Person or Virtual Event"
													options={[
														{
															label: "Live In-Person",
															value: "Live In-Person",
														},
														{
															label: "Virtual Event",
															value: "Virtual Event",
														},
													]}
													onChange={handleEventType}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="cost"
												rules={[
													isFree ? validator.require_false : validator.require,
												]}
												hasFeedback
											>
												<FloatInputRate
													label="Event Cost"
													placeholder="Event Cost"
													disabled={isFree}
												/>
											</Form.Item>
										</Col>
									</Row>
									{eventType === "Virtual Event" && (
										<Row gutter={24}>
											<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<Form.Item name="meeting_details" hasFeedback>
													<FloatTextArea
														label="Meeting Details"
														placeholder="Meeting Details"
													/>
												</Form.Item>
												<span
													className="virtualMettingText"
													style={{
														marginTop: "-24px",
														position: "absolute",
													}}
												>
													Virtual meeting details you provide here, will only be
													visible to registered attendees
												</span>
											</Col>
										</Row>
									)}
								</Panel>
							</Collapse>

							<br />
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 2 - Event Date & Time"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="time_zone"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="TimeZone"
													placeholder="TimeZone"
													options={[
														{
															label: "Eastern Standard Time (EST)",
															value: "Eastern Standard Time (EST)",
														},
														{
															label: "Central Standard Time (CST)",
															value: "Central Standard Time (CST)",
														},
														{
															label: "Mountian Standard Time (MST)",
															value: "Mountian Standard Time (MST)",
														},
														{
															label: "Pacific Standard Time (PST)",
															value: "Pacific Standard Time (PST)",
														},
														{
															label: "Hawaii Standard Time (HST)",
															value: "Hawaii Standard Time (HST)",
														},
													]}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="date_from"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatDatePickerForm
													label="Date From"
													placeholder="Date From"
													onChange={handleDateFrom}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="date_to"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatDatePickerForm
													label="Date To"
													placeholder="Date To"
													disabledPast={rsvpDisable}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.List name="event_time">
												{(fields, { add, remove }) => (
													<>
														{fields.map(({ key, name, ...restField }) => (
															<Row key={key} gutter={24}>
																<Col
																	xs={24}
																	sm={24}
																	md={24}
																	lg={24}
																	xl={8}
																	xxl={8}
																>
																	<Form.Item
																		{...restField}
																		name={[name, "date"]}
																		rules={[validator.require]}
																		hasFeedback
																	>
																		<FloatDatePicker
																			label={"Date"}
																			placeholder="Date"
																			mode
																			disable={true}
																		/>
																	</Form.Item>
																</Col>
																<Col
																	xs={24}
																	sm={24}
																	md={10}
																	lg={10}
																	xl={7}
																	xxl={7}
																>
																	<Form.Item
																		{...restField}
																		name={[name, "datetime_start"]}
																		rules={[validator.require]}
																		hasFeedback
																	>
																		<FloatTimePicker
																			label={"Start Time"}
																			placeholder="Start Time"
																			mode
																		/>
																	</Form.Item>
																</Col>
																<Col
																	xs={24}
																	sm={24}
																	md={10}
																	lg={10}
																	xl={7}
																	xxl={7}
																>
																	<Form.Item
																		{...restField}
																		name={[name, "datetime_end"]}
																		rules={[validator.require]}
																		hasFeedback
																	>
																		<FloatTimePicker
																			label="End Time"
																			placeholder="End Time"
																			mode
																		/>
																	</Form.Item>
																</Col>

																{key != 0 && (
																	<Col
																		className="gutter-row"
																		xs={2}
																		sm={2}
																		md={2}
																		lg={2}
																		xl={2}
																		xxl={2}
																	>
																		<MinusCircleOutlined
																			style={{ marginTop: "15px" }}
																			// className="btn-danger-outline"
																			onClick={() => {
																				remove(name);

																				var val = form.getFieldsValue(true);
																				form.setFieldsValue({
																					...val,
																					date_to: moment(
																						val["date_to"]
																					).subtract(1, "days"),
																				});
																			}}
																		/>
																		{/* <Button
                                    icon={<MinusCircleOutlined />}
                                    className="btn-danger-outline"
                                    size="large"
                                    style={{
                                      marginBottom: "15px",
                                    }}
                                    onClick={() => {
                                      remove(name);

                                      var val = form.getFieldsValue(true);
                                      form.setFieldsValue({
                                        ...val,
                                        date_to: moment(
                                          val["date_to"]
                                        ).subtract(1, "days"),
                                      });
                                    }}
                                  ></Button> */}
																	</Col>
																)}
															</Row>
														))}
													</>
												)}
											</Form.List>
										</Col>
									</Row>
									<Divider></Divider>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="rscp_datetime"
												// rules={[validator.require]}
												hasFeedback
											>
												<FloatDateTimePicker
													label="RSVP by Date & Time"
													placeholder="RSVP by Date & Time"
													format="YYYY-MM-DD HH:mm"
													mode
													disabledDate={rsvpDisable}
												/>
											</Form.Item>
											<span
												style={{
													marginTop: "-7px",
													position: "absolute",
													lineHeight: "1.3",
												}}
											>
												Registration is no longer possible after selected RSVP
												date and time
											</span>
											<br />
											<br />
										</Col>
									</Row>
								</Panel>
							</Collapse>
							<br />

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								{eventType === "Live In-Person" ? (
									<Panel
										header="STEP 3 - Event Location"
										key="1"
										className="accordion bg-darkgray-form"
									>
										<Row gutter={24}>
											<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
												<Form.Item
													name="venue_name"
													rules={[
														{
															required: venueTBD === 0 ? false : true,
															message: "Required",
														},
													]}
													hasFeedback={venueTBD === 0 ? false : true}
												>
													<FloatAutoComplete
														label="Venue Name"
														placeholder="Venue Name"
														options={selectVenue}
														onChange={onChangeAutoComplete}
														disabled={venueTBD === 1 ? false : true}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
												<Form.Item
													name="country"
													rules={[
														{
															required: venueTBD === 0 ? false : true,
															message: "Required",
														},
													]}
													hasFeedback={venueTBD === 0 ? false : true}
													className="form-select-error"
												>
													<FloatSelect
														label="Country"
														placeholder="Country"
														options={country}
														disabled={venueTBD === 1 ? false : true}
														onChange={handleCountry}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<Form.Item
													name="address_1"
													rules={[
														{
															required: venueTBD === 0 ? false : true,
															message: "Required",
														},
													]}
													hasFeedback={venueTBD === 0 ? false : true}
												>
													<FloatInput
														label="Address 1"
														placeholder="Address 1"
														disabled={venueTBD === 1 ? false : true}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<Form.Item
													name="address_2"
													hasFeedback={venueTBD === 0 ? false : true}
												>
													<FloatInput
														label="Address 2"
														placeholder="Address 2"
														disabled={venueTBD === 1 ? false : true}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
												<Form.Item
													name="city"
													rules={[validator.require]}
													hasFeedback={venueTBD === 0 ? false : true}
												>
													<FloatInput label="City" placeholder="City" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
												<Form.Item
													name="state"
													rules={[
														{
															required: venueTBD === 0 ? false : true,
															message: "Required",
														},
													]}
													hasFeedback={venueTBD === 0 ? false : true}
													className="form-select-error"
												>
													<FloatSelect
														label={stateLabel}
														placeholder={stateLabel}
														options={optionState}
														disabled={venueTBD === 1 ? false : true}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
												<Form.Item
													name="zip"
													rules={[
														{
															required: venueTBD === 0 ? false : true,
															message: "Required",
														},
														{
															pattern: optionZip,
															message: "Invalid " + zipLabel,
														},
													]}
													hasFeedback={venueTBD === 0 ? false : true}
												>
													<FloatInput
														label={zipLabel}
														placeholder={zipLabel}
														disabled={venueTBD === 1 ? false : true}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<Form.Item
													name="map_link"
													rules={[
														{
															required: venueTBD === 0 ? false : true,
															message: "Required",
														},
													]}
													hasFeedback={venueTBD === 0 ? false : true}
												>
													<FloatInput
														label="Map Link"
														placeholder="Map Link"
														disabled={venueTBD === 1 ? false : true}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<Form.Item
													name="direction_details"
													rules={[
														{
															required: venueTBD === 0 ? false : true,
															message: "Required",
														},
													]}
												>
													<FloatTextArea
														label="Direction Details"
														placeholder="Direction Details"
														disabled={venueTBD === 1 ? false : true}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={24}>
											{venueTBD === 1 && (
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
													<span>
														Need a venue ?{" "}
														<Link to="#" onClick={handleVenueShow}>
															{" "}
															<FontAwesomeIcon icon={faLocationArrow} /> View
															All Venue Providers
														</Link>
													</span>
												</Col>
											)}
											<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
												<Form.Item
													name="venue_tbd"
													rules={[validator.require_false]}
												>
													<Switch
														onClick={(e) => {
															setVenueTBD(e ? 0 : 1);
															setVenueModalSelected([]);
														}}
													/>{" "}
													<span>Venue TBD</span>
													<br />
													<span>No Venue yet chosen?</span>
												</Form.Item>
											</Col>
											{/* <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        
                          <Button
                            type="primary"
                            className="btn-login-outline"
                            onClick={handleVenueShow}
                          >
                            View Venue's
                          </Button>
                      </Col> */}
										</Row>
										<br />
									</Panel>
								) : (
									<Panel
										header="STEP 3 - Event Location"
										key="1"
										className="accordion bg-darkgray-form"
									>
										<Form.Item
											name="meeting_url"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatTextArea
												label="Meeting URL"
												placeholder="Meeting URL"
											/>
										</Form.Item>
									</Panel>
								)}
							</Collapse>
							<br />

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 4 - Event Details"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="title"
												rules={[validator.require]}
												hasFeedback={venueTBD === 0 ? false : true}
											>
												<FloatInput label="Title" placeholder="Title" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="sub_title"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Sub Title / Topic"
													placeholder="Sub Title / Topic"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.List name="speaker_list">
												{(fields, { add, remove }) => (
													<>
														{fields.map(({ key, name, ...restField }) => (
															<Row key={key} gutter={24}>
																<Col
																	xs={24}
																	sm={24}
																	md={12}
																	lg={12}
																	xl={12}
																	xxl={12}
																>
																	<Form.Item
																		{...restField}
																		name={[name, "speaker_name"]}
																		rules={[validator.require]}
																		hasFeedback
																	>
																		<FloatAutoComplete
																			label="Speaker"
																			placeholder="Speaker"
																			options={selectSpeaker}
																			onChange={onChangeAutoCompleteSpeaker}
																			fieldKey={name}
																		/>
																	</Form.Item>
																	<Form.Item
																		{...restField}
																		name={[name, "speaker_id"]}
																		hasFeedback
																		style={{ display: "none" }}
																	>
																		<Input />
																	</Form.Item>
																</Col>
																<Col
																	xs={24}
																	sm={24}
																	md={restField.fieldKey !== 0 ? 12 : 12}
																	lg={restField.fieldKey !== 0 ? 10 : 12}
																	xl={restField.fieldKey !== 0 ? 10 : 12}
																	xxl={restField.fieldKey !== 0 ? 10 : 12}
																>
																	<Form.Item
																		{...restField}
																		name={[name, "speaker_credentials"]}
																		rules={[validator.require]}
																		hasFeedback
																	>
																		<FloatInput
																			label="Speaker Credentials"
																			placeholder="Speaker Credentials"
																		/>
																	</Form.Item>
																</Col>
																{restField.fieldKey !== 0 && (
																	<Col
																		className="gutter-row"
																		xs={24}
																		sm={24}
																		md={2}
																		lg={2}
																		xl={2}
																		xxl={2}
																	>
																		<MinusCircleOutlined
																			className="remove-btn-icon"
																			onClick={() => {
																				remove(name);
																				onRemoveSpeaker(key);
																			}}
																		/>
																	</Col>
																)}
															</Row>
														))}
														<Row gutter={24}>
															<Col
																className="gutter-row"
																xs={24}
																sm={24}
																md={11}
																lg={11}
																xl={11}
																xxl={11}
															>
																<Form.Item>
																	<Button
																		className="btn-login-outline"
																		onClick={() => add()}
																		block
																		style={{ width: "100%" }}
																		icon={<PlusOutlined />}
																	>
																		ADD SPEAKER
																	</Button>
																</Form.Item>
															</Col>
														</Row>
													</>
												)}
											</Form.List>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="director"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput label="Director" placeholder="Director" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="facilitator"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Facilitator"
													placeholder="Facilitator"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="summary"
												rules={[validator.require_false]}
											>
												<FloatTextArea label="Summary" placeholder="Summary" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="description"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Description"
													placeholder="Description"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="restriction"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Restriction (Provider details, if applicable)"
													placeholder="Restriction (Provider details, if applicable)"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="what_to_bring"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="What to Bring"
													placeholder="What to Bring"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="ce_credit_hours"
												rules={[validator.require]}
											>
												<FloatInputNumber
													label="CE Credit Hours"
													placeholder="CE Credit Hours"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="seat_available"
												rules={[
													{
														required: true,
														message: "Required",
													},
												]}
											>
												<FloatInputNumber
													label="Seats Avaible"
													placeholder="Seats Avaible"
													limit={seatCapacity}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="completion_code"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Completion Code"
													placeholder="Completion Code"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="subject_code"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Subject Code"
													placeholder="Subject Code"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="course_topic"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="Course Topic"
													placeholder="Course Topic"
													options={optionCourseTopic}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="allow_waiting_list"
												rules={[validator.require_false]}
											>
												<Switch
													// defaultChecked
													onChange={(e) => setAwaiting(e ? 1 : 0)}
												/>{" "}
												<span>Allow Waiting List</span>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="attending_participants"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error-multi"
											>
												<FloatSelect
													label="Attending Participants"
													placeholder="Attending Participants"
													options={sortCat(business_cat)}
													multi="multiple"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="your_company"
												rules={[validator.require_false]}
												className="form-select-error"
												hasFeedback
											>
												<FloatSelect
													label={
														dataUser && dataUser.data[0].member_company
															? dataUser.data[0].member_company.business_name
															: "Your Company"
													}
													placeholder={
														dataUser && dataUser.data[0].member_company
															? dataUser.data[0].member_company.business_name
															: "Your Company"
													}
													// placeholder="Big Smiles of AZ"
													// label="Your Company"
													// placeholder="Your Company"
													disabled={true}
													options={[
														{
															label: "Big Smiles of AZ",
															value: "Big Smiles of AZ",
														},
													]}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="your_phone"
												rules={[validator.require]}
												className="no-feed-back"
												hasFeedback
											>
												<FloatInputMask
													label="Your Phone"
													placeholder="Your Phone"
													maskLabel="your_phone"
													maskType="999-999-9999"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="your_email"
												rules={[validator.require, validator.email]}
												hasFeedback
											>
												<FloatInput
													label="Your Email"
													placeholder="Your Email"
												/>
											</Form.Item>
										</Col>
									</Row>
								</Panel>
							</Collapse>
							<br />

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 5 - Select the Appropriate Organization's Verification"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<span>
												These are your company's approved verifications which
												will appear on your attendees certificates. Be sure to
												upload these approved verifications on your{" "}
												<Link
													to={"/event-provider/certificate-template"}
													target={"_blank"}
												>
													certificate template page
												</Link>
												.
											</span>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												// name="ada_cerp_verification"
												name="verification"
												rules={[validator.require]}
												// hasFeedback
											>
												<Checkbox.Group
													onChange={handleCheckbox}
													style={{
														width: "100%",
														display: "flex",
														flexDirection: "column",
													}}
												>
													<Row gutter={24}>
														<Col
															className="gutter-row"
															xs={24}
															sm={24}
															md={12}
															lg={12}
															xl={12}
															xxl={12}
														>
															<Checkbox
																style={{ marginLeft: "0px" }}
																value="ADA CERP Verification"
															>
																ADA CERP Verification
															</Checkbox>
															<br />
															<br />
															<Checkbox
																style={{ marginLeft: "0px" }}
																value="ADA PACE Verification"
															>
																AGD PACE Verification
															</Checkbox>
															<br />
															<br />
															<Checkbox
																style={{ marginLeft: "0px" }}
																value="No Verification Needed"
															>
																No Verification Needed
															</Checkbox>
															<br />
															<br />
															{/* </Checkbox.Group> */}
														</Col>
													</Row>
												</Checkbox.Group>
											</Form.Item>
										</Col>
									</Row>
								</Panel>
							</Collapse>
							<br />
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 6 - Event Image"
									key="1"
									className="accordion bg-darkgray-form"
									ellipsis
								>
									<label className="font-red">
										<b>
											Photo upload & Cropping: Select your image orientation
										</b>
									</label>
									<br />
									<Radio.Group onChange={handleResize} value={radioData}>
										<Radio value={1}>Square</Radio>
										<Radio value={2}>Rectangle</Radio>
										<Radio value={3}>Portrait</Radio>
									</Radio.Group>
									<Divider />
									<div>
										<ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
											<Upload
												listType="picture-card"
												fileList={fileList}
												maxCount={1}
												style={{ width: "200px" }}
												action={false}
												customRequest={false}
												onChange={onChange}
												onPreview={onPreview}
												beforeUpload={beforeUpload}
											>
												{fileList.length < 1 && "+ Upload"}
											</Upload>
										</ImgCrop>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<span>One file Only. 10 MB limit.</span>
										<span>
											Your selected profile photo will be visible to all event
											organizers and event attendees. <br></br>
										</span>
									</div>
									<div className="c-secondary" style={{ fontSize: "12px" }}>
										Allowed types: png gif jpg jpeg.
									</div>
								</Panel>
							</Collapse>
							<br />

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 7 - Add Joint Providers (Optional)"
									key="1"
									className="accordion bg-darkgray-form"
									ellipsis
								>
									<Row gutter={24}>
										<Col
											className="gutter-row"
											xs={24}
											sm={24}
											lg={24}
											xl={24}
											xxl={24}
										>
											<Form.Item
												name="joint_event_provider_1"
												rules={[validator.require_false]}
												hasFeedback
											>
												<FloatInput
													label="Joint Event Provider 1"
													placeholder="Joint Event Provider 1"
												/>
											</Form.Item>
										</Col>
										<Col
											className="gutter-row"
											xs={24}
											sm={24}
											lg={24}
											xl={24}
											xxl={24}
										>
											<Form.Item
												name="joint_event_provider_2"
												rules={[validator.require_false]}
												hasFeedback
											>
												<FloatInput
													label="Joint Event Provider 2"
													placeholder="Joint Event Provider 2"
												/>
											</Form.Item>
										</Col>
										<Col
											className="gutter-row"
											xs={24}
											sm={24}
											lg={24}
											xl={24}
											xxl={24}
										>
											<Form.Item
												name="joint_event_provider_3"
												rules={[validator.require_false]}
												hasFeedback
											>
												<FloatInput
													label="Joint Event Provider 3"
													placeholder="Joint Event Provider 3"
												/>
											</Form.Item>
										</Col>
									</Row>
								</Panel>
							</Collapse>
							<br />

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 8 - Published Status"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={8}>
										<Col md={24} lg={12} style={{ textAlign: "center" }}>
											{" "}
											<b>Current Status:</b>
										</Col>
										<Col md={24} lg={12} style={{ textAlign: "center" }}>
											<b style={{ textTransform: "uppercase" }}>
												{statusFinal}
											</b>
										</Col>
									</Row>
									<Row gutter={8} style={{ marginTop: 10 }}>
										<Col md={24} lg={12}>
											{" "}
											<b>Preview:</b> See how it will appear on the website.
										</Col>
										<Col md={24} lg={12}>
											<Button
												disabled
												size="large"
												className="btn-loginNew-outline"
												icon={<EyeOutlined />}
												// onClick={() => setPreviewShow(true)}
												style={{ width: "100%" }}
											>
												Preview
											</Button>
										</Col>
									</Row>
									<Row
										gutter={8}
										style={{
											marginTop: 10,
										}}
									>
										<Col md={24} lg={12}>
											<b>Draft</b>: Will not appear on website until published
											and save.
										</Col>
										<Col md={24} lg={12}>
											<Button
												disabled={subscription}
												size="large"
												className="btn-login-outline"
												icon={<SaveOutlined />}
												onClick={() => changeStatus("Draft")}
												style={{ width: "100%" }}
											>
												Draft
											</Button>
										</Col>
									</Row>
									<Row gutter={8} style={{ marginTop: 10 }}>
										<Col md={24} lg={12}>
											<b>Published</b>: Will appear on website once saved. Once
											this event is published draft mode will no longer be
											available
										</Col>
										<Col md={24} lg={12}>
											<Button
												disabled={subscription}
												size="large"
												className="btn-success-outline"
												icon={<ArrowUpOutlined />}
												onClick={() => changeStatus("Published")}
												style={{ width: "100%" }}
											>
												Published
											</Button>
										</Col>
									</Row>

									{/* <Panel
                  header="STEP 8 - Published Status"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
                      <span>
                        Current state <b>{status}</b>
                      </span>
                      <br />
                      <br />
                    </Col>
                    <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        name="published_status"
                        rules={[validator.require]}
                        hasFeedback
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Change to"
                          placeholder="Change to"
                          options={[
                            {
                              label: "Draft",
                              value: "Draft",
                            },
                            {
                              label: "Published",
                              value: "Published",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                    >
                      <span>
                        <b>Draft</b>: will not appear on website until published
                        and save.
                      </span>
                      <br />
                      <span>
                        <b>Published</b>: will appear on website once saved.
                      </span>
                      <br />
                      <span>
                        Once this event is <b>published</b>: draft mode will now
                        longer be available.
                      </span>
                      <br />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      style={{
                        fontSize: "12px",
                        marginTop: "10px",
                      }}
                    >
                      
                      <Button
                        size="large"
                        className="btn-login-outline"
                        icon={<SaveOutlined />}
                        htmlType="submit"
                        loading={isLoadingEvent}
                        disabled={subscription}
                      >
                        Submit
                      </Button>
                    
                    </Col>
                  </Row>
                </Panel> */}
								</Panel>
							</Collapse>
							<br />
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="STEP 9 - Certificate Template Selection"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
											<span>
												Next be sure to visit the{" "}
												<Link
													to="/event-provider/certificate-template"
													target={"_blank"}
												>
													Certificate Template
												</Link>{" "}
												page to set up your course verification template. This
												is the certificate all attendees of your event will
												receive upon event completion.
											</span>
										</Col>
									</Row>
								</Panel>
							</Collapse>
							<br />
						</Col>
					</Row>
				</Form>

				<Modal
					title="Successfully Created Event"
					className="modal-login"
					visible={modalFinish}
					onCancel={handleCancel}
					footer={[
						<Space>
							<Button onClick={handleCancel}> Update Later</Button>
							<Button className="btn-login-outline" onClick={handleOk}>
								Yes
							</Button>
						</Space>,
					]}
				>
					<p style={{ textAlign: "center" }}>
						Do you want to update your certificate on this event ?
					</p>
				</Modal>

				<Modal
					title="Warning"
					className="modal-warning"
					// visible={modalFinish}
					visible={warningCert}
					// onCancel={handleCancel}
					closable={false}
					footer={[
						<Space>
							<Button onClick={() => history.goBack()}>Close</Button>
							<Button
								className="btn-warning-outline"
								onClick={() =>
									history.push("/event-provider/certificate-template")
								}
							>
								Setup
							</Button>
						</Space>,
					]}
				>
					<p style={{ textAlign: "center" }}>
						Be sure to setup the certificate default template first
					</p>
				</Modal>

				<ModalVenusList
					venueShow={venueShow}
					setVenueShow={setVenueShow}
					setVenueModalSelected={setVenueModalSelected}
				/>
			</Layout.Content>
		</Layout>
	);
}
