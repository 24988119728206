import React, { useEffect, useState } from "react";

import {
	Layout,
	Card,
	Form,
	Input,
	Button,
	Row,
	Col,
	Image,
	Divider,
	Collapse,
	Select,
	notification,
	Checkbox,
	Alert,
} from "antd";
import { FormOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
// import { PasswordInput } from "antd-password-input-strength";
import moment from "moment";
import { useHistory } from "react-router-dom";
import imageLogo from "../../../assets/img/CELIYA-Logo-Design.png";
import FloatInput from "../../../providers/FloatInput";
import FloatSelect from "../../../providers/FloatSelect";
import FloatInputPasswordStrength from "../../../providers/FloatInputPasswordStrength";

import states from "states-us";
import ComponentStepThree from "./ComponentStepThree/ComponentStepThree";
import ComponentStepFour from "./ComponentStepFour/ComponentStepFour";

import { GET, POST } from "../../../providers/useAxiosQuery";
import ReCAPTCHA from "react-google-recaptcha";
import FloatSelectWithDangerouslySetInnerHTML from "../../../providers/FloatSelectWithDangerouslySetInnerHTML";

import optionCountryCodes from "../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../providers/optionStateCodesCanada";

export default function PageLogin() {
	let history = useHistory();
	// const queryString = window.location.search;
	// const urlParams = new URLSearchParams(queryString);
	// const [errorMessage, setErrorMessage] = useState();

	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		form.resetFields(["state", "zip"]);
	};

	const [form] = Form.useForm();
	const { Panel } = Collapse;
	const [selectedPlan, setSelectedPlan] = useState();

	const [accountType, setAccountType] = useState("");
	const [policy, setPolicy] = useState("");
	const [collapse, setCollapse] = useState(["1", "2", "3", "4"]);
	const halderAccountType = (value, option) => {
		if (value && option != undefined) {
			// console.log("halderAccountType", option["data-id"]);
			setAccountType(option["data-id"]);
			setPolicy(option["data-policy"]);
			setCollapse(["2", "3", "4"]);
		} else {
			setAccountType("");
			setPolicy("");
			setCollapse(["1", "2", "3", "4"]);
		}
	};

	const [showStepThree, setShowStepThree] = useState(false);
	const [dataStepTwo, setDataStepTwo] = useState();

	const { mutate: mutateRegisterFree, isLoading: isLoadingRegisterFree } = POST(
		"api/v1/register_free",
		"register_free"
	);

	const { mutate: mutateLogin, isLoading: isLoadingButtonLogin } = POST(
		"api/v1/login",
		"login"
	);

	const [captchaToken, setCaptchaToken] = useState("");
	const [captchaTokenError, setCaptchaTokenError] = useState(false);
	const [emailConfirmAlert, setEmailConfirmAlert] = useState(false);
	const onFinishStepTwo = (values) => {
		if (freeReg == 0) {
			setDataStepTwo(values);
			setShowStepThree(true);
			setCollapse(["3", "4"]);
		} else {
			console.log(values);
			if (!captchaToken) {
				setCaptchaTokenError(true);
			} else {
				setCaptchaTokenError(false);
				mutateRegisterFree(
					{
						...values,
						account_type: accountType,
						link_origin: window.location.origin,
					},
					{
						onSuccess: (res) => {
							if (res.success) {
								setEmailConfirmAlert(true);
								setBtnDisabled(true);
								form.resetFields();
							} else {
								notification.error({
									message: "Error",
									description: res.message,
								});
							}
						},
						onError: (err) => {
							console.log(err.response.data);
							// notification.error({
							//   message: "Error",
							//   description: err.response.data.message,
							// });
							notification.error({
								message: "Error",
								description: "Please Contact Support",
							});
						},
					}
				);
			}
		}
	};

	const [showStepFour, setShowStepFour] = useState(false);
	const [dataStepThree, setDataStepThree] = useState();

	const [dataStepFour, setDataStepFour] = useState();

	function callback(key) {
		setCollapse(key);
	}

	// useEffect(() => {
	//     console.log('accountType', accountType)
	//     console.log('dataStepTwo', dataStepTwo)
	//     console.log('dataStepThree', dataStepThree)
	//     console.log('dataStepFour', dataStepFour)
	// }, [collapse])

	// console.log('states', states)

	const [memberPlan, setMemberPlan] = useState([]);
	const [eventPlan, setEventPlan] = useState([]);
	const [venuePlan, setVenuetPlan] = useState([]);
	const [speakerPlan, setSpeakerPlan] = useState([]);
	const [eventspeakerPlan, setEventSpeakerPlan] = useState([]);
	const { data: dataGetPlans } = GET("api/v1/at_plan", "at_plan", (res) => {
		if (res.success) {
			let member = [];
			let event = [];
			let venue = [];
			let speaker = [];
			let eventspeaker = [];
			console.log("dataGetPlans", res.data);
			res.data.map((row, key) => {
				if (row.account_type_id == 1) {
					member.push({
						label: row.description,
						value: row.id,
						price: row.amount,
						plan: row.plan,
						type: row.type,
					});
				} else if (row.account_type_id == 2) {
					event.push({
						label: row.description,
						value: row.id,
						price: row.amount,
						plan: row.plan,
						type: row.type,
					});
				} else if (row.account_type_id == 3) {
					venue.push({
						label: row.description,
						value: row.id,
						price: row.amount,
						plan: row.plan,
						type: row.type,
					});
				} else if (row.account_type_id == 4) {
					speaker.push({
						label: row.description,
						value: row.id,
						price: row.amount,
						plan: row.plan,
						type: row.type,
					});
				} else if (row.account_type_id == 5) {
					eventspeaker.push({
						label: row.description,
						value: row.id,
						price: row.amount,
						plan: row.plan,
						type: row.type,
					});
				}

				setMemberPlan(member);
				setEventPlan(event);
				setVenuetPlan(venue);
				setSpeakerPlan(speaker);
				setEventSpeakerPlan(eventspeaker);
			});
		}
	});

	function convertToPlain(html) {
		var tempDivElement = document.createElement("div");
		tempDivElement.innerHTML = html;
		return tempDivElement.textContent || tempDivElement.innerText || "";
	}

	const [memberPlanOptions, setMemberPlanOptions] = useState([]);
	const { data: dataAccountType, isLoading: isLoadingAccountType } = GET(
		"api/v1/at",
		"at",
		(res) => {
			if (res.success) {
				console.log("qasdwe", res.data);
				let arr = [];
				res.data.map((row, index) => {
					arr.push({
						label: row.description,
						value: row.account_type,
						policy: row.privacy && row.privacy.privacy_policy,
					});

					return "";
				});

				console.log("dataAccountType", arr);
				setMemberPlanOptions(arr);
			}
		}
	);

	const [freeReg, setFreeReg] = useState(0);
	const {
		data: dataRegistrationConfig,
		isLoading: isLoadingRegistrationConfig,
	} = GET("api/v1/registration_config", "registration_config", (res) => {
		if (res.success) {
			console.log("registration_config", res);
			setFreeReg(res.data.free_registration);
		}
	});

	const handleBack = () => {
		history.push("/");
	};

	const [accept, setAccept] = useState(false);
	const onChange = (e) => {
		setAccept(e.target.checked);
	};

	const [scroll, setScroll] = useState(false);
	const handleScroll = (e) => {
		// console.log("values");
		let element = e.target;
		if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
			setScroll(true);
		} else {
			setScroll(false);
		}
	};

	const [yesDisabled, setYesDisabled] = useState(true);
	const [btnDisabled, setBtnDisabled] = useState(true);

	useEffect(() => {
		// if (scroll === true && accept === true) {
		if (scroll === true) {
			setYesDisabled(false);
		} else {
			setYesDisabled(true);
		}
	}, [scroll]);

	useEffect(() => {
		// if (scroll === true && accept === true) {
		if (accept === true) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [scroll, accept]);

	const { data: dataGetMaintenance } = GET(
		"api/v1/maintenance",
		"maintenance",
		(res) => {
			if (res.success == true) {
				if (res.data.system_maintenance == 1) {
					history.push("/maintenance");
				}
			}
		}
	);

	return (
		<Layout.Content
			className="login-layout login-back"
			// style={{
			//   paddingBottom: "10vh",
			//   background: "linear-gradient(180deg, white 0%, #e2c991 80%)",
			// }}
		>
			<Row>
				<Col xs={24} sm={4} md={8}></Col>
				<Col
					xs={24}
					sm={16}
					md={8}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<div style={{ padding: 30 }}>
						<Image
							onClick={handleBack}
							src={imageLogo}
							preview={false}
							style={{ width: "100%" }}
						/>
					</div>
				</Col>
			</Row>
			{/* <br />
      <br /> */}
			<Row className="regMob">
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
				<Col xs={24} sm={16} md={16} lg={16} xl={10} xxl={8}>
					{/* title={
              <Image
                onClick={handleBack}
                style={{ cursor: "pointer" }}
                src={imageLogo}
                preview={false}
              />
            } */}
					<Card
						style={{
							// background: "transparent",
							border: "0px solid",
							textAlign: "center",
							height: "auto",
							borderRadius: "10px",
						}}
						headStyle={{
							borderBottom: "none",
							background: "transparent!important",
						}}
						className="login"
					>
						<Row className="flexdirection">
							<Col xs={24} md={24}>
								<br />
								<div
									style={{
										display: "flex",
										flexDirection: "row",
									}}
								>
									<div
										size="large"
										shape="circle"
										type="link"
										className="editIcon"
									>
										<FormOutlined />
									</div>
									<div
										style={{
											textAlign: "left",
											lineHeight: "1.3",
											paddingTop: "7px",
										}}
									>
										<span style={{ fontSize: "16px" }}>New Member</span>
										<br />
										<span className="newMemberTitle">Registration</span>
										<br />
									</div>
								</div>
								<Divider style={{ background: "#293a56", height: "2px" }} />

								<Collapse
									defaultActiveKey={collapse}
									// defaultActiveKey={['1', '2', '3', '4']}
									activeKey={collapse}
									expandIconPosition="right"
									expandIcon={({ isActive }) =>
										isActive ? <MinusOutlined /> : <PlusOutlined />
									}
									className="login-collapse registration-collapse-header"
									onChange={callback}
								>
									<Panel
										header={<span style={{ fontSize: "26px" }}>Step 1</span>}
										key="1"
									>
										{/* <div>
                      Select Your CE Account Type{" "}
                      <span style={{ color: "red" }}>*</span>
                    </div> */}
										{/* <br /> */}
										<div style={{ marginBottom: 10 }}></div>
										<Form
											name="basic"
											layout="vertical"
											className="login-form"
											//   form={form}
											autoComplete="off"
										>
											<Form.Item name="role" hasFeedback>
												<FloatSelectWithDangerouslySetInnerHTML
													label="Select Your CE Account Type"
													placeholder="Select Your CE Account Type"
													options={memberPlanOptions}
													onChange={halderAccountType}
												/>
											</Form.Item>
										</Form>
									</Panel>
									{accountType && (
										<Panel
											header={<span style={{ fontSize: "26px" }}>Step 2</span>}
											key="2"
										>
											<Form
												name="basic"
												layout="vertical"
												className="login-form"
												onFinish={onFinishStepTwo}
												form={form}
												autoComplete="off"
											>
												{/* <div>
                          Complete All Fields Below{" "}
                          <span style={{ color: "red" }}>*</span>
                        </div> */}
												<br />
												<h1 style={{ fontWeight: "normal " }}>
													Participant's Information
												</h1>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="first_name"
															rules={[
																{
																	required: true,
																	message: "This field field is required.",
																},
															]}
															hasFeedback
														>
															<FloatInput
																label="First Name"
																placeholder="First Name"
															/>
														</Form.Item>
													</Col>

													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="last_name"
															rules={[
																{
																	required: true,
																	message: "This field field is required.",
																},
															]}
															hasFeedback
														>
															<FloatInput
																placeholder="Last Name"
																label="Last Name"
															/>
														</Form.Item>
													</Col>

													{accountType === "SPEAKER" && (
														<Col
															xs={24}
															sm={24}
															md={24}
															lg={24}
															xl={24}
															xxl={24}
														>
															<Form.Item
																name="credentials"
																rules={[
																	{
																		required: true,
																		message: "This field field is required.",
																	},
																]}
																hasFeedback
															>
																<FloatInput
																	placeholder="Credentials"
																	label="Credentials"
																/>
															</Form.Item>
														</Col>
													)}

													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="email"
															rules={[
																{
																	required: true,
																	message: "This field field is required.",
																},
																{
																	type: "email",
																	message: "E-mail is not a valid email",
																},
															]}
															hasFeedback
														>
															<FloatInput label="E-mail" placeholder="E-mail" />
														</Form.Item>
													</Col>
													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="confirm_email"
															dependencies={["email"]}
															rules={[
																{
																	required: true,
																	message: "This field field is required.",
																},
																{
																	type: "email",
																	message:
																		"Confirm E-mail is not a valid email",
																},
																({ getFieldValue }) => ({
																	validator(_, value) {
																		if (
																			!value ||
																			getFieldValue("email") === value
																		) {
																			return Promise.resolve();
																		}
																		return Promise.reject(
																			new Error(
																				"The emails you entered did not match!"
																			)
																		);
																	},
																}),
															]}
															hasFeedback
														>
															<FloatInput
																label="Confirm E-mail"
																placeholder="Confirm E-mail"
															/>
														</Form.Item>
													</Col>

													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="username"
															rules={[
																{
																	required: true,
																	message: "This field field is required.",
																},
															]}
															hasFeedback
														>
															<FloatInput
																label="Create Username"
																placeholder="Create Username"
															/>
														</Form.Item>
													</Col>

													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="country"
															rules={[
																{
																	required: true,
																	message: "This field field is required.",
																},
															]}
															className="form-select-error"
															hasFeedback
														>
															<FloatSelect
																label="Country"
																placeholder="Country"
																options={country}
																onChange={handleCountry}
															/>
														</Form.Item>
													</Col>

													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="state"
															rules={[
																{
																	required: true,
																	message: "This field field is required.",
																},
															]}
															hasFeedback
															// style={{ marginBottom: "37px" }}
															className="form-select-error"
														>
															<FloatSelect
																label={stateLabel}
																placeholder={stateLabel}
																options={optionState}
															/>
														</Form.Item>
													</Col>

													{freeReg == 0 && (
														<Col
															xs={24}
															sm={24}
															md={24}
															lg={24}
															xl={24}
															xxl={24}
														>
															<Form.Item
																name="password"
																rules={[
																	{
																		required: true,
																		message: "Please input your password!",
																	},
																	{
																		min: 8,
																		message:
																			"Password must be minimum 8 characters.",
																	},
																]}
																hasFeedback
															>
																<FloatInputPasswordStrength
																	label="Password"
																	placeholder="Password"
																/>
															</Form.Item>
														</Col>
													)}
													{freeReg == 0 && (
														<Col
															xs={24}
															sm={24}
															md={24}
															lg={24}
															xl={24}
															xxl={24}
														>
															<Form.Item
																name="confirm"
																dependencies={["password"]}
																hasFeedback
																rules={[
																	{
																		required: true,
																		message: "Please confirm your password!",
																	},
																	{
																		min: 8,
																		message:
																			"Password must be minimum 8 characters.",
																	},
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			if (
																				!value ||
																				getFieldValue("password") === value
																			) {
																				return Promise.resolve();
																			}
																			return Promise.reject(
																				new Error(
																					"The two passwords that you entered do not match!"
																				)
																			);
																		},
																	}),
																]}
															>
																<FloatInputPasswordStrength
																	label="Confirm Password"
																	placeholder="Confirm Password"
																/>
															</Form.Item>
														</Col>
													)}
												</Row>
												{!emailConfirmAlert && (
													<>
														{freeReg == 1 && (
															<>
																<h1 style={{ fontWeight: "normal " }}>
																	Privacy Policy and Terms & Conditions
																</h1>
																<div
																	className="c-danger"
																	style={{ marginTop: -9 }}
																>
																	<b>
																		Please read / scroll to the end to continue.
																	</b>
																</div>
																{/* <Input.TextArea

                                  // value={policy}
                                  id={"policyText"}
                                onScroll={handleScroll}
                  className ="policyDiv"
                                  rows={7}
                                  style={{ marginBottom: 10 }}
                                /> */}
																<div
																	onScroll={handleScroll}
																	className="policyDiv"
																	style={{
																		marginBottom: 10,
																		marginTop: 10,
																		height: 170,
																		resize: "vertical",
																		overflow: "auto",
																		border: "1px solid #d9d9d9",
																	}}
																	dangerouslySetInnerHTML={{ __html: policy }}
																></div>

																<Checkbox
																	onChange={onChange}
																	name="checkbox_2"
																	className="optiona"
																	id="dd"
																	disabled={yesDisabled}
																>
																	Yes, I have read the Privacy Policy and Terms
																	and Conditions
																</Checkbox>
																<br></br>
																<ReCAPTCHA
																	style={{ marginTop: 10 }}
																	onChange={(token) => setCaptchaToken(token)}
																	// theme="dark"
																	render="explicit"
																	className="captcha-registration"
																	// render="explicit"
																	sitekey="6LfNfXgfAAAAAAbDCNuzI35Cm0hW_YwJ4UC0TYgw"
																	// onloadCallback={() => {}}
																/>
																{captchaTokenError && (
																	<span
																		style={{
																			color: "#dc3545",
																		}}
																	>
																		Please Verify Captcha
																	</span>
																)}
															</>
														)}

														<Button
															type="primary"
															htmlType="submit"
															loading={isLoadingRegisterFree}
															disabled={freeReg == 1 ? btnDisabled : false}
															className="btn-loginNew-outline"
															// onClick={() => {
															//     form.submit();
															// }}
															style={{
																width: "100%",
																marginTop: 10,
																fontSize: "20px",
																height: "45px",
															}}
														>
															{freeReg == 0 ? "CONTINUE" : "SUBMIT"}
														</Button>
													</>
												)}
												{emailConfirmAlert == true && (
													<Alert
														style={{ marginTop: 10 }}
														type="success"
														message="We sent you an email confirmation , Please confirm your email address to get started on CE.LIYA"
													/>
												)}
											</Form>
										</Panel>
									)}

									{showStepThree && (
										<Panel
											header={<span style={{ fontSize: "26px" }}>Step 3</span>}
											key="3"
										>
											{accountType === "MEMBER" && (
												<ComponentStepThree
													setShowStepFour={setShowStepFour}
													setDataStepThree={setDataStepThree}
													setCollapse={setCollapse}
													memberPlan={memberPlan}
													accountType={accountType}
													setSelectedPlan={setSelectedPlan}
												/>
											)}

											{accountType === "EVENT PROVIDER" && (
												<ComponentStepThree
													setShowStepFour={setShowStepFour}
													setDataStepThree={setDataStepThree}
													setCollapse={setCollapse}
													memberPlan={eventPlan}
													setSelectedPlan={setSelectedPlan}
													accountType={accountType}
												/>
											)}

											{accountType === "VENUE PROVIDER" && (
												<ComponentStepThree
													setShowStepFour={setShowStepFour}
													setDataStepThree={setDataStepThree}
													setCollapse={setCollapse}
													memberPlan={venuePlan}
													setSelectedPlan={setSelectedPlan}
													accountType={accountType}
												/>
											)}
											{accountType === "SPEAKER" && (
												<ComponentStepThree
													setShowStepFour={setShowStepFour}
													setDataStepThree={setDataStepThree}
													setCollapse={setCollapse}
													memberPlan={speakerPlan}
													setSelectedPlan={setSelectedPlan}
													accountType={accountType}
												/>
											)}
											{accountType === "EVENT PROVIDER/SPEAKER" && (
												<ComponentStepThree
													setShowStepFour={setShowStepFour}
													setDataStepThree={setDataStepThree}
													setCollapse={setCollapse}
													memberPlan={eventspeakerPlan}
													setSelectedPlan={setSelectedPlan}
													accountType={accountType}
												/>
											)}
										</Panel>
									)}

									{showStepFour && (
										<Panel
											header={<span style={{ fontSize: "26px" }}>Step 4</span>}
											key="4"
										>
											<ComponentStepFour
												setShowStepFour={setShowStepFour}
												setDataStepFour={setDataStepFour}
												setCollapse={setCollapse}
												states={states}
												accountType={accountType}
												dataStepTwo={dataStepTwo}
												dataStepThree={dataStepThree}
												dataStepFour={dataStepFour}
												selectedPlan={selectedPlan}
												policy={policy}
											/>
										</Panel>
									)}
								</Collapse>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
			</Row>

			<div style={{ width: "100%", textAlign: "center" }}>
				<br />
				<br />
				<span style={{ color: "white" }}>
					© {moment().format("YYYY")} CE.LIYA. All Rights Reserved.
				</span>
				<br />
				<br />
				<br />
				<br />
			</div>
		</Layout.Content>
	);
}
