import React, { useEffect, useState } from "react";
import { Form, Layout, notification, Space } from "antd";
import { MailOutlined } from "@ant-design/icons";
import ComponentHeader from "../Components/ComponentHeader";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";
import PageEditableTemplate from "./PageEditableTemplate";

const PageEditableTemplates = ({ permission }) => {
  let history = useHistory();
  const {
    data: dataEditableTemplates,
    isLoading: isLoadingTblEditableTemplate,
    refetch: refetchEditableTemplates,
    isFetching: isFetchingTblEditableTemplate,
  } = GET(`api/v1/editable-template`, "editable-templates", (res) => {
    if (res.success) {
      console.log(res);
      let formFreeRegEmailData = res.data.find(
        (p) => p.title == "Free Registration Email"
      );
      formFreeRegEmail.setFieldsValue(formFreeRegEmailData);
      let formResetPassData = res.data.find(
        (p) => p.title == "Password Reset Email"
      );
      formResetPass.setFieldsValue(formResetPassData);
      let formAccDeativateData = res.data.find(
        (p) => p.title == "Account Deactivation Email"
      );
      formAccDeativate.setFieldsValue(formAccDeativateData);
      let fromCreatedTicketData = res.data.find(
        (p) => p.title == "Created Ticket Email"
      );
      fromCreatedTicket.setFieldsValue(fromCreatedTicketData);
      let fromCreatedTicketDataAdmin = res.data.find(
        (p) => p.title == "Created Ticket Email -  Admin Only"
      );
      fromCreatedTicketAdmin.setFieldsValue(fromCreatedTicketDataAdmin);
      let fromTicketReplyData = res.data.find(
        (p) => p.title == "We Replied to Your Ticket"
      );
      fromTicketReply.setFieldsValue(fromTicketReplyData);
      let fromCertificationData = res.data.find(
        (p) => p.title == "Certification Email"
      );
      fromCertification.setFieldsValue(fromCertificationData);

      let fromRegMemData = res.data.find(
        (p) => p.title == "Registration email - Member"
      );
      fromRegMem.setFieldsValue(fromRegMemData);
      let fromRegEPData = res.data.find(
        (p) => p.title == "Registration email - Event Provider"
      );
      fromRegEP.setFieldsValue(fromRegEPData);
      let fromRegVenData = res.data.find(
        (p) => p.title == "Registration email - Venue Provider"
      );
      fromRegVen.setFieldsValue(fromRegVenData);
      let fromRegSpeData = res.data.find(
        (p) => p.title == "Registration email - Speaker"
      );
      fromRegSpe.setFieldsValue(fromRegSpeData);
      let fromRegEPSpeData = res.data.find(
        (p) => p.title == "Registration email - Event Provider/Speaker"
      );
      fromRegEPSpe.setFieldsValue(fromRegEPSpeData);

      let fromPassResetData = res.data.find(
        (p) => p.title == "Password Reset Notification Email"
      );
      fromPassReset.setFieldsValue(fromPassResetData);

      let fromTicketClosedData = res.data.find(
        (p) => p.title == "Ticket Has Been Closed Email"
      );
      fromTicketClosed.setFieldsValue(fromTicketClosedData);
      let fromTicketCustomerReplyData = res.data.find(
        (p) => p.title == "Customer Updated Ticket Email"
      );
      fromTicketCustomerReply.setFieldsValue(fromTicketCustomerReplyData);

      let fromMessagesData = res.data.find(
        (p) => p.title == "You Have a New Instant Message Email"
      );
      fromMessages.setFieldsValue(fromMessagesData);
      let fromEventsRegData = res.data.find(
        (p) => p.title == "Thank You For Your Event Registration Email"
      );
      fromEventsReg.setFieldsValue(fromEventsRegData);
      let fromEventsCanData = res.data.find(
        (p) => p.title == "Event Cancellation Notice Email"
      );
      fromEventsCan.setFieldsValue(fromEventsCanData);
      let fromEventsUpData = res.data.find(
        (p) => p.title == "Event Update Notice Email"
      );
      fromEventsUp.setFieldsValue(fromEventsUpData);

      let fromEventsConfirmData = res.data.find(
        (p) => p.title == "Check-In Confirmation Email"
      );
      fromEventsConfirm.setFieldsValue(fromEventsConfirmData);

      let fromEventsAddData = res.data.find(
        (p) => p.title == "Success! Your Event has been Added Email"
      );
      fromEventsAdd.setFieldsValue(fromEventsAddData);

      let fromEventsSoldoutData = res.data.find(
        (p) => p.title == "Congratulations! Your Event is Now Sold Out Email"
      );
      fromEventsSoldout.setFieldsValue(fromEventsSoldoutData);
      let fromEventsWaitData = res.data.find(
        (p) => p.title == "Event Wait-List Addition Email"
      );
      fromEventsWait.setFieldsValue(fromEventsWaitData);

      let fromEventsReqCanData = res.data.find(
        (p) => p.title == "Request to Cancel Your Event Confirmed Email"
      );
      fromEventsReqCan.setFieldsValue(fromEventsReqCanData);
      let fromBillingThankData = res.data.find(
        (p) => p.title == "Thank You for Your Order Email"
      );
      fromBillingThank.setFieldsValue(fromBillingThankData);
      let fromBillingSubUpData = res.data.find(
        (p) => p.title == "Your Account Subscription has Updated Email"
      );
      fromBillingSubUp.setFieldsValue(fromBillingSubUpData);
      let fromEventReminderData = res.data.find(
        (p) => p.title == "Event Reminder Email"
      );
      fromEventReminder.setFieldsValue(fromEventReminderData);
      let fromBillingMembershipData = res.data.find(
        (p) => p.title == "Membership Expires Email"
      );
      fromBillingMembership.setFieldsValue(fromBillingMembershipData);
      let fromBillingCardData = res.data.find(
        (p) => p.title == "Card Expires Soon Email"
      );
      fromBillingCard.setFieldsValue(fromBillingCardData);

      let fromBillingCardDeclinedData = res.data.find(
        (p) => p.title == "Card Declined Email"
      );
      fromBillingCardDeclined.setFieldsValue(fromBillingCardDeclinedData);
    }
  });

  const [formFreeRegEmail] = Form.useForm();
  const [formResetPass] = Form.useForm();
  const [formAccDeativate] = Form.useForm();
  const [fromCreatedTicket] = Form.useForm();
  const [fromCreatedTicketAdmin] = Form.useForm();
  const [fromTicketReply] = Form.useForm();
  const [fromCertification] = Form.useForm();

  const [fromRegMem] = Form.useForm();
  const [fromRegEP] = Form.useForm();
  const [fromRegVen] = Form.useForm();
  const [fromRegSpe] = Form.useForm();
  const [fromRegEPSpe] = Form.useForm();

  const [fromPassReset] = Form.useForm();
  const [fromTicketClosed] = Form.useForm();
  const [fromTicketCustomerReply] = Form.useForm();

  const [fromMessages] = Form.useForm();

  const [fromEventsReg] = Form.useForm();
  const [fromEventsCan] = Form.useForm();
  const [fromEventsUp] = Form.useForm();
  const [fromEventsConfirm] = Form.useForm();

  const [fromEventsAdd] = Form.useForm();

  const [fromEventsSoldout] = Form.useForm();
  const [fromEventsWait] = Form.useForm();
  const [fromEventsReqCan] = Form.useForm();

  const [fromBillingThank] = Form.useForm();
  const [fromBillingSubUp] = Form.useForm();

  const [fromEventReminder] = Form.useForm();

  const [fromBillingMembership] = Form.useForm();

  const [fromBillingCard] = Form.useForm();

  const [fromBillingCardDeclined] = Form.useForm();

  const {
    mutate: mutateSaveEditableTemplate,
    isLoading: isLoadingMutateSaveEditableTemplate,
  } = POST("api/v1/editable-template", "editable-templates");

  const handleSubmitEditableTemplate = (data) => {
    mutateSaveEditableTemplate(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Template Successfully Saved",
          });
        }
      },
    });
  };
  const sub_title = "VIEW ALL";

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageEditableTemplates"
    >
      <ComponentHeader
        permission={permission}
        sub_title={sub_title}
        icon={<MailOutlined />}
      />
      <Layout.Content style={{ padding: 24 }}>
        <div className="editableTemplateDiv">
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <PageEditableTemplate
              defaultShow={false}
              title="Registration email - Member"
              formRef={fromRegMem}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Registration email - Event Provider"
              formRef={fromRegEP}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Registration email - Venue Provider"
              formRef={fromRegVen}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Registration email - Speaker"
              formRef={fromRegSpe}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Registration email - Event Provider/Speaker"
              formRef={fromRegEPSpe}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Free Registration Email"
              formRef={formFreeRegEmail}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Certification Email"
              formRef={fromCertification}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Password Reset Email"
              formRef={formResetPass}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Password Reset Notification Email"
              formRef={fromPassReset}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Account Cancellation"
              formRef={formAccDeativate}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Created Ticket Email -  Admin Only"
              formRef={fromCreatedTicketAdmin}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Created Ticket Email"
              formRef={fromCreatedTicket}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="We Replied to Your Ticket"
              formRef={fromTicketReply}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />

            <PageEditableTemplate
              defaultShow={false}
              title="Customer Updated Ticket Email"
              formRef={fromTicketCustomerReply}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Ticket Has Been Closed Email"
              formRef={fromTicketClosed}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="You Have a New Instant Message Email"
              formRef={fromMessages}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Thank You For Your Event Registration Email"
              formRef={fromEventsReg}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Event Cancellation Notice Email"
              formRef={fromEventsCan}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Event Update Notice Email"
              formRef={fromEventsUp}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Check-In Confirmation Email"
              formRef={fromEventsConfirm}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Event Reminder Email"
              formRef={fromEventReminder}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Success! Your Event has been Added Email"
              formRef={fromEventsAdd}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Congratulations! Your Event is Now Sold Out Email"
              formRef={fromEventsSoldout}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Event Wait-List Addition Email"
              formRef={fromEventsWait}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Request to Cancel Your Event Confirmed Email"
              formRef={fromEventsReqCan}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Thank You for Your Order Email"
              formRef={fromBillingThank}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />

            <PageEditableTemplate
              defaultShow={false}
              title="Your Account Subscription has Updated Email"
              formRef={fromBillingSubUp}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Membership Expires Email"
              formRef={fromBillingMembership}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Card Expires Soon Email"
              formRef={fromBillingCard}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
            <PageEditableTemplate
              defaultShow={false}
              title="Card Declined Email"
              formRef={fromBillingCardDeclined}
              handleSubmitEditableTemplate={handleSubmitEditableTemplate}
            />
          </Space>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default PageEditableTemplates;
