import React from "react";
import { Layout, Form, Button, Row, Col, Collapse, notification } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	SaveOutlined,
	UpOutlined,
	DownOutlined,
	CreditCardFilled,
} from "@ant-design/icons";

import FloatInput from "../../../../providers/FloatInput";
import FloatTextarea from "../../../../providers/FloatTextArea";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import TableAccountTypesPlan from "./Component/TableAccountTypesPlan";
import TableFaqs from "./Component/TableFaqs";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

export default function PageAccountTypesEdit({ match, props, permission }) {
	const sub_title = "VIEW/EDIT";
	let id = match.params.id;
	const { Panel } = Collapse;

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];

	const modulesToolBar = {
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			// ["link", "image", "video"],
			// ["clean"],
		],
		imageResize: {
			modules: ["Resize", "DisplaySize"],
		},
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();

	const { data: dataGetAccountType, isLoading: isLoadingGetAccountType } = GET(
		`api/v1/account_type/${id}`,
		"account_type",
		(res) => {
			if (res.success) {
				console.log("account_type", res);
				form.setFieldsValue({
					account_type: res.data.account_type,
					description: res.data.description,
					privacy_policy: res.data.privacy
						? res.data.privacy.privacy_policy
						: "",
				});
				if (res.data.privacy && res.data.privacy.privacy_policy == "") {
					form.resetFields(["privacy_policy"]);
				}
			}
		}
	);

	const { mutate: mutateAccountType, isLoading: isLoadingAccountType } = POST(
		"api/v1/account_type",
		"account_type"
	);

	const onFinish = (value) => {
		let data = {
			...value,
			id: id,
		};
		mutateAccountType(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Successfully Submited",
					});
				}
			},
		});
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageAccountTypesEdit"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CreditCardFilled />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br />
				<Form
					form={form}
					onFinish={onFinish}
					wrapperCol={{ span: 24 }}
					layout="vertical"
				>
					<Row gutter={4}>
						<Col xs={24} sm={24} md={14} lg={14}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Account Type Details"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="account_type"
												rules={[validator.require]}
												className="capslock"
												hasFeedback
											>
												<FloatInput
													label="Account Type"
													placeholder="Account Type"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="description"
												label="Description"
												rules={[validator.require]}
											>
												{/* <FloatTextarea
                          label="Description"
                          placeholder="Description"
                        /> */}
												<ReactQuill
													theme="snow"
													style={{ height: 200 }}
													formats={formats}
													modules={modulesToolBar}
												/>
											</Form.Item>
											<br />
										</Col>
									</Row>
								</Panel>
							</Collapse>
							<br />
						</Col>
						<Col xs={24} sm={24} md={10} lg={10}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Privacy Policy"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="privacy_policy"
												label="Policy"
												rules={[validator.require]}
											>
												{/* <FloatTextarea label="Policy" placeholder="Policy" />
												 */}
												<ReactQuill
													theme="snow"
													style={{ height: 200 }}
													formats={formats}
													modules={modulesToolBar}
												/>
											</Form.Item>
											<br />
										</Col>
									</Row>
								</Panel>
							</Collapse>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={8} lg={8}>
							<br />
							<Button
								size="large"
								style={{ width: "100%" }}
								className="btn-success-outline"
								icon={<SaveOutlined />}
								onClick={() => form.submit()}
								loading={isLoadingAccountType}
							>
								Submit
							</Button>
						</Col>
					</Row>
				</Form>
			</Layout.Content>
			<TableAccountTypesPlan id={id} />
			<TableFaqs id={id} />
			{/* <TablePolicy id={id} /> */}
		</Layout>
	);
}
