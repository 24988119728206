import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Table, Pagination, Space, Button } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { EditOutlined, AccountBookOutlined } from "@ant-design/icons";
import $ from "jquery";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";

export default function PageAccountTypes({ props, permission }) {
	const sub_title = "VIEW ALL";
	const history = useHistory();

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const {
		data: dataGetProfiles,
		isLoading: isLoadingGetProfiles,
		refetch: refetchGetProfiles,
	} = GET(
		`api/v1/account_type?${$.param(dataTableInfo)}`,
		"account_type",
		(res) => {
			if (res.success) {
				console.log("account_type", res);
			}
		}
	);
	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchGetProfiles();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageAccountType"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<AccountBookOutlined />}
			/>
			{/* test */}
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br />
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataTableInfo.data && dataTableInfo.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						className="table-responsive table-thead-login"
						size="small"
						rowKey={(record) => record.id}
						loading={isLoadingGetProfiles}
						dataSource={dataGetProfiles ? dataGetProfiles.data.data : []}
						pagination={false}
						onChange={handleTableChange}
					>
						<Table.Column
							key="account_type"
							title="Account Type"
							dataIndex="account_type"
							sorter
						/>
						<Table.Column
							key="description"
							title="Description"
							dataIndex="description"
							render={(text, record) => {
								return (
									<div
										dangerouslySetInnerHTML={{
											__html: text,
										}}
									/>
								);
							}}
							sorter
						/>
						<Table.Column
							key="action"
							title="Action"
							render={(text, record) => {
								return (
									<Space>
										{/* <Button
                      size="small"
                      className="btn-primary"
                      icon={<EyeOutlined />}
                      onClick={(e) =>
                        history.push("/admin/account-types/view/" + record.id)
                      }
                    >
                      View
                    </Button> */}
										<Button
											size="small"
											className="btn-warning-outline"
											icon={<EditOutlined />}
											onClick={(e) =>
												history.push("/admin/account-types/edit/" + record.id)
											}
										>
											Edit
										</Button>
									</Space>
								);
							}}
						/>
					</Table>
				</div>
			</Layout.Content>
		</Layout>
	);
}
