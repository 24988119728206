import React, { useState, useEffect } from "react";
import {
	Card,
	Layout,
	Modal,
	Typography,
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Checkbox,
	Collapse,
	notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import { FaTimes } from "react-icons/fa";
import {
	HomeOutlined,
	SaveOutlined,
	FieldTimeOutlined,
	ClockCircleOutlined,
	CalendarOutlined,
	UserOutlined,
	BankOutlined,
	MinusOutlined,
	UpOutlined,
	DownOutlined,
	PlusCircleOutlined,
	EditOutlined,
} from "@ant-design/icons";
import {
	faMapLocation,
	faCircleDot,
	faBuilding,
} from "@fortawesome/free-regular-svg-icons";

import { Link, useHistory } from "react-router-dom";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";

import countryList from "react-select-country-list";
import states from "states-us";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
// import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatInputMask from "../../../../providers/FloatInputMask";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";

export default function PageMyCompanyView({ props, permission }) {
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
	const [formData, setFormData] = useState("");

	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		form.resetFields(["state", "zip"]);
	};

	const history = useHistory();
	const { Panel } = Collapse;
	const sub_title = "My";
	let country_list = countryList().getData();
	let userdata = getUserData();
	const [state, setState] = useState([]);
	const [primary, setPrimary] = useState(0);
	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();

	const onFinishMyAccount = (values) => {
		let data = {
			...values,
			user_id: userdata.id,
			primary: primary,
		};
		console.log("onFinishMyAccount", data);
		mutateCreateAccount(data, {
			onSuccess: (res) => {
				if (res.success) {
					form.resetFields();
					notification.success({
						message: "Success",
						description: "Successfully Saved!",
					});
					setShowCompany(false);
				} else {
					notification.error({
						message: res.message,
					});
				}
			},
			onError: (res) => {},
		});
	};

	const { data: dataGetAccount, isLoading: isLoadingGetAccount } = GET(
		`api/v1/member_company_account?user_id=${userdata.id}`,
		"member_company_account"
	);

	const { mutate: mutateCreateAccount, isLoading: isLoadingCreateAccount } =
		POST("api/v1/member_company/createAcc", "member_company_account");

	const [showCompany, setShowCompany] = useState(false);

	const onCancelCompany = () => {
		setShowCompany(false);
	};

	const {
		mutate: mutateUpdatePrimary,
		isLoading: isLoadingMutateUpdatePrimary,
	} = POST(
		"api/v1/member_company/mutateUpdatePrimary",
		"member_company_account"
	);

	const setPrimaryCompany = (e, id) => {
		let primary = e ? 1 : 0;

		console.log(e);
		mutateUpdatePrimary(
			{ primary: primary, user_id: userdata.id, id: id },
			{
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Success",
							description: "Primary Company Successfully Saved!",
						});
						setShowCompany(false);
					} else {
						notification.error({
							message: res.message,
						});
					}
				},
				onError: (res) => {},
			}
		);
	};

	const ceView = (id) => {
		history.push("/member/my-account/my-company/" + id);
	};

	const { data: dataUser, isLoading: isLoadingUser } = GET(
		`api/v1/get_by_id?id=${userdata.id}`,
		"get_by_id",
		(res) => {
			if (res.success) {
				console.log("res", res);
			}
		}
	);

	const [businessCategory, setBusinessCategory] = useState([
		{
			label: "Free",
			value: "Free",
		},
	]);

	useEffect(() => {
		if (dataUser) {
			let planType = dataUser.data[0].user_plans
				? dataUser.data[0].user_plans.stripe_subscription_status == "active"
					? dataUser.data[0].user_plans.account_type_plans
						? dataUser.data[0].user_plans.account_type_plans.plan
						: "0"
					: "0"
				: "0";

			console.log("planType", planType);
			if (planType == "0") {
				let business_category = [
					{
						label: "Free",
						value: "Free",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}

			if (planType.includes("Group 1")) {
				let business_category = [
					{
						label: "Dental Student",
						value: "Dental Student",
					},
					{
						label: "Dental Assistants",
						value: "Dental Assistants",
					},
					{
						label: "Front Office",
						value: "Front Office",
					},
					{
						label: "Office Manager",
						value: "Office Manager",
					},
					{
						label: "Office Coordinator",
						value: "Office Coordinator",
					},
					{
						label: "Dental Practice Owner",
						value: "Dental Practice Owner",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}
			if (planType.includes("Group 2")) {
				let business_category = [
					{
						label: "Retired Dentist",
						value: "Retired Dentist",
					},
					{
						label: "Denturist",
						value: "Denturist",
					},
					{
						label: "Dental Hygienist",
						value: "Dental Hygienist",
					},
					{
						label: "Dental Health Aid Therapist (DHAT)",
						value: "Dental Health Aid Therapist (DHAT)",
					},
					{
						label: "Dental Professor/Instructor",
						value: "Dental Professor/Instructor",
					},
					{
						label: "Dental Lab Techs",
						value: "Dental Lab Techs",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}
			if (planType.includes("Group 3")) {
				let business_category = [
					{
						label: "Dentists",
						value: "Dentists",
					},
					{
						label: "Dental Specialist",
						value: "Dental Specialist",
					},
					{
						label: "Endodontist",
						value: "Endodontist",
					},
					{
						label: "Oral Surgeon",
						value: "Oral Surgeon",
					},
					{
						label: "Periodontist",
						value: "Periodontist",
					},
					{
						label: "Pediatric Dentist",
						value: "Pediatric Dentist",
					},
					{
						label: "Prosthodontist",
						value: "Prosthodontist",
					},
					{
						label: "Dental Anesthesiologist",
						value: "Dental Anesthesiologist",
					},
					{
						label: "Dental Public Health",
						value: "Dental Public Health",
					},
					{
						label: "Oral Maxillofacial Pathologist",
						value: "Oral Maxillofacial Pathologist",
					},
					{
						label: "Oral Maxillofacial Radiologist",
						value: "Oral Maxillofacial Radiologist",
					},
					{
						label: "Oral Medicine",
						value: "Oral Medicine",
					},
					{
						label: "Oral Facial Pain Specialist",
						value: "Oral Facial Pain Specialist",
					},
					{
						label: "Other",
						value: "Other",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}
		}
	}, [dataUser]);

	const onChangeOption = (val, option) => {
		setFormData(val);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageMyCompanyView"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<FontAwesomeIcon icon={faBuilding} />}
			/>
			{/* <ComponentFaqs
        linkVid={"https://player.vimeo.com/video/644214039?autoplay=0&muted=1"}
      /> */}

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br></br>
				<Row gutter={16}>
					<Col xs={24} sm={24} md={16} lg={16}>
						<Button
							className="btn-login-outline"
							icon={<PlusCircleOutlined />}
							onClick={() => {
								setShowCompany(true);
							}}
							style={{ marginBottom: 10, float: "right" }}
						>
							Add Company
						</Button>
					</Col>
					<Col xs={24} sm={24} md={16} lg={16}>
						{dataGetAccount &&
							dataGetAccount.data.map((item, key) => {
								return (
									<div key={key}>
										<Collapse
											className="lesspaddingCollapse"
											expandIcon={({ isActive }) =>
												isActive ? (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(270deg)",
														}}
													></span>
												) : (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(90deg)",
														}}
													></span>
												)
											}
											expandIconPosition="right"
											defaultActiveKey={["1"]}
										>
											<Panel
												header={item.business_name}
												key="1"
												className="accordion bg-darkgray-form "
											>
												<Row>
													<Col xs={20} sm={20} md={12}>
														<div>
															<Link
																className="companyTitle"
																to={"/member/my-account/my-company/" + item.id}
															>
																{item.business_name}
															</Link>
														</div>
														<div>{item.title ? item.title : ""}</div>
														<div>{item.address_1 ? item.address_1 : ""}</div>
														<div>{item.address_2 ? item.address_2 : ""}</div>
														<div>
															{item.city}, {item.state} {item.zip}
														</div>
														<div>
															{item.business_phone
																? " Business Phone: " + item.business_phone
																: ""}
														</div>
														<span>Primary Dental Company</span>{" "}
														<Switch
															size="small"
															checked={item.primary == 1 ? true : false}
															onChange={(e) => setPrimaryCompany(e, item.id)}
														/>{" "}
													</Col>
													<Col xs={4} sm={4} md={12}>
														<span style={{ float: "right", marginRight: 5 }}>
															<Button
																type="primary"
																className="btn-warning-outline"
																icon={<EditOutlined />}
																onClick={() => ceView(item.id)}
															></Button>
														</span>
													</Col>
												</Row>
											</Panel>
										</Collapse>
										<br></br>
									</div>
								);
							})}
					</Col>
				</Row>

				<Modal
					className="modal-login"
					title={"Add Company "}
					visible={showCompany}
					width={650}
					onCancel={onCancelCompany}
					footer={[
						<Button onClick={onCancelCompany}>Cancel</Button>,
						<Button
							className="btn-login-outline"
							icon={<SaveOutlined />}
							onClick={() => form.submit()}
							loading={isLoadingCreateAccount}
						>
							Save
						</Button>,
					]}
					closeIcon={<FaTimes className="modal-close-icon" />}
				>
					<Form
						wrapperCol={{ span: 24 }}
						layout="horizontal"
						form={form}
						onFinish={onFinishMyAccount}
					>
						<Form.Item
							name="business_name"
							rules={[validator.require]}
							hasFeedback
						>
							<FloatInput
								label="Business/Practice Name"
								placeholder="Business/Practice Name"
							/>
						</Form.Item>
						<Form.Item name="title" rules={[validator.require]} hasFeedback>
							<FloatInput label="Title" placeholder="Title" />
						</Form.Item>
						<Form.Item name="primary">
							<Switch
								// defaultChecked
								checked={primary == 1 ? true : false}
								onChange={(e) => setPrimary(e ? 1 : 0)}
							/>{" "}
							<span>Is this your Primary Company of Employment?</span>
						</Form.Item>{" "}
						<Form.Item
							name="country"
							rules={[
								{
									required: true,
									message: "This field field is required.",
								},
							]}
							className="form-select-error"
							hasFeedback
						>
							<FloatSelect
								label="Country"
								placeholder="Country"
								options={country}
								onChange={handleCountry}
							/>
						</Form.Item>
						<Form.Item name="address_1" rules={[validator.require]} hasFeedback>
							<FloatInput label="Address 1" placeholder="Address 1" />
						</Form.Item>
						<Form.Item name="address_2">
							<FloatInput label="Address 2" placeholder="Address 2" />
						</Form.Item>
						<Row>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<Form.Item name="city" rules={[validator.require]} hasFeedback>
									<FloatInput label="City" placeholder="City" />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
								<Form.Item
									name="state"
									rules={[validator.require]}
									hasFeedback
									className="form-select-error"
								>
									<FloatSelect
										label={stateLabel}
										placeholder={stateLabel}
										options={optionState}
									/>
								</Form.Item>
							</Col>

							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
								<Form.Item
									name="zip"
									rules={[
										validator.require,
										{
											pattern: optionZip,
											message: "Invalid " + zipLabel,
										},
									]}
									hasFeedback
								>
									<FloatInput label={zipLabel} placeholder={zipLabel} />
								</Form.Item>
							</Col>
						</Row>
						<Form.Item
							name="business_phone"
							rules={[validator.require]}
							hasFeedback
						>
							<FloatInputMask
								label="Business Phone1"
								placeholder="Business Phone1"
								maskLabel="business_phone"
								maskType="999-999-9999"
							/>
						</Form.Item>
						<Form.Item name="business_phone2">
							<FloatInputMask
								label="Business Phone 2"
								placeholder="Business Phone 2"
								maskLabel="business_phone2"
								maskType="999-999-9999"
							/>
						</Form.Item>
						<Form.Item name="business_fax">
							<FloatInput label="Business Fax" placeholder="Business Fax" />
						</Form.Item>
						<Form.Item
							name="business_email"
							rules={[
								{
									type: "email",
									message: "please enter a valid email",
								},
							]}
						>
							<FloatInput label="Business Email" placeholder="Business Email" />
						</Form.Item>{" "}
						<Form.Item
							name="business_categories"
							rules={[validator.require]}
							hasFeedback
							className="form-select-error-multi"
						>
							<FloatSelect
								label="Business Category"
								placeholder="Business Category"
								options={businessCategory}
								multi="multiple"
								onChange={onChangeOption}
							/>
						</Form.Item>
						<Form.Item name="website">
							<FloatInput label="Website" placeholder="Website" />
						</Form.Item>
					</Form>
				</Modal>
			</Layout.Content>
		</Layout>
	);
}
