import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRouteEventProviderSpeaker from "./PrivateRouteEventProviderSpeaker";

// EVENT PROVIDER
import PageDashboard from "../views/private/EventProviderSpeaker/PageDashboard/PageDashboard";
import PageMyCompany from "../views/private/EventProviderSpeaker/PageMyAccount/PageMyCompany";
import PageProfile from "../views/private/EventProviderSpeaker/PageMyAccount/PageProfile";
import PageBankDetails from "../views/private/EventProviderSpeaker/PageMyAccount/PageBankDetails";
import PageSubscription from "../views/private/EventProviderSpeaker/PageMyAccount/PageSubscription";
import PageInvoicesView from "../views/private/EventProviderSpeaker/PageMyAccount/PageInvoicesView";
import PageAttendeesPayments from "../views/private/EventProviderSpeaker/PageMyAccount/PageAttendeesPayments";
import PageViewEditEvents from "../views/private/EventProviderSpeaker/PageEvent/PageViewEditEvents";
import PageCreateEvent from "../views/private/EventProviderSpeaker/PageEvent/PageCreateEvent";
import PageEditEvent from "../views/private/EventProviderSpeaker/PageEvent/PageEditEvent";
import PageViewEventsAttendees from "../views/private/EventProviderSpeaker/PageEvent/PageViewEventsAttendees";
import PageIndividualEvent from "../views/private/EventProviderSpeaker/PageEvent/PageIndividualEvent";
import PageAttendees from "../views/private/EventProviderSpeaker/PageAttendees/PageAttendees";
import PageInvoices from "../views/private/EventProviderSpeaker/PageMyAccount/PageInvoices";
import PageCertTemplate from "../views/private/EventProviderSpeaker/PageCertTemplate/PageCertTemplate";
// import PagePDF from "../views/private/EventProviderSpeaker/PageCertTemplate/PagePDF";
import PageFaqs from "../views/private/EventProviderSpeaker/PageSupport/PageFaqs";
import PageTicketing from "../views/private/EventProviderSpeaker/PageSupport/PageTicketing";
import PageCreateTicket from "../views/private/EventProviderSpeaker/PageSupport/PageCreateTicket";
import PageTicketingReply from "../views/private/EventProviderSpeaker/PageSupport/PageTicketingReply";
import PageVenue from "../views/private/EventProviderSpeaker/PageVenue/PageVenue";
import PageSpeaker from "../views/private/EventProviderSpeaker/PageSpeaker/PageSpeaker";
import PageSubscriptionUpgrade from "../views/private/EventProviderSpeaker/PageMyAccount/PageSubscriptionUpgrade";
import PageMessages from "../views/private/PageMessages/PageMessages";
import ComponentCookies from "../views/private/Components/ComponentCookies";
import ComponentPrivacy from "../views/private/Components/ComponentPrivacy";
import ComponentTermsProviders from "../views/private/Components/ComponentTermsProviders";
import PageSubscriptionDowngrade from "../views/private/EventProviderSpeaker/PageMyAccount/PageSubscriptionDowngrade";
import Page2fa from "../views/private/Page2fa/Page2fa";
import PageQRreader from "../views/private/EventProviderSpeaker/PageEvent/PageQRreader";
import PageSpeakerProfile from "../views/private/EventProviderSpeaker/SpeakerProfile/PageSpeakerProfile";
import PageCreditCard from "../views/private/PageCreditCard/PageCreditCard";
import Error404 from "../views/public/Widgets/Error404";
const RoutePageEventSpeaker = () => {
	return (
		<Switch>
			{/* Event Provider */}
			<PrivateRouteEventProviderSpeaker
				exact
				path="/terms-providers"
				component={ComponentTermsProviders}
				permission="Conditions"
				breadcrumbs={[
					{
						name: "Terms & Conditions",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/cookies"
				component={ComponentCookies}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Cookie Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/privacy"
				component={ComponentPrivacy}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Privacy Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/dashboard"
				component={PageDashboard}
				permission="Dashboard"
				breadcrumbs={[
					{
						name: "Dashboard",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/profile"
				component={PageProfile}
				permission="Profile"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Account Profile",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/profile/bank-details"
				component={PageBankDetails}
				permission="Bank Details"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Account Profile",
						link: "/event-provider-speaker/my-account/profile",
					},
					{
						name: "Edit Bank Details",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/profile/subscription"
				component={PageSubscription}
				permission="Subscription"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Account Profile",
						link: "/event-provider-speaker/my-account/profile",
					},
					{
						name: "Subscription",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/invoices/subscription/credit-card"
				component={PageCreditCard}
				permission="Credit Card"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/event-provider-speaker/my-account/invoices",
					},
					{
						name: "Credit Card",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/invoices/subscription/upgrade"
				component={PageSubscriptionUpgrade}
				permission="Subscription Upgrade"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/event-provider-speaker/my-account/invoices",
					},
					{
						name: "Upgrade",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/invoices/subscription/downgrade"
				component={PageSubscriptionDowngrade}
				permission="Subscription Downgrade"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/event-provider-speaker/my-account/invoices",
					},
					{
						name: "Downgrade",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/my-company"
				component={PageMyCompany}
				permission="Company"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "My Company",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/invoices"
				component={PageInvoices}
				permission="Invoices"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/attendees-payments"
				component={PageAttendeesPayments}
				permission="Invoices"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Attendees Payments",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/invoices/:id"
				component={PageInvoicesView}
				permission="Paid"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/event-provider-speaker/my-account/invoices",
					},
					{
						name: "Invoice",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/ce-calendar/view-edit-events"
				component={PageViewEditEvents}
				permission="Event"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "View All Events",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/ce-calendar/create-event"
				component={PageCreateEvent}
				permission="Event"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "Create Event",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/ce-calendar/edit-event/:id"
				component={PageEditEvent}
				permission="Event"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "View All Events",
						link: "/event-provider-speaker/ce-calendar/view-edit-events",
					},
					{
						name: "Edit Event",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/ce-calendar/events-with-attendees"
				component={PageViewEventsAttendees}
				permission="Attendees"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "All Events with Attendees",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/ce-calendar/events-with-attendees/qr-reader"
				component={PageQRreader}
				permission="Reader"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "All Events with Attendees",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/ce-calendar/events-with-attendees/:id"
				component={PageIndividualEvent}
				permission="Event"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "All Events with Attendees",
						link: "/event-provider-speaker/ce-calendar/events-with-attendees",
					},
					{
						name: "Individual Event",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/venue"
				component={PageVenue}
				permission="Venue"
				breadcrumbs={[
					{
						name: "Search Venue",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/speaker"
				component={PageSpeaker}
				permission="Speaker"
				breadcrumbs={[
					{
						name: "Search Speaker",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/attendees/all"
				component={PageAttendees}
				permission="Attendees"
				breadcrumbs={[
					{
						name: "Attendees",
						link: null,
					},
					{
						name: "All Attendees",
						link: null,
					},
				]}
			/>

			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/attendees/events-with-attendees"
				component={PageViewEventsAttendees}
				permission="Attendees"
				breadcrumbs={[
					{
						name: "Attendees",
						link: null,
					},
					{
						name: "Events with Attendees",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/certificate-template/"
				component={PageCertTemplate}
				permission="Template"
				breadcrumbs={[
					{
						name: "Certificate Template",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/support/faqs"
				component={PageFaqs}
				permission="Questions"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "FAQs",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/support/ticketing"
				component={PageTicketing}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/support/ticketing/:id"
				component={PageTicketingReply}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: "/event-provider-speaker/support/ticketing",
					},
					{
						name: "Reply",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/support/create-ticket"
				component={PageCreateTicket}
				permission="Ticket"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Create Ticket",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/messages"
				component={PageMessages}
				permission="Messages"
				breadcrumbs={[
					{
						name: "Messages",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/my-account/profile/2fa"
				component={Page2fa}
				permission="Authentication"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Profile",
						link: "/event-provider-speaker/my-account/profile",
					},
					{
						name: "2-Factor Authentication ",
						link: null,
					},
				]}
			/>
			<PrivateRouteEventProviderSpeaker
				exact
				path="/event-provider-speaker/speaker-profile"
				component={PageSpeakerProfile}
				permission="Speaker Profile"
				breadcrumbs={[
					{
						name: "Speaker Profile",
						link: null,
					},
				]}
			/>
			{/* <PrivateRouteEventProviderSpeaker
        exact
        path="/event-provider-speaker/page-pdf"
        component={PagePDF}
        permission="Tickets"
      /> */}
			<Route path="*" exact={true} component={Error404} />
		</Switch>
	);
};

export default RoutePageEventSpeaker;
