import statesCanada from "provinces-ca";
export default function optionStateCodesCanada() {
  let arr = [];
  statesCanada.map((row, key) => {
    // if (
    //   row.name != "Federated States Of Micronesia" &&
    //   row.name != "Marshall Islands" &&
    //   row.name != "Palau" &&
    //   row.name != "Northern Mariana Islands"
    // ) {
    arr.push({
      value: row.abbreviation,
      label: row.name,
    });
    // }
  });

  return arr;
}
