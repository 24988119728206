import React from "react";
import { Layout, Form, Button, Row, Col, Checkbox, Collapse } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import {
  SaveOutlined,
  UpOutlined,
  DownOutlined,
  CreditCardFilled,
} from "@ant-design/icons";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputNumber from "../../../../providers/FloatInputNumber";

export default function PageBankDetails({ props, permission }) {
  const sub_title = "Edit";
  const { Panel } = Collapse;

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };
  const [form] = Form.useForm();

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageBankDetails"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<CreditCardFilled />}
      />

      <Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
        <ComponentFaqs linkVid={"awdawd"} />
        <Form wrapperCol={{ span: 24 }} layout="horizontal">
          <Row gutter={4}>
            <Col xs={24} sm={24} md={16} lg={16}>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Bank Account Details (For Attendee's Payments)"
                  key="1"
                  className="accordion bg-darkgray-form white-space"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="Business Name"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Business Name"
                          placeholder="Business Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="Bank Name"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput label="Bank Name" placeholder="Bank Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="Account Type"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatSelect
                          label="Account Type"
                          placeholder="Account Type"
                          options={[
                            {
                              label: "Checking Account",
                              value: "Checking Account",
                            },
                            {
                              label: "Savings Account",
                              value: "Savings Account",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="Routing Number"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInputNumber
                          label="Routing Number"
                          placeholder="Routing Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="Account Number"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInputNumber
                          label="Account Number"
                          placeholder="Account Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="Confirm Account #"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInputNumber
                          label="Confirm Account #"
                          placeholder="Confirm Account #"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Checkbox.Group>
                      <Row>
                        <Col span={24}>
                          <Checkbox
                            value="A"
                            style={{ lineHeight: "32px" }}
                            checked
                          >
                            Primary Account?
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <br></br>
          <Button
            size="large"
            // className="btn-success modal-save-fill"
            className="btn-login-outline"
            icon={<SaveOutlined />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
