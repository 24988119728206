import React, { useState, useEffect, useRef } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	message,
	Card,
	Form,
	Upload,
	notification,
	Typography,
	Modal,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	ContainerOutlined,
	SearchOutlined,
	PlusCircleOutlined,
	UploadOutlined,
	RedoOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import FloatInput from "../../../../providers/FloatInput";
import FloatInputNumber from "../../../../providers/FloatInputNumber";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatDateTimePicker from "../../../../providers/FloatDateTimePicker";
import getUserData from "../../../../providers/getUserData";
import SendCertificate from "./Components/SendCertificate";
import moment from "moment";
import { Link } from "react-router-dom";
import companyInfo from "../../../../providers/companyInfo";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import { Preview, print } from "react-html2pdf";
// import { saveAs } from "file-saver";

import { jsPDF } from "jspdf";

export default function PageCertificates({ props, permission, history }) {
	const urlParams = new URLSearchParams(window.location.search);
	const myParam = urlParams.get("event_title");
	console.log("title_event", myParam);
	const sub_title = "View All";
	const userdata = getUserData();
	const [currentPage, setCurrentPage] = useState(1);
	const [hours, setHours] = useState(0);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: myParam ? myParam : "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
		user_id: userdata.id,
	});

	const handleTableChange = (pagination, filters, sorter) => {
		console.log("sorter", sorter);
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState(myParam ? myParam : "");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataGetEvent,
		isLoading: isLoadingGetEvent,
		refetch: refetchGetEvent,
		isFetching: isFetchingGetEvent,
	} = GET(
		`api/v1/event_certificate?${$.param(dataTableInfo)}`,
		"event_certificate",
		(res) => {
			if (res.success) {
				console.log(res);
			}
		}
	);

	const {
		data: dataGetEventTotalHours,
		isLoading: isLoadingGetEventTotalHours,
		refetch: refetchGetEventTotalHours,
		isFetching: isFetchingGetEventTotalHours,
	} = GET(
		`api/v1/event_certificate_totalhours?${$.param(dataTableInfo)}`,
		"event_certificate_totalhours",
		(res) => {
			if (res.success) {
				console.log("totalhours", res);
				var hours = 0;
				res.data.map((item, key) => {
					hours = hours + parseFloat(item["ce_hours"]);
				});
				setHours(hours);
			}
		}
	);

	useEffect(() => {
		refetchGetEvent();
		refetchGetEventTotalHours();
		// console.log("dataTableInfo", dataTableInfo);
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		// console.log("current", current);
		// console.log("pageSize", pageSize);
	};

	const [selectedDownload, setSelectedDownload] = useState(null);
	const handleDownload = (record) => {
		console.log("record", record);
		setSelectedDownload(record);
		setPreviewCert(true);
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (selectedDownload !== null) {
				let pdf = new jsPDF("portrait", "pt", "letter");
				var html_pdf = $(".preview-certificate").html();
				pdf.html(html_pdf).then(() => {
					pdf.save(selectedDownload.event.title + ".pdf");
				});
				setSelectedDownload(null);
			}
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [selectedDownload]);

	const onChangeDatesSearch = (val) => {
		setDataTableInfo({
			...dataTableInfo,
			date_from: moment(val.date_from).format("YYYY-MM-DD"),
			date_to: moment(val.date_to).format("YYYY-MM-DD"),
		});
		console.log(val);
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();

	const [fileList, setFileList] = useState([]);
	const onSubmitNonCeliya = (val) => {
		console.log("onSubmitNonCeliya", val);
		const data = new FormData();
		data.append("id", userdata.id);
		data.append("title", val.title);
		data.append("provider", val.provider);
		data.append("date_from", val.date_from.format("YYYY-MM-DD"));
		data.append("date_to", val.date_to.format("YYYY-MM-DD"));
		data.append(
			"event_schedule",
			JSON.stringify({
				datetime_start: val.date_from.format("YYYY-MM-DD HH:mm:ss"),
				datetime_end: val.date_to.format("YYYY-MM-DD HH:mm:ss "),
			})
		);
		data.append("ce_hours", val.ce_credit_hours);
		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append("upload", fileList[0].originFileObj, fileList[0].name);
			}
		}
		mutateAdd(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Successfully Submitted Non CE.LIYA Credits ",
					});
					setFileList([]);
					form.resetFields();
				}
			},
		});
	};

	const { mutate: mutateAdd, isLoading: isLoading } = POST(
		"api/v1/event_certificate_add",
		"event_certificate"
	);

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const [showModalUpload, setShowModalUpload] = useState(false);
	const [showModalUploadData, setShowModalUploadData] = useState({});
	const [fileListUpadte, setFileListUpdate] = useState([]);
	const handleUploadModal = (val) => {
		setShowModalUpload(!showModalUpload);
		setShowModalUploadData(val);
	};

	const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = POST(
		"api/v1/event_certificate_update",
		"event_certificate"
	);

	const handleUpdateUploadCert = () => {
		const data = new FormData();
		data.append("id", showModalUploadData.id);
		if (fileListUpadte.length !== 0) {
			if (fileListUpadte[0].uid != "-1") {
				data.append(
					"upload",
					fileListUpadte[0].originFileObj,
					fileListUpadte[0].name
				);
			}
		}
		mutateUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Successfully Submitted Non CE.LIYA Credits ",
					});
					setFileListUpdate([]);
					setShowModalUpload(false);
				}
			},
		});
	};

	const [previewCert, setPreviewCert] = useState(false);

	const pdfRef = useRef(null);
	const generatePDF = () => {
		// let data = {
		//   pdf: html_pdf,
		// };
		// console.log(data);
		// mutateDownload(data, {
		//   onSuccess: (res) => {
		//     if (res.success) {
		//       console.log("success", res.data);
		//     }
		//   },
		// });
		// saveAs(
		//   "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
		//   "example.pdf"
		// );
		// let doc = new jsPDF("portrait", 'pt', 'letter');
		// pdf.html(data).then(() => {
		//   pdf.save("shipping_label.pdf");
		// });
		// let content = $(".preview-certificate");
		// doc.save("a4.pdf");
		// let content = pdfRef.current;
		// let doc = new jsPDF("portrait", "pt", "letter");
		// doc.html(content, {
		//   callback: function (doc) {
		//     doc.save("sample.pdf");
		//   },
		// });
		// const pdf = new jsPDF("portrait", "pt", "letter");
		// const data = document.querySelector("#preview-certificate");
		// pdf.html(data).then(() => {
		//   pdf.save("shipping_label.pdf");
		// });

		// let data = $(".preview-certificate");

		// pdf.html("<p>Hello world!</p>");
		// pdf.save("a4.pdf");
		// pdf.html(html_pdf, {
		//   callback: function (d) {
		//     d.save();
		//   },
		// });
		// pdf.html(html_pdf);
		// pdf.save("a4.pdf");

		let pdf = new jsPDF("portrait", "pt", "letter");
		var html_pdf = $(".preview-certificate").html();

		pdf.html(html_pdf).then(() => {
			pdf.save("shipping_label.pdf");
		});
	};

	// const { mutate: mutateDownload } = POST("api/v1/download", "download");

	const onChangeUpload = ({ fileList: newFileList }) => {
		var _file = newFileList;
		console.log(_file);
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileList(_file);
		} else {
			setFileList([]);
		}
	};
	const onChangeUpload1 = ({ fileList: newFileList }) => {
		var _file = newFileList;
		console.log(_file);
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileListUpdate(_file);
		} else {
			setFileListUpdate([]);
		}
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageCertificates"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<ContainerOutlined />}
			/>
			<br />
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<Row gutter={24}>
					<Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
						<Card
							title={"Add Non CE.LIYA Credits"}
							className="cardBodyPadding Certificatesheader"
						>
							<Form form={form} onFinish={onSubmitNonCeliya}>
								<Row gutter={12}>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											name="title"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatInput
												label="Event Name"
												placeholder="Event Name"
												rules={[validator.require]}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											name="provider"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatInput
												label="Provider"
												placeholder="Provider"
												rules={[validator.require]}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											name="date_from"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatDateTimePicker
												label={"Date From"}
												// placeholder={"Date and Start Time"}
												mode
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											name="date_to"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatDateTimePicker
												label={"Date To"}
												// placeholder={"Date and Start Time"}
												mode
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											name="ce_credit_hours"
											rules={[validator.require]}
										>
											<FloatInputNumber
												label="Hours Credit"
												placeholder="Hours Credit"
												rules={[validator.require]}
											/>
											{/* <FloatInputMask
                        label="Hours Credit"
                        placeholder="Hours Credit"
                        maskLabel="ce_credit_hours"
                        maskType="99999999"
                      /> */}
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} className="m-b-md">
										<Upload
											size="large"
											action={false}
											customRequest={() => {}}
											maxCount={1}
											className="upload-w-100"
											fileList={fileList}
											beforeUpload={(file) => {
												const isJpgOrPng =
													file.type === "image/jpeg" ||
													file.type === "image/png" ||
													file.type === "image/gif" ||
													file.type === "image/jpg" ||
													file.type === "application/pdf";
												if (!isJpgOrPng) {
													message.error(
														"You can only upload JPG, PNG, GIF, JPEG file!"
													);
													setFileList([]);
													return;
												}
												const isLt2M = file.size / 102400 / 102400 < 10;
												if (!isLt2M) {
													message.error("Image must smaller than 10MB!");
													setFileList([]);
													return;
												}
												return isJpgOrPng && isLt2M;
											}}
											onChange={onChangeUpload}
										>
											<Button
												icon={<UploadOutlined />}
												size={"large"}
												className="w-100"
											>
												Upload Certificate
											</Button>
										</Upload>
									</Col>
									<Col xs={24} sm={24} md={12}>
										<Button
											className="btn-login-outline w-100"
											loading={isLoading}
											icon={<PlusCircleOutlined />}
											size="large"
											htmlType={"submit"}
										>
											Submit
										</Button>
									</Col>
								</Row>
							</Form>
						</Card>
						<br></br>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
						<Card
							title={"Search Dates"}
							className="cardBodyPadding Certificatesheader"
						>
							<Form onFinish={onChangeDatesSearch}>
								<Row gutter={24}>
									<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
										<Form.Item
											name="date_from"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatDatePickerForm
												label={"Date From"}
												// placeholder={"Date and Start Time"}
												mode
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
										{" "}
										<Form.Item
											name="date_to"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatDatePickerForm
												label={"Date To"}
												// placeholder={"Date and Start Time"}
												mode
											/>
										</Form.Item>
									</Col>
								</Row>
								<Button
									className="btn-login-outline search-dates"
									icon={<SearchOutlined />}
									size="large"
									htmlType={"submit"}
								>
									Search Dates
								</Button>
							</Form>
						</Card>
						<br></br>

						<Typography.Title level={3}>
							Results: Total Hours {hours}{" "}
						</Typography.Title>
					</Col>
					{/* <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}></Col> */}
				</Row>
				<br></br>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataGetEvent && dataGetEvent.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<Space>
							<FloatInputSearch
								label="Search..."
								placeholder="Search..."
								dataTableInfo={dataTableInfo}
								setDataTableInfo={setDataTableInfo}
								value={searchText}
								onChange={setSearchText}
							/>
						</Space>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						dataSource={dataGetEvent ? dataGetEvent.data.data : []}
						loading={isLoadingGetEvent}
						rowKey={(record) => record.id}
						className="table-responsive table-thead-login"
						pagination={false}
						size="small"
						onChange={handleTableChange}
					>
						<Table.Column
							key="date_time"
							title="Date"
							dataIndex="date_time"
							sorter
							width={200}
						/>
						<Table.Column
							key="type"
							title="CE. LIYA Event"
							dataIndex="type"
							sorter
							width={150}
						/>
						<Table.Column
							key="event_name"
							title="Name"
							dataIndex="event_name"
							sorter
						/>
						<Table.Column
							key="provider"
							title="Provider"
							dataIndex="provider"
							sorter
							width={200}
						/>
						<Table.Column
							key="ce_hours"
							title="Hours"
							dataIndex="ce_hours"
							sorter
							width={80}
						/>
						<Table.Column
							key="certification"
							title="Certification"
							dataIndex="certification"
							width={120}
							render={(text, record) => {
								if (record.type == "No") {
									return text === "Yes" ? (
										<a
											style={{ cursor: "pointer" }}
											href={companyInfo().apiUrl + record.upload}
											target="_blank"
											download
										>
											Download
										</a>
									) : (
										<>
											{"No - "}
											<Link
												to="#"
												onClick={() => handleUploadModal(record)}
												style={{ cursor: "pointer" }}
											>
												Upload
											</Link>
										</>
									);
								} else {
									return (
										<Link
											to="#"
											onClick={() => handleDownload(record)}
											style={{ cursor: "pointer" }}
										>
											Download
										</Link>
									);
								}
							}}
						/>
						{/* <Table.Column
              key="action"
              title="Action"
              dataIndex="action"
              render={(text, record) => {
                return (
                  <Space>
                    <Button
                      className="btn-warning-outline"
                      onClick={() => handleDownload(record)}
                    >
                      Dowload
                    </Button>
                  </Space>
                );
              }}
            /> */}
					</Table>
				</div>
			</Layout.Content>

			{/* {selectedDownload !== null && (
        <div
          style={{ display: "none", position: "absolute", marginTop: "-500px" }}
        >
          <Preview pageSize={"L"} id={"jsx-template"}>
          <SendCertificate dataCetificate={selectedDownload} />
          </Preview>
        </div>
      )} */}

			{selectedDownload !== null && (
				<div style={{ display: "none" }} className="preview-certificate">
					<SendCertificate dataCetificate={selectedDownload} />
				</div>
			)}

			{/* <Button onClick={(e) => generatePDF()}>download</Button> */}
			{/* <div id="report">asdasdasd</div> */}

			<Modal
				className="table-thead-login"
				title="Upload Certificate"
				visible={showModalUpload}
				onCancel={() => {
					setShowModalUpload(false);
				}}
				// onOk={() => {
				//   handleUpdateUploadCert();
				// }}
				footer={[
					<Button
						onClick={() => {
							setShowModalUpload(false);
						}}
					>
						Cancel
					</Button>,
					<Button
						className="btn-login-outline"
						loading={isLoadingUpdate}
						onClick={() => handleUpdateUploadCert()}
					>
						Submit
					</Button>,
				]}
			>
				<div style={{ display: "contents" }}>
					<Upload
						size="large"
						maxCount={1}
						className="upload-w-100"
						fileList={fileListUpadte}
						beforeUpload={(file) => {
							const isJpgOrPng =
								file.type === "image/jpeg" ||
								file.type === "image/png" ||
								file.type === "image/gif" ||
								file.type === "image/jpg" ||
								file.type === "application/pdf";
							if (!isJpgOrPng) {
								message.error("You can only upload JPG, PNG, GIF, JPEG file!");
								setFileListUpdate([]);
								return;
							}
							const isLt2M = file.size / 102400 / 102400 < 10;
							if (!isLt2M) {
								message.error("Image must smaller than 10MB!");
								setFileListUpdate([]);
								return;
							}
							return isJpgOrPng && isLt2M;
						}}
						onChange={onChangeUpload1}
					>
						<Button icon={<UploadOutlined />} size={"large"} className="w-100">
							Upload Certificate
						</Button>
					</Upload>
				</div>
			</Modal>

			{/* <Modal
        title="Preview Certificate"
        className="table-thead-login preview-certificate-modal"
        visible={previewCert}
        width={816}
        bodyStyle={{ padding: "0px !important" }}
        footer={[
          <Button
            className="btn-login-outline"
            loading={isLoadingUpdate}
            onClick={() => generatePDF()}
          >
            Print
          </Button>,
        ]}
      >
        {selectedDownload !== null && (
          <div
            style={{ display: "none" }}
            className="preview-certificate"
          >
            <SendCertificate dataCetificate={selectedDownload} />
          </div>
        )}
      </Modal> */}
		</Layout>
	);
}
