import React, { useState, useEffect } from "react";
import { Layout, Input, Button, Row, Col, Table, Pagination } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { PlusOutlined, TableOutlined } from "@ant-design/icons";
import $ from "jquery";
import moment from "moment";
import { GET } from "../../../../providers/useAxiosQuery";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";

export default function PageViewEventsAttendees({ props, permission }) {
	const sub_title = "View My";
	const history = useHistory();

	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataTickets,
		refetch: refetchTickets,
		isLoading: isLoadingTickets,
		isFetching: isFetchingTickets,
	} = GET(`api/v1/tickets?${$.param(dataTableInfo)}`, `tickets`, (res) => {
		if (res.success) {
			console.log(res);
		}
	});

	useEffect(() => {
		refetchTickets();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});

		setCurrentSize(pageSize);
		setCurrentPage(current);
		console.log("current", current);
		console.log("pageSize", pageSize);
	};
	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
		setCurrentSize(dataTableInfo.page_size);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageTicketing"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<TableOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br></br>
				{/* <Row>
          <Col md={8} xs={0}>
            <Button
              // size="large"
              className="btn-login-outline"
              style={{ width: 200 }}
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={(e) => history.push("/admin/support/create-ticket")}
            >
              Start New Ticket
            </Button>
          </Col>
        </Row> */}
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataTickets && dataTickets.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<Table
					className="table-responsive table-thead-login"
					size="small"
					rowKey={(record) => record.id}
					loading={isLoadingTickets}
					dataSource={dataTickets ? dataTickets.data.data : []}
					pagination={false}
					onChange={handleTableChange}
				>
					<Table.Column
						key="subject"
						title="Subject & Description"
						dataIndex="subject"
						sorter
					/>
					<Table.Column
						key="reply"
						title="View/Reply"
						render={(text, record) => {
							return (
								<Link
									to={"/admin/support/ticketing/" + record.id}
									style={{ color: "#d6ae71" }}
								>
									<FontAwesomeIcon
										className="c-lightorange"
										icon={faReply}
										style={{ marginRight: "5px" }}
									/>
								</Link>
							);
						}}
					/>
					<Table.Column
						key="created_at_str"
						title="Created"
						dataIndex="created_at_str"
						sorter
						render={(text, record) => {
							return text;
						}}
					/>
					<Table.Column
						key="requester"
						title="Requester"
						dataIndex="requester"
						sorter
						render={(text, record) => {
							return record.requeter_user
								? record.requeter_user.first_name
								: "";
						}}
					/>
					<Table.Column
						key="asssigned_user"
						title="Assigned"
						dataIndex="asssigned_user"
						sorter
					/>
					<Table.Column
						key="status"
						title="Status"
						dataIndex="status"
						sorter
						render={(text, record) => {
							return record.status;
						}}
					/>
					<Table.Column
						key="id"
						title="Ticket ID"
						dataIndex="id"
						sorter
						render={(text, record) => {
							return "#" + record.id;
						}}
					/>
					<Table.Column
						key="priority"
						title="Priority"
						dataIndex="priority"
						sorter
						render={(text, record) => {
							return record.priority;
						}}
					/>
				</Table>
			</Layout.Content>
		</Layout>
	);
}
