import React, { useState, useEffect } from "react";
import {
	Layout,
	Form,
	Button,
	Row,
	Col,
	Collapse,
	notification,
	Tabs,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	SaveOutlined,
	UpOutlined,
	DownOutlined,
	CreditCardFilled,
} from "@ant-design/icons";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
// import FloatTextarea from "../../../../providers/FloatTextArea";
import { GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import TableAccountTypesPlan from "./Component/TableAccountTypesPlan";
import TableFaqs from "./Component/TableFaqs";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

export default function PageAccountType({ match, props, permission }) {
	const sub_title = "VIEW/EDIT";
	const { TabPane } = Tabs;
	const getACID = (type) => {
		if (type === "event") {
			return 2;
		} else if (type === "member") {
			return 1;
		} else if (type === "venue") {
			return 3;
		} else if (type === "speaker") {
			return 4;
		} else if (type === "event-provider-speaker") {
			return 5;
		}
	};
	let type = match.url.split("/");
	console.log(type);
	let id = getACID(type[3]);
	const { Panel } = Collapse;

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			refetchAccountTypes();
		});
		return () => {
			clearTimeout(timeoutId);
		};
	}, [id]);
	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];
	const modulesToolBar = {
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			// ["link", "image", "video"],
			// ["clean"],
		],
		imageResize: {
			modules: ["Resize", "DisplaySize"],
		},
	};
	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();
	const [accTypeRole, setAccountTypeRole] = useState("");

	const {
		data: dataGetAccountType,
		isLoading: isLoadingGetAccountType,
		refetch: refetchAccountTypes,
	} = GETMANUAL(`api/v1/account_type/${id}`, "account_type", (res) => {
		console.log("dataGetAccountType", res);
		if (res.success) {
			setAccountTypeRole(res.data.account_type);
			form.setFieldsValue({
				account_type: res.data.account_type,
				description: res.data.description,
				privacy_policy: res.data.privacy ? res.data.privacy.privacy_policy : "",
				member_purchase_policy: res.data.privacy
					? res.data.privacy.member_purchase_policy
					: "",
			});
			if (res.data.privacy && res.data.privacy.privacy_policy == "") {
				form.resetFields(["privacy_policy"]);
			}
		}
	});

	const { mutate: mutateAccountType, isLoading: isLoadingAccountType } = POST(
		"api/v1/account_type",
		"account_type"
	);

	const onFinish = (value) => {
		let data = {
			...value,
			id: id,
		};
		mutateAccountType(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Successfully Submited",
					});
				}
			},
		});
	};

	function callback(key) {
		console.log(key);
	}

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageAccountType"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CreditCardFilled />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br />
				<Tabs defaultActiveKey="1" type="card" onChange={callback}>
					<TabPane tab="Account Type and Policy" key="1">
						<Form
							form={form}
							onFinish={onFinish}
							wrapperCol={{ span: 24 }}
							layout="vertical"
						>
							<Row gutter={4}>
								<Col xs={24} sm={24} md={14} lg={14}>
									<Collapse
										expandIcon={({ isActive }) =>
											isActive ? (
												<span
													className="ant-menu-submenu-arrow"
													style={{ color: "#FFF", transform: "rotate(270deg)" }}
												></span>
											) : (
												<span
													className="ant-menu-submenu-arrow"
													style={{ color: "#FFF", transform: "rotate(90deg)" }}
												></span>
											)
										}
										expandIconPosition="right"
										defaultActiveKey={["1"]}
									>
										<Panel
											header="Account Type Details"
											key="1"
											className="accordion bg-darkgray-form"
										>
											<Row gutter={24}>
												<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
													{/* <Form.Item
                        name="account_type"
                        rules={[validator.require]}
                        className="capslock form-select-error"
                        hasFeedback
                      >
                        <FloatSelect
                          label="Account Type"
                          placeholder="Account Type"
                          options={accountTypeDate}
                        />
                      </Form.Item> */}
													<Form.Item
														name="account_type"
														rules={[validator.require]}
														className="capslock"
														hasFeedback
													>
														<FloatInput
															label="Account Type"
															placeholder="Account Type"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
													<Form.Item
														name="description"
														label="Description"
														rules={[validator.require]}
													>
														{/* <FloatTextarea
                          label="Description"
                          placeholder="Description"
                        /> */}
														<ReactQuill
															theme="snow"
															style={{ height: 200 }}
															formats={formats}
															modules={modulesToolBar}
														/>
													</Form.Item>
													<br />
												</Col>
											</Row>
										</Panel>
									</Collapse>
									<br />
								</Col>
								<Col xs={24} sm={24} md={10} lg={10}>
									<Collapse
										expandIcon={({ isActive }) =>
											isActive ? (
												<span
													className="ant-menu-submenu-arrow"
													style={{ color: "#FFF", transform: "rotate(270deg)" }}
												></span>
											) : (
												<span
													className="ant-menu-submenu-arrow"
													style={{ color: "#FFF", transform: "rotate(90deg)" }}
												></span>
											)
										}
										expandIconPosition="right"
										defaultActiveKey={["1"]}
									>
										<Panel
											header="Privacy Policy"
											key="1"
											className="accordion bg-darkgray-form"
										>
											<Row gutter={24}>
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
													<Form.Item
														name="privacy_policy"
														label="Policy"
														rules={[validator.require]}
													>
														{/* <FloatTextarea label="Policy" placeholder="Policy" />
														 */}
														<ReactQuill
															theme="snow"
															style={{ height: 200 }}
															formats={formats}
															modules={modulesToolBar}
														/>
													</Form.Item>
													<br />
													<br />
													<br />
												</Col>
											</Row>
										</Panel>
									</Collapse>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={24} sm={24} md={8} lg={8}>
									<br />
									<Button
										size="large"
										className="btn-login-outline"
										style={{ width: 200 }}
										icon={<SaveOutlined />}
										onClick={() => form.submit()}
										loading={isLoadingAccountType}
									>
										Submit
									</Button>
								</Col>
							</Row>
						</Form>
					</TabPane>
					<TabPane tab="Plans" key="2">
						<TableAccountTypesPlan id={id} />
					</TabPane>
					<TabPane tab="FAQ's" key="3">
						<TableFaqs id={id} />
					</TabPane>
					{accTypeRole == "MEMBER" && (
						<TabPane tab="Purchase Policy" key="4">
							<Form
								form={form}
								onFinish={onFinish}
								wrapperCol={{ span: 24 }}
								layout="vertical"
							>
								{" "}
								<Row>
									<Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
										<Collapse
											expandIcon={({ isActive }) =>
												isActive ? (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(270deg)",
														}}
													></span>
												) : (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(90deg)",
														}}
													></span>
												)
											}
											expandIconPosition="right"
											defaultActiveKey={["1"]}
										>
											<Panel
												header="Purchase Policy"
												key="1"
												className="accordion bg-darkgray-form"
											>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="member_purchase_policy"
															label="Policy"
															rules={[validator.require]}
														>
															{/* <FloatTextarea label="Policy" placeholder="Policy" />
															 */}
															<ReactQuill
																theme="snow"
																style={{ height: 200 }}
																formats={formats}
																modules={modulesToolBar}
															/>
														</Form.Item>
														<br />
													
													</Col>
												</Row>
											</Panel>
										</Collapse>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={24} sm={24} md={8} lg={8}>
										<br />
										<Button
											size="large"
											className="btn-login-outline"
											style={{ width: 200 }}
											icon={<SaveOutlined />}
											onClick={() => form.submit()}
											loading={isLoadingAccountType}
										>
											Submit
										</Button>
									</Col>
								</Row>
							</Form>
						</TabPane>
					)}
				</Tabs>
			</Layout.Content>
			{/* <TablePolicy id={id} /> */}
		</Layout>
	);
}
