import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  notification,
} from "antd";
import image1 from "../../../../../assets/img/image1.jpg";
import image2 from "../../../../../assets/img/social_media.png";

import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import { GET, GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";

import companyInfo from "../../../../../providers/companyInfo";
import moment from "moment";
import {
  HomeOutlined,
  SaveOutlined,
  FieldTimeOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  UserOutlined,
  StarOutlined,
  EyeOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  EditOutlined,
} from "@ant-design/icons";
import QrReader from "react-qr-reader";
import { FaTimes } from "react-icons/fa";
export default function ModalQRCodeReader({
  setShowModalQrCodeReader,
  showModalQrCodeReader,
  refetchGetEvent,
  refetchGetEventWaiting,
  match,
}) {
  const [data, setData] = useState("No result");

  const { mutate: mutateUpdateStatus, isLoading: isLoadingMutateUpdateStatus } =
    POST(
      "api/v1/event_with_attendees/updateStatusQR",
      "event_with_attendee_status"
    );

  const handleScanErrorQrCode = (e) => {};
  const handleScanQrCode = (e) => {
    if (e != null) {
      const user_id = e.split(",");
      const _user_id = user_id[1].split(":");

      var _data = {
        event_id: match.params.id,
        status: "Attended",
        user_id: _user_id[1],
      };
      mutateUpdateStatus(_data, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description:
                "Scanned Successfully! " +
                res.data.first_name +
                " " +
                res.data.last_name +
                " Attended on this Event",
            });
            refetchGetEvent();
            refetchGetEventWaiting();
            setShowModalQrCodeReader(false);
          } else {
            notification.error({
              message: "Error",
              description: "Can't Find this User on the Registered List",
            });
            setShowModalQrCodeReader(false);
          }
        },
        onError: (err) => {},
      });
    }
  };
  return (
    <Modal
      visible={showModalQrCodeReader}
      width={500}
      className="modalPreview"
      onCancel={() => {
        setShowModalQrCodeReader(false);
      }}
      style={{ top: 20 }}
      footer={[
        <Button
          onClick={() => {
            setShowModalQrCodeReader(false);
          }}
        >
          Cancel
        </Button>,
      ]}
      // closeIcon={<FaTimes className="modal-close-icon-black" />}
    >
      <Card title={"QR Code Reader"}>
        <QrReader
          onError={handleScanErrorQrCode}
          onScan={handleScanQrCode}
          style={{
            height: "100%",
            width: "100%",
            margin: "0 auto",
          }}
        />
      </Card>
    </Modal>
  );
}
