import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Layout, Card, Typography } from "antd";
import { BookOutlined } from "@ant-design/icons";
import ComponentHeader from "../Member/Components/ComponentHeader";
import { Link } from "react-router-dom";
import { GiThorHammer } from "react-icons/gi";

const ComponentPrivacy = ({ permission, icon }) => {
  const sub_title = "Privacy";

  useEffect(() => {
    scrollView();
  }, []);

  const divScroll = useRef(null);

  const scrollView = () => {
    divScroll.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <Layout
      className="site-layout-background ant-page-privacy-policy"
      style={{
        padding: "20px 0px 20px 0px",
        background: "#fff",
      }}
      ref={divScroll}
      id="ComponentPrivacy"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<GiThorHammer />}
      />

      <Row>
        <Col xs={24} sm={24} md={16}>
          {" "}
          <Card bordered={false}>
            <br />
            <Row gutter={24}>
              <Col md={24}>
                <Typography.Title
                  level={4}
                  style={{ lineHeight: 0, color: "#293a56" }}
                  className=" font-family-roboto tx-normal TypoClassLine"
                >
                  Privacy Policy
                </Typography.Title>
              </Col>
            </Row>
            <br />

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  Last updated: [May 10, 2021]
                </p>
                <p className="lead lead-font-size-new">
                  We at ce.LIYA, LLC (“Company”, “we”, or “us”) are committed to
                  protecting your privacy through this “Privacy Policy.” This
                  Privacy Policy describes our policies and procedures on the
                  collection, use and disclosure of your personal information
                  when you visit or use the Company website, https://celiya.com,
                  or any of our social media channels (collectively the
                  “Website”).
                </p>
                <p className="lead lead-font-size-new">
                  Please carefully read the terms of the Privacy Policy. By
                  using the Website, you agree to the terms of the Privacy
                  Policy, if you do not agree with the terms of the Privacy
                  Policy, please do not use this Website.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Children Under The Age Of 13.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  Our Website and services are not intended for anyone under the
                  age of 13. We do not knowingly collect information from anyone
                  under the age of 13. If you are under 13, do not use this
                  Website or provide us with any information about yourself,
                  including your name or email address. If we learn that we have
                  collected or received personal information from a child under
                  13, we will delete that information. If you believe we might
                  have any information from or about a child under 13, please
                  contact us at info@celiya.com
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Types of Information We Collect
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    1. Information You Provide to Us.
                  </span>{" "}
                  Here are some examples of information we collect from
                  information that you voluntarily provide us. Please note we
                  collect this personal identifiable information from you, only
                  if you voluntarily submit such information to us. You can
                  always use this Website anonymously.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    a. Webforms and Subscriptions.
                  </span>{" "}
                  If you elect, you may subscribe to our newsletter or fill out
                  a form on our Website so that we can send you email
                  communications about the requested information, products,
                  and/or services. Such personally identifiable information
                  includes but is not limited to your name, email address, phone
                  number, or any other information you may choose to provide.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    b. Communications.
                  </span>{" "}
                  We may invite you to complete a survey, provide feedback, or
                  participate in a promotion (like a giveaway), either through
                  our Website or a third-party platform. If you participate, we
                  will collect and store the data you provide us as part of your
                  participation, such as your name and email address and any
                  other requested feedback. If you submit this information via a
                  third-party platform, the collection, use, and sharing of your
                  data will also be subject to the privacy policies and other
                  agreements of that third party.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    c. Giveaways, Promotions, and Surveys.
                  </span>{" "}
                  We may invite you to complete a survey, provide feedback, or
                  participate in a promotion (like a giveaway), either through
                  our Website or a third-party platform. If you participate, we
                  will collect and store the data you provide us as part of your
                  participation, such as your name and email address and any
                  other requested feedback. If you submit this information via a
                  third-party platform, the collection, use, and sharing of your
                  data will also be subject to the privacy policies and other
                  agreements of that third party.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    d. Shared Public Content.
                  </span>{" "}
                  Our Website permits you to publicly interact with us and other
                  users, share content, post comments, and ask or answer
                  questions. Such shared content may be publicly viewable by
                  others depending on where it is posted. If such information is
                  shared on a third-party platform, such as a social media site,
                  the collection, use, and sharing of your data will also be
                  subject to the privacy policies and related agreements of that
                  third party.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    e. Registration.
                  </span>{" "}
                  When you create an account, we may ask you for the following
                  information including but not limited to, your name, email
                  address, phone number, and any other information you choose to
                  provide.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    f. Appointment.
                  </span>{" "}
                  When you schedule an appointment, we may ask you for the
                  following information including but not limited to, your name,
                  email address, phone number, business information and any
                  other information you choose to provide.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    g. Payment Information.
                  </span>{" "}
                  If you purchase a service or product, we collect data
                  regarding which services or products you’ve purchased and how
                  much you’ve paid for those services or products. We collect
                  certain data about your purchase as necessary to process your
                  request. You must provide certain payment and billing data
                  directly to our third-party payment processing partners,
                  including your name, credit card number, security code,
                  expiration date, billing address, and zip code. For your
                  security, the Company does not have access to or store
                  sensitive cardholder data, such as full credit card numbers or
                  card authentication data.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    h. Online Courses.
                  </span>{" "}
                  In order to enroll in one of our online courses, you must
                  provide certain information like your name and email address
                  so that we can communicate information about attending the
                  online course. We collect data regarding which online
                  course(s) you enroll in, whether you attend the course and if
                  so, for how long, whether you purchased a product or service
                  during an online course, as well as any feedback in the form
                  of responses to surveys and/or chat features that may be
                  enabled for an online course. Such online webinars are hosted
                  via third-party platforms, therefore, the collection, use, and
                  sharing of your data will also be subject to the privacy
                  policies and related agreements of that third party.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    2. Information We Collect Automatically.
                  </span>{" "}
                  As you visit our Website, including email communications, we
                  may use automatic data collection technologies to collect
                  non-personal identification information. Nonpersonal
                  identification information may include but is not limited to
                  certain information about your equipment, browsing action,
                  click patterns, mobile device data, operating system, your IP
                  address, browser type, domain name, access time and referring
                  website address. The tracking technologies we use include the
                  following:
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    a. Cookies.
                  </span>{" "}
                  A cookie is a data text file sent from a website to your
                  browser. It may be stored within your system. We use
                  non-identifying cookies to provide easier site navigation. Our
                  cookies do not generate personal data, do not read personal
                  data from your machine and are never tied to anything that
                  could be used to identify you. Information gathered through
                  cookies may include the date and time of visits, the pages
                  viewed, time spent at our site, and the websites visited just
                  before and just after our own, as well as your IP address. By
                  tracking usage, we can best determine the needs of our
                  customers.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    b. Clickstream Data.
                  </span>{" "}
                  Through website access logs, we do collect clickstream data
                  and HTTP protocol elements, which generate certain kinds of
                  non-identifying site usage data, such as the number of hits
                  and visits to our Website. This information is used for
                  internal purposes for research and development, user analysis
                  and business decision making, all of which provides better
                  services to our users. The statistics garnered, which contain
                  no personal information and cannot be used to gather such
                  information, may also be provided to third parties for data
                  processing.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p
                  className="lead lead-font-size-new"
                  style={{ marginLeft: 55 }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    c. Pixels and Web Beacons.
                  </span>{" "}
                  We employ a software technology called tracking pixels (a/k/a
                  web beacons/web bugs/clear gifs) that enables us to better
                  manage the content on the Website by informing us what content
                  is effective. Tracking pixels are tiny graphics with a unique
                  identifier, similar in function to cookies, and are used to
                  track the online movements of visitors to a website. In
                  contrast to cookies, which are stored on a user’s computer
                  hard drive, tracking pixels are embedded invisibly on website
                  pages and are about the size of the period at the end of a
                  sentence. We do not tie the information gathered by tracking
                  pixels to your personally identifiable information. We use
                  tracking pixels in our e-mails to let us know which e-mails
                  have been opened by recipients. This allows us to gauge the
                  effectiveness of certain communications and the effectiveness
                  of our marketing campaigns. If you would like to opt-out of
                  these e-mails, please see the “Marketing Communications”
                  section of this Privacy Policy below.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  How We Use Your Information.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    1. To Improve Our Website and User Experience.
                  </span>{" "}
                  We continually strive to improve our Website and business
                  offerings, based on the information and feedback we receive
                  from you.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    2. To Provide and Process Services or Goods.
                  </span>{" "}
                  We may use information to provide the services or products the
                  you have requested. As well as keep you informed of the same.
                  These services may include services provided by third parties.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    3. To Send Periodic Emails.
                  </span>{" "}
                  If you opt-in to communications on the Website, the email
                  address you provide, may be used to send you information
                  pertaining to our products or services, occasional company
                  news, updates or promotions.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    4. To Administer a Contest, Promotion, Survey or Other
                    Website Feature.
                  </span>{" "}
                  We may invite you to complete a survey, provide feedback, or
                  participate in a promotion (like a giveaway). If you
                  participate, we will collect and store the data you provide us
                  as part of your participation, such as your name and email
                  address and any other requested information.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    5. Communication.
                  </span>{" "}
                  Communicate about and administer our products, services,
                  events, online courses, podcasts, programs and promotions.
                  Communicate with you and respond to your inquiries and
                  comments.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    6. Conduct Marketing and Sales Activities.
                  </span>{" "}
                  We may use your information to generate lead
                  lead-font-size-news, pursue marketing prospects, perform
                  market research, determine and manage the effectiveness of our
                  advertising and marketing campaigns and manage our brand.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    7. Fraud.
                  </span>{" "}
                  To protect against unauthorized transactions, including
                  identifying potential unauthorized users and/or hackers.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    8. Payments.
                  </span>{" "}
                  To facilitate transactions and payments.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    9. Account.
                  </span>{" "}
                  Create and manage your account.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  How We Share Your Information.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    1. Third-Party Service Providers.
                  </span>{" "}
                  We use third-party service providers to collect and process
                  personal information from or about you on our behalf. Examples
                  include data analysis, marketing and advertising services,
                  email and hosting services, customer services and support,
                  hosting user accounts, processing credit card payments, and
                  collecting customer research or satisfaction surveys. They
                  have access to the personal information needed to perform
                  their functions.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    2. Third Party App Integrations.
                  </span>{" "}
                  We may provide you with direct access to functionality from
                  other third parties, including Facebook and Instagram. The
                  placement of social media widgets, or advertising units on the
                  Website may permit these third parties to see information
                  about you and your activities via cookies, web beacons and
                  other technologies they place and/or access on your browser or
                  device, even if you do not interact with them.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    3. Online Advertising Services.
                  </span>{" "}
                  We may use third-party advertising services, like Facebook and
                  Instagram, to deliver advertising about our products and
                  services on our Website, as well as other websites and
                  applications you use. The ads may be based on information we
                  have collected (as outlined above) and information these
                  advertising providers know about you based on their tracking
                  data. The ads can be based on your recent activity or activity
                  over time and across other sites and services and may be
                  tailored to your interests. For more information about
                  interest-based ads, and to learn about options for opting out
                  of having your web browsing information used for behavioral
                  advertising purposes, please visit www.aboutads.info/choices
                  or, if you are in the EU, www.youronlinechoices.eu/.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    4. Contractors or Employees.
                  </span>{" "}
                  We may share your information with trusted third parties who
                  assist us in operating our Website, conducting our business,
                  or servicing you, so long as those parties agree to keep this
                  information confidential.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    5. Third Party Analytic Services.
                  </span>{" "}
                  We may use third-party browser and mobile analytics services
                  like Google Analytics. These services use tools to help us
                  analyze your use of the Website, including information like
                  the third-party website you arrive from, how often you visit,
                  events within the Website, usage and performance data, and
                  purchasing behavior. We use this data to improve the Website
                  and provide information, products and services that may be of
                  interest to you.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    6. Companies Under Same Ownership.
                  </span>{" "}
                  We may share your information with our affiliates, in which
                  case we will require those affiliates to honor this Privacy
                  Policy. Affiliates include our parent company and any
                  subsidiaries, joint venture partners or other companies that
                  we control or that are under common control with us.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    7. As Required By Law.
                  </span>{" "}
                  We may access, preserve, and disclose your information if we
                  believe doing so is required or appropriate to: (i) comply
                  with law enforcement requests and legal process, such as a
                  court order or subpoena or other lawful requests by public
                  authorities, including to meet national security or law
                  enforcement requirements; (ii) respond to your requests; or
                  (iii) protect your, our, or others' rights, property, or
                  safety. For the avoidance of doubt, the disclosure of your
                  information may occur if you post any objectionable content on
                  or through the Website.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    8. Change of Ownership.
                  </span>{" "}
                  We may transfer your information to service providers,
                  advisors, potential transactional partners, or other third
                  parties in connection with the consideration, negotiation, or
                  completion of a corporate transaction in which we are acquired
                  by or merged with another company or we sell, liquidate, or
                  transfer all or a portion of our assets. The use of your
                  information following any of these events will be governed by
                  the provisions of this Privacy Policy in effect at the time
                  the applicable information was collected.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    9. Consent.
                  </span>{" "}
                  We may also disclose your information with your permission or
                  at your direction.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                {/* <Typography.Title
                  level={4}
                  style={{
                    lineHeight: 0,
                    color: "#293a56",
                    textDecoration: "underline",
                  }}
                  className=" font-family-roboto tx-normal TypoClassLine"
                >
                  DO NOT TRACK.
                </Typography.Title> */}
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Do Not Track.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  When you use the Website, we may receive “do not track”
                  requests from you, whether via signals from web browsers or
                  other mechanisms. At this time, we do not respond to such “do
                  not track” requests, although we may choose to do so in the
                  future.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Your Rights.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  You have the rights in relation to personal information we
                  hold about you. Below is an outline of those rights and how to
                  exercise those rights. Please note that we may require you to
                  verify your identity before responding to any requests. Please
                  note that for each of the rights below we may have valid legal
                  reasons to refuse your request, in such instances we will let
                  you know if that is the case. You can choose not to provide
                  certain data to us, but you may not be able to use certain
                  features of the Website.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    1. Marketing Communications.
                  </span>{" "}
                  By submitting your name and e-mail address to us, you opt-in
                  to receiving email from us. You may opt-out of receiving
                  further e-mail by contacting us at info@celiya.com or
                  following the directions in any email or elsewhere on this
                  Website.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    2. Transactional Or Service Email Communications.
                  </span>{" "}
                  Some communications from us are considered transactional or
                  service communications (for example, important account
                  notifications, product updates, and billing information), and
                  your purchase of products and services are provided to you
                  upon the condition that you receive these communications from
                  us. If you do not wish to receive any transactional or service
                  communications from us, you must not purchase any products or
                  services from the Website and/or cancel your user account.{" "}
                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    Members can delete their user account at anytime, however
                    all membership fees paid are final sale and not refundable.
                  </span>
                  <br />
                  Please note that you may still receive marketing
                  communications from us unless you also opt-out of our
                  marketing communications, as described above.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    3. Google Analytics.
                  </span>{" "}
                  We use Google Analytics to collect and process certain
                  analytics data. These services may also collect information
                  about your use of other websites, apps, and online resources.
                  You can learn about Google's practices by going to
                  https://www.google.com/policies/privacy/partners/, and opt-out
                  of them by downloading the Google Analytics opt-out browser
                  add-on, by going to https://tools.google.com/dlpage/gaoptout.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    4. Information From Third Parties.
                  </span>{" "}
                  To manage the information we receive about you from a social
                  media site or other third parties, you will need to follow the
                  instructions from that party for updating your information and
                  changing your privacy settings, where available. The
                  information we collect is covered by this privacy policy and
                  the information the third-party collects is subject to such
                  third party’s privacy practices. Privacy choices you have made
                  on any third-party site will not apply to our use of the
                  information we have collected directly through our Website.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    5. Cookies.
                  </span>{" "}
                  Most web browsers are set to accept cookies by default. If you
                  prefer, you can usually choose to set your browser to remove
                  cookies and to reject cookies. If you choose to remove cookies
                  or reject cookies, this could affect certain features or
                  services of our Sites. To opt-out of interest-based
                  advertising by advertisers on our Site visit
                  http://www.aboutads.info/choices/.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Keeping Your Information Secure.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  The transmission of information via the internet or email is
                  not completely secure. Although we will do our best to protect
                  your personal information, we cannot guarantee the security of
                  your information transmitted through the Website or over
                  email; any transmission is at your own risk.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Accessing, Updating, Transferring And Deleting Your Data.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  To update your personal information, please contact us at
                  info@celiya.com. Where you request correction, please explain
                  in detail why you believe the personal information we hold
                  about you, to be inaccurate or incomplete so that we can
                  assess whether a correction is required. If you wish to access
                  any personal information, we hold about you, or to request
                  that we delete or transfer any information about you that we
                  have obtained, you may contact us at info@celiya.com. Please
                  allow up to 30 days for a response. For your protection, we
                  may require that the request be sent through the email address
                  associated with your account, and we may need to verify your
                  identity before implementing your request. Please note that we
                  retain certain data where we have a lawful basis to do so,
                  including for mandatory record-keeping and to complete
                  transactions.
                  <br />
                  <br />
                  At any time, you may object to the processing of your personal
                  information, on legitimate grounds, except if otherwise
                  permitted by applicable law. If you believe your right to
                  privacy granted by applicable data protection laws has been
                  infringed upon, please contact us at info@celiya.com. You also
                  may have a right to lodge a complaint with data protection
                  authorities.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Legal Basis For Processing Data.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  We will only process personal data about in accordance with
                  the following legal basis:
                </p>
              </Col>

              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    1. Necessity.
                  </span>{" "}
                  As necessary to perform our responsibilities under our
                  contract with you. For example, by processing your order and
                  delivering the products you purchase.
                </p>
              </Col>

              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    2. Legitimate Interest.
                  </span>{" "}
                  When we have a legitimate interest in processing your personal
                  data. For example, to communicate with you about changes to
                  our products or services or legal documents, to prevent fraud,
                  or to provide, protect and improve our products and services.
                  We only rely on our legitimate interests to process your
                  personal information when these interests are not overridden
                  by your rights and interests.
                </p>
              </Col>

              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    3. Consent.
                  </span>{" "}
                  If we request your consent to a specific processing activity
                  (such as to send marketing emails), and you provide your
                  consent in the manner indicated; and
                </p>
              </Col>

              <Col md={23} offset={1}>
                <p className="lead lead-font-size-new">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#293a56",
                    }}
                  >
                    3. Legal Obligation.
                  </span>{" "}
                  In some cases, processing will be necessary for compliance
                  with a legal obligation, such as response to legal process
                  requests
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Third-Party Sites.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  The Website may contain features or links to web sites and
                  services provided by third parties. Any information you
                  provide on third-party sites or services is provided directly
                  to the operators of such services and is subject to those
                  operators’ policies, if any, governing privacy and security,
                  even if accessed through the Website. We are not responsible
                  for the content or privacy and security practices and policies
                  of third-party sites or services to which links or access are
                  provided through the Website. We encourage you to learn about
                  third parties’ privacy and security policies before providing
                  them with information.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Data Transfers.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  In order for us to provide the products and services to you,
                  your personal information will be stored and processed in the
                  United States. Where data is shared with third-party data
                  processors, your data will be captured, transferred, stored
                  and processed in accordance with their policies, practices and
                  in compliance with their local regulatory laws.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Data Retention.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  We store the information we collect about you for as long as
                  is necessary for the purposes for which we originally
                  collected it. We may retain certain information for legitimate
                  business purposes or as required by law. If you wish to delete
                  your information, please contact us at info@celiya.com Absent
                  exceptional circumstances (like where I am required to store
                  data for legal reasons) we will generally delete your personal
                  information upon request.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Notice Of Privacy Rights To California Residents.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  If you are a California resident, you have the right to
                  request certain details about what personal information we
                  share with third parties (if any) for those third parties’
                  direct marketing purposes. You may opt out of such future
                  sharing of your personal information (as defined by California
                  Shine the Light Act) and/or request information about our
                  compliance with this law. To submit your request, send an
                  email to info@celiya.com with the phrase “California Shine the
                  Light” and include your mailing address, state of residence,
                  zip code and email address. We are not responsible for notices
                  that are not labeled or sent properly, or do not have complete
                  information.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  International Privacy Laws And Your Rights Under The GDPR.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  If you are visiting the Website from outside the United
                  States, please be aware that you are sending information to
                  the United States where my servers are located. Information
                  you submit may then be transferred within the United States or
                  back out of the United States to other countries outside of
                  your country of residence, depending on the type of
                  information and how it is stored by us. These countries
                  (including the United States) may not necessarily have data
                  protection laws as comprehensive protective as your country of
                  residence; however, my collection, storage, and use of your
                  data will at all times continue to be governed by this Privacy
                  Policy.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Changes To This Privacy Policy.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  We may modify, amend or otherwise change this Privacy at any
                  time in our sole discretion, without your consent and without
                  prior notice. If we make changes, we will notify you by
                  revising the date at the top of this Privacy Policy, and in
                  some cases, we may provide you with additional notice (such as
                  sending you an email notification). We encourage you to review
                  the Privacy Policy whenever you interact with us to stay
                  informed about our information practices and the ways you can
                  help protect your privacy.
                  <br />
                  <br />
                  Your continued use of the Website after the revised Privacy
                  Policy has become effective indicates that you have read,
                  understood and agreed to the current version of the Privacy
                  Policy.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new lead-font-size-new-bold">
                  Contacting Us.
                </p>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col md={24}>
                <p className="lead lead-font-size-new">
                  If you have any questions about this Privacy Policy, the
                  practices of this Website, or your dealings with this Website,
                  please contact us at info@celiya.com.
                </p>
              </Col>
            </Row>
            {/* ---------------------- */}
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default ComponentPrivacy;
