import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRouteVenueProvider from "./PrivateRouteVenueProvider";

// venue PROVIDER
import PageVenueDashboard from "../views/private/VenueProvider/PageVenueDashboard/PageVenueDashboard";
import PageVenueMyCompany from "../views/private/VenueProvider/PageMyAccount/PageMyCompany";
import PageVenueProfile from "../views/private/VenueProvider/PageMyAccount/PageProfile";
import PageVenueBankDetails from "../views/private/VenueProvider/PageMyAccount/PageBankDetails";
import PageVenueSubscription from "../views/private/VenueProvider/PageMyAccount/PageSubscription";
import PageVenueSubscriptionUpgrade from "../views/private/VenueProvider/PageMyAccount/PageSubscriptionUpgrade";
import PageVenueInvoices from "../views/private/VenueProvider/PageMyAccount/PageInvoices";
import PageVenueInvoicesView from "../views/private/VenueProvider/PageMyAccount/PageInvoicesView";
import PageVenueAttendeesPayments from "../views/private/VenueProvider/PageMyAccount/PageAttendeesPayments";
import PageVenueCreate from "../views/private/VenueProvider/PageVenueOptions/PageVenueCreate";
import PageVenueList from "../views/private/VenueProvider/PageVenueOptions/PageVenueList";
import PageVenueEdit from "../views/private/VenueProvider/PageVenueOptions/PageVenueEdit";
import PageVenueFaqs from "../views/private/VenueProvider/PageSupport/PageVenueFaqs";
import PageVenueTicketing from "../views/private/VenueProvider/PageSupport/PageTicketing";
import PageVenueCreateTicket from "../views/private/VenueProvider/PageSupport/PageCreateTicket";
import PageVenueTicketingReply from "../views/private/VenueProvider/PageSupport/PageTicketingReply";
import PageMessages from "../views/private/PageMessages/PageMessages";
import ComponentCookies from "../views/private/Components/ComponentCookies";
import ComponentPrivacy from "../views/private/Components/ComponentPrivacy";
import ComponentTermsProviders from "../views/private/Components/ComponentTermsProviders";
import PageSubscriptionDowngrade from "../views/private/VenueProvider/PageMyAccount/PageSubscriptionDowngrade";

import Page2fa from "../views/private/Page2fa/Page2fa";
import PageCreditCard from "../views/private/PageCreditCard/PageCreditCard";
import Error404 from "../views/public/Widgets/Error404";
const RoutePageVenue = () => {
	return (
		<Switch>
			{/* Event Provider */}
			<PrivateRouteVenueProvider
				exact
				path="/terms-providers"
				component={ComponentTermsProviders}
				permission="Conditions"
				breadcrumbs={[
					{
						name: "Terms & Conditions",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/cookies"
				component={ComponentCookies}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Cookie Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/privacy"
				component={ComponentPrivacy}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Privacy Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/dashboard"
				component={PageVenueDashboard}
				permission="Dashboard"
				breadcrumbs={[
					{
						name: "Dashboard",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/my-company"
				component={PageVenueMyCompany}
				permission="Company"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "My Company",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/profile"
				component={PageVenueProfile}
				permission="Profile"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Account Profile",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/profile/bank-details"
				component={PageVenueBankDetails}
				permission="Bank Details"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Account Profile",
						link: "/venue-provider/my-account/profile",
					},
					{
						name: "Edit Bank Details",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/profile/subscription"
				component={PageVenueSubscription}
				permission="Subscription"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Account Profile",
						link: "/venue-provider/my-account/profile",
					},
					{
						name: "Subscription",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/invoices/subscription/credit-card"
				component={PageCreditCard}
				permission="Credit Card"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/venue-provider/my-account/invoices",
					},
					{
						name: "Credit Card",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/invoices/subscription/upgrade"
				component={PageVenueSubscriptionUpgrade}
				permission="Subscription Upgrade"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/venue-provider/my-account/invoices",
					},
					{
						name: "Upgrade",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/invoices/subscription/downgrade"
				component={PageSubscriptionDowngrade}
				permission="Subscription Downgrade"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/venue-provider/my-account/invoices",
					},
					{
						name: "Downgrade",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/invoices"
				component={PageVenueInvoices}
				permission="Invoices"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/invoices/:id"
				component={PageVenueInvoicesView}
				permission="Paid"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/venue-provider/my-account/invoices",
					},
					{
						name: "Invoice",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/attendees-payments"
				component={PageVenueAttendeesPayments}
				permission="Invoices"
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/venue-options/create-venue"
				component={PageVenueCreate}
				permission="Venue"
				breadcrumbs={[
					{
						name: "Venue Options",
						link: null,
					},
					{
						name: "Create a Venue",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/venue-options/all-venue"
				component={PageVenueList}
				permission="Venues"
				breadcrumbs={[
					{
						name: "Venue Options",
						link: null,
					},
					{
						name: "All Venues",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/venue-options/venue/:id"
				component={PageVenueEdit}
				permission="Venue"
				breadcrumbs={[
					{
						name: "Venue Options",
						link: null,
					},
					{
						name: "All Venues",
						link: "/venue-provider/venue-options/all-venue",
					},
					{
						name: "Edit Venue",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/support/faqs"
				component={PageVenueFaqs}
				permission="Questions"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "FAQs",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/support/ticketing"
				component={PageVenueTicketing}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/support/ticketing/:id"
				component={PageVenueTicketingReply}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: "/venue-provider/support/ticketing",
					},
					{
						name: "Reply",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/support/create-ticket"
				component={PageVenueCreateTicket}
				permission="Ticket"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Create Ticket",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/messages"
				component={PageMessages}
				permission="Messages"
				breadcrumbs={[
					{
						name: "Messages",
						link: null,
					},
				]}
			/>
			<PrivateRouteVenueProvider
				exact
				path="/venue-provider/my-account/profile/2fa"
				component={Page2fa}
				permission="Authentication"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Profile",
						link: "/venue-provider/my-account/profile",
					},
					{
						name: "2-Factor Authentication ",
						link: null,
					},
				]}
			/>
			<Route path="*" exact={true} component={Error404} />
		</Switch>
	);
};

export default RoutePageVenue;
