const optionReferredBy = [
	{
		label: "Amazon",
		value: "Amazon",
	},
	{
		label: "Catalog",
		value: "Catalog",
	},
	{
		label: "Co-worker",
		value: "Co-worker",
	},
	{
		label: "Facebook",
		value: "Facebook",
	},
	{
		label: "From a friend or colleague",
		value: "From a friend or colleague",
	},
	{
		label: "Google Ad",
		value: "Google Ad",
	},
	{
		label: "Google search",
		value: "Google search",
	},
	{
		label: "Instagram",
		value: "Instagram",
	},
	{
		label: "Pinterest",
		value: "Pinterest",
	},
	{
		label: "Podcast",
		value: "Podcast",
	},
	{
		label: "Postcard",
		value: "Postcard",
	},
	{
		label: "Snapchat",
		value: "Snapchat",
	},
	{
		label: "Streaming TV",
		value: "Streaming TV",
	},
	{
		label: "Television Ad",
		value: "Television Ad",
	},
	{
		label: "TikTok",
		value: "TikTok",
	},
	{
		label: "Website banner",
		value: "Website banner",
	},
	{
		label: "Youtube",
		value: "Youtube",
	},
];

export default optionReferredBy;
