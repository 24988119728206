import React, { useState, useEffect } from "react";
import {
	Layout,
	Form,
	Popconfirm,
	Button,
	Row,
	Col,
	Checkbox,
	Collapse,
	notification,
	message,
	Divider,
	Radio,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";

import {
	SaveOutlined,
	UpOutlined,
	DownOutlined,
	UserOutlined,
	EditOutlined,
	MinusOutlined,
} from "@ant-design/icons";

import companyInfo from "../../../../providers/companyInfo";

import { Link } from "react-router-dom";

import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMask from "../../../../providers/FloatInputMask";
import ModalPassword from "./Modals/ModalPassword";
import getUserData from "../../../../providers/getUserData";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import states from "states-us";
import optionReferredBy from "../../../../providers/optionReferredBy";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";

export default function PageProfile({ history, permission }) {
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const [dataFetch, setDataFetch] = useState([]);
	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}

		setFormData(val);
		form.resetFields(["state", "zip"]);
		if (dataFetch.length > 0) {
			if (val === dataFetch[0].user_business_infos.country) {
				form.setFieldsValue({
					state: dataFetch[0].user_business_infos.state,
					zip: dataFetch[0].user_business_infos.zip,
				});
			}
		}
	};

	const sub_title = "Edit Account";
	let userdata = getUserData();
	const { Panel } = Collapse;
	const [fileList, setFileList] = useState([]);

	const onChange = ({ fileList: newFileList }) => {
		var _file = newFileList;
		console.log(_file);
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileList(_file);
			setFormData("image_upload");
			// if (_file[0].uid != "-1") {
			//   const data = new FormData();
			//   data.append("id", userdata.id);
			//   data.append("upload", _file[0].originFileObj, _file[0].name);
			//   mutateUpdateProfileImage(data, {
			//     onSuccess: (res) => {
			//       if (res.success) {
			//         // console.log(res.data);
			//         localStorage.setItem("userdata", JSON.stringify(res.data));
			//         notification.success({
			//           message: res.message,
			//           description: res.description,
			//         });
			//       } else {
			//         // notification.success({
			//         //   message: res.message,
			//         //   description: res.description,
			//         // });
			//       }
			//     },
			//     onError: (err) => {
			//       // notification.success({
			//       //   message: err.message,
			//       //   description: err.description,
			//       // });
			//     },
			//   });
			// }
		} else {
			setFileList([]);
		}
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const beforeUpload = (file) => {
		const isJpgOrPng =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "image/gif" ||
			file.type === "image/jpg";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG, PNG, GIF, JPEG file!");
			setFileList([]);
			return;
		}
		const isLt2M = file.size / 102400 / 102400 < 10;
		if (!isLt2M) {
			message.error("Image must smaller than 10MB!");
			setFileList([]);
			return;
		}
		return isJpgOrPng && isLt2M;
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		require_false: {
			required: false,
			message: "Required",
		},
		email: {
			type: "email",
			message: "please enter a valid email",
		},
	};

	const [form] = Form.useForm();
	const [, forceUpdate] = useState({});

	useEffect(() => {
		forceUpdate({});
	}, []);

	const gotoBankDetails = () => {
		history.push("/venue-provider/my-account/profile/bank-details");
	};

	const gotoBankSubscription = () => {
		history.push("/venue-provider/my-account/invoices");
	};

	const [isModalPassword, setIsModalPassword] = useState(false);
	const handleCancel = () => {
		setIsModalPassword(false);
	};

	const {
		mutate: mutateUpdateProfileImage,
		isLoading: isLoadingUpdateProfileImage,
	} = POST("api/v1/uppdate_profile_image", "profile");

	const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } =
		POST("api/v1/uppdate_profile", "profile");

	const onFinishProfile = (values) => {
		const data = new FormData();
		data.append("id", userdata.id);
		data.append("first_name", values.first_name ? values.first_name : "");
		data.append("last_name", values.last_name ? values.last_name : "");
		data.append("email", values.email ? values.email : "");
		data.append("cell_number", values.cell_number ? values.cell_number : "");
		data.append(
			"email_alternative",
			values.email_alternative ? values.email_alternative : ""
		);
		data.append("referred_by", values.referred_by ? values.referred_by : "");
		data.append("country", values.country ? values.country : "");
		data.append(
			"business_category_1",
			values.business_category_1 ? values.business_category_1 : ""
		);
		data.append(
			"business_category_2",
			values.business_category_2 ? values.business_category_2 : ""
		);
		data.append(
			"business_category_3",
			values.business_category_3 ? values.business_category_3 : ""
		);
		data.append("facebook", values.facebook ? values.facebook : "");
		data.append("instagram", values.instagram ? values.instagram : "");
		data.append("twitter", values.twitter ? values.twitter : "");
		data.append("youtube", values.youtube ? values.youtube : "");
		data.append("vimeo", values.vimeo ? values.vimeo : "");
		data.append(
			"youtube_vimeo",
			values.youtube_vimeo ? values.youtube_vimeo : ""
		);
		data.append(
			"business_name",
			values.business_name ? values.business_name : ""
		);
		data.append("address_1", values.address_1 ? values.address_1 : "");
		data.append("address_2", values.address_2 ? values.address_2 : "");
		data.append("city", values.city ? values.city : "");
		data.append("state", values.state ? values.state : "");
		data.append("zip", values.zip ? values.zip : "");
		data.append(
			"business_categories",
			JSON.stringify(values.business_categories)
		);

		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append("upload", fileList[0].originFileObj, fileList[0].name);
			}
		}

		mutateUpdateProfile(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: res.message,
						description: res.description,
					});
					console.log("wewe", res.data);
					localStorage.setItem("userdata", JSON.stringify(res.data));
				} else {
					notification.success({
						message: res.message,
						description: res.description,
					});
				}
			},
			onError: (err) => {
				notification.success({
					message: err.message,
					description: err.description,
				});
			},
		});
	};

	const [authText, setAuthText] = useState("enable");
	const {
		data: dataProfile,
		refetch: refetchProfile,
		isLoading: isLoadingProfile,
	} = GET(`api/v1/get_by_id?id=${userdata.id}`, `profile`, (res) => {
		if (res.success) {
			// console.log(res)
			setAuthText(res.data[0].google2fa_enable == 1 ? "disable" : "enable");
			setDataFetch(res.data);
			if (res.data[0].user_business_infos.country === "United States") {
				setStateLabel("State");
				setZipLabel("Zip Code");
				setOptionState(stateUS);
				setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
			} else if (res.data[0].user_business_infos.country === "Mexico") {
				setStateLabel("State");
				setZipLabel("Zip Code");
				setOptionState(stateMX);
				setOptionZip(/(^\d{5}$)/);
			} else if (res.data[0].user_business_infos.country === "Canada") {
				setStateLabel("County");
				setZipLabel("Postal Code");
				setOptionState(stateCA);
				setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
			} else {
				setStateLabel("State");
				setZipLabel("Zip Code");
				setOptionState(stateUS);
				setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
			}

			form.setFieldsValue({
				first_name: res.data[0].first_name,
				last_name: res.data[0].last_name,
				email: res.data[0].email,
				cell_number: res.data[0].cell_number,
				email_alternative: res.data[0].email_alternative,
				referred_by: res.data[0].referred_by ?? "",
				business_category_1: res.data[0].business_category_1,
				business_category_2: res.data[0].business_category_2,
				business_category_3: res.data[0].business_category_3,
				username: res.data[0].username,
				facebook: res.data[0].facebook ? res.data[0].facebook : "",
				instagram: res.data[0].instagram ? res.data[0].instagram : "",
				youtube: res.data[0].youtube ? res.data[0].youtube : "",
				twitter: res.data[0].twitter ? res.data[0].twitter : "",
				vimeo: res.data[0].vimeo ? res.data[0].vimeo : "",
				business_categories: res.data[0].business_categories
					? JSON.parse(res.data[0].business_categories)
					: [],

				business_name: res.data[0].user_business_infos.business_name,
				address_1: res.data[0].user_business_infos.address_1,
				country: res.data[0].user_business_infos.country,
				city: res.data[0].user_business_infos.city,
				state: res.data[0].user_business_infos.state,
				zip: res.data[0].user_business_infos.zip,
			});

			if (
				res.data[0].user_business_infos &&
				res.data[0].user_business_infos.address_2
			) {
				form.setFieldsValue({
					address_2: res.data[0].user_business_infos.address_2,
				});
			} else {
				form.resetFields(["address_2"]);
			}

			if (!res.data[0].cell_number) {
				form.resetFields(["cell_number"]);
			}
			if (!res.data[0].email_alternative) {
				form.resetFields(["email_alternative"]);
			}
			if (!res.data[0].referred_by) {
				form.resetFields(["referred_by"]);
			}
			if (!res.data[0].business_category_1) {
				form.resetFields(["business_category_1"]);
			}
			if (!res.data[0].business_category_2) {
				form.resetFields(["business_category_2"]);
			}
			if (!res.data[0].business_category_3) {
				form.resetFields(["business_category_3"]);
			}

			if (!res.data[0].facebook || res.data[0].facebook == "") {
				form.resetFields(["facebook"]);
			}
			if (!res.data[0].instagram || res.data[0].instagram == "") {
				form.resetFields(["instagram"]);
			}
			if (!res.data[0].youtube_vimeo || res.data[0].youtube_vimeo == "") {
				form.resetFields(["youtube_vimeo"]);
			}
			if (!res.data[0].twitter || res.data[0].twitter == "") {
				form.resetFields(["twitter"]);
			}
			if (!res.data[0].vimeo || res.data[0].vimeo == "") {
				form.resetFields(["vimeo"]);
			}
			if (!res.data[0].youtube || res.data[0].youtube == "") {
				form.resetFields(["youtube"]);
			}

			let image_type =
				res.data[0] && res.data[0].upload ? res.data[0].upload.split("/") : "";
			if (image_type[0] != undefined) {
				image_type =
					image_type[0] == "https:"
						? res.data[0].upload
						: companyInfo().apiUrl + "storage/" + res.data[0].upload;
				setFileList([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: image_type,
					},
				]);
			} else {
				setFileList([]);
				image_type = "";
			}
		}
	});

	let business_category = [
		{
			label: "Dental Lab",
			value: "Dental Lab",
		},
		{
			label: "Dental Office",
			value: "Dental Office",
		},
		{
			label: "Event Venue",
			value: "Event Venue",
		},
		{
			label: "Hotel",
			value: "Hotel ",
		},
		{
			label: "Restaurant",
			value: "Restaurant",
		},
		{
			label: "Other",
			value: "Other",
		},
	];

	const [state, setState] = useState([]);
	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.name,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const [deactivate, setDeactivate] = useState(false);

	const { mutate: mutateDeactive, isLoading: isLoadingDeactive } = POST(
		"api/v1/user_deactive",
		"user_deactive"
	);

	const handleDeactivate = () => {
		mutateDeactive(
			{ id: userdata.id, link: window.location.origin },
			{
				onSuccess: (res) => {
					console.log("res", res);
					if (res.success) {
						notification.success({
							message: res.message,
							description: res.description,
						});

						// mutateLogout(
						//   { user_id: userdata.id },
						//   {
						//     onSuccess: (res) => {
						//       console.log("userdata", res);
						//       if (res.success) {
						//         localStorage.removeItem("token");
						//         localStorage.removeItem("userdata");
						//         window.location.href = window.location.origin;
						//       }
						//     },
						//   }
						// );
					}
				},
				onError: (err) => {},
			}
		);
	};

	const { mutate: mutateLogout, isLoading: isLoadingLogout } = POST(
		"api/v1/logout",
		"logout"
	);

	const [currentPlan, setCurrentPlan] = useState();
	const { data: dataAccountType, isLoading: isLoadingAccountType } = GET(
		"api/v1/acc_type_per_user",
		"acc_type_per_user",
		(res) => {
			if (res.success) {
				console.log("acc_type", res);

				let currentPlan;
				if (res.current_plan) {
					currentPlan = res.current_plan.account_type_plans;
					currentPlan = (
						<>
							<h2 style={{ marginBottom: 0 }}>
								{currentPlan.plan} - ${currentPlan.amount}
							</h2>
							<span className="c-secondary">{currentPlan.description}</span>
						</>
					);
				} else {
					currentPlan = "Not yet Subscribed to any Plan";
				}

				// console.log("currentPlan", currentPlan);
				setCurrentPlan(currentPlan);
			}
		}
	);

	const [formData, setFormData] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			console.log("useEffect", formData);
			if (formData) {
				form.submit();
				setFormData("");
			}
		}, 3000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [formData]);

	const hanldeInput = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const onChangeOption = (val, option) => {
		setFormData(val);
	};

	const hanldeTextArea = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const [radioData, setRadioData] = useState(1);
	const [imageCrop, setImageCrop] = useState({
		width: 1,
		height: 1,
	});

	const handleResize = (val) => {
		console.log("val", val.target.value);
		setRadioData(val.target.value);
		if (val.target.value === 1) {
			setImageCrop({
				width: 1,
				height: 1,
			});
		} else if (val.target.value === 2) {
			setImageCrop({
				width: 3.9,
				height: 2.6,
			});
		} else if (val.target.value === 3) {
			setImageCrop({
				width: 1,
				height: 1.5,
			});
		}
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageProfile"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<UserOutlined />}
			/>

			<ModalPassword
				isModalPassword={isModalPassword}
				setIsModalPassword={setIsModalPassword}
				userdata={userdata}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/645101788?autoplay=0&muted=1"
					}
					col="speaker_profile"
					colLink="speaker_profile_link"
				/>
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinishProfile}
				>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Login Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="username"
												rules={[validator.require_false]}
												hasFeedback
											>
												<FloatInput
													label="Username"
													placeholder="Username"
													disabled={true}
													// readOnly={true}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Link
										to="#"
										onClick={(e) => setIsModalPassword(true)}
										className="changePassText"
									>
										Change Password
									</Link>
								</Panel>
							</Collapse>

							<br></br>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Personal Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="first_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="last_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Last Name"
													placeholder="Last Name"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="email"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Email Address"
													placeholder="Email Address"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="cell_number"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputMask
													label="Cell Phone"
													placeholder="Cell Phone"
													maskLabel="cell_number"
													maskType="999-999-9999"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<div>
										<Link
											to={"/venue-provider/my-account/profile/2fa"}
											className="link2factor"
										>
											{" "}
											CLICK HERE
										</Link>{" "}
										to {authText} 2-Factor Authentication (2FA)
									</div>
									<br></br>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="email_alternative"
												rules={[validator.require_false, validator.email]}
												className="colemailalt"
											>
												<FloatInput
													label="Email Address (Alternative / Optional)"
													placeholder="Email Address (Alternative / Optional)"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="referred_by"
												className="form-select-error"
												rules={[validator.require_false]}
											>
												<FloatSelect
													label="Referred by"
													placeholder="Referred by"
													options={optionReferredBy}
													onChange={onChangeOption}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="business_categories"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error-multi"
											>
												<FloatSelect
													label="Business Category"
													placeholder="Business Category"
													options={business_category}
													multi="multiple"
													onChange={onChangeOption}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Panel>
							</Collapse>

							{/* <br></br>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Business Information"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        name="business_name"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Business Name"
                          placeholder="Business Name"
                          onChange={hanldeInput}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        name="country"
                        rules={[validator.require]}
                        className="form-select-error"
                        hasFeedback
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={country}
                          onChange={handleCountry}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="address_1"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          onChange={hanldeInput}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="address_2"
                        rules={[validator.require_false]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Address 2"
                          placeholder="Address 2"
                          onChange={hanldeInput}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item
                        name="city"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="City"
                          placeholder="City"
                          onChange={hanldeInput}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item
                        name="state"
                        rules={[validator.require]}
                        hasFeedback
                        className="form-select-error"
                      >
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                          onChange={hanldeInput}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item
                        name="zip"
                        rules={[
                          validator.require,
                          {
                            pattern: optionZip,
                            message: "Invalid " + zipLabel,
                          },
                        ]}
                        hasFeedback
                      >
                        <FloatInput
                          label={zipLabel}
                          placeholder={zipLabel}
                          onChange={hanldeInput}
                        />
                      </Form.Item>{" "}
                    </Col>
                  </Row>
                </Panel>
              </Collapse> */}

							<br></br>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Social Media"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Form.Item name="facebook" hasFeedback>
										<FloatInput
											label="Your Facebook URL Link"
											placeholder="Your Facebook URL Link"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item name="instagram" hasFeedback>
										<FloatInput
											label="Your Instagram Account"
											placeholder="Your Instagram Account"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item name="twitter" hasFeedback>
										<FloatInput
											label="Your Twitter Account (Optional)"
											placeholder="Your Twitter Account (Optional)"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item name="youtube" hasFeedback>
										<FloatInput
											label="Your Youtube Account (Optional)"
											placeholder="Your Youtube Account (Optional)"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item name="vimeo" hasFeedback>
										<FloatInput
											label="Your Vimeo Account (Optional)"
											placeholder="Your Vimeo Account (Optional)"
											onChange={hanldeInput}
										/>
									</Form.Item>
									{/* <Form.Item name="youtube_vimeo" hasFeedback>
                    <FloatInput
                      label="Your Youtube / Vimeo Account "
                      placeholder="Your Youtube / Vimeo Account "
                      onChange={hanldeInput}
                    />
                  </Form.Item>{" "} */}
								</Panel>
							</Collapse>
							<br></br>
						</Col>
						<Col xs={24} sm={24} md={8} lg={8}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Profile Photo"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<label className="font-red">
										<b>
											Photo upload & Cropping: Select your image orientation
										</b>
									</label>
									<br />
									<Radio.Group
										// className="crop-center"
										onChange={handleResize}
										value={radioData}
									>
										<Radio value={1}>Square</Radio>
										<Radio value={2}>Rectangle</Radio>
										<Radio value={3}>Portrait</Radio>
									</Radio.Group>
									<Divider />
									<div>
										<ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
											<Upload
												// action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
												listType="picture-card"
												style={{ width: "200px" }}
												maxCount={1}
												action={false}
												customRequest={false}
												fileList={fileList}
												onChange={onChange}
												onPreview={onPreview}
												beforeUpload={beforeUpload}
												loading={isLoadingUpdateProfileImage}
												className="profilePhoto"
											>
												{fileList.length < 1 && "+ Upload"}
											</Upload>
										</ImgCrop>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<span>One file Only. 10 MB limit.</span>
										<span>
											Your selected profile photo will be visible to all event
											organizers and event attendees. <br></br>
										</span>
									</div>
									<div className="c-secondary" style={{ fontSize: "12px" }}>
										Allowed types: png gif jpg jpeg.
									</div>
								</Panel>
							</Collapse>

							<br></br>

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Subscription"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<div>
										{currentPlan}
										<br></br>
										<br></br>
										{currentPlan != "Not yet Subscribed to any Plan" && (
											<div>
												{dataAccountType &&
												dataAccountType.stripe_data &&
												dataAccountType.stripe_data.cancel_at_period_end
													? "You are set up for manual payments, you are not on a recurring payment plan."
													: "You are on a recurring payment plan."}
											</div>
										)}
										<br></br>
										<Button
											size="large"
											className="btn-loginNew-outline subscription-button-accprof"
											style={{ width: "100%" }}
											onClick={() => gotoBankSubscription()}
										>
											View Subscription
										</Button>
									</div>
								</Panel>
							</Collapse>
							<br></br>

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Deactivate Account"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<div>
										No longer need your account and want to deactivate it?
									</div>
									<br></br>
									<Checkbox onChange={(e) => setDeactivate(e.target.checked)}>
										Yes I understand that by deactivating my account I will no
										longer have access to my account information and all
										historical data.
									</Checkbox>
									<br></br>
									<br></br>

									<Popconfirm
										title="Are you sure to Deactivate this account?"
										onConfirm={() => handleDeactivate()}
										okText="Confirm"
										cancelText="Cancel"
										disabled={deactivate ? false : true}
									>
										<Button
											size="large"
											className="btn-red-outline deactivate-button-accprof"
											disabled={deactivate ? false : true}
											block
										>
											Deactivate My Account
										</Button>
									</Popconfirm>
								</Panel>
							</Collapse>
						</Col>
					</Row>
					<br></br>
					{/* <Row gutter={24}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    size="large"
                    className="btn-login-outline"
                    htmlType="submit"
                    style={{ width: 200, marginTop: "20px" }}
                    icon={<SaveOutlined />}
                    // disabled={
                    //   !form.isFieldsTouched(true) ||
                    //   !!form
                    //     .getFieldsError()
                    //     .filter(({ errors }) => errors.length).length
                    // }
                    loading={isLoadingUpdateProfile}
                  >
                    Save
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row> */}
				</Form>
			</Layout.Content>
		</Layout>
	);
}
