import React from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PublicLayout from "../layouts/public";
import { GET } from "../providers/useAxiosQuery";
// import getUserData from "../providers/getUserData";

// const isLoggedIn = localStorage.getItem("token");
// const userdata = getUserData();
const PublicRoute = ({ component: Component, ...rest }) => {
	return (
		<div>
			<Route
				{...rest}
				render={
					(props) => (
						// !isLoggedIn ||
						// props.match.path.split("/")[1] == "logout" ||
						// props.match.path.split("/")[1] == "error" ? (
						<PublicLayout>
							<Component {...props} />
						</PublicLayout>
					)
					// ) : (
					//     <div>
					//         <Redirect to={{ pathname: "/dashboard" }} />
					//     </div>
					// )
				}
			/>
		</div>
	);
};

export default PublicRoute;
