import React, { useState, useEffect } from "react";
import { Layout, Input, Row, Col, Table, Pagination } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { TeamOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import ComponentFaqs from "../Components/ComponentFaqs";
import { GET } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import getUserData from "../../../../providers/getUserData";
export default function PageViewEventsAttendees({ props, permission }) {
	const { Search } = Input;
	const sub_title = "Events with";
	const userdata = getUserData();
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		page_number: 1,
		page_size: "50",
		column: "event_date",
		order: "desc",
		user_id: userdata.id,
	});

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataGetEvent,
		isLoading: isLoadingGetEvent,
		refetch: refetchGetEvent,
		isFetching: isFetchingGetEvent,
	} = GET(`api/v1/event?${$.param(dataTableInfo)}`, "event", (res) => {
		if (res.success) {
			console.log(res);
		}
	});

	useEffect(() => {
		refetchGetEvent();
	}, [dataTableInfo]);
	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		console.log("current", current);
		console.log("pageSize", pageSize);
	};

	const handleTableChange = (pagination, filters, sorter) => {
		// console.log("sorter", sorter);
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageViewEventsAttendees"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<TeamOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/645104703?autoplay=0&muted=1"
					}
				/>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataGetEvent && dataGetEvent.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						className="table-responsive table-thead-login"
						size="small"
						rowKey={(record) => record.id}
						loading={isLoadingGetEvent}
						dataSource={dataGetEvent ? dataGetEvent.data.data : []}
						pagination={false}
						onChange={handleTableChange}
					>
						<Table.Column
							key="event_date"
							title="Date"
							dataIndex="event_date"
							sorter
						/>
						<Table.Column
							key="title"
							title="Edit Event"
							dataIndex="title"
							sorter
							render={(text, record) => {
								return record.event_details ? (
									<Link
										to={
											"/event-provider-speaker/ce-calendar/events-with-attendees/" +
											record.id
										}
										style={{ color: "#d6ae71" }}
									>
										{record.event_details.title}
									</Link>
								) : (
									""
								);
							}}
						/>
						<Table.Column
							key="speaker_name"
							title="Speaker"
							dataIndex="speaker_name"
							sorter
							// sorter={(a, b) =>
							//   a.event_speaker.speaker_name.length -
							//   b.event_speaker.speaker_name.length
							// }
							render={(text, record) => {
								let val = record.event_speaker
									.reduce((arr, items) => {
										// let name = items.speaker_name.split(", ");
										// name = name[1] + " " + name[0] + ";";
										let name = items.speaker_name.split(", ");
										name = name[0] + ";";
										return (arr += name);
									}, [])
									.split(";")
									.join(", ");
								return val.slice(0, -2);
							}}
						/>
						{/* <Table.Column
              key="adress_tbd"
              title="Address TBD"
              dataIndex="event_name"
              render={(text, record) => {
                return record.event_venue &&
                  record.event_venue.venue_name === ""
                  ? record.event_venue.city
                  : "";
              }}
              sorter
            /> */}
						<Table.Column
							key="city"
							title="City"
							dataIndex="event_name"
							render={(text, record) => {
								return record.event_venue &&
									record.event_venue.venue_name !== ""
									? record.event_venue.city
									: "";
							}}
							sorter
						/>
						<Table.Column
							key="state"
							title="State"
							dataIndex="event_name"
							render={(text, record) => {
								return record.event_venue &&
									record.event_venue.venue_name !== ""
									? record.event_venue.state
									: "";
							}}
							sorter
						/>

						<Table.Column
							key="event_attendee"
							title="Registered Attendees"
							dataIndex="event_attendee"
							sorter
							render={(text, record) => {
								var seats = record.event_details.seat_available
									? record.event_details.seat_available
									: 0;

								return record.event_attendee.length > seats
									? seats + "/" + seats
									: record.event_attendee.length + "/" + seats;
							}}
						/>
					</Table>
				</div>
			</Layout.Content>
		</Layout>
	);
}
