// const optionStateCodesMexico = [
//   { value: "AG", label: "AGUASCALIENTES" },
//   { value: "BC", label: "BAJA CALIFORNIA" },
//   { value: "BS", label: "BAJA CALIFORNIA SUR" },
//   { value: "CH", label: "COAHUILA" },
//   { value: "CI", label: "CHIHUAHUA" },
//   { value: "CL", label: "COLIMA" },
//   { value: "CP", label: "CAMPECHE" },
//   { value: "CS", label: "CHIAPAS" },
//   { value: "CDMX", label: "MEXICO CITY" },
//   { value: "DG", label: "DURANGO" },
//   { value: "GE", label: "GUERRERO" },
//   { value: "GJ", label: "GUANAJUATO" },
//   { value: "HD", label: "HIDALGO" },
//   { value: "JA", label: "JALISCO" },
//   { value: "MC", label: "MICHOACAN" },
//   { value: "MR", label: "MORELOS" },
//   { value: "MX", label: "MEXICO" },
//   { value: "NA", label: "NAYARIT" },
//   { value: "NL", label: "NUEVO LEON" },
//   { value: "OA", label: "OAXACA" },
//   { value: "PU", label: "PUEBLA" },
//   { value: "QE", label: "QUERETARO" },
//   { value: "QI", label: "QUINTANA ROO" },
//   { value: "SI", label: "SINALOA" },
//   { value: "SL", label: "SAN LUIS POTOSI" },
//   { value: "SO", label: "SONORA" },
//   { value: "TA", label: "TAMAULIPAS" },
//   { value: "TB", label: "TABASCO" },
//   { value: "TL", label: "TLAXCALA" },
//   { value: "VC", label: "VERACRUZ" },
//   { value: "YU", label: "YUCATAN" },
//   { value: "ZA", label: "ZACATECAS" },
// ];

// export default optionStateCodesMexico;

export default function optionStateCodesMexico() {
  const optionStateCodesMexico = [
    { value: "AG", label: "Aguascalientes" },
    { value: "BC", label: "Baja California" },
    { value: "BS", label: "Baja California Sur" },
    { value: "CH", label: "Coahuila" },
    { value: "CI", label: "Chihuahua" },
    { value: "CL", label: "Colima" },
    { value: "CP", label: "Campeche" },
    { value: "CS", label: "Chiapas" },
    { value: "CDMX", label: "Mexico City" },
    { value: "DG", label: "Durango" },
    { value: "GE", label: "Guerrero" },
    { value: "GJ", label: "Guanajuato" },
    { value: "HD", label: "Hidalgo" },
    { value: "JA", label: "Jalisco" },
    { value: "MC", label: "Michoacan" },
    { value: "MR", label: "Morelos" },
    { value: "MX", label: "Mexico" },
    { value: "NA", label: "Nayarit" },
    { value: "NL", label: "Nuevo Leon" },
    { value: "OA", label: "Oaxaca" },
    { value: "PU", label: "Puebla" },
    { value: "QE", label: "Queretaro" },
    { value: "QI", label: "Quintana Roo" },
    { value: "SI", label: "Sinaloa" },
    { value: "SL", label: "San Luis Potosi" },
    { value: "SO", label: "Sonora" },
    { value: "TA", label: "Tamaulipas" },
    { value: "TB", label: "Tabasco" },
    { value: "TL", label: "Tlaxcala" },
    { value: "VC", label: "Veracruz" },
    { value: "YU", label: "Yucatan" },
    { value: "ZA", label: "Zacatecas" },
  ];

  return optionStateCodesMexico;
}
