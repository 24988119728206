import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Form,
  Button,
  Row,
  Col,
  Checkbox,
  Collapse,
  Image,
  Space,
  Upload,
  message,
  notification,
  Modal,
  Radio,
  Divider,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import ImgCrop from "antd-img-crop";
import {
  SaveOutlined,
  EyeOutlined,
  InboxOutlined,
  BookOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import BlackGoldfields from "../../../../assets/img/BlackGoldfields.jpeg";
import BlackRedfields from "../../../../assets/img/BlackRedfields.jpeg";
import BlueGoldfields_0 from "../../../../assets/img/BlueGoldfields_0.jpeg";
import BlueGreenfields from "../../../../assets/img/BlueGreenfields.jpeg";
import BlueRed2fields from "../../../../assets/img/BlueRed2fields.jpeg";
import BlueRedfields from "../../../../assets/img/BlueRedfields.jpeg";
import BlueTealfields from "../../../../assets/img/BlueTealfields.jpeg";
import SignaturePad from "react-signature-canvas";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import ModalPreviewCertificate from "./Modals/ModalPreviewCertificate";
import SendCertificate from "./Modals/SendCertificate";

import { Preview, print } from "react-html2pdf";

export default function PageCertTemplate({ history, permission }) {
  const sub_title = "Certificate";
  const { Panel } = Collapse;
  let componentRef = useRef();
  const { Dragger } = Upload;
  // console.log("history", history.location.search);
  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };
  const [form] = Form.useForm();
  const [trimmedDataURL, setTrimmedDataURL] = useState({
    trimmedDataURL: null,
  });
  const [fileLogo, setFileLogo] = useState([]);
  const [sigPad, setSigPad] = useState([]);
  const [fileLogoCERP, setFileLogoCERP] = useState([]);
  const [fileLogoPACE, setFileLogoPACE] = useState([]);
  const [isDefault, setIsDefault] = useState(0);
  const clear = () => {
    sigPad.clear();
    setTrimmedDataURL({
      trimmedDataURL: null,
    });
  };

  const trim = () => {
    // clear();
    setTrimmedDataURL({
      trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
    setFormData(sigPad.getTrimmedCanvas().toDataURL("image/png"));
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const [fileLogoError, setfileLogoError] = useState("");
  const [sigPadError, setSigPadError] = useState("");
  const [fileLogoCERPError, setFileLogoCERPError] = useState("");
  const [fileLogoPACEError, setFileLogoPACEError] = useState("");
  const [fileTemplate, setFileTemplate] = useState("");
  const onFinish = (values) => {
    setfileLogoError(fileLogo.length === 0 ? "Required" : "");
    setSigPadError(trimmedDataURL.trimmedDataURL === null ? "Required" : "");
    setFileLogoCERPError(fileLogoCERP.length === 0 ? "Required" : "");
    setFileLogoPACEError(fileLogoPACE.length === 0 ? "Required" : "");
    setFileTemplate(
      values.template === undefined || values.template.length === 0
        ? "Required"
        : ""
    );

    if (
      trimmedDataURL.trimmedDataURL !== null &&
      fileLogo.length !== 0 &&
      fileLogoCERP.length !== 0 &&
      fileLogoPACE.length !== 0 &&
      values.template !== undefined &&
      values.template.length !== 0
    ) {
      const data = new FormData();
      // if (values.options === "Default Template") {
      // }

      console.log("isDefault", isDefault);
      data.append("options", isDefault == 0 ? values.options : isDefault);
      data.append("title", values.title);
      data.append("event_id", eventID ? eventID.id : "");
      data.append(
        "template",
        values.template.length == 1 ? values.template[0] : values.template
      );
      if (trimmedDataURL.from) {
        data.append("sign_update", 1);
        data.append("sign_upload", trimmedDataURL.DataURL);
      } else {
        let sign = dataURItoBlob(trimmedDataURL.trimmedDataURL);
        data.append("sign_update", 0);
        data.append("sign_upload", sign, "sign.png");
      }

      if (fileLogo[0].from) {
        data.append("logo_update", 1);
        data.append("logo_upload", fileLogo[0].ud_url);
      } else {
        data.append("logo_update", 0);
        data.append("logo_upload", fileLogo[0].originFileObj, fileLogo[0].name);
      }
      if (fileLogoCERP[0].from) {
        data.append("cerp_update", 1);
        data.append("cerp_upload", fileLogoCERP[0].ud_url);
      } else {
        data.append("cerp_update", 0);
        data.append(
          "cerp_upload",
          fileLogoCERP[0].originFileObj,
          fileLogoCERP[0].name
        );
      }
      if (fileLogoPACE[0].from) {
        data.append("pace_update", 1);
        data.append("pace_upload", fileLogoPACE[0].ud_url);
      } else {
        data.append("pace_update", 0);
        data.append(
          "pace_upload",
          fileLogoPACE[0].originFileObj,
          fileLogoPACE[0].name
        );
      }

      mutateCertificate(data, {
        onSuccess: (res) => {
          if (res.success) {
            setEventID([]);
            notification.success({
              message: "Success",
              description: "Succesfully Submitted",
            });

            if (values.options == 0) {
              setIsDefault(res.data.id);
            }
            console.log("resOnfinish", res);
            handleGetCert(res.data.id);
          }
        },
      });
    }
  };

  const { mutate: mutateCertificate, isLoading: isLoadingCertificate } = POST(
    "api/v1/certificate",
    "get_cert"
  );

  const [template, setTemplate] = useState([]);

  const { data: dataAllCert } = GET(
    "api/v1/all_certificate",
    "certificates",
    (res) => {
      if (res.success) {
        console.log("dataAllCerts", res);
        let arr = [];
        res.data &&
          res.data.map((item, key) => {
            arr.push({
              label: item.options,
              value: item.id,
              json: item,
            });
          });
        if (res.data.length > 0) {
          setTemplate([...arr]);
        } else {
          setTemplate([
            {
              label: "Default Template",
              value: "0",
            },
          ]);
        }
      } else {
      }
    }
  );

  const [dataCetificate, setDataCetificate] = useState([]);
  const [btnCert, setBtnCert] = useState(true);

  const [queryDate, setQueryDate] = useState({
    options: "Default Template",
  });

  const { mutate: mutateCert, isLoading: isLoadingCert } = POST(
    "api/v1/get_cert",
    "get_cert_mutate"
  );

  useEffect(() => {
    if (history.location.search) {
      let name = history.location.search.split("?id=");

      if (name[1]) {
        console.log("search_id", name[1]);
        handleGetCert(name[1]);
        form.setFieldsValue({ options: Number(name[1]) });
      }
    }
  }, [history.location.search]);

  const handleGetCert = (val) => {
    mutateCert(
      { id: val },
      {
        onSuccess: (res) => {
          if (res.success) {
            console.log("handleGetCert", res);
            setBtnCert(res.data ? false : true);
            if (res.data) {
              setDataCetificate(res.data ? res.data : []);
              form.setFieldsValue({
                title: res.data.title,
                template: res.data.template,
              });
              let filenameLogo = res.data.company_logo.split("logo/");
              setFileLogo([
                {
                  uid: "-1",
                  name: filenameLogo[1],
                  status: "done",
                  url: companyInfo().apiUrl + res.data.company_logo,
                  thumbUrl: companyInfo().apiUrl + res.data.company_logo,
                  ud_url: res.data.company_logo,
                  from: "old",
                },
              ]);
              let filenameCERP = res.data.ada_cerp_logo.split("cerp/");
              setFileLogoCERP([
                {
                  uid: "-1",
                  name: filenameCERP[1],
                  status: "done",
                  url: companyInfo().apiUrl + res.data.ada_cerp_logo,
                  thumbUrl: companyInfo().apiUrl + res.data.ada_cerp_logo,
                  ud_url: res.data.ada_cerp_logo,
                  from: "old",
                },
              ]);
              let filenamePACE = res.data.ada_pace_logo.split("pace/");
              setFileLogoPACE([
                {
                  uid: "-1",
                  name: filenamePACE[1],
                  status: "done",
                  url: companyInfo().apiUrl + res.data.ada_pace_logo,
                  thumbUrl: companyInfo().apiUrl + res.data.ada_pace_logo,
                  ud_url: res.data.ada_pace_logo,
                  from: "old",
                },
              ]);
              setTrimmedDataURL({
                trimmedDataURL: companyInfo().apiUrl + res.data.signature,
                DataURL: res.data.signature,
                from: "old",
              });
            }
          }
        },
      }
    );
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     handleGetCert();
  //     // setDataTableInfo({
  //     //   ...dataTableInfo,
  //     //   search: searchText,
  //     //   page_number: 1,
  //     // });
  //   }, 500);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [queryDate]);

  const [eventID, setEventID] = useState([]);
  const handleOptions = (val, opt) => {
    // setQueryDate({
    //   options: val,
    // });
    // console.log("handleGetCert", val);
    // console.log("handleGetCert", opt["data-json"]);
    setEventID(opt["data-json"]);
    handleGetCert(val);
  };

  const [previewCertificate, setPreviewCertificate] = useState(false);
  const handlePreviewCancel = () => {
    setPreviewCertificate(false);
  };

  const handlePreviewCertificate = () => {
    setPreviewCertificate(true);
  };

  const [formData, setFormData] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (formData) {
        form.submit();
        setFormData("");
      }
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [formData]);

  const handleInput = (val) => {
    if (val === "") {
      setFormData("empty");
    } else {
      setFormData(val);
    }
  };

  const handleSelect = (val, option) => {
    setFormData(val);
  };

  const handleTextArea = (val) => {
    if (val === "") {
      setFormData("empty");
    } else {
      setFormData(val);
    }
  };

  const onChangeForm = (change, allval) => {
    if (change[0].name[0] === "template") {
      var val = form.getFieldsValue(true);
      var value = val["template"];
      setFormData(value);
    }
  };

  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 8.5,
        height: 2,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const [radioData1, setRadioData1] = useState(1);
  const [imageCrop1, setImageCrop1] = useState({
    width: 8.5,
    height: 2,
  });

  // const handleResize1 = (val) => {
  //   console.log("val", val.target.value);
  //   setRadioData1(val.target.value);
  //   if (val.target.value === 1) {
  //     setImageCrop1({
  //       width: 1,
  //       height: 1,
  //     });
  //   } else if (val.target.value === 2) {
  //     setImageCrop1({
  //       width: 8.5,
  //       height: 2,
  //     });
  //   } else if (val.target.value === 3) {
  //     setImageCrop1({
  //       width: 1,
  //       height: 1.5,
  //     });
  //   }
  // };

  const [radioData2, setRadioData2] = useState(1);
  const [imageCrop2, setImageCrop2] = useState({
    width: 8.5,
    height: 2,
  });

  // const handleResize2 = (val) => {
  //   console.log("val", val.target.value);
  //   setRadioData2(val.target.value);
  //   if (val.target.value === 1) {
  //     setImageCrop2({
  //       width: 1,
  //       height: 1,
  //     });
  //   } else if (val.target.value === 2) {
  //     setImageCrop2({
  //       width: 8.5,
  //       height: 2,
  //     });
  //   } else if (val.target.value === 3) {
  //     setImageCrop2({
  //       width: 1,
  //       height: 1.5,
  //     });
  //   }
  // };

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageCertemplate"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<BookOutlined />}
      />
      <Layout.Content style={{ padding: "30px", paddingTop: "0px", zIndex: 2 }}>
        <ComponentFaqs
          linkVid={
            "https://player.vimeo.com/video/645104788?autoplay=0&muted=1"
          }
          col="event_certificate_template"
          colLink="event_certificate_template_link"
        />
        <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          // initialValues={{
          //   options: "Default Template",
          // }}
          onFieldsChange={(changedValues, allValues) =>
            onChangeForm(changedValues, allValues)
          }
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={16} lg={16}>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="STEP 1 - Add Provider's / Facilitator's Full Name"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Form.Item
                    name="options"
                    className="form-select-error"
                    rules={[validator.require]}
                    hasFeedback
                  >
                    <FloatSelect
                      label="Template"
                      placeholder="Template"
                      options={template}
                      onChange={handleOptions}
                    />
                  </Form.Item>
                  <Form.Item
                    name="title"
                    rules={[validator.require]}
                    hasFeedback
                  >
                    <FloatInput
                      label="Full Name"
                      placeholder="Full Name"
                      onChange={handleInput}
                    />
                  </Form.Item>
                </Panel>
              </Collapse>
              <br></br>

              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Step 2 - Add Company Logo"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <label className="font-red">
                    <b>
                      Photo upload & Cropping: Select your image orientation
                    </b>
                  </label>
                  <br />
                  <Radio.Group onChange={handleResize} value={radioData}>
                    <Radio value={1}>Square</Radio>
                    <Radio value={2}>Rectangle</Radio>
                    <Radio value={3}>Portrait</Radio>
                  </Radio.Group>
                  <Divider />
                  <ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
                    <Upload
                      className="venue-images"
                      listType="picture"
                      fileList={[...fileLogo]}
                      onRemove={(e) => {
                        console.log("onRemove", e);
                        let onremove = fileLogo.filter(function (item, pos) {
                          return item.uid != e.uid;
                        });
                        setFileLogo(onremove);
                        setFormData(onremove);
                      }}
                      beforeUpload={(file) => {
                        let error = false;
                        const isJpgOrPng =
                          file.type === "image/jpeg" ||
                          file.type === "image/png" ||
                          file.type === "image/gif" ||
                          file.type === "image/jpg";
                        if (!isJpgOrPng) {
                          message.error(
                            "You can only upload JPG, PNG, GIF, JPEG file!"
                          );
                          error = Upload.LIST_IGNORE;
                        }
                        const isLt2M = file.size / 102400 / 102400 < 10;
                        if (!isLt2M) {
                          message.error("Image must smaller than 10MB!");
                          error = Upload.LIST_IGNORE;
                        }
                        if (error === false) {
                          console.log("file -> ", file);
                          getBase64(file, (imageUrl) =>
                            setFileLogo([
                              // ...fileLogo,
                              {
                                lastModified: file.lastModified,
                                lastModifiedDate: file.lastModifiedDate,
                                name: file.name,
                                originFileObj: file,
                                percent: 0,
                                size: file.size,
                                type: file.type,
                                uid: file.uid,
                              },
                            ])
                          );
                          setFormData(file);
                        }
                        return error;
                      }}
                      onPreview={async (file) => {
                        let src = file.url;
                        // console.log("src", dataURItoBlob(src));
                        window.open(src, "_blank");
                        // if (!src) {
                        //   src = await new Promise((resolve) => {
                        //     const reader = new FileReader();
                        //     reader.readAsDataURL(file.originFileObj);
                        //     reader.onload = () => resolve(reader.result);
                        //   });
                        // }
                        // const image = new Image();
                        // image.src = src;
                        // const imgWindow = window.open(src);
                        // imgWindow.document.write(image.outerHTML);
                      }}
                      maxCount={1}
                    >
                      <Button
                        type="dashed"
                        className={
                          fileLogoError === "Required"
                            ? "venue-images-btn log-btn ant-btn-dashed-red"
                            : "venue-images-btn log-btn"
                        }
                      >
                        <p className="ant-upload-text">
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-hint">
                            Add a new file. Unlimited number of files can be
                            uploaded to this field. 10MB limit.
                            <br />
                            Allowed types: png gif jpg jpeg
                          </p>
                        </p>
                      </Button>
                    </Upload>
                  </ImgCrop>
                  {fileLogoError === "Required" && (
                    <span className="c-danger">{fileLogoError}</span>
                  )}
                  {/* <span style={{ color: "red" }}>{fileLogoError}</span> */}
                </Panel>
              </Collapse>
              <br></br>

              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="STEP 3 - Create & Add Signature"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <SignaturePad
                        penColor="black"
                        canvasProps={{
                          className:
                            sigPadError == "Required"
                              ? "sigCanvas ant-btn-dashed-red"
                              : "sigCanvas",
                        }}
                        ref={(ref) => {
                          setSigPad(ref);
                        }}
                      />
                      {sigPadError && (
                        <span className="c-danger">{sigPadError}</span>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      {trimmedDataURL.trimmedDataURL && (
                        <Image
                          height={"150px"}
                          width="100%"
                          src={trimmedDataURL.trimmedDataURL}
                          style={{
                            width: "100%",
                            border: "1px solid #dcdcdc",
                            borderRadius: "3px",
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Space>
                    <Button onClick={clear}>Clear</Button>
                    <Button className="btn-login-outline" onClick={trim}>
                      Generate
                    </Button>
                  </Space>
                  <div style={{ fontSize: 12, marginTop: 10 }}>
                    {" "}
                    <div>
                      To create a free digital signature, follow steps below.
                    </div>
                    {/* <div>
                      <b>Step 1</b>: https://createmysignature.com/{" "}
                    </div>{" "} */}
                    <div>
                      {" "}
                      <b>Step 1</b>: "Draw Signature"
                    </div>{" "}
                    <div>
                      <b>Step 2</b>: Create png
                    </div>{" "}
                    {/* <div>
                      <b>Step 4</b>: Upload png signature
                    </div> */}
                  </div>
                </Panel>
              </Collapse>

              <br></br>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="STEP 4 - Add Your Organization's Approvals"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <div>
                    If you are approved by any / all of the following
                    organizations, please upload your approvals.
                  </div>

                  <br></br>
                  <div style={{ fontWeight: "bold", fontSize: 16 }}>
                    ADA CERP Logo and Legal Text
                  </div>
                  <br></br>
                  {/* <ImgCrop rotate> */}
                  {/* <label className="font-red">
                    <b>Photo upload & Cropping: Select your image orientation</b>
                  </label>
                  <br />
                  <Radio.Group onChange={handleResize1} value={radioData1}>
                    <Radio value={1}>Square</Radio>
                    <Radio value={2}>Rectangle</Radio>
                    <Radio value={3}>Portrait</Radio>
                  </Radio.Group>
                  <Divider /> */}
                  <ImgCrop rotate aspect={imageCrop1.width / imageCrop1.height}>
                    <Upload
                      className="venue-images"
                      listType="picture"
                      maxCount={1}
                      fileList={[...fileLogoCERP]}
                      onRemove={(e) => {
                        console.log("onRemove", e);
                        let onremove = fileLogoCERP.filter(function (
                          item,
                          pos
                        ) {
                          return item.uid != e.uid;
                        });
                        setFileLogoCERP(onremove);
                        setFormData(onremove);
                      }}
                      beforeUpload={(file) => {
                        let error = false;
                        const isJpgOrPng =
                          file.type === "image/jpeg" ||
                          file.type === "image/png" ||
                          file.type === "image/gif" ||
                          file.type === "image/jpg";
                        if (!isJpgOrPng) {
                          message.error(
                            "You can only upload JPG, PNG, GIF, JPEG file!"
                          );
                          error = Upload.LIST_IGNORE;
                        }
                        const isLt2M = file.size / 102400 / 102400 < 10;
                        if (!isLt2M) {
                          message.error("Image must smaller than 10MB!");
                          error = Upload.LIST_IGNORE;
                        }
                        if (error === false) {
                          getBase64(file, (imageUrl) => {
                            console.log("src", file);
                            setFileLogoCERP([
                              // ...fileLogoCERP,
                              {
                                lastModified: file.lastModified,
                                lastModifiedDate: file.lastModifiedDate,
                                name: file.name,
                                originFileObj: file,
                                percent: 0,
                                size: file.size,
                                type: file.type,
                                uid: file.uid,
                              },
                            ]);
                          });
                          setFormData(file);
                        }
                        return error;
                      }}
                      onChange={(file) => {
                        if (file.fileList.length == 0) {
                          setFileLogoCERP([]);
                        }
                      }}
                      onPreview={async (file) => {
                        let src = file.url;
                        window.open(src, "_blank");
                        // console.log("src", file);
                        // if (!src) {
                        //   src = await new Promise((resolve) => {
                        //     const reader = new FileReader();
                        //     reader.readAsDataURL(file.originFileObj);
                        //     reader.onload = () => resolve(reader.result);
                        //   });
                        // }
                        // const image = new Image();
                        // image.src = src;
                        // const imgWindow = window.open(src);
                        // imgWindow.document.write(image.outerHTML);
                      }}
                    >
                      <Button
                        type="dashed"
                        className={
                          fileLogoCERPError
                            ? "venue-images-btn ant-btn-dashed-red"
                            : "venue-images-btn"
                        }
                      >
                        <p className="ant-upload-text">
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-hint">
                            Add a new file. Unlimited number of files can be
                            uploaded to this field. 10MB limit.
                            <br />
                            Allowed types: png gif jpg jpeg
                          </p>
                        </p>
                      </Button>
                    </Upload>
                  </ImgCrop>
                  {fileLogoCERPError && (
                    <span className="c-danger">{fileLogoCERPError}</span>
                  )}
                  {/* </ImgCrop> */}

                  <div style={{ fontSize: 10, marginTop: 10 }}>
                    {" "}
                    <div>Size MUST be exactly 850 X 180.</div>
                    <div>Only upload approved logo and text.</div>
                  </div>

                  <br></br>

                  <div style={{ fontWeight: "bold", fontSize: 16 }}>
                    AGD PACE Logo and Legal Text
                  </div>
                  <br></br>
                  {/* <ImgCrop rotate> */}
                  {/* <label className="font-red">
                    <b>Photo upload & Cropping: Select your image orientation</b>
                  </label>
                  <br />
                  <Radio.Group onChange={handleResize2} value={radioData2}>
                    <Radio value={1}>Square</Radio>
                    <Radio value={2}>Rectangle</Radio>
                    <Radio value={3}>Portrait</Radio>
                  </Radio.Group>
                  <Divider /> */}
                  <ImgCrop rotate aspect={imageCrop2.width / imageCrop2.height}>
                    <Upload
                      className="venue-images"
                      listType="picture"
                      maxCount={1}
                      fileList={[...fileLogoPACE]}
                      onRemove={(e) => {
                        console.log("onRemove", e);
                        let onremove = fileLogoPACE.filter(function (
                          item,
                          pos
                        ) {
                          return item.uid != e.uid;
                        });
                        setFileLogoPACE(onremove);
                        setFormData(onremove);
                      }}
                      beforeUpload={(file) => {
                        let error = false;
                        const isJpgOrPng =
                          file.type === "image/jpeg" ||
                          file.type === "image/png" ||
                          file.type === "image/gif" ||
                          file.type === "image/jpg";
                        if (!isJpgOrPng) {
                          message.error(
                            "You can only upload JPG, PNG, GIF, JPEG file!"
                          );
                          error = Upload.LIST_IGNORE;
                        }
                        const isLt2M = file.size / 102400 / 102400 < 10;
                        if (!isLt2M) {
                          message.error("Image must smaller than 10MB!");
                          error = Upload.LIST_IGNORE;
                        }
                        if (error === false) {
                          getBase64(file, (imageUrl) => {
                            console.log("src", file);
                            setFormData(file);
                            setFileLogoPACE([
                              // ...fileLogoPACE,
                              {
                                lastModified: file.lastModified,
                                lastModifiedDate: file.lastModifiedDate,
                                name: file.name,
                                originFileObj: file,
                                percent: 0,
                                size: file.size,
                                type: file.type,
                                uid: file.uid,
                              },
                            ]);
                          });
                        }
                        return error;
                      }}
                      onPreview={async (file) => {
                        let src = file.url;
                        console.log("src", file);
                        window.open(src, "_blank");
                        // if (!src) {
                        //   src = await new Promise((resolve) => {
                        //     const reader = new FileReader();
                        //     reader.readAsDataURL(file.originFileObj);
                        //     reader.onload = () => resolve(reader.result);
                        //   });
                        // }
                        // const image = new Image();
                        // image.src = src;
                        // const imgWindow = window.open(src);
                        // imgWindow.document.write(image.outerHTML);
                      }}
                    >
                      <Button
                        type="dashed"
                        className={
                          fileLogoPACEError
                            ? "venue-images-btn ant-btn-dashed-red"
                            : "venue-images-btn"
                        }
                      >
                        <p className="ant-upload-text">
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-hint">
                            Add a new file. Unlimited number of files can be
                            uploaded to this field. 10MB limit.
                            <br />
                            Allowed types: png gif jpg jpeg
                          </p>
                        </p>
                      </Button>
                    </Upload>
                  </ImgCrop>
                  {fileLogoPACEError && (
                    <span className="c-danger">{fileLogoPACEError}</span>
                  )}
                  {/* </ImgCrop> */}

                  <div style={{ fontSize: 10, marginTop: 10 }}>
                    {" "}
                    <div>Size MUST be exactly 850 X 180.</div>
                    <div>Only upload approved logo and text.</div>
                  </div>
                </Panel>
              </Collapse>

              <br></br>

              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="STEP 5 - Select Template"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item name="template">
                        <Checkbox.Group>
                          <Checkbox
                            value="Black and Gold"
                            style={{ lineHeight: "32px" }}
                          >
                            Black and Gold
                          </Checkbox>
                          <br></br>
                          <img
                            src={BlackGoldfields}
                            style={{ width: 150 }}
                          ></img>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item name="template">
                        <Checkbox.Group>
                          <Checkbox
                            value="Black and Red"
                            style={{ lineHeight: "32px" }}
                          >
                            {" "}
                            Black and Red
                          </Checkbox>
                          <br></br>
                          <img
                            src={BlackRedfields}
                            style={{ width: 150 }}
                          ></img>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item name="template">
                        <Checkbox.Group>
                          <Checkbox
                            value="Blue and Gold"
                            style={{ lineHeight: "32px" }}
                          >
                            {" "}
                            Blue and Gold
                          </Checkbox>
                          <br></br>
                          <img
                            src={BlueGoldfields_0}
                            style={{ width: 150 }}
                          ></img>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item name="template">
                        <Checkbox.Group>
                          <Checkbox
                            value="Blue and Green"
                            style={{ lineHeight: "32px" }}
                          >
                            {" "}
                            Blue and Green
                          </Checkbox>
                          <br></br>
                          <img
                            src={BlueGreenfields}
                            style={{ width: 150 }}
                          ></img>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item name="template">
                        <Checkbox.Group>
                          <Checkbox
                            value="Blue and Red 1"
                            style={{ lineHeight: "32px" }}
                          >
                            {" "}
                            Blue and Red 1
                          </Checkbox>
                          <br></br>
                          <img src={BlueRedfields} style={{ width: 150 }}></img>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item name="template">
                        <Checkbox.Group>
                          <Checkbox
                            value="Blue and Red 2"
                            style={{ lineHeight: "32px" }}
                          >
                            {" "}
                            Blue and Red 2
                          </Checkbox>
                          <br></br>
                          <img
                            src={BlueRed2fields}
                            style={{ width: 150 }}
                          ></img>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item name="template">
                        <Checkbox.Group>
                          <Checkbox
                            value="Blue and Teal"
                            style={{ lineHeight: "32px" }}
                          >
                            {" "}
                            Blue and Teal
                          </Checkbox>
                          <br></br>
                          <img
                            src={BlueTealfields}
                            style={{ width: 150 }}
                          ></img>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  {fileTemplate && (
                    <span className="c-danger">{fileTemplate}</span>
                  )}
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <br></br>

          <Space>
            <Button
              size="large"
              htmlType="button"
              className="btn-login-outline"
              icon={<EyeOutlined />}
              onClick={() => handlePreviewCertificate()}
              disabled={btnCert}
            >
              Preview Certificate
            </Button>

            {/* <Button
              size="large"
              htmlType="submit"
              className="btn-login-outline"
              style={{ width: 200 }}
              icon={<SaveOutlined />}
              loading={isLoadingCertificate}
              // onClick={() => form.submit()}
            >
              Submit
            </Button> */}
          </Space>
        </Form>
        <br />

        <ModalPreviewCertificate
          previewCertificate={previewCertificate}
          handlePreviewCancel={handlePreviewCancel}
          dataCetificate={dataCetificate}
        />
      </Layout.Content>
      {/* <div style={{ display: "none", position: "absolute", zIndex: 1 }}>
        <Preview id={"jsx-template"}>
          <SendCertificate
            previewCertificate={previewCertificate}
            handlePreviewCancel={handlePreviewCancel}
            dataCetificate={dataCetificate}
          />
        </Preview>
      </div> */}
    </Layout>
  );
}
