import React, { useState, useEffect, useRef } from "react";
import {
	Card,
	Row,
	Col,
	Button,
	Input,
	Divider,
	notification,
	Table,
	Popconfirm,
	Space,
	DatePicker,
	Select,
	InputNumber,
} from "antd";

import {
	DeleteFilled,
	EditFilled,
	PlusCircleOutlined,
	PrinterOutlined,
	UploadOutlined,
	SettingOutlined,
	SearchOutlined,
	FileExcelOutlined,
	DollarCircleFilled,
} from "@ant-design/icons";
const FloatInputNumber = (props) => {
	const [focus, setFocus] = useState(false);
	let { label, value, placeholder, type, required, limit, disabled } = props;

	if (!placeholder) placeholder = label;

	const isOccupied = focus || (value && value.length !== 0);

	const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

	const requiredMark = required ? <span className="text-danger">*</span> : null;

	useEffect(() => {
		// console.log(props)
	}, [props]);

	return (
		<div
			className="float-label input-number"
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
		>
			<InputNumber
				onChange={props.onChange}
				type={"float"}
				value={value}
				size="large"
				style={{ width: "100%" }}
				max={limit ? limit : ""}
				min={0}
				disabled={disabled}
			/>
			<label className={labelClass}>
				{isOccupied ? label : placeholder} {requiredMark}
			</label>
		</div>
	);
};

export default FloatInputNumber;
