import React, { useState, useEffect } from "react";
import { Layout, Form, Row, Col, Collapse } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { BulbOutlined, UpOutlined, QuestionOutlined } from "@ant-design/icons";
import { GET } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import { HiOutlineLightBulb } from "react-icons/hi";
import $ from "jquery";
export default function PageFaqs({ props, permission }) {
  const sub_title = "Frequently Asked ";
  const { Panel } = Collapse;
  const userdata = getUserData();
  // console.log("userdata", userdata);

  const { data: dataFAQ, isLoading: isLoadingFAQ } = GET(
    `api/v1/faq_list?account_type=${userdata.role}`,
    `faq_list`,
    (res) => {
      if (res.success) {
        // console.log(res);
      }
    }
  );

  useEffect(() => {
    setTimeout(() => {
      $(".iframevideofaqs p")
        .filter(function () {
          return !$(this).text().trim();
        })
        .addClass("hide");
    }, [500]);
  }, [dataFAQ]);

  const onChangeCollaps = () => {
    setTimeout(() => {
      $(".iframevideofaqs p")
        .filter(function () {
          return !$(this).text().trim();
        })
        .addClass("hide");
    }, [500]);
  };

  return (
    <Layout
      className="site-layout-background divvideofaqs"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageFaqs"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<QuestionOutlined />}
      />
      <br />
      <Layout.Content
        style={{ padding: "30px", paddingTop: "0px" }}
        className="faqsContlayout"
      >
        <Form wrapperCol={{ span: 24 }} layout="horizontal">
          <br />
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              {dataFAQ &&
                dataFAQ.data.map((row, index) => {
                  return (
                    <Collapse
                      className="faqsCollapse"
                      key={index}
                      onChange={() => onChangeCollaps()}
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <span
                            className="ant-menu-submenu-arrow"
                            style={{
                              color: "#FFF",
                              transform: "rotate(270deg)",
                            }}
                          ></span>
                        ) : (
                          <span
                            className="ant-menu-submenu-arrow"
                            style={{
                              color: "#FFF",
                              transform: "rotate(90deg)",
                            }}
                          ></span>
                        )
                      }
                      expandIconPosition="right"
                      defaultActiveKey={[index == 0 ? "1" : "2"]}
                      style={{ marginBottom: "10px" }}
                    >
                      <Panel
                        header={row.title}
                        key="1"
                        className="accordion bg-darkgray-form"
                      >
                        <div
                          className="iframevideofaqs"
                          dangerouslySetInnerHTML={{
                            __html: row.description,
                          }}
                        />
                      </Panel>
                    </Collapse>
                  );
                })}
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
