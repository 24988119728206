import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import "antd/dist/antd.css";

// from sass
import "../assets/css/helper/helper.css";
import "../assets/css/main/main.css";
// import "../assets/css/main-mobile/main-mobile.css";
import "../assets/css/accordion/accordion.css";
import "../assets/css/card/card.css";
import "../assets/css/button/button.css";
// import "../assets/css/navigation/navigation.css";
import "../assets/css/tooltip/tooltip.css";
import "../assets/css/typography/typography.css";
import "../assets/css/login/login.css";
import "../assets/css/input/input.css";
import "../assets/css/layout-cileya/layout-cileya.css";
import "../assets/css/dashboard/dashboard.css";
import "../assets/css/inputs-cileya/inputs-cileya.css";
import "../assets/css/profile/profile.css";
import "../assets/css/invoice/invoice.css";
import "../assets/css/event/event.css";
import "../assets/css/venue/venue.css";
import "../assets/css/attendees/attendees.css";
import "../assets/css/table/table.css";
import "../assets/css/modal/modal.css";
import "../assets/css/header/header.css";
import "../assets/css/sidemenu/sidemenu.css";
import "../assets/css/layout-event/layout-event.css";
import "../assets/css/private-layout/private-layout.css";

import "../assets/css/responsive-1400/responsive-1400.css";
import "../assets/css/responsive-1200/responsive-1200.css";
import "../assets/css/responsive-992/responsive-992.css";
import "../assets/css/responsive-768/responsive-768.css";
import "../assets/css/responsive-576/responsive-576.css";
import "../assets/css/responsive-425/responsive-425.css";
import "../assets/css/responsive-375/responsive-375.css";
import "../assets/css/responsive-320/responsive-320.css";

import "../assets/css/webapp/webapp.css";

/** template */
import PublicRoute from "./PublicRoute";
import PrivateRouteMember from "./PrivateRouteMember";
/** end template */

/** public views */
import Error403 from "../views/public/Widgets/Error403";
import Error404 from "../views/public/Widgets/Error404";
import Error500 from "../views/public/Widgets/Error500";
import Maintenance from "../views/public/Widgets/Maintenance";
import Success from "../views/public/Widgets/Success";
import PageConfirmEmail from "../views/public/PageConfirmEmail/PageConfirmEmail";
import PageConfirmEmailDeactivate from "../views/public/PageConfirmEmail/PageConfirmEmailDeactivate";

import PageLogin from "../views/public/PageLogin/PageLogin";
import PageForgotPassword from "../views/public/ForgotPassword/PageForgotPassword";
import Page2FA from "../views/public/ForgotPassword/Page2FA";
import PageRegistration from "../views/public/PageRegistration/PageRegistration";
import PageEvent from "../views/public/PageEvent/PageEvent";
import PageLoginMaintenance from "../views/public/PageLogin/PageLoginMaintenance";
/** end public views */

/** private views */
import RoutePageAdmin from "./RoutePageAdmin";

/** private views */
import RoutePageEvent from "./RoutePageEvent";

// Venue PROVIDER
import RoutePageVenue from "./RoutePageVenue";

// Spaker
import RoutePageSpeaker from "./RoutePageSpeaker";

// Member
import RoutePageMember from "./RoutePageMember";

//eventspeaker

import RoutePageEventSpeaker from "./RoutePageEventSpeaker";

import getUserData from "../providers/getUserData";
import { useEffect, useState } from "react";
import { Alert, Input, Modal } from "antd";
import { GET } from "../providers/useAxiosQuery";

import { ClearCacheProvider, useClearCache } from "react-clear-cache";
import { Button } from "antd";
import { GiftOutlined } from "@ant-design/icons";

const queryClient = new QueryClient();
const userdata = getUserData();

const Routes = () => {
	const [demoPass, setDemoPass] = useState("CELIYA2022!");
	const [demoPassInput, setDemoPassInput] = useState("");
	const [showDemoModal, setShowDemoModal] = useState(false);
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		return () => {};
	}, []);

	const handleSubmitDemo = () => {
		if (demoPass == demoPassInput) {
			localStorage.demoMode = true;
			window.location.reload();
			// setShowDemoModal(false);
		} else {
			setShowError(true);
		}
	};

	return process.env.REACT_APP_DEMO_MODE == 1 &&
		localStorage.getItem("demoMode") == null ? (
		<Modal
			visible={true}
			title="Password Protected Site"
			onOk={(e) => handleSubmitDemo()}
		>
			<Input
				placeholder="Please Input Password"
				type="password"
				value={demoPassInput}
				onChange={(e) => setDemoPassInput(e.target.value)}
			/>
			{showError && (
				<Alert style={{ marginTop: 20 }} message="Incorrect Password, try again" />
			)}
		</Modal>
	) : (
		// <ClearCacheProvider>
		<QueryClientProvider client={queryClient}>
			<Router>
				<Switch>
					{/* public route */}
					<PublicRoute exact path="/" component={PageLogin} />
					<PublicRoute
						exact
						path="/maintenance-login"
						component={PageLoginMaintenance}
					/>
					<PublicRoute exact path="/registration" component={PageRegistration} />
					<PublicRoute exact path="/registration-succes" component={Success} />
					<PublicRoute
						exact
						path="/forgot-password"
						component={PageForgotPassword}
					/>
					<PublicRoute
						exact
						path="/forgot-password/:id"
						component={PageForgotPassword}
					/>
					<PublicRoute
						exact
						path="/forgot-password/:token/:id"
						component={PageForgotPassword}
					/>
					<Route exact path="/event/:id" component={PageEvent} />
					<PublicRoute exact path="/authentication" component={Page2FA} />
					<Route exact path="/maintenance" component={Maintenance} />
					<PublicRoute
						exact
						path="/confirmation/:token"
						component={PageConfirmEmail}
					/>
					<PublicRoute exact path="/error-500" component={Error500} />

					{/* Super Admin */}
					{userdata && userdata.role === "SUPER ADMIN" && <RoutePageAdmin />}

					{/* Event Provider */}
					{userdata && userdata.role === "EVENT PROVIDER" && <RoutePageEvent />}

					{/* VENUE */}
					{userdata && userdata.role === "VENUE PROVIDER" && <RoutePageVenue />}

					{/* SPEAKER */}
					{userdata && userdata.role === "SPEAKER" && <RoutePageSpeaker />}

					{/* MEMBER */}
					{userdata && userdata.role === "MEMBER" && <RoutePageMember />}

					{userdata && userdata.role === "EVENT PROVIDER/SPEAKER" && (
						<RoutePageEventSpeaker />
					)}

					<Route path="/403" exact={true} component={Error403} />
					{/* this should always in the bottom */}
					<Route path="*" exact={true} component={Error404} />
				</Switch>
			</Router>
		</QueryClientProvider>
		// </ClearCacheProvider>
	);
};

export default Routes;
