import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Layout, Breadcrumb, Button } from "antd";
import { RightOutlined, HomeOutlined, GiftOutlined } from "@ant-design/icons";

import {
	BiHome,
	//  BiSearch
} from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";

import $ from "jquery";

import Sidemenu from "./Sidemenu";

import ComponentsAdminHeader from "./ComponentsAdmin/ComponentsAdminHeader";
import ComponentsEventHeader from "./ComponentsEvent/ComponentsEventHeader";
import ComponentsVenueHeader from "./ComponentsVenue/ComponentsVenueHeader";
import ComponentsMemberHeader from "./ComponentsMember/ComponentsMemberHeader";
import ComponentsSpeakerHeader from "./ComponentsSpeaker/ComponentsSpeakerHeader";
import ComponentsEventSpeakerHeader from "./ComponentsEventSpeaker/ComponentsEventHeader";

import ComponentsAdminSidemenu from "./ComponentsAdmin/ComponentsAdminSidemenu";
import ComponentsEventSidemenu from "./ComponentsEvent/ComponentsEventSidemenu";
import ComponentsVenueSidemenu from "./ComponentsVenue/ComponentsVenueSidemenu";
import ComponentsMemberSidemenu from "./ComponentsMember/ComponentsMemberSidemenu";
import ComponentsSpeakerSidemenu from "./ComponentsSpeaker/ComponentsSpeakerSidemenu";
import ComponentsEventSpeakerSidemenu from "./ComponentsEventSpeaker/ComponentsEventSidemenu";

import Footer from "./Footer";
import getUserData from "../../providers/getUserData";

// import IdleTimer from "react-idle-timer";

// import { socketio } from "../../../../socketio";

// import getCheckPermission from "../../../providers/getCheckPermission";
// import getPermission from "../../../providers/getPermission";

import { SpinnerDotted } from "spinners-react";
import { GET, POST } from "../../providers/useAxiosQuery";
import IdleTimer from "react-idle-timer";
import { ClearCacheProvider, useClearCache } from "react-clear-cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
export default function Content(props) {
	const { Content } = Layout;
	const history = useHistory();
	const userdata = getUserData();

	const [sideMenuCollapse, setSideMenuCollapse] = useState(
		$(window).width() <= 768 ? true : false
	);
	const [width, setWidth] = useState($(window).width());

	const [dataBread, setDataBread] = useState([]);
	const [dashUrl, setDashUrl] = useState("");

	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			console.log("is windows");
			setTimeout(() => {
				$(".headerRight").addClass("forWindowsHeaderRight");
			}, [500]);
		}
	}, []);

	useEffect(() => {
		if (props) {
			// console.log("props", props.children.props.breadcrumbs);

			setDataBread(props.children.props.breadcrumbs);

			if (userdata.role === "MEMBER") {
				setDashUrl("/member");
			}
			if (userdata.role === "EVENT PROVIDER") {
				setDashUrl("/event-provider");
			}
			if (userdata.role === "VENUE PROVIDER") {
				setDashUrl("/venue-provider");
			}
			if (userdata.role === "SPEAKER") {
				setDashUrl("/speaker");
			}
			if (userdata.role === "SUPER ADMIN") {
				setDashUrl("/admin");
			}
			if (userdata.role === "EVENT PROVIDER/SPEAKER") {
				setDashUrl("/event-provider-speaker");
			}
		}

		function handleResize() {
			setWidth($(window).width());
			if ($(window).width() <= 768) {
				setSideMenuCollapse(true);
			} else {
				setSideMenuCollapse(false);
			}
		}
		window.addEventListener("resize", handleResize);

		$(".ant-btn-quick-link-svg-512-448").attr("viewBox", "0 0 512 448");
	}, [props, userdata]);

	const { mutate: mutateGenerateToken, isLoading: isLoadingtickets } = POST(
		"api/v1/generate/token/viewas",
		"viewas_mutate"
	);

	const handleBackToSuperAdmin = () => {
		let userdata_admin = JSON.parse(localStorage.userdata_admin);
		viewAsBack(userdata_admin.id, true);
	};

	const viewAsBack = (id, backtoadmin = false) => {
		mutateGenerateToken(
			{ id: id, viewas: localStorage.viewas },
			{
				onSuccess: (res) => {
					if (res.success) {
						console.log(res);
						localStorage.token = res.token;
						localStorage.userdata = JSON.stringify(res.data);
						if (backtoadmin) {
							localStorage.removeItem("viewas");
							localStorage.removeItem("userdata_admin");
						}

						var url = window.location.origin + "/admin/view-as";
						window.location.href = url;
					}
				},
			}
		);
	};

	let idleTimer;

	const [timerState, setTimerState] = React.useState({
		timeout: 1000 * 60 * 60,
		// timeout: 5000,
		userLoggedIn: false,
		isTimedOut: false,
	});

	useEffect(() => {
		// console.log("userdata", userdata);
		// console.log("props", props);
		if (!userdata) {
			window.location.reload(true);
			signOut();
		}
		return () => {};
	}, [userdata]);

	const onIdle = (e) => {
		// console.log("user is idle", e);
		// openNotification()
		const isTimedOut = timerState.isTimedOut;
		if (isTimedOut) {
			// props.history.push("/");
		} else {
			idleTimer.reset(); // console.log("timeout");
			signOut();
			setTimerState({ ...timerState, isTimedOut: true });
		}
	};
	const onAction = (e) => {
		setTimerState({ ...timerState, isTimedOut: false });
	};

	const onActive = (e) => {
		// console.log("user is active", e);
		setTimerState({ ...timerState, isTimedOut: false });
	};

	const signOut = () => {
		localStorage.removeItem("userdata");
		localStorage.removeItem("token");
		localStorage.removeItem("viewas");
		localStorage.removeItem("userdata_admin");
		window.location.replace("/");
	};

	const { isLatestVersion, emptyCacheStorage } = useClearCache();
	useEffect(() => {
		console.log("isLatestVersion", isLatestVersion);

		return () => {};
	}, []);
	return (
		<ClearCacheProvider>
			<Layout hasSider className="private-layout">
				{!isLatestVersion && (
					<div className="updateAvailableDiv">
						<div className="div1">
							<GiftOutlined />
						</div>
						<div className="div2">
							<h3>Updates Now Available</h3>
							<p>A new version of this Web App is ready.</p>
							<Button
								onClick={(e) => {
									e.preventDefault();
									emptyCacheStorage().then(() => window.location.reload());
								}}
								type="primary"
								icon={<FontAwesomeIcon icon={faRefresh} style={{ marginRight: 5 }} />}
							>
								Refresh
							</Button>
						</div>
					</div>
				)}
				{props && (
					<>
						{userdata.role === "SUPER ADMIN" ? (
							<ComponentsAdminSidemenu
								history={history}
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
							/>
						) : (
							""
						)}
						{userdata.role === "EVENT PROVIDER" ? (
							<ComponentsEventSidemenu
								history={history}
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
							/>
						) : (
							""
						)}
						{userdata.role === "VENUE PROVIDER" ? (
							<ComponentsVenueSidemenu
								history={history}
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
							/>
						) : (
							""
						)}
						{userdata.role === "SPEAKER" ? (
							<ComponentsSpeakerSidemenu
								history={history}
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
							/>
						) : (
							""
						)}
						{userdata.role === "MEMBER" ? (
							<ComponentsMemberSidemenu
								history={history}
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
							/>
						) : (
							""
						)}
						{userdata.role === "EVENT PROVIDER/SPEAKER" ? (
							<ComponentsEventSpeakerSidemenu
								history={history}
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
							/>
						) : (
							""
						)}

						<Layout
							className={
								"site-layout-main " +
								(sideMenuCollapse ? "layout-main-collapse" : "")
							}
						>
							{userdata.role === "SUPER ADMIN" ? (
								<ComponentsAdminHeader
									width={width}
									setSideMenuCollapse={setSideMenuCollapse}
								/>
							) : (
								""
							)}
							{userdata.role === "EVENT PROVIDER" ? (
								<ComponentsEventHeader
									width={width}
									setSideMenuCollapse={setSideMenuCollapse}
								/>
							) : (
								""
							)}
							{userdata.role === "VENUE PROVIDER" ? (
								<ComponentsVenueHeader
									width={width}
									setSideMenuCollapse={setSideMenuCollapse}
								/>
							) : (
								""
							)}
							{userdata.role === "SPEAKER" ? (
								<ComponentsSpeakerHeader
									width={width}
									setSideMenuCollapse={setSideMenuCollapse}
								/>
							) : (
								""
							)}
							{userdata.role === "MEMBER" ? (
								<ComponentsMemberHeader
									width={width}
									setSideMenuCollapse={setSideMenuCollapse}
								/>
							) : (
								""
							)}
							{userdata.role === "EVENT PROVIDER/SPEAKER" ? (
								<ComponentsEventSpeakerHeader
									width={width}
									setSideMenuCollapse={setSideMenuCollapse}
								/>
							) : (
								""
							)}

							<Breadcrumb
								separator={<span className="arrow" />}
								className="site-bread-crumbs"
								style={{ marginTop: 100 }}
							>
								<Breadcrumb.Item key="/home">
									<Link to={dashUrl + "/dashboard"}>
										<HomeOutlined />
									</Link>
								</Breadcrumb.Item>

								{dataBread &&
									dataBread.map((item, $key) => {
										return (
											<Breadcrumb.Item key={$key}>
												<Link to={item.link ? item.link : "#"}>
													{item.name}
												</Link>
											</Breadcrumb.Item>
										);
									})}
							</Breadcrumb>

							<div className="globalLoading hide ">
								<SpinnerDotted thickness="100" color="293a56" enabled={true} />
							</div>

							{localStorage.viewas == "true" && (
								<>
									{" "}
									<div>
										<div
											style={{
												position: "fixed",
												bottom: 0,
												left: "50%",
												bottom: "4%",
												transform: "translate(-50%, 0)",
												padding: 10,
												fontWeight: 900,
												background: "#888888",
												color: "white",
												zIndex: 999,
												textAlign: "center",
											}}
										>
											Viewing As:{" "}
											{userdata.first_name + " " + userdata.last_name}
											<br></br>
											<Button
												className="btn-login-outline"
												style={{ marginTop: "10px" }}
												onClick={handleBackToSuperAdmin}
											>
												Back to Super Admin View
											</Button>
										</div>
									</div>
									<div className="viewAsBoxTop"></div>
									<div className="viewAsBoxRight"></div>
									<div className="viewAsBoxLeft"></div>
									<div className="viewAsBoxBottom"></div>
								</>
							)}

							{/* <Content
              // permission={props.children.props.permission}
              style={{ marginTop: "0px", minHeight: "100vh" }}
              className="mobileWidthContent"
              match={props.children.props.match}
            >
              {props.children}
            </Content> */}

							{props.children}

							<Footer />
							<IdleTimer
								ref={(ref) => {
									idleTimer = ref;
								}}
								element={document}
								onActive={onActive}
								onIdle={onIdle}
								onAction={onAction}
								debounce={250}
								timeout={timerState.timeout}
							/>
						</Layout>
					</>
				)}
			</Layout>
		</ClearCacheProvider>
	);
}
