import React, { useState, useEffect } from "react";
import {
	Layout,
	Form,
	Button,
	Row,
	Col,
	Checkbox,
	Collapse,
	notification,
	message,
	Upload,
	Divider,
	Radio,
	Popconfirm,
	Switch,
	Modal,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ModalPassword from "./Modals/ModalPassword";
// import ComponentFaqs from "../Components/ComponentFaqs";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import { FaTimes } from "react-icons/fa";
import ImgCrop from "antd-img-crop";
import {
	SaveOutlined,
	UpOutlined,
	DownOutlined,
	UserOutlined,
	EditOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import companyInfo from "../../../../providers/companyInfo";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMask from "../../../../providers/FloatInputMask";
import getUserData from "../../../../providers/getUserData";
import { GET, POST, GETMANUAL } from "../../../../providers/useAxiosQuery";
import states from "states-us";
import optionReferredBy from "../../../../providers/optionReferredBy";

export default function PageProfile({ match, history, permission }) {
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const [dataFetch, setDataFetch] = useState([]);
	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}

		setFormData(val);
		form.resetFields(["state", "zip"]);
		// if (dataFetch.length > 0) {
		//   if (val === dataFetch[0].user_business_infos.country) {
		//     form.setFieldsValue({
		//       state: dataFetch[0].user_business_infos.state,
		//       zip: dataFetch[0].user_business_infos.zip,
		//     });
		//   }
		// }
	};

	const sub_title = "Edit";
	let userdata = getUserData();
	let id = match.params.id;
	const { Panel } = Collapse;
	const [fileList, setFileList] = useState([]);
	const [state, setState] = useState([]);
	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const onChange = ({ fileList: newFileList }) => {
		var _file = newFileList;
		console.log(_file);
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileList(_file);
			setFormData("image_upload");
			// if (_file[0].uid != "-1") {
			//   const data = new FormData();
			//   data.append("id", userdata.id);
			//   data.append("upload", _file[0].originFileObj, _file[0].name);
			//   mutateUpdateProfileImage(data, {
			//     onSuccess: (res) => {
			//       if (res.success) {
			//         // console.log(res.data);
			//         localStorage.setItem("userdata", JSON.stringify(res.data));
			//         notification.success({
			//           message: res.message,
			//           description: res.description,
			//         });
			//       } else {
			//         notification.success({
			//           message: res.message,
			//           description: res.description,
			//         });
			//       }
			//     },
			//     onError: (err) => {
			//       notification.success({
			//         message: err.message,
			//         description: err.description,
			//       });
			//     },
			//   });
			// }
		} else {
			setFileList([]);
		}
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const beforeUpload = (file) => {
		const isJpgOrPng =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "image/gif" ||
			file.type === "image/jpg";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG, PNG, GIF, JPEG file!");
			setFileList([]);
			return;
		}
		const isLt2M = file.size / 102400 / 102400 < 10;
		if (!isLt2M) {
			message.error("Image must smaller than 10MB!");
			setFileList([]);
			return;
		}
		return isJpgOrPng && isLt2M;
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		require_false: {
			required: false,
			message: "Required",
		},
		email: {
			type: "email",
			message: "please enter a valid email",
		},
	};
	const [form] = Form.useForm();
	const [, forceUpdate] = useState({});
	useEffect(() => {
		forceUpdate({});
	}, []);

	const [isModalPassword, setIsModalPassword] = useState(false);

	const {
		mutate: mutateUpdateProfileImage,
		isLoading: isLoadingUpdateProfileImage,
	} = POST("api/v1/uppdate_profile_image", "profile");

	const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } =
		POST("api/v1/uppdate_profile", "profile");

	const onFinishProfile = (values) => {
		console.log("onFinishProfile", values);

		const data = new FormData();
		data.append("id", id ? id : userdata.id);
		data.append("first_name", values.first_name ? values.first_name : "");
		data.append("last_name", values.last_name ? values.last_name : "");
		data.append("email", values.email ? values.email : "");
		data.append("cell_number", values.cell_number ? values.cell_number : "");
		data.append(
			"email_alternative",
			values.email_alternative ? values.email_alternative : ""
		);
		data.append("referred_by", values.referred_by ? values.referred_by : "");
		data.append(
			"business_category_1",
			values.business_category_1 ? values.business_category_1 : ""
		);
		data.append(
			"business_category_2",
			values.business_category_2 ? values.business_category_2 : ""
		);
		data.append(
			"business_category_3",
			values.business_category_3 ? values.business_category_3 : ""
		);
		data.append("facebook", values.facebook ?? "");
		data.append("instagram", values.instagram ?? "");
		data.append("twitter", values.twitter ?? "");
		data.append("youtube", values.youtube ?? "");
		data.append("vimeo", values.vimeo ?? "");

		data.append(
			"youtube_vimeo",
			values.youtube_vimeo ? values.youtube_vimeo : ""
		);
		data.append(
			"business_name",
			values.business_name ? values.business_name : ""
		);
		data.append("address_1", values.address_1 ? values.address_1 : "");
		data.append("address_2", values.address_2 ? values.address_2 : "");
		data.append("country", values.country ? values.country : "");
		data.append("city", values.city ? values.city : "");
		data.append("state", values.state ? values.state : "");
		data.append("zip", values.zip ? values.zip : "");
		data.append(
			"business_categories",
			JSON.stringify(values.business_categories)
		);

		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append("upload", fileList[0].originFileObj, fileList[0].name);
			}
		}

		mutateUpdateProfile(data, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					notification.success({
						message: res.message,
						description: res.description,
					});
				} else {
					notification.success({
						message: res.message,
						description: res.description,
					});
				}
			},
			onError: (err) => {
				notification.success({
					message: err.message,
					description: err.description,
				});
			},
		});
	};

	const [authText, setAuthText] = useState("enable");
	const {
		data: dataProfile,
		refetch: refetchProfile,
		isLoading: isLoadingProfile,
	} = GET(`api/v1/get_by_id?id=${id}`, `profile_admin`, (res) => {
		if (res.success) {
			console.log("dataProfile", res);
			setAuthText(res.data[0].google2fa_enable == 1 ? "disable" : "enable");
			setDataFetch(res.data[0]);

			form.setFieldsValue({
				first_name: res.data[0].first_name,
				last_name: res.data[0].last_name,
				email: res.data[0].email,
				cell_number: res.data[0].cell_number,
				email_alternative: res.data[0].email_alternative,
				referred_by: res.data[0].referred_by ?? "",
				business_category_1: res.data[0].business_category_1,
				business_category_2: res.data[0].business_category_2,
				business_category_3: res.data[0].business_category_3,
				username: res.data[0].username,
				facebook: res.data[0].facebook,
				instagram: res.data[0].instagram,
				youtube_vimeo: res.data[0].youtube_vimeo,
				business_categories: res.data[0].business_categories
					? JSON.parse(res.data[0].business_categories)
					: [],
				facebook: res.data[0].facebook ? res.data[0].facebook : "",
				instagram: res.data[0].instagram ? res.data[0].instagram : "",
				youtube: res.data[0].youtube ? res.data[0].youtube : "",
				twitter: res.data[0].twitter ? res.data[0].twitter : "",
				vimeo: res.data[0].vimeo ? res.data[0].vimeo : "",
			});

			if (res.data[0].member_company) {
				form.setFieldsValue({
					country: res.data[0].member_company.country,
				});
				if (
					res.data[0].member_company.country &&
					res.data[0].member_company.country === "United States"
				) {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateUS);
					setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
				} else if (
					res.data[0].member_company.country &&
					res.data[0].member_company.country === "Mexico"
				) {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateMX);
					setOptionZip(/(^\d{5}$)/);
				} else if (
					res.data[0].member_company.country &&
					res.data[0].member_company.country === "Canada"
				) {
					setStateLabel("County");
					setZipLabel("Postal Code");
					setOptionState(stateCA);
					setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
				} else {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateUS);
					setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
				}
				if (
					res.data[0].member_company &&
					res.data[0].member_company.business_name
				) {
					form.setFieldsValue({
						business_name: res.data[0].member_company.business_name,
					});
				} else {
					form.resetFields(["business_name"]);
				}
				if (
					res.data[0].member_company &&
					res.data[0].member_company.address_1
				) {
					form.setFieldsValue({
						address_1: res.data[0].member_company.address_1,
					});
				} else {
					form.resetFields(["address_1"]);
				}
				if (
					res.data[0].member_company &&
					res.data[0].member_company.address_2
				) {
					form.setFieldsValue({
						address_2: res.data[0].member_company.address_2,
					});
				} else {
					form.resetFields(["address_2"]);
				}
				if (res.data[0].member_company && res.data[0].member_company.city) {
					form.setFieldsValue({
						city: res.data[0].member_company.city,
					});
				} else {
					form.resetFields(["city"]);
				}
				if (res.data[0].member_company && res.data[0].member_company.state) {
					form.setFieldsValue({
						state: res.data[0].member_company.state,
					});
				} else {
					form.resetFields(["state"]);
				}
				if (res.data[0].member_company && res.data[0].member_company.zip) {
					form.setFieldsValue({
						zip: res.data[0].member_company.zip,
					});
				} else {
					form.resetFields(["zip"]);
				}
			}
			if (!res.data[0].cell_number) {
				form.resetFields(["cell_number"]);
			}
			if (!res.data[0].email_alternative) {
				form.resetFields(["email_alternative"]);
			}
			if (!res.data[0].referred_by) {
				form.resetFields(["referred_by"]);
			}
			if (!res.data[0].business_category_1) {
				form.resetFields(["business_category_1"]);
			}
			if (!res.data[0].business_category_2) {
				form.resetFields(["business_category_2"]);
			}
			if (!res.data[0].business_category_3) {
				form.resetFields(["business_category_3"]);
			}
			if (!res.data[0].facebook || res.data[0].facebook == "") {
				form.resetFields(["facebook"]);
			}
			if (!res.data[0].instagram || res.data[0].instagram == "") {
				form.resetFields(["instagram"]);
			}
			if (!res.data[0].youtube_vimeo || res.data[0].youtube_vimeo == "") {
				form.resetFields(["youtube_vimeo"]);
			}
			if (!res.data[0].twitter || res.data[0].twitter == "") {
				form.resetFields(["twitter"]);
			}
			if (!res.data[0].vimeo || res.data[0].vimeo == "") {
				form.resetFields(["vimeo"]);
			}
			if (!res.data[0].youtube || res.data[0].youtube == "") {
				form.resetFields(["youtube"]);
			}

			let image_type =
				res.data[0] && res.data[0].upload ? res.data[0].upload.split("/") : "";
			if (image_type[0] != undefined) {
				image_type =
					image_type[0] == "https:"
						? res.data[0].upload
						: companyInfo().apiUrl + "storage/" + res.data[0].upload;
				setFileList([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: image_type,
					},
				]);
			} else {
				setFileList([]);
				image_type = "";
			}
		}
	});

	const [deactivate, setDeactivate] = useState(false);

	const { mutate: mutateDeactive, isLoading: isLoadingDeactive } = POST(
		"api/v1/user_deactive_admin",
		"user_deactive_admin"
	);

	const handleDeactivate = () => {
		mutateDeactive(
			{
				id: id,
				link: window.location.origin,
			},
			{
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: res.message,
							description: res.description,
						});

						history.push("/admin/profiles/all");
					}
				},
				onError: (err) => {},
			}
		);
	};

	const [formData, setFormData] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (formData) {
				form.submit();
				setFormData("");
			}
		}, 3000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [formData]);

	const handleInput = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const handleSelect = (val, option) => {
		setFormData(val);
	};

	const handleTextArea = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const [radioData, setRadioData] = useState(1);
	const [imageCrop, setImageCrop] = useState({
		width: 1,
		height: 1,
	});

	const handleResize = (val) => {
		console.log("val", val.target.value);
		setRadioData(val.target.value);
		if (val.target.value === 1) {
			setImageCrop({
				width: 1,
				height: 1,
			});
		} else if (val.target.value === 2) {
			setImageCrop({
				// width: 8.5,
				// height: 2,
				width: 3.9,
				height: 2.6,
			});
		} else if (val.target.value === 3) {
			setImageCrop({
				width: 1,
				height: 1.5,
			});
		}
	};

	const {
		mutate: mutateUpdatePrimary,
		isLoading: isLoadingMutateUpdatePrimary,
	} = POST(
		"api/v1/member_company/mutateUpdatePrimary",
		"member_company_account"
	);

	const setPrimaryCompany = (e, id) => {
		let primary = e ? 1 : 0;

		mutateUpdatePrimary(
			{ primary: primary, user_id: match.params.id, id: id },
			{
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Success",
							description: "Primary Company Successfully Saved!",
						});
						refetchProfile();
					} else {
						notification.error({
							message: res.message,
						});
					}
				},
				onError: (res) => {},
			}
		);
	};
	const ceView = (id) => {
		history.push("/admin/profiles/edit/edit-company/" + id);
	};

	const [form_modal] = Form.useForm();
	const { mutate: mutateCreateAccount, isLoading: isLoadingCreateAccount } =
		POST("api/v1/member_company/createAcc", "member_company_account");
	const [primary, setPrimary] = useState(0);
	const [showCompany, setShowCompany] = useState(false);

	const onCancelCompany = () => {
		setShowCompany(false);
	};
	const onFinishMyAccount = (values) => {
		let data = {
			...values,
			user_id: dataFetch.id,
			primary: primary,
		};
		console.log("onFinishMyAccount", data);
		mutateCreateAccount(data, {
			onSuccess: (res) => {
				if (res.success) {
					form.resetFields();
					notification.success({
						message: "Success",
						description: "Successfully Saved!",
					});
					setShowCompany(false);
					refetchProfile();
				} else {
					notification.error({
						message: res.message,
					});
				}
			},
			onError: (res) => {},
		});
	};

	const {
		data: dataUser,
		isLoading: isLoadingUser,
		refetch: refetchDataUser,
	} = GETMANUAL(
		`api/v1/get_by_id?id=${dataFetch && dataFetch.id}`,
		"get_by_id",
		(res) => {
			if (res.success) {
				console.log("@res", res);
				let planType = res.data[0].user_plans
					? res.data[0].user_plans.stripe_subscription_status == "active"
						? res.data[0].user_plans.account_type_plans
							? res.data[0].user_plans.account_type_plans.plan
							: "0"
						: "0"
					: "0";

				console.log("planType", planType);
				if (planType == "0") {
					let business_category = [
						{
							label: "Free",
							value: "Free",
						},
					];
					setBusinessCategory(
						business_category.sort((a, b) => a.label.localeCompare(b.label))
					);
				}

				if (planType.includes("Group 1")) {
					let business_category = [
						{
							label: "Dental Student",
							value: "Dental Student",
						},
						{
							label: "Dental Assistants",
							value: "Dental Assistants",
						},
						{
							label: "Front Office",
							value: "Front Office",
						},
						{
							label: "Office Manager",
							value: "Office Manager",
						},
						{
							label: "Office Coordinator",
							value: "Office Coordinator",
						},
						{
							label: "Dental Practice Owner",
							value: "Dental Practice Owner",
						},
					];
					setBusinessCategory(
						business_category.sort((a, b) => a.label.localeCompare(b.label))
					);
				}
				if (planType.includes("Group 2")) {
					let business_category = [
						{
							label: "Retired Dentist",
							value: "Retired Dentist",
						},
						{
							label: "Denturist",
							value: "Denturist",
						},
						{
							label: "Dental Hygienist",
							value: "Dental Hygienist",
						},
						{
							label: "Dental Health Aid Therapist (DHAT)",
							value: "Dental Health Aid Therapist (DHAT)",
						},
						{
							label: "Dental Professor/Instructor",
							value: "Dental Professor/Instructor",
						},
						{
							label: "Dental Lab Techs",
							value: "Dental Lab Techs",
						},
					];
					setBusinessCategory(
						business_category.sort((a, b) => a.label.localeCompare(b.label))
					);
				}
				if (planType.includes("Group 3")) {
					let business_category = [
						{
							label: "Dentists",
							value: "Dentists",
						},
						{
							label: "Dental Specialist",
							value: "Dental Specialist",
						},
						{
							label: "Endodontist",
							value: "Endodontist",
						},
						{
							label: "Oral Surgeon",
							value: "Oral Surgeon",
						},
						{
							label: "Periodontist",
							value: "Periodontist",
						},
						{
							label: "Pediatric Dentist",
							value: "Pediatric Dentist",
						},
						{
							label: "Prosthodontist",
							value: "Prosthodontist",
						},
						{
							label: "Dental Anesthesiologist",
							value: "Dental Anesthesiologist",
						},
						{
							label: "Dental Public Health",
							value: "Dental Public Health",
						},
						{
							label: "Oral Maxillofacial Pathologist",
							value: "Oral Maxillofacial Pathologist",
						},
						{
							label: "Oral Maxillofacial Radiologist",
							value: "Oral Maxillofacial Radiologist",
						},
						{
							label: "Oral Medicine",
							value: "Oral Medicine",
						},
						{
							label: "Oral Facial Pain Specialist",
							value: "Oral Facial Pain Specialist",
						},
						{
							label: "Other",
							value: "Other",
						},
					];
					setBusinessCategory(
						business_category.sort((a, b) => a.label.localeCompare(b.label))
					);
				}
			}
		}
	);
	const [businessCategory, setBusinessCategory] = useState([
		{
			label: "Dental Lab",
			value: "Dental Lab",
		},
		{
			label: "Dental Product",
			value: "Dental Product",
		},
		{
			label: "Dental Specialist",
			value: "Dental Specialist",
		},
		{
			label: "Dental Supply Company",
			value: "Dental Supply Company",
		},
		{
			label: "Dentist",
			value: "Dentist",
		},
		{
			label: "Educator",
			value: "Educator",
		},
		{
			label: "Medical Doctor",
			value: "Medical Doctor",
		},
		{
			label: "Motivational Speaker",
			value: "Motivational Speaker",
		},
		{
			label: "Other",
			value: "Other",
		},
		{
			label: "Professional Speaker",
			value: "Professional Speaker",
		},
		{
			label: "Professor",
			value: "Professor",
		},
		{
			label: "Study Club",
			value: "Study Club",
		},
	]);

	useEffect(() => {
		if (dataFetch) {
			if (dataFetch.role == "MEMBER") {
				refetchDataUser();
			}
		}
	}, [dataFetch]);

	const onChangeOption = (val, option) => {
		setFormData(val);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageProfile"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<UserOutlined />}
			/>

			<ModalPassword
				isModalPassword={isModalPassword}
				setIsModalPassword={setIsModalPassword}
				dataProfile={dataProfile && dataProfile.data ? dataProfile.data : []}
				userdata={userdata}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br />
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinishProfile}
				>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Login Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="username"
												rules={[validator.require_false]}
												hasFeedback
											>
												<FloatInput
													label="Username"
													placeholder="Username"
													disabled={true}
													//   readOnly={true}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Link
										to="#"
										onClick={(e) => setIsModalPassword(true)}
										className="changePassText"
									>
										Change Password
									</Link>
								</Panel>
							</Collapse>

							<br></br>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Personal Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="first_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
													onChange={handleInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="last_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Last Name"
													placeholder="Last Name"
													onChange={handleInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="email"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Email Address"
													placeholder="Email Address"
													onChange={handleInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="cell_number"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputMask
													label="Cell Phone"
													placeholder="Cell Phone"
													maskLabel="cell_number"
													maskType="999-999-9999"
													onChange={handleInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<div>
										<Link
											to={"/admin/my-account/profile/2fa"}
											className="link2factor"
										>
											{" "}
											CLICK HERE
										</Link>{" "}
										to {authText} 2-Factor Authentication (2FA)
									</div>
									<br></br>
									<Row gutter={24}>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											xxl={12}
											className="colemailalt"
										>
											<Form.Item
												name="email_alternative"
												rules={[validator.require_false, validator.email]}
												className="alternateEmailfont"
											>
												<FloatInput
													label="Email Address (Alternative / Optional)"
													placeholder="Email Address (Alternative / Optional)"
													onChange={handleInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="referred_by"
												rules={[validator.require_false]}
												className="form-select-error"
											>
												<FloatSelect
													label="Referred by"
													placeholder="Referred by"
													options={optionReferredBy}
													onChange={handleSelect}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="business_categories"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error-multi"
											>
												<FloatSelect
													label="Business Category"
													placeholder="Business Category"
													options={businessCategory}
													multi="multiple"
													onChange={handleSelect}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Panel>
							</Collapse>

							<br></br>
							{dataFetch && dataFetch.role == "MEMBER" && (
								<>
									<Collapse
										expandIcon={({ isActive }) =>
											isActive ? (
												<span
													className="ant-menu-submenu-arrow"
													style={{
														color: "#FFF",
														transform: "rotate(270deg)",
													}}
												></span>
											) : (
												<span
													className="ant-menu-submenu-arrow"
													style={{
														color: "#FFF",
														transform: "rotate(90deg)",
													}}
												></span>
											)
										}
										expandIconPosition="right"
										defaultActiveKey={["1"]}
									>
										<Panel
											header="Business Information"
											key="1"
											className="accordion bg-darkgray-form"
										>
											<div>
												<Button
													className="btn-login-outline"
													icon={<PlusCircleOutlined />}
													onClick={() => {
														setShowCompany(true);
													}}
													style={{ marginBottom: 10 }}
												>
													Add Company
												</Button>
											</div>

											{dataFetch &&
												dataFetch.companies.map((item, key) => {
													return (
														<div key={key}>
															<Row>
																<Col xs={20} sm={20} md={12}>
																	<div>
																		<Link
																			className="companyTitle"
																			to={
																				"/member/my-account/my-company/" +
																				item.id
																			}
																		>
																			{item.business_name}
																		</Link>
																	</div>
																	<div>{item.title ? item.title : ""}</div>
																	<div>
																		{item.address_1 ? item.address_1 : ""}
																	</div>
																	<div>
																		{item.address_2 ? item.address_2 : ""}
																	</div>
																	<div>
																		{item.city}, {item.state} {item.zip}
																	</div>
																	<div>
																		{item.business_phone
																			? " Business Phone: " +
																			  item.business_phone
																			: ""}
																	</div>
																	<span>Primary Dental Company</span>{" "}
																	<Switch
																		size="small"
																		checked={item.primary == 1 ? true : false}
																		onChange={(e) =>
																			setPrimaryCompany(e, item.id)
																		}
																	/>{" "}
																</Col>
																<Col xs={4} sm={4} md={12}>
																	<span
																		style={{
																			float: "right",
																			marginRight: 5,
																		}}
																	>
																		<Button
																			type="primary"
																			className="btn-warning-outline"
																			icon={<EditOutlined />}
																			onClick={() => ceView(item.id)}
																		></Button>
																	</span>
																</Col>
															</Row>

															<br></br>
														</div>
													);
												})}
										</Panel>
									</Collapse>
									<br></br>
								</>
							)}
							{dataFetch &&
								dataFetch.role !== "MEMBER" &&
								dataFetch.role !== "SUPER ADMIN" &&
								dataFetch.role !== "SPEAKER" &&
								dataFetch.role !== "EVENT PROVIDER/SPEAKER" && (
									<>
										<Collapse
											expandIcon={({ isActive }) =>
												isActive ? (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(270deg)",
														}}
													></span>
												) : (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(90deg)",
														}}
													></span>
												)
											}
											expandIconPosition="right"
											defaultActiveKey={["1"]}
										>
											<Panel
												header="Business Information"
												key="1"
												className="accordion bg-darkgray-form"
											>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
														<Form.Item
															name="business_name"
															rules={[validator.require]}
															hasFeedback
														>
															<FloatInput
																label="Business Name"
																placeholder="Business Name"
																onChange={handleInput}
															/>
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="country"
															rules={[validator.require]}
															className="form-select-error"
															hasFeedback
														>
															<FloatSelect
																label="Country"
																placeholder="Country"
																options={country}
																onChange={handleCountry}
															/>
														</Form.Item>
													</Col>
												</Row>

												<Row gutter={24}>
													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="address_1"
															rules={[validator.require]}
															hasFeedback
														>
															<FloatInput
																label="Address 1"
																placeholder="Address 1"
																onChange={handleInput}
															/>
														</Form.Item>
													</Col>
													<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
														<Form.Item
															name="address_2"
															rules={[validator.require_false]}
															hasFeedback
														>
															<FloatInput
																label="Address 2"
																placeholder="Address 2"
																onChange={handleInput}
															/>
														</Form.Item>
													</Col>
												</Row>

												<Row gutter={24}>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														<Form.Item
															name="city"
															rules={[validator.require]}
															hasFeedback
														>
															<FloatInput
																label="City"
																placeholder="City"
																onChange={handleInput}
															/>
														</Form.Item>
													</Col>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														<Form.Item
															name="state"
															rules={[validator.require]}
															hasFeedback
															className="form-select-error"
														>
															<FloatSelect
																label={stateLabel}
																placeholder={stateLabel}
																options={optionState}
																onChange={handleSelect}
															/>
														</Form.Item>
													</Col>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														<Form.Item
															name="zip"
															rules={[
																validator.require,
																{
																	pattern: optionZip,
																	message: "Invalid " + zipLabel,
																},
															]}
															hasFeedback
														>
															<FloatInput
																label={zipLabel}
																placeholder={zipLabel}
																onChange={handleInput}
															/>
														</Form.Item>{" "}
													</Col>
												</Row>
											</Panel>
										</Collapse>
										<br></br>
									</>
								)}

							{dataFetch &&
								dataFetch.role !== "SPEAKER" &&
								dataFetch.role !== "EVENT PROVIDER/SPEAKER" && (
									<>
										<Collapse
											expandIcon={({ isActive }) =>
												isActive ? (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(270deg)",
														}}
													></span>
												) : (
													<span
														className="ant-menu-submenu-arrow"
														style={{
															color: "#FFF",
															transform: "rotate(90deg)",
														}}
													></span>
												)
											}
											expandIconPosition="right"
											defaultActiveKey={["1"]}
										>
											<Panel
												header="Social Media"
												key="1"
												className="accordion bg-darkgray-form"
											>
												<Form.Item name="facebook" hasFeedback>
													<FloatInput
														label="Your Facebook URL Link"
														placeholder="Your Facebook URL Link"
														onChange={handleInput}
													/>
												</Form.Item>
												<Form.Item name="instagram" hasFeedback>
													<FloatInput
														label="Your Instagram Account"
														placeholder="Your Instagram Account"
														onChange={handleInput}
													/>
												</Form.Item>
												<Form.Item name="twitter" hasFeedback>
													<FloatInput
														label="Your Twitter Account (Optional)"
														placeholder="Your Twitter Account (Optional)"
														onChange={handleInput}
													/>
												</Form.Item>
												<Form.Item name="youtube" hasFeedback>
													<FloatInput
														label="Your Youtube Account (Optional)"
														placeholder="Your Youtube Account (Optional)"
														onChange={handleInput}
													/>
												</Form.Item>
												<Form.Item name="vimeo" hasFeedback>
													<FloatInput
														label="Your Vimeo Account (Optional)"
														placeholder="Your Vimeo Account (Optional)"
														onChange={handleInput}
													/>
												</Form.Item>

												{/* <Form.Item name="youtube_vimeo" hasFeedback>
                    <FloatInput
                      label="Your Youtube / Vimeo Account "
                      placeholder="Your Youtube / Vimeo Account "
                      onChange={handleInput}
                    />
                  </Form.Item>{" "} */}
											</Panel>
										</Collapse>
										<br></br>
									</>
								)}
						</Col>

						<Col xs={24} sm={24} md={8} lg={8}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Profile Photo"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<label className="font-red">
										<b>
											Photo upload & Cropping: Select your image orientation
										</b>
									</label>
									<br />
									<Radio.Group
										// className="crop-center"
										onChange={handleResize}
										value={radioData}
									>
										<Radio value={1}>Square</Radio>
										<Radio value={2}>Rectangle</Radio>
										<Radio value={3}>Portrait</Radio>
									</Radio.Group>
									<Divider />
									<div>
										<ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
											<Upload
												// action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
												listType="picture-card"
												style={{ width: "200px" }}
												maxCount={1}
												action={false}
												customRequest={false}
												fileList={fileList}
												onChange={onChange}
												onPreview={onPreview}
												beforeUpload={beforeUpload}
												loading={isLoadingUpdateProfileImage}
												className="profilePhoto"
											>
												{fileList.length < 1 && "+ Upload"}
											</Upload>
										</ImgCrop>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<span>One file Only. 10 MB limit.</span>
										<span>
											Your selected profile photo will be visible to all event
											organizers and event attendees. <br></br>
										</span>
									</div>
									<div className="c-secondary" style={{ fontSize: "12px" }}>
										Allowed types: png gif jpg jpeg.
									</div>
								</Panel>
							</Collapse>

							<br></br>
							{dataFetch && dataFetch.role !== "SUPER ADMIN" && (
								<Collapse
									expandIcon={({ isActive }) =>
										isActive ? (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(270deg)" }}
											></span>
										) : (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(90deg)" }}
											></span>
										)
									}
									expandIconPosition="right"
									defaultActiveKey={["1"]}
								>
									<Panel
										header={
											dataFetch.status === "Active"
												? "Deactivate Account"
												: "Activate Account"
										}
										key="1"
										className="accordion bg-darkgray-form"
									>
										{dataFetch.status === "Active" && (
											<div>
												No longer need your account and want to deactivate it?
											</div>
										)}
										<br></br>
										<Checkbox onChange={(e) => setDeactivate(e.target.checked)}>
											Yes I understand that by{" "}
											{dataFetch.status === "Active"
												? "deactivating this account user will have no longer access "
												: "activating this account user will have access "}{" "}
											to information and all historical data.
										</Checkbox>
										<br></br>
										<br></br>

										{/* <Button
                      size="large"
                      className="btn-secondary-outline"
                      style={{ width: "100%", marginTop: 10 }}
                      disabled={deactivate ? false : true}
                      onClick={() => handleDeactivate()}
                    >
                      Deactivate My Account
                    </Button> */}

										<Popconfirm
											title={
												dataFetch.status === "Active"
													? "Are you sure to Deactivate this Account?"
													: "Are you sure to Activate this Account?"
											}
											onConfirm={() => handleDeactivate()}
											okText="Confirm"
											cancelText="Cancel"
											disabled={deactivate ? false : true}
										>
											<Button
												size="large"
												className={
													dataFetch.status === "Active"
														? "btn-red-outline deactivate-button-accprof"
														: "btn-success-outline deactivate-button-accprof"
												}
												disabled={deactivate ? false : true}
												block
											>
												{dataFetch.status === "Active"
													? "Deactivate This Account"
													: "Activate This Account"}
											</Button>
										</Popconfirm>
									</Panel>
								</Collapse>
							)}
						</Col>
					</Row>

					<Row gutter={24}>
						<Col
							xs={24}
							sm={24}
							md={16}
							lg={16}
							xl={16}
							xxl={16}
							className="adminProfSubmit"
						>
							<div>
								<Button
									size="large"
									className="btn-login-outline "
									htmlType="submit"
									style={{ width: 200, marginTop: "20px" }}
									icon={<SaveOutlined />}
									// disabled={
									//   !form.isFieldsTouched(true) ||
									//   !!form
									//     .getFieldsError()
									//     .filter(({ errors }) => errors.length).length
									// }
									loading={isLoadingUpdateProfile}
								>
									Save
								</Button>
							</div>
						</Col>
					</Row>
				</Form>
			</Layout.Content>
			<Modal
				className="modal-login"
				title={"Add Company "}
				visible={showCompany}
				width={650}
				onCancel={onCancelCompany}
				footer={[
					<Button onClick={onCancelCompany}>Cancel</Button>,
					<Button
						className="btn-login-outline"
						icon={<SaveOutlined />}
						onClick={() => form_modal.submit()}
						loading={isLoadingCreateAccount}
					>
						Save
					</Button>,
				]}
				closeIcon={<FaTimes className="modal-close-icon" />}
			>
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form_modal}
					onFinish={onFinishMyAccount}
				>
					<Form.Item
						name="business_name"
						rules={[validator.require]}
						hasFeedback
					>
						<FloatInput
							label="Business/Practice Name"
							placeholder="Business/Practice Name"
						/>
					</Form.Item>
					<Form.Item name="title" rules={[validator.require]} hasFeedback>
						<FloatInput label="Title" placeholder="Title" />
					</Form.Item>
					<Form.Item name="primary">
						<Switch
							// defaultChecked
							checked={primary == 1 ? true : false}
							onChange={(e) => setPrimary(e ? 1 : 0)}
						/>{" "}
						<span>Is this your Primary Company of Employment?</span>
					</Form.Item>{" "}
					<Form.Item
						name="country"
						rules={[
							{
								required: true,
								message: "This field field is required.",
							},
						]}
						className="form-select-error"
						hasFeedback
					>
						<FloatSelect
							label="Country"
							placeholder="Country"
							options={country}
						/>
					</Form.Item>
					<Form.Item name="address_1" rules={[validator.require]} hasFeedback>
						<FloatInput label="Address 1" placeholder="Address 1" />
					</Form.Item>
					<Form.Item name="address_2">
						<FloatInput label="Address 2" placeholder="Address 2" />
					</Form.Item>
					<Row>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Form.Item name="city" rules={[validator.require]} hasFeedback>
								<FloatInput label="City" placeholder="City" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Form.Item
								name="state"
								rules={[validator.require]}
								hasFeedback
								className="form-select-error"
							>
								<FloatSelect
									label={stateLabel}
									placeholder={stateLabel}
									options={optionState}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Form.Item
								name="zip"
								rules={[
									validator.require,
									{
										pattern: optionZip,
										message: "Invalid " + zipLabel,
									},
								]}
								hasFeedback
							>
								<FloatInput label={zipLabel} placeholder={zipLabel} />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name="business_phone"
						rules={[validator.require]}
						hasFeedback
					>
						<FloatInputMask
							label="Business Phone1"
							placeholder="Business Phone1"
							maskLabel="business_phone"
							maskType="999-999-9999"
						/>
					</Form.Item>
					<Form.Item name="business_phone2">
						<FloatInputMask
							label="Business Phone 2"
							placeholder="Business Phone 2"
							maskLabel="business_phone2"
							maskType="999-999-9999"
						/>
					</Form.Item>
					<Form.Item name="business_fax">
						<FloatInput label="Business Fax" placeholder="Business Fax" />
					</Form.Item>
					<Form.Item
						name="business_email"
						rules={[
							{
								type: "email",
								message: "please enter a valid email",
							},
						]}
					>
						<FloatInput label="Business Email" placeholder="Business Email" />
					</Form.Item>{" "}
					<Form.Item
						name="business_categories"
						rules={[validator.require]}
						hasFeedback
						className="form-select-error-multi"
					>
						<FloatSelect
							label="Business Category"
							placeholder="Business Category"
							options={businessCategory}
							multi="multiple"
						/>
					</Form.Item>
					<Form.Item name="website">
						<FloatInput label="Website" placeholder="Website" />
					</Form.Item>
				</Form>
			</Modal>
		</Layout>
	);
}
