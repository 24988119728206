import React, { useState, useEffect } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	Tooltip,
	Collapse,
	Form,
	Typography,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
// import ComponentFaqs from "../Components/ComponentFaqs";
import {
	EditOutlined,
	TeamOutlined,
	MailOutlined,
	PieChartOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET } from "../../../../providers/useAxiosQuery";
import { useHistory, Link } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import moment from "moment";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";

import toCurrency from "../../../../providers/toCurrency";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
import leftArrow from "../../../../assets/img/left-arrow.png";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import FloatSelectv2 from "../../../../providers/FloatSelectv2";
import optionYear from "../../../../providers/optionYear";
import optionMonth from "../../../../providers/optionMonth";
import optionStateCodes from "../../../../providers/optionStateCodes";
export default function PageStatsFinancial({ props, permission }) {
	highchartsSetOptions(Highcharts);
	const sub_title = "Financial";
	const history = useHistory();
	const { Panel } = Collapse;
	const [windowWidth, setWindowWidth] = useState($(window).width());
	useEffect(() => {
		function handleResize() {
			setWindowWidth($(window).width());
		}
		window.addEventListener("resize", handleResize);

		return () => {};
	}, []);

	const [graphRevenuePerYearFilter, setGraphRevenuePerYearFilter] = useState({
		action: "yearly",
		year: "",
		quarter: "",
		month: "",
		week: "",
	});
	const [xTitle, setXTitle] = useState("");
	const { refetch: refetchGraphRevenuePerYear } = GET(
		`api/v1/revenue_graph_per_year?${new URLSearchParams(
			graphRevenuePerYearFilter
		)}`,
		"dashboard_revenue_graph_per_year",
		(res) => {
			console.log("data_series_value res", res);
			if (res.data) {
				let data = res.data;
				let action = res.data.action;

				let xAxisTitleText = "YEAR";
				let subtitleText = "CLICK THE COLUMNS TO VIEW PER QUARTER";

				if (action === "yearly") {
					xAxisTitleText = "YEAR";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER QUARTER";
				} else if (action === "quarterly") {
					xAxisTitleText = "QUARTER";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER MONTH";
				} else if (action === "monthly") {
					xAxisTitleText = "MONTH";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER WEEK";
				} else if (action === "daily") {
					xAxisTitleText = "DAY";
					subtitleText = "CLICK THE COLUMNS TO GO BACK TO PER YEAR";
				}
				setXTitle(xAxisTitleText);
				if (document.getElementById("div_revenue_by_year")) {
					Highcharts.chart(
						"div_revenue_by_year",
						{
							chart: {
								type: "column",
								events: {
									// load: function (event) {
									// 	event.target.reflow();
									// },
									click: function (e) {
										let action = data.action;
										let downTo = data.downTo;
										let series_name =
											data.data_series_name[
												Math.abs(Math.round(e.xAxis[0].value))
											];

										if (action === "yearly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												year: series_name,
											});
										}
										if (action === "quarterly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												quarter: series_name,
											});
										}
										if (action === "monthly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												month: series_name,
											});
										}
										if (action === "daily") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												week: "",
												month: "",
												quarter: "",
											});
										}
									},
								},
							},
							title: {
								text: null,
							},
							subtitle: {
								text: subtitleText,
							},
							xAxis: {
								// title: {
								// 	text: xAxisTitleText,
								// },
								categories: data.data_series_name,
								crosshair: true,
								type: "category",
							},
							yAxis: {
								title: {
									text: null,
								},
								labels: {
									formatter: function () {
										return `$${Highcharts.numberFormat(
											this.value,
											0,
											"",
											","
										)}`;
									},
								},
							},
							tooltip: {
								headerFormat: "",
								formatter: function () {
									return `<span style="color:${this.color}; font-size:14px;">${
										this.x
									}</span><br/>TOTAL: <b>$${this.y.toFixed(2)}</b>`;
								},
								// pointFormat: `<span style="color:{point.color}; font-size:14px;">{point.name}</span><br/>TOTAL: <b>{point.y:.f}</b>`,
							},
							legend: {
								enabled: false,
							},
							plotOptions: {
								series: {
									borderWidth: 0,
									dataLabels: {
										enabled: false,
										// format: "{point.y:.2f}",
									},
								},
								column: {
									pointPadding: 0.2,
									borderWidth: 0,
									dataLabels: {
										enabled: false,
										// format: "{point.y:.0f}",
										formatter: function () {
											if (this.y === 0) {
												return null;
											}
											return this.y.toFixed(2);
										},
									},
									events: {
										click: function (e) {
											let action = data.action;
											let downTo = data.downTo;

											if (action === "yearly") {
												setGraphRevenuePerYearFilter({
													...graphRevenuePerYearFilter,
													action: downTo,
													year: e.point.category,
												});
											}
											if (action === "quarterly") {
												setGraphRevenuePerYearFilter({
													...graphRevenuePerYearFilter,
													action: downTo,
													quarter: e.point.category,
												});
											}
											if (action === "monthly") {
												setGraphRevenuePerYearFilter({
													...graphRevenuePerYearFilter,
													action: downTo,
													month: e.point.category,
												});
											}
											if (action === "daily") {
												setGraphRevenuePerYearFilter({
													...graphRevenuePerYearFilter,
													action: downTo,
													week: "",
													month: "",
													quarter: "",
												});
											}
										},
									},
								},
							},

							series: [
								{
									name: "REVENUE BY YEAR",
									colorByPoint: true,
									data: data.data_series_value,
								},
							],
						},
						function (chart) {
							// on complete

							if (data.action !== "yearly") {
								let y = 65;
								let x = 15;
								if (windowWidth <= 470) {
									y = 25;
									x = 10;
								}

								console.log("x: ", x, " y: ", y);
								chart.renderer
									.image(leftArrow, chart.chartWidth - y, x, 18, 17)
									.add()
									.addClass("highcharts-button-arrow-left")
									.css({ cursor: "pointer" })
									.attr({ title: "Back" })
									.on("click", function () {
										// prcessing after image is clicked
										let action = res.data.action;

										if (action === "quarterly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: "yearly",
											});
										}
										if (action === "monthly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: "quarterly",
											});
										}
										if (action === "daily") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: "monthly",
											});
										}
									});
							}
						}
					);
				}
			}
		}
	);

	useEffect(() => {
		refetchGraphRevenuePerYear();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [graphRevenuePerYearFilter]);

	const [filterRevenuePerMonth, setFilterRevenuePerMonth] = useState({
		year: moment().format("YYYY"),
		month: moment().format("MM"),
	});

	const { refetch: refetchRevenuePerMonth } = GET(
		`api/v1/revenue_per_month?${new URLSearchParams(filterRevenuePerMonth)}`,
		"dashboard_revenue_per_month",
		(res) => {
			console.log("res", res);
			if (res.data) {
				Highcharts.chart("div_revenue_by_month", {
					credits: false,
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: "pie",
						// width: 250,
					},
					title: {
						text: null,
					},
					tooltip: {
						formatter: function () {
							console.log("this", this);
							return `<b>${this.key}: $${this.y.toFixed(2)}`;
						},
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: "pointer",
							dataLabels: {
								enabled: true,
								distance: -50,
								formatter: function () {
									return `<b style="font-size:16px;">$${this.y.toFixed(2)}</b>`;
								},
							},
							showInLegend: true,
						},
					},
					legend: {
						align: "center",
						verticalAlign: "top",
					},
					accessibility: {
						enabled: false,
					},
					series: [
						{
							name: "REVENUE BY MONTH",
							colorByPoint: true,
							data: res.data,
						},
					],
				});
			}
		}
	);

	const handleOnValuesChangePerMonth = (e) => {
		// console.log("handleOnValuesChangePerMonth", e);
		if (e.year) {
			setFilterRevenuePerMonth({
				...filterRevenuePerMonth,
				year: e.year,
			});
		}
		if (e.month) {
			setFilterRevenuePerMonth({
				...filterRevenuePerMonth,
				month: e.month,
			});
		}
	};

	useEffect(() => {
		refetchRevenuePerMonth();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRevenuePerMonth]);

	const [filterRevenuePerState, setFilterRevenuePerState] = useState({
		type: "ALL",
		state: "AZ",
	});
	const { refetch: refetchRevenuePerState } = GET(
		`api/v1/revenue_per_state?${new URLSearchParams(filterRevenuePerState)}`,
		"dashboard_revenue_per_state",
		(res) => {
			console.log("dashboard_revenue_per_state", res);
			if (res.data) {
				Highcharts.chart("div_revenue_by_state", {
					credits: false,
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: "pie",
						// width: 250,
					},
					title: {
						text: null,
					},
					tooltip: {
						formatter: function () {
							// console.log("this", this);
							return `<b>${this.key}: $${this.y.toFixed(2)}`;
						},
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: "pointer",
							dataLabels: {
								enabled: true,
								// format: "<b>{point.name}</b><br>{point.y:.0f}",
								distance: -50,
								formatter: function () {
									// console.log("this", this);
									return `<b style="font-size:16px;">$${this.y.toFixed(2)}</b>`;
								},
							},
							showInLegend: true,
						},
					},
					legend: {
						align: "center",
						verticalAlign: "top",
					},
					series: [
						{
							name: "REVENUE BY STATE",
							colorByPoint: true,
							data: res.data,
						},
					],
				});
			}
		}
	);

	const handleOnValuesChangePerState = (e) => {
		// console.log("handleOnValuesChangePerState", e);
		if (e.type) {
			setFilterRevenuePerState({
				...filterRevenuePerState,
				type: e.type,
			});
		}
		if (e.state) {
			setFilterRevenuePerState({
				...filterRevenuePerState,
				state: e.state,
			});
		}
	};

	useEffect(() => {
		refetchRevenuePerState();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRevenuePerState]);
	const [filterRevenueAll, setFilterRevenueAll] = useState({
		date_start: moment(1, "DD").format("YYYY-MM-DD"),
		date_end: moment().format("YYYY-MM-DD"),
		type: "ALL",
	});
	const { data: dataRevenueAll, refetch: refetchRevenueAll } = GET(
		`api/v1/revenue_all?${new URLSearchParams(filterRevenueAll)}`,
		"dashboard_revenue_all",
		(res) => {
			// console.log("res", res);
		}
	);

	const handleOnValuesChangeAll = (e) => {
		// console.log("handleOnValuesChange", e);
		if (e.date_start) {
			setFilterRevenueAll({
				...filterRevenueAll,
				date_start: moment(e.date_start).format("YYYY-MM-DD"),
			});
		}
		if (e.date_end) {
			setFilterRevenueAll({
				...filterRevenueAll,
				date_end: moment(e.date_end).format("YYYY-MM-DD"),
			});
		}
		if (e.type) {
			setFilterRevenueAll({
				...filterRevenueAll,
				type: e.type,
			});
		}
	};

	useEffect(() => {
		refetchRevenueAll();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRevenueAll]);

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageStatsFinancial"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<PieChartOutlined />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<Row style={{ marginTop: 20 }} gutter={12}>
					<Col xs={24} sm={24} md={24} lg={16}>
						<Collapse
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(270deg)",
										}}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(90deg)",
										}}
									></span>
								)
							}
							style={{
								boxShadow:
									"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
								marginTop: 10,
							}}
							expandIconPosition="right"
							defaultActiveKey={["1"]}
						>
							<Panel
								header={"Revenue"}
								key="1"
								className="accordion bg-darkgray-form"
							>
								<div
									id="div_revenue_by_year"
									style={{ display: "flex", justifyContent: "center" }}
								/>
								<div className="xTitleBar xTitleRevByYear">
									<strong>{xTitle}</strong>
								</div>

								<Row style={{ marginTop: 20 }} gutter={24}>
									<Col xs={24} sm={24} md={24} lg={24} xl={12}>
										<Typography.Title level={3}>
											Revenue By Month
										</Typography.Title>{" "}
										<Form
											initialValues={{
												year: moment().format("YYYY"),
												month: moment().format("MM"),
											}}
											onValuesChange={handleOnValuesChangePerMonth}
										>
											<Row gutter={8}>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
													<Form.Item name="year">
														<FloatSelectv2
															label="Year"
															placeholder="Year"
															options={optionYear}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
													<Form.Item name="month">
														<FloatSelectv2
															label="Month"
															placeholder="Month"
															options={optionMonth}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={24}>
													<div
														id="div_revenue_by_month"
														className="highchart-responsive"
														style={{
															display: "flex",
															justifyContent: "center",
														}}
													/>
												</Col>
											</Row>
										</Form>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24} xl={12}>
										<Typography.Title level={3}>
											Revenue By State
										</Typography.Title>{" "}
										<Form
											initialValues={{
												type: "ALL",
												state: "AZ",
											}}
											onValuesChange={handleOnValuesChangePerState}
										>
											<Row gutter={8}>
												<Col xs={24} sm={24} md={12} lg={12}>
													<Form.Item
														name="type"
														className="m-b-sm form-select-error"
														rules={[
															{
																required: true,
																message: "This field is required.",
															},
														]}
													>
														<FloatSelectv2
															label="Select Type"
															placeholder="Select Type"
															options={[
																{
																	value: "ALL",
																	label: "ALL",
																},
																{
																	value: "MEMBER",
																	label: "MEMBER",
																},
																{
																	value: "EVENT PROVIDER",
																	label: "EVENT PROVIDER",
																},
																{
																	value: "EVENT PROVIDER/SPEAKER",
																	label: "EVENT PROVIDER/SPEAKER",
																},
																{
																	value: "SPEAKER",
																	label: "SPEAKER",
																},
																{
																	value: "VENUE PROVIDER",
																	label: "VENUE PROVIDER",
																},
															]}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={12} lg={12}>
													<Form.Item name="state">
														<FloatSelectv2
															label="State"
															placeholder="State"
															options={optionStateCodes}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={24}>
													<div
														id="div_revenue_by_state"
														className="highchart-responsive"
														style={{
															display: "flex",
															justifyContent: "center",
														}}
													/>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</Panel>
						</Collapse>
					</Col>
					<Col xs={24} sm={24} md={24} lg={8}>
						<Collapse
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(270deg)",
										}}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(90deg)",
										}}
									></span>
								)
							}
							style={{
								boxShadow:
									"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
								marginTop: 10,
							}}
							expandIconPosition="right"
							defaultActiveKey={["1"]}
						>
							<Panel
								header={"Revenue"}
								key="1"
								className="accordion bg-darkgray-form"
							>
								<Form
									initialValues={{
										date_start: moment(1, "DD"),
										date_end: moment(),
										type: "ALL",
									}}
									onValuesChange={handleOnValuesChangeAll}
								>
									<Row gutter={8}>
										<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
											<Form.Item name="date_start">
												<FloatDatePickerForm
													label="Date Start"
													placeholder="Date Start"
													format="MM/DD/YYYY"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
											<Form.Item name="date_end">
												<FloatDatePickerForm
													label="Date End"
													placeholder="Date End"
													format="MM/DD/YYYY"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
											<Form.Item
												name="type"
												className="m-b-sm form-select-error"
												hasFeedback
												rules={[
													{
														required: true,
														message: "This field is required.",
													},
												]}
											>
												<FloatSelectv2
													label="Select Type"
													placeholder="Select Type"
													options={[
														{
															value: "ALL",
															label: "ALL",
														},
														{
															value: "MEMBER",
															label: "MEMBER",
														},
														{
															value: "EVENT PROVIDER",
															label: "EVENT PROVIDER",
														},
														{
															value: "EVENT PROVIDER/SPEAKER",
															label: "EVENT PROVIDER/SPEAKER",
														},
														{
															value: "SPEAKER",
															label: "SPEAKER",
														},
														{
															value: "VENUE PROVIDER",
															label: "VENUE PROVIDER",
														},
													]}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Form>

								<Typography.Title strong level={4}>
									TOTAL: ${dataRevenueAll?.data.toFixed(2)}
								</Typography.Title>
							</Panel>
						</Collapse>
					</Col>
				</Row>
			</Layout.Content>
		</Layout>
	);
}
