import React, { useState, useEffect } from "react";
import {
	Layout,
	Form,
	Button,
	Row,
	Col,
	Checkbox,
	Collapse,
	notification,
	Upload,
	message,
	Modal,
	Alert,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import {
	SaveOutlined,
	UpOutlined,
	DownOutlined,
	CreditCardFilled,
	UploadOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputNumber from "../../../../providers/FloatInputNumber";
import FloatInputMask from "../../../../providers/FloatInputMask";
import { GET, GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import companyInfo from "../../../../providers/companyInfo";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import { Country, State, City } from "country-state-city";
import moment, { isMoment } from "moment";
import geo2zip from "geo2zip";

export default function PageBankDetails({ props, permission }) {
	const [countryList, setCountryList] = useState(Country.getAllCountries());
	const [stateList, setStateList] = useState(State.getStatesOfCountry("US"));
	const [cityList, setCityList] = useState(City.getCitiesOfState("US", "AL"));

	const [countrySelect, setCountrySelect] = useState([]);
	const [stateSelect, setStateSelect] = useState([]);
	const [citySelect, setCitySelect] = useState([]);

	useEffect(() => {
		var country = [];
		countryList.forEach((item) => {
			if (item.isoCode == "US") {
				var _country = {
					label: item.name,
					value: item.isoCode,
					json: item,
				};
				country.push(_country);
			}
		});

		var state = [];
		stateList.forEach((item) => {
			var _state = {
				label: item.name,
				value: item.isoCode,
				json: item,
			};
			state.push(_state);
		});

		console.log(state);
		var city = [];
		cityList.forEach((item) => {
			var _city = {
				label: item.name,
				value: item.name,
				json: item,
			};
			city.push(_city);
		});

		setCountrySelect(country);
		setStateSelect(state);
		setCitySelect(city);
	}, [stateList, cityList, countryList]);

	let userdata = getUserData();
	const sub_title = "Edit";
	const { Panel } = Collapse;
	const [check, setCheck] = useState(false);
	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();

	const [isUpdate, setIsUpdate] = useState(false);
	const [statusVerify, setStatusVerify] = useState(false);
	const [statusPending, setStatusPending] = useState(false);
	const [statusUnverified, setStatusUnverified] = useState(false);
	const [statusUnverifiedMessage, setStatusUnverifiedMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState([]);
	const {
		data: dataGetAccount,
		isLoading: isLoadingGetAccount,
		refetch: refetchData,
	} = GET(
		`api/v1/geteventUpdate?id=${userdata.id}`,
		"get_event_bank_detail_update1",
		(res) => {
			console.log("get_event_bank_detail_update", res);
			if (res.data) {
				setIsUpdate(true);

				if (res.stripe_data.individual.requirements.errors.length > 0) {
					var arr = [];
					res.stripe_data.individual.requirements.errors.map((item) => {
						var found = arr.find((x) => x.reason == item.reason);
						if (!found) {
							arr.push(item);
						}
					});

					setErrorMessage(arr);
				}

				setStatusVerify(
					res.stripe_data.individual.verification.status == "verified"
						? true
						: false
				);
				setStatusPending(
					res.stripe_data.individual.verification.status == "pending"
						? true
						: false
				);

				setStatusUnverified(
					res.stripe_data.individual.verification.status == "unverified"
						? true
						: false
				);

				if (res.stripe_data.individual.verification.status == "unverified") {
					setStatusUnverifiedMessage(
						res.stripe_data.individual.verification.document.details
					);
				}

				form.setFieldsValue({
					city: res.data.city,
					state: res.data.state,
					postal_code: res.data.zip,
					line_1: res.data.address,
					country: res.data.country,
					phone_number: res.data.phone_number,
					email: res.data.email,

					business_name: res.data.business_name,
					business_name: res.data.business_name,
					bank_name: res.data.bank_name,
					account_type: res.data.account_type,
					first_name: res.data.first_name,
					last_name: res.data.last_name,
					dob: res.data.dob,
					ssn: "*****" + res.data.ssn_last_4,
					account_number:
						"********" + res.stripe_data.external_accounts.data[0].last4,
					// acc_holder_name:
					//   res.stripe_data.external_accounts.data[0].account_holder_name,
					routing_number:
						res.stripe_data.external_accounts.data[0].routing_number,
				});
				let fileName = res.data.back_id.split("files/");
				setFileList([
					{
						uid: "-1",
						name: fileName[1],
						status: "done",
						url: companyInfo().apiUrl + "storage/" + res.data.back_id,
						thumbUrl: companyInfo().apiUrl + "storage/" + res.data.back_id,
					},
				]);
				let fileName1 = res.data.back_id.split("files/");
				setFileList1([
					{
						uid: "-1",
						name: fileName1[1],
						status: "done",
						url: companyInfo().apiUrl + "storage/" + res.data.front_id,
						thumbUrl: companyInfo().apiUrl + "storage/" + res.data.front_id,
					},
				]);
			}
		}
	);

	const { mutate: mutateAdd, isLoading: isLoadingAdd } = POST(
		"api/v1/addBankStripe",
		"event_bank_details_update1"
	);

	const onFinishProfile = (val) => {
		var _dob = isMoment(val.dob) ? val.dob.format("YYYY-MM-DD") : val.dob;
		const split = _dob.split("-");

		const data = new FormData();

		data.append("acc_holder_name", val.first_name + " " + val.last_name);
		data.append("bank_name", val.bank_name);
		data.append("account_number", val.account_number);
		data.append("account_type", val.account_type);
		data.append("routing_number", val.routing_number);
		data.append("email", userdata.email);
		data.append("first_name", val.first_name);
		data.append("last_name", val.last_name);
		data.append("ssn", val.ssn);
		data.append("ssn_last_4", val.ssn.substr(val.ssn.length - 4));

		data.append("dob", _dob);
		data.append("dob_year", split[0]);
		data.append("dob_month", split[1]);
		data.append("dob_day", split[2]);
		data.append("business_name", val.business_name);
		data.append("line_1", val.line_1);
		data.append("city", val.city);
		data.append("state", val.state);
		data.append("country", val.country);
		data.append("email", val.email);
		data.append("postal_code", val.postal_code);

		data.append("phone_number", val.phone_number);
		console.log(val.ssn.substr(val.ssn.length - 4));

		var format = /^[*]*$/;

		var a = form.getFieldValue("account_number");
		var b = form.getFieldValue("ssn");

		if (a.charAt(0) == "*") {
			notification.error({
				message: "Error",
				description: "Please re-enter Account Number",
			});
			form.setFieldsValue({
				account_number: null,
			});
		}
		if (b.charAt(0) == "*") {
			notification.error({
				message: "Error",
				description: "Please re-enter Social Security Number",
			});
			form.setFieldsValue({
				ssn: null,
			});
		}

		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append(
					"upload_front",
					fileList[0].originFileObj,
					fileList[0].name
				);
			}
		}

		if (fileList1.length !== 0) {
			if (fileList1[0].uid != "-1") {
				data.append(
					"upload_back",
					fileList1[0].originFileObj,
					fileList1[0].name
				);
			}
		}

		if (fileList.length == 0) {
			notification.warning({
				message: "Warning",
				description: "Please Upload ID Front Document",
			});
		}

		if (fileList1.length == 0) {
			notification.warning({
				message: "Warning",
				description: "Please Upload ID Back Document",
			});
		}

		if (
			b.charAt(0) != "*" &&
			a.charAt(0) != "*" &&
			fileList1.length !== 0 &&
			fileList.length !== 0
		) {
			mutateAdd(data, {
				onSuccess: (res) => {
					if (res.success) {
						console.log("wew", res);
						refetchData();
						notification.success({
							message: "Success",
							description: "Bank Details Successfully Updated",
						});
						setErrorMessage([]);
					}
				},
				onError: (err) => {
					notification.error({
						message: "Error",
						description: err.response.data.message,
					});
					// setErrorMessage([{ reason: err.response.data.message }]);
				},
			});
		}
	};

	const [formData, setFormData] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			console.log("useEffect", formData);
			if (formData) {
				// form.submit();
				setFormData("");
			}
		}, 3000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [formData]);

	async function getLocation(location) {
		const closestZip = await geo2zip(location);

		return closestZip;
	}

	const hanldeInput = (val) => {
		setFormData("empty");
	};

	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [state, setState] = useState([]);
	const [country, setCountry] = useState(optionCountryCodes);

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
	const [dataFetch, setDataFetch] = useState([]);

	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		form.resetFields(["state", "zip"]);
		if (dataFetch.length > 0) {
			if (val === dataFetch[0].country) {
				form.setFieldsValue({
					state: dataFetch[0].state,
					zip: dataFetch[0].zip,
				});
			}
		}
		setFormData(val);
	};

	const [fileList, setFileList] = useState([]);
	const [fileList1, setFileList1] = useState([]);
	const beforeUpload = (file) => {
		const isJpgOrPng =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "image/gif" ||
			file.type === "image/jpg" ||
			file.type === "application/pdf";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG, PNG, GIF, JPEG file!");
			setFileList([]);
			return;
		}
		const isLt2M = file.size / 102400 / 102400 < 10;
		if (!isLt2M) {
			message.error("Image must smaller than 10MB!");
			setFileList([]);
			return;
		}
		return isJpgOrPng && isLt2M;
	};
	const onChange = ({ fileList: newFileList }) => {
		var _file = newFileList;
		console.log(_file);
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileList(_file);
			setFormData("image_upload");
		} else {
			setFileList([]);
		}
	};

	const beforeUpload1 = (file) => {
		const isJpgOrPng =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "image/gif" ||
			file.type === "image/jpg" ||
			file.type === "application/pdf";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG, PNG, GIF, JPEG file!");
			setFileList1([]);
			return;
		}
		const isLt2M = file.size / 102400 / 102400 < 10;
		if (!isLt2M) {
			message.error("Image must smaller than 10MB!");
			setFileList1([]);
			return;
		}
		return isJpgOrPng && isLt2M;
	};
	const onChange1 = ({ fileList: newFileList }) => {
		var _file = newFileList;
		console.log(_file);
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileList1(_file);
			setFormData("image_upload");
		} else {
			setFileList1([]);
		}
	};

	const [showModalUpload, setShowModalUpload] = useState(false);

	const onChangeState = (e) => {
		console.log(City.getCitiesOfState("US", e));
		setCityList(City.getCitiesOfState("US", e));
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageBankDetails"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CreditCardFilled />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs linkVid={"awdawd"} />
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinishProfile}
					// onValuesChange={hanldeInput}
				>
					<Row gutter={4}>
						<Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Bank Account Details (For Attendee's Payments)"
									key="1"
									className="accordion bg-darkgray-form white-space"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="business_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Business Name"
													placeholder="Business Name"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="email"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput label="Email" placeholder="Email" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="first_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="last_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput label="Last Name" placeholder="Last Name" />
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="line_1"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput label="Address" placeholder="Address" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="country"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="Country"
													placeholder="Country"
													options={countrySelect}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="state"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="State"
													placeholder="State"
													options={stateSelect}
													onChange={(e) => onChangeState(e)}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="city"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="City"
													placeholder="City"
													options={citySelect}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="postal_code"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput label="Zip Code" placeholder="Zip Code" />
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="phone_number"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Phone"
													placeholder="Phone"
													maxLength={10}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={2} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="bank_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput label="Bank Name" placeholder="Bank Name" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="account_type"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="Account Holder Type"
													placeholder="Account Holder Type"
													options={[
														{
															label: "Individual",
															value: "individual",
														},
														{
															label: "Company",
															value: "company",
														},
													]}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="routing_number"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Routing Number"
													placeholder="Routing Number"
													maxLength={9}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="account_number"
												rules={[validator.require]}
												hasFeedback
											>
												{/* <FloatInputMask
                          label="Account Number"
                          placeholder="Account Number"
                          maskLabel="account_number"
                          maskType={"9999999999"}
                          onChange={hanldeInput}
                        /> */}

												<FloatInput
													label="Account Number"
													placeholder="Account Number"
													maxLength={12}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="dob"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatDatePickerForm
													label="Date of Birth"
													placeholder="Date of Birth"
													// onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="ssn"
												rules={[validator.require]}
												hasFeedback
											>
												{/* <FloatInputMask
                          label="Social Security Number"
                          placeholder="Social Security Number"
                          maskType="*99-99-9999"
                          onChange={hanldeInput}
                        /> */}
												<FloatInput
													label="Social Security Number"
													placeholder="Social Security Number"
													maxLength={9}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Button
												size="large"
												disabled={statusVerify || statusPending}
												block
												onClick={(e) => {
													setShowModalUpload(true);
												}}
												className={"btn-login-outline"}
											>
												Upload Identification Document
												<UploadOutlined style={{ marginTop: 4 }} />
											</Button>

											{statusVerify && (
												<div
													className="c-success"
													style={{ textAlign: "center" }}
												>
													Identification Verified
												</div>
											)}
											{statusPending && (
												<div
													className="c-secondary"
													style={{ textAlign: "center" }}
												>
													Identification Pending
												</div>
											)}

											{statusUnverified && (
												<div
													className="c-secondary"
													style={{ textAlign: "center", marginTop: "4px" }}
												>
													Identification Unverified{" "}
													{statusUnverifiedMessage &&
														" -" + statusUnverifiedMessage}
												</div>
											)}
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<div className="c-danger ">
												<b>Please Note: </b> Your SSN is required to verify your
												bank account.Your SSN is encrypted and will not be saved
											</div>
										</Col>

										<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}></Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											{errorMessage.length > 0 && (
												<>
													<Alert
														message="Information Needed"
														style={{ marginTop: 20 }}
														description={errorMessage.map((item, key) => {
															return (
																<>
																	<div style={{ marginBottom: 10 }}>
																		• {item.reason}
																	</div>
																</>
															);
														})}
														type="error"
														showIcon
													/>
												</>
											)}

											<Button
												size="large"
												style={{ width: "100%", marginTop: 20 }}
												className="btn-login-outline"
												icon={<SaveOutlined />}
												onClick={() => form.submit()}
											>
												Submit
											</Button>
										</Col>
										<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}></Col>
									</Row>
								</Panel>
							</Collapse>
						</Col>
					</Row>
					<Modal
						title={
							<>
								Proof of identity document<br></br>
								<label style={{ fontSize: 12 }}>
									E.g. Driver's Liscense, Passport, State issued ID card , Local
									cards etc.
								</label>
							</>
						}
						visible={showModalUpload}
						width={500}
						className="modal-login"
						onCancel={() => {
							setShowModalUpload(false);
						}}
						centered
						style={{ top: 20 }}
						footer={[
							<Button
								onClick={() => {
									setShowModalUpload(false);
								}}
							>
								Close
							</Button>,
						]}
					>
						<Row>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<i>
									Please make sure the document you're about to upload meets the
									requirements below
								</i>
								<div>
									-The Document shows exactly this information,{" "}
									<b>
										Legal name of person:{" "}
										{form.getFieldValue("first_name")
											? form.getFieldValue("first_name")
											: "" + " " + form.getFieldValue("last_name")
											? form.getFieldValue("last_name")
											: ""}
									</b>
								</div>
								<div>-Upload document is in color</div>
							</Col>
						</Row>
						<br></br>
						<Row style={{ marginTop: 10 }}>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
								<Upload
									style={{ width: "100%" }}
									maxCount={1}
									action={false}
									customRequest={() => {}}
									fileList={fileList}
									beforeUpload={beforeUpload}
									onChange={onChange}
								>
									<Button icon={<UploadOutlined />}>Upload ID Front</Button>
								</Upload>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
								<Upload
									maxCount={1}
									action={false}
									customRequest={() => {}}
									fileList={fileList1}
									beforeUpload={beforeUpload1}
									onChange={onChange1}
								>
									<Button icon={<UploadOutlined />}>Upload ID Back</Button>
								</Upload>
							</Col>
						</Row>
					</Modal>
				</Form>
			</Layout.Content>
		</Layout>
	);
}
