import React, { useEffect, useState } from "react";

import {
	Layout,
	Card,
	Form,
	Input,
	Button,
	Row,
	Col,
	Image,
	Divider,
	Collapse,
	Select,
	notification,
	Checkbox,
	Alert,
	Modal,
	Space,
} from "antd";
import {
	FormOutlined,
	PlusOutlined,
	MinusOutlined,
	EyeOutlined,
} from "@ant-design/icons";
import ComponentHeader from "../Components/ComponentHeader";
import moment from "moment";
import { useHistory } from "react-router-dom";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";

import states from "states-us";

import { GET, POST } from "../../../../providers/useAxiosQuery";

export default function ViewAs({ showModalNew, permission }) {
	const { Panel } = Collapse;
	const [form] = Form.useForm();
	const sub_title = "User";
	const [dataUsers, setDataUsers] = useState([]);
	const { data: dataMember, isLoading: isLoadingDataMember } = GET(
		"api/v1/member_options",
		"member_options",
		(res) => {
			if (res.success) {
				console.log("member_options", res);
				let arr = [];
				res.data &&
					res.data.map((row, index) => {
						let val =
							row.first_name + " " + row.last_name + " - (" + row.role + ")";
						arr.push({
							value: row.id,
							label: val,
							json: row,
						});
					});
				console.log(arr);
				setDataUsers(arr);
			}
		}
	);

	const { mutate: mutateGenerateToken, isLoading: isLoadingtickets } = POST(
		"api/v1/generate/token/viewas",
		"viewas_mutate"
	);

	const handleFinish = (val) => {
		console.log(val.users);
		mutateGenerateToken(
			{ id: val.users, viewas: localStorage.viewas },
			{
				onSuccess: (res) => {
					console.log(res);
					localStorage.token = res.token;
					if (!localStorage.viewas) {
						localStorage.userdata_admin = localStorage.userdata;
					}
					localStorage.userdata = JSON.stringify(res.data);
					localStorage.viewas = true;
					var url = window.location.origin;
					window.location.href = url;
				},
			}
		);
	};

	const onChangeSelectUser = () => {
		form.submit();
	};
	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="ViewAs"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<EyeOutlined />}
			/>
			{/* <ComponentFaqs
      linkVid={"https://player.vimeo.com/video/644213846?autoplay=0&muted=1"}
    /> */}

			<Layout.Content
				style={{ padding: "30px", paddingTop: "0px", marginTop: 20 }}
			>
				<Row gutter={24}>
					<Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
						<Collapse
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							expandIconPosition="right"
							defaultActiveKey={["1"]}
						>
							<Panel
								header="Select User To View"
								key="1"
								className="accordion bg-darkgray-form"
							>
								<Form
									wrapperCol={{ span: 24 }}
									layout="horizontal"
									form={form}
									onFinish={handleFinish}
								>
									<Form.Item
										name="users"
										rules={[{ required: true, message: "required!" }]}
										className="form-select-error m-b-none"
										hasFeedback
									>
										<FloatSelect
											label="Select User"
											placeholder="Select User"
											options={dataUsers}
											onChange={() => onChangeSelectUser()}
										/>
									</Form.Item>
								</Form>
							</Panel>
						</Collapse>
					</Col>
				</Row>
			</Layout.Content>
		</Layout>
	);
}
