import React, { useState, useEffect } from "react";
import {
	Card,
	Layout,
	Modal,
	Typography,
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Checkbox,
	Collapse,
	notification,
	Popconfirm,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import {
	HomeOutlined,
	SaveOutlined,
	FieldTimeOutlined,
	ClockCircleOutlined,
	CalendarOutlined,
	UserOutlined,
	EditOutlined,
	MinusOutlined,
	CreditCardFilled,
	UpOutlined,
	DownOutlined,
	CloseCircleFilled,
	ClockCircleFilled,
	MinusCircleOutlined,
	PlusOutlined,
	DeleteOutlined,
	FolderOpenOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import { faMapLocation, faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

import { Pie } from "@ant-design/charts";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatDateTimePicker from "../../../../providers/FloatDateTimePicker";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import FloatAutoComplete from "../../../../providers/FloatAutoComplete";
// import FloatInputNumber from "../../../../providers/FloatInputNumber";
import FloatInputRate from "../../../../providers/FloatInputRate";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import states from "states-us";
import { GET, POST } from "../../../../providers/useAxiosQuery";

import getUserData from "../../../../providers/getUserData";

import { useHistory } from "react-router-dom";

export default function PageCEHoursProfile({ props, permission }) {
	const history = useHistory();
	const userdata = getUserData();
	const sub_title = "CE Hours";
	const { Panel } = Collapse;

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();
	const [state, setState] = useState([]);
	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const {
		mutate: mutateCreateCeHoursProfile,
		isLoading: isLoadingCreateCeHoursProfile,
	} = POST("api/v1/member_ce_hours_profile", "member_my_ce_hours_my_profile");

	const handleFinish = (val) => {
		console.log(val);
		let data = {
			...val,
			date_issued: val.date_issued.format("YYYY-MM-DD"),
			renewal_date: val.renewal_date.format("YYYY-MM-DD"),
		};
		data["ce_renewals"].map((item) => {
			item["start_date"] = item["start_date"].format("YYYY-MM-DD");
			item["end_date"] = item["end_date"].format("YYYY-MM-DD");
		});

		console.log("data", data);

		mutateCreateCeHoursProfile(data, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					notification.success({
						message: "Success",
						description: "Successfully Saved!",
					});
					form.resetFields();
					setShowDental(false);
				}
			},
			onError: (res) => {},
		});
	};

	const {
		data: dataGetCeProfile,
		isLoading: isDataGetCeProfile,
		refetch: isRefetchce,
	} = GET(
		`api/v1/member_ce_hours_profile/my_profile?user_id=${userdata.id}`,
		"member_my_ce_hours_my_profile",
		(res) => {
			if (res.success) {
				console.log("@res", res);

				// form.setFieldsValue({ ...res.data, current_hours: res.hours });
			}
		}
	);
	useEffect(() => {
		form.setFieldsValue({
			ce_renewals: [
				{
					start_date: null,
					end_date: null,
					current_ce_hours: null,
					min_license_ce_hours_required: null,
					show: true,
				},
			],
		});
	}, []);

	const { mutate: mutateGetHours, isLoading: isLoadingGetHours } = POST(
		"api/v1/member_my_ce_hours_my_profile_get_hours",
		"member_my_ce_hours_my_profile_get_hours"
	);

	const onChangeForm = (change, allval) => {
		console.log(change);
		if (change[0].name.length > 2) {
			if (
				change[0].name[0] == "ce_renewals" &&
				change[0].name[2] != "min_license_ce_hours_required" &&
				change[0].name[2] != "title" &&
				change[0].name[2] != "show"
			) {
				var key = change[0].name[1];
				var val = form.getFieldsValue(true);

				var start_date = val["ce_renewals"][key]["start_date"]
					? val["ce_renewals"][key]["start_date"].format("YYYY-MM-DD")
					: null;

				var end_date = val["ce_renewals"][key]["end_date"]
					? val["ce_renewals"][key]["end_date"].format("YYYY-MM-DD")
					: null;

				var min = val["ce_renewals"][key]["min_license_ce_hours_required"]
					? val["ce_renewals"][key]["min_license_ce_hours_required"]
					: null;

				console.log("start_date", start_date);
				console.log("end_date", end_date);

				if (start_date && end_date) {
					mutateGetHours(
						{
							start_date: start_date,
							end_date: end_date,
							user_id: userdata.id,
						},
						{
							onSuccess: (res) => {
								if (res.success) {
									console.log(res);

									if (res.hours == 0) {
										notification.success({
											message: "Success",
											description: "No CE Hours Credited",
										});
									} else {
										notification.success({
											message: "Success",
											description: "Current CE Hours Updated",
										});
									}

									console.log("key", key);
									console.log("val", val);
									val["ce_renewals"][key]["current_ce_hours"] =
										res.hours == 0 ? null : res.hours;
									form.setFieldsValue(val);
								}
							},
							onError: (res) => {},
						}
					);
				}
			}
		}
	};

	const { mutate: mutateCeDelete, isLoading: isLoadingCeDelete } = POST(
		"api/v1/member_my_ce_profile_delete",
		"member_my_ce_hours_my_profile"
	);
	const deleteProfile = (id) => {
		mutateCeDelete(
			{
				id: id,
			},
			{
				onSuccess: (res) => {
					if (res.success) {
						console.log(res);
						isRefetchce();
						notification.success({
							message: "Success",
							description: "CE Profile Successfully Archived",
						});
					}
				},
				onError: (res) => {},
			}
		);
	};

	const ceView = (id) => {
		history.push("/member/ce-hours/profile/" + id);
	};

	const [showDental, setShowDental] = useState(false);

	const onCancelDental = () => {
		setShowDental(false);
	};

	const { mutate: mutateUpdateShow, isLoading: isLoadingMutateUpdatePrimary } =
		POST(
			"api/v1/member_my_ce_profile_update_show",
			"member_my_ce_hours_my_profile"
		);

	const setShowDashBoard = (e, id) => {
		let show = e ? 1 : 0;

		mutateUpdateShow(
			{ show: show, user_id: userdata.id, id: id },
			{
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Success",
							description: "Successfully Saved!",
						});
					}
				},
				onError: (res) => {},
			}
		);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageCEHoursProfile"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<ClockCircleFilled />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/654658562?autoplay=0&muted=1&muted=1&muted=1"
					}
					col="member_ce_profile"
					colLink="member_ce_profile_link"
				/>

				<Row>
					<Col xs={24} sm={24} md={16} lg={16}>
						<Button
							className="btn-login-outline"
							icon={<PlusCircleOutlined />}
							onClick={() => {
								setShowDental(true);
							}}
							style={{ marginBottom: 10, float: "right" }}
						>
							Add Dental License
						</Button>
					</Col>
					<Col xs={24} sm={24} md={16} lg={16}>
						{dataGetCeProfile && dataGetCeProfile.data && (
							<div>
								{dataGetCeProfile.data.length != 0
									? dataGetCeProfile.data.map((item, key) => {
											return (
												<div key={key}>
													<Collapse
														className="lesspaddingCollapse"
														expandIcon={({ isActive }) =>
															isActive ? (
																<span
																	className="ant-menu-submenu-arrow"
																	style={{
																		color: "#FFF",
																		transform: "rotate(270deg)",
																	}}
																></span>
															) : (
																<span
																	className="ant-menu-submenu-arrow"
																	style={{
																		color: "#FFF",
																		transform: "rotate(90deg)",
																	}}
																></span>
															)
														}
														expandIconPosition="right"
														defaultActiveKey={["1"]}
													>
														<Panel
															header="My Dental Licenses"
															key="1"
															className="accordion bg-darkgray-form"
														>
															<span style={{ float: "right" }}>
																<Popconfirm
																	placement="topRight"
																	title={
																		"Are you sure you want to archive this?"
																	}
																	onConfirm={() => deleteProfile(item.id)}
																	okText="Yes"
																	cancelText="No"
																>
																	<Button
																		type="primary"
																		className="btn-danger-outline"
																		icon={<FolderOpenOutlined />}
																	></Button>
																</Popconfirm>
															</span>
															<span style={{ float: "right", marginRight: 5 }}>
																<Button
																	type="primary"
																	className="btn-warning-outline"
																	icon={<EditOutlined />}
																	onClick={() => ceView(item.id)}
																></Button>
															</span>
															<div style={{ fontSize: 18 }}>
																Title: {item.title ? item.title : "None"}
															</div>
															<div>
																<Link
																	className="companyTitle"
																	to={"/member/ce-hours/profile/" + item.id}
																>
																	Dental License: {item.dental_license}
																</Link>
															</div>
															<div>
																Issued In: {item.dental_license_issued_in}
															</div>
															<div>AGD Id: {item.agd_id}</div>
															{/* <div className="companyTitlePrimary">
                              Total CE Hours: {item.total_hours}
                            </div> */}

															{item.ce_renewals.map((_item, _key) => {
																return (
																	<div key={_key}>
																		<b>·</b> {_item.start_date} -{" "}
																		{_item.end_date} = {_item.current_ce_hours}/
																		{_item.min_license_ce_hours_required} hours
																	</div>
																);
															})}
															{/* <div>
															<span>Show on Dashboard</span>{" "}
															<Switch
																checked={item.show == 1 ? true : false}
																onChange={(e) => setShowDashBoard(e, item.id)}
															/>{" "}
														</div> */}
														</Panel>
													</Collapse>
													<br></br>
												</div>
											);
									  })
									: "No data..."}
							</div>
						)}
					</Col>
				</Row>

				<Modal
					title={"Add Dental License"}
					className="modal-login"
					visible={showDental}
					width={650}
					onCancel={onCancelDental}
					footer={[
						<Button onClick={onCancelDental}>Cancel</Button>,
						<Button
							className="btn-login-outline"
							icon={<SaveOutlined />}
							onClick={() => form.submit()}
							loading={isLoadingCreateCeHoursProfile}
						>
							Save
						</Button>,
					]}
					closeIcon={<FaTimes className="modal-close-icon" />}
				>
					<Form
						wrapperCol={{ span: 24 }}
						layout="horizontal"
						onFinish={handleFinish}
						form={form}
						onFieldsChange={(changedValues, allValues) =>
							onChangeForm(changedValues, allValues)
						}
					>
						<Row gutter={4}>
							<Col xs={24} sm={24} md={24} lg={24}>
								<Row gutter={24}>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											name="title"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatInput label="Title" placeholder="Title" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="dental_license"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatInput
												label="Dental License #"
												placeholder="Dental License #"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="dental_license_issued_in"
											rules={[validator.require]}
											hasFeedback
											className="form-select-error"
										>
											<FloatSelect
												label="Dental License Issued In"
												placeholder="Dental License Issued In"
												options={state}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="date_issued"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatDatePickerForm
												label="Date Issued"
												// placeholder="Date and End Time"
												mode
											/>
										</Form.Item>
									</Col>

									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="renewal_date"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatDatePickerForm
												label="Renewal Date"
												// placeholder="Date and End Time"
												mode
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="agd_id"
											rules={[validator.require]}
											hasFeedback
										>
											<FloatInput label="AGD ID #" placeholder="AGD ID #" />
										</Form.Item>
									</Col>
								</Row>
								<Divider orientation="left">
									<span style={{ fontSize: 12 }}>
										{" "}
										License Renewal Requirements
									</span>
								</Divider>
								<Form.List name="ce_renewals">
									{(fields, { add, remove }) => (
										<>
											{fields.map(({ key, name, ...restField }) => (
												<Row key={key} gutter={24}>
													<Col
														className="gutter-row"
														xs={24}
														sm={24}
														md={24}
														lg={24}
														xl={24}
														xxl={24}
													>
														<Form.Item
															valuePropName="checked"
															label="Show on Dashboard"
															name={[name, "show"]}
														>
															<Switch defaultChecked />
														</Form.Item>
													</Col>
													<Col
														className="gutter-row"
														xs={24}
														sm={24}
														md={22}
														lg={22}
														xl={22}
														xxl={22}
													>
														<Form.Item
															{...restField}
															name={[name, "title"]}
															rules={[validator.require]}
															hasFeedback
														>
															<FloatInput label="Title" placeholder="Title" />
														</Form.Item>
													</Col>

													<Col
														className="gutter-row"
														xs={24}
														sm={24}
														md={12}
														lg={12}
														xl={12}
														xxl={12}
													>
														<Form.Item
															{...restField}
															name={[name, "start_date"]}
															rules={[validator.require]}
															hasFeedback
														>
															<FloatDatePickerForm
																label="Start Date"
																// placeholder="Date and End Time"
																mode
															/>
														</Form.Item>
													</Col>
													<Col
														className="gutter-row"
														xs={24}
														sm={24}
														md={10}
														lg={10}
														xl={10}
														xxl={10}
													>
														<Form.Item
															{...restField}
															name={[name, "min_license_ce_hours_required"]}
															rules={[validator.require]}
															hasFeedback
														>
															<FloatInput
																label="Min. License CE Hours Required"
																placeholder="Min. License CE Hours Required"
															/>
														</Form.Item>
													</Col>

													<Col
														className="gutter-row"
														xs={24}
														sm={24}
														md={12}
														lg={12}
														xl={12}
														xxl={12}
													>
														<Form.Item
															{...restField}
															name={[name, "end_date"]}
															rules={[validator.require]}
															hasFeedback
														>
															<FloatDatePickerForm
																label="End Date"
																// placeholder="Date and End Time"
																mode
															/>
														</Form.Item>
													</Col>
													<Col
														className="gutter-row"
														xs={24}
														sm={24}
														md={10}
														lg={10}
														xl={10}
														xxl={10}
													>
														<Form.Item
															{...restField}
															name={[name, "current_ce_hours"]}
															hasFeedback
														>
															<FloatInput
																label="Current CE Hours"
																placeholder="Current CE Hours"
																disabled={true}
															/>
														</Form.Item>
													</Col>
													<Col
														className="gutter-row"
														xs={24}
														sm={24}
														md={2}
														lg={2}
														xl={2}
														xxl={2}
													>
														<Button
															icon={<MinusCircleOutlined />}
															className="btn-danger-outline"
															size="large"
															style={{
																marginBottom: "15px",
															}}
															onClick={() => remove(name)}
														></Button>
													</Col>
												</Row>
											))}
											<Row gutter={24}>
												<Col
													className="gutter-row"
													xs={24}
													sm={24}
													md={11}
													lg={11}
													xl={11}
													xxl={11}
												>
													<Form.Item>
														<Button
															className="btn-login-outline"
															onClick={() => add()}
															block
															style={{ width: "100%" }}
															icon={<PlusOutlined />}
														>
															ADD RENEWAL
														</Button>
													</Form.Item>
												</Col>
											</Row>
										</>
									)}
								</Form.List>
								<Divider orientation="left"></Divider>
								<a
									target={"_blank"}
									href="https://celiya.com/dentist-dds-ce-requirements/"
								>
									- Click Here for Dentists.
								</a>
								<br></br>
								<a
									target={"_blank"}
									href="https://celiya.com/dental-hygienist-rdh-ce-requirements/"
								>
									- Click Here for Dental Hygienists.
								</a>
								<br></br>
								<a
									target={"_blank"}
									href="https://celiya.com/dental-assistant-rda-ce-requirements/"
								>
									- Click Here for Dental Assistants.
								</a>
							</Col>
						</Row>
					</Form>
				</Modal>
			</Layout.Content>
		</Layout>
	);
}
