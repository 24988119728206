import React, { useEffect, useState } from "react";
import {
	Card,
	Modal,
	Typography,
	Form,
	Button,
	Row,
	Col,
	notification,
	Collapse,
} from "antd";

import { SaveOutlined, MinusOutlined } from "@ant-design/icons";
import FloatInput from "../../../../../providers/FloatInput";
import FloatInputPassword from "../../../../../providers/FloatInputPassword";
import FloatInputPasswordStrength from "../../../../../providers/FloatInputPasswordStrength";
import FloatSelect from "../../../../../providers/FloatSelect";
import { POST } from "../../../../../providers/useAxiosQuery";
export default function ModalPassword({
	isModalPassword,
	setIsModalPassword,
	dataProfile,
	userdata,
}) {
	const { Panel } = Collapse;
	const showModal = () => {
		setIsModalPassword(true);
	};

	const onClose = () => {
		setIsModalPassword(false);
	};

	const [form] = Form.useForm();

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		password: {
			min: 8,
			message: "Password must be minimum 8 characters.",
		},
	};

	useEffect(() => {
		console.log("dataProfile", dataProfile);
	}, []);

	const { mutate: mutatePassword, isLoading: isLoadingPassword } = POST(
		"api/v1/user_password",
		"user_password"
	);

	const onFinish = (values) => {
		let data = {
			...values,
			id: dataProfile[0].id,
		};
		// console.log("onFinish", data);
		mutatePassword(data, {
			onSuccess: (res) => {
				if (res.success) {
					// console.log(res)
					notification.success({
						message: res.message,
						description: res.description,
					});
					setIsModalPassword(false);
				} else {
					notification.error({
						message: res.message,
						description: res.description,
					});
				}
			},
			onError: (err) => {
				console.log(err);
			},
		});
	};

	return (
		<Modal
			title="CHANGE PASSWORD"
			visible={isModalPassword}
			onOk={showModal}
			className="modal-login"
			onCancel={onClose}
			footer={null}
			style={{ top: 20 }}
		>
			<Form
				wrapperCol={{ span: 24 }}
				layout="horizontal"
				form={form}
				onFinish={onFinish}
			>
				<p>
					Your password must be at least 8 characters and contain at least one
					number, one uppercase letter and one special character.
				</p>
				<Row gutter={16}>
					{/* <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                        name="current_password"
                        rules={[validator.require]}
                        hasFeedback
                    >
                        <FloatInputPassword 
                            label="Current Password" 
                            placeholder="Current Password" 
                        />
                    </Form.Item>
                </Col> */}
					<Col xs={24} sm={24} md={24} lg={24}>
						<Form.Item
							name="new_password"
							rules={[
								validator.require,
								validator.password,
								{
									pattern:
										/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
									message: "Invalid Password",
								},
							]}
							hasFeedback
						>
							<FloatInputPasswordStrength
								label="New Password"
								placeholder="New Password"
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24}>
						<Form.Item
							name="confirm_new_password"
							hasFeedback
							dependencies={["new_password"]}
							rules={[
								validator.require,
								// validator.password
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("new_password") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"The two passwords that you entered do not match!"
											)
										);
									},
								}),
							]}
						>
							<FloatInputPasswordStrength
								label="Confirm New Password"
								placeholder="Confirm New Password"
							/>
						</Form.Item>
					</Col>
				</Row>
				<br></br>
				<Row gutter={24} justify="end">
					<Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
						<Button
							size="large"
							className="btn-login-outline"
							style={{ width: "100%", marginTop: "20px" }}
							icon={<SaveOutlined />}
							loading={isLoadingPassword}
							onClick={() => form.submit()}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
