import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  notification,
} from "antd";
import image1 from "../../../../../assets/img/image1.jpg";
import image2 from "../../../../../assets/img/social_media.png";

import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import { GET, GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";

import companyInfo from "../../../../../providers/companyInfo";
import moment from "moment";
import {
  HomeOutlined,
  SaveOutlined,
  FieldTimeOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  UserOutlined,
  StarOutlined,
  EyeOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { FaTimes } from "react-icons/fa";
export default function ModalChangeStatus({
  showModalChangeStatus,
  setShowModalChangeStatus,
  match,
  selectedRowsData,
  refetchGetEvent,
  refetchGetEventWaiting,
  setSelectedRowKeys,
  setShowButton,
  // setLoding,
}) {
  const [form] = Form.useForm();

  const { mutate: mutateUpdateStatus, isLoading: isLoadingMutateUpdateStatus } =
    POST(
      "api/v1/event_with_attendees/updateStatus",
      "event_with_attendee_status"
    );

  const handleFinish = (e) => {
    var _data = {
      event_id: match.params.id,
      status: e.status,
      users: selectedRowsData,
    };
    mutateUpdateStatus(_data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log(res);
          notification.success({
            message: "Success",
            description: "Status Successfully Updated",
          });
          refetchGetEvent();
          refetchGetEventWaiting();
          setSelectedRowKeys([]);
          setShowButton(true);
        }
      },
      onError: (err) => {},
    });
    setShowModalChangeStatus(false);
  };
  const validator = {
    require: {
      required: true,
      message: "Required",
    },
    require_false: {
      required: false,
      message: "Required",
    },
    email: {
      type: "email",
      message: "please enter a valid email",
    },
  };

  return (
    <Modal
      visible={showModalChangeStatus}
      width={600}
      className="modalPreview"
      onCancel={() => {
        setShowModalChangeStatus(false);
      }}
      style={{ top: 20 }}
      footer={[
        <Button
          onClick={() => {
            setShowModalChangeStatus(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          htmlType="submit"
          onClick={() => form.submit()}
          className="btn-login-outline"
          loading={isLoadingMutateUpdateStatus}
        >
          Confirm
        </Button>,
      ]}
      // closeIcon={<FaTimes className="modal-close-icon-black" />}
    >
      <Card title={"Change Status"}>
        <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          form={form}
          onFinish={handleFinish}
        >
          <Form.Item name="status" hasFeedback>
            <FloatSelect
              label="Status"
              rules={[validator.require]}
              placeholder="Status"
              options={[
                {
                  label: "Registered",
                  value: "Registered",
                  key: "1",
                },
                {
                  label: "Attended",
                  value: "Attended",
                  key: "2",
                },
                {
                  label: "Cancelled",
                  value: "Cancelled",
                  key: "3",
                },

                {
                  label: "Not Attended",
                  value: "Not Attended",
                  key: "4",
                },
                {
                  label: "Waiting",
                  value: "Waiting",
                  key: "5",
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Card>
    </Modal>
  );
}
