import React, { useState, useEffect } from "react";
import {
	Layout,
	Input,
	Row,
	Col,
	Table,
	Pagination,
	Button,
	Tooltip,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	UsergroupAddOutlined,
	MessageOutlined,
	MailOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import ModalPreview from "./Modals/ModalPreview";
import ModalPreviewEvent from "../PageEvent/Modals/ModalPreview";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import { useHistory } from "react-router-dom";

export default function PageAttendees({ props, permission }) {
	let userdata = getUserData();
	let history = useHistory();
	const { Search } = Input;
	const sub_title = "View All";

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		page_number: 1,
		page_size: 50,
		column: "id",
		order: "desc",
		user_id: userdata.id,
	});

	const handleTableChange = (pagination, filters, sorter) => {
		console.log("sorter", sorter);
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const {
		data: dataGet,
		isLoading: isLoadingGet,
		refetch: refetchGet,
	} = GET(
		`api/v1/event_attending_participant?${$.param(dataTableInfo)}`,
		"event_attending_participant",
		(res) => {
			if (res.success) {
				console.log("dataGet", res);
			}
		}
	);

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const [previewShow, setPreviewShow] = useState(false);
	const [previewShowData, setPreviewShowData] = useState([]);
	const showModal = (record) => {
		setPreviewShow(true);
		setPreviewShowData(record);
	};

	useEffect(() => {
		refetchGet();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		// console.log("current", current);
		// console.log("pageSize", pageSize);
	};

	const [previewShowEvent, setPreviewShowEvent] = useState(false);
	const [id, setID] = useState(0);
	const showModalEvent = (e) => {
		// console.log(e)
		setID(e);
		setPreviewShowEvent(true);
	};

	const { mutate: mutateStartNewConvo, isLoading: isLoadingStartNewConvo } =
		POST("api/v1/message", `message_${userdata.id}`);
	const handleMakeNewConvo = (user_id) => {
		let data = {
			to_id: user_id,
			from_id: userdata.id,
		};
		console.log(data);
		mutateStartNewConvo(data, {
			onSuccess: (res) => {
				console.log(res);
				handleNewMessageConvo(res.data.id, user_id);
			},
		});
	};

	const { mutate: mutateNewMessageConvo, isLoading: isLoadingNewMessageConvo } =
		POST("api/v1/message_convo");

	const handleNewMessageConvo = (message_id, user_id) => {
		let messageText = `Hello!`;
		let data = {
			from_id: userdata.id,
			to_id: user_id,
			message_id: message_id,
			message: messageText,
		};
		console.log(data);
		mutateNewMessageConvo(data, {
			onSuccess: (res) => {
				console.log(res);
				history.push(
					"/event-provider-speaker/messages?message_id=" + message_id
				);
			},
		});
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageAttendees"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<UsergroupAddOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br></br>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataGet && dataGet.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />

				<Table
					rowKey={(record) => record.id}
					dataSource={dataGet ? dataGet.data.data : []}
					loading={isLoadingGet}
					className="table-responsive table-thead-login"
					pagination={false}
					size="small"
					onChange={handleTableChange}
				>
					{" "}
					<Table.Column
						key="event_date_start"
						title="Event Date"
						dataIndex="event_date_start"
						sorter
					/>
					<Table.Column
						key="event"
						title="Event"
						dataIndex="event"
						render={(text, record) => {
							return (
								<a href="#" onClick={() => showModalEvent(record.event_id)}>
									{record.event.event_details.title}
								</a>
							);
						}}
						sorter
					/>
					<Table.Column
						key="first_name"
						title="First Name"
						dataIndex="first_name"
						render={(text, record) => {
							return (
								<a href="#" onClick={() => showModal(record)}>
									{record.first_name}
								</a>
							);
						}}
						sorter
					/>
					<Table.Column
						key="last_name"
						title="Last Name"
						dataIndex="last_name"
						render={(text, record) => {
							return (
								<a href="#" onClick={() => showModal(record)}>
									{record.last_name}
								</a>
							);
						}}
						sorter
					/>
					<Table.Column
						key="categories"
						title="Categories"
						dataIndex="categories"
						render={(text, record) => {
							return JSON.parse(
								record.event.event_details.attending_participants
							).toString();
						}}
						sorter
					/>
					<Table.Column key="status" title="Status" dataIndex="status" sorter />
					<Table.Column
						key="email"
						title="Email"
						dataIndex="email"
						sorter
						render={(text, record) => {
							return (
								<a href={"mailto:" + text}>
									<Tooltip placement="top" title={text}>
										<MailOutlined />
									</Tooltip>
								</a>
							);
						}}
					/>
					<Table.Column
						key="id"
						title="Message"
						render={(test, record) => {
							// console.log("record", record);
							return (
								<Button onClick={(e) => handleMakeNewConvo(record.user.id)}>
									<MessageOutlined />
								</Button>
							);
						}}
					/>
				</Table>

				{previewShow && (
					<ModalPreview
						previewShow={previewShow}
						setPreviewShow={setPreviewShow}
						previewShowData={previewShowData}
					/>
				)}

				<ModalPreviewEvent
					previewShow={previewShowEvent}
					setPreviewShow={setPreviewShowEvent}
					match={id}
				/>
			</Layout.Content>
		</Layout>
	);
}
