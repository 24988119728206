import React, { useState } from "react";
import $ from "jquery";
import {
  Layout,
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Image,
  Divider,
  Result,
} from "antd";
import moment from "moment";
import FloatInput from "../../../providers/FloatInput";
import { CheckOutlined } from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";
import imageLogo from "../../../assets/img/logo.png";
import maintenanceLogo from "../../../assets/img/maintenance.png";
import { PasswordInput } from "antd-password-input-strength";

export default function Maintenance() {
  let history = useHistory();

  return (
    <Layout.Content
      className="login-layout"
      // style={{
      //   paddingBottom: "10vh",
      //   background: "linear-gradient(180deg, white 0%, #e2c991 80%)",
      // }}
    >
      <div className="divMaintenance">
        <div className="divMaintenanceChild">
          <Result
            status="warning"
            title={
              <span className="textMaintenance">
                Our site is currently undergoing scheduled maintenance and
                upgrades, but will return shorthly. We apologize for the
                inconvenience, thank you for your patience.
              </span>
            }
          />
        </div>
      </div>
    </Layout.Content>
  );
}
