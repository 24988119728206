import states from "states-us";
export default function optionStateCodesUnitedState() {
  let arr = [];
  states.map((row, key) => {
    if (
      row.name != "Federated States Of Micronesia" &&
      row.name != "Marshall Islands" &&
      row.name != "Palau" &&
      row.name != "Northern Mariana Islands"
    ) {
      arr.push({
        value: row.abbreviation,
        label: row.name,
      });
    }
  });

  return arr;
}
