import React, { useState, useEffect } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	Tooltip,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
// import ComponentFaqs from "../Components/ComponentFaqs";
import { EditOutlined, TeamOutlined, MailOutlined } from "@ant-design/icons";
import $ from "jquery";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET } from "../../../../providers/useAxiosQuery";
import { useHistory, Link } from "react-router-dom";

export default function PageProfilesList({ props, permission }) {
	const sub_title = "List";
	const history = useHistory();

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const {
		data: dataGetProfiles,
		isLoading: isLoadingGetProfiles,
		refetch: refetchGetProfiles,
	} = GET(`api/v1/users?${$.param(dataTableInfo)}`, "profiles", (res) => {
		if (res.success) {
			console.log("profiles", res);
		}
	});
	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchGetProfiles();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageProfilesList"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<TeamOutlined />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br />

				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataGetProfiles && dataGetProfiles.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						className="table-responsive table-thead-login"
						size="small"
						rowKey={(record) => record.id}
						loading={isLoadingGetProfiles}
						dataSource={dataGetProfiles ? dataGetProfiles.data.data : []}
						pagination={false}
						onChange={handleTableChange}
					>
						<Table.Column
							key="fullname"
							title="Full Name"
							dataIndex="fullname"
							sorter
						/>
						<Table.Column
							key="username"
							title="User Name"
							dataIndex="username"
							sorter
						/>
						<Table.Column
							key="email"
							title="Email"
							dataIndex="email"
							sorter
							render={(text, record) => {
								return (
									<a href={"mailto:" + text}>
										<Tooltip placement="top" title={text}>
											<MailOutlined />
										</Tooltip>
									</a>
								);
							}}
						/>
						<Table.Column key="role" title="Role" dataIndex="role" sorter />
						<Table.Column
							key="status"
							title="Status"
							dataIndex="status"
							sorter
							render={(text, record) => {
								return text == "Active" ? "Active" : "Deactivated";
							}}
						/>
						<Table.Column
							key="action"
							title="Action"
							render={(text, record) => {
								return (
									<Space>
										<Button
											size="small"
											className="btn-warning-outline"
											icon={<EditOutlined />}
											onClick={(e) =>
												history.push("/admin/profiles/edit/" + record.id)
											}
										>
											Edit
										</Button>
									</Space>
								);
							}}
						/>
					</Table>
				</div>
			</Layout.Content>
		</Layout>
	);
}
