import { useState } from "react";
import { Button, Col, Image, Layout, Row, Space, Typography } from "antd";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

// import HelmetMetaData from "./HelmetMetaData";

import MetaTags from "react-meta-tags";

import logo from "../../../assets/img/CELIYA-Logo-white.png";
import { GET } from "../../../providers/useAxiosQuery";

import companyInfo from "../../../providers/companyInfo";

const apiUrl = companyInfo().apiUrl;

export default function PageEvent(props) {
  const { match } = props;
  const history = useHistory();
  const [eventData, setEventData] = useState();

  GET(`api/v1/event_info/${match.params.id}`, "event", (res) => {
    if (res.success) {
      console.log("res", res);
      if (res.data.length > 0) {
        setEventData({
          event_type: res.data[0].event_type,
          cost: res.data[0].cost,
          event_time: res.data[0].event_schedules,
          rscp_datetime: res.data[0].rscp_datetime,
          venue_name: res.data[0].event_venue.venue_name,
          country: res.data[0].event_venue.country,
          address_1: res.data[0].event_venue.address_1,
          address_2: res.data[0].event_venue.address_2,
          city: res.data[0].event_venue.city,
          state: res.data[0].event_venue.state,
          zip: res.data[0].event_venue.zip,
          map_link: res.data[0].event_venue.map_link,
          direction_details: res.data[0].event_venue.direction_details,
          title: res.data[0].event_details.title,
          sub_title: res.data[0].event_details.sub_title,
          director: res.data[0].event_details.director,
          facilitator: res.data[0].event_details.facilitator,
          summary: res.data[0].event_details.summary,
          description: res.data[0].event_details.descrition,
          restriction: res.data[0].event_details.restriction,
          what_to_bring: res.data[0].event_details.what_to_bring,
          ce_credit_hours: res.data[0].event_details.ce_credit_hours,
          seat_available: res.data[0].event_details.seat_available,
          completion_code: res.data[0].event_details.completion_code,
          subject_code: res.data[0].event_details.subject_code,
          course_topic: res.data[0].event_details.course_topic,
          attending_participants: JSON.parse(
            res.data[0].event_details.attending_participants
          ),
          your_phone: res.data[0].event_details.your_phone,
          your_email: res.data[0].event_details.your_email,
          verification: JSON.parse(res.data[0].event_details.verification),
          published_status: res.data[0].event_save_status,
          speaker_name: res.data[0].event_speaker.speaker_name,
          speaker_credentials: res.data[0].event_speaker.speaker_credentials,
          event_img:
            res.data[0].event_details &&
            apiUrl + "storage/" + res.data[0].event_details.upload,
          // event_img:
          // 	"https://celiya-api.5ppsite.com/storage/uploads/event/1652028566_my_qr_code.png",
          event_speaker: res.data[0].event_speaker,
          date_from: res.data[0].date_from,
          date_to: res.data[0].date_to,
        });

        // <meta property="og:url"           content="https://www.your-domain.com/your-page.html" />
        // <meta property="og:type"          content="website" />
        // <meta property="og:title"         content="Your Website Title" />
        // <meta property="og:description"   content="Your description" />
        // <meta property="og:image"         content="https://www.your-domain.com/path/image.jpg" />

        // const ogURL = document.createElement("meta");
        // const ogTYPE = document.createElement("meta");
        // const ogTITLE = document.createElement("meta");
        // const ogDESCRIPTION = document.createElement("meta");
        // const ogIMAGE = document.createElement("meta");

        // ogURL.setAttribute("property", "og:title");
        // ogURL.content = "http://localhost:3002/event/1";
        // document.getElementsByTagName("head")[0].appendChild(ogURL);

        // ogTYPE.setAttribute("property", "og:type");
        // ogTYPE.content = "website";
        // document.getElementsByTagName("head")[0].appendChild(ogTYPE);

        // ogTITLE.setAttribute("property", "og:title");
        // ogTITLE.content = res.data[0].event_details.title;
        // document.getElementsByTagName("head")[0].appendChild(ogTITLE);

        // ogDESCRIPTION.setAttribute("property", "og:description");
        // ogDESCRIPTION.content = res.data[0].event_details.descrition;
        // document.getElementsByTagName("head")[0].appendChild(ogDESCRIPTION);

        // ogIMAGE.setAttribute("property", "og:image");
        // ogIMAGE.content = logo;
        // document.getElementsByTagName("head")[0].appendChild(ogIMAGE);
      }

      // setVenueTBD(res.data[0].venue_tbd);
      // setAwaiting(res.data[0].event_details.allow_waiting_list);
    }
  });

  const booking = () => {
    if (localStorage.userdata) {
      var userdata = JSON.parse(localStorage.userdata);
      if (userdata.role == "MEMBER") {
        history.push("/member/book/" + match.params.id);
      } else {
        // console.log("not a member");
        history.push("/registration");
      }
    } else {
      history.push("/registration");
      // console.log("/register");
    }
  };

  return (
    <>
      {/* <HelmetMetaData
        quote="quotetest"
        title={eventData && eventData.title}
        image={eventData && eventData.event_img}
        description={eventData && eventData.description}
      /> */}
      {eventData && (
        <MetaTags>
          {/* Facebook Meta Tags */}
          <title>{eventData.title}</title>
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta property="fb:app_id" content="969675650345598" />
          <meta name="description" content={eventData.description} />
          <meta
            property="og:url"
            content={
              "https://myceliya2022.5ppsite.com/event/" + match.params.id
            }
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={eventData.title} />
          <meta property="og:description" content={eventData.description} />
          <meta property="og:image" content={eventData.event_img} />
          <meta property="og:image:url" content={eventData.event_img} />

          {/* Twitter Meta Tags */}

          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="myceliya2022.5ppsite.com" />
          <meta
            property="twitter:url"
            content={
              "https://myceliya2022.5ppsite.com/event/" + match.params.id
            }
          />

          <meta name="twitter:title" content={eventData.title} />
          <meta name="twitter:description" content={eventData.description} />
          <meta name="twitter:image" content={eventData.event_img} />

          <meta content="image/*" property="og:image:type" />
        </MetaTags>
      )}

      <Layout className="ant-layout-event">
        <Layout.Header style={{ height: 90 }}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={6} className="text-right">
              <Image src={logo} preview={false} />
            </Col>
          </Row>
        </Layout.Header>

        <Layout.Content>
          {eventData && (
            <Row gutter={12}>
              <Col
                xs={24}
                sm={24}
                md={14}
                lg={14}
                xl={14}
                xxl={14}
                className="col-image"
              >
                <Image src={eventData.event_img} preview={false} />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                className="col-basic-info"
              >
                <Space direction="vertical" size={0}>
                  <Typography.Title level={4}>
                    {moment(eventData.event_time[0].datetime_start).format(
                      "dddd"
                    )}
                  </Typography.Title>
                  <Typography.Title level={1}>
                    {moment(eventData.event_time[0].datetime_start).format(
                      "MMMM Do"
                    )}
                  </Typography.Title>
                  <Typography.Title level={3}>
                    {eventData.title}
                  </Typography.Title>
                  <Typography.Title level={4}>
                    {eventData.venue_name}
                  </Typography.Title>
                  <Typography.Title level={5}>
                    COST: ${eventData.cost}
                  </Typography.Title>

                  <Typography.Paragraph className="typography-description">
                    {eventData.description}
                  </Typography.Paragraph>
                </Space>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-social-media"
              >
                <Space>
                  <Space>
                    <Typography.Text>SHARE: </Typography.Text>

                    {/* <ShareLink link="https://myceliya2022.5ppsite.com/event/38">
										{(link) => (
											<a href={link} target="_blank">
												Share this on Facebook
											</a>
										)}
									</ShareLink> */}

                    <FacebookShareButton
                      url={
                        process.env.REACT_APP_API_URL +
                        "share-media-content/" +
                        match.params.id
                      }
                      quote={eventData.title}
                      // hashtag={"#event"}
                      // {{date('d M, Y', strtotime($data[0]->date_from))}} - {{date('d M, Y', strtotime($data[0]->date_to))}}, {{"\n".$data[0]->event_details->descrition}}
                      description={eventData.description}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon
                        size={32}
                        style={{ marginBottom: "-5px" }}
                        round
                      />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={
                        process.env.REACT_APP_API_URL +
                        "share-media-content/" +
                        match.params.id
                      }
                      title={
                        `Title ${eventData.title} \nDate: ${moment(
                          eventData.date_from
                        ).format("YYYY/MM/DD")} - ${moment(
                          eventData.date_to
                        ).format("YYYY/MM/DD")} \nDescription:${
                          " " + eventData.description
                        }\n`.substring(0, 135) + "..."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon
                        size={32}
                        round
                        style={{ marginBottom: "-5px" }}
                      />
                    </TwitterShareButton>

                    <LinkedinShareButton
                      url={
                        process.env.REACT_APP_API_URL +
                        "share-media-content/" +
                        match.params.id
                      }
                      className="Demo__some-network__share-button"
                    >
                      <LinkedinIcon
                        size={32}
                        round
                        style={{ marginBottom: "-5px" }}
                      />
                    </LinkedinShareButton>

                    <EmailShareButton
                      url={
                        process.env.REACT_APP_API_URL +
                        "share-media-content/" +
                        match.params.id
                      }
                      subject={eventData.title}
                      body={
                        eventData.course_topic +
                        "\n" +
                        "When: " +
                        moment(eventData.date_from).format("DD MMM, YYYY") +
                        "-" +
                        moment(eventData.date_to).format("DD MMM, YYYY") +
                        "\n" +
                        "Description: " +
                        eventData.description +
                        "\n"
                      }
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon
                        size={32}
                        style={{ marginBottom: "-5px" }}
                        round
                      />
                    </EmailShareButton>

                    {/* <AiFillTwitterCircle
											style={{
												fontSize: "29px",
												color: "#55acee",
												marginBottom: "-7px",
											}}
										/> */}

                    {/* <BsLinkedin
											style={{
												borderRadius: "100%",
												color: "#007bb5",
												marginBottom: "-5px",
											}}
										/> */}

                    {/* <ImMail4
											style={{ color: "#0166ff", marginBottom: "-5px" }}
										/> */}
                  </Space>

                  <Button className="btn-book-now" onClick={booking}>
                    BOOK NOW
                  </Button>
                </Space>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-other-info"
              >
                <Space>
                  <Space direction="vertical" className="space-date-time">
                    <Typography.Title level={4} className="m-b-none">
                      DATE & TIME
                    </Typography.Title>

                    <Space direction="vertical">
                      {eventData.event_time.map((item, key) => {
                        return (
                          <Typography.Text key={key}>
                            {moment(item.datetime_start).format("ll h:mm a")} -
                            {moment(item.datetime_end).format("h:mm a")}
                          </Typography.Text>
                        );
                      })}
                    </Space>

                    <Typography.Title level={4} className="m-t-md m-b-none">
                      CREDIT HOURS
                    </Typography.Title>
                    <Typography.Text>
                      {eventData.ce_credit_hours}
                    </Typography.Text>
                  </Space>

                  <Space direction="vertical" className="space-location">
                    <Typography.Title level={4} className="m-b-none">
                      LOCATION
                    </Typography.Title>
                    {eventData.venue_name ? (
                      <Typography.Text>{eventData.venue_name}</Typography.Text>
                    ) : null}

                    {eventData.city ? (
                      <Typography.Text>{eventData.city}</Typography.Text>
                    ) : null}

                    {eventData.country || eventData.state || eventData.zip ? (
                      <Typography.Text>
                        {eventData.country && eventData.country + ","}{" "}
                        {eventData.state && eventData.state + ","}{" "}
                        {eventData.zip && eventData.zip + ","}{" "}
                      </Typography.Text>
                    ) : null}

                    <Typography.Title level={4} className="m-t-md m-b-none">
                      CONTACT INFO
                    </Typography.Title>
                    {eventData.your_phone ? (
                      <Typography.Text>{eventData.your_phone}</Typography.Text>
                    ) : null}
                    {eventData.your_email ? (
                      <Typography.Text>{eventData.your_email}</Typography.Text>
                    ) : null}
                    {eventData.your_email ? (
                      <Typography.Text>{eventData.your_email}</Typography.Text>
                    ) : null}
                  </Space>

                  <Space direction="vertical" className="space-speaker">
                    <Typography.Title level={4} className="m-b-none">
                      SPEAKER
                    </Typography.Title>
                    <Space direction="vertical">
                      {eventData.event_speaker.map((row, key) => {
                        return (
                          <div key={key}>
                            <Typography.Text>
                              NAME: {row.speaker_name}
                            </Typography.Text>
                            <Typography.Text>
                              CREDENTIALS: {row.speaker_credentials}
                            </Typography.Text>
                          </div>
                        );
                      })}
                    </Space>
                  </Space>
                </Space>
              </Col>
            </Row>
          )}
        </Layout.Content>

        <Layout.Footer style={{ textAlign: "center" }}>
          © Copyright {process.env.REACT_APP_APP_NAME}{" "}
          {new Date().getFullYear()} Alright Reserve.
        </Layout.Footer>
      </Layout>
    </>
  );
}
