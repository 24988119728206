import React, { useState, useEffect } from "react";
import {
	Layout,
	Input,
	Row,
	Col,
	Table,
	Pagination,
	Menu,
	Dropdown,
	Button,
	notification,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	CalendarOutlined,
	CheckOutlined,
	EyeOutlined,
} from "@ant-design/icons";
import $ from "jquery";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import { Link, useHistory } from "react-router-dom";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import getUserData from "../../../../providers/getUserData";
export default function PageViewEditEvents({ props, permission }) {
	const { Search } = Input;
	const sub_title = "View/Edit";
	const history = useHistory();

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		page_number: 1,
		page_size: 50,
		column: "event_date",
		order: "desc",
		user_id: getUserData().id,
		status: ["Published", "Cancelled", "Draft"],
	});

	const handleTableChange = (pagination, filters, sorter) => {
		// console.log("sorter", sorter);
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataGetEvent,
		isLoading: isLoadingGetEvent,
		refetch: refetchGetEvent,
		// isFetching: isFetchingGetEvent,
	} = GET(`api/v1/event?${$.param(dataTableInfo)}`, "event", (res) => {
		if (res.success) {
			console.log("event", res);
		}
	});

	useEffect(() => {
		refetchGetEvent();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		// console.log("current", current);
		// console.log("pageSize", pageSize);
	};

	const handleRead = (item) => {
		// console.log("handleRead", item);
		mutatePublicView(
			{
				id: item.id,
				public_status: item.public_status === "No" ? "Yes" : "No",
			},
			{
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Success",
							description: "Successfully updated",
						});
					}
				},
			}
		);
	};

	const { mutate: mutatePublicView, isLoading: isLoadingPublicView } = POST(
		`api/v1/public_status`,
		"event"
	);

	const handleRemove = (item) => {
		history.push("/event-provider-speaker/ce-calendar/edit-event/" + item.id);
	};

	const menu = (item) => {
		return (
			<Menu
				items={[
					{
						label: (
							<a href="#" onClick={() => handleRemove(item)}>
								<EyeOutlined style={{ marginRight: "10px" }} />
								View Event
							</a>
						),
					},
					{
						label: (
							<a href="#" onClick={() => handleRead(item)}>
								<CheckOutlined style={{ marginRight: "10px" }} />
								Set to {item.public_status === "No" ? " Public" : " Private"}
							</a>
						),
					},
				]}
			/>
		);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageViewEditEvents"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CalendarOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br></br>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataGetEvent && dataGetEvent.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						className="table-responsive table-thead-login"
						size="small"
						rowKey={(record) => record.id}
						loading={isLoadingGetEvent}
						dataSource={dataGetEvent ? dataGetEvent.data.data : []}
						pagination={false}
						onChange={handleTableChange}
					>
						<Table.Column
							key="title"
							title="Edit Event"
							dataIndex="title"
							render={(text, record) => {
								let colorText =
									record.public_status === "No" ? "#d6ae71" : "#252525";
								return record.event_details ? (
									// <Link
									//   to={"/event-provider-speaker/ce-calendar/edit-event/" + record.id}
									//   style={{ color: "#d6ae71" }}
									// >
									//   {record.event_details.title}
									// </Link>
									//   <Dropdown overlay={(e) => menu(record)}>
									<Button
										shape="circle"
										type="link"
										style={{ color: "#d6ae71" }}
										onClick={(e) =>
											history.push(
												"/event-provider-speaker/ce-calendar/edit-event/" +
													record.id
											)
										}
									>
										{record.event_details.title}
									</Button>
								) : (
									//   </Dropdown>
									""
								);
							}}
							sorter
						/>
						<Table.Column
							key="event_date"
							title="Date"
							dataIndex="event_date"
							sorter
						/>
						<Table.Column
							key="speaker_name"
							title="Speaker"
							dataIndex="event_name"
							render={(text, record) => {
								let val = record.event_speaker
									.reduce((arr, items) => {
										let name = items.speaker_name.split(", ");
										name = name[0] + ";";
										return (arr += name);
									}, [])
									.split(";")
									.join(", ");
								// console.log(record.event_speaker);
								return val.slice(0, -2);
							}}
							sorter
						/>
						{/* <Table.Column
							key="adress_tbd"
							title="Address TBD"
							dataIndex="event_name"
							render={(text, record) => {
								return record.event_venue && record.event_venue.venue_name === ""
									? record.event_venue.city
									: "";
							}}
							sorter
						/> */}
						<Table.Column
							key="city"
							title="City"
							dataIndex="event_name"
							render={(text, record) => {
								return record.event_venue &&
									record.event_venue.venue_name !== ""
									? record.event_venue.city
									: "";
							}}
							sorter
						/>
						<Table.Column
							key="state"
							title="State"
							dataIndex="event_name"
							render={(text, record) => {
								return record.event_venue &&
									record.event_venue.venue_name !== ""
									? record.event_venue.state
									: "";
							}}
							sorter
						/>
						<Table.Column
							key="certificate_template"
							title="Certificate Template"
							dataIndex="certificate_template"
							render={(text, record) => {
								return record.certificate ? (
									<Link
										to={
											"/event-provider-speaker/certificate-template?id=" +
											record.certificate_id
										}
										style={{ color: "#d6ae71" }}
									>
										{text}
									</Link>
								) : (
									""
								);
							}}
							sorter
						/>
						<Table.Column
							key="event_save_status"
							title="Event Status"
							dataIndex="event_save_status"
							sorter
							render={(text, record) => {
								return record.event_save_status == "Cancelled" ? (
									<div style={{ color: "red" }}>{record.event_save_status}</div>
								) : (
									<div> {record.event_save_status}</div>
								);
							}}
						/>
						{/*
						 */}
					</Table>
				</div>
			</Layout.Content>
		</Layout>
	);
}
