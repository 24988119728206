import React, { useState, useEffect } from "react";
import { Input } from "antd";

const FloatInputDasboard2 = (props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    type,
    required,
    disabled,
    readOnly,
    addonAfter,
    state,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  useEffect(() => {
    // console.log(props)
  }, [props]);

  return (
    <div
      className="float-label company"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      style={{ marginBottom: "15px" }}
    >
      <Input
        onChange={(e) => props.onChange({ ...state, speaker: e.target.value })}
        type={type}
        value={value}
        size="large"
        autoComplete="off"
        disabled={disabled}
        readOnly={readOnly}
        addonAfter={addonAfter}
        allowClear
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInputDasboard2;
