import React from "react";
import { Layout, Form, Button, Row, Col, Collapse, notification } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { SaveOutlined, SnippetsOutlined } from "@ant-design/icons";
import ComponentFaqs from "../Components/ComponentFaqs";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import { POST } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import { useHistory } from "react-router-dom";
export default function PageCreateTicket({ props, permission }) {
  let history = useHistory();
  const sub_title = "Create a";
  const { Panel } = Collapse;
  let userdata = getUserData();

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };
  const [form] = Form.useForm();

  const { mutate: mutateTicket, isLoading: isLoadingtickets } = POST(
    "api/v1/tickets",
    "tickets"
  );

  const handleFinish = (values) => {
    // console.log('handleFinish', values)
    let data = {
      ...values,
      user_id: userdata.id,
      link: window.location.origin,
    };
    mutateTicket(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: res.message,
            description: res.description,
          });
          form.resetFields();
          history.push(
            "/event-provider-speaker/support/ticketing/" + res.data.id
          );
        } else {
        }
      },
      onError: (err) => {},
    });
  };

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageCreateTicket"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<SnippetsOutlined />}
      />
      <Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
        <ComponentFaqs
          linkVid={
            "https://player.vimeo.com/video/654658617?autoplay=0&muted=1"
          }
          col="event_tickets"
          colLink="event_tickets_link"
        />
        <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={4}>
            <Col xs={24} sm={24} md={16} lg={16}>
              <Form.Item
                name="priority"
                className="form-select-error"
                rules={[validator.require]}
                hasFeedback
              >
                <FloatSelect
                  label="Priority"
                  placeholder="Priority"
                  options={[
                    {
                      label: "Low",
                      value: "Low",
                    },
                    {
                      label: "Medium",
                      value: "Medium",
                    },
                    {
                      label: "High",
                      value: "High",
                    },
                    {
                      label: "Urgent",
                      value: "Urgent",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="subject" rules={[validator.require]} hasFeedback>
                <FloatInput label="Subject" placeholder="Subject" />
              </Form.Item>

              <Form.Item name="comments" rules={[validator.require]}>
                <FloatTextArea
                  label="Question/Comments"
                  placeholder="Question/Comments"
                />
              </Form.Item>
            </Col>
          </Row>
          <br></br>
          <Button
            size="large"
            className="btn-login-outline"
            style={{ width: 200 }}
            icon={<SaveOutlined />}
            htmlType="submit"
            loading={isLoadingtickets}
          >
            Submit
          </Button>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
