import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Space } from "antd";
import {
	MenuFoldOutlined,
	HomeOutlined,
	BellOutlined,
	SnippetsOutlined,
	AccountBookOutlined,
	MessageOutlined,
	MenuUnfoldOutlined,
	SettingOutlined,
	TeamOutlined,
	TagOutlined,
	CalendarOutlined,
	MailOutlined,
	EyeOutlined,
	PieChartOutlined,
	DollarCircleFilled,
	DollarCircleOutlined,
} from "@ant-design/icons";
import imageLogo from "../../../assets/img/cileya-logo.png";

import { BiDollar } from "react-icons/bi";
const menuItems = [
	{
		title: "Dashboard",
		path: "/admin/dashboard",
		icon: <HomeOutlined />,
		permission: "Dashboard",
	},
	{
		title: "Revenue",
		path: "/admin/revenue",
		icon: <DollarCircleOutlined />,
		permission: "Revenue",
	},
	{
		title: "Stats",
		path: "/admin/stats",
		icon: <PieChartOutlined />,
		permission: "Revenue",
		children: [
			{
				title: "Financial",
				path: "/admin/stats/financial",
				permission: "Financial",
			},
			{
				title: "Forecast",
				path: "/admin/stats/forecast",
				permission: "Forcast",
			},
			{
				title: "Account Types",
				path: "/admin/stats/account-type",
				permission: "Account Types",
			},
		],
	},
	{
		title: "Account Types",
		path: "/admin/account-type",
		icon: <AccountBookOutlined />,
		children: [
			{
				title: "Event Provider",
				path: "/admin/account-type/event",
				permission: "Event Provider",
			},
			{
				title: "Venue Provider",
				path: "/admin/account-type/venue",
				permission: "Venue Provider",
			},
			{
				title: "Member",
				path: "/admin/account-type/member",
				permission: "Member",
			},
			{
				title: "Speaker",
				path: "/admin/account-type/speaker",
				permission: "Speaker",
			},
			{
				title: "Event Provider/Speaker",
				path: "/admin/account-type/event-provider-speaker",
				permission: "Event Provider/Speaker",
			},
		],
	},

	{
		title: "Profiles",
		path: "/admin/profiles/all",
		icon: <TeamOutlined />,
		permission: "Profiles",
	},
	{
		title: "Notification",
		path: "/admin/notification",
		icon: <BellOutlined />,
		permission: "Notification",
	},
	{
		title: "Ticketing",
		path: "/admin/support/ticketing",
		icon: <SnippetsOutlined />,
		permission: "Ticketing",
	},

	{
		title: "Configuration",
		path: "/admin/configuration",
		icon: <SettingOutlined />,
		permission: "Configuration",
	},
	{
		title: "Messages",
		path: "/admin/messages",
		icon: <MessageOutlined />,
		permission: "Messages",
	},
	{
		title: "Coupon",
		path: "/admin/coupon",
		icon: <TagOutlined />,
		permission: "Coupon",
	},
	{
		title: "Events",
		path: "/admin/events",
		icon: <CalendarOutlined />,
		permission: "Events",
	},
	{
		title: "Editable Templates",
		path: "/admin/editable-templates",
		icon: <MailOutlined />,
		permission: "Editable Templates",
	},
	{
		title: "View As",
		path: "/admin/view-as",
		icon: <EyeOutlined />,
		permission: "View As",
	},
];

export default function ComponentsAdminSidemenu(props) {
	const { history, sideMenuCollapse, setSideMenuCollapse, width } = props;

	let selectedKeys = [];
	let pathname = history.location.pathname;
	pathname = pathname.split("/");

	if (pathname.length > 4) {
		selectedKeys.push(
			"/" + pathname[1] + "/" + pathname[2] + "/" + pathname[3]
		);
	}

	pathname = "/" + pathname[1] + "/" + pathname[2];

	const [openKeys, setOpenKeys] = useState();
	useEffect(() => {
		console.log("@pathname", pathname);
		console.log("@menu", menuItems);
		setOpenKeys(
			menuItems
				.filter((item) => item.path === pathname)
				.map((item) => item.path)
		);
	}, [history, pathname]);

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		const menuItemsFilter = menuItems
			.filter((item) => item.path === latestOpenKey)
			.map((item) => item.path);

		if (menuItemsFilter.indexOf(latestOpenKey) === -1) {
			setOpenKeys(menuItemsFilter);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	const activeRoute = (routeName) => {
		const pathname = history.location.pathname;
		return pathname === routeName ? "ant-menu-item-selected" : "";
	};

	const activeSubRoute = (routeName) => {
		const pathname1 = history.location.pathname;
		const pathname2 = routeName;
		return pathname2 === pathname1 ? "ant-menu-item-selected" : "";
	};

	const handleMenuRender = () => {
		return menuItems.map((item, index) => {
			if (item.children && item.children.length > 0) {
				let children_list = item.children.map((item2, index2) => {
					return (
						<Menu.Item
							key={item2.path}
							className={`${activeSubRoute(item2.path)}`}
							onClick={() => {
								if (width <= 768) {
									setSideMenuCollapse(!sideMenuCollapse);
								}
							}}
						>
							<Link to={item2.path}>{item2.title ?? item2.permission}</Link>
						</Menu.Item>
					);
				});

				if (children_list && children_list.length > 0) {
					return (
						<Menu.SubMenu
							key={item.path}
							icon={item.icon}
							title={item.title}
							className={`${item.className ?? ""}`}
						>
							{children_list}
						</Menu.SubMenu>
					);
				}
			} else {
				return (
					<Menu.Item
						key={item.path}
						className={`${activeRoute(item.path)} ${item.className ?? ""}`}
						icon={item.icon}
						onClick={() => {
							if (width <= 768) {
								setSideMenuCollapse(!sideMenuCollapse);
							}
						}}
					>
						<Link
							onClick={() => {
								setOpenKeys([]);
							}}
							to={item.path}
						>
							{item.title ?? item.permission}
						</Link>
					</Menu.Item>
				);
			}

			return "";
		});
	};

	return (
		<Layout.Sider
			trigger={null}
			collapsible
			collapsed={sideMenuCollapse}
			className="scrollbar-2 mobileSiderFixed"
		>
			<Space className="ant-side-header" size={10}>
				{sideMenuCollapse ? (
					<MenuUnfoldOutlined
						onClick={() => setSideMenuCollapse(!sideMenuCollapse)}
						className="menuCollapseOnClose menuCollapseOnClose-1"
					/>
				) : (
					<MenuFoldOutlined
						onClick={() => setSideMenuCollapse(!sideMenuCollapse)}
						className="menuCollapseOnClose"
					/>
				)}

				{!sideMenuCollapse && (
					<img
						src={process.env.REACT_APP_LOGO_SIDE_BAR}
						className="menuCollapseLogo"
						alt="celiya"
					/>
				)}
			</Space>

			<Menu
				mode="inline"
				theme="light"
				className="sideMenu"
				openKeys={openKeys}
				onOpenChange={onOpenChange}
				selectedKeys={selectedKeys}
			>
				{handleMenuRender()}
			</Menu>
		</Layout.Sider>
	);
}
