import React, { useState } from "react";
import { Select } from "antd";

const FloatSelectRegistration = (props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    type,
    required,
    options,
    disabled,
    account_type,
    multi,
    listHeight,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const multiClass = multi
    ? "float-label float-select-multi"
    : "float-label float-select";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const formatMoney = (val) => {
    return (Math.round(val * 100) / 100).toLocaleString();
  };

  // console.log(props);
  return (
    <div
      className={multiClass}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        style={{ width: "100%" }}
        defaultValue={value}
        value={value}
        // onChange={props.onChange}
        onChange={(e, option) => props.onChange(e, option)}
        size="large"
        allowClear
        showSearch
        disabled={disabled}
        mode={multi}
        dropdownStyle={{ zIndex: 9999999999 }}
        listHeight={listHeight}
        filterOption={(input, option) => {
          console.log("option", option);
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {options.map((item, key) => {
          // console.log("item", item);
          // let bold = item.label.split(" - ");
          // let list = bold[1].split("(");
          // list = list[1].split(")");
          //   list = list[0].split(", ");
          //   list = list
          // console.log("options", item.label.substring(0, 1));
          let price = formatMoney(parseFloat(item.price));
          // if (item.label.substring(0, 1) === "-") {
          if (item.label) {
            return (
              <Select.Option
                key={key}
                value={item.value}
                data-id={item.value}
                data-price={item.price}
                data-plan={item.plan}
                data-policy={item.policy}
                data-json={item.json}
                style={{
                  paddingLeft: "25px",
                }}
                label={item.label}
              >
                {" "}
                <b>
                  {item.plan} - ${price}
                </b>
                <br />
                {item.label}
              </Select.Option>
            );
          } else {
            return (
              <Select.Option
                key={key}
                value={item.value}
                data-id={item.value}
                data-price={item.price}
                data-plan={item.plan}
                data-policy={item.policy}
                data-json={item.json}
                label={item.label}
              >
                {" "}
                {/* {item.label} */}
                <b>
                  {item.plan} - ${price}
                </b>
                <br />
                {item.label}
              </Select.Option>
            );
          }
        })}
      </Select>
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatSelectRegistration;
