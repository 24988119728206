import React, { useEffect, useState, useRef, memo } from "react";
import {
  Card,
  Layout,
  Typography,
  Input,
  DatePicker,
  Row,
  Col,
  Badge,
  Divider,
  Menu,
  Button,
  Alert,
  Space,
  Collapse,
  Tag,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
  HomeOutlined,
  CalendarOutlined,
  EyeOutlined,
  UpOutlined,
  DownOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { MdRefresh } from "react-icons/md";
import ModalContiueFill from "./Modals/ModalContiueFill";
import moment from "moment";

import image1 from "../../../../assets/img/image1.jpg";
import image2 from "../../../../assets/img/image2.jpg";
import image3 from "../../../../assets/img/image3.jpg";

import { Pie } from "@ant-design/plots";

import getUserData from "../../../../providers/getUserData";
import { GET } from "../../../../providers/useAxiosQuery";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import companyInfo from "../../../../providers/companyInfo";
import { useHistory } from "react-router-dom";

import ModalPreview from "./Modals/ModalPreview";
import { Link } from "react-router-dom";
import $ from "jquery";
import states from "states-us";
import FloatSelectDashboard from "../../../../providers/FloatSelectDashboard";
import FloatSelectDashboard1 from "../../../../providers/FloatSelectDashboard1";
import FloatInputDasboard2 from "../../../../providers/FloatInputDasboard2";
import FloatInputDasboard from "../../../../providers/FloatInputDasboard";
import FloatInputDasboard3 from "../../../../providers/FloatInputDasboard3";
import InfiniteScroll from "react-infinite-scroll-component";

const PageMemberDashboardPieChart = ({}) => {
  const userdata = getUserData();
  const sub_title = "All Features";
  const { Search } = Input;
  const { Meta } = Card;
  const history = useHistory();
  const { Panel } = Collapse;

  const getChartConfig = (type) => {
    return {
      height: 150,
      appendPadding: 0,
      angleField: "value",
      colorField: "type",
      // color: ['#293a56', '#d6ae71', '#000000'],
      color: ({ type }) => {
        let text = type;
        let result = text.includes("Not Completed");
        if (result) {
          return "#d6ae71";
        }
        return "#293a56";
      },
      label: {
        type: "inner",
        offset: "-50%",
        // content: "{value}",
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        autoRotate: false,
        position: "bottom",
        style: {
          textAlign: "center",
          fontSize: 10,
        },
      },
      // legend: {
      //   layout: "horizontal",
      //   position: "bottom",
      // },
      legend: false,
      interactions: [{ type: "element-selected" }, { type: "element-active" }],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          formatter: function formatter() {
            return type;
          },
        },
      },
    };
  };
  const [myUpcommingEvents, setMyUpcommingEvent] = useState([]);
  const [ceProfiles, setCeProfiles] = useState([]);

  const { data: dataGetCurrentHours, isLoading: isDataCurrentHours } = GET(
    `api/v1/member_ce_hours_profile/my_hours?user_id=${userdata.id}`,
    "member_my_ce_hours",
    (res) => {
      if (res.success) {
        console.log("hours", res.registered);
        setCeProfiles(res.data);
        setMyUpcommingEvent(res.registered);
      }
    }
  );

  return (
    <>
      <Col xs={24} sm={24} md={9} lg={9} xl={7}>
        <Row className="dashRightRowMargin">
          <Col xs={24} sm={24} md={24} lg={24}>
            {ceProfiles.map((item, key) => {
              return (
                <Collapse
                  key={key}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <span
                        className="ant-menu-submenu-arrow"
                        style={{
                          color: "#FFF",
                          transform: "rotate(270deg)",
                        }}
                      ></span>
                    ) : (
                      <span
                        className="ant-menu-submenu-arrow"
                        style={{
                          color: "#FFF",
                          transform: "rotate(90deg)",
                        }}
                      ></span>
                    )
                  }
                  style={{
                    boxShadow:
                      "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
                    marginTop: 10,
                  }}
                  expandIconPosition="right"
                  defaultActiveKey={["1"]}
                >
                  <Panel
                    header={item.title ? item.title : "No Title"}
                    key="1"
                    className="accordion bg-darkgray-form"
                  >
                    <div style={{ textAlign: "end" }}>
                      {moment(item.start_date).format("YYYY")}/
                      {moment(item.end_date).format("YYYY")}
                    </div>
                    <Pie
                      {...getChartConfig("")}
                      data={[
                        {
                          type: "Completed (" + item.c_hours + "hrs)",
                          value: item.c_hours,
                        },
                        {
                          type: "Not Completed (" + item.n_hours + "hrs)",
                          value: item.n_hours,
                        },
                      ]}
                    />
                    <Row gutter={4}>
                      <Col xs={24} sm={24} md={24} lg={3}></Col>
                      <Col xs={24} sm={24} md={24} lg={9}>
                        <Space>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              background: "#293a56",
                            }}
                          ></div>
                          <div
                            style={{
                              lineHeight: "initial",
                              fontSize: "12px",
                              paddingTop: "11px",
                            }}
                          >
                            Completed <br />
                            {item.c_hours} hrs
                          </div>
                        </Space>
                      </Col>
                      <Col xs={24} sm={24} md={2} lg={2}></Col>
                      <Col xs={24} sm={24} md={10} lg={10}>
                        <Space>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              background: "#d6ae71",
                            }}
                          ></div>
                          <div
                            style={{
                              lineHeight: "initial",
                              fontSize: "12px",
                              paddingTop: "11px",
                            }}
                          >
                            Not Completed <br />
                            {item.n_hours} hrs
                          </div>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              );
            })}
          </Col>
        </Row>
        {/* <br
              style={{ display: ceProfiles.length == 0 ? "none" : "block" }}
            ></br> */}
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              style={{
                boxShadow:
                  "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
                marginTop: 10,
              }}
              expandIconPosition="right"
              defaultActiveKey={["1"]}
            >
              <Panel
                header="My Upcoming Events"
                key="1"
                className="accordion bg-darkgray-form"
              >
                {myUpcommingEvents.length != 0
                  ? myUpcommingEvents.map((item, key) => {
                      return (
                        <div key={key}>
                          <div style={{ fontSize: 16 }}>
                            <Link
                              to={
                                "/member/ce-calendar/my-book-events/" +
                                item.event.id
                              }
                            >
                              {" "}
                              {/* <b>·</b> */}
                              {item.event.event_details.title}
                            </Link>
                          </div>
                          {/* <div>
                                {moment(
                                  item.event.event_schedules[0].datetime_start
                                ).format("MMMM DD, YYYY")}
                              </div>
                              <div>
                                {item.event.event_schedules[0]
                                  ? moment(
                                      item.event.event_schedules[0]
                                        .datetime_start
                                    ).format("h:mma") +
                                    " - " +
                                    moment(
                                      item.event.event_schedules[0].datetime_end
                                    ).format("h:mm: a")
                                  : ""}
                              </div> */}
                          {item.event.event_schedules.map((item, key) => {
                            return (
                              <div key={key} s>
                                <div>
                                  {moment(item.datetime_start).format(
                                    "MMMM DD, YYYY"
                                  )}{" "}
                                  {moment(item.datetime_start).format("h:mma") +
                                    " - " +
                                    moment(item.datetime_end).format("h:mma")}
                                </div>
                                {/* <div style={{ lineHeight: ".9" }}>
                                      {moment(item.datetime_start).format(
                                        "h:mma"
                                      ) +
                                        " - " +
                                        moment(item.datetime_end).format(
                                          "h:mma"
                                        )}
                                    </div> */}
                              </div>
                            );
                          })}
                          <Divider style={{ marginTop: 10 }}></Divider>
                        </div>
                      );
                    })
                  : "No Upcoming Events"}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default memo(PageMemberDashboardPieChart);
