import React, { useState, useEffect } from "react";
import {
	Layout,
	Input,
	Row,
	Col,
	Table,
	Pagination,
	Button,
	Space,
	notification,
	Popconfirm,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";

import {
	ContainerOutlined,
	PlusCircleOutlined,
	TagOutlined,
	EditOutlined,
	DeleteOutlined,
} from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import toCurrency from "../../../../providers/toCurrency";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import moment from "moment";
import ModalAdd from "./Modals/ModalAdd";
import ModalUpdate from "./Modals/ModalUpdate";

export default function PageCoupon({ props, permission }) {
	let history = useHistory();
	const { Search } = Input;
	const sub_title = "View";

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const {
		data: dataCoupons,
		isLoading: isLoadingCoupons,
		refetch: refetchDataInvoices,
		isFetching: isFetchingDataCoupons,
	} = GET(
		`api/v1/get_coupons_system?${$.param(dataTableInfo)}`,
		"get_coupons_system",
		(res) => {
			console.log("coupon", res);
		}
	);

	const [searchText, setSearchText] = useState("");
	const handleOnChange = (e) => {
		setSearchText(e);
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchDataInvoices();
		console.log("dataTableInfo", dataTableInfo);
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		console.log("current", current);
		console.log("pageSize", pageSize);
	};

	const [isModalAdd, setIsModalAdd] = useState(false);
	const [isModalUpdate, setIsModalUpdate] = useState(false);
	const [isModalUpdateData, setIsModalUpdateData] = useState(false);

	const showModalUpdate = (record) => {
		setIsModalUpdateData(record);
		setIsModalUpdate(true);
	};

	const { mutate: mutateCoupon, isLoading: isLoadingCoupon } = POST(
		"api/v1/delete_coupon_system",
		"get_coupons_system"
	);
	const deleteCoupon = (id) => {
		mutateCoupon(
			{ id: id },
			{
				onSuccess: (res) => {
					if (res.success) {
						// console.log(res)
						notification.success({
							message: "Success",
							description: "Coupon Successfully Deactivated",
						});
					}
				},
				onError: (err) => {
					console.log(err);
				},
			}
		);
	};

	function convertDollar(x) {
		return Number.parseFloat(x).toFixed(2);
	}

	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageCoupon"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<TagOutlined />}
			/>
			<br></br>

			<Layout.Content
				style={{ padding: "30px", paddingTop: "0px" }}
				className="webMT"
			>
				<Row>
					<Col md={8} xs={24}>
						<Button
							className="btn-login-outline addTicketCoupon"
							icon={<PlusCircleOutlined />}
							onClick={() => {
								setIsModalAdd(true);
							}}
							style={{ marginBottom: 10 }}
						>
							Add Coupon
						</Button>
					</Col>
				</Row>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataCoupons && dataCoupons.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={handleOnChange}
						/>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						className="table-responsive table-thead-login"
						size="small"
						rowKey={(record) => record.id}
						loading={isLoadingCoupons}
						dataSource={dataCoupons ? dataCoupons.data.data : []}
						pagination={false}
						onChange={handleTableChange}
					>
						<Table.Column
							key="coupon_code"
							title="Coupon Code"
							dataIndex="coupon_code"
							sorter
						/>
						<Table.Column
							key="coupon_name"
							title="Coupon Name"
							dataIndex="coupon_name"
							sorter
						/>

						<Table.Column
							key="off"
							title="Discount"
							dataIndex="off"
							sorter
							render={(text, record) => {
								return (
									<div key={record.id}>
										{record.type == "percent" && record.off + "%"}
										{record.type == "fixed" &&
											"$" + convertDollar(record.off) + " off"}
										{record.type == "offer" && record.off + " days"}
									</div>
								);
							}}
							// sorter
						/>
						<Table.Column
							key="duration_from"
							title="Duration"
							dataIndex="duration_from"
							sorter
							render={(text, record) => {
								return (
									<div key={record.id}>
										{"" + record.duration_from + " - " + record.duration_to}
									</div>
								);
							}}
						/>

						<Table.Column
							key="role"
							title="Account Type"
							dataIndex="role"
							sorter
							render={(text, record) => {
								return (
									<div key={record.id}>
										{JSON.parse(record.role).join(", ")}
									</div>
								);
							}}
						/>
						<Table.Column
							key="redemption"
							title="Redemption"
							dataIndex="redemption"
							sorter
							render={(text, record) => {
								return (
									<div key={record.id}>
										{record.redemption
											? record.redemption + "/" + record.max
											: record.redemption}
									</div>
								);
							}}
						/>
						{/* <Table.Column
              key="days_free"
              title="Days Free"
              dataIndex="days_free"
              sorter={(a, b) => a.days_free.localeCompare(b.days_free)}
              render={(text, record) => {
                return <div key={record.id}>{record.days_free}</div>;
              }}
            /> */}
						<Table.Column
							key="action"
							title="Action"
							className="couponAction"
							// sorter
							render={(text, record) => {
								return (
									<Space>
										<Button
											size="small"
											className="btn-warning-outline"
											icon={<EditOutlined />}
											onClick={(e) => showModalUpdate(record)}
										>
											Edit
										</Button>

										<Popconfirm
											placement="topRight"
											title={
												"Are you sure you want to deactivate this coupon ?"
											}
											onConfirm={() => deleteCoupon(record.id)}
											okText="Yes"
											cancelText="No"
										>
											<Button
												size="small"
												className="btn-danger-outline"
												icon={<DeleteOutlined />}
											>
												Deactivate
											</Button>
										</Popconfirm>
									</Space>
								);
							}}
						/>
					</Table>
				</div>
				<ModalAdd isModalAdd={isModalAdd} setIsModalAdd={setIsModalAdd} />
				<ModalUpdate
					isModalUpdate={isModalUpdate}
					setIsModalUpdate={setIsModalUpdate}
					isModalUpdateData={isModalUpdateData}
					setIsModalUpdateData={setIsModalUpdateData}
				/>
			</Layout.Content>
		</Layout>
	);
}
