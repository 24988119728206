import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  Badge,
  Divider,
  Menu,
  Checkbox,
  Collapse,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import {
  HomeOutlined,
  SaveOutlined,
  FieldTimeOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  UserOutlined,
  EditOutlined,
  MinusOutlined,
  CreditCardFilled,
  UpOutlined,
  DownOutlined,
  CloseCircleFilled,
  ClockCircleFilled,
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { faMapLocation, faCircleDot } from "@fortawesome/free-solid-svg-icons";

import { Link, useHistory } from "react-router-dom";

import { Pie } from "@ant-design/charts";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatDateTimePicker from "../../../../providers/FloatDateTimePicker";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatAutoComplete from "../../../../providers/FloatAutoComplete";
// import FloatInputNumber from "../../../../providers/FloatInputNumber";
import FloatInputRate from "../../../../providers/FloatInputRate";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import states from "states-us";
import { GET, POST } from "../../../../providers/useAxiosQuery";

import getUserData from "../../../../providers/getUserData";
import moment from "moment";
export default function PageCEHoursProfileView({ match, permission }) {
  let history = useHistory();
  const userdata = getUserData();
  const sub_title = "CE HOURS";
  const { Panel } = Collapse;

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };
  const [form] = Form.useForm();
  const [state, setState] = useState([]);
  useEffect(() => {
    let arr = [];
    states.map((row, key) => {
      if (
        row.name != "Federated States Of Micronesia" &&
        row.name != "Marshall Islands" &&
        row.name != "Palau" &&
        row.name != "Northern Mariana Islands"
      ) {
        arr.push({
          value: row.abbreviation,
          label: row.name,
        });
      }
    });
    setState(arr);
  }, []);

  const {
    mutate: mutateCreateCeHoursProfile,
    isLoading: isLoadingCreateCeHoursProfile,
  } = POST(
    "api/v1/member_ce_hours_profile_view/update",
    "member_ce_hours_profile_view_update"
  );

  const handleFinish = (val) => {
    let data = {
      ...val,
      date_issued: val.date_issued.format("YYYY-MM-DD"),
      renewal_date: val.renewal_date.format("YYYY-MM-DD"),
    };
    data["ce_renewals"].map((item) => {
      item["start_date"] = item["start_date"].format("YYYY-MM-DD");
      item["end_date"] = item["end_date"].format("YYYY-MM-DD");
    });
    console.log("data", data);
    mutateCreateCeHoursProfile(
      { ...data, id: match.params.id },
      {
        onSuccess: (res) => {
          if (res.success) {
            console.log(res);
            notification.success({
              message: "Success",
              description: "Successfully Saved!",
            });
          }
        },
        onError: (res) => {},
      }
    );
  };

  const { data: dataGetCeProfile, isLoading: isDataGetCeProfile } = GET(
    `api/v1/member_ce_hours_profile/my_profile_view?id=${match.params.id}`,
    "member_my_ce_hours_my_profile_view",
    (res) => {
      if (res.success) {
        console.log("tae", res.data);
        if (userdata.id != res.data.user_id) {
          history.push("/member/ce-hours/profile");
        }

        res.data["date_issued"] = moment(res.data["date_issued"]);
        res.data["renewal_date"] = moment(res.data["renewal_date"]);
        res.data.ce_renewals.forEach((element) => {
          element["current_ce_hours"] =
            element["current_ce_hours"] == 0
              ? null
              : element["current_ce_hours"];
          element["start_date"] = moment(element["start_date"]);
          element["end_date"] = moment(element["end_date"]);
          element["show"] = element["show"] == 1 ? true : false;
        });

        console.log("edit", res.data);
        form.setFieldsValue(res.data);
      }
    }
  );
  const backButton = () => {
    history.push("/member/ce-hours/profile");
  };

  const { mutate: mutateGetHours, isLoading: isLoadingGetHours } = POST(
    "api/v1/member_my_ce_hours_my_profile_get_hours",
    "member_my_ce_hours_my_profile_get_hours"
  );

  const onChangeForm = (change, allval) => {
    console.log(change[0].name);
    if (change[0].name.length > 2) {
      if (
        change[0].name[0] == "ce_renewals" &&
        change[0].name[2] != "min_license_ce_hours_required" &&
        change[0].name[2] != "title" &&
        change[0].name[2] != "show"
      ) {
        var key = change[0].name[1];
        var val = form.getFieldsValue(true);

        var start_date = val["ce_renewals"][key]["start_date"]
          ? val["ce_renewals"][key]["start_date"].format("YYYY-MM-DD")
          : null;

        var end_date = val["ce_renewals"][key]["end_date"]
          ? val["ce_renewals"][key]["end_date"].format("YYYY-MM-DD")
          : null;

        var min = val["ce_renewals"][key]["min_license_ce_hours_required"]
          ? val["ce_renewals"][key]["min_license_ce_hours_required"]
          : null;

        // console.log("start_date", start_date);
        // console.log("end_date", end_date);

        if (start_date && end_date) {
          mutateGetHours(
            {
              start_date: start_date,
              end_date: end_date,
              user_id: userdata.id,
            },
            {
              onSuccess: (res) => {
                if (res.success) {
                  // console.log(res);

                  if (res.hours == 0) {
                    notification.success({
                      message: "Success",
                      description: "No CE Hours Credited",
                    });
                  } else {
                    notification.success({
                      message: "Success",
                      description: "Current CE Hours Updated",
                    });
                  }

                  // console.log("key", key);
                  // console.log("val", val);
                  val["ce_renewals"][key]["current_ce_hours"] =
                    res.hours == 0 ? null : res.hours;
                  form.setFieldsValue(val);
                }
              },
              onError: (res) => {},
            }
          );
        }
      }
    }
  };

  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log("@isChange", isChange);
      if (isChange) {
        form.submit();
        // console.log("fomr submit");
        setIsChange(false);
      }
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isChange]);

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageCEHoursProfileView"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<ClockCircleFilled />}
      />

      <Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
        <ComponentFaqs
          linkVid={
            "https://player.vimeo.com/video/654658562?autoplay=0&muted=1&muted=1&muted=1"
          }
          col="member_ce_profile"
          colLink="member_ce_profile_link"
        />
        <br></br>
        <Button
          icon={<ArrowLeftOutlined />}
          className="btn-login-outline"
          onClick={() => backButton()}
        >
          Back
        </Button>
        <br></br>
        <br></br>

        <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          onFinish={handleFinish}
          form={form}
          onFieldsChange={(changedValues, allValues) =>
            onChangeForm(changedValues, allValues)
          }
          onValuesChange={() => {
            setIsChange(true);
          }}
        >
          <Row gutter={4}>
            <Col xs={24} sm={24} md={16} lg={16}>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Dental License"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="title"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput label="Title" placeholder="Title" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="dental_license"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Dental License #"
                          placeholder="Dental License #"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="dental_license_issued_in"
                        rules={[validator.require]}
                        hasFeedback
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Dental License Issued In"
                          placeholder="Dental License Issued In"
                          options={state}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="date_issued"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatDatePicker
                          label="Date Issued"
                          // placeholder="Date and End Time"
                          mode
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="renewal_date"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatDatePicker
                          label="Renewal Date"
                          // placeholder="Date and End Time"
                          mode
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      {" "}
                      <Form.Item
                        name="agd_id"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput label="AGD ID #" placeholder="AGD ID #" />
                      </Form.Item>
                    </Col>{" "}
                  </Row>
                </Panel>
              </Collapse>

              <Form.List name="ce_renewals">
                {(fields, { add, remove }) => (
                  <>
                    <Row gutter={24}>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                      >
                        <Form.Item>
                          <Button
                            className="btn-login-outline"
                            icon={<PlusCircleOutlined />}
                            onClick={() => add(null, 0)}
                            // onClick={() => add()}
                            style={{
                              float: "right",
                              marginBottom: "-30px",
                            }}
                          >
                            {/* <Button
                            className="btn-login-outline"
                            onClick={() => add(null, 0)}
                            block
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                          > */}
                            Add Renewal
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Collapse
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <span
                            className="ant-menu-submenu-arrow"
                            style={{
                              color: "#FFF",
                              transform: "rotate(270deg)",
                            }}
                          ></span>
                        ) : (
                          <span
                            className="ant-menu-submenu-arrow"
                            style={{
                              color: "#FFF",
                              transform: "rotate(90deg)",
                            }}
                          ></span>
                        )
                      }
                      expandIconPosition="right"
                      defaultActiveKey={["1"]}
                    >
                      <Panel
                        header="License Renewal Requirements"
                        key="1"
                        className="accordion bg-darkgray-form"
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <Row key={key} gutter={24}>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                            >
                              <Form.Item
                                valuePropName="checked"
                                label="Show on Dashboard"
                                name={[name, "show"]}
                              >
                                <Switch defaultChecked />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={22}
                              lg={22}
                              xl={22}
                              xxl={22}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "title"]}
                                rules={[validator.require]}
                                hasFeedback
                              >
                                <FloatInput label="Title" placeholder="Title" />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "start_date"]}
                                rules={[validator.require]}
                                hasFeedback
                              >
                                <FloatDatePicker
                                  label="Start Date"
                                  // placeholder="Date and End Time"
                                  mode
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={10}
                              lg={10}
                              xl={10}
                              xxl={10}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "end_date"]}
                                rules={[validator.require]}
                                hasFeedback
                              >
                                <FloatDatePicker
                                  label="End Date"
                                  // placeholder="Date and End Time"
                                  mode
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "min_license_ce_hours_required"]}
                                rules={[validator.require]}
                                hasFeedback
                              >
                                <FloatInput
                                  label="Minumum License CE Hours Required"
                                  placeholder="Minumum License CE Hours Required"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={10}
                              lg={10}
                              xl={10}
                              xxl={10}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "current_ce_hours"]}
                                hasFeedback
                              >
                                <FloatInput
                                  label="Current CE Hours"
                                  placeholder="Current CE Hours"
                                  disabled={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={2}
                              lg={2}
                              xl={2}
                              xxl={2}
                            >
                              <Button
                                icon={<MinusCircleOutlined />}
                                className="btn-danger-outline"
                                size="large"
                                style={{
                                  marginBottom: "15px",
                                }}
                                onClick={() => remove(name)}
                              ></Button>
                            </Col>
                          </Row>
                        ))}
                        <Divider orientation="left"></Divider>
                        <a
                          target={"_blank"}
                          href="https://celiya.com/dentist-dds-ce-requirements/"
                        >
                          - Click Here for Dentists.
                        </a>
                        <br></br>
                        <a
                          target={"_blank"}
                          href="https://celiya.com/dental-hygienist-rdh-ce-requirements/"
                        >
                          - Click Here for Dental Hygienists.
                        </a>
                        <br></br>
                        <a
                          target={"_blank"}
                          href="https://celiya.com/dental-assistant-rda-ce-requirements/"
                        >
                          - Click Here for Dental Assistants.
                        </a>
                      </Panel>
                    </Collapse>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          {/* <br></br>
          <Button
            size="large"
            className="btn-login-outline"
            style={{ width: 200 }}
            icon={<SaveOutlined />}
            onClick={() => form.submit()}
            loading={isLoadingCreateCeHoursProfile}
          >
            Save
          </Button> */}
        </Form>
      </Layout.Content>
    </Layout>
  );
}
