import { Col, Divider, Image, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";

import BlackAndGold from "../../../../../assets/img/certificates/back_and_gold.jpg";
import BlackAndRed from "../../../../../assets/img/certificates/back_and_red.jpg";
import BlueAndGold from "../../../../../assets/img/certificates/blue_and_gold.jpg";
import BlueAndGreen from "../../../../../assets/img/certificates/blue_and_green.jpg";
import BlueAndRed from "../../../../../assets/img/certificates/blue_and_red.jpg";
import BlueAndRed2 from "../../../../../assets/img/certificates/blue_and_red2.jpg";
import BlueAndTeal from "../../../../../assets/img/certificates/blue_and_teal.jpg";

import BlackAndGold1 from "../../../../../assets/img/certificates/back_and_gold-1.jpg";
import BlackAndRed1 from "../../../../../assets/img/certificates/back_and_red-1.jpg";
import BlueAndGold1 from "../../../../../assets/img/certificates/blue_and_gold-1.jpg";
import BlueAndGreen1 from "../../../../../assets/img/certificates/blue_and_green-1.jpg";
import BlueAndRed1 from "../../../../../assets/img/certificates/blue_and_red-1.jpg";
import BlueAndRed21 from "../../../../../assets/img/certificates/blue_and_red2-1.jpg";
import BlueAndTeal1 from "../../../../../assets/img/certificates/blue_and_teal-1.jpg";
import moment from "moment";
import companyInfo from "../../../../../providers/companyInfo";

const ModalPreviewCertificate = ({
  previewCertificate,
  handlePreviewCancel,
  dataCetificate,
}) => {
  const [certBG, setCertBG] = useState();
  const [certSign, setCertSign] = useState();
  const [certLogo, setCertLogo] = useState();
  const [certCERP, setCertCERP] = useState();
  const [certPACE, setCertPACE] = useState();
  const [certEventDetails, setCertEventDetails] = useState([]);
  const [certEventVenue, setCertEventVenue] = useState([]);
  const [certEventSpeaker, setCertEventSpeaker] = useState();
  const [certEventSchedule, setCertEventSchedule] = useState([]);
  const [certEventScheduleTime, setCertEventScheduleTime] = useState([]);
  const [certEventUser, setCertEventUser] = useState([]);
  const [certVerification, setCertVerification] = useState(0);
  useEffect(() => {
    if (dataCetificate) {
      let joint = 0;
      if (
        dataCetificate.event_info &&
        dataCetificate.event_info.event_details.joint_event_provider_1 !== ""
      ) {
        joint = 1;
      }
      if (
        dataCetificate.event_info &&
        dataCetificate.event_info.event_details.joint_event_provider_2 !== ""
      ) {
        joint = 1;
      }
      if (
        dataCetificate.event_info &&
        dataCetificate.event_info.event_details.joint_event_provider_3 !== ""
      ) {
        joint = 1;
      }
      console.log("dataCetificate", joint);

      if (dataCetificate.template === "Black and Gold") {
        if (joint == 1) {
          setCertBG(BlackAndGold);
        } else {
          setCertBG(BlackAndGold1);
        }
      } else if (dataCetificate.template === "Black and Red") {
        if (joint == 1) {
          setCertBG(BlackAndRed);
        } else {
          setCertBG(BlackAndRed1);
        }
      } else if (dataCetificate.template === "Blue and Gold") {
        if (joint == 1) {
          setCertBG(BlueAndGold);
        } else {
          setCertBG(BlueAndGold1);
        }
      } else if (dataCetificate.template === "Blue and Green") {
        if (joint == 1) {
          setCertBG(BlueAndGreen);
        } else {
          setCertBG(BlueAndGreen1);
        }
      } else if (dataCetificate.template === "Blue and Red 1") {
        if (joint == 1) {
          setCertBG(BlueAndRed);
        } else {
          setCertBG(BlueAndRed1);
        }
      } else if (dataCetificate.template === "Blue and Red 2") {
        if (joint == 1) {
          setCertBG(BlueAndRed2);
        } else {
          setCertBG(BlueAndRed2);
        }
      } else if (dataCetificate.template === "Blue and Teal") {
        if (joint == 1) {
          setCertBG(BlueAndTeal);
        } else {
          setCertBG(BlueAndTeal1);
        }
      }
      setCertSign(companyInfo().apiUrl + dataCetificate.signature);
      setCertLogo(companyInfo().apiUrl + dataCetificate.company_logo);
      setCertCERP(companyInfo().apiUrl + dataCetificate.ada_cerp_logo);
      setCertPACE(companyInfo().apiUrl + dataCetificate.ada_pace_logo);
      setCertEventDetails(
        dataCetificate.event_info && dataCetificate.event_info.event_details
          ? dataCetificate.event_info.event_details
          : []
      );
      setCertEventVenue(
        dataCetificate.event_info && dataCetificate.event_info.event_venue
          ? dataCetificate.event_info.event_venue
          : []
      );
      let speaker =
        dataCetificate.event_info &&
        dataCetificate.event_info.event_speaker
          .reduce((arr, items) => {
            let name = items.speaker_name.split(", ");
            name = name[0] + ";";
            return (arr += name);
          }, [])
          .split(";")
          .join(", ");
      setCertEventSpeaker(
        dataCetificate.event_info && dataCetificate.event_info.event_speaker
          ? speaker.slice(0, -2)
          : "N/A"
      );

      let schedule =
        dataCetificate.event_info &&
        dataCetificate.event_info.event_schedules
          .reduce((arr, items) => {
            var date = new Date(items.datetime_start);
            let name = moment(date).format("L");
            name = name + ";";
            return (arr += name);
          }, [])
          .split(";")
          .join(", ");
      setCertEventSchedule(
        dataCetificate.event_info && dataCetificate.event_info.event_schedules
          ? schedule.slice(0, -2)
          : "N/A"
      );

      let schedule_time =
        dataCetificate.event_info &&
        dataCetificate.event_info.event_schedules
          .reduce((arr, items) => {
            var date = new Date(items.datetime_start);
            let name = moment(date).format("LT");
            name = name + ";";
            return (arr += name);
          }, [])
          .split(";")
          .join(", ");
      setCertEventScheduleTime(
        dataCetificate.event_info && dataCetificate.event_info.event_schedules
          ? schedule_time.slice(0, -2)
          : "N/A"
      );

      setCertEventUser(
        dataCetificate.event_info && dataCetificate.event_info.user
          ? dataCetificate.event_info.user
          : []
      );

      let verification =
        dataCetificate.event_info && dataCetificate.event_info.event_details
          ? JSON.parse(
              dataCetificate.event_info &&
                dataCetificate.event_info.event_details.verification
            )
          : [];
      let count = 0;
      verification.map((row, key) => {
        if (row === "ADA CERP Verification") {
          count = count + 1;
          // setCertVerification(1);
        } else if (row === "ADA PACE Verification") {
          count = count + 2;
        } else {
          count = 0;
        }
      });
      setCertVerification(count);
    }
  }, [dataCetificate]);

  return (
    <Modal
      title="Preview Certificate"
      className="modal-login modal-certificate"
      visible={previewCertificate}
      onCancel={handlePreviewCancel}
      footer={false}
      width="950px"
      bodyStyle={{
        padding: "0",
        background: "url(" + certBG + ") center center / cover no-repeat",
        height: "1250px",
        marginTop: "-1px",
      }}
    >
      {/* <Row gutter={24}></Row> */}
      <div className="cert-div">
        <Image src={certLogo} preview={false} className="cert-logo" />
      </div>
      <div className="cert-address">
        {/* <span>1234 S. Drive Gilbert, AZ 85296</span> */}
        <span>
          {certEventUser && certEventUser.member_company
            ? certEventUser.member_company.business_name
            : ""}
          {certEventVenue
            ? certEventVenue.address_1
              ? ", " + certEventVenue.address_1
              : certEventVenue.city
              ? ", " + certEventVenue.city
              : ""
            : ""}
        </span>
      </div>
      <div className="cert-joint-providers">
        <span>
          {certEventDetails && certEventDetails.joint_event_provider_1
            ? certEventDetails.joint_event_provider_1
            : ""}
        </span>
        <span>
          {certEventDetails && certEventDetails.joint_event_provider_2
            ? certEventDetails.joint_event_provider_2
            : ""}
        </span>
        <span>
          {certEventDetails && certEventDetails.joint_event_provider_3
            ? certEventDetails.joint_event_provider_3
            : ""}
        </span>
      </div>

      <div className="cert-content">
        <Row gutter={24} className="cert-name cert-text-font ">
          <Col xs={19} sm={19} md={19} offset={2}>
            <span className="cert-text">John Doe</span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">PARTICIPANT'S NAME</span>
          </Col>
        </Row>

        <Row gutter={24} className="cert-text-font margin-top">
          <Col xs={8} sm={8} md={8} offset={3}>
            <span className="cert-text">123467</span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">STATE AND LICENSE #</span>
          </Col>
          <Col xs={8} sm={8} md={8} offset={1}>
            <span className="cert-text">000340567</span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">PARTICIPANT'S AGD ID #</span>
          </Col>
        </Row>

        <Row gutter={24} className="cert-text-font margin-top">
          <Col xs={19} sm={19} md={19} offset={2}>
            <span className="cert-text">
              {/* Hygienists and Invisalign: How to Identify and Sell More Cases */}
              {certEventDetails && certEventDetails.title
                ? certEventDetails.title
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">EVENT NAME</span>
          </Col>
        </Row>

        <Row gutter={24} className="cert-location cert-text-font">
          <Col xs={19} sm={19} md={19} offset={2}>
            <span className="cert-text">
              {/* 123 W. Someplace Street, Gilbert, AZ 85234 */}
              {certEventVenue
                ? certEventVenue.meeting_url
                  ? certEventVenue.meeting_url
                  : certEventVenue.address_1
                  ? certEventVenue.address_1
                  : certEventVenue.city
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">LOCATION</span>
          </Col>
        </Row>

        <Row gutter={24} className="cert-text-font margin-top">
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">{certEventSchedule}</span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">DATE</span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">{certEventScheduleTime}</span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">START TIME</span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {certEventDetails && certEventDetails.ce_credit_hours
                ? certEventDetails.ce_credit_hours
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">CE CREDITS</span>
          </Col>
        </Row>

        <Row gutter={24} className="cert-text-font margin-top">
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {certEventDetails && certEventDetails.subject_code
                ? certEventDetails.subject_code
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">SUBJECT CODE</span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {certEventDetails && certEventDetails.course_topic
                ? certEventDetails.course_topic
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">EDUCATION METHOD</span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {certEventDetails && certEventDetails.completion_code
                ? certEventDetails.completion_code
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">COMPLETION CODE</span>
          </Col>
        </Row>

        <Row gutter={24} className="cert-text-font margin-top">
          <Col xs={8} sm={8} md={8} offset={2}>
            <span className="cert-text">{certEventSpeaker}</span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">SPEAKER</span>
          </Col>
          <Col xs={8} sm={8} md={8} offset={3}>
            <span className="cert-text">
              {certEventDetails && certEventDetails.director
                ? certEventDetails.director
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">DIRECTOR</span>
          </Col>
        </Row>

        <Row gutter={24} className="cert-text-font margin-top">
          <Col xs={8} sm={8} md={8} offset={2}>
            <span className="cert-text">
              {certEventDetails && certEventDetails.facilitator
                ? certEventDetails.facilitator
                : "N/A"}
            </span>
            <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
            <span className="cert-label">FACILITATOR</span>
          </Col>
          <Col xs={8} sm={8} md={8} offset={3}>
            <Image className="cert-signature" src={certSign} preview={false} />
            <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
            <span className="cert-label">AUTHORIZED SIGNATURE</span>
          </Col>
        </Row>
      </div>

      <Row
        gutter={24}
        className="cert-logo-ada-pace"
        // style={{ position: "absolute", bottom: "53px", width: "100%" }}
      >
        {certVerification === 3 && (
          <>
            <Col xs={12} sm={12} md={12}>
              <Image
                className="cert-ada-image"
                src={certCERP}
                preview={false}
                style={{ marginLeft: "28px" }}
              />
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Image
                src={certPACE}
                preview={false}
                className="cert-pace-image"
                style={{ marginLeft: "34px" }}
              />
            </Col>
          </>
        )}

        {certVerification === 1 && (
          <Col xs={12} sm={12} md={12} offset={6}>
            <Image className="cert-ada-image" src={certCERP} preview={false} />
          </Col>
        )}

        {certVerification === 2 && (
          <Col xs={12} sm={12} md={12} offset={6}>
            <Image src={certPACE} preview={false} className="cert-pace-image" />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default ModalPreviewCertificate;
