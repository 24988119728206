import React, { useState, useEffect } from "react";
import {
	Card,
	Layout,
	Modal,
	Typography,
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Checkbox,
	Collapse,
	notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import {
	HomeOutlined,
	SaveOutlined,
	FieldTimeOutlined,
	ClockCircleOutlined,
	CalendarOutlined,
	UserOutlined,
	BankOutlined,
	MinusOutlined,
	UpOutlined,
	DownOutlined,
	ArrowLeftOutlined,
} from "@ant-design/icons";
import {
	faMapLocation,
	faCircleDot,
	faBuilding,
} from "@fortawesome/free-regular-svg-icons";

import { Link } from "react-router-dom";

import { Pie } from "@ant-design/charts";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";

import countryList from "react-select-country-list";
import states from "states-us";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatInputMask from "../../../../providers/FloatInputMask";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";

export default function PageMyCompany({ history, match, permission }) {
	// let history = useHistory();
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		setFormData(val);
		form.resetFields(["state", "zip"]);
	};
	const { Panel } = Collapse;
	const sub_title = "Edit Account";
	let country_list = countryList().getData();
	let userdata = getUserData();
	const [state, setState] = useState([]);

	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		require_false: {
			required: false,
			message: "Required",
		},
	};
	const [form] = Form.useForm();

	const onFinishMyAccount = (values) => {
		let data = {
			...values,
			user_id: userdata.id,
			id: match.params.id,
			primary: primary,
		};
		console.log("onFinishMyAccount", data);
		mutateCreateAccount(data, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					notification.success({
						message: "Success",
						description: "Successfully Saved!",
					});
				}
			},
			onError: (res) => {},
		});
	};
	const [primary, setPrimary] = useState(0);

	const { data: dataGetAccount, isLoading: isLoadingGetAccount } = GET(
		`api/v1/getAccId_mycopmany?id=${match.params.id}`,
		"getAccId_mycopmany",
		(res) => {
			if (res.success) {
				console.log("member_company_account", res);

				if (userdata.id != res.data.user_id) {
					history.push("/member/my-account/my-company");
				}

				form.setFieldsValue({
					title: res.data.title,
					address_1: res.data.address_1,
					address_2: res.data.address_2,
					business_email: res.data.business_email,
					business_fax: res.data.business_fax,
					business_name: res.data.business_name,
					business_phone: res.data.business_phone,
					business_phone2: res.data.business_phone2,
					country: res.data.country,
					city: res.data.city,
					service: res.data.service,
					state: res.data.state,
					website: res.data.website,
					zip: res.data.zip,
					map_link: res.data.map_link,
					business_categories: res.data.business_categories
						? JSON.parse(res.data.business_categories)
						: [],
				});

				setPrimary(res.data.primary);
				if (res.data.title == "" || res.data.title == null) {
					form.resetFields(["title"]);
				}

				if (res.data.website == "" || res.data.website == null) {
					form.resetFields(["website"]);
				}

				if (res.data.service == "" || res.data.service == null) {
					form.resetFields(["service"]);
				}

				if (res.data.address_2 == "" || res.data.address_2 == null) {
					form.resetFields(["address_2"]);
				}

				if (
					res.data.additional_legal_credentials == "" ||
					res.data.additional_legal_credentials == null
				) {
					form.resetFields(["additional_legal_credentials"]);
				}
				if (res.data.company_agd == "" || res.data.company_agd == null) {
					form.resetFields(["company_agd"]);
				}
				if (res.data.business_fax == "" || res.data.business_fax == null) {
					form.resetFields(["business_fax"]);
				}
				if (res.data.map_link == "" || res.data.map_link == null) {
					form.resetFields(["map_link"]);
				}
			}
		}
	);

	const { mutate: mutateCreateAccount, isLoading: isLoadingCreateAccount } =
		POST("api/v1/member_company/updateAcc", "getAccId_mycopmany");

	const backButton = () => {
		history.push("/member/my-account/my-company");
	};

	const [formData, setFormData] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (formData) {
				form.submit();
				setFormData("");
			}
		}, 3000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [formData]);

	const handleInput = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const handleSelect = (val, option) => {
		setFormData(val);
	};

	const handleTextArea = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const { data: dataUser, isLoading: isLoadingUser } = GET(
		`api/v1/get_by_id?id=${userdata.id}`,
		"get_by_id",
		(res) => {
			if (res.success) {
				console.log("res", res);
			}
		}
	);

	const [businessCategory, setBusinessCategory] = useState([
		{
			label: "Free",
			value: "Free",
		},
	]);

	useEffect(() => {
		if (dataUser) {
			let planType = dataUser.data[0].user_plans
				? dataUser.data[0].user_plans.stripe_subscription_status == "active"
					? dataUser.data[0].user_plans.account_type_plans
						? dataUser.data[0].user_plans.account_type_plans.plan
						: "0"
					: "0"
				: "0";

			console.log("planType", planType);
			if (planType == "0") {
				let business_category = [
					{
						label: "Free",
						value: "Free",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}

			if (planType.includes("Group 1")) {
				let business_category = [
					{
						label: "Dental Student",
						value: "Dental Student",
					},
					{
						label: "Dental Assistants",
						value: "Dental Assistants",
					},
					{
						label: "Front Office",
						value: "Front Office",
					},
					{
						label: "Office Manager",
						value: "Office Manager",
					},
					{
						label: "Office Coordinator",
						value: "Office Coordinator",
					},
					{
						label: "Dental Practice Owner",
						value: "Dental Practice Owner",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}
			if (planType.includes("Group 2")) {
				let business_category = [
					{
						label: "Retired Dentist",
						value: "Retired Dentist",
					},
					{
						label: "Denturist",
						value: "Denturist",
					},
					{
						label: "Dental Hygienist",
						value: "Dental Hygienist",
					},
					{
						label: "Dental Health Aid Therapist (DHAT)",
						value: "Dental Health Aid Therapist (DHAT)",
					},
					{
						label: "Dental Professor/Instructor",
						value: "Dental Professor/Instructor",
					},
					{
						label: "Dental Lab Techs",
						value: "Dental Lab Techs",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}
			if (planType.includes("Group 3")) {
				let business_category = [
					{
						label: "Dentists",
						value: "Dentists",
					},
					{
						label: "Dental Specialist",
						value: "Dental Specialist",
					},
					{
						label: "Endodontist",
						value: "Endodontist",
					},
					{
						label: "Oral Surgeon",
						value: "Oral Surgeon",
					},
					{
						label: "Periodontist",
						value: "Periodontist",
					},
					{
						label: "Pediatric Dentist",
						value: "Pediatric Dentist",
					},
					{
						label: "Prosthodontist",
						value: "Prosthodontist",
					},
					{
						label: "Dental Anesthesiologist",
						value: "Dental Anesthesiologist",
					},
					{
						label: "Dental Public Health",
						value: "Dental Public Health",
					},
					{
						label: "Oral Maxillofacial Pathologist",
						value: "Oral Maxillofacial Pathologist",
					},
					{
						label: "Oral Maxillofacial Radiologist",
						value: "Oral Maxillofacial Radiologist",
					},
					{
						label: "Oral Medicine",
						value: "Oral Medicine",
					},
					{
						label: "Oral Facial Pain Specialist",
						value: "Oral Facial Pain Specialist",
					},
					{
						label: "Other",
						value: "Other",
					},
				];
				setBusinessCategory(
					business_category.sort((a, b) => a.label.localeCompare(b.label))
				);
			}
		}
	}, [dataUser]);

	const onChangeOption = (val, option) => {
		setFormData(val);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageMyCompany"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<FontAwesomeIcon icon={faBuilding} />}
			/>
			{/* <ComponentFaqs
        linkVid={"https://player.vimeo.com/video/644214039?autoplay=0&muted=1"}
      /> */}
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br></br>
				<Button
					icon={<ArrowLeftOutlined />}
					className="btn-login-outline"
					onClick={() => backButton()}
				>
					Back
				</Button>
				<br></br>
				<br></br>
				<Form
					wrapperCol={{ span: 24, marginTop: 15 }}
					layout="horizontal"
					form={form}
					onFinish={onFinishMyAccount}
				>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={16} lg={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="My Company Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Form.Item
										name="business_name"
										rules={[validator.require]}
										hasFeedback
									>
										<FloatInput
											label="Business/Practice Name"
											placeholder="Business/Practice Name"
											onChange={handleInput}
										/>
									</Form.Item>
									<Form.Item
										name="title"
										rules={[validator.require]}
										hasFeedback
									>
										<FloatInput
											label="Title"
											placeholder="Title"
											onChange={handleInput}
										/>
									</Form.Item>
									<Form.Item name="primary">
										<Switch
											// defaultChecked
											checked={primary == 1 ? true : false}
											onChange={(e) => {
												setPrimary(e ? 1 : 0);
												setFormData(e);
											}}
										/>{" "}
										<span>
											Is this your Primary Dental Company of Employment?
										</span>
									</Form.Item>{" "}
									<Form.Item
										name="country"
										rules={[
											{
												required: true,
												message: "This field field is required.",
											},
										]}
										className="form-select-error"
										hasFeedback
									>
										<FloatSelect
											label="Country"
											placeholder="Country"
											options={country}
											onChange={handleCountry}
										/>
									</Form.Item>
									<Form.Item
										name="address_1"
										rules={[validator.require]}
										hasFeedback
									>
										<FloatInput
											label="Address 1"
											placeholder="Address 1"
											onChange={handleInput}
										/>
									</Form.Item>
									<Form.Item name="address_2" hasFeedback>
										<FloatInput
											label="Address 2"
											placeholder="Address 2"
											onChange={handleInput}
										/>
									</Form.Item>
									<Row>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="city"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="City"
													placeholder="City"
													onChange={handleInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="state"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label={stateLabel}
													placeholder={stateLabel}
													options={optionState}
													onChange={handleSelect}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="zip"
												rules={[
													validator.require,
													{
														pattern: optionZip,
														message: "Invalid " + zipLabel,
													},
												]}
												hasFeedback
											>
												<FloatInput
													label={zipLabel}
													placeholder={zipLabel}
													onChange={handleSelect}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Form.Item
										name="business_phone"
										rules={[validator.require]}
										hasFeedback
									>
										{/* <FloatInput
                      label="Business Phone1"
                      placeholder="Business Phone1"
                      onChange={handleInput}
                    /> */}
										<FloatInputMask
											label="Business Phone 1"
											placeholder="Business Phone 1"
											maskLabel="business_phone"
											maskType="999-999-9999"
											onChange={handleInput}
										/>
									</Form.Item>
									<Form.Item name="business_phone2" hasFeedback>
										{/* <FloatInput
                      label="Business Phone 2"
                      placeholder="Business Phone 2"
                      onChange={handleInput}
                    /> */}
										<FloatInputMask
											label="Business Phone 2"
											placeholder="Business Phone 2"
											maskLabel="business_phone2"
											maskType="999-999-9999"
											onChange={handleInput}
										/>
									</Form.Item>
									<Form.Item name="business_fax" hasFeedback>
										{/* <FloatInput
                      label="Business Fax"
                      placeholder="Business Fax"
                      onChange={handleInput}
                    /> */}
										<FloatInput
											label="Business Fax"
											placeholder="Business Fax"
											onChange={handleInput}
										/>
									</Form.Item>
									<Form.Item
										name="business_email"
										rules={[
											{
												type: "email",
												message: "please enter a valid email",
											},
										]}
										hasFeedback
									>
										<FloatInput
											label="Business Email"
											placeholder="Business Email"
											onChange={handleInput}
										/>
									</Form.Item>
									<Form.Item
										name="business_categories"
										rules={[validator.require]}
										hasFeedback
										className="form-select-error-multi"
									>
										<FloatSelect
											label="Business Category"
											placeholder="Business Category"
											options={businessCategory}
											multi="multiple"
											onChange={onChangeOption}
										/>
									</Form.Item>
									<Form.Item name="website" hasFeedback>
										<FloatInput
											label="Website"
											placeholder="Website"
											onChange={handleInput}
										/>
									</Form.Item>
									{/* <Form.Item
                    name="map_link"
                    rules={[validator.require]}
                    hasFeedback
                  >
                    <FloatInput label="Map Link" placeholder="Map Link" />
                  </Form.Item> */}
								</Panel>
							</Collapse>
						</Col>
					</Row>
					<br></br>

					{/* <Button
            size="large"
            className="btn-login-outline"
            style={{ width: 200 }}
            icon={<SaveOutlined />}
            loading={isLoadingCreateAccount}
            htmlType="submit"
          >
            Save
          </Button> */}
				</Form>
			</Layout.Content>
		</Layout>
	);
}
