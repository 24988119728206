import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Form,
  Button,
  Row,
  Col,
  Modal,
  DatePicker,
  Alert,
  notification,
  Input,
} from "antd";

import $ from "jquery";

import { POST } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatInputZipCode from "../../../../providers/FloatInputZipCode";

// import MaskedInput from 'antd-mask-input'
import ReCAPTCHA from "react-google-recaptcha";

import statesCanada from "provinces-ca";
import statesUnitedState from "states-us";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";

// import { getCountries } from "node-countries";
const ComponentStepFour = ({
  setShowStepFour,
  setDataStepFour,
  setCollapse,
  states,
  accountType,
  dataStepTwo,
  dataStepThree,
  dataStepFour,
  selectedPlan,
  policy,
}) => {
  const [country, setCountry] = useState(optionCountryCodes);
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const stateMX = optionStateCodesMexico();

  const [stateLabel, setStateLabel] = useState("State");
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionState, setOptionState] = useState(stateUS);
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    } else if (val === "Mexico") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateMX);
      setOptionZip(/(^\d{5}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    }
    form.resetFields(["state", "zip"]);
  };

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  let history = useHistory();

  const [state, setState] = useState([]);

  useEffect(() => {
    let arr = [];
    states.map((row, key) => {
      if (
        row.name != "Federated States Of Micronesia" &&
        row.name != "Marshall Islands" &&
        row.name != "Palau" &&
        row.name != "Northern Mariana Islands"
      ) {
        arr.push({
          value: row.abbreviation,
          label: row.name,
        });
      }
    });
    setState(arr);
  }, []);

  const { mutate: mutateRegister, isLoading: isLoadingRegister } = POST(
    "api/v1/register",
    "register"
  );
  const [expError, setExpError] = useState(false);
  const [cardError, setCardError] = useState(false);
  const [cvvError, setCvvError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaTokenError, setCaptchaTokenError] = useState(false);
  const onFinishStepThree = (values) => {
    setDataStepFour(values);
    setShowStepFour(true);
    // setCollapse(["4"]);
    // // console.log('accountType', accountType)
    // // console.log('dataStepTwo', dataStepTwo)
    // // console.log('dataStepThree', dataStepThree)
    // // console.log('dataStepFour', values)
    let user_data = {
      ...dataStepTwo,
      credentials: dataStepTwo.credentials ? dataStepTwo.credentials : "",
    };
    let data = {
      account_type: accountType,
      user_detials: user_data,
      user_plan: dataStepThree,
      credit_dat: {
        card_name: values.card_name,
        card_number: values.card_number ? values.card_number : "01",
        expiration_month: values.expiration ? values.expiration : "01/01",
        expiration_year: values.expiration_year,
        card_security_code: values.card_security_code,
      },
      billing_address: {
        address: values.address,
        address_extra: values.address_extra,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: values.country,
      },
    };

    console.log("Registration", data);

    if (!captchaToken) {
      setCaptchaTokenError(true);
    } else {
      mutateRegister(data, {
        onSuccess: (res) => {
          if (res.success) {
            let login = {
              email: data.user_detials.email,
              password: data.user_detials.password,
            };
            console.log("reg", res);
            mutateLogin(login, {
              onSuccess: (res) => {
                if (res.token) {
                  // console.log("register", res);

                  localStorage.setItem("one_time_modal", 1);
                  localStorage.setItem("token", res.token);
                  localStorage.setItem("userdata", JSON.stringify(res.data));
                  if (res.data.role === "EVENT PROVIDER") {
                    window.location.href = "/event-provider/dashboard";
                  } else if (res.data.role === "VENUE PROVIDER") {
                    window.location.href = "/venue-provider/dashboard";
                  } else if (res.data.role === "MEMBER") {
                    window.location.href = "/member/dashboard";
                  } else if (res.data.role === "SPEAKER") {
                    window.location.href = "/speaker/dashboard";
                  } else if (res.data.role === "EVENT PROVIDER/SPEAKER") {
                    window.location.href = "/event-provider-speaker/dashboard";
                  }
                } else {
                  notification.warning({
                    message: res.message,
                    description: res.description,
                  });
                }
              },
            });
          } else {
            notification.error({
              message: "Error",
              description: res.message,
            });
          }
        },
        onError: (err) => {
          console.log(err.response.data);
          if (
            err.response.data.message ==
            "Your card's expiration year is invalid."
          ) {
            setExpError(true);
            setCardError(false);
          }
          if (
            err.response.data.message == "Your card number is incorrect." ||
            err.response.data.message ==
              "The card number is not a valid credit card number."
          ) {
            setCardError(true);
            setExpError(false);
          }
          notification.error({
            message: "Error",
            description: err.response.data.message,
          });
        },
      });
    }
  };

  const { mutate: mutateLogin, isLoading: isLoadingButtonLogin } = POST(
    "api/v1/login",
    "login"
  );

  const [accept, setAccept] = useState(false);
  const onChange = (e) => {
    setAccept(e.target.checked);
  };

  const [scroll, setScroll] = useState(false);
  const handleScroll = (e) => {
    // console.log("values");
    let element = e.target;
    console.log("element.scrollHeight", element.scrollHeight);
    console.log("element.scrollTop", element.scrollTop);
    console.log("element.clientHeight", element.clientHeight);

    if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const [yesDisabled, setYesDisabled] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    // if (scroll === true && accept === true) {
    if (scroll === true) {
      setYesDisabled(false);
    } else {
      setYesDisabled(true);
    }
  }, [scroll]);

  useEffect(() => {
    // if (scroll === true && accept === true) {
    if (accept === true) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [scroll, accept]);

  const [couponError, setCouponError] = useState(false);
  const handleApplyCoupon = (e) => {
    let coupon = $("#form-member_coupon_card").val();
    setCouponError(true);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
    history.push("/");
  };
  return (
    <Form
      name="form-member"
      layout="vertical"
      className="login-form"
      // style={{
      //     marginTop: '-50px'
      // }}
      onFinish={onFinishStepThree}
      form={form}
      autoComplete="off"
    >
      <Modal
        visible={isModalVisible}
        className="card-secondary card-white-head"
        closable={false}
        footer={[
          <Button
            size="large"
            className="btn-success-outline"
            onClick={handleOk}
          >
            Back to Login
          </Button>,
        ]}
      >
        <Alert
          message="Registration Success"
          description="Congratulations, your account has been successfully created"
          type="success"
          showIcon
          // closable
        />
      </Modal>

      <span>{selectedPlan}</span>
      <br />

      <h1 style={{ fontWeight: "normal " }}>Credit Card Information</h1>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name="card_name"
            rules={[
              {
                required: true,
                message: "This field field is required.",
              },
            ]}
            hasFeedback
          >
            {/* <Input size="large" placeholder="Name on Card"/> */}
            <FloatInput label="Name on Card" placeholder="Name on Card" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="card_number"
            hasFeedback
            validateStatus={cardError ? "error" : "success"}
            help={cardError ? "Your card number is incorrect" : ""}
          >
            {/* <Input placeholder="Card Number" size="large" /> */}
            <FloatInputMask
              label="Card Number"
              placeholder="Card Number"
              maskLabel="card_number"
              maskType="9999-9999-9999-9999"
              validateStatus={cardError}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item
            name="expiration"
            validateStatus={expError ? "error" : "success"}
            help={expError ? "Your card's expiration year is invalid." : ""}
            hasFeedback
          >
            <FloatInputMask
              label="Exp."
              placeholder="Exp."
              maskLabel="expiration"
              maskType="99/99"
              validateStatus={expError}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item
            name="card_security_code"
            rules={[
              {
                required: true,
                message: "Required.",
              },
            ]}
            hasFeedback
          >
            <FloatInputMask
              label="CVV"
              placeholder="CVV"
              maskLabel="card_security_code"
              maskType="999"
            />
          </Form.Item>
        </Col>
      </Row>

      <br />
      <h1 style={{ marginTop: "-25px", fontWeight: "normal " }}>
        Billing Address
      </h1>

      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "This field field is required.",
            },
          ]}
          className="form-select-error"
          hasFeedback
        >
          <FloatSelect
            label="Country"
            placeholder="Country"
            options={country}
            onChange={handleCountry}
          />
        </Form.Item>
      </Col>
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            message: "This field field is required.",
          },
        ]}
        hasFeedback
      >
        <FloatInput label="Street Address" placeholder="Street Address" />
      </Form.Item>
      <Form.Item name="address_extra" hasFeedback>
        <FloatInput label="Street Address 2" placeholder="Street Address 2" />
      </Form.Item>
      <Row gutter={24} style={{ marginBottom: "-27px" }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item
            name="city"
            rules={[
              {
                required: true,
                message: "Required.",
              },
            ]}
            hasFeedback
          >
            <FloatInput placeholder="City" label="City" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item
            name="state"
            className="form-select-error"
            rules={[
              {
                required: true,
                message: "Required.",
              },
            ]}
            hasFeedback
          >
            <FloatSelect
              label={stateLabel}
              placeholder={stateLabel}
              options={optionState}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Form.Item
            name="zip"
            rules={[
              {
                required: true,
                message: "Required.",
              },
              {
                pattern: optionZip,
                message: "Invalid Zip Code",
              },
            ]}
            hasFeedback
          >
            <FloatInput label={zipLabel} placeholder={zipLabel} />
          </Form.Item>
          {/* <FloatInput placeholder="Zip Code" label="Zip Code" /> */}
        </Col>
      </Row>
      <br />

      <h1 style={{ fontWeight: "normal " }}>
        Privacy Policy and Terms & Conditions
      </h1>
      <div style={{ marginTop: -9 }} className="c-danger">
        <b>Please read / scroll to the end to continue.</b>
      </div>
      <div
        onScroll={handleScroll}
        className="policyDiv"
        style={{
          marginBottom: 10,
          marginTop: 10,
          height: 170,
          resize: "vertical",

          overflow: "auto",
          border: "1px solid #d9d9d9",
        }}
        dangerouslySetInnerHTML={{ __html: policy }}
      ></div>

      <Checkbox
        onChange={onChange}
        name="checkbox_2"
        className="optiona"
        id="dd"
        disabled={yesDisabled}
      >
        Yes, I have read the Privacy Policy and Terms and Conditions
      </Checkbox>
      <br></br>
      <ReCAPTCHA
        style={{ marginTop: 10 }}
        onChange={(token) => setCaptchaToken(token)}
        className="captcha-registration"
        // theme="dark"
        render="explicit"
        // render="explicit"
        sitekey="6LfNfXgfAAAAAAbDCNuzI35Cm0hW_YwJ4UC0TYgw"
        // onloadCallback={() => {}}
      />
      {captchaTokenError && (
        <span
          style={{
            color: "#dc3545",
          }}
        >
          Please Verify Captcha
        </span>
      )}
      {/* <input type="checkbox" name="optiona" id="dd" /> 
            Yes, I have read the Privacy Policy and Terms and Conditions */}

      <Button
        type="primary"
        htmlType="submit"
        className="btn-loginNew-outline complete-btn"
        disabled={btnDisabled}
        style={{
          width: "100%",
          marginTop: 10,
          fontSize: "20px",
          height: "45px",
        }}
        loading={isLoadingRegister}
      >
        COMPLETE PURCHASE
      </Button>
    </Form>
  );
};

export default ComponentStepFour;
