import React, { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Typography,
  Input,
  Row,
  Col,
  Button,
  Steps,
  Form,
  Collapse,
  Alert,
  notification,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { HomeOutlined } from "@ant-design/icons";

import getUserData from "../../../../providers/getUserData";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import companyInfo from "../../../../providers/companyInfo";
import { useHistory } from "react-router-dom";
import moment from "moment";
import countryList from "react-select-country-list";
import termsAndCondition from "../../../../providers/termsAndCondition";

export default function PageWaitingList({ match, permission }) {
  const userdata = getUserData();
  const sub_title = "Book Upcoming";
  const [form] = Form.useForm();
  const validator = {
    require: {
      required: true,
      message: "Required",
    },
    require_false: {
      required: false,
      message: "Required",
    },
    email: {
      type: "email",
      message: "please enter a valid email",
    },
  };
  const [eventData, setEventData] = useState([]);
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState();
  const { data: datGetById, isLoading: isLoadingGetById } = GET(
    `api/v1/event/${match.params.id}`,
    "event",
    (res) => {
      if (res.success) {
        setEventData({
          event_type: res.data[0].event_type,
          cost: res.data[0].cost,
          event_time: res.data[0].event_schedules,
          rscp_datetime: res.data[0].rscp_datetime,
          time_zone: res.data[0].time_zone,
          venue_name: res.data[0].event_venue.venue_name,
          country: res.data[0].event_venue.country,
          address_1: res.data[0].event_venue.address_1,
          address_2: res.data[0].event_venue.address_2,
          city: res.data[0].event_venue.city,
          state: res.data[0].event_venue.state,
          zip: res.data[0].event_venue.zip,
          map_link: res.data[0].event_venue.map_link,
          direction_details: res.data[0].event_venue.direction_details,

          title: res.data[0].event_details.title,
          sub_title: res.data[0].event_details.sub_title,
          director: res.data[0].event_details.director,
          facilitator: res.data[0].event_details.facilitator,
          summary: res.data[0].event_details.summary,
          description: res.data[0].event_details.descrition,
          restriction: res.data[0].event_details.restriction,
          what_to_bring: res.data[0].event_details.what_to_bring,
          ce_credit_hours: res.data[0].event_details.ce_credit_hours,
          seat_available: res.data[0].event_details.seat_available,
          completion_code: res.data[0].event_details.completion_code,
          subject_code: res.data[0].event_details.subject_code,
          course_topic: res.data[0].event_details.course_topic,
          attending_participants: JSON.parse(
            res.data[0].event_details.attending_participants
          ),
          your_phone: res.data[0].event_details.your_phone,
          your_email: res.data[0].event_details.your_email,
          verification: JSON.parse(res.data[0].event_details.verification),
          published_status: res.data[0].event_save_status,

          speaker_name: res.data[0].event_speaker.speaker_name,
          speaker_credentials: res.data[0].event_speaker.speaker_credentials,
          event_img:
            res.data[0].event_details &&
            companyInfo().apiUrl +
              "storage/" +
              res.data[0].event_details.upload,
        });

        form.setFieldsValue({
          last_name: userdata.last_name,
          first_name: userdata.first_name,
          email: userdata.email,
          phone_number: userdata.phone_number,
        });

        console.log("@userdata", userdata);
      }
    }
  );

  const { mutate: mutateAddBooking, isLoading: isLoadingBooking } = POST(
    "api/v1/member_waiting_list",
    "mutate_waiting_list"
  );

  const onFinishMyAccount = (val) => {
    console.log(val);
    let _data = {
      ...formData,
      ...val,
      user_id: userdata.id,
      event_id: match.params.id,
    };

    mutateAddBooking(_data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("handleFinish", res);
          notification.success({
            message: "Success",
            description: "Successfully Submitted",
          });

          window.location.href = "/member/dashboard";
        } else {
          notification.error({
            message: "Error",
            description: "You Already on Waiting List",
          });
        }
      },
      onError: (err) => {},
    });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageWaitingList"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<HomeOutlined />}
      />
      <Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
        <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          form={form}
          onFinish={onFinishMyAccount}
        >
          <Card className="ant-card-bordered-blue no-side-border">
            <br></br>
            {current != 3 ? (
              <Card style={{ background: "#f0f0f0" }}>
                <Row>
                  <Col md={24}>
                    <div style={{ textAlign: "center", fontSize: 20 }}>
                      <div className="c-lightorange" style={{ fontSize: 22 }}>
                        Waiting List
                      </div>
                      <div style={{ fontSize: 14 }}>
                        This Event is sold out. You will be added to the waiting
                        list and notified if space becomes available
                      </div>
                      <br></br>
                      <div>{eventData.title}</div>

                      {eventData.event_time && eventData.event_time.length && (
                        <>
                          {" "}
                          <div>
                            {eventData.event_time.length == 0 && (
                              <>
                                {" "}
                                {moment(
                                  eventData.event_time[0].datetime_start
                                ).format("ll h:mm a")}{" "}
                                -{" "}
                                {moment(
                                  eventData.event_time[0].datetime_end
                                ).format("ll h:mm a")}{" "}
                              </>
                            )}
                          </div>
                          <div>
                            {eventData.event_time.length != 0 && (
                              <>
                                {" "}
                                {moment(
                                  eventData.event_time[0].datetime_start
                                ).format("ll h:mm a")}{" "}
                                -{" "}
                                {moment(
                                  eventData.event_time[
                                    eventData.event_time.length - 1
                                  ].datetime_end
                                ).format("ll h:mm a")}{" "}
                              </>
                            )}
                          </div>
                        </>
                      )}
                      <div>{eventData.time_zone}</div>
                      <div>
                        {" "}
                        <span>${eventData.cost}</span> <span>|</span>
                        <span> {eventData.ce_credit_hours} Credit Hours</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Alert
                  message={
                    <Typography.Title level={5}>
                      Thank you for registering through CE.LIYA. The details
                      below be emailed to you and saved on your upcoming events
                      page
                    </Typography.Title>
                  }
                  type="success"
                ></Alert>
              </div>
            )}

            <br></br>

            <Row gutter={24}>
              <Col md={12}>
                <Form.Item
                  name="first_name"
                  rules={[validator.require]}
                  hasFeedback
                >
                  <FloatInput label="First Name" placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  name="last_name"
                  rules={[validator.require]}
                  hasFeedback
                >
                  <FloatInput label="Last Name" placeholder="Last Name" />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item name="email" rules={[validator.require]} hasFeedback>
                  <FloatInput label="Email" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  name="phone_number"
                  rules={[validator.require]}
                  hasFeedback
                >
                  <FloatInput label="Phone Number" placeholder="Phone Number" />
                </Form.Item>
              </Col>
            </Row>

            <br></br>

            <div className="steps-action" style={{ textAlign: "right" }}>
              <Button
                htmlType="submit"
                size="large"
                className="btn-login "
                loading={isLoadingBooking}
              >
                Submit
              </Button>
            </div>
          </Card>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
