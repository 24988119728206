import React, { useState, useEffect } from "react";
import $ from "jquery";
import {
	Layout,
	Card,
	Form,
	Input,
	Button,
	Row,
	Col,
	Image,
	Divider,
	notification,
} from "antd";
import moment from "moment";

import { Link, useHistory } from "react-router-dom";
import imageLogo from "../../../assets/img/logo.png";
// import { PasswordInput } from "antd-password-input-strength";
import FloatInputPasswordStrength from "../../../providers/FloatInputPasswordStrength";
import { POST } from "../../../providers/useAxiosQuery";
import companyInfo from "../../../providers/companyInfo";
import axios from "axios";
export default function PageForgotPassword({ match }) {
	let history = useHistory();
	let user_id = match.params.id;
	let token = match.params.token;
	const [form] = Form.useForm();
	const [formPassword] = Form.useForm();
	let apiUrl = companyInfo().apiUrl + "api/v1/";
	let url = `newpassword/auth`;

	useEffect(() => {
		axios
			.post(
				`${apiUrl}${url}`,
				{},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			)
			.then((res) => {
				console.log("success");
			})
			.catch((err) => {
				if (err.response.status === 401) {
					// history.push("/error-500");
				}
			});
	}, []);

	const onFinish = (values) => {
		let data = {
			...values,
			id: user_id,
		};
		// console.log("data", data);
		mutateChangePass(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Password Successfully Updated",
					});
					form.resetFields();
					history.push("/");
				}
			},
			onError: (err) => {},
		});
	};

	const { mutate: mutateChangePass, isLoading: isLoadingChangePass } = POST(
		"api/v1/change_password",
		"change_password"
	);

	const handleBack = () => {
		history.push("/");
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		password: {
			min: 8,
			message: "Password must be minimum 8 characters.",
		},
	};
	return (
		<Layout.Content
			className="login-layout login-back"
			// style={{
			//   paddingBottom: "18vh",
			//   background: "linear-gradient(180deg, white 0%, #e2c991 80%)",
			//   height: "100%",
			// }}
		>
			<Row>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
				<Col
					xs={24}
					sm={16}
					md={16}
					lg={16}
					xl={10}
					xxl={8}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<div style={{ padding: 30 }}>
						<Image
							src={imageLogo}
							onClick={handleBack}
							preview={false}
							style={{ width: "100%" }}
						/>
					</div>
				</Col>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
			</Row>

			<Row className="confirmemail forgot-password-row">
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
				<Col xs={24} sm={16} md={16} lg={16} xl={10} xxl={8}>
					<Card
						style={{
							// background: "transparent",
							border: "0px solid",
							textAlign: "center",
							height: "auto",
							borderRadius: "10px",
						}}
						headStyle={{
							borderBottom: "none",
							background: "transparent!important",
						}}
						// title={}
						className="login"
					>
						<Row className="flexdirection">
							<Col xs={24} md={24}>
								<Form
									name="basic"
									layout="vertical"
									className="login-form"
									onFinish={onFinish}
									form={form}
									autoComplete="off"
								>
									<span style={{ fontSize: "20px" }}>Password Reset</span>
									<br />
									<span>
										Your password must be at least 8 characters and contain at
										least one number, one uppercase letter and one special
										character.
									</span>
									<br />
									<br />

									<Form.Item
										name="password"
										rules={[
											validator.require,
											validator.password,
											{
												pattern:
													/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
												message: "Invalid Password",
											},
										]}
										hasFeedback
									>
										<FloatInputPasswordStrength
											placeholder="Password"
											label="Password"
										/>
									</Form.Item>

									<Form.Item
										name="confirm"
										dependencies={["password"]}
										hasFeedback
										rules={[
											{
												required: true,
												message: "Please confirm your password!",
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue("password") === value) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															"The two passwords that you entered do not match!"
														)
													);
												},
											}),
										]}
									>
										<FloatInputPasswordStrength
											placeholder="Confirm Password"
											label="Confirm Password"
										/>
									</Form.Item>

									<Button
										type="primary"
										htmlType="submit"
										loading={isLoadingChangePass}
										className="btn-loginNew-outline"
										style={{ width: "100%", fontSize: "20px", height: "45px" }}
									>
										SUBMIT
									</Button>
								</Form>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
			</Row>
			<div style={{ width: "100%", textAlign: "center" }}>
				<br />
				<br />
				<span style={{ color: "white" }}>
					© {moment().format("YYYY")} CE.LIYA. All Rights Reserved..
				</span>
				<br />
				<br />
				<br />
				<br />
			</div>
		</Layout.Content>
	);
}
