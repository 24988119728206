import React, { useState, useEffect, Component } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	Modal,
	Form,
	notification,
	Divider,
	Popconfirm,
} from "antd";
// import ComponentFaqs from "../Components/ComponentFaqs";
import {
	PlusOutlined,
	EditOutlined,
	DeleteOutlined,
	DragOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import FloatInputSearch from "../../../../../providers/FloatInputSearch";
import { GET, POST, DELETE } from "../../../../../providers/useAxiosQuery";
// import { useHistory } from "react-router-dom";
import FloatInput from "../../../../../providers/FloatInput";
import FloatTextArea from "../../../../../providers/FloatTextArea";
import FloatInputRate from "../../../../../providers/FloatInputRate";
import FloatSelect from "../../../../../providers/FloatSelect";
// import dragula from "dragula";
// import "dragula/dist/dragula.css";

// import { useDrag } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import update from "immutability-helper";

import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
// import { log } from "@antv/g2plot/lib/utils";
import toCurrency from "../../../../../providers/toCurrency";
const DragHandle = SortableHandle(() => (
	<MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export default function TableAccountTypesPlan({ id }) {
	// const sub_title = "View";
	// const history = useHistory();
	const [form] = Form.useForm();
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "index",
		order: "asc",
		account_type_id: id,
	});
	// const [data, setData] = useState([]);
	const [data, setData] = useState([]);
	const {
		data: dataGetProfiles,
		isLoading: isLoadingGetProfiles,
		refetch: refetchGetProfiles,
	} = GET(
		`api/v1/account_type_plan?${$.param(dataTableInfo)}`,
		"account_type_plan",
		(res) => {
			if (res.success) {
				// setData(dataGetProfiles ? dataGetProfiles.data.data : []);
				console.log("account_type_plan", res);
				let arr = [];
				res.data.data.map((row, key) => {
					arr.push({
						key: row.id,
						index: key,
						account_type_id: row.account_type_id,
						amount: row.amount,
						description: row.description,
						id: row.id,
						plan: row.plan,
						type: row.type,
						up_to: row.up_to,
						updated_at: row.updated_at,
						stripe_price_id: row.stripe_price_id,
						has_user_plan: row.has_user_plan,
					});
				});
				setData(arr);
			}
		}
	);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				account_type_id: id,
			});
		});
		return () => {
			clearTimeout(timeoutId);
		};
	}, [id]);

	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchGetProfiles();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const handleEdit = (record) => {
		// console.log("handleEdit", record);
		form.setFieldsValue({
			id: record.id,
			plan: record.plan,
			description: record.description,
			amount: record.amount,
			up_to: record.up_to,
			type: record.type,
			stripe_price_id: record.stripe_price_id,
		});
		setModal(true);
	};

	const handleDelete = (record) => {
		let data = {
			id: record.id,
		};

		mutateDelete(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Succesfully Deleted",
					});
				}
			},
		});
	};

	const { mutate: mutateDelete, isLoading: isLoadingDelete } = DELETE(
		"api/v1/account_type_plan",
		"account_type_plan"
	);

	const [modal, setModal] = useState(false);
	const onFinish = (value) => {
		let data = {
			...value,
			account_type_id: id,
		};
		console.log("onFinish", data);
		mutateAccountType(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Succesfully Submitted",
					});
					form.resetFields();
					setModal(false);
				}
			},
		});
	};

	const { mutate: mutateAccountType, isLoading: isLoadingAccountType } = POST(
		"api/v1/account_type_plan",
		"account_type_plan"
	);

	const onSortEnd = ({ oldIndex, newIndex }) => {
		// const { dataSource } = data;
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(
				[].concat(data),
				oldIndex,
				newIndex
			).filter((el) => !!el);
			console.log("Sorted items: ", newData);
			let data_sort = { sorted_data: JSON.stringify(newData) };
			mutateSort(data_sort, {
				onSuccess: (res) => {
					if (res.success) {
						console.log(res);
						notification.success({
							message: "Success",
							description: "Succesfully Save",
						});
					}
				},
			});
			setData(newData);
		}
	};

	const { mutate: mutateSort, isLoading: isLoadingSort } = POST(
		"api/v1/plan_sort",
		"plan_sort"
	);

	const DraggableContainer = (props) => (
		<SortableBody
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		// const { dataSource } = data;
		// console.log("dataSource", dataSource);
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
		return <SortableItem index={index} {...restProps} />;
	};

	const columns = [
		{
			title: "Sort",
			dataIndex: "sort",
			width: 30,
			className: "drag-visible",
			render: () => <DragHandle />,
		},
		{
			title: "Plan",
			dataIndex: "plan",
			key: "plan",
			className: "drag-visible",
			sorter: true,
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			className: "drag-visible",
			sorter: true,
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			className: "drag-visible",
			sorter: true,
			render: (text, record) => {
				return <>${toCurrency(text)}</>;
			},
		},

		{
			title: "Up To",
			dataIndex: "up_to",
			key: "up_to",
			className: "drag-visible",
			sorter: true,
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			className: "drag-visible",
			sorter: true,
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			className: "drag-visible",
			render: (text, record) => {
				console.log(record);
				return (
					<Space>
						<Button
							size="small"
							className="btn-warning-outline"
							icon={<EditOutlined />}
							onClick={(e) => handleEdit(record)}
						>
							Edit
						</Button>
						<Popconfirm
							title="Are you sure to delete this Plan?"
							onConfirm={(e) => handleDelete(record)}
							// onCancel={cancel}
							okText="Yes"
							cancelText="No"
							disabled={record?.has_user_plan?.length > 0 ? true : false}
						>
							<Button
								size="small"
								className={
									record?.has_user_plan?.length > 0
										? "btn-danger-disabled"
										: "btn-danger-outline"
								}
								icon={<DeleteOutlined />}
								// onClick={(e) => handleDelete(record)}
							>
								Delete
							</Button>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Layout.Content style={{ paddingTop: "0px" }}>
			<Button
				className="btn-login-outline"
				icon={<PlusOutlined />}
				onClick={(e) => {
					setModal(true);
				}}
				id="TableAccountTypesPlan"
			>
				Add Plan
			</Button>
			<br />
			<br />
			<Row gutter={24} className="search-table" align="bottom">
				<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
					<Pagination
						className="paginate"
						showSizeChanger
						onChange={onChange}
						current={currentPage}
						pageSize={currentSize}
						total={dataTableInfo.data && dataTableInfo.data.total}
						size="small"
					/>
				</Col>
				<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
					<FloatInputSearch
						label="Search..."
						placeholder="Search..."
						dataTableInfo={dataTableInfo}
						setDataTableInfo={setDataTableInfo}
						value={searchText}
						onChange={setSearchText}
					/>
				</Col>
			</Row>
			<br />
			<div className="table-responsive"></div>
			<Table
				className="table-responsive table-thead-login"
				size="small"
				// rowKey={(record) => record.id}
				rowKey="index"
				loading={isLoadingGetProfiles}
				dataSource={data}
				columns={columns}
				pagination={false}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow,
					},
				}}
			/>
			<Modal
				title="Plan Form"
				className="modal-login"
				visible={modal}
				width="700px"
				onCancel={(e) => {
					setModal(false);
					form.resetFields();
				}}
				footer={
					<Space>
						<Button
							onClick={(e) => {
								setModal(false);
								form.resetFields();
							}}
						>
							Cancel
						</Button>
						<Button
							className="btn-login-outline"
							onClick={(e) => form.submit()}
							loading={isLoadingAccountType}
						>
							Submit
						</Button>
					</Space>
				}
			>
				<Form layout="horizontal" form={form} onFinish={onFinish}>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Form.Item name="id" style={{ display: "none" }}>
								<FloatInput label="id" placeholder="id" />
							</Form.Item>
							<Form.Item name="plan" rules={[validator.require]} hasFeedback>
								<FloatInput label="Plan" placeholder="Plan" />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Form.Item name="amount" rules={[validator.require]} hasFeedback>
								<FloatInputRate label="Amount" placeholder="Amount" />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Form.Item name="description" rules={[validator.require]}>
								<FloatTextArea label="Description" placeholder="Description" />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Form.Item name="up_to" rules={[validator.require]} hasFeedback>
								<FloatInput label="Up To" placeholder="Up To" />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Form.Item
								name="type"
								rules={[validator.require]}
								hasFeedback
								className="form-select-error"
							>
								<FloatSelect
									label="Type"
									placeholder="Type"
									options={[
										{
											label: "Monthly",
											value: "Monthly",
										},
										{
											label: "Yearly",
											value: "Yearly",
										},
									]}
								/>
							</Form.Item>
						</Col>
						{/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="stripe_price_id"
                rules={[validator.require]}
                hasFeedback
              >
                <FloatInput
                  label="Stripe Price Id"
                  placeholder="Stripe Price Id"
                />
              </Form.Item>
            </Col> */}
					</Row>
				</Form>
			</Modal>
		</Layout.Content>
	);
}
