import React, { useState } from "react";
import { Button, Modal, Space } from "antd";
import { useHistory } from "react-router-dom";
import { GET } from "./useAxiosQuery";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const CheckSubscription = ({ user_id }) => {
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const location = history.location.pathname.split("/");
  const onCancel = () => {
    setModal(false);
  };

  const {} = GET(
    `api/v1/check_user_plan?user_id=${user_id}`,
    "check_user_plan",
    (res) => {
      console.log("check_user_plan", res);
      console.log("user_id", user_id);
      if (res.success) {
        setModal(false);
      } else {
        setModal(true);
      }
    }
  );

  return (
    <Modal
      // className="modal-warning"
      // title="Warning"
      visible={modal}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      footer={[
        <Space>
          <Button onClick={(e) => history.goBack()}>Go Back</Button>

          <Button
            className="btn-warning-outline"
            onClick={(e) =>
              history.push("/" + location[1] + "/my-account/invoices")
            }
          >
            Proceed
          </Button>
        </Space>,
      ]}
    >
      <div style={{ display: "contents" }}>
        <span>
          <ExclamationCircleOutlined
            style={{ color: "#ae8c58", fontSize: 20 }}
          />{" "}
        </span>
        <span style={{ fontSize: 17, fontWeight: "bold" }}>
          Please Select a Plan to Unlock Features
        </span>
      </div>
    </Modal>
  );
};

export default CheckSubscription;
