import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Content from "../layouts/private/Content";
// import PrivateMerchantLayout from "../layouts/private/merchant/Content";
// import PrivateMerchantTicketsOnlyLayout from "../layouts/private/merchantTicketsOnly/Content";
// import PrivateGiftOnlyLayout from "../layouts/private/gift/Content";
// import getUserData from "../providers/getUserData";
// const userdata = getUserData();
import getUserData from "../providers/getUserData";

const PrivateRouteEventProvider = ({
  path: path,
  component: Component,
  permission: Permission,
  breadcrumbs: Breadcrumbs,
  ...rest
}) => {
  // console.log('permission', Permission)
  let isLoggedIn = localStorage.getItem("token");
  let userdata = getUserData();

  // useEffect(() => {
  //     console.log('isLoggedIn', isLoggedIn)
  // }, [])
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && userdata.role == "EVENT PROVIDER" ? (
          <Content>
            <Component
              permission={Permission}
              breadcrumbs={Breadcrumbs}
              {...props}
            />
          </Content>
        ) : (
          // <Redirect to={{ pathname: "/" }} />
          <Redirect to={{ pathname: "/error-500" }} />
        )
      }
    />
  );
};

export default PrivateRouteEventProvider;
