import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRouteMember from "./PrivateRouteMember";

// Member
import PageMemberDashboard from "../views/private/Member/PageMemberDashboard/PageMemberDashboard";
import PageMemberMyCompany from "../views/private/Member/PageMyAccount/PageMyCompany";
import PageMyCompanyView from "../views/private/Member/PageMyAccount/PageMyCompanyView";
import PageMemberProfile from "../views/private/Member/PageMyAccount/PageProfile";
import PageMemberInvoicesView from "../views/private/Member/PageMyAccount/PageInvoicesView";
import PageMemberInvoices from "../views/private/Member/PageMyAccount/PageInvoices";
import AllEvent from "../views/private/Member/PageEvents/PageAllEvents";
import PageMyBookEvents from "../views/private/Member/PageEvents/PageMyBookEvents";
import PageBookEvent from "../views/private/Member/PageEvents/PageBookEvent";
import PageMemberIndividualMembers from "../views/private/Member/PageEvents/PageIndividualEvent";
import PageSubscription from "../views/private/Member/PageMyAccount/PageSubscription";
import PageSubscriptionUpgrade from "../views/private/Member/PageMyAccount/PageSubscriptionUpgrade";

import PageMemberFaqs from "../views/private/Member/PageSupport/PageMemberFaqs";
import PageMemberTicketing from "../views/private/Member/PageSupport/PageMemberTicketing";
import PageMemberCreateTicket from "../views/private/Member/PageSupport/PageMemberCreateTicket";
import PageMemberTicketingReply from "../views/private/Member/PageSupport/PageMemberTicketingReply";

import PageCEHoursProfile from "../views/private/Member/PageCEHours/PageCEHoursProfile";
import PageCeHoursByYears from "../views/private/Member/PageCEHours/PageCeHoursByYears";
import PageWaitingList from "../views/private/Member/PageEvents/PageWaitingList";
import PageCEHoursProfileView from "../views/private/Member/PageCEHours/PageCEHoursProfileView";
import PageCertificates from "../views/private/Member/PageCEHours/PageCertificates";
import PageMessages from "../views/private/PageMessages/PageMessages";

import ComponentCookies from "../views/private/Components/ComponentCookies";
import ComponentPrivacy from "../views/private/Components/ComponentPrivacy";
import ComponentTermsProviders from "../views/private/Components/ComponentTermsProviders";

import Page2fa from "../views/private/Page2fa/Page2fa";
import PageSubscriptionDowngrade from "../views/private/Member/PageMyAccount/PageSubscriptionDowngrade";
import PageCreditCard from "../views/private/PageCreditCard/PageCreditCard";
import Error404 from "../views/public/Widgets/Error404";

const RoutePageMember = () => {
	return (
		<Switch>
			{/* member */}

			<PrivateRouteMember
				exact
				path="/terms-providers"
				component={ComponentTermsProviders}
				permission="Conditions"
				breadcrumbs={[
					{
						name: "Terms & Conditions",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/cookies"
				component={ComponentCookies}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Cookie Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/privacy"
				component={ComponentPrivacy}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Privacy Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/dashboard"
				component={PageMemberDashboard}
				permission="Dashboard"
				breadcrumbs={[
					{
						name: "Dashboard",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/my-company/:id"
				component={PageMemberMyCompany}
				permission="Company"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "My Company",
						link: "/member/my-account/my-company",
					},
					{
						name: "Edit Company",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/my-company"
				component={PageMyCompanyView}
				permission="Company"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "My Company",
						link: null,
					},
				]}
			/>

			<PrivateRouteMember
				exact
				path="/member/my-account/profile"
				component={PageMemberProfile}
				permission="Profile"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Account Profile",
						link: null,
					},
				]}
			/>

			<PrivateRouteMember
				exact
				path="/member/my-account/invoices"
				component={PageMemberInvoices}
				permission="Invoices"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/invoices/:id"
				component={PageMemberInvoicesView}
				permission="Paid"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/member/my-account/invoices",
					},
					{
						name: "Invoice",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/profile/subscription"
				component={PageSubscription}
				permission="Subscription"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Profile",
						link: "/member/my-account/profile",
					},
					{
						name: "Subscription",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/invoices/subscription/credit-card"
				component={PageCreditCard}
				permission="Credit Card"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/member/my-account/invoices",
					},
					{
						name: "Credit Card",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/invoices/subscription/upgrade"
				component={PageSubscriptionUpgrade}
				permission="Subscription Upgrade"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/member/my-account/invoices",
					},
					{
						name: "Upgrade",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/invoices/subscription/downgrade"
				component={PageSubscriptionDowngrade}
				permission="Subscription Downgrade"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Subscriptions & Invoices",
						link: "/member/my-account/invoices",
					},
					{
						name: "Downgrade",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/ce-calendar/all-events"
				component={AllEvent}
				permission="Events"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "All Events",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/ce-calendar/my-book-events"
				component={PageMyBookEvents}
				permission="My Calendar"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "My Booked Events",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/ce-calendar/my-book-events/:id"
				component={PageMemberIndividualMembers}
				permission="Event"
				breadcrumbs={[
					{
						name: "My CE Events Calendar",
						link: null,
					},
					{
						name: "My Booked Events",
						link: "/member/ce-calendar/my-book-events",
					},
					{
						name: "Individual Event",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/book/:id"
				component={PageBookEvent}
				permission="Seminar"
				breadcrumbs={[
					{
						name: " Book Upcoming Seminar",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/support/faqs"
				component={PageMemberFaqs}
				permission="Questions"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "FAQs",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/support/ticketing"
				component={PageMemberTicketing}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/support/ticketing/:id"
				component={PageMemberTicketingReply}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: "/member/support/ticketing",
					},
					{
						name: "Reply",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/support/create-ticket"
				component={PageMemberCreateTicket}
				permission="Ticket"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Create Ticket",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/ce-hours/profile"
				component={PageCEHoursProfile}
				permission="Profile"
				breadcrumbs={[
					{
						name: "My CE Hours",
						link: null,
					},
					{
						name: "CE Hours Profile",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/ce-hours/profile/:id"
				component={PageCEHoursProfileView}
				permission="Profile"
				breadcrumbs={[
					{
						name: "My CE Hours",
						link: null,
					},
					{
						name: "CE Hours Profile",
						link: "/member/ce-hours/profile",
					},
					{
						name: "Edit CE Hours Profile",
						link: null,
					},
				]}
			/>

			<PrivateRouteMember
				exact
				path="/member/ce-hours/by-years"
				component={PageCeHoursByYears}
				permission="CE Hours By Years"
				breadcrumbs={[
					{
						name: "My CE Hours",
						link: null,
					},
					{
						name: "My CE Hours by Years",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/ce-hours/my-certificates"
				component={PageCertificates}
				permission="My Certificates"
				breadcrumbs={[
					{
						name: "My CE Hours",
						link: null,
					},
					{
						name: "My Certificates",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/waiting/:id"
				component={PageWaitingList}
				permission="Seminar"
				breadcrumbs={[
					{
						name: "Waiting",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/messages"
				component={PageMessages}
				permission="Messages"
				breadcrumbs={[
					{
						name: "Messages",
						link: null,
					},
				]}
			/>
			<PrivateRouteMember
				exact
				path="/member/my-account/profile/2fa"
				component={Page2fa}
				permission="Authentication"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Profile",
						link: "/member/my-account/profile",
					},
					{
						name: "2-Factor Authentication ",
						link: null,
					},
				]}
			/>
			<Route path="*" exact={true} component={Error404} />
		</Switch>
	);
};

export default RoutePageMember;
