import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Space, Typography, Divider, Modal } from "antd";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { GET, POST, GETMANUAL } from "../../providers/useAxiosQuery";
import getUserData from "../../providers/getUserData";
import { getSelector } from "../../providers/getSelector";
import ModalTooltip from "./Modaltooltips";
export default function Footer() {
	let history = useHistory();
	let userdata = getUserData();
	const [showTooltipModal, setShowTooltipModal] = useState(false);

	const [selector, setSelector] = useState();
	const [selectorInsertat, setSelectorInsertat] = useState();
	const [selectorIsreq, setSelectorIsreq] = useState();
	const [formDataTooltip, setFormDataTooltip] = useState({
		tooltip_color: "",
		selector: "",
		description: "",
		position: "",
		inserted_at: "",
		video_url: "",
		tooltip_type: "text",
	});
	const setClickEvents = () => {
		$("body").on("click", function (e) {
			if (e.altKey) {
				e.preventDefault();

				var class_name = e.target.className;
				var isRequired = e.target.nextSibling
					? e.target.nextSibling.firstChild
						? e.target.nextSibling.firstChild
						: null
					: null;

				// class_name.includes("ant-select-selection-item") ||
				// class_name.includes("ant-select-selection-overflow") ||
				// class_name.includes("ant-select-selection-search-input")

				if (
					class_name.includes("ant-collapse-header") ||
					class_name.includes("ant-input") ||
					class_name.includes("mask-input-antd")
				) {
					let _selector = getSelector(e.target);

					console.log(_selector);
					// console.log("isR", isRequired);

					var inserted_at = "";
					var is_req = "tooltip-is-not-required";
					if (
						class_name.includes("ant-input") ||
						class_name.includes("mask-input-antd")
					) {
						inserted_at = "tooltip-input";

						if (isRequired) {
							is_req = "tooltip-is-required";
						} else {
							is_req = "tooltip-is-not-required";
						}
					}
					if (class_name.includes("ant-collapse-header")) {
						inserted_at = "tooltip-collapse";
					}
					// if (class_name.includes("ant-select-selection-item")) {
					//   inserted_at = "tooltip-select";
					// }
					// if (class_name.includes("ant-select-selection-overflow")) {
					//   inserted_at = "tooltip-selection";
					// }
					// if (class_name.includes("ant-select-selection-search-input")) {
					//   inserted_at = "tooltip-select";
					// }
					setSelectorIsreq(is_req);
					getTooltipBySelector(_selector);
					setSelector(_selector);
					setSelectorInsertat(inserted_at);
					setShowTooltipModal(true);
				}
			}
		});
	};

	useEffect(() => {
		var view_as_role = localStorage.userdata_admin
			? JSON.parse(localStorage.userdata_admin)
			: "";

		if (
			userdata.role == "SUPER ADMIN" ||
			userdata.role == "ADMIN" ||
			view_as_role.role == "ADMIN" ||
			view_as_role.role == "SUPER ADMIN"
		) {
			setClickEvents();
		}

		setTimeout(() => {
			getToolTips();
		}, 1500);

		return () => {};
	}, [window.location.href]);

	const { mutate: mutateGetSelector, isLoading: isLoadingGetSelector } = POST(
		"api/v1/tooltips/selector",
		"tooltip_selector"
	);

	const getTooltipBySelector = (selector) => {
		mutateGetSelector(
			{ selector: selector, role: userdata.role },
			{
				onSuccess: (res) => {
					if (res.success) {
						if (res.data.length != 0) {
							// console.log("getTooltip", res.data[0]);
							setFormDataTooltip(res.data[0]);
						} else {
							setFormDataTooltip({
								tooltip_color: "",
								selector: "",
								description: "",
								position: "",
								inserted_at: "",
								video_url: "",
								tooltip_type: "text",
							});
						}
					}
				},
				onError: (err) => {
					console.log(err);
				},
			}
		);
	};

	const {
		data: dataToolTips,
		isLoading: isLoadingToolTips,
		refetch: getToolTips,
		isFetching: isFetchingToolTips,
	} = GETMANUAL(`api/v1/tooltips`, `get_tooltiips`, (res) => {
		if (res.success) {
			let role = userdata.role;

			res.data.map((tooltip, key) => {
				if (tooltip.role == role) {
					let description = "";
					if (tooltip.tooltip_type == "video") {
						description =
							"<iframe style='width: 185px; margin-top: 4px; ' allowfullscreen frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen' src=" +
							tooltip.video_url +
							"></iframe>";
					}
					if (tooltip.tooltip_type == "text") {
						description = tooltip.description;
					}

					// console.log("wew", description);

					if (tooltip.insert_at == "tooltip-collapse") {
						$(tooltip.selector).append(
							`<div class="tooltip ` +
								tooltip.insert_at +
								`">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
                    <span class=" ` +
								tooltip.position +
								` tooltiptext ` +
								tooltip.tooltip_color +
								`"> ` +
								description +
								`</span>
                </span>
                </div>`
						);
					} else {
						$(tooltip.selector).after(
							`<div class="tooltip ` +
								tooltip.insert_at +
								` ` +
								tooltip.is_req +
								`">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
                    <span class=" ` +
								tooltip.position +
								` tooltiptext ` +
								tooltip.tooltip_color +
								`"> ` +
								description +
								`</span>
                </span>
                </div>`
						);
					}
				}
			});
		}
	});

	const { data: dataGetMaintenance } = GET(
		"api/v1/maintenance",
		"maintenance",
		(res) => {
			if (res.success == true) {
				var view_as_role = localStorage.userdata_admin
					? JSON.parse(localStorage.userdata_admin)
					: "";

				if (
					userdata.role == "SUPER ADMIN" ||
					userdata.role == "ADMIN" ||
					view_as_role.role == "ADMIN" ||
					view_as_role.role == "SUPER ADMIN"
				) {
				} else {
					if (res.data.system_maintenance == 1) {
						history.push("/maintenance");
					}
				}
			}
		}
	);

	const [modalNotif, setModalNotif] = useState(false);
	const [modalNotifData, setModalNotifData] = useState({
		title: "",
		description: "",
	});

	const { mutate: mutateGetNotif, isLoading: isLoadingGetNotif } = POST(
		"api/v1/get_notification_by_link",
		"get_notification_by_link"
	);

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		var id = urlParams.get("user_id_notification");
		var open = urlParams.get("open_modal_notification");

		if (open) {
			mutateGetNotif(
				{ id: id },
				{
					onSuccess: (res) => {
						if (res.success) {
							setModalNotifData({
								title: res.data.notification.title,
								description: res.data.notification.description,
							});
							setModalNotif(true);
						}
					},
				}
			);
		}
	}, []);

	return (
		<Layout.Footer style={{ background: "#fff" }}>
			<Space
				direction="horizontal"
				className="sh-footer"
				size={0}
				style={{ display: "flex!important", paddingLeft: "14px" }}
			>
				<Typography.Text style={{ color: "#6c6c6c" }}>
					© {new Date().getFullYear()} {process.env.REACT_APP_APP_NAME} All
					Rights Reserved.
				</Typography.Text>
				<Space
					split={<Divider type="vertical" />}
					style={{ color: "#6c6c6c" }}
					size={2}
					className="footerPrivacyPolicy"
				>
					<Link style={{ color: "#6c6c6c" }} to="/privacy">
						Privacy Policy
					</Link>
					<Link
						style={{ color: "#6c6c6c" }}
						to="/terms-providers"
						className="termsDiv"
					>
						Terms & Conditions
					</Link>
					<Link style={{ color: "#6c6c6c" }} to="/cookies">
						Cookie Policy
					</Link>
				</Space>
			</Space>
			{showTooltipModal && (
				<ModalTooltip
					showTooltipModal={showTooltipModal}
					setShowTooltipModal={setShowTooltipModal}
					selector={selector}
					formDataTooltip={formDataTooltip}
					setFormDataTooltip={setFormDataTooltip}
					selectorInsertat={selectorInsertat}
					selectorIsreq={selectorIsreq}
					// getToolTips={getToolTips}
				/>
			)}
			{modalNotif && (
				<Modal
					className="modal-login"
					title={modalNotifData && modalNotifData.title}
					visible={modalNotif}
					footer={false}
					onCancel={() => {
						setModalNotif(false);
					}}
				>
					<span>{modalNotifData && modalNotifData.description}</span>
				</Modal>
			)}
		</Layout.Footer>
	);
}
