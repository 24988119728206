import React, { useEffect, useState, useRef, memo } from "react";
import {
	Card,
	Layout,
	Typography,
	Input,
	DatePicker,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Button,
	Alert,
	Space,
	Collapse,
	Tag,
	Form,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	HomeOutlined,
	CalendarOutlined,
	EyeOutlined,
	UpOutlined,
	DownOutlined,
	FilterOutlined,
} from "@ant-design/icons";

import moment from "moment";

import image1 from "../../../../assets/img/image1.jpg";
import image2 from "../../../../assets/img/image2.jpg";
import image3 from "../../../../assets/img/image3.jpg";

import { Pie, Column } from "@ant-design/plots";

import getUserData from "../../../../providers/getUserData";
import { GET } from "../../../../providers/useAxiosQuery";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import companyInfo from "../../../../providers/companyInfo";
import { useHistory } from "react-router-dom";

import ModalPreview from "./Modals/ModalPreview";
import { Link } from "react-router-dom";
import $ from "jquery";
import states from "states-us";
import FloatSelectDashboard from "../../../../providers/FloatSelectDashboard";
import FloatSelectDashboard1 from "../../../../providers/FloatSelectDashboard1";
import FloatInputDasboard2 from "../../../../providers/FloatInputDasboard2";
import FloatInputDasboard from "../../../../providers/FloatInputDasboard";
import FloatInputDasboard3 from "../../../../providers/FloatInputDasboard3";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdRefresh } from "react-icons/md";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
import leftArrow from "../../../../assets/img/left-arrow.png";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatSelectv2 from "../../../../providers/FloatSelectv2";
import optionYear from "../../../../providers/optionYear";
import optionMonth from "../../../../providers/optionMonth";
import optionStateCodes from "../../../../providers/optionStateCodes";
const PageDashboardPieChart = () => {
	const userdata = getUserData();
	const sub_title = "All Features";
	const { Search } = Input;
	const { Meta } = Card;
	const history = useHistory();
	const { Panel } = Collapse;

	const [filterRevenueAll, setFilterRevenueAll] = useState({
		date_start: moment(1, "DD").format("YYYY-MM-DD"),
		date_end: moment().format("YYYY-MM-DD"),
		type: "ALL",
	});
	const { data: dataRevenueAll, refetch: refetchRevenueAll } = GET(
		`api/v1/revenue_all?${new URLSearchParams(filterRevenueAll)}`,
		"dashboard_revenue_all",
		(res) => {
			// console.log("res", res);
		}
	);

	const handleOnValuesChangeAll = (e) => {
		// console.log("handleOnValuesChange", e);
		if (e.date_start) {
			setFilterRevenueAll({
				...filterRevenueAll,
				date_start: moment(e.date_start).format("YYYY-MM-DD"),
			});
		}
		if (e.date_end) {
			setFilterRevenueAll({
				...filterRevenueAll,
				date_end: moment(e.date_end).format("YYYY-MM-DD"),
			});
		}
		if (e.type) {
			setFilterRevenueAll({
				...filterRevenueAll,
				type: e.type,
			});
		}
	};

	useEffect(() => {
		refetchRevenueAll();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRevenueAll]);

	const [filterRevenuePerMonth, setFilterRevenuePerMonth] = useState({
		year: moment().format("YYYY"),
		month: moment().format("MM"),
	});

	const { refetch: refetchRevenuePerMonth } = GET(
		`api/v1/revenue_per_month?${new URLSearchParams(filterRevenuePerMonth)}`,
		"dashboard_revenue_per_month",
		(res) => {
			console.log("res", res);
			if (res.data) {
				Highcharts.chart("div_revenue_by_month", {
					credits: false,
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: "pie",
						// width: 250,
					},
					title: {
						text: null,
					},
					tooltip: {
						formatter: function () {
							console.log("this", this);
							return `<b>${this.key}: $${this.y.toFixed(2)}`;
						},
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: "pointer",
							dataLabels: {
								enabled: true,
								distance: -50,
								formatter: function () {
									return `<b style="font-size:16px;">$${this.y.toFixed(2)}</b>`;
								},
							},
							showInLegend: true,
						},
					},
					legend: {
						layout: "horizontal",
						align: "center",
						verticalAlign: "top",
					},
					accessibility: {
						enabled: false,
					},
					series: [
						{
							name: "REVENUE BY MONTH",
							colorByPoint: true,
							data: res.data,
						},
					],
				});
			}
		}
	);

	const handleOnValuesChangePerMonth = (e) => {
		// console.log("handleOnValuesChangePerMonth", e);
		if (e.year) {
			setFilterRevenuePerMonth({
				...filterRevenuePerMonth,
				year: e.year,
			});
		}
		if (e.month) {
			setFilterRevenuePerMonth({
				...filterRevenuePerMonth,
				month: e.month,
			});
		}
	};

	useEffect(() => {
		refetchRevenuePerMonth();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRevenuePerMonth]);

	const [filterRevenuePerState, setFilterRevenuePerState] = useState({
		type: "ALL",
		state: "AZ",
	});
	const { refetch: refetchRevenuePerState } = GET(
		`api/v1/revenue_per_state?${new URLSearchParams(filterRevenuePerState)}`,
		"dashboard_revenue_per_state",
		(res) => {
			console.log("dashboard_revenue_per_state", res);
			if (res.data) {
				Highcharts.chart("div_revenue_by_state", {
					credits: false,
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: "pie",
						// width: 250,
					},
					title: {
						text: null,
					},
					tooltip: {
						formatter: function () {
							// console.log("this", this);
							return `<b>${this.key}: $${this.y.toFixed(2)}`;
						},
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: "pointer",
							dataLabels: {
								enabled: true,
								// format: "<b>{point.name}</b><br>{point.y:.0f}",
								distance: -50,
								formatter: function () {
									// console.log("this", this);
									return `<b style="font-size:16px;">$${this.y.toFixed(2)}</b>`;
								},
							},
							showInLegend: true,
						},
					},
					legend: {
						layout: "horizontal",
						align: "center",
						verticalAlign: "top",
					},
					series: [
						{
							name: "REVENUE BY STATE",
							colorByPoint: true,
							data: res.data,
						},
					],
				});
			}
		}
	);

	const handleOnValuesChangePerState = (e) => {
		// console.log("handleOnValuesChangePerState", e);
		if (e.type) {
			setFilterRevenuePerState({
				...filterRevenuePerState,
				type: e.type,
			});
		}
		if (e.state) {
			setFilterRevenuePerState({
				...filterRevenuePerState,
				state: e.state,
			});
		}
	};

	useEffect(() => {
		refetchRevenuePerState();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRevenuePerState]);

	return (
		<>
			<Collapse
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(270deg)",
							}}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(90deg)",
							}}
						></span>
					)
				}
				style={{
					boxShadow:
						"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
					marginTop: 10,
				}}
				expandIconPosition="right"
				defaultActiveKey={["1"]}
			>
				<Panel
					header={"Revenue"}
					key="1"
					className="accordion bg-darkgray-form"
				>
					<Form
						initialValues={{
							date_start: moment(1, "DD"),
							date_end: moment(),
							type: "ALL",
						}}
						onValuesChange={handleOnValuesChangeAll}
					>
						<Row gutter={8}>
							<Col xs={24} sm={24} md={24} lg={224} xxl={8}>
								<Form.Item name="date_start">
									<FloatDatePicker
										label="Date Start"
										placeholder="Date Start"
										format="MM/DD/YYYY"
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xxl={8}>
								<Form.Item name="date_end">
									<FloatDatePicker
										label="Date End"
										placeholder="Date End"
										format="MM/DD/YYYY"
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xxl={8}>
								<Form.Item
									name="type"
									className="m-b-sm form-select-error"
									rules={[
										{
											required: true,
											message: "This field is required.",
										},
									]}
								>
									<FloatSelectv2
										label="Select Type"
										placeholder="Select Type"
										options={[
											{
												value: "ALL",
												label: "ALL",
											},
											{
												value: "MEMBER",
												label: "MEMBER",
											},
											{
												value: "EVENT PROVIDER",
												label: "EVENT PROVIDER",
											},
											{
												value: "EVENT PROVIDER/SPEAKER",
												label: "EVENT PROVIDER/SPEAKER",
											},
											{
												value: "SPEAKER",
												label: "SPEAKER",
											},
											{
												value: "VENUE PROVIDER",
												label: "VENUE PROVIDER",
											},
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>

					<Typography.Title strong level={4}>
						TOTAL: ${dataRevenueAll?.data.toFixed(2)}
					</Typography.Title>
				</Panel>
			</Collapse>

			<Collapse
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(270deg)",
							}}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(90deg)",
							}}
						></span>
					)
				}
				style={{
					boxShadow:
						"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
					marginTop: 10,
				}}
				expandIconPosition="right"
				defaultActiveKey={["1"]}
			>
				<Panel
					header={"Revenue By Month"}
					key="1"
					className="accordion bg-darkgray-form"
				>
					<Form
						initialValues={{
							year: moment().format("YYYY"),
							month: moment().format("MM"),
						}}
						onValuesChange={handleOnValuesChangePerMonth}
					>
						<Row gutter={8}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
								<Form.Item name="year">
									<FloatSelectv2
										label="Year"
										placeholder="Year"
										options={optionYear}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
								<Form.Item name="month">
									<FloatSelectv2
										label="Month"
										placeholder="Month"
										options={optionMonth}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24}>
								<div
									id="div_revenue_by_month"
									className="highchart-responsive"
									style={{ display: "flex", justifyContent: "center" }}
								/>
							</Col>
						</Row>
					</Form>
				</Panel>
			</Collapse>

			<Collapse
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(270deg)",
							}}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(90deg)",
							}}
						></span>
					)
				}
				style={{
					boxShadow:
						"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
					marginTop: 10,
				}}
				expandIconPosition="right"
				defaultActiveKey={["1"]}
			>
				<Panel
					header={"Revenue By State"}
					key="1"
					className="accordion bg-darkgray-form"
				>
					<Form
						initialValues={{
							type: "ALL",
							state: "AZ",
						}}
						onValuesChange={handleOnValuesChangePerState}
					>
						<Row gutter={8}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
								<Form.Item
									name="type"
									className="m-b-sm form-select-error"
									rules={[
										{
											required: true,
											message: "This field is required.",
										},
									]}
								>
									<FloatSelectv2
										label="Select Type"
										placeholder="Select Type"
										options={[
											{
												value: "ALL",
												label: "ALL",
											},
											{
												value: "MEMBER",
												label: "MEMBER",
											},
											{
												value: "EVENT PROVIDER",
												label: "EVENT PROVIDER",
											},
											{
												value: "EVENT PROVIDER/SPEAKER",
												label: "EVENT PROVIDER/SPEAKER",
											},
											{
												value: "SPEAKER",
												label: "SPEAKER",
											},
											{
												value: "VENUE PROVIDER",
												label: "VENUE PROVIDER",
											},
										]}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
								<Form.Item name="state">
									<FloatSelectv2
										label="State"
										placeholder="State"
										options={optionStateCodes}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24}>
								<div
									id="div_revenue_by_state"
									className="highchart-responsive"
									style={{ display: "flex", justifyContent: "center" }}
								/>
							</Col>
						</Row>
					</Form>
				</Panel>
			</Collapse>
		</>
	);
};
export default memo(PageDashboardPieChart);
