import React, { useEffect, useState, useRef, memo } from "react";
import {
	Card,
	Layout,
	Typography,
	Input,
	DatePicker,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Button,
	Alert,
	Space,
	Collapse,
	Tag,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	HomeOutlined,
	CalendarOutlined,
	EyeOutlined,
	UpOutlined,
	DownOutlined,
	FilterOutlined,
} from "@ant-design/icons";

import moment from "moment";

import image1 from "../../../../assets/img/image1.jpg";
import image2 from "../../../../assets/img/image2.jpg";
import image3 from "../../../../assets/img/image3.jpg";

import { Pie, Column } from "@ant-design/plots";

import getUserData from "../../../../providers/getUserData";
import { GET } from "../../../../providers/useAxiosQuery";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import companyInfo from "../../../../providers/companyInfo";
import { useHistory } from "react-router-dom";

import ModalPreview from "./Modals/ModalPreview";
import { Link } from "react-router-dom";
import $ from "jquery";
import states from "states-us";
import FloatSelectDashboard from "../../../../providers/FloatSelectDashboard";
import FloatSelectDashboard1 from "../../../../providers/FloatSelectDashboard1";
import FloatInputDasboard2 from "../../../../providers/FloatInputDasboard2";
import FloatInputDasboard from "../../../../providers/FloatInputDasboard";
import FloatInputDasboard3 from "../../../../providers/FloatInputDasboard3";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdRefresh } from "react-icons/md";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
import leftArrow from "../../../../assets/img/left-arrow.png";
const PageDashboardRevenueBarChart = () => {
	const userdata = getUserData();
	const sub_title = "All Features";
	const { Search } = Input;
	const { Meta } = Card;
	const history = useHistory();
	const { Panel } = Collapse;

	const [graphRevenuePerYearFilter, setGraphRevenuePerYearFilter] = useState({
		action: "yearly",
		year: "",
		quarter: "",
		month: "",
		week: "",
	});
	const [xTitle, setXTitle] = useState("");
	const { refetch: refetchGraphRevenuePerYear } = GET(
		`api/v1/revenue_graph_per_year?${new URLSearchParams(
			graphRevenuePerYearFilter
		)}`,
		"dashboard_revenue_graph_per_year",
		(res) => {
			// console.log("data_series_value res", res);
			if (res.data) {
				let data = res.data;
				let action = res.data.action;

				let xAxisTitleText = "YEAR";
				let subtitleText = "CLICK THE COLUMNS TO VIEW PER QUARTER";

				if (action === "yearly") {
					xAxisTitleText = "YEAR";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER QUARTER";
				} else if (action === "quarterly") {
					xAxisTitleText = "QUARTER";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER MONTH";
				} else if (action === "monthly") {
					xAxisTitleText = "MONTH";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER DAY";
				} else if (action === "daily") {
					xAxisTitleText = "DAY";
					subtitleText = "CLICK THE COLUMNS TO GO BACK TO PER YEAR";
				}
				setXTitle(xAxisTitleText);
				Highcharts.chart(
					"div_revenue_by_year",
					{
						credits: false,
						chart: {
							type: "column",
							events: {
								// load: function (event) {
								// 	event.target.reflow();
								// },
								click: function (e) {
									let action = data.action;
									let downTo = data.downTo;
									let series_name =
										data.data_series_name[
											Math.abs(Math.round(e.xAxis[0].value))
										];

									// console.log("sss");

									if (action === "yearly") {
										setGraphRevenuePerYearFilter({
											...graphRevenuePerYearFilter,
											action: downTo,
											year: series_name,
										});
									}
									if (action === "quarterly") {
										setGraphRevenuePerYearFilter({
											...graphRevenuePerYearFilter,
											action: downTo,
											quarter: series_name,
										});
									}
									if (action === "monthly") {
										setGraphRevenuePerYearFilter({
											...graphRevenuePerYearFilter,
											action: downTo,
											month: series_name,
										});
									}
									if (action === "daily") {
										setGraphRevenuePerYearFilter({
											...graphRevenuePerYearFilter,
											action: downTo,
											week: "",
											month: "",
											quarter: "",
										});
									}
								},
							},
						},
						title: {
							text: null,
						},
						subtitle: {
							text: subtitleText,
						},
						xAxis: {
							// title: {
							// 	text: xAxisTitleText,
							// },
							categories: data.data_series_name,
							crosshair: true,
							type: "category",
						},
						yAxis: {
							title: {
								text: null,
							},
							labels: {
								formatter: function () {
									return `$${Highcharts.numberFormat(this.value, 0, "", ",")}`;
								},
							},
						},
						tooltip: {
							headerFormat: "",
							formatter: function () {
								return `<span style="color:${this.color}; font-size:14px;">${
									this.x
								}</span><br/>TOTAL: <b>$${this.y.toFixed(2)}</b>`;
							},
							// pointFormat: `<span style="color:{point.color}; font-size:14px;">{point.name}</span><br/>TOTAL: <b>{point.y:.f}</b>`,
						},
						legend: {
							enabled: false,
						},
						plotOptions: {
							series: {
								borderWidth: 0,
								dataLabels: {
									enabled: false,
									// format: "{point.y:.2f}",
								},
							},
							column: {
								pointPadding: 0.2,
								borderWidth: 0,
								dataLabels: {
									enabled: false,
									// format: "{point.y:.0f}",
									formatter: function () {
										if (this.y === 0) {
											return null;
										}
										return this.y.toFixed(2);
									},
								},
								events: {
									click: function (e) {
										let action = data.action;
										let downTo = data.downTo;

										if (action === "yearly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												year: e.point.category,
											});
										}
										if (action === "quarterly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												quarter: e.point.category,
											});
										}
										if (action === "monthly") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												month: e.point.category,
											});
										}
										if (action === "daily") {
											setGraphRevenuePerYearFilter({
												...graphRevenuePerYearFilter,
												action: downTo,
												week: "",
												month: "",
												quarter: "",
											});
										}
									},
								},
							},
						},
						exporting: {
							enabled: false,
						},
						series: [
							{
								name: "REVENUE BY YEAR",
								colorByPoint: true,
								data: data.data_series_value,
							},
						],
					},
					function (chart) {
						// on complete

						if (data.action !== "yearly") {
							chart.renderer
								.image(leftArrow, chart.chartWidth - 65, 15, 18, 17)
								.add()
								.addClass("highcharts-button-arrow-left")
								.css({ cursor: "pointer" })
								.attr({ title: "Filter" })
								.on("click", function () {
									// prcessing after image is clicked
									let action = res.data.action;

									if (action === "quarterly") {
										setGraphRevenuePerYearFilter({
											...graphRevenuePerYearFilter,
											action: "yearly",
										});
									}
									if (action === "monthly") {
										setGraphRevenuePerYearFilter({
											...graphRevenuePerYearFilter,
											action: "quarterly",
										});
									}
									if (action === "daily") {
										setGraphRevenuePerYearFilter({
											...graphRevenuePerYearFilter,
											action: "monthly",
										});
									}
								});
						}
					}
				);
			}
		}
	);

	useEffect(() => {
		refetchGraphRevenuePerYear();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [graphRevenuePerYearFilter]);

	return (
		<>
			<Collapse
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(270deg)",
							}}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{
								color: "#FFF",
								transform: "rotate(90deg)",
							}}
						></span>
					)
				}
				style={{
					boxShadow:
						"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
					marginTop: 10,
				}}
				expandIconPosition="right"
				defaultActiveKey={["1"]}
			>
				<Panel
					header={"Revenue By Year"}
					key="1"
					className="accordion bg-darkgray-form"
				>
					<div
						id="div_revenue_by_year"
						style={{ display: "flex", justifyContent: "center" }}
					/>
					<div className="xTitleBar xTitleRevByYear">
						<strong>{xTitle}</strong>
					</div>
				</Panel>
			</Collapse>
		</>
	);
};
export default memo(PageDashboardRevenueBarChart);
