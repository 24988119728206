import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import Error404 from "../views/public/Widgets/Error404";

// ADMIN
import PageDashboard from "../views/private/Admin/PageAdminDashboard/PageAdminDashboard";
import PageAccountTypes from "../views/private/Admin/PageAccountTypes/PageAccountTypes";
import PageAccountTypesEdit from "../views/private/Admin/PageAccountTypes/PageAccountTypesEdit";
import PageAccountTypeView from "../views/private/Admin/PageAccountTypes/PageAccountTypeView";
import PageProfilesList from "../views/private/Admin/PagesProfiles/PageProfilesList";
import PageProfile from "../views/private/Admin/PagesProfiles/PageProfile";
import PageFaqs from "../views/private/Admin/PageSupport/PageFaqs";
import PageTicketing from "../views/private/Admin/PageSupport/PageTicketing";
import PageCreateTicket from "../views/private/Admin/PageSupport/PageCreateTicket";
import PageTicketingReply from "../views/private/Admin/PageSupport/PageTicketingReply";
import PageAccountType from "../views/private/Admin/PageAccountTypes/PageAccountType";
import PageMessages from "../views/private/PageMessages/PageMessages";
import PageNotification from "../views/private/Admin/PageNotification/PageNotification";
import ComponentCookies from "../views/private/Components/ComponentCookies";
import ComponentPrivacy from "../views/private/Components/ComponentPrivacy";
import ComponentTermsProviders from "../views/private/Components/ComponentTermsProviders";
import PageConfiguration from "../views/private/Admin/PageConfiguration/PageConfiguration";
import PageCoupon from "../views/private/Admin/PageCoupon/PageCoupon";
import Page2fa from "../views/private/Page2fa/Page2fa";

import PageAdminIndividualEvent from "../views/private/Admin/PageAdminEvent/PageAdminIndividualEvent";
import PageAdminViewEventsAttendees from "../views/private/Admin/PageAdminEvent/PageAdminViewEventsAttendees";
import PageEditableTemplates from "../views/private/Admin/PageEditableTemplates/PageEditableTemplates";
import PageMyCompany from "../views/private/Admin/PagesProfiles/PageMyCompany";
import ViweAs from "../views/private/Admin/ViewAs/ViewAs";
import PageRevenue from "../views/private/Admin/PagesRevenue/PageRevenue";
import PageStatsFinancial from "../views/private/Admin/PagesStats/PageStatsFinancial";
import PageStatsAccountType from "../views/private/Admin/PagesStats/PageStatsAccountType";
import PageStatsForcast from "../views/private/Admin/PagesStats/PageStatsForcast";
const RoutePageAdmin = () => {
	return (
		<Switch>
			{/* Admin */}
			<PrivateRouteAdmin
				exact
				path="/terms-providers"
				component={ComponentTermsProviders}
				permission="Conditions"
				breadcrumbs={[
					{
						name: "Terms & Conditions",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/cookies"
				component={ComponentCookies}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Cookie Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/privacy"
				component={ComponentPrivacy}
				permission="Policy"
				breadcrumbs={[
					{
						name: "Privacy Policy",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/dashboard"
				component={PageDashboard}
				permission="Dashboard"
				breadcrumbs={[
					{
						name: "Dashboard",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-types/all"
				component={PageAccountTypes}
				permission="Account Type"
				breadcrumbs={[
					{
						name: "Account Types",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-type/event"
				component={PageAccountType}
				permission="Account Type"
				breadcrumbs={[
					{
						name: "Account Types",
						link: null,
					},
					{
						name: "Event Provider",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-type/member"
				component={PageAccountType}
				permission="Account Type"
				breadcrumbs={[
					{
						name: "Account Types",
						link: null,
					},
					{
						name: "Member",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-type/venue"
				component={PageAccountType}
				permission="Account Type"
				breadcrumbs={[
					{
						name: "Account Types",
						link: null,
					},
					{
						name: "Venue Provider",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-type/speaker"
				component={PageAccountType}
				permission="Account Type"
				breadcrumbs={[
					{
						name: "Account Types",
						link: null,
					},
					{
						name: "Speaker",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-type/event-provider-speaker"
				component={PageAccountType}
				permission="Account Type"
				breadcrumbs={[
					{
						name: "Account Types",
						link: null,
					},
					{
						name: "Event Provider/Speaker",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-types/view/:id"
				component={PageAccountTypeView}
				permission="Account Type"
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/account-types/edit/:id"
				component={PageAccountTypesEdit}
				permission="Account Type"
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/profiles/all"
				component={PageProfilesList}
				permission="Profiles"
				breadcrumbs={[
					{
						name: "List Profiles",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/profiles/edit/:id"
				component={PageProfile}
				permission="Profiles"
				breadcrumbs={[
					{
						name: "Profiles",
						link: "/admin/profiles/all",
					},
					{
						name: "Edit Profile",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/profiles/edit/edit-company/:id"
				component={PageMyCompany}
				permission="Company"
				breadcrumbs={[
					{
						name: "Profiles",
						link: "/admin/profiles/all",
					},
					{
						name: "Edit Company",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/support/faqs"
				component={PageFaqs}
				permission="Questions"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "FAQs",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/support/ticketing"
				component={PageTicketing}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/support/ticketing/:id"
				component={PageTicketingReply}
				permission="Tickets"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Ticketing",
						link: "/admin/support/ticketing",
					},
					{
						name: "Reply",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/support/create-ticket"
				component={PageCreateTicket}
				permission="Ticket"
				breadcrumbs={[
					{
						name: "Support",
						link: null,
					},
					{
						name: "Create Ticket",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/notification"
				component={PageNotification}
				permission="Page Notification"
				breadcrumbs={[
					{
						name: "Notification",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/messages"
				component={PageMessages}
				permission="Messages"
				breadcrumbs={[
					{
						name: "Messages",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/configuration"
				component={PageConfiguration}
				permission="Configuration"
				breadcrumbs={[
					{
						name: "Configuration",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/my-account/profile/2fa"
				component={Page2fa}
				permission="Authentication"
				breadcrumbs={[
					{
						name: "My Account",
						link: null,
					},
					{
						name: "Profile",
						link: "/admin/my-account/profile",
					},
					{
						name: "2-Factor Authentication ",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/coupon"
				component={PageCoupon}
				permission="Coupon"
				breadcrumbs={[
					{
						name: "Coupon",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/events"
				component={PageAdminViewEventsAttendees}
				permission="Attendees"
				breadcrumbs={[
					{
						name: "Events",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/events/:id"
				component={PageAdminIndividualEvent}
				permission="Event"
				breadcrumbs={[
					{
						name: "Events",
						link: "/admin/events/",
					},
					{
						name: "Individual Event",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/editable-templates"
				component={PageEditableTemplates}
				permission="Editable Templates"
				breadcrumbs={[
					{
						name: "Editable Templates",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/view-as"
				component={ViweAs}
				permission="View As"
				breadcrumbs={[
					{
						name: "View As",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/revenue"
				component={PageRevenue}
				permission="Revenue"
				breadcrumbs={[
					{
						name: "Revenue",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/stats/account-type"
				component={PageStatsAccountType}
				permission="Types"
				breadcrumbs={[
					{
						name: "Stats",
						link: null,
					},
					{
						name: "Account Types",
						link: null,
					},
				]}
			/>
			<PrivateRouteAdmin
				exact
				path="/admin/stats/financial"
				component={PageStatsFinancial}
				permission="Stats"
				breadcrumbs={[
					{
						name: "Stats",
						link: null,
					},
					{
						name: "Financial",
						link: null,
					},
				]}
			/>

			<PrivateRouteAdmin
				exact
				path="/admin/stats/forecast"
				component={PageStatsForcast}
				permission="Stats"
				breadcrumbs={[
					{
						name: "Stats",
						link: null,
					},
					{
						name: "Forecast",
						link: null,
					},
				]}
			/>
			{/* this should always in the bottom */}
			<Route path="*" exact={true} component={Error404} />
		</Switch>
	);
};

export default RoutePageAdmin;
