import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Table, Pagination } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { ShopOutlined, MessageOutlined } from "@ant-design/icons";
import $ from "jquery";

import FloatInputSearch from "../../../../providers/FloatInputSearch";
import FloatSelect from "../../../../providers/FloatSelect";
import ModalPreview from "./Modals/ModalPreview";
import { useHistory } from "react-router-dom";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import states from "states-us";
import ComponentFaqs from "../Components/ComponentFaqs";
import getUserData from "../../../../providers/getUserData";

export default function PageVenue({ props, permission }) {
	let userdata = getUserData();
	const history = useHistory();
	const sub_title = "Need a";
	const [previewShow, setPreviewShow] = useState(false);
	const [previewData, setpreviewData] = useState();
	const showModal = (data) => {
		setPreviewShow(true);
		var _uploads = data.venue_images ? JSON.parse(data.venue_images) : [];
		setpreviewData({ ...data, venue_images: _uploads });
	};

	const [state, setState] = useState([]);
	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataVenue,
		isLoading: isLoadingVenue,
		refetch: refetchVenue,
	} = GET(`api/v1/venue?${$.param(dataTableInfo)}`, "venue", (res) => {
		if (res.success) {
			console.log("event_venue", res);
		}
	});

	useEffect(() => {
		refetchVenue();
		return () => {};
	}, [dataTableInfo]);

	const [searchSelect, setSearchSelect] = useState("");
	const handleSelect = (value, options) => {
		setSearchSelect(value);
		setDataTableInfo({
			...dataTableInfo,
			state: value,
			page_number: 1,
		});
	};

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		console.log("current", current);
		console.log("pageSize", pageSize);
	};

	const handleTableChange = (pagination, filters, sorter) => {
		// console.log("sorter", sorter);
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageVenue"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<ShopOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/644214087?autoplay=0&muted=1"
					}
					col="event_search_venue"
					colLink="event_search_venue_link"
				/>
				<Row gutter={24}>
					<Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
						<div style={{ maxWidth: 250 }}>
							<FloatSelect
								label="State"
								placeholder="State"
								onChange={handleSelect}
								options={state}
								value={searchSelect}
							/>
						</div>

						<br />
					</Col>
				</Row>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataVenue && dataVenue.data.total}
							size="small"
						/>
					</Col>
					<Col
						xs={24}
						sm={24}
						md={12}
						lg={6}
						xl={6}
						xxl={6}
						className="searchVenue"
					>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						className="table-responsive table-thead-login"
						size="small"
						rowKey={(record) => record.id}
						loading={isLoadingVenue}
						dataSource={dataVenue ? dataVenue.data.data : []}
						pagination={false}
						onChange={handleTableChange}
					>
						<Table.Column
							key="business_name"
							title="Company"
							dataIndex="business_name"
							// sorter={(a, b) => a.business_name.length - b.business_name.length}
							sorter
						/>
						<Table.Column
							key="venue_name"
							title="Venue"
							dataIndex="venue_name"
							width={250}
							sorter
							render={(a, b) => {
								return (
									<a href="#" onClick={() => showModal(b)}>
										{b.venue_name}
									</a>
								);
							}}
						/>
						<Table.Column key="state" title="State" dataIndex="state" sorter />
						<Table.Column key="city" title="City" dataIndex="city" sorter />
						<Table.Column
							key="capacity"
							title="Capacity"
							dataIndex="capacity"
							sorter
						/>
						<Table.Column
							key="availability"
							title="Catering Available"
							dataIndex="availability"
							sorter
						/>
						{/*
						 */}
					</Table>
				</div>

				{previewData && (
					<ModalPreview
						previewShow={previewShow}
						setPreviewShow={setPreviewShow}
						showModal={showModal}
						previewData={previewData}
					/>
				)}
			</Layout.Content>
		</Layout>
	);
}
