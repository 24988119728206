import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Divider,
  notification,
  Table,
  Popconfirm,
  Space,
  DatePicker,
  Select,
} from "antd";

import {
  DeleteFilled,
  EditFilled,
  PlusCircleOutlined,
  PrinterOutlined,
  UploadOutlined,
  SettingOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import moment from "moment";
const FloatDatePickerForm = (props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    type,
    required,
    format,
    picker,
    disable,
    disabledPast,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker
        onChange={props.onChange}
        value={value ? moment(value) : ""}
        size="large"
        placeholder={[""]}
        style={{ width: "100%" }}
        className="input-date-picker"
        disabledDate={(current) => {
          //   console.log(disabledDate);
          if (disabledPast) {
            return current && current < disabledPast;
          }
        }}
        // // format="YYYY-MM-DD"
        // picker={picker ? picker : null}
        // disabled={disable ? disable : false}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatDatePickerForm;
