import React from "react";
import { PageHeader, Row, Col, Space } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const ComponentHeader = (props) => {
  const { sub_title, permission, icon } = props;

  return (
    <PageHeader
      className="site-page-header"
      // breadcrumb={{ routes }}
      title={
        <Space>
          <div className="ant-icon-container">{icon}</div>
          <div className="ant-sh-container">
            <span className="sh-pagetitle-title-sub">{sub_title}</span>
            <h2 className="sh-pagetitle-title">{permission}</h2>
          </div>
        </Space>
      }
    />
  );
};

export default ComponentHeader;
