const date = new Date();
const key = "REACT-LARAVEL-CELIYA-2021";
const CryptoJS = require("crypto-js");

const encryptData = (string) => {
  let ciphertext = CryptoJS.AES.encrypt(string, key).toString();
  return ciphertext;
};

const decrypt = (string) => {
  let bytes = CryptoJS.AES.decrypt(string, key);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export default function companyInfo() {
  return {
    date,
    key,
    apiUrl: process.env.REACT_APP_API_URL,
    token: localStorage.getItem("token"),
    enrypt: encryptData,
  };
}
