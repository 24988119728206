import React, { useState } from "react";
import { Row, Col, Modal, Space } from "antd";
import { GET } from "../../../../providers/useAxiosQuery";
const ComponentFaqs = ({ linkVid, col, colLink }) => {
  const [infoShow, setInfoShow] = useState(false);
  const showModal = () => {
    setInfoShow(true);
    console.log("wew");
  };

  const onClose = () => {
    setInfoShow(false);
  };
  const { data: dataGetConfig, refetch: refetchGetConfig } = GET(
    "api/v1/config",
    "config",
    (res) => {
      if (res.success) {
        console.log("config", res);
      }
    }
  );

  return dataGetConfig && dataGetConfig.data[col] === 1 ? (
    <>
      <br />
      <Row gutter={24}>
        <Col
          className="gutter-row"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
        >
          <Space>
            <div className="videofaq1" onClick={() => showModal()}></div>
            <div>
              <div className="" style={{ lineHeight: "1", marginTop: "-9px" }}>
                <b className="text-color-default">Video FAQ</b>
                <br />
                Select video button to watch tutorial
              </div>
            </div>
          </Space>
        </Col>
      </Row>
      <Modal
        visible={infoShow}
        onOk={showModal}
        width={895}
        className="modalHeaderFaqs"
        onCancel={onClose}
        footer={null}
      >
        {dataGetConfig && dataGetConfig.data[colLink] ? (
          <iframe
            width="854"
            height="480"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            className="iframModalFaqs"
            src={dataGetConfig.data[colLink]}
          ></iframe>
        ) : (
          <>No Video Uploaded</>
        )}
      </Modal>
    </>
  ) : (
    <>
      {/* <br /> */}
      <br />
    </>
  );
};

export default ComponentFaqs;
