import React, { useState, useEffect } from "react";
import {
	Layout,
	Form,
	Popconfirm,
	Button,
	Row,
	Col,
	Checkbox,
	Collapse,
	notification,
	message,
	Divider,
	Radio,
	Switch,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";

import {
	SaveOutlined,
	UpOutlined,
	DownOutlined,
	UserOutlined,
	EditOutlined,
	MinusOutlined,
	InboxOutlined,
	EyeOutlined,
} from "@ant-design/icons";

import companyInfo from "../../../../providers/companyInfo";

import { Link } from "react-router-dom";

import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMask from "../../../../providers/FloatInputMask";

import getUserData from "../../../../providers/getUserData";
import { GET, GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import states from "states-us";
import optionReferredBy from "../../../../providers/optionReferredBy";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import ModalPreview from "./Modals/ModalPreview";

export default function PageSpeakerProfile({ history, permission, match }) {
	const [previewShow, setPreviewShow] = useState(true);
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();
	const [travel, setTravel] = useState(0);
	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const [dataFetch, setDataFetch] = useState([]);
	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}

		setFormData(val);
		form.resetFields(["state", "zip"]);
		if (dataFetch.length > 0) {
			if (val === dataFetch[0].user_business_infos.country) {
				form.setFieldsValue({
					state: dataFetch[0].user_business_infos.state,
					zip: dataFetch[0].user_business_infos.zip,
				});
			}
		}
	};

	const sub_title = "View/Edit";
	let userdata = getUserData();
	const { Panel } = Collapse;
	const [fileList, setFileList] = useState([]);
	const [multifileList, setMultiFileList] = useState([]);
	const onChange = ({ fileList: newFileList }) => {
		var _file = newFileList;
		console.log(_file);
		if (_file.length != 0) {
			_file[0].status = "done";
			setFileList(_file);
			setFormData("image_upload");
		} else {
			setFileList([]);
		}
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const beforeUpload = (file) => {
		const isJpgOrPng =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "image/gif" ||
			file.type === "image/jpg";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG, PNG, GIF, JPEG file!");
			setFileList([]);
			return;
		}
		const isLt2M = file.size / 102400 / 102400 < 10;
		if (!isLt2M) {
			message.error("Image must smaller than 10MB!");
			setFileList([]);
			return;
		}
		return isJpgOrPng && isLt2M;
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		require_false: {
			required: false,
			message: "Required",
		},
		email: {
			type: "email",
			message: "please enter a valid email",
		},
	};
	const [form] = Form.useForm();
	const [, forceUpdate] = useState({});
	useEffect(() => {
		forceUpdate({});
	}, []);

	const gotoBankDetails = () => {
		history.push("/speaker/my-account/profile/bank-details");
	};

	const gotoBankSubscription = () => {
		history.push("/speaker/my-account/profile/subscription");
	};

	const [isModalPassword, setIsModalPassword] = useState(false);
	const handleCancel = () => {
		setIsModalPassword(false);
	};

	const {
		data: dataProfilemanual,
		refetch: refetchProfilemanual,
		isLoading: isLoadingProfilemanual,
	} = GETMANUAL(
		`api/v1/get_by_id?id=${userdata.id}`,
		`profile_manual`,
		(res) => {
			if (res.success) {
				console.log("atuocomplete", res);

				if (res.data[0].user_business_infos.country === "United States") {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateUS);
					setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
				} else if (res.data[0].user_business_infos.country === "Mexico") {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateMX);
					setOptionZip(/(^\d{5}$)/);
				} else if (res.data[0].user_business_infos.country === "Canada") {
					setStateLabel("County");
					setZipLabel("Postal Code");
					setOptionState(stateCA);
					setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
				} else {
					setStateLabel("State");
					setZipLabel("Zip Code");
					setOptionState(stateUS);
					setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
				}

				form.setFieldsValue({
					first_name: res.data[0].first_name,
					last_name: res.data[0].last_name,
					email: res.data[0].email,
					cell_number: res.data[0].cell_number,
					email_alternative: res.data[0].email_alternative,
					referred_by: res.data[0].referred_by,
					business_category_1: res.data[0].business_category_1,
					business_category_2: res.data[0].business_category_2,
					business_category_3: res.data[0].business_category_3,
					username: res.data[0].username,
					facebook: res.data[0].facebook,
					instagram: res.data[0].instagram,
					business_categories: res.data[0].business_categories
						? JSON.parse(res.data[0].business_categories)
						: [],

					business_name: res.data[0].user_business_infos.business_name,
					address_1: res.data[0].user_business_infos.address_1,
					country: res.data[0].user_business_infos.country,
					city: res.data[0].user_business_infos.city,
					state: res.data[0].user_business_infos.state,
					zip: res.data[0].user_business_infos.zip,
					facebook_link: res.data[0].facebook ? res.data[0].facebook : "",
					instagram_link: res.data[0].instagram ? res.data[0].instagram : "",
					youtube_link: res.data[0].youtube ? res.data[0].youtube : "",
					twitter_link: res.data[0].twitter ? res.data[0].twitter : "",
					vimeo_link: res.data[0].vimeo ? res.data[0].vimeo : "",
				});
			}
		}
	);

	const {
		mutate: mutateUpdateProfileImage,
		isLoading: isLoadingUpdateProfileImage,
	} = POST("api/v1/uppdate_profile_image", "profile");

	const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } =
		POST("api/v1/speaker", "speaker_post");

	const onFinishProfile = (values) => {
		console.log(values);
		console.log(fileList);
		console.log(multifileList);
		const data = new FormData();
		data.append("id", speakerId);
		data.append("first_name", values.first_name ? values.first_name : "");
		data.append("last_name", values.last_name ? values.last_name : "");
		data.append("cell_number", values.cell_number ? values.cell_number : "");
		data.append("email", values.email ? values.email : "");

		data.append("website", values.website ? values.website : "");
		data.append(
			"book_apointment_link",
			values.book_apointment_link ? values.book_apointment_link : ""
		);
		data.append(
			"business_categories",
			values.business_categories ? values.business_categories : ""
		);

		data.append("biography", values.biography ? values.biography : "");
		data.append("topics", values.topics ? values.topics : "");
		data.append("education", values.education ? values.education : "");
		data.append("engagements", values.engagements ? values.engagements : "");
		data.append("credentials", values.credentials ? values.credentials : " ");
		data.append(
			"conflict_of_interests",
			values.conflict_of_interests ? values.conflict_of_interests : ""
		);
		data.append("travel_notes", values.travel_notes ? values.travel_notes : "");

		data.append(
			"business_name",
			values.business_name ? values.business_name : ""
		);
		data.append("country", values.country ? values.country : "");
		data.append("address_1", values.address_1 ? values.address_1 : "");
		data.append("address_2", values.address_2 ? values.address_2 : "");
		data.append("city", values.city ? values.city : "");
		data.append("state", values.state ? values.state : "");
		data.append("zip", values.zip ? values.zip : "");

		data.append(
			"business_phone",
			values.business_phone ? values.business_phone : ""
		);
		data.append(
			"business_email",
			values.business_email ? values.business_email : ""
		);

		data.append("company_agd", values.company_agd ? values.company_agd : "");
		data.append("is_travel", travel ? 1 : 0);
		data.append("services", values.services ? values.services : "");

		data.append(
			"facebook_link",
			values.facebook_link ? values.facebook_link : ""
		);
		data.append(
			"instagram_link",
			values.instagram_link ? values.instagram_link : ""
		);
		data.append("youtube_link", values.youtube_link ? values.youtube_link : "");
		data.append("vimeo_link", values.vimeo_link ? values.vimeo_link : "");
		data.append("twitter_link", values.twitter_link ? values.twitter_link : "");

		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append("upload", fileList[0].originFileObj, fileList[0].name);
			}
		}
		if (multifileList.length > 0) {
			multifileList.map((item, index) => {
				if (item.image_from === "old") {
					data.append("speaker_images_old_" + index, item.image_from_url);
				} else {
					data.append("speaker_images_" + index, item.originFileObj, item.name);
				}
			});
			data.append(
				"speaker_images_count",
				multifileList ? multifileList.length : 0
			);
			data.append("speaker_images", multifileList);
		}

		if (fileList.length > 0 && multifileList.length > 0) {
			mutateUpdateProfile(data, {
				onSuccess: (res) => {
					if (res.success) {
						refetchProfile();
						notification.success({
							message: "Success",
							description: "Speaker Successfully Updated",
						});
					}
				},
				onError: (err) => {
					// notification.success({
					//   message: err.message,
					//   description: err.description,
					// });
				},
			});
		} else {
			notification.warning({
				message: "Warning",
				description: "Speaker Images or Profile Logo is Empty!",
			});
		}
	};

	const [speakerId, setSpeakerId] = useState(0);
	const [notYetSet, setNotYetSet] = useState(false);
	const [authText, setAuthText] = useState("enable");
	const {
		data: dataProfile,
		refetch: refetchProfile,
		isLoading: isLoadingProfile,
	} = GET(
		`api/v1/getSpeaker?user_id=${userdata.id}`,
		`speaker_profile`,
		(res) => {
			if (res.success) {
				if (res.data.length == 0) {
					console.log("walaysulod");
					setNotYetSet(false);
					refetchProfilemanual();
				} else {
					console.log("naaysulod");
					setNotYetSet(true);
					if (res.data[0].country === "United States") {
						setStateLabel("State");
						setZipLabel("Zip Code");
						setOptionState(stateUS);
						setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
					} else if (res.data[0].country === "Mexico") {
						setStateLabel("State");
						setZipLabel("Zip Code");
						setOptionState(stateMX);
						setOptionZip(/(^\d{5}$)/);
					} else if (res.data[0].country === "Canada") {
						setStateLabel("County");
						setZipLabel("Postal Code");
						setOptionState(stateCA);
						setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
					} else {
						setStateLabel("State");
						setZipLabel("Zip Code");
						setOptionState(stateUS);
						setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
					}
					if (res.data[0].is_travel == 1) {
						setTravel(true);
					} else {
						setTravel(false);
					}

					setSpeakerId(res.data[0].id);
					form.setFieldsValue({
						first_name: res.data[0].first_name,
						last_name: res.data[0].last_name,
						email: res.data[0].email,
						cell_number: res.data[0].cell_number,
						business_categories: res.data[0].business_categories
							? JSON.parse(res.data[0].business_categories)
							: [],

						book_apointment_link: res.data[0].book_apointment_link,
						website: res.data[0].website,

						business_name: res.data[0].business_name,
						address_1: res.data[0].address_1,
						address_2: res.data[0].address_2,
						country: res.data[0].country,
						city: res.data[0].city,
						state: res.data[0].state,
						zip: res.data[0].zip,

						company_agd: res.data[0].company_agd,

						is_travel: res.data[0].is_travel == 1 ? true : false,
						conflict_of_interests: res.data[0].conflict_of_interests,
						travel_notes: res.data[0].travel_notes,
						biography: res.data[0].biography,
						education: res.data[0].education,

						business_phone: res.data[0].business_phone,
						business_email: res.data[0].business_email,

						topics: res.data[0].topics,
						engagements: res.data[0].engagements,
						services: res.data[0].services,
						credentials: res.data[0].credentials,

						facebook_link: res.data[0].facebook_link,
						instagram_link: res.data[0].instagram_link,
						twitter_link: res.data[0].twitter_link,
						vimeo_link: res.data[0].vimeo_link,
						youtube_link: res.data[0].youtube_link,
					});

					setFileList([
						{
							uid: "-1",
							name: "image.png",
							status: "done",
							url:
								companyInfo().apiUrl + "storage/" + res.data[0].logo_or_photo,
							thumbUrl:
								companyInfo().apiUrl + "storage/" + res.data[0].logo_or_photo,
						},
					]);
					let arr = [];
					let venue_images = JSON.parse(res.data[0].speaker_images);
					venue_images.map((row, key) => {
						arr.push({
							uid: "-" + (key + 1),
							name: "image.png",
							status: "done",
							url: companyInfo().apiUrl + "storage/" + row,
							thumbUrl: companyInfo().apiUrl + "storage/" + row,
							image_from: "old",
							image_from_url: row,
						});
					});
					setMultiFileList(arr);
					if (!res.data[0].facebook_link || res.data[0].facebook_link == "") {
						form.resetFields(["facebook_link"]);
					}
					if (!res.data[0].instagram_link || res.data[0].instagram_link == "") {
						form.resetFields(["instagram_link"]);
					}
					if (
						!res.data[0].youtube_vimeo_link ||
						res.data[0].youtube_vimeo_link == ""
					) {
						form.resetFields(["youtube_vimeo_link"]);
					}
					if (!res.data[0].twitter_link || res.data[0].twitter_link == "") {
						form.resetFields(["twitter_link"]);
					}
					if (!res.data[0].vimeo_link || res.data[0].vimeo_link == "") {
						form.resetFields(["vimeo_link"]);
					}
					if (!res.data[0].youtube_link || res.data[0].youtube_link == "") {
						form.resetFields(["youtube_link"]);
					}
					// if (
					//   res.data[0].user_business_infos &&
					//   res.data[0].user_business_infos.address_2
					// ) {
					//   form.setFieldsValue({
					//     address_2: res.data[0].user_business_infos.address_2,
					//   });
					// } else {
					//   form.resetFields(["address_2"]);
					// }
					// if (!res.data[0].cell_number) {
					//   form.resetFields(["cell_number"]);
					// }
					// if (!res.data[0].email_alternative) {
					//   form.resetFields(["email_alternative"]);
					// }
					// if (!res.data[0].referred_by) {
					//   form.resetFields(["referred_by"]);
					// }
					// if (!res.data[0].business_category_1) {
					//   form.resetFields(["business_category_1"]);
					// }
					// if (!res.data[0].business_category_2) {
					//   form.resetFields(["business_category_2"]);
					// }
					// if (!res.data[0].business_category_3) {
					//   form.resetFields(["business_category_3"]);
					// }
					// if (!res.data[0].facebook) {
					//   form.resetFields(["facebook"]);
					// }
					// if (!res.data[0].instagram) {
					//   form.resetFields(["instagram"]);
					// }
					// if (!res.data[0].youtube_vimeo) {
					//   form.resetFields(["youtube_vimeo"]);
					// }
					// let image_type =
					//   res.data[0] && res.data[0].upload ? res.data[0].upload.split("/") : "";
					// if (image_type[0] != undefined) {
					//   image_type =
					//     image_type[0] == "https:"
					//       ? res.data[0].upload
					//       : companyInfo().apiUrl + "storage/" + res.data[0].upload;
					//   setFileList([
					//     {
					//       uid: "-1",
					//       name: "image.png",
					//       status: "done",
					//       url: image_type,
					//     },
					//   ]);
					// } else {
					//   setFileList([]);
					//   image_type = "";
					// }
				}
			}
		}
	);

	let business_category = [
		{
			label: "Dental Lab",
			value: "Dental Lab",
		},
		{
			label: "Dental Product",
			value: "Dental Product",
		},
		{
			label: "Dental Specialist",
			value: "Dental Specialist",
		},
		{
			label: "Dental Supply Company",
			value: "Dental Supply Company",
		},
		{
			label: "Dentist",
			value: "Dentist",
		},
		{
			label: "Educator",
			value: "Educator",
		},
		{
			label: "Medical Doctor",
			value: "Medical Doctor",
		},
		{
			label: "Motivational Speaker",
			value: "Motivational Speaker",
		},
		{
			label: "Other",
			value: "Other",
		},
		{
			label: "Professional Speaker",
			value: "Professional Speaker",
		},
		{
			label: "Professor",
			value: "Professor",
		},
		{
			label: "Study Club",
			value: "Study Club",
		},
	];

	const [state, setState] = useState([]);
	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.name,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const [deactivate, setDeactivate] = useState(false);

	const { mutate: mutateDeactive, isLoading: isLoadingDeactive } = POST(
		"api/v1/user_deactive",
		"user_deactive"
	);

	const { mutate: mutateLogout, isLoading: isLoadingLogout } = POST(
		"api/v1/logout",
		"logout"
	);

	const handleDeactivate = () => {
		mutateDeactive(
			{ id: userdata.id, link: window.location.origin },
			{
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: res.message,
							description: res.description,
						});

						// mutateLogout(
						//   { user_id: userdata.id },
						//   {
						//     onSuccess: (res) => {
						//       console.log("userdata", res);
						//       if (res.success) {
						//         localStorage.removeItem("token");
						//         localStorage.removeItem("userdata");
						//         window.location.href = window.location.origin;
						//       }
						//     },
						//   }
						// );
					}
				},
				onError: (err) => {},
			}
		);
	};

	const [currentPlan, setCurrentPlan] = useState();
	const { data: dataAccountType, isLoading: isLoadingAccountType } = GET(
		"api/v1/acc_type_per_user",
		"acc_type_per_user",
		(res) => {
			if (res.success) {
				console.log("acc_type", res);

				let currentPlan;
				if (res.current_plan) {
					currentPlan = res.current_plan.account_type_plans;
					currentPlan = (
						<>
							<h2 style={{ marginBottom: 0 }}>
								{currentPlan.plan} - ${currentPlan.amount}
							</h2>
							<span className="c-secondary">{currentPlan.description}</span>
						</>
					);
				} else {
					currentPlan = "Not yet Subscribed to any Plan";
				}

				// console.log("currentPlan", currentPlan);
				setCurrentPlan(currentPlan);
			}
		}
	);

	const [formData, setFormData] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			console.log("useEffect", formData);
			if (formData) {
				form.submit();
				setFormData("");
			}
		}, 3000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [formData]);

	const hanldeInput = (val) => {
		// console.log(val);
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const hanldeInputSwitch = (val) => {
		setTravel(val);
		setFormData("change");
	};

	const onChangeOption = (val, option) => {
		setFormData(val);
	};

	const hanldeTextArea = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const [radioData, setRadioData] = useState(1);
	const [imageCrop, setImageCrop] = useState({
		width: 1,
		height: 1,
	});

	const handleResize = (val) => {
		console.log("val", val.target.value);
		setRadioData(val.target.value);
		if (val.target.value === 1) {
			setImageCrop({
				width: 1,
				height: 1,
			});
		} else if (val.target.value === 2) {
			setImageCrop({
				width: 3.9,
				height: 2.6,
			});
		} else if (val.target.value === 3) {
			setImageCrop({
				width: 1,
				height: 1.5,
			});
		}
	};

	const [radioData1, setRadioData1] = useState(1);
	const [imageCrop1, setImageCrop1] = useState({
		width: 1,
		height: 1,
	});

	const handleResize1 = (val) => {
		console.log("val", val.target.value);
		setRadioData1(val.target.value);
		if (val.target.value === 1) {
			setImageCrop1({
				width: 1,
				height: 1,
			});
		} else if (val.target.value === 2) {
			setImageCrop1({
				width: 3.9,
				height: 2.6,
			});
		} else if (val.target.value === 3) {
			setImageCrop1({
				width: 1,
				height: 1.5,
			});
		}
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageSpeakerProfile"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<UserOutlined />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/645101788?autoplay=0&muted=1"
					}
					col="speaker_profile"
					colLink="speaker_profile_link"
				/>
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinishProfile}
				>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Speaker Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="first_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
													onChange={hanldeInput}
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="last_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Last Name"
													placeholder="Last Name"
													onChange={hanldeInput}
													disabled={true}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="email"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Email Address"
													placeholder="Email Address"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="cell_number"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputMask
													label="Cell Phone"
													placeholder="Cell Phone"
													maskLabel="cell_number"
													maskType="999-999-9999"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="business_categories"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error-multi"
											>
												<FloatSelect
													label="Business Category"
													placeholder="Business Category"
													options={business_category}
													multi="multiple"
													onChange={onChangeOption}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="website"
												rules={[validator.require_false]}
											>
												<FloatInput
													label="Website"
													placeholder="Webiste"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="book_apointment_link"
												rules={[validator.require_false]}
											>
												<FloatInput
													label="Book Appointment Link"
													placeholder="Book Appointment Link"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item name="biography" rules={[validator.require]}>
												<FloatTextArea
													label="Biography"
													placeholder="Biography"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item name="topics" rules={[validator.require]}>
												<FloatTextArea
													label="Topics"
													placeholder="Topics"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="education"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Education"
													placeholder="Education"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="engagements"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Previous Speaking Engagements"
													placeholder="Previous Speaking Engagements"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="credentials"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Credentials"
													placeholder="Credentials"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="conflict_of_interests"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Conflict of Interests"
													placeholder="Conflict of Interests"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="travel_notes"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Travel Notes"
													placeholder="Travel Notes"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Panel>
							</Collapse>

							<br></br>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Company Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="business_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Business Name"
													placeholder="Business Name"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="country"
												rules={[validator.require]}
												className="form-select-error"
												hasFeedback
											>
												<FloatSelect
													label="Country"
													placeholder="Country"
													options={country}
													onChange={handleCountry}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="address_1"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Address 1"
													placeholder="Address 1"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="address_2"
												rules={[validator.require_false]}
											>
												<FloatInput
													label="Address 2"
													placeholder="Address 2"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
											<Form.Item
												name="city"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="City"
													placeholder="City"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
											<Form.Item
												name="state"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label={stateLabel}
													placeholder={stateLabel}
													options={optionState}
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
											<Form.Item
												name="zip"
												rules={[
													validator.require,
													{
														pattern: optionZip,
														message: "Invalid " + zipLabel,
													},
												]}
												hasFeedback
											>
												<FloatInput
													label={zipLabel}
													placeholder={zipLabel}
													onChange={hanldeInput}
												/>
											</Form.Item>{" "}
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="business_phone"
												rules={[validator.require_false]}
											>
												<FloatInputMask
													label="Business Phone"
													placeholder="Business Phone"
													maskLabel="business_phone"
													maskType="999-999-9999"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="business_email"
												rules={[validator.require_false]}
											>
												<FloatInput
													label="Business Email"
													placeholder="Business Email"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="company_agd"
												rules={[validator.require_false]}
											>
												<FloatInput
													label="Company AHG#"
													placeholder="Company AHG#"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="is_travel"
												rules={[validator.require_false]}
											>
												<Switch onChange={hanldeInputSwitch} checked={travel} />{" "}
												<span>Will Travel</span>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="services"
												rules={[validator.require_false]}
											>
												<FloatTextArea
													label="Services"
													placeholder="Services"
													onChange={hanldeInput}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Panel>
							</Collapse>

							<br></br>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Social Media"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Form.Item name="facebook_link">
										<FloatInput
											label="Your Facebook URL Link"
											placeholder="Your Facebook URL Link"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item name="instagram_link">
										<FloatInput
											label="Your Instagram Account"
											placeholder="Your Instagram Account"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item name="twitter_link">
										<FloatInput
											label="Your Twitter Account "
											placeholder="Your Twitter Account "
											onChange={hanldeInput}
										/>
									</Form.Item>{" "}
									<Form.Item name="youtube_link">
										<FloatInput
											label="Your Youtube Account "
											placeholder="Your Youtube Account "
											onChange={hanldeInput}
										/>
									</Form.Item>{" "}
									<Form.Item name="vimeo_link">
										<FloatInput
											label="Your Vimeo Account "
											placeholder="Your Vimeo Account "
											onChange={hanldeInput}
										/>
									</Form.Item>{" "}
								</Panel>
							</Collapse>
							<br></br>
						</Col>
						<Col xs={24} sm={24} md={8} lg={8}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Logo or Profile Photo"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<label className="font-red">
										<b>
											Photo upload & Cropping: Select your image orientation
										</b>
									</label>
									<br />
									<Radio.Group
										// className="crop-center"
										onChange={handleResize}
										value={radioData}
									>
										<Radio value={1}>Square</Radio>
										<Radio value={2}>Rectangle</Radio>
										<Radio value={3}>Portrait</Radio>
									</Radio.Group>
									<Divider />
									<div>
										<ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
											<Upload
												// action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
												listType="picture-card"
												style={{ width: "200px" }}
												maxCount={1}
												action={false}
												customRequest={false}
												fileList={fileList}
												onChange={onChange}
												onPreview={onPreview}
												beforeUpload={beforeUpload}
												loading={isLoadingUpdateProfileImage}
												className="profilePhoto"
											>
												{fileList.length < 1 && "+ Upload"}
											</Upload>
										</ImgCrop>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<span>One file Only. 10 MB limit.</span>
										<span>
											Your selected profile photo will be visible to all event
											organizers and event attendees. <br></br>
										</span>
									</div>
									<div className="c-secondary" style={{ fontSize: "12px" }}>
										Allowed types: png gif jpg jpeg.
									</div>
								</Panel>
							</Collapse>
							<br></br>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Speaker Images"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<label className="font-red">
										<b>
											Photo upload & Cropping: Select your image orientation
										</b>
									</label>
									<br />
									<Radio.Group onChange={handleResize1} value={radioData1}>
										<Radio value={1}>Square</Radio>
										<Radio value={2}>Rectangle</Radio>
										<Radio value={3}>Portrait</Radio>
									</Radio.Group>
									<Divider />
									<ImgCrop rotate aspect={imageCrop1.width / imageCrop1.height}>
										<Upload
											className="venue-images"
											listType="picture"
											fileList={multifileList}
											onChange={({ fileList: newFileList }) => {
												var _file = newFileList;
												console.log(_file);
												_file.map((row, key) => {
													return (row.status = "done");
												});
												setMultiFileList(_file);
												setFormData("multifileList");
											}}
											beforeUpload={(file) => {
												console.log(file.size);
												let error = false;
												const isJpgOrPng =
													file.type === "image/jpeg" ||
													file.type === "image/png" ||
													file.type === "image/gif" ||
													file.type === "image/jpg";
												if (!isJpgOrPng) {
													message.error(
														"You can only upload JPG, PNG, GIF, JPEG file!"
													);
													error = Upload.LIST_IGNORE;
												}
												const isLt2M = file.size / 102400 / 102400 < 10;
												if (!isLt2M) {
													message.error("Image must smaller than 10MB!");
													error = Upload.LIST_IGNORE;
												}
												if (error === false) {
													return isJpgOrPng && isLt2M;
												}
											}}
											onPreview={async (file) => {
												let src = file.url;
												if (!src) {
													src = await new Promise((resolve) => {
														const reader = new FileReader();
														reader.readAsDataURL(file.originFileObj);
														reader.onload = () => resolve(reader.result);
													});
												}
												const image = new Image();
												image.src = src;
												const imgWindow = window.open(src);
												imgWindow.document.write(image.outerHTML);
											}}
										>
											<Button type="dashed" className="venue-images-btn">
												<p className="ant-upload-text">
													<p className="ant-upload-drag-icon">
														<InboxOutlined />
													</p>
													<p className="ant-upload-text">
														Add a new file. Unlimited number of files can be
														uploaded to this field. 10MB limit.
													</p>
													<p className="ant-upload-hint">
														Allowed types: png gif jpg jpeg
													</p>
												</p>
											</Button>
										</Upload>
									</ImgCrop>
								</Panel>
							</Collapse>
							<br></br>
							{notYetSet && (
								<Button
									size="large"
									className="btn-loginNew-outline"
									// style={{ width: 200 }}
									icon={<EyeOutlined />}
									onClick={(e) => setPreviewShow(true)}
									// htmlType="submit"
									// loading={isLoadingVenue}
									block
								>
									Preview
								</Button>
							)}
						</Col>
					</Row>
					<br></br>

					{dataProfile && dataProfile.data.length != 0 && (
						<ModalPreview
							previewShow={previewShow}
							setPreviewShow={setPreviewShow}
							previewData={dataProfile && dataProfile.data}
						/>
					)}
				</Form>
			</Layout.Content>
		</Layout>
	);
}
