import {
	Button,
	Card,
	Col,
	Layout,
	notification,
	Row,
	Space,
	Typography,
	Upload,
	Image,
	Divider,
	Select,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
	UploadOutlined,
	ArrowLeftOutlined,
	SendOutlined,
	SnippetsOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import moment from "moment";
import companyInfo from "../../../../providers/companyInfo";
import { dateDiff } from "../../../../providers/dateDiff";
import { useHistory } from "react-router-dom";
import { GET, POST, UPDATE } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import FloatSelect from "../../../../providers/FloatSelect";
import getUserData from "../../../../providers/getUserData";
import profile from "../../../../assets/img/profile.png";
import ComponentHeader from "../Components/ComponentHeader";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

const PageTicketingReply = (props) => {
	const sub_title = "View My";
	const history = useHistory();
	const userdata = getUserData();
	const ticket_id = props.match.params.id;
	const { Title, Text } = Typography;

	const modulesToolBar = {
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			["link", "image", "video"],
			["clean"],
		],
		imageResize: {
			modules: ["Resize", "DisplaySize"],
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];

	const [ticketResponse, setTicketResponse] = useState([]);
	const [ticketUserData, setTicketUserData] = useState([]);
	const [ticketUserImage, setTicketUserImage] = useState("");
	const {
		data: dataGetTicket,
		refetch: refetchGetTicket,
		isLoading: isLoadingGetTicket,
	} = GET(`api/v1/tickets/${ticket_id}`, "tickets_response", (res) => {
		if (res.success) {
			console.log("tickets_response", res);
			if (userdata.id != res.data.requester) {
				history.push("/venue-provider/support/ticketing");
			}

			setTicketUserData(res.data);
			setTicketResponse(res.data.ticket_response);
			let avatarImage = res.data.requeter_user.upload.split("/");
			if (avatarImage[0] === "https:") {
				setTicketUserImage(res.data.requeter_user.upload);
			} else {
				setTicketUserImage(
					companyInfo().apiUrl + "storage/" + res.data.requeter_user.upload
				);
			}
			console.log("tickets_response", avatarImage[0]);
		}
	});
	const [showReply, setShowreply] = useState(false);
	const [submitButtonText, setSubmitButtonText] = useState("Reply");
	const [responseData, setResponseData] = useState({
		is_pan: false,
		response: "",
		attachment_url: [],
	});

	useEffect(() => {
		// console.log(responseData);

		return () => {};
	}, [responseData]);

	const handleSubmitReply = () => {
		let formData = new FormData();
		formData.append(
			"response",
			responseData.response ? responseData.response : ""
		);
		formData.append("ticket_id", ticket_id);
		formData.append("link", window.location.origin);
		console.log("responseData", responseData);
		if (responseData.attachment_url.length !== 0) {
			if (responseData.attachment_url[0].uid != "-1") {
				// formData.append("upload", responseData.attachment_url ? responseData.attachment_url: "");
				formData.append(
					"upload",
					responseData.attachment_url[0].originFileObj,
					responseData.attachment_url[0].name
				);
			}
		}

		mutateTicketReply(formData, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: res.message,
						description: res.description,
					});
					setSubmitButtonText("Reply");
					setResponseData({
						is_pan: false,
						response: "",
						attachment_url: [],
					});
					setShowreply(false);
				}
			},
			onError: (err) => {},
		});
	};

	const { mutate: mutateTicketReply, isLoading: isLoadingTicketReply } = POST(
		"api/v1/tickets_response",
		"tickets_response"
	);

	const trimResponse = (response) => {
		let _response = response.split('<br><div className="gmail_quote">');
		_response = _response[0];
		return _response;
	};

	const { mutate: mutateTicketUpdate, isLoading: isLoadingTicletUpdate } =
		UPDATE(`api/v1/tickets`, "tickets_response");

	const handleStatusChange = (value) => {
		let data = {
			id: ticket_id,
			status: value,
		};
		mutateTicketUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: res.message,
						description: res.description,
					});
				} else {
					notification.success({
						message: res.message,
						description: res.description,
					});
				}
			},
		});
	};

	const [userAssigned, setUserAssigned] = useState([]);
	const { data: dataUser } = GET(
		"api/v1/user_assigned_tickets",
		"user_assigned_tickets",
		(res) => {
			if (res.success) {
				console.log("user_assigned_tickets", res);
				let arr = [];
				res.data.map((row, key) => {
					arr.push({
						value: row.id,
						label: row.email,
					});
				});
				setUserAssigned(arr);
			}
		}
	);

	const handleAssignedChange = (e, options) => {
		let data = {
			id: ticket_id,
			asssigned: e,
		};
		mutateTicketUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: res.message,
						description: res.description,
					});
				} else {
					notification.success({
						message: res.message,
						description: res.description,
					});
				}
			},
		});
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 5px 20px 0px",
				background: "#fff",
			}}
			id="PageTicketing"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={"Ticket"}
				icon={<SnippetsOutlined />}
			/>
			<Row gutter={24} style={{ padding: 15 }}>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={16}
					xl={16}
					xxl={16}
					style={{ paddingLeft: "3px" }}
				>
					<Card
						title={
							<>
								<Button
									type="link"
									onClick={(e) => history.goBack()}
									icon={<ArrowLeftOutlined />}
									style={{ padding: "0" }}
									className="c-darkgray"
								>
									Back to Tickets
								</Button>
								<br />
								<Title className="ticketTitle">
									{dataGetTicket && dataGetTicket.data.subject}{" "}
								</Title>
								<br />
								<Row gutter={24}>
									<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
										<span style={{ fontWeight: "300" }}>
											{moment(
												dataGetTicket && dataGetTicket.data.created_at
											).format("MMM. DD YYYY")}
										</span>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={12}
										lg={12}
										xl={12}
										xxl={12}
										className="text-right"
									>
										<Button
											type="link"
											className="mx-1 nodecor c-lightorange"
											style={{ fontWeight: "300", float: "right" }}
											onClick={(e) => setShowreply(showReply != true)}
										>
											<FontAwesomeIcon
												className="c-lightorange"
												icon={faReply}
												style={{ marginRight: "5px" }}
											/>
											Reply
										</Button>
									</Col>
								</Row>
							</>
						}
						bordered={false}
						bodyStyle={{
							height: "auto",
							// paddingBottom: "50px",
						}}
						headStyle={{
							borderBottom: 0,
							marginBottom: "-56px",
						}}
					>
						<Divider />
						{showReply && (
							<Row gutter={24}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
									<ReactQuill
										theme="snow"
										className="ticket-quill"
										style={{ height: 200 }}
										modules={modulesToolBar}
										formats={formats}
										onChange={(e) => {
											setResponseData({ ...responseData, response: e });
										}}
									/>
								</Col>
								<Col
									className="gutter-row"
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									xxl={24}
								>
									<Upload
										onChange={({ fileList: newFileList }) => {
											var _file = newFileList;
											if (_file.length != 0) {
												_file[0].status = "done";
												setResponseData({
													...responseData,
													attachment_url: _file,
												});
											} else {
												setResponseData({
													...responseData,
													attachment_url: [],
												});
											}
										}}
										maxCount={1}
										// customRequest={({
										//     onSuccess,
										//     onError,
										//     file
										// }) => {
										//     setResponseData({
										//         ...responseData,
										//         attachment_url: file
										//     });
										// }}
									>
										<Button icon={<UploadOutlined />}>Attach File</Button>
									</Upload>
									<br />
								</Col>
								<Col
									className="gutter-row"
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									xxl={24}
								>
									<Space>
										<Button
											size="sm"
											type="primary"
											className="mr-1 btn-loginNew-outline"
											loading={isLoadingTicketReply}
											onClick={handleSubmitReply}
											icon={<SendOutlined />}
										>
											{submitButtonText}
										</Button>
										<Button
											className="btn-loginNew-outline-acitve"
											onClick={(e) => {
												setShowreply(false);
												setResponseData(null);
											}}
											style={{ marginLeft: 5 }}
										>
											Cancel
										</Button>
									</Space>
								</Col>
								<Divider />
							</Row>
						)}

						{ticketUserData && (
							<Row gutter={24}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
									<div>
										<div style={{ display: "flex" }}>
											<img
												src={ticketUserImage}
												width="40"
												height="40"
												className="pull-left mr-2 "
												style={{
													marginRight: 5,
													borderRadius: "50%",
													objectFit: "cover",
												}}
											/>
											<div className="ticketUserReply">
												{ticketUserData.requeter_user
													? ticketUserData.requeter_user.first_name
													: ""}{" "}
												{ticketUserData.requeter_user
													? ticketUserData.requeter_user.last_name
													: ""}
												<br />{" "}
												<small className="text-muted">
													{dateDiff(ticketUserData.created_at)}
												</small>
											</div>
										</div>
										<br />
										{ticketUserData.requeter_user && (
											<div
												dangerouslySetInnerHTML={{
													__html: trimResponse(ticketUserData.comments),
												}}
											></div>
										)}
										<Divider />
									</div>
								</Col>
							</Row>
						)}
						<Row gutter={24}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								{ticketResponse &&
									ticketResponse.map((row, key) => {
										let image_type = row.user_submitted.upload
											? row.user_submitted.upload.split("/")
											: profile;
										return (
											<div key={key}>
												<div style={{ display: "flex" }}>
													<img
														src={
															image_type[0] == "https:"
																? row.user_submitted.upload
																: row.user_submitted.upload
																? companyInfo().apiUrl +
																  "storage/" +
																  row.user_submitted.upload
																: profile
														}
														width="40"
														height="40"
														className="pull-left mr-2 "
														style={{
															marginRight: 5,
															borderRadius: "50%",
															objectFit: "cover",
														}}
													/>
													<div className="ticketUserReply">
														{row.user_submitted.first_name}{" "}
														{row.user_submitted.last_name}
														<br />{" "}
														<small className="text-muted">
															{dateDiff(row.created_at)}
														</small>
													</div>
												</div>
												<br />
												<div
													dangerouslySetInnerHTML={{
														__html: trimResponse(row.response),
													}}
												></div>
												<br />
												{row.attachment_url && (
													<a
														target="_blank"
														download={`${companyInfo().apiUrl}storage/${
															row.attachment_url
														}`}
														href={`${companyInfo().apiUrl}storage/${
															row.attachment_url
														}`}
													>
														Attachment
													</a>
												)}

												<Divider />
											</div>
										);
									})}
							</Col>
						</Row>
					</Card>
				</Col>
				<Col
					className="gutter-row"
					xs={24}
					sm={24}
					md={24}
					lg={8}
					xl={8}
					xxl={8}
				>
					<Card bordered={false}>
						<h3>Ticket Information</h3>
						<br />
						<span className="span-title">
							Ticket ID
							<br />
						</span>
						<span>#{dataGetTicket && dataGetTicket.data.id}</span>
						<br /> <br />
						<span className="span-title">Subject</span>
						<br />
						<span>{dataGetTicket && dataGetTicket.data.subject}</span>
						<br /> <br />
						<span className="span-title">Status</span>
						<br />
						<span>{dataGetTicket && dataGetTicket.data.status}</span>
						<br />
						<br />
						{userdata.role === "SUPER ADMIN" && (
							<>
								<FloatSelect
									placeholder="Status"
									label="Status"
									value={dataGetTicket && dataGetTicket.data.status}
									options={[
										{ label: "Open", value: "Open" },
										{
											label: "Awaiting Customer Reply",
											value: "Awaiting Customer Reply",
										},
										{
											label: "Awaiting Support Reply",
											value: "Awaiting Support Reply",
										},
										{ label: "On Hold", value: "On Hold" },
										{ label: "Closed", value: "Closed" },
										{ label: "Archive", value: "Archive" },
									]}
									onChange={handleStatusChange}
								/>
								<br />
								<FloatSelect
									placeholder="Search Assigned"
									label="Search Assigned"
									value={dataGetTicket && dataGetTicket.data.asssigned}
									options={userAssigned}
									onChange={handleAssignedChange}
								/>
							</>
						)}
					</Card>
				</Col>
			</Row>
		</Layout>
	);
};

export default PageTicketingReply;
