import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Layout, Card, Typography } from "antd";
import { BookOutlined } from "@ant-design/icons";
import ComponentHeader from "../Member/Components/ComponentHeader";
import { Link } from "react-router-dom";
import { GiThorHammer } from "react-icons/gi";

const ComponentTermsProviders = ({ permission, icon }) => {
	const sub_title = "Terms &";
	useEffect(() => {
		scrollView();
	}, []);

	const divScroll = useRef(null);

	const scrollView = () => {
		divScroll.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};
	return (
		<Layout
			className="site-layout-background ant-page-terms-providers"
			style={{
				padding: "20px 0px 20px 0px",
				background: "#fff",
			}}
			id="ComponentTermsProviders"
			ref={divScroll}
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<GiThorHammer />}
			/>

			<Card bordered={false}>
				<br></br>
				<Row gutter={24}>
					<Col md={24}>
						<Typography.Title
							level={4}
							style={{ lineHeight: 0, color: "#293a56" }}
							className=" font-family-roboto tx-normal TypoClassLine"
						>
							Terms & Conditions
						</Typography.Title>
					</Col>
				</Row>
				<br></br>
				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							These Terms & Conditions (the “Terms”) govern your use and access
							to our Company’s website (www.celiya.com) and any of our social
							media channels or emails (collectively the “Website”) or use or
							purchase of our services and/or products (individually and
							collectively the “Services”).
							<br />
							<br />
							The terms “we”, “us” and “our” refers to Ce.Liya LLC (“Company”).
							When these Terms use the term “Providers” we mean the event
							creators who use our Services to create Continuing Education
							events displayed on the Website for the use of participants who
							are attending the events (“Participants”) or for any other reason.
							When the Terms use the term “Suppliers” we mean the venue partners
							using the Service to provide hotels and venues displayed on the
							Website for the use of the Participants while attending events and
							for use to Providers to host the events. Providers, Participants,
							Venues and any other third parties using our Services are referred
							to in these Terms collectively as “you” or “your.”
							<br />
							<br />
							Please read these Terms carefully before accessing or using our
							Website. By accessing or using any part of our Website, you agree
							to be bound by these Terms. If you do not agree to all of these
							Terms, then you may not access or use the Websites or any of our
							Services therein.
							<br />
							<br />
							1. OUR SERVICES AND ROLES.
							<br />
							<Row gutter={24}>
								<Col md={23} offset={1}>
									a. We are a fully digital membership solution, helping
									Participants find and sign up for Continuing Education events
									(individually and collectively the “CE Events”) live online
									and live in your local area, while helping Providers have
									access to Participants to promote their CE Events. We are not
									the creator, organizer or owner of the CE Events listed on the
									Services. Rather we provide our Services, which allows
									Providers to promote their CE Events and manage ticketing and
									registration of Participants. The Provider is solely
									responsible for ensuring that any page displaying CE Event on
									the Website and the CE Event itself meets all applicable,
									local, state, national and other laws, rules and regulations.
									In addition, Provider shall be responsible for providing
									accurate descriptions on the event page and the CE Events are
									delivered as described in an accurate and satisfactory manner.
									If a Provider is using our payment processing, we will also
									act as the Provider’s limited agent solely for the purpose of
									using our third-party payment service providers to collect
									payments made by the Participants on the Services and passing
									such payments to the Provider.
									<br />
									<br />
									b. Our Services enables Providers to search for and book venue
									space for CE Events (“Venue”) offered by suppliers, vendors,
									or other providers of such Venues (“Suppliers”). We do not
									own, control, offer, or manage any of the Venues. The Supplier
									is solely responsible for ensuring that any page displaying
									the Venue information on the Website, meets all applicable,
									local, state, national and other laws, rules and regulations.
									In addition, Supplier shall be responsible for providing
									accurate descriptions of the Venue and the Venue is delivered
									as described in an accurate and satisfactory manner. When you
									book the Venue, a contract is formed directly between you and
									the Supplier for the reservation, and you agree to abide by
									all terms and conditions of the Supplier. We are not a party
									to the contracts between Providers and Suppliers. If the
									Supplier’s terms and conditions conflict with these Terms,
									these Terms apply with respect to your relationship with
									Ce.Liya.
								</Col>
							</Row>
							<br />
							2. ACCESSING THE WEBSITE. In order to access the Website, you must
							obtain access to the World Wide Web through devices that access
							web-based content and pay any service fees associated with such
							access. We reserve the right to modify, suspend or discontinue,
							whether temporarily or permanently, the Website (or any part
							thereof) for any reason and without notice. You agree that Company
							shall not be liable to you or to any third party for any
							modification, interruption, suspension, or discontinuance of the
							Website. We are not responsible for delay or failure of our
							performance of any of the features of the Website caused by events
							beyond our reasonable control, like an act of war, hostility, or
							sabotage; natural disaster; electrical, internet, or
							telecommunication outage; or government restrictions.
							<br />
							<br />
							3. PRIVACY POLICY. See Company’s Privacy Policy at [LINK TO
							PRIVACY POLICY] for information and notices concerning Company’s
							use of your personal information. As a Provider, you represent,
							warrant and agree that (a) you will at all times comply with all
							applicable local, state, provincial, national and other laws,
							rules and regulations with respect to information you collect from
							(or receive about) Participants, and (b) you will at all times
							comply with any applicable policies posted on the Website with
							respect to information you collect from Participants.
							<br />
							<br />
							4. MEMBERSHIP. To enjoy full access to the Website, you need to
							sign up for a yearly membership (the “Membership”). The services,
							features and functionalities available to you are determined by
							the subscription tier and specific terms agreed to as stated on
							the Website.
							<br />
							<br />
							&nbsp;&nbsp; We reserve the right to modify, terminate or
							otherwise amend our offered options, pricing and plans at any time
							in our discretion. From time to time, we may permit
							non-subscribers to access certain Services, content or features
							for a cost or at no cost. To enjoy full access to the Website, you
							need to sign up for a Membership. Memberships starts on the date
							that you sign up and submit payment. Each billing cycle is one (1)
							year in length (a "Membership Cycle"). Your Membership
							automatically renews each year, and we will automatically bill the
							Membership fee to your Payment Method (as defined herein) each
							year, until your Membership is cancelled or terminated as provided
							for below. For example, if you purchase your Membership on
							September 5, 2021 your Membership will automatically renew on
							September 5, 2022 (as further explained in "Membership Cycles,"
							below). You must provide us with a current, valid, accepted method
							of payment ("Payment Method"). We may update the accepted methods
							from time to times. If you upgrade or downgrade to a different
							membership, all such memberships will be governed by these Terms
							and will continue indefinitely until canceled or terminated.
							<br />
							<br />
							Ce.Liya does not guarantee the amount of Participants that will
							sign up for a CE Event or the availability of Venues, or other
							aspects of the Services (including during the course of any given
							Membership Cycle).
							<br />
							<br />
							5. INTELLECTUAL PROPERTY. Unless explicitly stated otherwise,
							Company owns all rights, titles, and interest in and to the
							Website and Services, including, without limitation, Website
							content, organization, graphics, design, artwork, compilation, and
							other matters related to or included on the Website and Services.
							Company name and all related names, logos, product and service
							names, designs, artwork and slogans are Company’s trademarks, and
							you must not use these marks without our prior written permission.
							All other names, logos, product and service names, designs and
							slogans on the Website are the trademarks of their respective
							owners and should not be used without those respective owners’
							permission. Any unauthorized use, dissemination, reproduction, or
							copying of these terms or any content on the Website or Services
							will be prosecuted to the fullest extent of the law.
							<br />
							<br />
							6. FOR LAWFUL PURPOSES. You agree to use the Website for
							legitimate, non-commercial purposes only. You shall not post or
							transmit through the Website any material which violates or
							infringes our rights, or the rights of others, or which is
							threatening, abusive, defamatory, libelous, invasive of privacy or
							publicity rights, vulgar, obscene, profane, or otherwise
							objectionable, contains injurious formulas, recipes, or
							instructions, which encourages conduct that would constitute a
							criminal offense, give rise to civil liability or otherwise
							violate any law.
							<br />
							<br />
							7. NO USERS UNDER 18 YEARS OLD. The Website and Services are only
							for users of the age of 18. If you are under the age of 18, please
							do not attempt to register with us at this Website or provide any
							personal information about yourself to us.
							<br />
							<br />
							8. FOR EDUCATIONAL AND INFORMATIONAL PURPOSES ONLY. All of the
							information provided throughout the Website or in our Services
							(including the digital content delivered via email, blog,
							podcasts, live and prerecorded events, on social media, through
							webinars and other content, whether available for purchase or not)
							are resources for educational and informational purposes only. The
							information contained on the Website are not a substitute for
							personalized advice and shall not be understood or construed as
							legal, financial, tax, medical, health, or any other professional
							advice.
							<br />
							<br />
							9. SERVICES DESCRIPTION. While we try to be as clear as possible
							in explaining the Services, we do not guarantee the description as
							entirely accurate, current, or error-free. From time to time we
							may correct errors in pricing and descriptions. We reserve the
							right to discontinue any of the Services at any time for any
							reason. All Services are subject to availability. We reserve the
							right to refuse or cancel any order with an incorrect price
							listing. Prices of all Services are subject to change.
							<br />
							<br />
							10. USER ACCOUNTS. You agree that any such information you give to
							the Company will always be accurate, correct and up-to-date. You
							agree that any usernames or passwords, may only be used by you.
							You agree to keep your password confidential and will be
							responsible for all use of your account and password. We reserve
							the right to remove, reclaim, or change a username you select if
							we determine, in our sole discretion, that such username is
							inappropriate, obscene, or otherwise objectionable. We reserve the
							right to terminate your user account in our sole discretion.
							<br />
							<br />
							11. PAYMENTS.
							<br />
							<Row gutter={24}>
								<Col md={23} offset={1}>
									a. In consideration for your purchase of Services, you agree
									to pay the price associated with that specific product or
									service on the Website. From time-to-time, Company may offer
									limited promotions through its email list or through other
									means. Those promotion rates are not guaranteed, and you
									understand that you are not entitled to any discounted prices.
									<br />
									<br />
									b. You understand and agree that any information provided by
									you is true and accurate, to be relied upon by us and our team
									in processing payment and delivering our Services to you.
									Should your payment fail to process, we reserve the right to
									withhold the purchased Services from you unless and until
									payment is properly rendered. Sales tax will be added to the
									price of purchases as deemed required by us. We may change
									prices at any time. You agree to pay all charges at the prices
									then in effect for your purchases and any applicable shipping
									fees, and you authorize us to charge your chosen payment
									provider for any such amounts upon placing your order. All
									payments shall be in U.S. dollars.
									<br />
									<br />
									c. By initiating a membership your order is subject to
									recurring charges, as such you authorize us to charging your
									Payment Method on a recurring basis without requiring your
									prior approval for each recurring charge, until such time as
									you cancel the membership. We reserve the right to correct any
									errors or mistakes in pricing, even if we have already
									requested or received payment. Note that even if you do not
									use the membership or access the Website and/or events, you
									will be responsible for membership fees until you cancel your
									membership, or it is otherwise terminated as provided for in
									these Terms.
									<br />
									<br />
									d. Membership Cycle. When you sign up and purchase your
									Membership your first Membership cycle will be billed
									immediately. Unless we expressly communicate otherwise, your
									Membership will automatically renew each year and you will be
									billed on the same date each year. We reserve the right to
									change the timing of our billing (and if we do, we’ll make
									adjustments to the amounts we charge, as appropriate). In the
									event your paid Membership began on a day not contained in a
									given month, we may bill your Payment Method on a day in the
									applicable month or such other day as we deem appropriate. For
									example, if you started your Membership or became a paying
									member on April 30, 2021, your next payment date is likely to
									be May 1, 2022, and your Payment Method would be billed on
									that date. Your renewal date may change due to changes in your
									Membership.
									<br />
									<br />
									e. Price Changes. We reserve the right to adjust pricing at
									any time. Unless we expressly communicate otherwise, any price
									changes to your Membership will take effect on your next
									billing cycle upon notice communicated through a posting on
									the Website or such other means as we may deem appropriate
									from time to time, such as email. If you do not cancel your
									Membership, you will be deemed to have accepted these new
									fees.
									<br />
									<br />
									f. Payment Methods. You may edit your Payment Method
									information by logging onto our website or mobile app and
									editing it in your account settings. If a payment is not
									successfully settled due to expiration, insufficient funds or
									otherwise, you nonetheless will remain responsible for any
									uncollected amounts and authorize us to continue billing the
									Payment Method or any other payment method you have provided,
									as it may be updated, including in the event you attempt to
									create a new account, reactivate the unsettled account, or
									sign up for a new account. This may result in a change to your
									payment billing dates. If we cannot charge your account, we
									reserve the right, but are not obligated, to terminate your
									access to our Website or any portion thereof.
									<br />
									<br />
									g. Cancellation of Membership. Unless we communicate
									otherwise, you may terminate your yearly Membership at any
									time before your Membership renews by going into your account
									settings on the Website and letting us know you would like to
									cancel. Unless we communicate otherwise, following any
									cancellation you will continue to have access to your
									Membership through the end of your current prepaid Membership
									Cycle. If you cancel your Membership or it is terminated for
									any reason, you will lose access to all events, content,
									credits or features available through the Membership.
									<br />
									<br />
									h. We reserve the right to refuse any order placed through the
									Website, in our sole discretion. We may, in our sole
									discretion, limit or cancel quantities purchased per person,
									per household, or per order. These restrictions may include
									orders placed by or under the same customer account, the same
									payment method, and/or orders that use the same billing or
									shipping address. We reserve the right to limit or prohibit
									orders that, in our sole judgment, appear to be placed by
									dealers, resellers, or distributors.
								</Col>
							</Row>
							<br />
							12. REFUND POLICY.
							<br />
							<Row gutter={24}>
								<Col md={23} offset={1}>
									a. Membership with Ce.Liya. ALL SALES ARE FINAL, AND YOU AGREE
									NO REFUNDS WILL BE PROVIDED. As such, you agree not to raise a
									dispute with your financial institution and/or credit card
									provider for Services you purchased and that we delivered to
									you.
									<br />
									<br />
									b. Venue Cancellation and Rescheduling. Provider understands
									that the purchasing and reserving of a Venue is between
									Provider and Supplier as such Supplier is responsible for
									their individual refund and rescheduling policy and for any
									refund requests by Provider. Provider must contact the
									Supplier for any refund or rescheduling requests for the
									Accommodations. We are not responsible for the policies of the
									Supplier.
								</Col>
							</Row>
							<br />
							13. Provider Refund Policy
							<br />
							<Row gutter={24}>
								<Col md={23} offset={1}>
									a. Minimum Requirements. We understand that refund policies
									will vary depending on the type of CE Event and the Provider,
									as such we provide flexibility for Providers to post their own
									policies with respect to their CE Events, so as long as they
									meet the following requirements: ‘
									<br />
									i. Refund policies must be posted on the CE Event page.
									<br />
									ii. “No Refund” policies are permissible BUT must clearly be
									stated as such on the CE Event page.
									<br />
									iii. In the event Provider is unable to provide the CE Event
									(event cancellation) then Provider must provide a refund or
									other accommodation (event with a “no refund” policy) through
									which the Provider will “make good” on its obligations to
									Participants.
									<br />
									iv. If Provider is offering Participants an event credit or
									other accommodation through which Provider will “make good” on
									its obligations to Participants in lieu of a purchase price
									refund, then:
									<br />
									1. such credit or other accommodation must be of equal or
									greater value to the value of the CE Event ticket; and
									<br />
									2. such value and all terms and restrictions applicable to
									such credit or other accommodation, including but not limited
									to the period in which such credit or other accommodation must
									be redeemed, (if such limitations are permitted by applicable
									law) shall be clearly communicated to Participants.
									<br />
									v. All refund policies (including credit refunds) must comply
									with all applicable local, state, provincial, national and
									other laws, rules and regulations, including all requirements
									imposed by the Merchant Agreement.
									<br />
									vi. All refund policies must include specific instructions on
									how to obtain a refund, applicable contact information, and
									time frame to request a refund.
									<br />
									vii. Refund policies may not be changed retroactively or to
									otherwise impact Participants’ entitlement to relief in
									connection with purchases made prior to the date of such
									change and its posting to the applicable CE Event page, unless
									such changes operate to place impacted Participants in an
									equal or better position as they were under the policy in
									place at the time of purchase. For example, where an
									Participant has posted a policy under which refunds may be
									obtained if requested in a specified method or time period,
									such Provider may not transition to a “No refunds” policy with
									respect to any purchase already made at the time of such
									attempted transition. However, where an Provider has posted a
									“No refunds” policy, such Provider r may transition to a
									policy that allows for refunds with respect to any purchase
									already made as of the time of such transition.
									<br />
									viii. Refund policies must set out a specific time frame
									within which refund requests will be responded to, which
									should not exceed five (5) business days for a first response.
									<br />
									ix. Refund policies must otherwise be fair and reasonable.
									<br />
									<br />
									b. Posting Refund Policy. If a refund policy is not posted or
									does not meet the minimum requirements as set forth above,
									Company may (but has no obligation to) modify such refund
									policy such that it meets these minimum requirements. Such
									modification may take the form of prospectively making changes
									to the Provider’s posted refund policy on the applicable CE
									Event page or retroactively applying such changes at the time
									of a dispute, chargeback and/or refund request.
									<br />
									<br />
									c. Refund Process. In order to initiate a refund request,
									Company instructs Participant to contact the Provider
									directly. Organizer agrees to administer its refund policy in
									accordance with the terms set forth on the applicable CE Event
									page and the minimum requirements set forth above.
									<br />
									<br />
									d. Termination. If Provider does not abide by these refund
									policies, Ce.Liya will have the right to terminate the
									Membership and remove all information about Provider and the
									Events from the Website. In addition Provider will not be
									entitled to refund of the Membership fee.
									<br />
									<br />
									e. No Insurance or Guarantee. This refund policy is not
									intended to be and does not constitute an offer to insure the
									performance or to guarantee the performance of any Provider.
								</Col>
							</Row>
							<br />
							14. CERTIFICATIONS. As part of the Services provided by Company
							you will be able to upload a certificate for Participant’s
							attending the CE Events (“Certification”) as proof of attendance.
							Provider will be required to upload all Certifications within
							forty-eight (48) hours of Participant’s attending the CE Event.
							Participants will be instructed to contact Provider if
							Certifications have not been uploaded.
							<br />
							<br />
							15. LIMITED LICENSE. Your ability to view content on our Website
							grants you a limited, revocable, non-transferrable license to use
							the information available to you for your personal, non-commercial
							use only. Should you decide to purchase Services from our Website,
							the Company grants you a limited, personal, non-exclusive,
							non-transferable license to use them for your own personal and
							internal business use. Except as otherwise provided, you
							acknowledge and agree that you have no right to publish, modify,
							edit, copy, reproduce, create derivative works of, reverse
							engineer, transfer, alter, sell, create derivate works of, enhance
							or in any way exploit any of the products in any manner, except
							for modifications in filling out the products for your authorized
							use. You shall not remove any copyright notice from any of the
							Website or Services. Doing so may infringe on our intellectual
							property rights, as outlined above.
							<br />
							<br />
							16. NO RESALE OF SERVICES OR WEBSITE CONTENT. You agree not to
							reproduce, duplicate, copy, sell, resell, create derivative works,
							or exploit, for any commercial or other purposes, any portion of
							the Services or Website or content or other information or
							materials of any kind that you do not own without our express
							prior written consent. This includes digital products,
							photographs, illustrations, artwork and/or free downloads.
							<br />
							<br />
							17. TRADEMARKS. The trademarks, service marks and logos of Ce.Liya
							(the “Ce.Liya Trademarks”) used and displayed in connection with
							the Services are registered and unregistered trademarks or service
							marks of Ce.Liya. Other company, product and service names used in
							connection with the Services may be trademarks or services marks
							owned by third parties (the “Third Party Trademarks” and
							collectively with Ce.Liya Trademarks, the “Trademarks”) The
							offering of the Services will not be construed as granting, by
							implication, estoppel or otherwise any license or right to use any
							Trademark displayed in connection with the Services without the
							prior written consent of Ce.Liya, specific for each use. The
							Trademark may not be used to disparage Ce.Liya, any third party or
							Ce.Liya’s products or services, in any manner that may damage any
							goodwill in the Trademarks. Use of the Trademarks as part of a
							link to or from any site is prohibited unless Ce.Liya provides
							written approval in each instance. All goodwill generated from the
							use of any Ce.Liya Trademark will inure to Ce.Liya’s benefit. Site
							content may also be protect by copyrights owned by Ce.Liysa and/or
							third parties. Please note that if you copy portions of the
							Services, you are violating these copyrights.
							<br />
							<br />
							18. AFFILIATE DISCLAIMER. Please note this Website may contain
							links to affiliate websites, and we receive an affiliate
							commission, services and/or complimentary products for any
							purchases made by you on the affiliate website using such links.
							<br />
							<br />
							19. TESTIMONIALS DISCLAIMER. The Website may contain testimonials
							by users of our Services. The views and opinions contained in the
							testimonials belong solely to the individual user and do not
							reflect our views and opinions. We are not affiliated with users
							who provide testimonials, and users are not paid or otherwise
							compensated for their testimonials. These testimonials reflect the
							real-life experiences and opinions of such users. However, the
							experiences are personal to those particular users, and may not
							necessarily be representative of all users of our Services. We do
							not claim, and you should not assume, that all users will have the
							same experiences. YOUR INDIVIDUAL RESULTS MAY VARY.
							<br />
							<br />
							20. PERSONAL RESPONSIBILITY Your decision to visit our Website,
							use information contained herein, and purchase Services we offer
							is purely voluntary, and you understand we are not responsible or
							liable for any harm or damage to you or your business, life,
							health, or family resulting from direct or indirect use of
							materials or content contained on our Website. You agree to hold
							Company harmless from any damages directly or indirectly resulting
							from your use of content or Services on our Website or distributed
							through email, and agree you will not make any claims against
							Company herein.
							<br />
							<br />
							21. NO ENDORSEMENTS. We may provide links to other websites or
							resources, which are not maintained by or related to us. We have
							no control over these websites or resources and their content, and
							makes no representations or warranties about the content,
							completeness, quality or accuracy of the links, goods, services,
							materials or information contained on any such website. Therefore,
							you acknowledge and agree that the Company is not responsible for
							the availability of such links, resources and content, and is not
							responsible or liable for, any content, advertising, products,
							services, or other materials made available on or from these
							linked websites. You also acknowledge and agree that the Company
							is not responsible or liable, directly or indirectly, for any
							damage or loss caused by or alleged to have been caused by, or in
							relation to, the use of any content, goods or services offered
							through these links or any failures and/or disruption to your
							computer system that results from your use of any such links, or
							for any intellectual property or other third party claims relating
							to your posting or using such links.
							<br />
							<br />
							22. THIRD PARTIES CONNECTING TO OUR WEBSITE. Company is not
							responsible for the content or practices of third-party websites
							that may be linking to our Website and Company makes no
							representation or warranty regarding such third parties, their
							websites, their content, or their goods and services.
							<br />
							<br />
							23. SHARED INFORMATION NOT CONFIDENTIAL OR PRIVILEGED. Information
							you provide or share with us directly or indirectly, on our
							Website, (whether in the form of emails, text messages, comments,
							audio calls or video calls) will not be treated as confidential or
							privileged, as they may be broadly available to other persons,
							both inside of and/or outside of the Company. Also, any expressed
							opinion by another user is his or her own and should not be
							considered as reflecting the opinion of the Company.
							<br />
							<br />
							24. FEEDBACK, COMMENTS AND TESTIMONIALS. With your prior
							permission, you agree that the Company has the right to use your
							feedback whether in the form of emails, submissions, surveys,
							comments, calls, otherwise, for the purposes of marketing or
							promoting the Company’s services and/or products.
							<br />
							<br />
							25. ERRORS, INACCURACIES, AND OMISSIONS. Information provided
							about the Website and/or Services is subject to change. Company
							makes no representation or warranty that the information provided,
							regardless of its source, is accurate, complete, reliable, current
							or error-free. Company disclaims all liability for any inaccuracy,
							error or incompleteness in the Website.
							<br />
							<br />
							26. NO GUARANTEE. The Company may share the successful results,
							testimonials or reviews of the Company, its users, or customers on
							the Website. Such examples are not to be interpreted as a promise
							or guarantee, verbally or in writing, regarding your results,
							future earnings, business profit, marketing performance, audience
							growth, or results of any kind. By accessing the Website and using
							our Services you accept, agree and understand that you are fully
							responsible for your progress and results from your participation.
							We do not guarantee that you will get any results using any of our
							ideas, tools, strategies or recommendations, and nothing in our
							Website and/or Services is a promise, warranty or guarantee to you
							of such results.
							<br />
							<br />
							27. NO WARRANTIES
							<br />
							<Row gutter={24}>
								<Col md={23} offset={1}>
									a. The use of the Website and Services are at your sole risk.
									The Website and Services are provided on an "as is" and "as
									available" basis. We make no representations or warranties
									about the suitability, reliability, availability, timeliness,
									security, lack of errors, or accuracy of the Website and
									Services. COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
									KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
									TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
									PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. THE COMPANY
									MAKES NO WARRANTY THAT (1) THE WEBSITE AND SERVICES WILL MEET
									YOUR REQUIREMENTS, (2) THE WEBSITE AND SERVICES WILL BE
									UNINTERRUPTED, TIMELY SECURE, OR ERROR-FREE, (3) THE RESULTS
									OF USING THE WEBSITE AND SERVICES WILL BE ACCURATE OR
									RELIABLE, (4) THE QUALITY OF ANY PRODUCTS, SERVICES,
									INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED THROUGH
									THE WEBSITE WILL MEET YOUR EXPECTATIONS, OR (5) THAT THE
									WEBSITE AND SERVICES WILL BE ERROR-FREE AND/OR ANY ERRORS ON
									THE WEBSITE AND SERVICES WILL BE CORRECTED. No advice or
									information, whether oral or written, obtained by you from us
									through or from the Website shall create a warranty not
									expressly stated in these Terms. Some jurisdictions do not
									allow the disclaimer of implied warranties and/or limitations
									of liability, so a portion of the foregoing may not apply to
									you. In such a case, any such disclaimer or limitation of
									liability is limited to the minimum extent permissible under
									applicable law.
									<br />
									<br />
									b. You acknowledge that Ce.Liya does not guarantee the
									quality, safety, accuracy or legality of any Venue or the
									truth or accuracy have any information provided by Participant
									or Venue. We have no responsibility to you for, and hereby
									disclaim all liability arising from, the acts or omission of
									any Participant or Venue.
								</Col>
							</Row>
							<br />
							28. RELEASE. You hereby agree to release Ce.Liya from all damages
							whether direct, indirect, incidental, consequential or otherwise
							losses, liabilities, costs and expenses of every kind in nature,
							known an unknown, arising out of a dispute between you and a third
							party including other Participants in connection with the Services
							or any CE Events listed on the Services in addition you waive any
							applicable law or statute which says in substance, “A GENERAL
							RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE RELEASING PARTY DOES
							NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING
							THE RELEASE WHICH IS KNOWN BY HIM MUST HAVE MUST HAVE MATERIALLY
							AFFECTED A SETTLEMENT WITH THE RELEASE PARTY.”
							<br />
							<br />
							29. LIMITATION OF LIABILITY. You agree to absolve the Company of
							any and all liability or loss that you or any person or entity
							associated with you may suffer or incur as a result of use of the
							Services and/or any information and resources contained in the
							Website. You agree that the Company shall not be liable to you for
							any type of damages, including direct, indirect, special,
							incidental, equitable, or consequential loss or damages for use of
							the Website and/or Services. Our liability to you or any third
							parties under any circumstance is limited to the the amount you
							have paid us in the three (3) months prior to the event giving
							rise to your claims.
							<br />
							<br />
							30. INDEMNIFICATION. You agree to indemnify, defend, and hold
							harmless the Company, its officers, directors, employees, agents,
							licensors, and third parties for any damages (whether direct,
							indirect, incidental, consequential or otherwise), losses, costs,
							liabilities, and expenses (including reasonable attorney and
							accounting fees) resulting from any claim, demand, suit, preceding
							(whether before an arbitrator court mediator otherwise) or
							investigation made by any third party (each a “Claim”) relating to
							or arising out of: (a) your breach of these Terms (including any
							agreements or policies incorporated into these Terms); (b) your
							use of the Services in violation of these Terms or other policies
							we post or make available; (c) your breach of any applicable
							local, state, provincial, national, or other law, rule or
							regulation or the rights of any third party; (d) Ce.Liya’s
							collection and remission of taxes; and Provider’s CE Events
							(including where Ce.Liya has provided Services with respect to
							those CE Events. The Company reserves the right, at its own cost,
							to assume the exclusive defense and control of any matter
							otherwise subject to indemnification by you, in which event you
							will fully cooperate with the Company in asserting any available
							defenses.
							<br />
							<br />
							31. REFUSAL OF SERVICE. We reserve the right to refuse access to
							the Website to any person or entity, without the obligation to
							assign a reason for doing so. We reserve the right to immediately
							remove you from the Website, if you violate the Terms.
							<br />
							<br />
							32. INDEPENDENT CONTRACTOR. Nothing in this Agreement shall be
							construed to create a partnership, joint venture, employment, or
							agency relationship. The Company is agreeing only to provide
							Client with access to the Services, which may include education
							and information. The information contained in the Services
							including any interactions with the instructors, is not intended
							as, and shall not be understood or construed as, professional
							advice.
							<br />
							<br />
							33. TERMINATION. The Company reserves the right, in its sole
							discretion, to terminate your access to the Website and/or
							Services or any portion thereof at any time, without notice.
							<br />
							<br />
							34. DISPUTE RESOLUTION Should any dispute arise, you agree to
							resolve any claim or controversy arising out of or relating to
							these Terms and Conditions by Mediation in Mesa, Arizona by the
							American Arbitration Association, Dispute Resolution Services, and
							agree to be bound by the decision(s) of the selected mediator. Any
							costs and fees associated with the mediation, other than attorney
							fees, will be shared equally by the both of us. If the dispute is
							not resolved within thirty (30) calendar days after it is referred
							to the mediator, it shall be settled exclusively by submitting to
							binding arbitration in Mesa, Arizona. party electing arbitration
							must initiate proceedings by filing an arbitration demand with the
							American Arbitration Association (AAA). Judgment upon the award
							rendered by the arbitrator may be entered in any court having
							jurisdiction to do so. Costs of arbitration, including attorney
							fees, will be allocated by the arbitrator.
							<br />
							<br />
							35. NO CLASS ACTIONS. YOU AND CE.LIYA AGREE THAT EACH MAY BRING
							CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY,
							AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,
							CONSOLIDATED OR REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY NOT
							CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, MAY NOT OVER ANY FORM
							OF CLASS, CONSOLIDATED OR REPRESENTATIVE PROCEEDING AND MAY ONLY
							PROVIDE RELIEF IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND
							ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEVE WARRANTED BY THAT
							PARTY’S INDIVIDUAL CLAIM.
							<br />
							<br />
							36. EFFECT OF HEADINGS. The subject headings of the paragraphs and
							subparagraphs of the Terms are included for convenience only and
							shall not affect the construction or interpretation of any of its
							provisions.
							<br />
							<br />
							37. WAIVER. Company’s waiver of any of the provisions of these
							Terms shall not be deemed, nor shall constitute, a waiver of any
							other provision, whether or not similar, nor shall any waiver
							constitute a continuing waiver. No waiver shall be binding unless
							executed in writing by an authorized representative of the
							Company.
							<br />
							<br />
							38. SEVERABILITY. If any term or provision of this Agreement is
							found by a court of competent jurisdiction to be invalid, illegal,
							or unenforceable in any jurisdiction, such invalidity, illegality
							or unenforceability shall not affect any other term or provision
							of this Agreement or invalidate or render unenforceable such term
							or provision in any other jurisdiction.
							<br />
							<br />
							39. ASSIGNMENT. You may not assign these Terms without the express
							written consent of Company.
							<br />
							<br />
							40. GOVERNING LAW. The Terms shall be governed by the laws of the
							State of Arizona
							<br />
							<br />
							41. CHANGES TO TERMS. The Company reserves the right, in its sole
							discretion, to change the Terms under which the Website is
							offered. The most current version of the Terms will supersede all
							previous versions. The Company encourages you to periodically
							review the Terms to stay informed of our updates.
							<br />
							<br />
							42. HOW TO CONTACT US. Please contact us at info@celiya.com with
							any question or comments regarding the Terms.
						</p>
					</Col>
				</Row>

				{/* ---------------------- */}
			</Card>
		</Layout>
	);
};

export default ComponentTermsProviders;
