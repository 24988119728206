import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Layout,
  Typography,
  Input,
  DatePicker,
  Row,
  Col,
  Badge,
  Divider,
  Menu,
  Button,
  Alert,
  Space,
  Collapse,
  Tag,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
  HomeOutlined,
  CalendarOutlined,
  EyeOutlined,
  UpOutlined,
  DownOutlined,
  FilterOutlined,
} from "@ant-design/icons";
// import ModalContiueFill from "./Modals/ModalContiueFill";
import moment from "moment";
import { MdRefresh } from "react-icons/md";
import image1 from "../../../../assets/img/image1.jpg";
import image2 from "../../../../assets/img/image2.jpg";
import image3 from "../../../../assets/img/image3.jpg";

import { Pie } from "@ant-design/plots";

import getUserData from "../../../../providers/getUserData";
import { GET } from "../../../../providers/useAxiosQuery";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import companyInfo from "../../../../providers/companyInfo";
import { useHistory } from "react-router-dom";

import ModalPreview from "./Modals/ModalPreview";
import { Link } from "react-router-dom";
import $ from "jquery";
import states from "states-us";
import FloatSelectDashboard from "../../../../providers/FloatSelectDashboard";
import FloatSelectDashboard1 from "../../../../providers/FloatSelectDashboard1";
import FloatInputDasboard2 from "../../../../providers/FloatInputDasboard2";
import FloatInputDasboard from "../../../../providers/FloatInputDasboard";
import FloatInputDasboard3 from "../../../../providers/FloatInputDasboard3";
import InfiniteScroll from "react-infinite-scroll-component";

export default function PageMemberDashboard({ props, permission }) {
  const userdata = getUserData();
  const sub_title = "All Features";
  const { Search } = Input;
  const { Meta } = Card;
  const history = useHistory();
  const { Panel } = Collapse;
  const getChartConfig = (type) => {
    return {
      height: 150,
      appendPadding: 0,
      angleField: "value",
      colorField: "type",
      // color: ['#293a56', '#d6ae71', '#000000'],
      color: ({ type }) => {
        let text = type;
        let result = text.includes("Not Completed");
        if (result) {
          return "#d6ae71";
        }
        return "#293a56";
      },
      label: {
        type: "inner",
        offset: "-50%",
        // content: "{value}",
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        autoRotate: false,
        position: "bottom",
        style: {
          textAlign: "center",
          fontSize: 10,
        },
      },
      // legend: {
      //   layout: "horizontal",
      //   position: "bottom",
      // },
      legend: false,
      interactions: [{ type: "element-selected" }, { type: "element-active" }],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          formatter: function formatter() {
            return type;
          },
        },
      },
    };
  };

  useEffect(() => {
    // console.log(userdata)
  }, []);

  const { data: dataUser, isLoading: isLoadingUser } = GET(
    `api/v1/get_by_id?id=${userdata.id}`,
    "get_by_id",
    (res) => {
      if (res.success) {
        // console.log("res", res);
        if (res.data[0].business_categories == null) {
          history.push("/member/dashboard");
        }
      }
    }
  );

  const [showMonthView, setShowMonthView] = useState(false);
  const handleshowMonth = () => {
    setShowMonthView(!showMonthView);
  };

  const [dataEventCalendar, setDataEventCalendar] = useState([]);
  const [stateData, setStateData] = useState({
    // user_id: userdata.id,
    state: "",
    speaker: "",
    company: "",
    event_name: "",
    categories: [],
    page_size: 9,
    page_number: 1,
  });

  const {
    data: dataEvent,
    isLoading: isLoadingEvent,
    refetch: refetchEvent,
  } = GET(
    `api/v1/memberAllEventDashboardCard?${$.param(stateData)}`,
    "member_all_event_dashboard_card",
    (res) => {
      console.log("dash", res);
    }
  );

  const {
    data: dataEventSchedule,
    isLoading: isLoadingEventSchedule,
    refetch: refetchEventSchedule,
  } = GET(
    `api/v1/memberAllEventDashboardCalendar?${$.param(stateData)}`,
    "member_all_event_dashboard_calendar",
    (res) => {
      if (res.success) {
        console.log("event_dashboard_calendar", res);
        let dataCalendar = [];
        res.data.data.map((row, index) => {
          var back_color = "#23bf08";
          if (row.is_attended) {
            back_color = "#1890ff";
          } else {
            if (row.is_waiting) {
              back_color = "#293a56";
            } else {
              if (row.is_registered) {
                back_color = "#d6ae71";
                // console.log("awdawd");
              } else {
                if (row.is_sold_out) {
                  back_color = "#800000";
                } else {
                  if (row.is_beyond_rsvp) {
                    back_color = "#293a56";
                  }
                }
              }
            }
          }

          if (row.is_past_date) {
            back_color = "#d9d9d9";
          }

          dataCalendar.push({
            title: row.event_details.title,
            // date: moment(row.datetime_start).format("YYYY-MM-DD"),
            start: row.datetime_start,
            end: row.datetime_end,
            id: row.event_id,
            extendedProps: row,
            backgroundColor: "#eeeeee",
            borderColor: "#eeeeee",
            circleDot: back_color,
          });
        });
        // console.log("dataCalendar", dataCalendar);
        setDataEventCalendar(dataCalendar);
      }
    }
  );

  const [previewShow, setPreviewShow] = useState(false);
  const [id, setID] = useState(0);
  const [isAttended, setIsAttended] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isByond, setIsBeyond] = useState(false);
  const [isMyCategories, setIsMyCategories] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  const showModal = (e) => {
    // console.log(e);
    setIsAttended(e.is_attended && e.is_attended);
    setIsRegistered(e.is_registered && e.is_registered);
    setIsSoldOut(e.is_sold_out && e.is_sold_out);
    setIsWaiting(e.is_waiting && e.is_waiting);
    setIsBeyond(e.is_beyond_rsvp && e.is_beyond_rsvp);
    setIsMyCategories(e.is_my_category && e.is_my_category);
    setID(e.id);
    setPreviewShow(true);
  };

  const handleClick = (e) => {
    // console.log(e.event.extendedProps);
    setIsPastDate(
      e.event.extendedProps.is_past_date && e.event.extendedProps.is_past_date
    );
    setIsAttended(
      e.event.extendedProps.is_attended && e.event.extendedProps.is_attended
    );
    setIsRegistered(
      e.event.extendedProps.is_registered && e.event.extendedProps.is_registered
    );
    setIsSoldOut(
      e.event.extendedProps.is_sold_out && e.event.extendedProps.is_sold_out
    );
    setIsWaiting(
      e.event.extendedProps.is_waiting && e.event.extendedProps.is_waiting
    );
    setIsBeyond(
      e.event.extendedProps.is_beyond_rsvp &&
        e.event.extendedProps.is_beyond_rsvp
    );
    setIsMyCategories(
      e.event.extendedProps.is_my_category &&
        e.event.extendedProps.is_my_category
    );
    setID(e.event.id);
    setPreviewShow(true);
  };

  const [myHours, setMyHours] = useState([]);
  const [myUpcommingEvents, setMyUpcommingEvent] = useState([]);
  const [ceProfiles, setCeProfiles] = useState([]);
  const [showDefaultView, setShowDefaultView] = useState(true);
  const { data: dataGetCurrentHours, isLoading: isDataCurrentHours } = GET(
    `api/v1/member_ce_hours_profile/my_hours?user_id=${userdata.id}`,
    "member_my_ce_hours",
    (res) => {
      if (res.success) {
        console.log("hours", res.registered);
        setCeProfiles(res.data);
        setMyUpcommingEvent(res.registered);
      }
    }
  );
  const [click, setClick] = useState("");
  const [state, setState] = useState([]);
  useEffect(() => {
    let arr = [];
    states.map((row, key) => {
      if (
        row.name != "Federated States Of Micronesia" &&
        row.name != "Marshall Islands" &&
        row.name != "Palau" &&
        row.name != "Northern Mariana Islands"
      ) {
        arr.push({
          value: row.abbreviation,
          label: row.name,
        });
      }
    });
    setState(arr);
  }, []);

  const selectState = (value, option) => {
    setStateData({ ...stateData, state: value });
  };

  const selectCategories = (value) => {
    setStateData({ ...stateData, categories: value });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetchEvent();
      refetchEventSchedule();
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [stateData]);

  const [CLICKBTN, setCLICKBTN] = useState(false);
  const handleClear = () => {
    setClick("");
    setStateData({
      ...stateData,
      state: "",
      speaker: "",
      company: "",
      event_name: "",
      categories: [],
    });

    setCLICKBTN(!CLICKBTN);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetchEvent();
      refetchEventSchedule();
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [CLICKBTN]);

  let categories_sel = [
    {
      label: "Dental Student",
      value: "Dental Student",
    },
    {
      label: "Dental Assistants",
      value: "Dental Assistants",
    },
    {
      label: "Front Office",
      value: "Front Office",
    },
    {
      label: "Office Manager",
      value: "Office Manager",
    },
    {
      label: "Office Coordinator",
      value: "Office Coordinator",
    },
    {
      label: "Dental Practice Owner",
      value: "Dental Practice Owner",
    },
    {
      label: "Retired Dentist",
      value: "Retired Dentist",
    },
    {
      label: "Denturist",
      value: "Denturist",
    },
    {
      label: "Dental Hygienist",
      value: "Dental Hygienist",
    },
    {
      label: "Dental Health Aid Therapist (DHAT)",
      value: "Dental Health Aid Therapist (DHAT)",
    },
    {
      label: "Dental Professor/Instructor",
      value: "Dental Professor/Instructor",
    },
    {
      label: "Dental Lab Techs",
      value: "Dental Lab Techs",
    },
    {
      label: "Dentists",
      value: "Dentists",
    },
    {
      label: "Dental Specialist",
      value: "Dental Specialist",
    },
    {
      label: "Endodontist",
      value: "Endodontist",
    },
    {
      label: "Oral Surgeon",
      value: "Oral Surgeon",
    },
    {
      label: "Periodontist",
      value: "Periodontist",
    },
    {
      label: "Pediatric Dentist",
      value: "Pediatric Dentist",
    },
    {
      label: "Prosthodontist",
      value: "Prosthodontist",
    },
    {
      label: "Dental Anesthesiologist",
      value: "Dental Anesthesiologist",
    },
    {
      label: "Dental Public Health",
      value: "Dental Public Health",
    },
    {
      label: "Oral Maxillofacial Pathologist",
      value: "Oral Maxillofacial Pathologist",
    },
    {
      label: "Oral Maxillofacial Radiologist",
      value: "Oral Maxillofacial Radiologist",
    },
    {
      label: "Oral Medicine",
      value: "Oral Medicine",
    },
    {
      label: "Oral Facial Pain Specialist",
      value: "Oral Facial Pain Specialist",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  const sortCat = (business_category) => {
    return business_category.sort((a, b) => a.label.localeCompare(b.label));
  };

  function renderEventContent(eventInfo) {
    // console.log(eventInfo);
    return (
      <>
        {/* <Row>
          <Col
            xs={2}
            md={2}
            style={{
              display:
                eventInfo.event.extendedProps.is_waiting == true ||
                eventInfo.event.extendedProps.is_beyond_rsvp == true ||
                eventInfo.event.extendedProps.is_past_date == true
                  ? "none"
                  : "block",
            }}
          >
            <div
              className="circleDot"
              style={{
                width: "8px",
                height: "8px",
                background: eventInfo.backgroundColor,
                borderRadius: "50%",
                marginLeft: 2,
                marginTop: 5,
              }}
            ></div>
          </Col>
          <Col xs={22} md={22}>
            <b
              style={{ color: "black", whiteSpace: "break-spaces" }}
              className="calendarLittleDes2"
            >
              {eventInfo.event.title}
            </b>
          </Col>
        </Row> */}
        <Space
          className={
            eventInfo.event.extendedProps.is_waiting == true ||
            eventInfo.event.extendedProps.is_past_date == true
              ? "spaceDashnoGap"
              : "spaceDash"
          }
        >
          <div
            style={{
              display:
                eventInfo.event.extendedProps.is_waiting == true ||
                eventInfo.event.extendedProps.is_past_date == true
                  ? "none"
                  : "block",
            }}
          >
            <div
              className="circleDot"
              style={{
                width: "8px",
                height: "8px",
                background: eventInfo.event.extendedProps.circleDot,
                borderRadius: "50%",
                marginLeft: 2,
              }}
            ></div>
          </div>
          <div>
            <b
              style={{
                color: "black",
                whiteSpace: "break-spaces",
              }}
              className="calendarLittleDes2"
            >
              {eventInfo.event.title}
            </b>
          </div>
        </Space>

        <div
          className="calendarLittleDes"
          style={{
            fontSize: "10px",
            color: "black",
            whiteSpace: "break-spaces",
          }}
        >
          {eventInfo.event.extendedProps.my_event.event_type == "Live In-Person"
            ? "In-Person"
            : eventInfo.event.extendedProps.my_event.event_type}
        </div>
        <div
          className="calendarLittleDes1"
          style={{
            fontSize: "10px",
            color: "gray",
            whiteSpace: "break-spaces",
          }}
        >
          {moment(eventInfo.event.extendedProps.datetime_start).format(
            "MMMM Do h:mma"
          ) +
            " - " +
            moment(eventInfo.event.extendedProps.datetime_end).format("h:mma")}
        </div>
      </>
    );
  }
  const changeView = (e) => {
    calendar.current
      .getApi()
      .changeView("timeGridDay", e.event.extendedProps.datetime_start);
  };

  const calendar = useRef();

  $(".fc-daygrid-day-number").on("click", function () {
    var a = $(this).attr("aria-label");
    var _a = moment(a).format("YYYY-MM-DD");
    calendar.current.getApi().changeView("timeGridDay", _a);
  });

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageAllEvents"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<HomeOutlined />}
      />
      <Layout.Content
        // title="Dashboard"
        style={{ padding: "30px", paddingTop: "0px" }}
      >
        <Row gutter={24}>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
            <br />
            <Button
              className="btn-login-outline"
              onClick={() => handleshowMonth()}
              icon={<CalendarOutlined />}
            >
              {!showMonthView ? "Calendar View" : "Posterboard View"}
            </Button>

            <Divider style={{ background: "#293a56", height: "2px" }} />
            <Row gutter={4} className="filtersandlegend">
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={14}
                xl={14}
                xxl={14}
                className="filtersCol"
              >
                <Space style={{ display: "flex", flexWrap: "wrap" }}>
                  <Button
                    type="link"
                    className="btn-text-login"
                    onClick={(e) => {
                      setClick("Categories");
                    }}
                  >
                    Categories
                  </Button>
                  <Button
                    type="link"
                    className="btn-text-login"
                    onClick={(e) => {
                      setClick("State");
                    }}
                  >
                    State
                  </Button>
                  <Button
                    type="link"
                    className="btn-text-login"
                    onClick={(e) => {
                      setClick("Speaker");
                    }}
                  >
                    Speaker
                  </Button>
                  <Button
                    type="link"
                    className="btn-text-login"
                    onClick={(e) => {
                      setClick("Company");
                    }}
                  >
                    Company
                  </Button>
                  <Button
                    type="link"
                    className="btn-text-login"
                    onClick={(e) => {
                      setClick("Event Name");
                    }}
                  >
                    Event Name
                  </Button>

                  <div
                    onClick={(e) => handleClear()}
                    className="c-lightorange refreshDashFilter"
                  >
                    <MdRefresh />
                  </div>
                </Space>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                className="legendsCol"
              >
                {/* <div style={{ fontSize: 25 }} className="c-darkgray">
                  Legend
                </div> */}

                <Space
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="legendsPos"
                >
                  <Space>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: "#23bf08",
                        borderRadius: "50%",
                      }}
                    ></div>
                    Open
                  </Space>
                  <Space>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: "#d6ae71",
                        borderRadius: "50%",
                      }}
                    ></div>
                    Registered
                  </Space>

                  <Space>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: "#1890ff",
                        borderRadius: "50%",
                      }}
                    ></div>
                    Attended
                  </Space>
                  <Space>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: "#800000",
                        borderRadius: "50%",
                      }}
                    ></div>
                    Sold Out
                  </Space>
                  <Space>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: "#293a56",
                        borderRadius: "50%",
                      }}
                    ></div>
                    RSVP Closed
                  </Space>
                </Space>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <div style={{ marginBottom: 5, marginTop: 5, display: "flex" }}>
                  <Tag
                    closable
                    visible={stateData.categories.length != 0 ? true : false}
                    onClose={() => {
                      setStateData({ ...stateData, categories: [] });
                    }}
                    color={"#d6ae71"}
                  >
                    Categories: {stateData.categories.join(", ")}
                  </Tag>
                  <Tag
                    closable
                    visible={stateData.state ? true : false}
                    onClose={() => {
                      setStateData({ ...stateData, state: "" });
                    }}
                    color={"#d6ae71"}
                  >
                    State: {stateData.state}
                  </Tag>
                  <Tag
                    closable
                    visible={stateData.speaker ? true : false}
                    onClose={() => {
                      setStateData({ ...stateData, speaker: "" });
                    }}
                    color={"#d6ae71"}
                  >
                    Speaker: {stateData.speaker}
                  </Tag>
                  <Tag
                    closable
                    visible={stateData.company ? true : false}
                    onClose={() => {
                      setStateData({ ...stateData, company: "" });
                    }}
                    color={"#d6ae71"}
                  >
                    Company: {stateData.company}
                  </Tag>
                  <Tag
                    closable
                    visible={stateData.event_name ? true : false}
                    onClose={() => {
                      setStateData({ ...stateData, event_name: "" });
                    }}
                    color={"#d6ae71"}
                  >
                    Event Name: {stateData.event_name}
                  </Tag>
                </div>
                {click === "Categories" && (
                  <div
                    style={{ marginBottom: "10px" }}
                    className="floatSelectDashboard"
                  >
                    <FloatSelectDashboard1
                      label="Categories"
                      placeholder="Categories"
                      options={sortCat(categories_sel)}
                      value={stateData.categories}
                      onChange={selectCategories}
                      multi="multiple"
                    />
                  </div>
                )}
                {click === "State" && (
                  <div
                    style={{ marginBottom: "10px" }}
                    className="floatSelectDashboard"
                  >
                    <FloatSelectDashboard
                      label="State"
                      placeholder="State"
                      options={state}
                      value={stateData.state}
                      onChange={selectState}
                    />
                  </div>
                )}

                {click === "Speaker" && (
                  <div
                    style={{ marginBottom: "10px" }}
                    className="floatSelectDashboard"
                  >
                    <FloatInputDasboard2
                      label="Speaker"
                      placeholder="Speaker"
                      name="spaker"
                      state={stateData}
                      value={stateData.speaker}
                      onChange={setStateData}
                    />
                  </div>
                )}
                {click === "Company" && (
                  <div
                    style={{ marginBottom: "10px" }}
                    className="floatSelectDashboard"
                  >
                    <FloatInputDasboard
                      label="Company"
                      placeholder="Company"
                      name="company"
                      state={stateData}
                      value={stateData.company}
                      onChange={setStateData}
                    />
                  </div>
                )}
                {click === "Event Name" && (
                  <div
                    style={{ marginBottom: "10px" }}
                    className="floatSelectDashboard"
                  >
                    <FloatInputDasboard3
                      label="Event Name"
                      placeholder="Event Name"
                      name="event_name"
                      state={stateData}
                      value={stateData.event_name}
                      onChange={setStateData}
                    />
                  </div>
                )}
              </Col>
            </Row>

            {showMonthView && (
              <FullCalendar
                defaultView="dayGridMonth"
                headerToolbar={{
                  left: "prev,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={dataEventCalendar}
                eventClick={(e) => handleClick(e)}
                datesSet={(dateInfo) => {
                  setStateData({
                    ...stateData,
                    start_date: moment(dateInfo.start).format("YYYY-MM-DD"),
                    end_date: moment(dateInfo.end).format("YYYY-MM-DD"),
                  });

                  console.log(moment(dateInfo.start).format("YYYY-MM-DD")); //start of the range the calendar date
                  console.log(moment(dateInfo.end).format("YYYY-MM-DD")); //end of the range the calendar date
                }}
                eventContent={renderEventContent}
                ref={calendar}
              />
            )}

            {!showMonthView && (
              <div>
                {dataEvent && (
                  <InfiniteScroll
                    dataLength={dataEvent.data.data.length}
                    next={() => {
                      setStateData({
                        ...stateData,
                        page_size: stateData.page_size + 9,
                      });
                    }}
                    hasMore={
                      dataEvent.data.to
                        ? dataEvent.data.to == dataEvent.data.total
                          ? false
                          : true
                        : false
                    }
                    loader={
                      <h4 style={{ textAlign: "center", marginTop: "10px" }}>
                        Loading...
                      </h4>
                    }
                    endMessage={<></>}
                  >
                    {" "}
                    <Row gutter={[16, 16]}>
                      {dataEvent &&
                        dataEvent.data.data.map((item, key) => {
                          var back_color = "#d6ae71";
                          console.log("item", item);

                          var back_color = "#23bf08";
                          if (item.is_attended) {
                            back_color = "#1890ff";
                          } else {
                            if (item.is_waiting) {
                              back_color = "#293a56";
                            } else {
                              if (item.is_registered) {
                                back_color = "#d6ae71";
                                // console.log("awdawd");
                              } else {
                                if (item.is_sold_out) {
                                  back_color = "#800000";
                                } else {
                                  if (item.is_beyond_rsvp) {
                                    back_color = "#293a56";
                                  }
                                }
                              }
                            }
                          }

                          let category = [];
                          if (
                            item.event_detail_attending_participants.length <= 2
                          ) {
                            item.event_detail_attending_participants.map(
                              (item, key) => {
                                category.push(item.category);
                              }
                            );
                          } else {
                            item.event_detail_attending_participants.map(
                              (item, key) => {
                                if (key <= 2) {
                                  category.push(item.category);
                                }
                              }
                            );
                          }

                          return (
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              lg={8}
                              xl={6}
                              key={key}
                              onClick={(e) => showModal(item)}
                            >
                              <Badge.Ribbon
                                text={
                                  <div>
                                    {moment(
                                      item.event_schedules[0].datetime_start
                                    ).format("MMMM DD, YYYY")}
                                  </div>
                                }
                                className="dash-badge-color"
                              >
                                {" "}
                                <Card
                                  hoverable
                                  style={{
                                    width: "100%",
                                    marginTop: 10,
                                    minHeight: 470,
                                    overflow: "hide",
                                  }}
                                  cover={
                                    <>
                                      <img
                                        alt="example"
                                        src={
                                          companyInfo().apiUrl +
                                          "storage/" +
                                          item.event_details.upload
                                        }
                                        style={{
                                          opacity:
                                            item.is_registered == true ||
                                            item.is_attended == true ||
                                            item.is_sold_out == true ||
                                            item.is_waiting == true ||
                                            item.is_beyond_rsvp == true
                                              ? 0.5
                                              : 1,
                                        }}
                                      />
                                    </>
                                  }
                                >
                                  <Meta
                                    title={
                                      <>
                                        <Row>
                                          <Col xs={2} md={2}>
                                            <div
                                              style={{
                                                width: "10px",
                                                height: "10px",
                                                marginTop: "7px",
                                                background:
                                                  item.is_waiting == true ||
                                                  item.is_beyond_rsvp == true
                                                    ? "#A7A4A6"
                                                    : back_color,
                                                borderRadius: "50%",
                                                marginRight: 3,
                                              }}
                                            ></div>
                                          </Col>
                                          <Col xs={22} md={22}>
                                            {item.event_details.title}
                                          </Col>
                                        </Row>
                                        <div className="dash-time-font-size">
                                          {item.event_type !== "Live In-Person"
                                            ? item.event_type
                                            : ""}
                                        </div>
                                        <div className="dash-para">
                                          <p className="c-secondary dash-time-font-size">
                                            {item.event_schedules.map(
                                              (item, key) => {
                                                return (
                                                  <div
                                                    key={key}
                                                    style={{
                                                      whiteSpace: "pre-line",
                                                    }}
                                                  >
                                                    <b>·</b>{" "}
                                                    {moment(
                                                      item.datetime_start
                                                    ).format("MMMM Do h:mma") +
                                                      " - " +
                                                      moment(
                                                        item.datetime_end
                                                      ).format("h:mma")}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </p>

                                          <p className="c-secondary dash-time-font-size dash-role">
                                            <b></b>{" "}
                                            {console.log(
                                              "data",
                                              item.event_detail_attending_participants
                                            )}
                                            {item
                                              .event_detail_attending_participants
                                              .length <= 3
                                              ? category
                                              : category
                                                  .join(", ")
                                                  .slice(0, -1) + " ..."}
                                          </p>

                                          <p className="c-secondary dash-time-font-size">
                                            {item.event_type ===
                                              "Live In-Person" && (
                                              <>
                                                <b>·</b>{" "}
                                                {item.event_venue &&
                                                item.event_venue
                                                  ? item.event_venue.city
                                                  : ""}
                                                {item.event_venue &&
                                                item.event_venue.state
                                                  ? ", " +
                                                    item.event_venue.state
                                                  : ""}
                                              </>
                                            )}
                                          </p>
                                        </div>
                                      </>
                                    }
                                    description={
                                      item.event_details.summary && (
                                        <div>
                                          {item.event_details.summary.length >=
                                          255
                                            ? item.event_details.summary.substring(
                                                0,
                                                255
                                              ) + " ..."
                                            : item.event_details.summary}
                                        </div>
                                      )
                                    }
                                  />

                                  {item.is_beyond_rsvp && (
                                    <div className="alertMessage">
                                      <Alert
                                        style={{
                                          background: "#293a56",
                                          borderColor: "#293a56",
                                        }}
                                        message={
                                          <div style={{ color: "white" }}>
                                            RSVP Booking Closed
                                          </div>
                                        }
                                      />
                                    </div>
                                  )}

                                  {item.is_attended && (
                                    <div className="alertMessage">
                                      <Alert
                                        style={{
                                          background: "#1890ff",
                                          borderColor: "#1890ff",
                                        }}
                                        message={
                                          <div style={{ color: "white" }}>
                                            You Attended on this Event
                                          </div>
                                        }
                                      />
                                    </div>
                                  )}
                                  {item.is_waiting && (
                                    <div className="alertMessage">
                                      <Alert
                                        style={{
                                          background: "#293a56",
                                          borderColor: "#293a56",
                                        }}
                                        message={
                                          <div style={{ color: "white" }}>
                                            You are on Waiting list
                                          </div>
                                        }
                                      />
                                    </div>
                                  )}
                                  {item.is_registered && (
                                    <div className="alertMessage">
                                      <Alert
                                        style={{
                                          background: "#d6ae71",
                                          borderColor: "#d6ae71",
                                        }}
                                        message={
                                          <div style={{ color: "white" }}>
                                            You Registered for this Event
                                          </div>
                                        }
                                      />
                                    </div>
                                  )}

                                  {item.is_sold_out && (
                                    <div className="alertMessage">
                                      <Alert
                                        style={{
                                          background: "#800000",

                                          borderColor: "#800000",
                                        }}
                                        message={
                                          <div style={{ color: "white" }}>
                                            Sold Out!
                                            <br></br>
                                            {item.event_details
                                              .allow_waiting_list == "1" && (
                                              <div style={{ fontSize: 11 }}>
                                                Waiting list available
                                              </div>
                                            )}
                                          </div>
                                        }
                                      />
                                    </div>
                                  )}
                                </Card>
                              </Badge.Ribbon>
                            </Col>
                          );
                        })}
                    </Row>
                  </InfiniteScroll>
                )}
              </div>
            )}
          </Col>
        </Row>

        <ModalPreview
          previewShow={previewShow}
          setPreviewShow={setPreviewShow}
          showModal={showModal}
          match={id}
          history={history}
          isRegistered={isRegistered}
          isSoldOut={isSoldOut}
          isAttended={isAttended}
          isWaiting={isWaiting}
          isByond={isByond}
          isMyCategories={isMyCategories}
          isPastDate={isPastDate}
        />
      </Layout.Content>
    </Layout>
  );
}
