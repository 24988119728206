import React, { useState, useEffect, useRef } from "react";
import {
	Card,
	Layout,
	Modal,
	Typography,
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Checkbox,
	Table,
	Tag,
	Space,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	PrinterOutlined,
	CreditCardOutlined,
	DownloadOutlined,
} from "@ant-design/icons";

import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { GET } from "../../../../providers/useAxiosQuery";
import toCurrency from "../../../../providers/toCurrency";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
export default function PageInvoicesView({ match, permission }) {
	let history = useHistory();
	const sub_title = "Invoice";
	let componentRef = useRef();
	let invoice_id = match.params.id;

	const {
		data: dataInvoice,
		isLoading: isLoadingDataInvoice,
		refetch: refetchDataInvoice,
		isFetching: isFetchingDataInvoice,
	} = GET(`api/v1/get_invoice/${invoice_id}`, "invoice" + invoice_id, (res) => {
		console.log("invoice", res);
	});
	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageInvoicesView"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CreditCardOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br></br>
				<Row>
					<Col xs={24} sm={24} md={24} lg={17} xl={17}>
						<Card className="invoiceCard">
							<Space>
								{/* <ReactToPrint
                  trigger={() => (
                    <Button
                      className="btn-login-outline printInvoice"
                      icon={<PrinterOutlined />}
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef}
                />{" "} */}
								<ReactToPrint content={() => componentRef}>
									<PrintContextConsumer>
										{({ handlePrint }) => (
											<Button
												className="btn-login-outline printInvoice"
												icon={<PrinterOutlined />}
												onClick={handlePrint}
											>
												Print
											</Button>
										)}
									</PrintContextConsumer>
								</ReactToPrint>
								<Button
									className="btn-loginNew-outline"
									icon={<DownloadOutlined />}
									onClick={() => {
										window.location.href =
											dataInvoice && dataInvoice.data.invoice_pdf;
									}}
								>
									Download
								</Button>
							</Space>

							<br />
							<br />
							<Row>
								<Col xs={24} sm={24} md={12} lg={12}>
									<div className="invoiceTextTitle">CE.LIYA LLC.</div>
									<div>8160 Butherus</div>
									<div>Dr #8</div>
									<div>Scottsdale AZ 85260</div>
									<a href={"tel:480-848-3197"}>(480) 848-3197</a>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12}>
									<div className="invoiceTextTitleRight ">INVOICE</div>
								</Col>
							</Row>
							<br></br>
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12}>
											<div className="invoiceTextTitleYellow">INVOICE TO</div>

											{dataInvoice && dataInvoice.data.customer_name && (
												<div className="invoiceTextTitle">
													{dataInvoice.data.customer_name}
												</div>
											)}
											{dataInvoice && (
												<div>
													{dataInvoice.data.customer_address.line1}{" "}
													{dataInvoice.data.customer_address.line2},{" "}
													{dataInvoice.data.customer_address.city},{" "}
													{dataInvoice.data.customer_address.state}{" "}
													{dataInvoice.data.customer_address.postal_code}
												</div>
											)}
											{dataInvoice && dataInvoice.data.customer_phone && (
												<div>
													<a href={"tel:" + dataInvoice.data.customer_phone}>
														{dataInvoice.data.customer_phone}
													</a>
												</div>
											)}
											{dataInvoice && dataInvoice.data.customer_email && (
												<div>
													{" "}
													<a href={"mailto:" + dataInvoice.data.customer_email}>
														{dataInvoice.data.customer_email}
													</a>
												</div>
											)}
											<br></br>
											<br></br>
											<div className="invoicethankYoutext">
												Thank you for your business Please save this invoice{" "}
												<br class="brNoneMobile"></br> for your records. Click
												the download button to save it.{" "}
												{/* <a
                          target="_blank"
                          href={dataInvoice && dataInvoice.data.invoice_pdf}
                        >
                          click here
                        </a> */}
											</div>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12}>
											<div className="invoiceTextTitleYellow">
												INVOICE INFORMATION
											</div>
											<table style={{ width: "100%" }}>
												<tr>
													<td>Invoice No.</td>
													<td>{dataInvoice && dataInvoice.data.number}</td>
												</tr>
												<tr>
													<td>Date Paid</td>
													<td>
														{dataInvoice &&
															dataInvoice.data.status_transitions &&
															moment
																.unix(
																	dataInvoice.data.status_transitions.paid_at
																)
																.format("MM/DD/YYYY")}
													</td>
												</tr>
												<tr>
													<td>Description</td>
													<td>{dataInvoice && dataInvoice.data.description}</td>
												</tr>
											</table>
											<br></br>
											<br></br>
											<table style={{ width: "100%" }}>
												<tr>
													<td className="textInfoBottom">PAID</td>
													<td className="textInfoBottom">
														$
														{dataInvoice &&
															toCurrency(dataInvoice.data.amount_paid / 100)}
													</td>
												</tr>
											</table>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<div style={{ display: "none" }}>
					<ComponentToPrint
						dataInvoice={dataInvoice}
						ref={(el) => (componentRef = el)}
					/>
				</div>
			</Layout.Content>
		</Layout>
	);
}

const ComponentToPrint = React.forwardRef((props, ref) => {
	let dataInvoice = props.dataInvoice;
	return (
		<Card className="invoiceCard" ref={ref} style={{ border: "0px" }}>
			<Row gutter={24}>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="invoiceTextTitle">CE.LIYA LLC.</div>
					<div>8160 Butherus</div>
					<div>Dr #8</div>
					<div>Scottsdale AZ 85260</div>
					<a href={"tel:480-848-3197"}>(480) 848-3197</a>
				</Col>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="invoiceTextTitleRight ">INVOICE</div>
				</Col>
			</Row>
			<br></br>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<div className="invoiceTextTitleYellow">INVOICE TO</div>
							{dataInvoice && dataInvoice.data.customer_name && (
								<div className="invoiceTextTitle">
									{dataInvoice.data.customer_name}
								</div>
							)}
							{dataInvoice && (
								<div>
									{dataInvoice.data.customer_address.line1}{" "}
									{dataInvoice.data.customer_address.line2},{" "}
									{dataInvoice.data.customer_address.city},{" "}
									{dataInvoice.data.customer_address.state}{" "}
									{dataInvoice.data.customer_address.postal_code}
								</div>
							)}
							{dataInvoice && dataInvoice.data.customer_phone && (
								<div>{dataInvoice.data.customer_phone}</div>
							)}
							{dataInvoice && dataInvoice.data.customer_email && (
								<div>{dataInvoice.data.customer_email}</div>
							)}
							<br></br>
							<br></br>
							<div className="invoicethankYoutext">
								Thank you for your business Please save this invoice <br></br>{" "}
								for your records. Click the download button to save it.{" "}
								{/* <a
                  target="_blank"
                  href={dataInvoice && dataInvoice.data.invoice_pdf}
                >
                  click here
                </a> */}
							</div>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<div className="invoiceTextTitleYellow">INVOICE INFORMATION</div>
							<table style={{ width: "100%" }}>
								<tr>
									<td>Invoice No.</td>
									<td>{dataInvoice && dataInvoice.data.number}</td>
								</tr>
								<tr>
									<td>Date Paid</td>
									<td>
										{dataInvoice &&
											dataInvoice.data.status_transitions &&
											moment
												.unix(dataInvoice.data.status_transitions.paid_at)
												.format("MM/DD/YYYY")}
									</td>
								</tr>
								<tr>
									<td>Description</td>
									<td>{dataInvoice && dataInvoice.data.description}</td>
								</tr>
							</table>
							<br></br>
							<br></br>
							<table style={{ width: "100%" }}>
								<tr>
									<td className="textInfoBottom">PAID</td>
									<td className="textInfoBottom">
										$
										{dataInvoice &&
											toCurrency(dataInvoice.data.amount_paid / 100)}
									</td>
								</tr>
							</table>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
});
