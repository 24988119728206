import React, { useState, useEffect } from "react";
import { Layout, Input, Row, Col, Table, Pagination } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import { ContainerOutlined } from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";

import toCurrency from "../../../../providers/toCurrency";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import moment from "moment";

import PageSubscription from "./PageSubscription";

export default function PageInvoices({ props, permission }) {
	let history = useHistory();
	const { Search } = Input;
	const sub_title = "Subscriptions  &";

	const data = [
		{
			key: "1",
			invoice: "#00108",
			paid_date: "Aug, 17 2020",
			description: "Yearly Biling",
			provider: "CE.LIYA",
			amount: "$895.00",
		},
		{
			key: "2",
			invoice: "#00107",
			paid_date: "Aug, 12 2020",
			description: "Yearly Biling",
			provider: "CE.LIYA",
			amount: "$895.00",
		},
		{
			key: "3",
			invoice: "#00106",
			paid_date: "Aug, 15 2020",
			description: "Yearly Biling",
			provider: "CE.LIYA",
			amount: "$895.00",
		},
	];

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const {
		data: dataInvoices,
		isLoading: isLoadingDataInvoices,
		refetch: refetchDataInvoices,
		isFetching: isFetchingDataInvoices,
	} = GET(
		`api/v1/get_invoices?page_size=${dataTableInfo.page_size}`,
		"invoices",
		(res) => {
			console.log("invoices", res);
		}
	);

	const [searchText, setSearchText] = useState("");
	const handleOnChange = (e) => {
		setSearchText(e);
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchDataInvoices();
		console.log("dataTableInfo", dataTableInfo);
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		console.log("current", current);
		console.log("pageSize", pageSize);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageInvoices"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<ContainerOutlined />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/644213903?autoplay=0&muted=1"
					}
					col="venue_invoices"
					colLink="venue_invoices_link"
				/>
				<div class="subscriptionSection" style={{ marginBottom: 20 }}>
					<PageSubscription refetchDataInvoices={refetchDataInvoices} />
				</div>
				{/* <Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							// total={dataGetEvent && dataGetEvent.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row> */}
				{/* <br /> */}
				<div className="table-responsive">
					<Table
						dataSource={
							dataInvoices && dataInvoices.data && dataInvoices.data.data
						}
						className="table-responsive table-thead-login"
						pagination={false}
						size="small"
					>
						<Table.Column
							key="invoice"
							title="Invoice#"
							dataIndex="invoice"
							sorter={(a, b) => a.number.localeCompare(b.number)}
							render={(text, record) => {
								return (
									<a
										key={record.number}
										href="#"
										onClick={(e) =>
											history.push(
												"/venue-provider/my-account/invoices/" + record.id
											)
										}
									>
										{record.number}
									</a>
								);
							}}
						/>
						<Table.Column
							key="status"
							title="Status"
							dataIndex="status"
							sorter={(a, b) => a.status.localeCompare(b.status)}
							render={(text, record) => {
								return record.status.toUpperCase();
							}}
						/>
						<Table.Column
							key="paid_date"
							title="Paid Date"
							dataIndex="paid_date"
							sorter={(a, b) =>
								a.status_transitions.paid_at - b.status_transitions.paid_at
							}
							render={(text, record) => {
								return moment
									.unix(record.status_transitions.paid_at)
									.format("MM/DD/YYYY");
							}}
						/>
						<Table.Column
							key="description"
							title="Description"
							dataIndex="description"
							sorter={(a, b) =>
								a.billing_reason.localeCompare(b.billing_reason)
							}
							render={(text, record) => {
								return record.billing_reason.replace(/_/g, " ").toUpperCase();
							}}
						/>

						{/* <Table.Column
							key="provider"
							title="Provider"
							dataIndex="provider"
							// sorter
						/> */}
						<Table.Column
							key="amount"
							title="Amount"
							dataIndex="amount"
							sorter={(a, b) => a.amount_paid - b.amount_paid}
							render={(text, record) => {
								return (
									<div key={record.number}>
										{/* ${toCurrency(record.total / 100)} */}$
										{toCurrency(record.amount_paid / 100)}
									</div>
								);
							}}
						/>
					</Table>
				</div>
			</Layout.Content>
		</Layout>
	);
}
