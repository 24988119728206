import React, { useState, useEffect } from "react";
import {
	Layout,
	Button,
	Row,
	Col,
	Table,
	Pagination,
	Modal,
	Form,
	Space,
	notification,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { PlusOutlined, TableOutlined, EditOutlined } from "@ant-design/icons";
import $ from "jquery";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import FloatInput from "../../../../providers/FloatInput";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatSelect from "../../../../providers/FloatSelect";

export default function PageNotification({ props, permission }) {
	const sub_title = "View All";
	const history = useHistory();
	const [form] = Form.useForm();
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataTickets,
		refetch: refetchTickets,
		isLoading: isLoadingTickets,
		isFetching: isFetchingTickets,
	} = GET(
		`api/v1/notification?${$.param(dataTableInfo)}`,
		`notification`,
		(res) => {
			if (res.success) {
				//   console.log(res);
			}
		}
	);

	useEffect(() => {
		refetchTickets();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		// console.log("current", current);
		// console.log("pageSize", pageSize);
	};

	const [showModalNew, setShowModalNew] = useState(false);
	const handleCancel = () => {
		setShowModalNew(false);
		setSearchFor("");
		setMemberOption([]);
		form.resetFields();
	};

	const onFinishForm = (values) => {
		let data = {
			...values,
			user_id: memberOption && memberOption.id,
			old_type: oldData && oldData.old_type,
			// old_search_for: oldData && oldData.old_search_for,
		};
		console.log("onFinishForm", data);
		mutateNotification(data, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					notification.success({
						message: "Success",
						description: "Successfully submited",
					});
					form.resetFields();
					setMemberOption([]);
					setSearchFor("");
					setShowModalNew(false);
				}
			},
		});
	};

	const { mutate: mutateNotification, isLoading: isLoadingNotification } = POST(
		"api/v1/notification",
		"notification"
	);

	const [oldData, setOldData] = useState();
	const handleEdit = (record) => {
		console.log("record", record);
		form.setFieldsValue({
			id: record.id,
			title: record.title,
			type: record.type,
			search_for: record.search_for,
			description: record.description,
		});
		setSearchFor(record.search_for);
		setShowModalNew(true);
		setOldData({
			old_type: record.type,
			old_search_for: record.type,
		});
		if (record.search_for === "User") {
			setMemberOption({ id: record.user_notification[0].user_id });
		}
	};

	const [role, setrole] = useState([]);
	const {} = GET("api/v1/get_account_option", "get_account_option", (res) => {
		if (res.success) {
			// console.log(res);
			let arr = [
				{
					label: "ALL",
					value: "ALL",
				},
			];
			res.data &&
				res.data.map((row, index) => {
					arr.push({
						label: row.account_type,
						value: row.account_type,
					});
				});
			setrole(arr);
		}
	});

	const [company, setcompany] = useState([]);
	const {} = GET("api/v1/company_option", "company_option", (res) => {
		if (res.success) {
			// console.log("company_option", res);
			let arr = [];
			res.data &&
				res.data.map((row, index) => {
					arr.push({
						label: row.business_name,
						value: row.business_name,
					});
				});
			setcompany(arr);
		}
	});

	const [member, setmember] = useState([]);
	const {} = GET("api/v1/member_options", "member_options", (res) => {
		if (res.success) {
			console.log("member_options", res);
			let arr = [];
			res.data &&
				res.data.map((row, index) => {
					let val =
						row.role
							.match(/(\b\S)?/g)
							.join("")
							.match(/(^\S|\S$)?/g)
							.join("")
							.toUpperCase() +
						" - " +
						row.first_name +
						" " +
						row.last_name;
					arr.push({
						value: val,
						label: val,
						json: row,
					});
				});
			setmember(arr);
		}
	});

	const [searchFor, setSearchFor] = useState();
	const handleSearchFor = (val, opt) => {
		// console.log("handleSearchFor", opt);
		setSearchFor(val);
		setMemberOption([]);
		form.resetFields(["type"]);
	};

	const [memberOption, setMemberOption] = useState([]);
	const handleMember = (val, opt) => {
		console.log("handleSearchFor", opt);
		setMemberOption(opt["data-json"]);
	};
	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageNotification"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<TableOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br></br>
				<Row>
					<Col md={8} xs={24}>
						<Button
							// size="large"
							className="btn-login-outline"
							style={{ width: 200 }}
							icon={<PlusOutlined />}
							htmlType="submit"
							onClick={(e) => setShowModalNew(true)}
						>
							Add Notification
						</Button>
					</Col>
				</Row>
				<br></br>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataTickets && dataTickets.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<Table
					className="table-responsive table-thead-login"
					size="small"
					rowKey={(record) => record.id}
					loading={isLoadingTickets}
					dataSource={dataTickets ? dataTickets.data.data : []}
					pagination={false}
					onChange={handleTableChange}
				>
					<Table.Column key="title" title="Title" dataIndex="title" sorter />
					<Table.Column
						key="description"
						title="Description"
						dataIndex="description"
						sorter
					/>
					<Table.Column
						key="search_for"
						title="Type"
						dataIndex="search_for"
						sorter
					/>
					<Table.Column
						key="created_str"
						title="Created"
						dataIndex="created_str"
						sorter
					/>
					<Table.Column
						key="action"
						title="Action"
						render={(text, record) => {
							return (
								<Space>
									{/* <Button
                      size="small"
                      className="btn-primary"
                      icon={<EyeOutlined />}
                      onClick={(e) =>
                        history.push("/admin/account-types/view/" + record.id)
                      }
                    >
                      View
                    </Button> */}
									<Button
										size="small"
										className="btn-warning-outline"
										icon={<EditOutlined />}
										onClick={(e) => handleEdit(record)}
									>
										Edit
									</Button>
								</Space>
							);
						}}
					/>
				</Table>
			</Layout.Content>

			<Modal
				visible={showModalNew}
				className="modal-login"
				title="Notification Form"
				okText="Submit"
				cancelText="Cancel"
				width={700}
				onCancel={handleCancel}
				footer={[
					<Space>
						<Button
							onClick={() => {
								handleCancel();
							}}
						>
							Cancel
						</Button>
						<Button
							className="btn-login-outline"
							onClick={() => {
								form
									.validateFields()
									.then((values) => {
										onFinishForm(values);
									})
									.catch((info) => {
										console.log("Validate Failed:", info);
									});
							}}
							loading={isLoadingNotification}
						>
							Submit
						</Button>
					</Space>,
				]}
			>
				<Form
					form={form}
					name="panlistnew"
					layout="vertical"
					// initialValues={{ search_for: "Role" }}
				>
					<Form.Item name="id" style={{ display: "none" }}>
						<FloatInput label="id" placeholder="id" />
					</Form.Item>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
							<Form.Item
								name="search_for"
								rules={[{ required: true, message: "required!" }]}
								className="form-select-error"
								hasFeedback
							>
								<FloatSelect
									label="Search For"
									placeholder="Search For"
									onChange={handleSearchFor}
									options={[
										{
											label: "Role",
											value: "Role",
										},
										{
											label: "Company",
											value: "Company",
										},
										{
											label: "User",
											value: "User",
										},
									]}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
							{searchFor === "Role" && (
								<Form.Item
									name="type"
									rules={[{ required: true, message: "required!" }]}
									className="form-select-error"
									hasFeedback
								>
									<FloatSelect
										label="Account Type"
										placeholder="Account Type"
										options={role}
									/>
								</Form.Item>
							)}
							{searchFor === "Company" && (
								<Form.Item
									name="type"
									rules={[{ required: true, message: "required!" }]}
									className="form-select-error"
									hasFeedback
								>
									<FloatSelect
										label="Company"
										placeholder="Company"
										options={company}
									/>
								</Form.Item>
							)}
							{searchFor === "User" && (
								<Form.Item
									name="type"
									rules={[{ required: true, message: "required!" }]}
									className="form-select-error"
									hasFeedback
								>
									<FloatSelect
										label="User"
										placeholder="User"
										onChange={handleMember}
										options={member}
									/>
								</Form.Item>
							)}
						</Col>
					</Row>
					<Form.Item
						name="title"
						rules={[{ required: true, message: "required!" }]}
						hasFeedback
					>
						<FloatInput label="Title" placeholder="Title" />
					</Form.Item>
					<Form.Item
						name="description"
						rules={[{ required: true, message: "required!" }]}
						hasFeedback
					>
						<FloatTextArea label="Description" placeholder="Description" />
					</Form.Item>
				</Form>
			</Modal>
		</Layout>
	);
}
