import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Carousel, Button, Space } from "antd";
import image1 from "../../../../../assets/img/default_venue.jpg";

import companyInfo from "../../../../../providers/companyInfo";
import {
  FaFileInvoice,
  FaMobileAlt,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaTimes,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaVimeo,
} from "react-icons/fa";
import {
  RightOutlined,
  LeftOutlined,
  MessageOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { POST } from "../../../../../providers/useAxiosQuery";
import getUserData from "../../../../../providers/getUserData";
import { useHistory } from "react-router-dom";

export default function ModalPreview({
  previewShow,
  setPreviewShow,
  showModal,
  previewData,
  // companyData,
}) {
  let userdata = getUserData();
  let history = useHistory();
  const sub_title = "All Features";
  useEffect(() => {
    console.log("previewData", previewData);
    return () => {};
  }, [previewData]);

  const onClose = () => {
    setPreviewShow(false);
  };

  const [firstName, setFirstName] = useState("");

  const [form] = Form.useForm();

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  let default_image = [
    {
      url: image1,
    },
    {
      url: image1,
    },
  ];

  const { mutate: mutateStartNewConvo, isLoading: isLoadingStartNewConvo } =
    POST("api/v1/message", `message_${userdata.id}`);
  const handleMakeNewConvo = (user_id) => {
    let data = {
      to_id: user_id,
      from_id: userdata.id,
    };
    console.log(data);
    mutateStartNewConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        handleNewMessageConvo(res.data.id, user_id);
      },
    });
  };

  const { mutate: mutateNewMessageConvo, isLoading: isLoadingNewMessageConvo } =
    POST("api/v1/message_convo");

  const handleNewMessageConvo = (message_id, user_id) => {
    let messageText = `Hello! I want to inquire about ${previewData.venue_name}`;
    let data = {
      from_id: userdata.id,
      to_id: user_id,
      message_id: message_id,
      message: messageText,
    };
    console.log(data);
    mutateNewMessageConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        history.push("/event-provider/messages?message_id=" + message_id);
      },
    });
  };

  return (
    <Modal
      visible={previewShow}
      width={650}
      className="modalPreviewVenue"
      onCancel={onClose}
      style={{ top: 20 }}
      // footer={<img src={image3} style={{ width: "100%" }}></img>}
      footer={null}
      closeIcon={<FaTimes className="modal-close-icon-black" />}
    >
      <div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="venueDescriptionTitleCompany">
              {previewData[0].first_name + " " + previewData[0].last_name}
            </div>
          </Col>
        </Row>
        <Row
          gutter={24}
          style={{ paddingRight: 0, marginTop: "20px" }}
          className="rowWrapModalPreview"
        >
          <Col xs={24} sm={24} md={14} lg={14} className="colCarousel">
            <div className="">
              <Carousel
                autoplay={true}
                arrows={true}
                prevArrow={<LeftOutlined />}
                nextArrow={<RightOutlined />}
              >
                {JSON.parse(previewData[0].speaker_images).length > 0 &&
                  JSON.parse(previewData[0].speaker_images).map(
                    (index, key) => {
                      return (
                        <div className="venueImage carouselLimit">
                          <img
                            src={companyInfo().apiUrl + "storage/" + index}
                            style={{ width: "100%", minHeight: 100 }}
                          ></img>
                        </div>
                      );
                    }
                  )}
                {JSON.parse(previewData[0].speaker_images).length <= 0 &&
                  default_image.map((row, key) => {
                    return (
                      <div className="venueImage carouselLimit">
                        <img
                          src={row.url}
                          style={{ width: "100%", minHeight: 100 }}
                        ></img>
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} className="colVenuePreviewLogo">
            <div className="colVenueFlex speakerColflex">
              <img
                src={
                  previewData[0].logo_or_photo
                    ? companyInfo().apiUrl +
                      "storage/" +
                      previewData[0].logo_or_photo
                    : "https://www.tibs.org.tw/images/default.jpg"
                }
                className="venuePreviewLogo"
              ></img>
            </div>
          </Col>
        </Row>
        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={14} lg={14}>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle"
            >
              <b>Biography</b>
            </div>

            <div
              className="modalLineheight"
              dangerouslySetInnerHTML={{
                __html: previewData[0].biography,
              }}
            />
            <br></br>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle "
            >
              <b>topics</b>
            </div>

            <div
              className="modalLineheight"
              dangerouslySetInnerHTML={{
                __html: previewData[0].topics,
              }}
            />
            <br></br>
            {previewData[0].education && (
              <>
                {" "}
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>education</b>
                </div>
                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData[0].education,
                  }}
                />
                <br></br>
              </>
            )}

            {previewData[0].engagements && (
              <>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>previous speaking engagements</b>
                </div>
                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData[0].engagements,
                  }}
                />
                <br></br>
              </>
            )}

            {previewData[0].credentials && (
              <>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>credentials</b>
                </div>
                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData[0].credentials,
                  }}
                />
                <br></br>
              </>
            )}

            {previewData[0].conflict_of_interests && (
              <>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>conflict of interests</b>
                </div>
                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData[0].conflict_of_interests,
                  }}
                />
                <br></br>
              </>
            )}

            {previewData[0].travel_notes && (
              <>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>travel notes</b>
                </div>
                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData[0].travel_notes,
                  }}
                />
              </>
            )}
          </Col>
          <Col xs={24} sm={24} md={10} lg={10}>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle modalLineheight contactInfo"
            >
              <b>Contact Info</b>
            </div>
            <div className="" style={{ marginTop: 2 }}>
              <div>
                <a
                  className="venueLink"
                  href={"tel:" + previewData[0].cell_number}
                >
                  <span className="iconDefualColor">
                    <FaMobileAlt />
                  </span>{" "}
                  {previewData[0].cell_number}
                </a>
              </div>
            </div>
            {previewData[0].business_phone && (
              <div className="">
                <div>
                  <a
                    className="venueLink"
                    href={"tel:" + previewData[0].business_phone}
                  >
                    <span className="iconDefualColor">
                      <FaPhoneAlt />
                    </span>{" "}
                    {previewData[0].business_phone}
                  </a>
                </div>
              </div>
            )}
            <div className="">
              <div>
                <span className="iconDefualColor">
                  <FaMapMarkerAlt />
                </span>{" "}
                {previewData[0].city}, {previewData[0].state}{" "}
              </div>
            </div>
            <div className="">
              <div>
                <a
                  className="venueLink"
                  href={"mailto:" + previewData[0].email}
                >
                  <span className="iconDefualColor">
                    <FaEnvelope />
                  </span>{" "}
                  Email Me
                </a>
              </div>
            </div>
            <br></br>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle"
            >
              <b>AHG #</b>
            </div>
            <div>
              <div>{previewData[0].company_agd}</div>
            </div>
            <br></br>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle"
            >
              <b>Will Travel?</b>
            </div>
            <div>
              <div>{previewData[0].is_travel == 1 ? "Yes" : "No"}</div>
            </div>

            {previewData[0].services && (
              <>
                <br></br>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>Services</b>
                </div>
                <div>
                  <div
                    className="modalLineheight"
                    dangerouslySetInnerHTML={{
                      __html: previewData[0].services,
                    }}
                  />
                </div>
              </>
            )}

            {previewData[0].facebook_link ||
            previewData[0].instagram_link ||
            previewData[0].twitter_link ||
            previewData[0].youtube_link ||
            previewData[0].vimeo_link ? (
              <>
                {" "}
                <br></br>{" "}
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>Social Media</b>
                </div>
                <div>
                  <Space size={"middle"}>
                    {previewData[0].facebook_link && (
                      <div className="iconDefualColor iconDefualColorNoPosition">
                        <a href={previewData[0].facebook_link} target="_blank">
                          <FaFacebook style={{ fontSize: "19px" }} />{" "}
                        </a>
                      </div>
                    )}

                    {previewData[0].instagram_link && (
                      <div className="iconDefualColor iconDefualColorNoPosition">
                        <a href={previewData[0].instagram_link} target="_blank">
                          {" "}
                          <FaInstagram style={{ fontSize: "20px" }} />{" "}
                        </a>
                      </div>
                    )}
                    {previewData[0].twitter_link && (
                      <div className="iconDefualColor iconDefualColorNoPosition">
                        <a href={previewData[0].twitter_link} target="_blank">
                          {" "}
                          <FaTwitter style={{ fontSize: "18px" }} />{" "}
                        </a>
                      </div>
                    )}

                    {previewData[0].youtube_link && (
                      <div className="iconDefualColor iconDefualColorNoPosition">
                        <a href={previewData[0].youtube_link} target="_blank">
                          {" "}
                          <FaYoutube style={{ fontSize: "22px" }} />{" "}
                        </a>
                      </div>
                    )}
                    {previewData[0].vimeo_link && (
                      <div className="iconDefualColor iconDefualColorNoPosition">
                        <a href={previewData[0].vimeo_link} target="_blank">
                          {" "}
                          <FaVimeo style={{ fontSize: "19px" }} />{" "}
                        </a>
                      </div>
                    )}
                  </Space>
                </div>
              </>
            ) : (
              ""
            )}
            <br></br>
            {previewData[0].website && (
              <div className="venueDescriptionSubTitle">
                <a
                  href={previewData[0].website}
                  className="venueLink"
                  target={"_blank"}
                >
                  <span className="iconDefualColor iconDefualColorNoPosition">
                    <GlobalOutlined />
                  </span>{" "}
                  Website Link
                </a>
              </div>
            )}
            {previewData[0].book_apointment_link && (
              <div className="venueDescriptionSubTitle">
                <a
                  href={previewData[0].book_apointment_link}
                  className="venueLink"
                  target={"_blank"}
                >
                  <span className="iconDefualColor iconDefualColorNoPosition">
                    <GlobalOutlined />
                  </span>{" "}
                  Book Me
                </a>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <br />
    </Modal>
  );
}
