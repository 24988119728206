import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Badge, Image, Space, Dropdown, Typography } from "antd";
import {
	MenuUnfoldOutlined,
	UserOutlined,
	CalendarOutlined,
	TeamOutlined,
	MessageOutlined,
	InboxOutlined,
	QrcodeOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { BiBell } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaFileInvoice } from "react-icons/fa";

import getUserData from "../../../providers/getUserData";
import companyInfo from "../../../providers/companyInfo";
import { POST, GET } from "../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";

// import getUserData from "../../providers/getUserData";
// import useAxiosQuery from "../../providers/useAxiosQuery";

import NotificationsAlert from "./Components/NotificationsAlert";
import MessagesAlert from "./Components/MessagesAlert";

export default function ComponentsEventHeader({ width, setSideMenuCollapse }) {
	// let history = useHistory();
	const userdata = getUserData();

	const handleLogout = (e) => {
		mutateLogout(
			{
				user_id: userdata.id,
				subcriptionData: localStorage.push_notification_subscription
					? JSON.parse(localStorage.push_notification_subscription)
					: "",
			},
			{
				onSuccess: (res) => {
					console.log("userdata", res);
					if (res.success) {
						localStorage.removeItem("viewas");
						localStorage.removeItem("token");
						localStorage.removeItem("userdata");
						localStorage.removeItem("push_notification_subscription");
						window.location.href = window.location.origin;
					}
				},
			}
		);
	};

	const { mutate: mutateLogout, isLoading: isLoadingLogout } = POST(
		"api/v1/logout",
		"logout"
	);

	const [imageProfile, setImageProfile] = useState();
	useEffect(() => {
		let image_type = userdata.upload ? userdata.upload.split("/") : "";
		if (image_type[0] !== undefined) {
			image_type =
				image_type[0] === "https:"
					? userdata.upload
					: companyInfo().apiUrl + "storage/" + userdata.upload;
		} else {
			image_type =
				"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
		}
		setImageProfile(image_type);

		return () => {};
	}, [userdata]);
	const {
		data: dataProfile,
		refetch: refetchProfile,
		isLoading: isLoadingProfile,
	} = GET(`api/v1/get_by_id?id=${userdata.id}`, `profile`, (res) => {
		let image_type = userdata.upload ? userdata.upload.split("/") : "";
		if (image_type[0] !== undefined) {
			image_type =
				image_type[0] === "https:"
					? userdata.upload
					: companyInfo().apiUrl + "storage/" + userdata.upload;
		} else {
			image_type =
				"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
		}
		setImageProfile(image_type);
	});

	const [unreadNotif, setUnreadNotif] = useState(0);
	const [notification, setnotification] = useState([]);
	const {
		data: dataNotification,
		isLoading: isLoadingNotification,
		refetch: refetchNotification,
	} = GET("api/v1/get_notification_alert", "get_notification_alert", (res) => {
		if (res.success) {
			console.log("dataNotification", res);
			setnotification(res.data);
			setUnreadNotif(res.unread);
		}
	});

	const [unreadMessages, setUnreadMessages] = useState(0);
	const [messages, setMessages] = useState([]);
	const {
		data: dataMessages,
		isLoading: isLoadingMessages,
		refetch: refetchMessages,
	} = GET("api/v1/get_messages_alert", "get_messages_alert", (res) => {
		if (res.success) {
			console.log("dataMessages", res);
			setMessages(res.data);
			setUnreadMessages(res.unread);
		}
	});

	const [memberCompany, setMemberCompany] = useState([]);
	const { data: dataMemberCompanyPrimary, isLoading: isLoadingGetAccount } =
		GET(
			`api/v1/getMemberCompanyPrimary?user_id=${userdata.id}`,
			"dataMemberCompanyPrimary",
			(res) => {
				if (res.success) {
					// console.log("dataMemberCompanyPrimary", res);
					setMemberCompany(res.data);
				}
			}
		);

	const location = useLocation();
	useEffect(() => {
		// console.log("refeteching messages");
		refetchMessages();
	}, [location.pathname]);

	const menuProfile = (
		<Menu>
			<Menu.Item
				key="/profile/details"
				className="ant-menu-item-profile-details"
			>
				<Space>
					<Image
						src={imageProfile}
						preview={false}
						className="imageObjectfit"
					/>

					<Typography.Text className="ant-typography-profile-details">
						<Typography.Text className="nameHeaderClass">
							{userdata.first_name} {userdata.last_name}
						</Typography.Text>
						<br />
						<Typography.Text>
							{memberCompany ? memberCompany.title : ""}
						</Typography.Text>
					</Typography.Text>
				</Space>
			</Menu.Item>
			<Menu.Item
				key="/event-provider-speaker/my-account/profile"
				icon={<UserOutlined />}
			>
				<Link to="/event-provider-speaker/my-account/profile">
					Account Profile
				</Link>
			</Menu.Item>
			<Menu.Item
				key="/event-provider-speaker/my-account/invoices"
				icon={<FaFileInvoice />}
			>
				<Link to="/event-provider-speaker/my-account/invoices">
					Subscriptions & Invoices
				</Link>
			</Menu.Item>
			<Menu.Item
				key="/event-provider-speaker/my-account/attendees-payments"
				icon={<InboxOutlined />}
			>
				<Link to="/event-provider-speaker/my-account/attendees-payments">
					Attendees Payments
				</Link>
			</Menu.Item>

			<Menu.Item key="/profile/signout" icon={<AiOutlinePoweroff />}>
				<Link to="#" onClick={handleLogout}>
					Sign Out
				</Link>
			</Menu.Item>
		</Menu>
	);

	const menuNotificationBell = (
		<NotificationsAlert
			notification={notification}
			refetch={refetchNotification}
		/>
	);

	const menuMessageAlert = (
		<MessagesAlert messages={messages} refetch={refetchMessages} />
	);

	const menuLeftMenus = (
		<Menu>
			<Menu.Item
				key="/event-provider-speaker/ce-calendar/view-edit-events"
				icon={<CalendarOutlined />}
				onClick={() => setSideMenuCollapse(true)}
			>
				<Link to="/event-provider-speaker/ce-calendar/view-edit-events">
					All Events
				</Link>
			</Menu.Item>
			<Menu.Item
				key="/event-provider-speaker/ce-calendar/events-with-attendees"
				icon={<TeamOutlined />}
				onClick={() => setSideMenuCollapse(true)}
			>
				<Link to="/event-provider-speaker/ce-calendar/events-with-attendees">
					All Attendees
				</Link>
			</Menu.Item>
			<Menu.Item
				key="/event-provider-speaker/my-account/my-company"
				icon={<FontAwesomeIcon className="link-icon" icon={faBuilding} />}
				onClick={() => setSideMenuCollapse(true)}
			>
				<Link to="/event-provider-speaker/my-account/my-company">
					My Company
				</Link>
			</Menu.Item>
			<Menu.Item
				key="/event-provider-speaker/ce-calendar/events-with-attendees"
				icon={<QrcodeOutlined />}
				onClick={() => setSideMenuCollapse(true)}
			>
				<Link to="/event-provider-speaker/ce-calendar/events-with-attendees/qr-reader">
					QR Reader
				</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<Layout.Header
			className="headerNavtop"
			style={{ position: "fixed", zIndex: 3, width: "100%" }}
		>
			<Space className="ant-space-container">
				<Space className="ant-space-left" size={5}>
					{width <= 576 && (
						// <div className="ant-space-icon">
						// </div>
						<MenuUnfoldOutlined
							onClick={() => setSideMenuCollapse(false)}
							className="menuCollapseOnClose"
						/>
					)}
					{width <= 768 ? (
						<>
							<div className="ant-space-icon">
								<Dropdown
									overlay={menuLeftMenus}
									placement="bottomRight"
									overlayClassName="ant-menu-submenu-left-menus-popup posFixed"
									trigger={["click", "hover"]}
								>
									<MdOutlineDashboardCustomize className="ant-menu-submenu-left-menus" />
								</Dropdown>
							</div>
						</>
					) : null}
					{width > 768 ? (
						<>
							<div className="ant-space-icon">
								<Link to="/event-provider-speaker/ce-calendar/view-edit-events">
									<CalendarOutlined />
									<Typography.Text className="ant-typography-text">
										All Events
									</Typography.Text>
								</Link>
							</div>

							<div className="ant-space-icon">
								<Link to="/event-provider-speaker/ce-calendar/events-with-attendees">
									<TeamOutlined />
									<Typography.Text className="ant-typography-text">
										All Attendees
									</Typography.Text>
								</Link>
							</div>

							<div className="ant-space-icon">
								<Link to="/event-provider-speaker/my-account/my-company">
									<FontAwesomeIcon className="link-icon" icon={faBuilding} />
									<Typography.Text className="ant-typography-text">
										My Company
									</Typography.Text>
								</Link>
							</div>
							<div className="ant-space-icon">
								<Link to="/event-provider-speaker/ce-calendar/events-with-attendees/qr-reader">
									<QrcodeOutlined />
									<Typography.Text className="ant-typography-text">
										QR Reader
									</Typography.Text>
								</Link>
							</div>
						</>
					) : null}
				</Space>
				<Space className="ant-space-right headerRight" size={0}>
					<Dropdown
						overlay={menuProfile}
						placement="bottomRight"
						overlayClassName="ant-menu-submenu-profile-popup"
					>
						<Image
							className="ant-menu-submenu-profile imageObjectfit"
							src={imageProfile}
							preview={false}
						/>
					</Dropdown>

					<Dropdown
						overlay={menuNotificationBell}
						placement="bottomRight"
						overlayClassName="ant-menu-submenu-notification-popup scrollbar-2"
						trigger={["click"]}
					>
						<Badge
							count={unreadNotif < 99 ? unreadNotif : "99+"}
							className="ant-menu-submenu-notification"
						>
							<BiBell />
						</Badge>
					</Dropdown>

					<Dropdown
						overlay={menuMessageAlert}
						placement="bottomRight"
						overlayClassName="ant-menu-submenu-message-alert-popup scrollbar-2"
						trigger={["click"]}
					>
						<Badge
							count={unreadMessages < 99 ? unreadMessages : "99+"}
							className="ant-menu-submenu-message-alert"
						>
							<MessageOutlined />
						</Badge>
					</Dropdown>
				</Space>
			</Space>
		</Layout.Header>
	);
}
