import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Form,
  Button,
  Row,
  Col,
  notification,
  Collapse,
  Alert,
} from "antd";

import { SaveOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import FloatInputMask from "../../../../../providers/FloatInputMask";
import { UPDATE } from "../../../../../providers/useAxiosQuery";
import states from "states-us";

import optionCountryCodes from "../../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../../providers/optionStateCodesCanada";
import optionStateCodesMexico from "../../../../../providers/optionStateCodesMexico";
export default function ModalContiueFill({ dataUser }) {
  const [country, setCountry] = useState(optionCountryCodes);
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const stateMX = optionStateCodesMexico();

  const [stateLabel, setStateLabel] = useState("State");
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionState, setOptionState] = useState(stateUS);
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    } else if (val === "Mexico") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateMX);
      setOptionZip(/(^\d{5}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    }
    form.resetFields(["state", "zip"]);
  };

  const { Panel } = Collapse;
  const [infoShow, setInfoShow] = useState(false);
  const showModal = () => {
    setInfoShow(true);
  };

  useEffect(() => {
    if (dataUser) {
      // console.log("one_time_modal", dataUser.data[0]);

      if (
        !dataUser.data[0].email_alternative ||
        dataUser.data[0].email_alternative === "" ||
        dataUser.data[0].email_alternative === "undefined"
      ) {
        form.resetFields(["email_alternative"]);
      }

      if (
        !dataUser.data[0].facebook ||
        dataUser.data[0].facebook === "" ||
        dataUser.data[0].facebook === "undefined"
      ) {
        form.resetFields(["facebook"]);
      }
      if (
        !dataUser.data[0].instagram ||
        dataUser.data[0].instagram === "" ||
        dataUser.data[0].instagram === "undefined"
      ) {
        form.resetFields(["instagram"]);
      }
      if (
        !dataUser.data[0].youtube_vimeo ||
        dataUser.data[0].youtube_vimeo === "" ||
        dataUser.data[0].youtube_vimeo === "undefined"
      ) {
        form.resetFields(["youtube_vimeo"]);
      }

      setInfoShow(
        dataUser.data[0].one_time_modal == 1 ||
          dataUser.data[0].one_time_modal == null
          ? true
          : false
      );

      if (dataUser.data[0].business_categories) {
        setInfoShow(
          dataUser.data[0].one_time_modal == 1 ||
            dataUser.data[0].one_time_modal == null
            ? true
            : false
        );
      } else {
        setInfoShow(true);
      }

      form.setFieldsValue({
        business_categories: dataUser.data[0].business_categories
          ? JSON.parse(dataUser.data[0].business_categories)
          : [],
      });

      if (
        dataUser.data[0].member_company &&
        dataUser.data[0].member_company.business_phone
      ) {
        form.setFieldsValue({
          business_phone: dataUser.data[0].member_company.business_phone,
        });
      } else {
        form.resetFields(["business_phone"]);
      }
      if (!dataUser.data[0].member_company) {
        if (dataUser.data && dataUser.data[0].email) {
          form.setFieldsValue({
            business_email: dataUser.data[0].email,
          });
        } else {
          form.resetFields(["business_email"]);
        }
      }
      if (dataUser.data && dataUser.data[0].country) {
        form.setFieldsValue({
          country: dataUser.data[0].country,
        });

        if (dataUser.data[0].country === "United States") {
          setStateLabel("State");
          setZipLabel("Zip Code");
          setOptionState(stateUS);
          setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
        } else if (dataUser.data[0].country === "Mexico") {
          setStateLabel("State");
          setZipLabel("Zip Code");
          setOptionState(stateMX);
          setOptionZip(/(^\d{5}$)/);
        } else if (dataUser.data[0].country === "Canada") {
          setStateLabel("County");
          setZipLabel("Postal Code");
          setOptionState(stateCA);
          setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
        } else {
          setStateLabel("State");
          setZipLabel("Zip Code");
          setOptionState(stateUS);
          setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
        }
      } else {
        form.resetFields(["country"]);
      }
    }
  }, [dataUser]);

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  useEffect(() => {
    forceUpdate({});
  }, []);

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
    require_false: {
      required: false,
      message: "Required",
    },
    email: {
      type: "email",
      message: "please enter a valid email",
    },
  };

  const [state, setState] = useState([]);

  useEffect(() => {
    let arr = [];
    states.map((row, key) => {
      if (
        row.name != "Federated States Of Micronesia" &&
        row.name != "Marshall Islands" &&
        row.name != "Palau" &&
        row.name != "Northern Mariana Islands"
      ) {
        arr.push({
          value: row.abbreviation,
          label: row.name,
        });
      }
    });
    setState(arr);
    // console.log("dataUser", dataUser);
  }, [dataUser]);

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } = UPDATE(
    `api/v1/users`,
    "users"
  );

  const onFinish = (values) => {
    // console.log("onFinish", values);
    let data = {
      id: dataUser.data[0].id,
      one_time_modal: 0,
      personal_info: {
        first_name: values.first_name,
        last_name: values.last_name,
        cell_number: values.cell_number,
        phone_number: values.phone_number,
        email_alternative: values.email_alternative,
        business_1: values.business_1,
        business_2: values.business_2,
        business_3: values.business_3,
        business_categories: values.business_categories,
        facebook: values.facebook ?? "",
        instagram: values.instagram ?? "" ,
        youtube: values.youtube ?? "",
        twitter: values.twitter ?? "",
        vimeo: values.vimeo ?? "",

        // youtube_vimeo: values.youtube_vimeo,
        youtube_vimeo: null,
      },
      business_info: {
        business_name: values.business_name,
        address_1: values.address_1,
        address_2: values.address_2,
        country: values.country,
        city: values.city,
        state: values.state,
        zip: values.zip,
        business_email: values.business_email,
        business_phone: values.business_phone,
      },
      // social_media: {
      //   instagram: values.instagram,
      //   youtube: values.youtube,
      // },
    };

    // console.log("data", data);
    mutateUpdateUser(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully save!",
          });
          setInfoShow(false);
        } else {
        }
      },
      onError: (err) => {},
    });
  };

  let business_category = [
    {
      label: "Dental Lab",
      value: "Dental Lab",
    },
    {
      label: "Dental Product",
      value: "Dental Product",
    },
    {
      label: "Dental Specialist",
      value: "Dental Specialist",
    },
    {
      label: "Dental Supply Company",
      value: "Dental Supply Company",
    },
    {
      label: "Dentist",
      value: "Dentist",
    },
    {
      label: "Educator",
      value: "Educator",
    },
    {
      label: "Medical Doctor",
      value: "Medical Doctor",
    },
    {
      label: "Motivational Speaker",
      value: "Motivational Speaker",
    },
    {
      label: "Other",
      value: "Other",
    },
    {
      label: "Professional Speaker",
      value: "Professional Speaker",
    },
    {
      label: "Professor",
      value: "Professor",
    },
    {
      label: "Study Club",
      value: "Study Club",
    },
  ];

  return (
    <Modal
      visible={infoShow}
      onOk={showModal}
      width={1000}
      className="userinfoModalDash"
      // onCancel={onClose}
      footer={null}
      style={{ top: 20 }}
      closable={false}
      maskClosable={false}
      zIndex={99999999}
    >
      {dataUser && (
        <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          onValuesChange={(changeedValue, values) => {
            // console.log(values);
          }}
          initialValues={{
            first_name: dataUser.data[0].first_name,
            last_name: dataUser.data[0].last_name,
            cell_number: dataUser.data[0].cell_number,
            phone_number: dataUser.data[0].phone_number,
            email_alternative: dataUser.data[0].email_alternative,
            business_1: dataUser.data[0].business_category_1,
            business_2: dataUser.data[0].business_category_2,
            business_3: dataUser.data[0].business_category_3,
            facebook: dataUser.data[0].facebook,
            instagram: dataUser.data[0].instagram,
            // youtube_vimeo: dataUser.data[0].youtube_vimeo,

            business_email: dataUser.data[0].member_company
              ? dataUser.data[0].member_company.business_email
              : "",
            business_name: dataUser.data[0].member_company
              ? dataUser.data[0].member_company.business_name
              : "",
            address_1: dataUser.data[0].member_company
              ? dataUser.data[0].member_company.address_1
              : "",
            address_2: dataUser.data[0].member_company
              ? dataUser.data[0].member_company.address_2
              : "",
            city: dataUser.data[0].member_company
              ? dataUser.data[0].member_company.city
              : "",
            state: dataUser.data[0].member_company
              ? dataUser.data[0].member_company.state
              : "",
            zip: dataUser.data[0].member_company
              ? dataUser.data[0].member_company.zip
              : "",
          }}
          form={form}
          onFinish={onFinish}
        >
          <Alert
            type="success"
            message={<b>Welcome {dataUser.data[0].first_name}</b>}
            description="To continue you must to complete all required fields"
          />
          <br />
          {/* <Typography.Title level={3}></Typography.Title> */}
          {/* <p>To continue you must to complete all required fields below.</p> */}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={14} lg={14}>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Personal Information"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="first_name"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="First Name"
                          placeholder="First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="last_name"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput label="Last Name" placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="cell_number"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInputMask
                          label="Cell Phone"
                          placeholder="Cell Phone"
                          maskLabel="cell_number"
                          maskType="999-999-9999"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item name="phone_number" hasFeedback>
                        <FloatInputMask
                          label="Work Phone"
                          placeholder="Work Phone"
                          maskLabel="phone_number"
                          maskType="999-999-9999"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        name="email_alternative"
                        rules={[validator.require_false, validator.email]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Email Address (Alternative/ Optional)"
                          placeholder="Email Address (Alternative/ Optional)"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        name="business_categories"
                        rules={[validator.require]}
                        hasFeedback
                        className="form-select-error-multi"
                      >
                        <FloatSelect
                          label="Business Category"
                          placeholder="Business Category"
                          options={business_category}
                          multi="multiple"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br></br>

              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Business Information"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        name="business_name"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Business / Practice Name"
                          placeholder="Business / Practice Name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="business_email"
                        rules={[validator.require, validator.email]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Business Email"
                          placeholder="Business Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="business_phone"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInputMask
                          label="Business Phone"
                          placeholder="Business Phone"
                          maskLabel="business_phone"
                          maskType="999-999-9999"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                    className="form-select-error"
                    hasFeedback
                  >
                    <FloatSelect
                      label="Country"
                      placeholder="Country"
                      options={country}
                      onChange={handleCountry}
                    />
                  </Form.Item>

                  <Form.Item
                    name="address_1"
                    rules={[validator.require]}
                    hasFeedback
                  >
                    <FloatInput label="Address 1" placeholder="Address 1" />
                  </Form.Item>
                  <Form.Item name="address_2" hasFeedback>
                    <FloatInput label="Address 2" placeholder="Address 2" />
                  </Form.Item>

                  <Form.Item
                    name="city"
                    rules={[validator.require]}
                    hasFeedback
                  >
                    <FloatInput label="City" placeholder="City" />
                  </Form.Item>

                  <Row gutter={24}>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        className="form-select-error"
                        name="state"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="zip"
                        rules={[
                          validator.require,
                          {
                            pattern: optionZip,
                            message: "Invalid " + zipLabel,
                          },
                        ]}
                        hasFeedback
                      >
                        <FloatInput label={zipLabel} placeholder={zipLabel} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} className="colMarginTopMobile">
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Social Media"
                  key="1"
                  className="accordion bg-darkgray-form "
                >
                  <Form.Item name="facebook" hasFeedback>
                    <FloatInput
                      label="Your Facebook Account (Optional)"
                      placeholder="Your Facebook Account (Optional)"
                    />
                  </Form.Item>
                  <Form.Item name="instagram" hasFeedback>
                    <FloatInput
                      label="Your Instagram Account (Optional)"
                      placeholder="Your Instagram Account (Optional)"
                    />
                  </Form.Item>
                  <Form.Item name="twitter" hasFeedback>
                    <FloatInput
                      label="Your Twitter Account (Optional)"
                      placeholder="Your Twitter Account (Optional)"
                    />
                  </Form.Item>
                  <Form.Item name="youtube" hasFeedback>
                    <FloatInput
                      label="Your Youtube Account (Optional)"
                      placeholder="Your Youtube Account (Optional)"
                    />
                  </Form.Item>
                  <Form.Item name="vimeo" hasFeedback>
                    <FloatInput
                      label="Your Vimeo Account (Optional)"
                      placeholder="Your Vimeo Account (Optional)"
                    />
                  </Form.Item>
                  {/* <Form.Item name="youtube_vimeo" hasFeedback>
                    <FloatInput
                      label="Your Youtube / Vimeo Account (Optional)"
                      placeholder="Your Youtube / Vimeo Account (Optional)"
                    />
                  </Form.Item> */}
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={14} lg={14}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    size="large"
                    className="btn-login-outline"
                    htmlType="submit"
                    style={{ width: "100%", marginTop: "20px" }}
                    icon={<SaveOutlined />}
                    // disabled={
                    //   !form.isFieldsTouched(true) ||
                    //   !!form
                    //     .getFieldsError()
                    //     .filter(({ errors }) => errors.length).length
                    // }
                    loading={isLoadingUpdateUser}
                  >
                    Save
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
}
