import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Carousel,
  Button,
  Space,
  notification,
} from "antd";
import image1 from "../../../../../assets/img/default_venue.jpg";

import companyInfo from "../../../../../providers/companyInfo";
import {
  FaFileInvoice,
  FaMobileAlt,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaTimes,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaVimeo,
  FaBuilding,
} from "react-icons/fa";
import {
  RightOutlined,
  LeftOutlined,
  MessageOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { POST } from "../../../../../providers/useAxiosQuery";
import getUserData from "../../../../../providers/getUserData";
import { useHistory } from "react-router-dom";

export default function ModalPreview({
  previewShow,
  setPreviewShow,
  showModal,
  previewData,
  // companyData,
}) {
  let userdata = getUserData();
  let history = useHistory();
  const sub_title = "All Features";
  useEffect(() => {
    console.log("previewData", previewData);
    return () => {};
  }, [previewData]);

  const onClose = () => {
    setPreviewShow(false);
  };

  const [firstName, setFirstName] = useState("");

  const [form] = Form.useForm();

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  let default_image = [
    {
      url: image1,
    },
    {
      url: image1,
    },
  ];

  const { mutate: mutateStartNewConvo, isLoading: isLoadingStartNewConvo } =
    POST("api/v1/message", `message_${userdata.id}`);
  const handleMakeNewConvo = (user_id) => {
    let data = {
      to_id: user_id,
      from_id: userdata.id,
    };
    console.log(data);
    mutateStartNewConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        if (res.success) {
          handleNewMessageConvo(res.data.id, user_id);
        } else {
          notification.error({ message: res.data });
        }
      },
    });
  };

  const { mutate: mutateNewMessageConvo, isLoading: isLoadingNewMessageConvo } =
    POST("api/v1/message_convo");

  const handleNewMessageConvo = (message_id, user_id) => {
    let messageText = `Hello! I want to inquire about ${previewData.venue_name}`;
    let data = {
      from_id: userdata.id,
      to_id: user_id,
      message_id: message_id,
      message: messageText,
    };
    console.log(data);
    mutateNewMessageConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        history.push(
          "/event-provider-speaker/messages?message_id=" + message_id
        );
      },
    });
  };

  return (
    <Modal
      visible={previewShow}
      width={650}
      className="modalPreviewVenue"
      onCancel={onClose}
      style={{ top: 20 }}
      // footer={<img src={image3} style={{ width: "100%" }}></img>}
      footer={null}
      closeIcon={<FaTimes className="modal-close-icon-black" />}
    >
      <div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="venueDescriptionTitleCompany">
              {previewData.venue_name}
            </div>
          </Col>
        </Row>
        <Row
          gutter={24}
          style={{ paddingRight: 0, marginTop: "20px" }}
          className="rowWrapModalPreview"
        >
          <Col xs={24} sm={24} md={14} lg={14} className="colCarousel">
            <div className="">
              <Carousel
                autoplay={true}
                arrows={true}
                prevArrow={<LeftOutlined />}
                nextArrow={<RightOutlined />}
              >
                {previewData.venue_images.length > 0 &&
                  previewData.venue_images.map((index, key) => {
                    return (
                      <div className="venueImage carouselLimit">
                        <img
                          src={companyInfo().apiUrl + "storage/" + index}
                          style={{ width: "100%", minHeight: 100 }}
                        ></img>
                      </div>
                    );
                  })}
                {previewData.venue_images.length <= 0 &&
                  default_image.map((row, key) => {
                    return (
                      <div className="venueImage carouselLimit">
                        <img
                          src={row.url}
                          style={{ width: "100%", minHeight: 100 }}
                        ></img>
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} className="colVenuePreviewLogo">
            <div className="colVenueFlex speakerColflex">
              <img
                src={
                  previewData.venue_logo
                    ? companyInfo().apiUrl + "storage/" + previewData.venue_logo
                    : "https://www.tibs.org.tw/images/default.jpg"
                }
                className="venuePreviewLogo"
              ></img>
            </div>
          </Col>
        </Row>
        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={14} lg={14}>
            {previewData.requirements && (
              <>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle"
                >
                  <b>REQUIREMENTS</b>
                </div>
                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData.requirements,
                  }}
                />
              </>
            )}
            {previewData.description && (
              <>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle "
                >
                  <b> DESCRIPTION / RESTRICTION</b>
                </div>

                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData.description,
                  }}
                />
              </>
            )}
            {previewData.direction_details && (
              <>
                <div
                  style={{ textTransform: "uppercase" }}
                  className="venueDescriptionSubTitle "
                >
                  <b> Direction Details</b>
                </div>

                <div
                  className="modalLineheight"
                  dangerouslySetInnerHTML={{
                    __html: previewData.direction_details,
                  }}
                />
              </>
            )}
          </Col>
          <Col xs={24} sm={24} md={10} lg={10}>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle modalLineheight "
            >
              <b>Contact Info</b>
            </div>
            <div className="">
              <div>
                <span className="iconDefualColor">
                  <FaBuilding />
                </span>{" "}
                {previewData.member_company &&
                previewData.member_company.business_name
                  ? previewData.member_company.business_name
                  : ""}
              </div>
            </div>
            <div className="">
              <div>
                <a className="venueLink" href={"tel:" + previewData.telephone}>
                  <span className="iconDefualColor">
                    <FaMobileAlt />
                  </span>{" "}
                  {previewData.telephone}
                </a>
              </div>
            </div>

            <div className="">
              <div>
                <span className="iconDefualColor">
                  <FaMapMarkerAlt />
                </span>{" "}
                {previewData.city}, {previewData.state}{" "}
              </div>
            </div>
            <div className="">
              <div>
                <a
                  className="venueLink"
                  href={"mailto:" + previewData.venue_email}
                >
                  <span className="iconDefualColor">
                    <FaEnvelope />
                  </span>{" "}
                  Email Me
                </a>
              </div>
            </div>
            <br></br>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle"
            >
              <b>ROOM RATE</b>
            </div>
            <div>
              ${previewData.room_rate} ({previewData.venue_type})
            </div>
            <br></br>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle"
            >
              <b>CAPACITY (# OF SEATS)</b>
            </div>
            <div>{previewData.capacity}</div>
            <br></br>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle"
            >
              <b>ROOM SIZE (SQ.FT.)</b>
            </div>
            <div>{previewData.room_size}</div>
            <br></br>
            <div
              style={{ textTransform: "uppercase" }}
              className="venueDescriptionSubTitle"
            >
              <b>CATERING</b>
            </div>
            <div>{previewData.catering == 1 ? "YES" : "NO"}</div>

            <br></br>
            {previewData.website_link && (
              <div className="venueDescriptionSubTitle">
                <a
                  href={previewData.website_link}
                  className="venueLink"
                  target={"_blank"}
                >
                  <span className="iconDefualColor iconDefualColorNoPosition">
                    <GlobalOutlined />
                  </span>{" "}
                  Website Link
                </a>
              </div>
            )}
            {previewData.website_booking_link && (
              <div className="venueDescriptionSubTitle">
                <a
                  href={previewData.website_booking_link}
                  className="venueLink"
                  target={"_blank"}
                >
                  <span className="iconDefualColor iconDefualColorNoPosition">
                    <GlobalOutlined />
                  </span>{" "}
                  Book Me
                </a>
              </div>
            )}
            <br></br>
            <Button
              tyle="link"
              // className="btn-primary"
              className="btn-login-outline"
              block
              icon={
                <MessageOutlined
                  style={{ marginLeft: "-8px", marginRight: "8px" }}
                />
              }
              onClick={(e) => handleMakeNewConvo(previewData.user_id)}
            >
              Chat with Venue Provider
            </Button>
          </Col>
        </Row>
      </div>
      <br />
      <div
        className="map_link"
        dangerouslySetInnerHTML={{
          __html: previewData.map_link,
        }}
      ></div>
    </Modal>
  );
}
