import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  Badge,
  Divider,
  Menu,
  Checkbox,
  Collapse,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import {
  HomeOutlined,
  SaveOutlined,
  FieldTimeOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  UserOutlined,
  EditOutlined,
  MinusOutlined,
  CreditCardFilled,
  BulbOutlined,
} from "@ant-design/icons";
import { faMapLocation, faCircleDot } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import { Pie } from "@ant-design/charts";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";

export default function PageFaqs({ props, permission }) {
  const sub_title = "Frequently Asked ";
  const { Panel } = Collapse;

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };
  const [form] = Form.useForm();

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageFaqs"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<BulbOutlined />}
      />

      <Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
        <Form wrapperCol={{ span: 24 }} layout="horizontal">
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="How to Check In Event Attendees"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/654723974?h=e93ed0df85&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Reset Password"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644214054?h=be7e8e769&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="My Company Details"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644214039?h=8957bd063a&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Create/Edit an Event"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644213804?h=d4d9ad6163&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Adding a Profile Account Photo"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644213760?h=d18ccd6d67&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Duplicate an Event"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644213960?h=bd0add295f&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Review Event Registered Attendees"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/645104703?h=e70afd644d&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Search for a Venue"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644214087?h=7f28a89b05&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Creating Attendees Certificates of Completion"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/645104788?h=e60ffe9f9f&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Reviewing All Attendees Payments"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/645104788?h=e60ffe9f9f&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Creating a Ticket"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644213846?h=7fa9fcbd48&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Ticketing"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/654658617?h=77fce76af9&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Upgrade or Renew Subscription"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644214143?h=a9adf1a76b&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Set up 2 Factor Authentication (2FA)"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644214135?h=bd8edde286&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Reset Password"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644214054?h=7be7e8e769&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Adding a Profile Account Photo"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/644213760?h=d18ccd6d67&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Set up Bank Details"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/645101788?h=f3929e48f9&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Attendees Payments"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/654658516?h=ca0b532b99&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                expandIcon={({ panelProps }) => (
                  <MinusOutlined className="collapseIcon" />
                )}
              >
                <Panel
                  header="Support Tickets"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    className="iframModalFaqs"
                    height="360"
                    src="https://player.vimeo.com/video/654658617?h=77fce76af9&amp;muted=1"
                    width="640"
                  ></iframe>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
