import React, { useState, useEffect } from "react";
import {
  Layout,
  Card,
  Form,
  // Input,
  Button,
  Row,
  Col,
  Image,
  Divider,
  notification,
  Alert,
  Space,
} from "antd";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import imageLogo from "../../../assets/img/CELIYA-Logo-Design.png";
import imageLogo1 from "../../../assets/img/logo-with-bg-removebg-preview.png";
// import companyInfo from "../../../providers/companyInfo";
import { GET, POST } from "../../../providers/useAxiosQuery";
// import { noAuto } from "@fortawesome/fontawesome-svg-core";
import FloatInput from "../../../providers/FloatInput";
import FloatInputPassword from "../../../providers/FloatInputPassword";
import FloatInputMask from "../../../providers/FloatInputMask";
import moment from "moment";

import {
  FaFileInvoice,
  FaMobileAlt,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaTimes,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaVimeo,
  FaGooglePlusSquare,
  FaGoogle,
  FaGooglePlusG,
} from "react-icons/fa";
import companyInfo from "../../../providers/companyInfo";

export default function PageLoginMaintenance() {
  let history = useHistory();

  const { data: dataGetCountryRestriction } = GET(
    "iamallowed",
    "iamallowed",
    (res) => {
      if (res.success == false) {
        history.push("/403");
      }
    }
  );
  // const [errorMessage, setErrorMessage] = useState();

  // console.log("window.location.origin", window.location.origin);

  const [form] = Form.useForm();
  const [formPassword] = Form.useForm();
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token != null) {
      let userdata = JSON.parse(localStorage.getItem("userdata"));
      if (userdata.role === "SUPER ADMIN") {
        history.push("/admin/dashboard");
      } else if (userdata.role === "EVENT PROVIDER") {
        history.push("/event-provider/dashboard");
      } else if (userdata.role === "VENUE PROVIDER") {
        history.push("/venue-provider/dashboard");
      } else if (userdata.role === "MEMBER") {
        history.push("/member/dashboard");
        window.location.href = "";
      } else if (userdata.role === "SPEAKER") {
        history.push("/speaker/dashboard");
      } else if (userdata.role === "EVENT PROVIDER/SPEAKER") {
        history.push("/event-provider-speaker/dashboard");
      }
    }
    // form.setFieldsValue({
    //   email: "",
    //   password: "",
    // });
    return () => {};
  }, []);

  const { mutate: mutateLogin, isLoading: isLoadingButtonLogin } = POST(
    "api/v1/login",
    "login"
  );

  const [subEnded, setSubended] = useState(false);

  const [showAuthCodeForm, setShowAuthCodeForm] = useState(false);
  const [uId, setUid] = useState(0);
  const validator = {
    require: {
      required: true,
      message: "Required",
    },
    require_false: {
      required: false,
      message: "Required",
    },
    email: {
      type: "email",
      message: "please enter a valid email",
    },
  };

  const onFinish = (values) => {
    mutateLogin(values, {
      onSuccess: (res) => {
        console.log("onFinish", res);
        if (res.token) {
          if (res.data.google2fa_enable === 1) {
            console.log("wew");
            setUid(res.data.id);
            setShowAuthCodeForm(true);
          } else {
            setSubended(false);
            console.log(res.data.role);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userdata", JSON.stringify(res.data));
            if (res.data.one_time_modal === 1) {
              localStorage.setItem("one_time_modal", 1);
            }
            if (res.data.role === "SUPER ADMIN") {
              window.location.href = "/admin/dashboard";
            } else if (res.data.role === "EVENT PROVIDER") {
              window.location.href = "/event-provider/dashboard";
            } else if (res.data.role === "VENUE PROVIDER") {
              window.location.href = "/venue-provider/dashboard";
            } else if (res.data.role === "MEMBER") {
              window.location.href = "/member/dashboard";
            } else if (res.data.role === "SPEAKER") {
              window.location.href = "/speaker/dashboard";
            } else if (res.data.role === "EVENT PROVIDER/SPEAKER") {
              window.location.href = "/event-provider-speaker/dashboard";
            }
          }
        } else {
          notification.error({
            message: res.message,
            description: res.description,
          });
        }
        // else {
        //   if (res.sub_ended) {
        //     setSubended(true);
        //     notification.error({
        //       message: res.message,
        //       description: res.description,
        //     });
        //   } else {
        //     setSubended(false);
        //     notification.error({
        //       message: res.message,
        //       description: res.description,
        //     });
        //   }
        // }
      },
      onError: (err) => {
        console.log(err);
        notification.error({
          message: "Error",
          description: "Username or Password is Invalid",
        });
      },
    });
  };

  const onFinishPassword = (values) => {
    // console.log("onFinishPassword", values);
    let data = {
      ...values,
      link: window.location.origin,
    };
    mutateForgot(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Please check your email.",
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Error",
          description: "Email Address Not Found",
        });
      },
    });
  };

  const { mutate: mutateForgot, isLoading: isLoadingForgot } = POST(
    "api/v1/forgot_password",
    "forgot_password"
  );

  const handleRegistrationLink = () => {
    history.push("/registration");
  };

  const [showPassword, setShowPassword] = useState(false);
  const hadleShowPassword = () => {
    // $('.forgot-password').show()
    setShowPassword(!showPassword);
  };

  const handleBack = () => {
    history.push("/");
  };

  let screenWitdh = window.innerWidth;
  // console.log("witdh", witdh);
  const { mutate: mutateverify2fa, isLoading: isLoadingverify2fa } = POST(
    "api/v1/verify2fa",
    `verify2fa`
  );

  const verifyCode = (val) => {
    var code = val.code.replace(/-/g, "");

    mutateverify2fa(
      { code: code, id: uId },
      {
        onSuccess: (res) => {
          if (res.success) {
            console.log(res);
            // setShowAuthCodeForm(false);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userdata", JSON.stringify(res.data));
            if (res.data.one_time_modal === 1) {
              localStorage.setItem("one_time_modal", 1);
            }
            if (res.data.role === "SUPER ADMIN") {
              window.location.href = "/admin/dashboard";
            } else if (res.data.role === "EVENT PROVIDER") {
              window.location.href = "/event-provider/dashboard";
            } else if (res.data.role === "VENUE PROVIDER") {
              window.location.href = "/venue-provider/dashboard";
            } else if (res.data.role === "MEMBER") {
              window.location.href = "/member/dashboard";
            } else if (res.data.role === "SPEAKER") {
              window.location.href = "/speaker/dashboard";
            }
          } else {
            notification.error({
              message: "Error",
              description: "Invalid Authenticator Code, Please try again",
            });
          }
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  window.onload = function () {
    // alert();
    // document.querySelector(".login-layout").click();

    const timeoutId = setTimeout(() => {
      $(".login-layout").click();
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  const click = () => {
    console.log("asd");
  };

  return (
    <Layout.Content onClick={click} className="login-layout login-back">
      <Row>
        <Col xs={24} sm={4} md={8}></Col>
        <Col
          xs={24}
          sm={16}
          md={8}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div style={{ padding: 30 }}>
            <Image
              onClick={handleBack}
              src={process.env.REACT_APP_LOGO}
              preview={false}
              style={{ width: "100%" }}
            />
          </div>
        </Col>
      </Row>
      <Button className="click" style={{ display: "none" }}></Button>
      <Row>
        <Col xs={24} sm={4} md={4} lg={8}></Col>
        <Col xs={24} sm={16} md={16} lg={8}>
          {!showAuthCodeForm && (
            <Card
              style={{
                // background: "transparent",
                border: "0px solid",
                textAlign: "center",
                height: "auto",
                borderRadius: "10px",
                margin: "0px 10px",
              }}
              headStyle={{
                borderBottom: "none",
                background: "transparent!important",
              }}
              bodyStyle={{
                padding: screenWitdh < 720 ? "35px 35px" : "35px 25px",
              }}
              className="login"
            >
              <Row className="flexdirection">
                <Col xs={24} md={24}>
                  <Form
                    name="basic"
                    layout="vertical"
                    className="login-form"
                    // style={{
                    //   marginTop: "-50px",
                    // }}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                  >
                    <span style={{ fontSize: "20px" }}>Create an Account</span>
                    <Button
                      type="primary"
                      size="large"
                      className="btn-loginNew-outline"
                      style={{
                        width: "100%",
                        marginTop: 10,
                        fontSize: "20px",
                        height: "45px",
                      }}
                      onClick={(e) => handleRegistrationLink()}
                    >
                      REGISTER HERE
                    </Button>
                    <Divider
                      style={{ background: "#2C0D0D", margin: "30px 0px" }}
                    />
                    <h2>
                      Already Have an Account?
                      {screenWitdh < 720 && (
                        <>
                          <br />
                        </>
                      )}{" "}
                      Sign in Here
                    </h2>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "This field field is required.",
                        },
                        // { type: "email" },
                      ]}
                      style={{ marginBottom: 10 }}
                      hasFeedback
                    >
                      <FloatInput
                        label="Username / E-mail"
                        placeholder="Username / E-mail"
                        autoFocus="true"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "This field field is required.",
                        },
                      ]}
                      hasFeedback
                      style={{ marginBottom: 10 }}
                    >
                      <FloatInputPassword
                        label="Password"
                        placeholder="Password"
                        // autoFocus="true"
                      />
                    </Form.Item>
                    {subEnded && (
                      <div
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        <Alert
                          message="Your subscription has been ended"
                          type="error"
                        />
                      </div>
                    )}
                    <div
                      style={{
                        textAlign: "left",
                        color: "#343a40",
                        fontSize: "13px",
                      }}
                    >
                      <span>
                        This page is protected by reCAPTCHA, and subject to
                        Google{" "}
                      </span>
                      {screenWitdh > 720 && <br />}
                      <span>
                        <a
                          href="https://policies.google.com/privacy?hl=en"
                          target="_blank"
                          style={{ color: "#293a56", fontWeight: "bold" }}
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://policies.google.com/terms?hl=en"
                          target="_blank"
                          style={{ color: "#293a56", fontWeight: "bold" }}
                        >
                          Terms of Services.
                        </a>
                      </span>
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isLoadingButtonLogin}
                      className="btn-loginNew-outline"
                      style={{
                        width: "100%",
                        marginTop: 10,
                        fontSize: "20px",
                        height: "45px",
                      }}
                    >
                      SIGN IN
                    </Button>
                    <br />
                    <br />
                    <div
                      className="forgot"
                      style={{
                        textAlign: "left",
                        color: "#343a40",
                        fontSize: "13px",
                      }}
                    >
                      <Link
                        type="link"
                        className="login-form-button"
                        size="small"
                        style={{
                          color: "#293a56",
                          fontWeight: "bold",
                          fontSize: "13px",
                        }}
                        to="#"
                        onClick={hadleShowPassword}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </Form>

                  {/* <Divider className="orLogin">OR</Divider> */}

                  {/* <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoadingButtonLogin}
                    className="btn-primary-outline"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      fontSize: "20px",
                      height: "45px",
                    }}
                  >
                    <Space className="sFB">
                      <div style={{ position: "relative", top: 4 }}>
                        <FaFacebook style={{ fontSize: 25 }} />
                      </div>
                      <div className="spaceTextLogin">
                        Sign in with Facebook
                      </div>
                    </Space>
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoadingButtonLogin}
                    className="btn-lightred-outline"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      fontSize: "20px",
                      height: "45px",
                    }}
                  >
                    <Space style={{ marginRight: 8 }} className="sGoogle">
                      <div style={{ position: "relative", top: 4 }}>
                        <FaGooglePlusG style={{ fontSize: 29 }} />
                      </div>
                      <div className="spaceTextLogin">Sign in with Google+</div>
                    </Space>
                  </Button> */}

                  {/* <Button
                    type="primary"
                    htmlType="submit"
                    // loading={isLoadingButtonLogin}
                    className="btn-login-outline"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      fontSize: "20px",
                      height: "45px",
                    }}
                  >
                    <Space style={{ marginRight: 17 }} className="sCL">
                      <div
                      // style={{ position: "relative", top: 4, fontSize: 28 }}
                      >
                        <img
                          style={{ width: 32, marginBottom: 4 }}
                          src={process.env.REACT_APP_LOGO_BUTTON}
                          preview={false}
                        />
                      </div>
                      <div className="spaceTextLogin">Sign in with CE.LIYA</div>
                    </Space>
                  </Button>

                  <p style={{ fontSize: 13, textAlign: "left", marginTop: 10 }}>
                    Use this option to login if subscribed to another CE.LIYA
                    account. Logins are universal requiring the same
                    email/username and password. This will sync your CE credits
                    and certificates data.
                  </p> */}

                  {showPassword && (
                    <Form
                      name="basic"
                      layout="vertical"
                      className="login-form"
                      style={{
                        marginTop: "-50px",
                      }}
                      onFinish={onFinishPassword}
                      form={formPassword}
                      autoComplete="off"
                    >
                      <br />
                      <br />
                      <br />
                      <br />
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                          {
                            type: "email",
                            message: "This is not a valid email",
                          },
                        ]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Enter your e-mail"
                          placeholder="Enter your e-mail"
                        />
                      </Form.Item>
                      <div
                        style={{
                          textAlign: "left",
                          marginTop: "-5px",
                          lineHeight: "1",
                        }}
                      >
                        <span
                          type="link"
                          className="login-form-button"
                          size="small"
                          style={{
                            color: "#293a56",
                            fontWeight: "bold",
                            fontSize: "13px",
                          }}
                        >
                          Password reset instructions will be sent to your
                          registered email address.
                        </span>
                      </div>

                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoadingForgot}
                        className="btn-loginNew-outline"
                        style={{
                          width: "100%",
                          marginTop: 10,
                          fontSize: "20px",
                          height: "45px",
                        }}
                      >
                        SUBMIT
                      </Button>
                    </Form>
                  )}
                </Col>
              </Row>
            </Card>
          )}
          {showAuthCodeForm && (
            <Card
              style={{
                // background: "transparent",
                border: "0px solid",
                textAlign: "center",
                height: "auto",
                borderRadius: "10px",
                margin: "0px 10px",
              }}
              headStyle={{
                borderBottom: "none",
                background: "transparent!important",
              }}
              bodyStyle={{
                padding: screenWitdh < 720 ? "35px 35px" : "35px 55px",
              }}
              className="login"
            >
              <Row className="flexdirection">
                <Col xs={24} md={24}>
                  <Form
                    name="basic"
                    layout="vertical"
                    className="login-form"
                    // style={{
                    //   marginTop: "-50px",
                    // }}
                    onFinish={verifyCode}
                    autoComplete="off"
                  >
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      <h3>Two-Factor Authentication Required</h3>
                      <p>Enter Authenticator Code </p>
                    </div>

                    <Form.Item
                      name="code"
                      rules={[validator.require]}
                      hasFeedback
                    >
                      <FloatInputMask
                        label="Authenticator Code"
                        placeholder="Authenticator Code"
                        maskLabel="code"
                        maskType="999-999"
                      />
                    </Form.Item>

                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isLoadingverify2fa}
                      className="btn-loginNew-outline"
                      style={{
                        width: "100%",
                        marginTop: 10,
                        fontSize: "20px",
                        height: "45px",
                      }}
                    >
                      SUBMIT
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <div style={{ width: "100%", textAlign: "center" }}>
        <br />
        <br />
        <span style={{ color: "#ddd" }}>
          © {moment().format("YYYY")} CE.LIYA. All Rights Reserved.
        </span>
        <br />
        <br />
        <br />
        <br />
      </div>

      {/* wewe */}
    </Layout.Content>
  );
}
