import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Space } from "antd";
import image2 from "../../../../../assets/img/doctor.png";
import companyInfo from "../../../../../providers/companyInfo";
import {
  FaFileInvoice,
  FaMobileAlt,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaTimes,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaVimeo,
  FaBuilding,
} from "react-icons/fa";
import {
  PhoneOutlined,
  MailOutlined,
  MessageOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { POST } from "../../../../../providers/useAxiosQuery";
import getUserData from "../../../../../providers/getUserData";
import { useHistory } from "react-router-dom";

export default function ModalPreviewProfile({
  previewShow,
  setPreviewShow,
  previewShowData,
}) {
  let userdata = getUserData();

  let history = useHistory();

  const onClose = () => {
    setPreviewShow(false);
  };

  const [imageProfile, setImageProfile] = useState();
  const [userCategoriesList, setUserCategoriesList] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  useEffect(() => {
    console.log("previewShowData", previewShowData);
    if (previewShowData) {
      let image_type = previewShowData.user.upload
        ? previewShowData.user.upload.split("/")
        : "";
      if (image_type[0] != undefined) {
        image_type =
          image_type[0] == "https:"
            ? previewShowData.user.upload
            : companyInfo().apiUrl + "storage/" + previewShowData.user.upload;
      } else {
        image_type =
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
      }
      setImageProfile(image_type);
      console.log("category", previewShowData.user.business_categories);
      let categories = JSON.parse(previewShowData.user.business_categories);
      let category = [];
      if (categories.length <= 2) {
        categories.map((item, key) => {
          category.push(item);
        });
      } else {
        categories.map((item, key) => {
          if (key <= 2) {
            category.push(item);
          }
        });
      }
      setUserCategoriesList(categories);
      setUserCategories(category);
    }

    return () => {};
  }, [previewShowData]);

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };

  const { mutate: mutateStartNewConvo, isLoading: isLoadingStartNewConvo } =
    POST("api/v1/message", `message_${userdata.id}`);
  const handleMakeNewConvo = (user_id) => {
    let data = {
      to_id: user_id,
      from_id: userdata.id,
    };
    console.log(data);
    mutateStartNewConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        handleNewMessageConvo(res.data.id, user_id);
      },
    });
  };

  const { mutate: mutateNewMessageConvo, isLoading: isLoadingNewMessageConvo } =
    POST("api/v1/message_convo");

  const handleNewMessageConvo = (message_id, user_id) => {
    let messageText = `Hello!`;
    let data = {
      from_id: userdata.id,
      to_id: user_id,
      message_id: message_id,
      message: messageText,
    };
    console.log(data);
    mutateNewMessageConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        history.push(
          "/event-provider-speaker/messages?message_id=" + message_id
        );
      },
    });
  };

  return (
    <Modal
      visible={previewShow}
      width={600}
      className="modalPreviewAttendees"
      onCancel={onClose}
      style={{ top: 20 }}
      footer={null}
      closeIcon={<FaTimes className="modal-close-icon" />}
    >
      {previewShowData && (
        <div>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={14}
              lg={14}
              style={{ textAlign: "center" }}
            >
              <img
                src={imageProfile}
                className="modalPreviewHeaderRightImg modalPreviewHeaderRightImgnewWidth"
              ></img>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10}>
              <div className="modalPreviewHeaderRight">
                <div
                  className="modalPreviewHeaderRightDate modalPreviewProfileName"
                  style={{ marginBottom: 10, marginTop: 17 }}
                >
                  {previewShowData.user.first_name +
                    " " +
                    previewShowData.user.last_name}
                </div>
                {userCategories.length != 0 &&
                  userCategories.map((item, key) => {
                    return (
                      <div key={key} style={{ fontSize: 15 }}>
                        {" "}
                        {item}{" "}
                      </div>
                    );
                  })}
                <br></br>
                <br></br>

                <div
                  className="btn-chat-with-div"
                  style={{
                    position: "absolute",
                    bottom: 14,
                    left: "15%",
                  }}
                >
                  <Button
                    tyle="link"
                    block
                    // className="btn-primary"
                    className="btn-chat-with"
                    style={
                      {
                        // background: "#800000 !important",
                        // height: "100%",
                        // width: "100%",
                        // border: "none",
                        // borderBottom: "1px solid #d6ae71",
                      }
                    }
                    icon={<MessageOutlined />}
                    onClick={(e) => handleMakeNewConvo(previewShowData.user.id)}
                  >
                    Chat with Attendee
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "normal",
                  padding: 15,
                  fontStyle: "italic",
                }}
              >
                {previewShowData.share_info == 0
                  ? "This member has opted to share their full details and information"
                  : "This member has not opted to share their full details and information"}
              </div>
            </Col>
          </Row>
          <Row
            gutter={12}
            style={{
              padding: 15,
            }}
          >
            <Col xs={24} sm={24} md={14} lg={14}>
              <div
                style={{ textTransform: "uppercase" }}
                className="venueDescriptionSubTitle modalLineheight"
              >
                <b>Business Info</b>
              </div>
              <div className="" style={{ marginTop: 2 }}>
                <span className="iconDefualColor">
                  <FaBuilding />
                </span>{" "}
                {previewShowData.user.member_company.business_name}
              </div>
              <div className="">
                <div>
                  <span className="iconDefualColor">
                    <FaMapMarkerAlt />
                  </span>{" "}
                  {previewShowData.user.member_company.city + ", "}
                  {previewShowData.user.member_company.state}
                </div>
              </div>
              <div className="">
                <div>
                  <a
                    className="venueLink"
                    href={
                      "tel:" +
                      previewShowData.user.member_company.business_phone
                    }
                  >
                    <span className="iconDefualColor">
                      <FaPhoneAlt />
                    </span>{" "}
                    {previewShowData.user.member_company.business_phone}
                  </a>
                </div>
              </div>
              {previewShowData.user.member_company.website && (
                <div className="venueDescriptionSubTitle">
                  <a
                    href={previewShowData.user.member_company.website}
                    className="venueLink"
                    target={"_blank"}
                  >
                    <span className="iconDefualColor iconDefualColorNoPosition">
                      <GlobalOutlined />
                    </span>{" "}
                    {previewShowData.user.member_company.website}
                  </a>
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} md={10} lg={10}>
              <div
                style={{ textTransform: "uppercase" }}
                className="venueDescriptionSubTitle modalLineheight"
              >
                <b>Contact Info</b>
              </div>
              <div className="" style={{ marginTop: 2 }}>
                <div>
                  <a
                    className="venueLink"
                    href={"tel:" + previewShowData.user.cell_number}
                  >
                    <span className="iconDefualColor">
                      <FaMobileAlt />
                    </span>{" "}
                    {previewShowData.user.cell_number}
                  </a>
                </div>
              </div>

              <div className="">
                <div>
                  <a
                    className="venueLink"
                    href={"mailto:" + previewShowData.user.email}
                  >
                    <span className="iconDefualColor">
                      <FaEnvelope />
                    </span>{" "}
                    Email Me
                  </a>
                </div>
              </div>
              {previewShowData.user.facebook ||
              previewShowData.user.instagram ||
              previewShowData.user.twitter ||
              previewShowData.user.youtube ||
              previewShowData.user.vimeo ? (
                <>
                  <br></br>{" "}
                  <div
                    style={{ textTransform: "uppercase" }}
                    className="venueDescriptionSubTitle"
                  >
                    <b>Social Media</b>
                  </div>
                  <div>
                    <Space size={"middle"}>
                      {previewShowData.user.facebook && (
                        <div className="iconDefualColor iconDefualColorNoPosition">
                          <a
                            href={previewShowData.user.facebook}
                            target="_blank"
                          >
                            <FaFacebook style={{ fontSize: "19px" }} />{" "}
                          </a>
                        </div>
                      )}

                      {previewShowData.user.instagram && (
                        <div className="iconDefualColor iconDefualColorNoPosition">
                          <a
                            href={previewShowData.user.instagram}
                            target="_blank"
                          >
                            {" "}
                            <FaInstagram style={{ fontSize: "20px" }} />{" "}
                          </a>
                        </div>
                      )}
                      {previewShowData.user.twitter && (
                        <div className="iconDefualColor iconDefualColorNoPosition">
                          <a
                            href={previewShowData.user.twitter}
                            target="_blank"
                          >
                            {" "}
                            <FaTwitter style={{ fontSize: "18px" }} />{" "}
                          </a>
                        </div>
                      )}

                      {previewShowData.user.youtube && (
                        <div className="iconDefualColor iconDefualColorNoPosition">
                          <a
                            href={previewShowData.user.youtube}
                            target="_blank"
                          >
                            {" "}
                            <FaYoutube style={{ fontSize: "22px" }} />{" "}
                          </a>
                        </div>
                      )}
                      {previewShowData.user.vimeo && (
                        <div className="iconDefualColor iconDefualColorNoPosition">
                          <a href={previewShowData.user.vimeo} target="_blank">
                            {" "}
                            <FaVimeo style={{ fontSize: "19px" }} />{" "}
                          </a>
                        </div>
                      )}
                    </Space>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
