import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Space } from "antd";
import {
  MenuFoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  MessageOutlined,
  MenuUnfoldOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import imageLogo from "../../../assets/img/cileya-logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faShop } from "@fortawesome/free-solid-svg-icons";
const menuItems = [
  {
    title: "Dashboard",
    path: "/venue-provider/dashboard",
    icon: <HomeOutlined />,
    permission: "Dashboard",
  },
  {
    title: "My Account",
    path: "/venue-provider/my-account",
    icon: <UserOutlined />,
    children: [
      {
        title: "My Company",
        path: "/venue-provider/my-account/my-company",
        permission: "My Company",
      },
      {
        title: "Account Profile",
        path: "/venue-provider/my-account/profile",
        permission: "Account Profile",
      },
      {
        title: "Subscriptions & Invoices",
        path: "/venue-provider/my-account/invoices",
        permission: "Subscriptions & Invoices",
      },
    ],
  },
  {
    title: "Venue Options",
    path: "/venue-provider/venue-options",
    icon: <FontAwesomeIcon className="venue-side-icon" icon={faShop} />,
    children: [
      {
        title: "Create a Venue",
        path: "/venue-provider/venue-options/create-venue",
        permission: "Create a Venue",
      },
      {
        title: "All Venues",
        path: "/venue-provider/venue-options/all-venue",
        permission: "All Venues",
      },
    ],
  },
  {
    title: "Support",
    path: "/venue-provider/support",
    icon: <QuestionCircleOutlined />,
    children: [
      {
        title: "FAQs",
        path: "/venue-provider/support/faqs",
        permission: "FAQs",
      },
      // {
      //   title: "Create Ticket",
      //   path: "/venue-provider/support/create-ticket",
      //   permission: "Create Ticket",
      // },
      {
        title: "Ticketing",
        path: "/venue-provider/support/ticketing",
        permission: "Ticketing",
      },
    ],
  },
  {
    title: "Messages",
    path: "/venue-provider/messages",
    icon: <MessageOutlined />,
    permission: "Messages",
  },
];

export default function ComponentsMemberSidemenu(props) {
  const { history, sideMenuCollapse, setSideMenuCollapse, width } = props;

  let selectedKeys = [];
  let pathname = history.location.pathname;
  pathname = pathname.split("/");

  if (pathname.length > 4) {
    selectedKeys.push(
      "/" + pathname[1] + "/" + pathname[2] + "/" + pathname[3]
    );
  }

  pathname = "/" + pathname[1] + "/" + pathname[2];

  const [openKeys, setOpenKeys] = useState();
  useEffect(() => {
    console.log("@pathname", pathname);
    console.log("@menu", menuItems);
    setOpenKeys(
      menuItems
        .filter((item) => item.path === pathname)
        .map((item) => item.path)
    );
  }, [history, pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const menuItemsFilter = menuItems
      .filter((item) => item.path === latestOpenKey)
      .map((item) => item.path);

    if (menuItemsFilter.indexOf(latestOpenKey) === -1) {
      setOpenKeys(menuItemsFilter);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const activeRoute = (routeName) => {
    const pathname = history.location.pathname;
    return pathname === routeName ? "ant-menu-item-selected" : "";
  };

  const activeSubRoute = (routeName) => {
    const pathname1 = history.location.pathname;
    const pathname2 = routeName;
    return pathname2 === pathname1 ? "ant-menu-item-selected" : "";
  };

  const handleMenuRender = () => {
    return menuItems.map((item, index) => {
      if (item.children && item.children.length > 0) {
        let children_list = item.children.map((item2, index2) => {
          return (
            <Menu.Item
              key={item2.path}
              className={`${activeSubRoute(item2.path)}`}
              onClick={() => {
                if (width <= 768) {
                  setSideMenuCollapse(true);
                }
              }}
            >
              <Link to={item2.path}>{item2.title ?? item2.permission}</Link>
            </Menu.Item>
          );
        });

        if (children_list && children_list.length > 0) {
          return (
            <Menu.SubMenu
              key={item.path}
              icon={item.icon}
              title={item.title}
              className={`${item.className ?? ""}`}
            >
              {children_list}
            </Menu.SubMenu>
          );
        }
      } else {
        return (
          <Menu.Item
            key={item.path}
            className={`${activeRoute(item.path)} ${item.className ?? ""}`}
            icon={item.icon}
            onClick={() => {
              if (width <= 768) {
                setSideMenuCollapse(true);
              }
            }}
          >
            <Link
              onClick={() => {
                setOpenKeys([]);
              }}
              to={item.path}
            >
              {item.title ?? item.permission}
            </Link>
          </Menu.Item>
        );
      }

      return "";
    });
  };

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={sideMenuCollapse}
      className="scrollbar-2 mobileSiderFixed"
    >
      <Space className="ant-side-header" size={10}>
        {sideMenuCollapse ? (
          <MenuUnfoldOutlined
            onClick={() => setSideMenuCollapse(false)}
            className="menuCollapseOnClose"
          />
        ) : (
          <MenuFoldOutlined
            onClick={() => setSideMenuCollapse(true)}
            className="menuCollapseOnClose"
          />
        )}

        {!sideMenuCollapse && (
          <img
            src={process.env.REACT_APP_LOGO_SIDE_BAR}
            className="menuCollapseLogo"
            alt="celiya"
          />
        )}
      </Space>

      <Menu
        mode="inline"
        theme="light"
        className="sideMenu"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
      >
        {handleMenuRender()}
      </Menu>
    </Layout.Sider>
  );
}
