import React, { useState, useEffect } from "react";
import {
	Modal,
	Button,
	Row,
	Col,
	Divider,
	Card,
	Badge,
	Pagination,
} from "antd";
import FloatInputSearch from "../../../../../providers/FloatInputSearch";
import moment from "moment";
import $ from "jquery";
import image from "../../../../../assets/img/default_venue.jpg";
import { GET } from "../../../../../providers/useAxiosQuery";
import companyInfo from "../../../../../providers/companyInfo";
import { FaTimes } from "react-icons/fa";
const ModalVenusList = ({ venueShow, setVenueShow, setVenueModalSelected }) => {
	const { Meta } = Card;
	const handleVenueHide = () => {
		setVenueShow(false);
		setVenueModalSelected([]);
	};
	const handleVenueSucess = () => {
		setVenueShow(false);
	};
	const [comfirm, setConfirm] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		page_number: 1,
		page_size: 50,
		column: "id",
		order: "desc",
	});

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};

	const {
		data: dataVenue,
		isLoading: isLoadingVenue,
		refetch: refetchVenue,
	} = GET(`api/v1/venue?${$.param(dataTableInfo)}`, "venue", (res) => {
		if (res.success) {
			// console.log("dataVenuea", res);
		}
	});

	useEffect(() => {
		refetchVenue();
		return () => {};
	}, [dataTableInfo]);

	const trimResponse = (response) => {
		let _response = response.split('<br><div className="gmail_quote">');
		_response = _response[0];
		return _response;
	};

	const handleSelected = (value) => {
		console.log("handleSelected", value);
		setVenueModalSelected(value);
		setConfirm(false);
		setVenueShow(false);
	};

	return (
		<Modal
			title="Venue List"
			visible={venueShow}
			onCancel={handleVenueHide}
			width="900px"
			closeIcon={<FaTimes className="modal-close-icon" />}
			// footer={[
			//   <Button onClick={handleVenueHide}>Cancel</Button>,
			//   <Button
			//     onClick={handleVenueSucess}
			//     className="btn-login-outline"
			//     disabled={comfirm}
			//   >
			//     Confirm
			//   </Button>,
			// ]}
			footer={false}
			className="modal-login"
		>
			<Row gutter={24}>
				<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
					<FloatInputSearch
						label="Search"
						placeholder="Search"
						dataTableInfo={dataTableInfo}
						setDataTableInfo={setDataTableInfo}
						value={searchText}
						onChange={setSearchText}
					/>
				</Col>
			</Row>
			<Divider />
			<Row gutter={24}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
					<Pagination
						showSizeChanger
						onChange={onChange}
						current={currentPage}
						pageSize={currentSize}
						total={dataVenue && dataVenue.total}
					/>
				</Col>
				{dataVenue &&
					dataVenue.data.data.map((row, key) => {
						let cover = row.venue_logo
							? companyInfo().apiUrl + "storage/" + row.venue_logo
							: image;
						return (
							<Col
								xs={24}
								sm={24}
								md={8}
								lg={8}
								xl={8}
								xxl={8}
								key={key}
								onClick={(e) => handleSelected(row)}
							>
								<Badge.Ribbon
									// text={row.venue_name.substring(0, 20)}
									text={
										row.venue_name?.length >= 20
											? row.venue_name.substring(0, 20) + "..."
											: row.venue_name
									}
									className="dash-badge-color"
								>
									{/* {" "} */}
									<Card
										hoverable
										style={{
											width: "100%",
											marginTop: 10,
											overflow: "hide",
										}}
										cover={<img alt="example" src={cover} />}
									>
										<Meta
											title={
												<>
													<div className="dash-para">
														<p className="c-secondary dash-time-font-size">
															<b>·</b> {" " + row.city}
														</p>
														<p className="c-secondary dash-time-font-size">
															<b>·</b> {" " + row.state}
														</p>
														<p className="c-secondary dash-time-font-size">
															<b>·</b> {" " + row.zip}
														</p>
														<p className="c-secondary dash-time-font-size">
															<b>·</b> {" " + row.country}
														</p>
													</div>
												</>
											}
											description={
												<div
													dangerouslySetInnerHTML={{
														__html:
															row.description?.length >= 125
																? row.description.substring(0, 125) + "..."
																: row.description,
													}}
												></div>
											}
										/>
									</Card>
								</Badge.Ribbon>
							</Col>
						);
					})}
			</Row>
		</Modal>
	);
};

export default ModalVenusList;
