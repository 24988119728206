import React, { useState, useEffect } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	Modal,
	Form,
	notification,
	Divider,
	Popconfirm,
} from "antd";
// import ComponentFaqs from "../Components/ComponentFaqs";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import $ from "jquery";
import FloatInputSearch from "../../../../../providers/FloatInputSearch";
import { DELETE, GET, POST } from "../../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";
import FloatInput from "../../../../../providers/FloatInput";

import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from "react-sortable-hoc";

import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

const DragHandle = SortableHandle(() => (
	<MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export default function TableFaqs({ id }) {
	const sub_title = "View";
	const history = useHistory();
	const [form] = Form.useForm();

	const modulesToolBar = {
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			["image", "video"],
			["clean"],
		],
		imageResize: {
			modules: ["Resize", "DisplaySize"],
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];
	const [responseData, setResponseData] = useState({
		response: "",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "index",
		order: "asc",
		account_type_id: id,
	});
	const [data, setData] = useState([]);
	const {
		data: dataGetProfiles,
		isLoading: isLoadingGetProfiles,
		refetch: refetchGetProfiles,
	} = GET(`api/v1/faq?${$.param(dataTableInfo)}`, "faq", (res) => {
		if (res.success) {
			console.log("faq", res);
			let arr = [];
			res.data.data.map((row, key) => {
				arr.push({
					key: row.id,
					index: key,
					account_type_id: row.account_type_id,
					title: row.title,
					description: row.description,
					id: row.id,
				});
			});
			setData(arr);
		}
	});

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				account_type_id: id,
			});
		});
		return () => {
			clearTimeout(timeoutId);
		};
	}, [id]);

	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchGetProfiles();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};

	const handleEdit = (record) => {
		// console.log("handleEdit", record);
		form.setFieldsValue({
			id: record.id,
			title: record.title,
		});
		setResponseData({ ...responseData, response: record.description });
		setModal(true);
	};

	const handleDelete = (record) => {
		let data = {
			id: record.id,
		};

		mutateDelete(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Succesfully Deleted",
					});
				}
			},
		});
	};

	const { mutate: mutateDelete, isLoading: isLoadingDelete } = DELETE(
		"api/v1/faq",
		"faq"
	);

	const [modal, setModal] = useState(false);
	const onFinish = (value) => {
		let data = {
			...value,
			account_type_id: id,
			description: responseData.response ? responseData.response : "",
		};
		console.log("onFinish", data);
		mutateAccountType(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Succesfully Submitted",
					});
					form.resetFields();
					setResponseData({
						response: "",
					});
					setModal(false);
				}
			},
		});
	};

	const { mutate: mutateAccountType, isLoading: isLoadingAccountType } = POST(
		"api/v1/faq",
		"faq"
	);

	const { mutate: mutateSort, isLoading: isLoadingSort } = POST(
		"api/v1/faq_sort",
		"faq_sort"
	);

	const onSortEnd = ({ oldIndex, newIndex }) => {
		// const { dataSource } = data;
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(
				[].concat(data),
				oldIndex,
				newIndex
			).filter((el) => !!el);
			console.log("Sorted items: ", newData);
			let data_sort = { sorted_data: JSON.stringify(newData) };
			mutateSort(data_sort, {
				onSuccess: (res) => {
					if (res.success) {
						console.log(res);
						notification.success({
							message: "Success",
							description: "Succesfully Save",
						});
					}
				},
			});
			setData(newData);
		}
	};

	const DraggableContainer = (props) => (
		<SortableBody
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
		return <SortableItem index={index} {...restProps} />;
	};

	const columns = [
		{
			title: "Sort",
			dataIndex: "sort",
			width: 30,
			className: "drag-visible",
			render: () => <DragHandle />,
		},
		{
			title: "Title",
			dataIndex: "title",
			width: 220,
			key: "title",
			className: "drag-visible",
			sorter: true,
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			className: "drag-visible",
			sorter: true,
			render: (text, record) => {
				return (
					<div
						dangerouslySetInnerHTML={{
							__html: text,
						}}
					></div>
				);
			},
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			className: "drag-visible",
			render: (text, record) => {
				return (
					<Space>
						<Button
							size="small"
							className="btn-warning-outline"
							icon={<EditOutlined />}
							onClick={(e) => handleEdit(record)}
						>
							Edit
						</Button>
						<Popconfirm
							title="Are you sure to delete this FAQ?"
							onConfirm={(e) => handleDelete(record)}
							// onCancel={cancel}
							okText="Yes"
							cancelText="No"
						>
							<Button
								size="small"
								className="btn-danger-outline"
								icon={<DeleteOutlined />}
								// onClick={(e) => handleDelete(record)}
							>
								Delete
							</Button>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Layout.Content style={{ paddingTop: "0px" }} id="TableFaqs">
			<Button
				className="btn-login-outline"
				icon={<PlusOutlined />}
				onClick={(e) => {
					setModal(true);
					setResponseData({
						response: "",
					});
				}}
			>
				Add FAQ's
			</Button>
			<br />
			<br />
			<Row gutter={24} className="search-table" align="bottom">
				<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
					<Pagination
						className="paginate"
						showSizeChanger
						onChange={onChange}
						current={currentPage}
						pageSize={currentSize}
						total={dataGetProfiles && dataGetProfiles.data.total}
						size="small"
					/>
				</Col>
				<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
					<FloatInputSearch
						label="Search..."
						placeholder="Search..."
						dataTableInfo={dataTableInfo}
						setDataTableInfo={setDataTableInfo}
						value={searchText}
						onChange={setSearchText}
					/>
				</Col>
			</Row>
			<br />
			<div className="table-responsive">
				<Table
					className="table-responsive table-thead-login"
					size="small"
					// rowKey={(record) => record.id}
					rowKey="index"
					loading={isLoadingGetProfiles}
					// dataSource={dataGetProfiles ? dataGetProfiles.data.data : []}
					dataSource={data}
					columns={columns}
					pagination={false}
					onChange={handleTableChange}
					// scroll={{ x: "fit-content" }}
					components={{
						body: {
							wrapper: DraggableContainer,
							row: DraggableBodyRow,
						},
					}}
				></Table>
			</div>
			<Modal
				title="FAQ Form"
				className="modal-login"
				visible={modal}
				width="700px"
				onCancel={(e) => {
					setModal(false);
					setResponseData({
						response: "",
					});
					form.resetFields();
				}}
				footer={
					<Space>
						<Button
							onClick={(e) => {
								setModal(false);
								form.resetFields();
							}}
						>
							Cancel
						</Button>
						<Button
							className="btn-login-outline"
							onClick={(e) => form.submit()}
							loading={isLoadingAccountType}
						>
							Submit
						</Button>
					</Space>
				}
			>
				<Form layout="horizontal" form={form} onFinish={onFinish}>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Form.Item name="id" style={{ display: "none" }}>
								<FloatInput label="id" placeholder="id" />
							</Form.Item>
							<Form.Item name="title" rules={[validator.require]} hasFeedback>
								<FloatInput label="Title" placeholder="Title" />
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<ReactQuill
								theme="snow"
								style={{ height: 200 }}
								modules={modulesToolBar}
								formats={formats}
								value={responseData.response}
								onChange={(e) => {
									setResponseData({ ...responseData, response: e });
								}}
							/>
							<br />
							<br />
						</Col>
					</Row>
				</Form>
			</Modal>
		</Layout.Content>
	);
}
