import React, { useState } from "react";
import $ from "jquery";
import {
	Layout,
	Card,
	Form,
	Input,
	Button,
	Row,
	Col,
	Image,
	Divider,
} from "antd";
import moment from "moment";
import FloatInput from "../../../providers/FloatInput";
import { CheckOutlined } from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";
import imageLogo from "../../../assets/img/logo.png";
import { PasswordInput } from "antd-password-input-strength";

export default function Page2FA() {
	let history = useHistory();

	const [form] = Form.useForm();
	const [formPassword] = Form.useForm();

	const onFinish = (values) => {};

	const handleBack = () => {
		history.push("/");
	};

	return (
		<Layout.Content
			className="login-layout login-back"
			// style={{
			//   paddingBottom: "10vh",
			//   background: "linear-gradient(180deg, white 0%, #e2c991 80%)",
			// }}
		>
			<Row>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
				<Col
					xs={24}
					sm={16}
					md={16}
					lg={16}
					xl={10}
					xxl={8}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<div style={{ padding: 30 }}>
						<Image
							src={imageLogo}
							onClick={handleBack}
							preview={false}
							style={{ width: "100%" }}
						/>
					</div>
				</Col>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
			</Row>

			<Row className="confirmemail">
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
				<Col xs={24} sm={16} md={16} lg={16} xl={10} xxl={8}>
					<Card
						style={{
							// background: "transparent",
							border: "0px solid",
							textAlign: "center",
							height: "auto",
							borderRadius: "10px",
						}}
						headStyle={{
							borderBottom: "none",
							background: "transparent!important",
						}}
						className="login"
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div size="large" shape="circle" type="link" className="editIcon">
								<CheckOutlined />
							</div>
							<div
								style={{
									textAlign: "left",
									lineHeight: "1.3",
									paddingTop: "7px",
								}}
							>
								<span style={{ fontSize: "16px" }}>Two Factor</span>
								<br />
								<span className="newMemberTitle newMemberTitleAuth">
									Authentication
								</span>
								<br />
							</div>
						</div>
						<Divider style={{ background: "#293a56", height: "2px" }} />
						<Row className="flexdirection">
							<Col xs={24} md={24}>
								<Form
									name="basic"
									layout="vertical"
									className="login-form"
									onFinish={onFinish}
									form={form}
									autoComplete="off"
								>
									<Form.Item
										name="password"
										rules={[
											{
												required: true,
												message: "This field is required!",
											},
										]}
										hasFeedback
									>
										<FloatInput
											label="Application Verification Code"
											placeholder="Application Verification Code"
										/>
									</Form.Item>
									<span
										className="VerificationCodeText"
										style={{
											marginTop: "-10px",
											position: "absolute",
										}}
									>
										Verification code is application generated and 6 digits
										long.
									</span>

									<Button
										type="primary"
										htmlType="submit"
										// loading={loadButton}
										className="btn-loginNew-outline"
										style={{
											width: "100%",
											marginTop: 20,
											fontSize: "20px",
											height: "45px",
										}}
									>
										VERIFY
									</Button>
									<br />
								</Form>
							</Col>
						</Row>
						{/* <Row className="flexdirection">
              <Col xs={24} md={24}>
                <br />
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    size="large"
                    shape="circle"
                    type="link"
                    className="editIcon"
                  >
                    <CheckOutlined />
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      lineHeight: "1.3",
                      paddingTop: "7px",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>Two Factor</span>
                    <br />
                    <span className="newMemberTitle">Authentication</span>
                    <br />
                  </div>
                </div>
                <Divider style={{ background: "#293a56", height: "2px" }} />

                <Form
                  name="basic"
                  layout="vertical"
                  className="login-form"
                  onFinish={onFinish}
                  form={form}
                  autoComplete="off"
                > 
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      }, 
                    ]}
                    hasFeedback
                  > 
                    <FloatInput
                      label=" Create Your Membership Account"
                      placeholder=" Create Your Membership Account"
                    />
                  </Form.Item>
                  <span style={{ marginTop: "-10px", position: "absolute" }}>
                    Verification code is application generated and 6 digits
                    long.
                  </span>
                  <br />

                  <Button
                    type="primary"
                    htmlType="submit" 
                    className="btn-login-outline"
                    style={{ fontSize: "20px", height: "45px" }}
                  >
                    VERIFY
                  </Button>
                </Form>

                <br />
                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <span>
                    © {moment().format("YYYY")} CE.LIYA. All Rights Reserved.
                  </span>
                </div>
              </Col>
            </Row> */}
					</Card>
				</Col>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
			</Row>
			<div style={{ width: "100%", textAlign: "center" }}>
				<br />
				<br />
				<span style={{ color: "white" }}>
					© {moment().format("YYYY")} CE.LIYA. All Rights Reserved..
				</span>
				<br />
				<br />
				<br />
				<br />
			</div>
		</Layout.Content>
	);
}
