const optionCourseTopic = [
  { value: "CPR (ACLS or PALS)", label: "CPR (ACLS or PALS)" },
  {
    value: "Ethics and Dental Jurisprudence",
    label: "Ethics and Dental Jurisprudence",
  },
  { value: "Ethics", label: "-Ethics" },
  { value: "Dental Jurisprudence", label: "-Dental Jurisprudence" },
  { value: "Risk management", label: "-Risk management" },
  {
    value: "Infection diseases and infectious disease control",
    label: "Infection diseases and infectious disease control",
  },
  { value: "Chemical dependency", label: "Chemical dependency" },
  {
    value: "Addiction-related disorders",
    label: "-Addiction-related disorders",
  },
  { value: "Opiod-related", label: "-Opiod-related" },
  {
    value: "Substance use disorder-related",
    label: "-Substance use disorder-related",
  },
  { value: "Tobacco cessation", label: "-Tobacco cessation" },
  { value: "Implantology", label: "Implantology" },
  { value: "Prosthodontics", label: "-Prosthodontics" },
  { value: "Practice Management", label: "Practice Management" },
  { value: "Business", label: "-Business" },
  {
    value: "Community Oral Health and Health Policy",
    label: "-Community Oral Health and Health Policy",
  },
  {
    value: "Dental practice Organization",
    label: "-Dental practice Organization",
  },
  { value: "Technology", label: "-Technology" },
  { value: "Ethics and law", label: "-Ethics and law" },
  {
    value: "Infection Control/Waste Management",
    label: "-Infection Control/Waste Management",
  },
  {
    value: "Methods of Health Care Delivery",
    label: "-Methods of Health Care Delivery",
  },
  { value: "Patient Management Skills", label: "-Patient Management Skills" },
  { value: "Clinical Dentistry", label: "Clinical Dentistry" },
  { value: "Anesthesia and Sedation", label: "-Anesthesia and Sedation" },
  {
    value: "Dental Materials and Bio Materials",
    label: "-Dental Materials and Bio Materials",
  },
  {
    value: "Dental public health and new concepts",
    label: "-Dental public health and new concepts",
  },
  { value: "Dental recordkeeping", label: "-Dental recordkeeping" },
  { value: "Dental Team", label: "-Dental Team" },
  {
    value: "Emergencies in the Dental Office",
    label: "-Emergencies in the Dental Office",
  },
  { value: "Endodontics", label: "-Endodontics" },
  { value: "Fixed Prosthetics", label: "-Fixed Prosthetics" },
  { value: "General Dentistry", label: "-General Dentistry" },
  { value: "Health and Wellness", label: "-Health and Wellness" },
  { value: "Laboratory procedures", label: "-Laboratory procedures" },
  {
    value: "Managing medical emergencies",
    label: "-Managing medical emergencies",
  },
  { value: "Oral Medicine", label: "-Oral Medicine" },
  { value: "Oral Surgery", label: "-Oral Surgery" },
  { value: "Pain management", label: "-Pain management" },
  { value: "Pediatric Dentistry", label: "-Pediatric Dentistry" },
  { value: "Periodontics", label: "-Periodontics" },
  {
    value: "Pharmacology and Therapeutics",
    label: "-Pharmacology and Therapeutics",
  },
  {
    value: "Radiology safety and techniques",
    label: "-Radiology safety and techniques",
  },
  { value: "Removable Prosthetics", label: "-Removable Prosthetics" },
  { value: "Sleep Medicine", label: "-Sleep Medicine" },
];

export default optionCourseTopic;
