import React, { useState, useEffect } from "react";
import {
	Card,
	Layout,
	Modal,
	Typography,
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Checkbox,
	Collapse,
	notification,
	Alert,
	Space,
} from "antd";
import { TextLoop } from "react-text-loop-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import { FaTimes } from "react-icons/fa";
import {
	HomeOutlined,
	SaveOutlined,
	FieldTimeOutlined,
	ClockCircleOutlined,
	CalendarOutlined,
	UserOutlined,
	EditOutlined,
	MinusOutlined,
	CreditCardFilled,
	LoadingOutlined,
	UpOutlined,
	DownOutlined,
} from "@ant-design/icons";
import { faMapLocation, faCircleDot } from "@fortawesome/free-solid-svg-icons";

import { Link, useHistory } from "react-router-dom";

import { Pie, useXFlowApp } from "@ant-design/charts";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatInputMask from "../../../../providers/FloatInputMask";
import termsAndCondition from "../../../../providers/termsAndCondition";

import states from "states-us";
import countryList from "react-select-country-list";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import moment from "moment";
import FloatSelectRegistration from "../../../../providers/FloatSelectRegistration";
import FloatSelectRegistration1 from "../../../../providers/FloatSelectRegistration1";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
export default function PageSubscription({
	props,
	permission,
	refetchDataInvoices,
}) {
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		form.resetFields(["state", "zip"]);
	};
	let userdata = getUserData();
	const history = useHistory();
	const [form] = Form.useForm();
	let country_list = countryList().getData();
	const terms = termsAndCondition();

	const sub_title = "CHANGE / RENEW";
	const { Panel } = Collapse;

	const [state, setState] = useState([]);
	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};

	const [memberPlanOptions, setMemberPlanOptions] = useState([]);
	const [currentPlan, setCurrentPlan] = useState();
	const [policy, setPolicy] = useState("");
	const [isLast, setIsLast] = useState(true);
	const [isFirst, setIsFirst] = useState(true);
	const { data: dataAccountType, isLoading: isLoadingAccountType } = GET(
		"api/v1/acc_type_per_user",
		"acc_type_per_user",
		(res) => {
			if (res.success) {
				console.log("acc_type", res);

				let currentPlan;
				if (res.current_plan) {
					currentPlan = res.current_plan.account_type_plans;
					currentPlan = (
						<>
							<div style={{ marginBottom: 0 }}>
								{currentPlan.plan} - ${currentPlan.amount}
							</div>
							<span className="c-secondary">{currentPlan.plan}</span>
						</>
					);
					setCurrentPlan(currentPlan);
				} else {
					currentPlan = "Not yet Subscribed to any Plan";
				}
				setPolicy(res.data[0].privacy.privacy_policy);

				let arr = [];
				res.data[0]["acc_plan"].map((row, index) => {
					arr.push({
						label: row.description,
						value: row.id,
						price: row.amount,
						plan: row.plan,
					});
				});
				// console.log("dataAccountType", arr);
				setMemberPlanOptions(arr);

				let arrcheckUpgrade = [];
				let arrcheckDownGrade = [];
				res.data[0]["acc_plan"].map((row, index) => {
					if (res.current_plan) {
						if (row.index > res.current_plan.account_type_plans.index) {
							arrcheckUpgrade.push({
								label: row.description,
								value: row.id,
								price: row.amount,
								plan: row.plan,
							});
						}
						if (row.index < res.current_plan.account_type_plans.index) {
							arrcheckDownGrade.push({
								label: row.description,
								value: row.id,
								price: row.amount,
								plan: row.plan,
							});
						}
					}
				});

				console.log("updarade", arrcheckUpgrade);
				console.log("donwgrade", arrcheckDownGrade);
				if (arrcheckUpgrade.length != 0) {
					setIsLast(false); //updarade
				}
				if (arrcheckDownGrade.length != 0) {
					setIsFirst(false); // donwgrade
				}
			}
		}
	);

	const [coupon, setCoupon] = useState(false);
	const [program, setProgram] = useState(0);

	const [couponError, setCouponError] = useState(false);

	const { mutate: mutateGetPlan, isLoading: isLoadingGetPlan } = POST(
		"api/v1/getPlan",
		"getPlan"
	);

	const handlerMemberProgram = (value, options) => {
		console.log(options);
		setCouponError(false);
		mutateGetPlan(
			{ id: value },
			{
				onSuccess: (res) => {
					if (res.success) {
						console.log(res);
						setProgram(parseFloat(res.data.amount));
						setProgramType(res.data.type);
					}
				},
				onError: (res) => {},
			}
		);
	};

	const { mutate: mutateApplyCoupon, isLoading: isLoadingApplyCoupon } = POST(
		"api/v1/apply_coupon_stripe_system",
		"apply_coupon_stripe_system"
	);
	const [couponDiscount, setCouponDiscount] = useState(0);
	const [couponDiscountTotal, setCouponDiscountTotal] = useState(0);
	const [couponDiscountTotal1, setCouponDiscountTotal1] = useState(0);
	const [couponFixedDiscount, setCouponFixedDiscount] = useState(0);
	const [isFixed, setIsFixed] = useState(false);

	const [typeCoupon, setTypeCoupon] = useState("");
	const [couponOff, setCouponOff] = useState(0);
	const [discountTotal, setDiscountTotal] = useState(0);
	const [programType, setProgramType] = useState("");

	const handleApplyCoupon = (e) => {
		var a = form.getFieldValue("coupon");
		// console.log("coupon", a);
		if (a) {
			mutateApplyCoupon(
				{ code: a, role: userdata.role },
				{
					onSuccess: (res) => {
						console.log("coupon_res ", res);

						if (res.success) {
							if (res.data) {
								if (parseInt(res.data.max) > parseInt(res.data.redemption)) {
									if (res.data.type == "fixed") {
										setCouponError(true);
										setTypeCoupon("fixed");
										setCouponOff(res.data.off);
										setDiscountTotal(program - res.data.off);
									}
									if (res.data.type == "percent") {
										setCouponError(true);
										setTypeCoupon("percent");
										setCouponOff(res.data.off);

										var percentage = res.data.off / 100;
										setDiscountTotal(program - program * percentage);
									}
									if (res.data.type == "offer") {
										console.log(res.data.off);
										setCouponError(true);
										setTypeCoupon("offer");
										setCouponOff(res.data.off);
										setDiscountTotal(program);
										// var days_to_divide = programType == "Monthly" ? 30 : 365;
										// var program_total_perday = program / days_to_divide;
										// var off_x_perday = res.data.off * program_total_perday;

										// var total = program - off_x_perday;

										// if (total >= 0) {
										//   setCouponError(true);
										//   setTypeCoupon("offer");
										//   setCouponOff(res.data.off);
										//   setDiscountTotal(total);
										// } else {
										//   setCouponError(false);
										//   notification.error({
										//     message: "Coupon Denied",
										//     description:
										//       "Can't Applied Coupon to this Subscription",
										//   });
										// }
									}
								} else {
									notification.error({
										message: "Coupon Denied",
										description: "Coupon usage limit has been reached",
									});
									setCouponError(false);
								}
							}
						} else {
							notification.error({
								message: "Coupon Denied",
								description: "No Such Coupon " + a,
							});
							setCouponError(false);
						}
					},
					onError: (err) => {
						console.log(err.response.data);
						notification.error({
							message: "Coupon Denied",
							description: err.response.data.message,
						});
						setCouponError(false);
					},
				}
			);
		} else {
			setCouponError(false);
		}
	};

	const formatMoney = (val) => {
		return (Math.round(val * 100) / 100).toLocaleString();
	};

	const [scroll, setScroll] = useState(false);
	const handleScroll = (e) => {
		// console.log("values");
		let element = e.target;
		if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
			setScroll(true);
		} else {
			setScroll(false);
		}
	};

	const [accept, setAccept] = useState(false);
	const onChange = (e) => {
		setAccept(e.target.checked);
	};
	const [yesDisabled, setYesDisabled] = useState(true);
	const [btnDisabled, setBtnDisabled] = useState(true);

	useEffect(() => {
		// if (scroll === true && accept === true) {
		if (scroll === true) {
			setYesDisabled(false);
		} else {
			setYesDisabled(true);
		}
	}, [scroll]);

	useEffect(() => {
		// if (scroll === true && accept === true) {
		if (accept === true) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [scroll, accept]);

	const { mutate: mutateChangePlan, isLoading: isLoadingChangelan } = POST(
		"api/v1/change_subscription",
		"acc_type_per_user"
	);

	const [expError, setExpError] = useState(false);
	const [cardError, setCardError] = useState(false);
	const [cvvError, setCvvError] = useState(false);

	const onFinish = (val) => {
		let data = {
			account_type: userdata.role,
			user_detials: userdata,
			user_plan: {
				program_plan: val.Select_Tier,
				coupon_status: couponError,
				coupon: val.coupon ? val.coupon : "",
				discountTotal: discountTotal > 0 ? discountTotal : 0,
				typeCoupon: typeCoupon,
				couponOff: couponOff,
			},
			credit_dat: {
				card_name: val.card_name,
				card_number: val.card_number ? val.card_number : "01",
				expiration_month: val.expiration ? val.expiration : "01/01",
				expiration_year: val.expiration_year,
				card_security_code: val.card_security_code,
			},
			billing_address: {
				address: val.address,
				address_extra: val.address_extra,
				city: val.city,
				state: val.state,
				zip: val.zip,
				country: val.country,
			},
			// cancel_at_period_end: val.auto_renew.length == 0 ? true : false,
			use_diff_card: true,
			total_price:
				program - discountTotal > 0 ? (program - discountTotal).toFixed(2) : 0,
			type: "Create Subscription",
		};

		console.log(data);

		mutateChangePlan(data, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					form.resetFields();
					form.setFieldsValue({
						auto_renew: ["A"],
					});
					setCouponError(false);
					setCardError(false);
					setExpError(false);
					refetchDataInvoices();
					notification.success({
						message: "Subscription",
						description: "Plan Successfully Subscribed",
					});
				}
			},
			onError: (err) => {
				console.log(err.response.data);
				if (
					err.response.data.message == "Your card's expiration year is invalid."
				) {
					setExpError(true);
					setCardError(false);
				}
				if (
					err.response.data.message == "Your card number is incorrect." ||
					err.response.data.message ==
						"The card number is not a valid credit card number."
				) {
					setCardError(true);
					setExpError(false);
				}

				notification.error({
					message: "Error",
					description: err.response.data.message,
				});
			},
		});
	};

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const { mutate: mutateCancelPlan, isLoading: isLoadingCancelPlan } = POST(
		"api/v1/cancel_subscription",
		"acc_type_per_user"
	);

	const handleOk = () => {
		mutateCancelPlan(userdata, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					setIsModalVisible(false);
					notification.success({
						message: "Subscription",
						description: "Successfully Canceled Your Plan",
					});
					setIsLast(false);
					setCurrentPlan("");
					// localStorage.removeItem("token");
					// localStorage.removeItem("userdata");
					// window.location.href = window.location.origin;
				}
			},
			onError: (err) => {
				console.log(err.response.data);
				notification.error({
					message: "Error",
					description: err.response.data.message,
				});
				setIsModalVisible(false);
			},
		});
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	useEffect(() => {
		form.setFieldsValue({
			auto_renew: ["A"],
		});
	}, []);

	const { mutate: mutateAutoRenew, isLoading: isLoadingAutRenew } = POST(
		"api/v1/cancelAutoRenew",
		"acc_type_per_user"
	);
	const handleToggleAutoRenew = (cancel_at_period_end) => {
		mutateAutoRenew(
			{
				stripe_subscription_id:
					dataAccountType.current_plan.stripe_subscription_id,
				cancel_at_period_end: cancel_at_period_end,
			},
			{
				onSuccess: (res) => {
					console.log("res", res);
				},
			}
		);
	};

	return (
		<div>
			{currentPlan ? (
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinish}
				>
					<Row gutter={4} className="webMT">
						<Col xs={24} sm={24} md={17} lg={17}>
							<div>
								<Collapse
									expandIcon={({ isActive }) =>
										isActive ? (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(270deg)" }}
											></span>
										) : (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(90deg)" }}
											></span>
										)
									}
									expandIconPosition="right"
									defaultActiveKey={["1"]}
								>
									<Panel
										header={`CURRENT MEMBERSHIP`}
										key="1"
										className="accordion bg-darkgray-form"
									>
										{currentPlan && (
											<>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														You Are Currently Subscribed to :
													</Col>
													<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
														{console.log("currentPlan", currentPlan)}
														<b>
															{currentPlan
																? currentPlan
																: "Not yet Subscribed to any Plan"}{" "}
														</b>
													</Col>
												</Row>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														Created Date :
													</Col>
													<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
														<b>
															{dataAccountType &&
																dataAccountType.stripe_data &&
																moment
																	.unix(dataAccountType.stripe_data.created)
																	.format("YYYY-MM-DD")}
															{dataAccountType &&
																dataAccountType.stripe_data.status ==
																	"trialing" &&
																" (free days)"}
															{/* {dataAccountType &&
                            dataAccountType.current_plan &&
                            moment(
                              dataAccountType.current_plan.created_at
                            ).format("YYYY-MM-DD")} */}
														</b>
													</Col>
												</Row>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														Period Start :
													</Col>
													<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
														<b>
															{dataAccountType &&
																dataAccountType.stripe_data &&
																moment
																	.unix(
																		dataAccountType.stripe_data
																			.current_period_start
																	)
																	.format("YYYY-MM-DD")}

															{/* {dataAccountType &&
                            dataAccountType.current_plan &&
                            dataAccountType.current_plan.date_from} */}
														</b>
													</Col>
												</Row>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														Period To :
													</Col>
													<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
														<b>
															{dataAccountType &&
																dataAccountType.stripe_data &&
																moment
																	.unix(
																		dataAccountType.stripe_data
																			.current_period_end
																	)
																	.format("YYYY-MM-DD")}
															{dataAccountType &&
																dataAccountType.stripe_data &&
																dataAccountType.stripe_data.status ==
																	"trialing" &&
																" (From Coupon Discount)"}
															{/* {dataAccountType &&
                            dataAccountType.current_plan &&
                            dataAccountType.current_plan.date_to} */}
														</b>
													</Col>
												</Row>
												<Row gutter={24}>
													<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
														Collection Method :
													</Col>
													<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
														<b>
															{dataAccountType &&
															dataAccountType.stripe_data &&
															dataAccountType.stripe_data.cancel_at_period_end
																? "Auto Renew Disabled"
																: "Auto Renew Enabled"}
														</b>
													</Col>
												</Row>
												<Row gutter={24}>
													<Col
														xs={24}
														sm={24}
														md={8}
														lg={8}
														xl={8}
														xxl={8}
													></Col>
													<Col
														xs={24}
														sm={24}
														md={12}
														lg={12}
														xl={12}
														xxl={12}
													></Col>
												</Row>
												<Row gutter={24}>
													<Col
														xs={24}
														sm={24}
														md={8}
														lg={8}
														xl={8}
														xxl={8}
													></Col>
													<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
														<b>
															{dataAccountType &&
																dataAccountType.stripe_data &&
																dataAccountType.stripe_data
																	.cancel_at_period_end == false && (
																	<a
																		href="#"
																		onClick={(e) => {
																			e.preventDefault();
																			handleToggleAutoRenew(true);
																		}}
																	>
																		Disable Auto Renew{" "}
																	</a>
																)}
															{dataAccountType &&
																dataAccountType.stripe_data &&
																dataAccountType.stripe_data
																	.cancel_at_period_end == true && (
																	<a
																		href="#"
																		onClick={(e) => {
																			e.preventDefault();
																			handleToggleAutoRenew(false);
																		}}
																	>
																		Enable Auto Renew{" "}
																	</a>
																)}
														</b>
													</Col>
												</Row>
											</>
										)}
										<br />
										{currentPlan && (
											<>
												<div>
													<div>
														<Space className="ant-space-subscription-buttons">
															{/* <Button
                                className="btn-primary-outline"
                                onClick={(e) =>
                                  history.push("/speaker/my-account/invoices")
                                }
                              >
                                Subscriptions & Invoices
                              </Button> */}
															<Button
																disabled={isLast}
																className="btn-warning-outline"
																onClick={(e) =>
																	history.push(
																		"/speaker/my-account/invoices/subscription/upgrade"
																	)
																}
															>
																Upgrade Subscription
															</Button>
															<Button
																disabled={isFirst}
																className="btn-login-outline"
																onClick={(e) =>
																	history.push(
																		"/speaker/my-account/invoices/subscription/downgrade"
																	)
																}
															>
																Downgrade Subscription
															</Button>
															{/* )} */}

															<Button
																className="btn-danger-outline"
																onClick={showModal}
															>
																Cancel Subscription
															</Button>
															<Button
																className="btn-success-outline"
																onClick={() => {
																	history.push(
																		"/speaker/my-account/invoices/subscription/credit-card"
																	);
																}}
															>
																Change Credit Card
															</Button>
														</Space>
													</div>
												</div>
											</>
										)}
									</Panel>
								</Collapse>
							</div>
						</Col>
					</Row>
				</Form>
			) : (
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinish}
				>
					<Row gutter={4} className="webMT">
						<Col xs={24} sm={24} md={16} lg={16}>
							<div>
								<Collapse
									expandIcon={({ isActive }) =>
										isActive ? (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(270deg)" }}
											></span>
										) : (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(90deg)" }}
											></span>
										)
									}
									expandIconPosition="right"
									defaultActiveKey={["1"]}
								>
									<Panel
										header="Select Your Membership Group"
										key="1"
										className="accordion bg-darkgray-form"
									>
										<div>
											{/* <Form.Item name={"auto_renew"}>
                        <Checkbox.Group>
                          <Checkbox value="A" style={{ lineHeight: "22px" }}>
                            <b>Renew my membership automatically.</b>
                          </Checkbox>
                        </Checkbox.Group>
                      </Form.Item> */}
											{userdata.role == "MEMBER" && (
												<div style={{ marginBottom: 10 }}>
													{dataAccountType &&
														dataAccountType.current_plan &&
														dataAccountType.current_plan
															.account_type_plan_id !== 1 && (
															<div>
																<b>GROUP 1:</b> Dental Assistants, Front Office
																Staff and Students ($9.95/month)
															</div>
														)}
													{dataAccountType &&
														dataAccountType.current_plan &&
														dataAccountType.current_plan.account_type_plan_id <
															2 && (
															<div>
																<b>GROUP 2:</b> Dental Hygienists, Retired
																Dentists and Dental Lab Techs ($12.95/month)
															</div>
														)}
													{dataAccountType &&
														dataAccountType.current_plan &&
														dataAccountType.current_plan.account_type_plan_id <
															3 && (
															<div>
																<b>GROUP 3:</b> Dentists ($24.95/month)
															</div>
														)}
												</div>
											)}
											<Form.Item
												name="Select_Tier"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelectRegistration1
													label="Select Group"
													placeholder="Select Group"
													options={memberPlanOptions}
													onChange={handlerMemberProgram}
												/>
											</Form.Item>
											<Row>
												<Col xs={24} sm={24} md={24} lg={24}>
													<Form.Item
														name="coupon"
														rules={[
															{
																required: false,
																message: "This field field is required.",
															},
														]}
													>
														<FloatInput
															label="Coupon"
															placeholder="Coupon"
															addonAfter={
																<Button
																	className="btn-login-outline"
																	disabled={isLoadingApplyCoupon}
																	style={{
																		height: "46px",
																		marginTop: "-1px",
																	}}
																	onClick={(e) => handleApplyCoupon(e)}
																>
																	{isLoadingApplyCoupon ? (
																		<LoadingOutlined />
																	) : (
																		""
																	)}{" "}
																	APPLY
																</Button>
															}
														/>
													</Form.Item>
													{couponError && (
														<span
															style={{
																color: "#23BF08",
																marginTop: "-23px",
																position: "absolute",
															}}
														>
															Code Successfully applied (
															{typeCoupon == "fixed" &&
																"$" + couponOff + " off"}
															{typeCoupon == "percent" &&
																"" + couponOff + "% off"}
															{typeCoupon == "offer" &&
																"" + couponOff + " days free"}
															)
														</span>
													)}
													<br></br>
													<br></br> <br></br>
													{!couponError && (
														<h3 style={{ marginTop: "-53px" }}>
															{" "}
															Total: ${program}
														</h3>
													)}
													{couponError && (
														<>
															{typeCoupon == "fixed" && (
																<>
																	<h4 style={{ marginTop: "-53px" }}>
																		{" "}
																		Subtotal: ${program}
																	</h4>
																	<h4 style={{ marginTop: "-10px" }}>
																		Discount: -${`${couponOff}`}
																	</h4>
																	<h3
																		style={{
																			marginTop: "-10px",
																			fontWeight: "bold",
																		}}
																	>
																		Total: $
																		{`${
																			discountTotal > 0
																				? discountTotal.toFixed(2)
																				: 0.0
																		}`}
																	</h3>
																</>
															)}
															{typeCoupon == "percent" && (
																<>
																	<h4 style={{ marginTop: "-53px" }}>
																		{" "}
																		Subtotal: ${program}
																	</h4>
																	<h4 style={{ marginTop: "-10px" }}>
																		Discount: {`${couponOff}`}%
																	</h4>
																	<h3
																		style={{
																			marginTop: "-10px",
																			fontWeight: "bold",
																		}}
																	>
																		Total: $
																		{`${
																			discountTotal > 0
																				? discountTotal.toFixed(2)
																				: 0.0
																		}`}
																	</h3>
																</>
															)}

															{typeCoupon == "offer" && (
																<>
																	<h4 style={{ marginTop: "-53px" }}>
																		{" "}
																		Subtotal: ${program}
																	</h4>
																	<h4 style={{ marginTop: "-10px" }}>
																		Discount: {`${couponOff}`} days free
																	</h4>
																	<h3
																		style={{
																			marginTop: "-10px",
																			fontWeight: "bold",
																		}}
																	>
																		Total: $
																		{`${
																			discountTotal > 0
																				? discountTotal.toFixed(2)
																				: 0.0
																		}`}
																	</h3>
																</>
															)}
														</>
													)}
												</Col>{" "}
											</Row>
										</div>
									</Panel>
								</Collapse>
							</div>
							<br></br>

							<div>
								<Alert
									message={
										<>
											<div className="textLoopDiv">
												A credit card is required to authenticate you are a real
												person. If using a coupon and if there is no payment for
												your account type, nothing will be charged.
											</div>{" "}
										</>
									}
									type="warning"
									showIcon
								/>
							</div>
							<br></br>
							<div>
								<Collapse
									expandIcon={({ isActive }) =>
										isActive ? (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(270deg)" }}
											></span>
										) : (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(90deg)" }}
											></span>
										)
									}
									expandIconPosition="right"
									defaultActiveKey={["1"]}
								>
									<Panel
										header="Credit Card Information"
										key="1"
										className="accordion bg-darkgray-form"
									>
										<div>
											<Row gutter={24}>
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
													<Form.Item
														name="card_name"
														rules={[
															{
																required: true,
																message: "This field field is required.",
															},
														]}
														hasFeedback
													>
														{/* <Input size="large" placeholder="Name on Card"/> */}
														<FloatInput
															label="Name on Card"
															placeholder="Name on Card"
														/>
													</Form.Item>
												</Col>
											</Row>

											<Row gutter={24}>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
													<Form.Item
														name="card_number"
														hasFeedback
														validateStatus={cardError ? "error" : "success"}
														help={
															cardError ? "Your card number is incorrect" : ""
														}
													>
														{/* <Input placeholder="Card Number" size="large" /> */}
														<FloatInputMask
															label="Card Number"
															placeholder="Card Number"
															maskLabel="card_number"
															maskType="9999-9999-9999-9999"
															validateStatus={cardError}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
													<Form.Item
														name="expiration"
														hasFeedback
														validateStatus={expError ? "error" : "success"}
														help={
															expError
																? "Your card's expiration year is invalid."
																: ""
														}
													>
														<FloatInputMask
															label="Exp."
															placeholder="Exp."
															maskLabel="expiration"
															maskType="99/99"
															validateStatus={expError}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
													<Form.Item
														name="card_security_code"
														rules={[
															{
																required: true,
																message: "Required.",
															},
														]}
														hasFeedback
													>
														{/* <DatePicker picker="year" size="large" style={{ width: "100%" }} /> */}
														<FloatInputMask
															label="CVV"
															placeholder="CVV"
															maskLabel="card_security_code"
															maskType="999"
														/>
													</Form.Item>
												</Col>
											</Row>
										</div>
									</Panel>
								</Collapse>
							</div>
							<br></br>
							<div>
								<Collapse
									expandIcon={({ isActive }) =>
										isActive ? (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(270deg)" }}
											></span>
										) : (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(90deg)" }}
											></span>
										)
									}
									expandIconPosition="right"
									defaultActiveKey={["1"]}
								>
									<Panel
										header="Billing Information"
										key="1"
										className="accordion bg-darkgray-form"
									>
										<div>
											<Form.Item
												name="country"
												rules={[
													{
														required: true,
														message: "This field field is required.",
													},
												]}
												className="form-select-error"
												hasFeedback
											>
												<FloatSelect
													label="Country"
													placeholder="Country"
													options={country}
													onChange={handleCountry}
												/>
											</Form.Item>
											<Form.Item
												name="address"
												rules={[
													{
														required: true,
														message: "This field field is required.",
													},
												]}
												hasFeedback
											>
												<FloatInput
													label="Street Address"
													placeholder="Street Address"
												/>
											</Form.Item>
											<Form.Item name="address_extra" hasFeedback>
												<FloatInput
													label="Street Address 2"
													placeholder="Street Address 2"
												/>
											</Form.Item>
											<Row gutter={24} style={{ marginBottom: "-27px" }}>
												<Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
													<Form.Item
														name="city"
														rules={[
															{
																required: true,
																message: "Required.",
															},
														]}
														hasFeedback
													>
														<FloatInput placeholder="City" label="City" />
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
													<Form.Item
														name="state"
														className="form-select-error"
														rules={[
															{
																required: true,
																message: "Required.",
															},
														]}
														hasFeedback
													>
														<FloatSelect
															label={stateLabel}
															placeholder={stateLabel}
															options={optionState}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
													<Form.Item
														name="zip"
														rules={[
															{
																required: true,
																message: "Required.",
															},
															{
																pattern: optionZip,
																message: "Invalid " + zipLabel,
															},
														]}
														hasFeedback
													>
														<FloatInput
															label={zipLabel}
															placeholder={zipLabel}
														/>
													</Form.Item>
												</Col>
											</Row>
											<br />
										</div>
									</Panel>
								</Collapse>
							</div>
							<br></br>
							<div>
								<Collapse
									expandIcon={({ isActive }) =>
										isActive ? (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(270deg)" }}
											></span>
										) : (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(90deg)" }}
											></span>
										)
									}
									expandIconPosition="right"
									defaultActiveKey={["1"]}
								>
									<Panel
										header="Privacy Policy And Terms & Conditions"
										key="1"
										className="accordion bg-darkgray-form"
									>
										<div>
											<div className="c-danger" style={{ marginTop: 10 }}>
												<b>Please read / scroll to the end to continue.</b>
											</div>
											<div
												onScroll={handleScroll}
												className="policyDiv"
												style={{
													marginBottom: 10,
													marginTop: 10,
													height: 170,
													resize: "vertical",
													overflow: "auto",
													border: "1px solid #d9d9d9",
												}}
												dangerouslySetInnerHTML={{ __html: policy }}
											></div>

											<Checkbox
												onChange={onChange}
												name="checkbox_2"
												className="optiona"
												id="dd"
												disabled={yesDisabled}
											>
												Yes, I have read the Privacy Policy and Terms and
												Conditions
											</Checkbox>
										</div>
									</Panel>
								</Collapse>
							</div>
							<div>
								<Button
									size="large"
									className="btn-login-outline"
									disabled={btnDisabled}
									style={{
										width: "100%",
										marginTop: "20px",
										marginRight: 10,
									}}
									htmlType="submit"
									isLoading={isLoadingChangelan}
								>
									Complete Purchase
								</Button>
							</div>
							<Divider></Divider>
						</Col>
					</Row>
				</Form>
			)}

			<Modal
				title={currentPlan}
				visible={isModalVisible}
				// onOk={handleOk}
				onCancel={handleCancel}
				// okText="Yes, Cancel my Subscription"
				// cancelText="No"
				closeIcon={<FaTimes className="modal-close-icon-black" />}
				footer={[
					<Button onClick={handleCancel}>No</Button>,
					<Button className="btn-login-outline" onClick={handleOk}>
						Yes, Cancel my Subscription
					</Button>,
				]}
			>
				<p style={{ fontSize: 22, fontWeight: "bold" }}>
					Do you want to cancel your Subscription?{" "}
				</p>
			</Modal>
		</div>
	);
}
