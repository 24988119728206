import React from "react";
import { Avatar, Menu, Typography } from "antd";

import { GoPrimitiveDot } from "react-icons/go";

import getUserData from "../../../../providers/getUserData";
import { Link, useHistory } from "react-router-dom";

const MessagesAlert = ({ messages }) => {
  let history = useHistory();
  // const { mutate: mutateRead, isLoading: isLoadingRead } = POST(
  // 	"api/v1/read",
  // 	"get_message_convo"
  // );

  let userdata = getUserData();

  const messageList = () => {
    if (messages && messages.length > 0) {
      return messages.map((item, index) => {
        let user;

        if (userdata.id === item.to_id) {
          user = item.from;
        }
        if (userdata.id === item.from_id) {
          user = item.to;
        }

        let image = user.upload;
        if (image) {
          image = image.includes("gravatar")
            ? image
            : `${process.env.REACT_APP_API_URL}storage/${image}`;
        } else {
          image = `${process.env.REACT_APP_API_URL}images/default.png`;
        }

        return (
          <Menu.Item
            key={index}
            onClick={() =>
              // history.push("/member/messages?message_id=" + item.message_id)
              {
                window.location.replace(
                  window.location.origin +
                    "/member/messages?message_id=" +
                    item.message_id
                );
              }
            }
            style={{ height: "60px" }}
            // icon={<Avatar src={image} style={{ width: 40, height: 40 }} />}
          >
            <Link to="#" style={{ paddingTop: "16px" }}>
              <Typography.Text strong>
                {user.first_name} {user.last_name}
              </Typography.Text>
              <Typography.Paragraph ellipsis={{ rows: 2 }}>
                {item.message}
              </Typography.Paragraph>
            </Link>

            {/* {item.unread === 1 ? (
              <span className="ant-status-container">
                <GoPrimitiveDot />
              </span>
            ) : null} */}
          </Menu.Item>
        );
      });
    } else {
      return (
        <Menu.Item key="#" className="text-center ant-notification">
          <Link to="#" style={{ paddingLeft: "47px" }}>
            No messages
          </Link>
        </Menu.Item>
      );
    }
  };

  return (
    <Menu>
      <Menu.Item key="#messages" className="text-center title">
        <Link to="#" style={{ color: "#293a56" }}>
          Messages
        </Link>
      </Menu.Item>
      <Menu.Divider key="#messagedivider" className="dividerMenuDropdown" />
      {messageList()}
    </Menu>
  );
};

export default MessagesAlert;
