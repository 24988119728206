import React, { useState, useEffect } from "react";
import {
	Card,
	Layout,
	Modal,
	Typography,
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Checkbox,
	Collapse,
	notification,
	Alert,
	Space,
} from "antd";
import { TextLoop } from "react-text-loop-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../EventProvider/Components/ComponentHeader";
import {
	HomeOutlined,
	SaveOutlined,
	FieldTimeOutlined,
	ClockCircleOutlined,
	CalendarOutlined,
	UserOutlined,
	EditOutlined,
	MinusOutlined,
	CreditCardFilled,
	LoadingOutlined,
	UpOutlined,
	DownOutlined,
	LeftOutlined,
} from "@ant-design/icons";
import { faMapLocation, faCircleDot } from "@fortawesome/free-solid-svg-icons";

import { Link, useHistory } from "react-router-dom";

import { Pie, useXFlowApp } from "@ant-design/charts";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../providers/FloatInput";
import FloatSelect from "../../../providers/FloatSelect";
import FloatSelectRegistration from "../../../providers/FloatSelectRegistration";
import FloatTextArea from "../../../providers/FloatTextArea";
import FloatInputMask from "../../../providers/FloatInputMask";
import termsAndCondition from "../../../providers/termsAndCondition";

import states from "states-us";
import countryList from "react-select-country-list";
import { GET, POST } from "../../../providers/useAxiosQuery";
import getUserData from "../../../providers/getUserData";
import moment from "moment";
import toCurrency from "../../../providers/toCurrency";
import optionCountryCodes from "../../../providers/optionCountryCodes";

import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../providers/optionStateCodesCanada";

export default function PageCreditCard({ props, permission }) {
	let userdata = getUserData();
	useEffect(() => {
		console.log("userdata", userdata);
		return () => {};
	}, []);

	let history = useHistory();
	const [form] = Form.useForm();
	let country_list = countryList().getData();
	const terms = termsAndCondition();

	const sub_title = "CHANGE / RENEW";
	const { Panel } = Collapse;

	const [state, setState] = useState([]);
	const [country, setCountry] = useState(optionCountryCodes);

	const [isDifferentCard, setIsDifferentCard] = useState(false);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const [dataFetch, setDataFetch] = useState([]);
	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		form.resetFields(["state", "zip"]);
		if (dataFetch.length > 0) {
			if (val === dataFetch[0].country) {
				form.setFieldsValue({
					state: dataFetch[0].state,
					zip: dataFetch[0].zip,
				});
			}
		}
	};

	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};

	const [memberPlanOptions, setMemberPlanOptions] = useState([]);
	const [currentPlan, setCurrentPlan] = useState();
	const [policy, setPolicy] = useState("");
	const { data: dataAccountType, isLoading: isLoadingAccountType } = GET(
		"api/v1/acc_type_per_user",
		"acc_type_per_user",
		(res) => {
			if (res.success) {
				console.log("acc_type", res);

				if (!res.current_plan) {
					history.push("/member/my-account/invoices");
				}
				setCurrentPlan(
					res.current_plan
						? res.current_plan.account_type_plans.description
						: ""
				);
				setPolicy(res.data[0].privacy.privacy_policy);
				let arr = [];
				res.data[0]["acc_plan"].map((row, index) => {
					if (res.current_plan) {
						if (row.index > res.current_plan.account_type_plans.index) {
							arr.push({
								label: row.description,
								value: row.id,
								price: row.amount,
								plan: row.plan,
							});
						}
					} else {
						arr.push({
							label: row.description,
							value: row.id,
						});
					}
				});
				console.log("dataAccountType", arr);
				setMemberPlanOptions(arr);
			}
		}
	);

	const [coupon, setCoupon] = useState(false);
	const [program, setProgram] = useState(0);

	const [couponError, setCouponError] = useState(false);

	const { mutate: mutateGetPlan, isLoading: isLoadingGetPlan } = POST(
		"api/v1/getPlan",
		"getPlan"
	);

	const [appliedBalance, setAppliedBalance] = useState(0);
	const [totalPrice, setTolalPrice] = useState(0);
	const [unUsedTime, setUsedTime] = useState(0);
	const [myBalance, setMybalance] = useState(0);

	const { mutate: mutateUpcomingInvoice, isLoading: isLoadingUpcomingInvoice } =
		POST("api/v1/upgrade_sub_upcoming_invoice", "upgrade_sub_upcoming_invoice");

	const handlerMemberProgram = (value, options) => {
		console.log(options);
		setCouponError(false);
		mutateUpcomingInvoice(
			{
				user_id: userdata.id,
				email: userdata.email,
				program_plan: value,
				account_type: userdata.role,
			},
			{
				onSuccess: (res) => {
					if (res.success) {
						// setProgram(
						//   parseFloat(res.data.subtotal / 100) -
						//     parseInt(options["data-price"])
						// );

						var priceRemaining = Math.abs(res.data.lines.data[1].amount / 100);
						var prevUseTime = Math.abs(res.data.lines.data[0].amount / 100);
						var creditBal = Math.abs(res.data.starting_balance / 100);
						var total = priceRemaining - prevUseTime;

						console.log(res);

						setUsedTime(Math.abs(res.data.lines.data[0].amount / 100));
						setMybalance(creditBal);

						if (total < creditBal) {
							var a = creditBal - total;
							var applied_balance = creditBal - a;
							setAppliedBalance(applied_balance);
							setTolalPrice(total - applied_balance);
						} else {
							setAppliedBalance(creditBal);
							setTolalPrice(total - creditBal);
						}
					}
				},
				onError: (res) => {},
			}
		);
	};

	const { mutate: mutateApplyCoupon, isLoading: isLoadingApplyCoupon } = POST(
		"api/v1/apply_coupon_stripe",
		"apply_coupon_stripe"
	);
	const [couponDiscount, setCouponDiscount] = useState(0);
	const [couponDiscountTotal, setCouponDiscountTotal] = useState(0);
	const [couponDiscountTotal1, setCouponDiscountTotal1] = useState(0);
	const handleApplyCoupon = (e) => {
		var a = form.getFieldValue("coupon");
		console.log("coupon", a);
		if (a) {
			mutateApplyCoupon(
				{ code: a, role: userdata.role },
				{
					onSuccess: (res) => {
						console.log(res);
						if (res.success) {
							setCouponError(true);
							notification.success({
								message: "Success",
								description: " Code Successfully applied",
							});
							//wewe
							setCouponDiscount(res.data.percent_off);
							var percent_off =
								(res.data.percent_off / 100) * parseFloat(program);
							setCouponDiscountTotal(formatMoney(percent_off));
							setCouponDiscountTotal1(percent_off);
						} else {
							notification.error({
								message: "Coupon Denied",
								description: res.message,
							});
						}
					},
					onError: (err) => {
						console.log(err.response.data);
						notification.error({
							message: "Coupon Denied",
							description: err.response.data.message,
						});
						setCouponError(false);
					},
				}
			);
		} else {
			setCouponError(false);
		}
	};

	const formatMoney = (val) => {
		return (Math.round(val * 100) / 100).toLocaleString();
	};

	const [scroll, setScroll] = useState(false);
	const handleScroll = (e) => {
		// console.log("values");
		let element = e.target;
		if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
			setScroll(true);
		} else {
			setScroll(false);
		}
	};

	const [accept, setAccept] = useState(false);
	const onChange = (e) => {
		setAccept(e.target.checked);
	};
	const [yesDisabled, setYesDisabled] = useState(true);
	const [btnDisabled, setBtnDisabled] = useState(true);

	useEffect(() => {
		// if (scroll === true && accept === true) {
		if (scroll === true) {
			setYesDisabled(false);
		} else {
			setYesDisabled(true);
		}
	}, [scroll]);

	useEffect(() => {
		// if (scroll === true && accept === true) {
		if (accept === true) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [scroll, accept]);

	const { mutate: mutateChangePlan, isLoading: isLoadingChangelan } = POST(
		"api/v1/change_subscription",
		"acc_type_per_user"
	);

	const [expError, setExpError] = useState(false);
	const [cardError, setCardError] = useState(false);
	const [cvvError, setCvvError] = useState(false);

	const onFinish1 = (val) => {
		let data = {
			account_type: userdata.role,
			user_detials: userdata,
			user_plan: {
				program_plan: val.Select_Tier,
				coupon_status: couponError,
				coupon: val.coupon ? val.coupon : "",
			},
			credit_dat: {
				card_name: val.card_name,
				card_number: val.card_number ? val.card_number : "01",
				expiration_month: val.expiration ? val.expiration : "01/01",
				expiration_year: val.expiration_year,
				card_security_code: val.card_security_code,
			},
			billing_address: {
				address: val.address,
				address_extra: val.address_extra,
				city: val.city,
				state: val.state,
				zip: val.zip,
				country: val.country,
			},
			// cancel_at_period_end: val.auto_renew.length == 0 ? true : false,
			use_diff_card: isDifferentCard,
		};
		console.log(data);

		mutateChangePlan(data, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					form.resetFields();
					form.setFieldsValue({
						auto_renew: ["A"],
					});
					setCouponError(false);
					setCardError(false);
					setExpError(false);
					notification.success({
						message: "Subscription",
						description: "Plan Successfully Subscribed",
					});
					history.push("/member/my-account/invoices");
				}
			},
			onError: (err) => {
				console.log(err.response.data);
				if (
					err.response.data.message == "Your card's expiration year is invalid."
				) {
					setExpError(true);
					setCardError(false);
				}
				if (
					err.response.data.message == "Your card number is incorrect." ||
					err.response.data.message ==
						"The card number is not a valid credit card number."
				) {
					setCardError(true);
					setExpError(false);
				}

				notification.error({
					message: "Error",
					description: err.response.data.message,
				});
			},
		});
	};

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const { mutate: mutateCancelPlan, isLoading: isLoadingCancelPlan } = POST(
		"api/v1/cancel_subscription",
		"acc_type_per_user"
	);

	const handleOk = () => {
		mutateCancelPlan(userdata, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					setIsModalVisible(false);
					notification.success({
						message: "Subscription",
						description: "Successfully Canceled Your Plan",
					});
					// localStorage.removeItem("token");
					// localStorage.removeItem("userdata");
					// window.location.href = window.location.origin;
				}
			},
			onError: (err) => {
				console.log(err.response.data);
				notification.error({
					message: "Error",
					description: err.response.data.message,
				});
				setIsModalVisible(false);
			},
		});
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	useEffect(() => {
		form.setFieldsValue({
			auto_renew: ["A"],
		});
	}, []);

	const { mutate: mutateAutoRenew, isLoading: isLoadingAutRenew } = POST(
		"api/v1/cancelAutoRenew",
		"acc_type_per_user"
	);
	const handleToggleAutoRenew = (cancel_at_period_end) => {
		mutateAutoRenew(
			{
				stripe_subscription_id:
					dataAccountType.current_plan.stripe_subscription_id,
				cancel_at_period_end: cancel_at_period_end,
			},
			{
				onSuccess: (res) => {
					console.log("res", res);
				},
			}
		);
	};

	const { mutate: mutateChangeCredit, isLoading: isLoadingCredit } = POST(
		"api/v1/mutateChangeCredit",
		"mutate_credti_change"
	);

	const onFinish = (val) => {
		let data = {
			user_detials: userdata,

			credit_dat: {
				card_name: val.card_name,
				card_number: val.card_number ? val.card_number : "01",
				expiration_month: val.expiration ? val.expiration : "01/01",
				expiration_year: val.expiration_year,
				card_security_code: val.card_security_code,
			},
			billing_address: {
				address: val.address,
				address_extra: val.address_extra,
				city: val.city,
				state: val.state,
				zip: val.zip,
				country: val.country,
			},
			// cancel_at_period_end: val.auto_renew.length == 0 ? true : false,
			use_diff_card: isDifferentCard,
		};
		console.log(data);

		mutateChangeCredit(data, {
			onSuccess: (res) => {
				if (res.success) {
					console.log(res);
					notification.success({
						message: "Success",
						description: "Credit Card Successfully Changed",
					});
					history.goBack();
				}
			},
			onError: (err) => {
				console.log(err.response.data);
				if (
					err.response.data.message == "Your card's expiration year is invalid."
				) {
					setExpError(true);
					setCardError(false);
				}
				if (
					err.response.data.message == "Your card number is incorrect." ||
					err.response.data.message ==
						"The card number is not a valid credit card number."
				) {
					setCardError(true);
					setExpError(false);
				}

				notification.error({
					message: "Error",
					description: err.response.data.message,
				});
			},
		});
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageCreditCard"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CreditCardFilled />}
			/>
			<br></br>

			<Layout.Content
				style={{ padding: "30px", paddingTop: "0px" }}
				className="webMT"
			>
				<Button
					style={{ marginBottom: 10 }}
					onClick={(e) => history.goBack()}
					className="btn-login-outline"
					icon={<LeftOutlined />}
				>
					Back
				</Button>
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinish}
				>
					<Row gutter={4}>
						<Col xs={24} sm={24} md={16} lg={16}>
							<div>
								<Collapse
									expandIcon={({ isActive }) =>
										isActive ? (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(270deg)" }}
											></span>
										) : (
											<span
												className="ant-menu-submenu-arrow"
												style={{ color: "#FFF", transform: "rotate(90deg)" }}
											></span>
										)
									}
									expandIconPosition="right"
									defaultActiveKey={["1"]}
								>
									<Panel
										header="Credit Card Information"
										key="1"
										className="accordion bg-darkgray-form"
									>
										<div>
											<Row gutter={24}>
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
													<Form.Item
														name="card_name"
														rules={[
															{
																required: true,
																message: "This field field is required.",
															},
														]}
														hasFeedback
													>
														{/* <Input size="large" placeholder="Name on Card"/> */}
														<FloatInput
															label="Name on Card"
															placeholder="Name on Card"
														/>
													</Form.Item>
												</Col>
											</Row>

											<Row gutter={24}>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
													<Form.Item
														name="card_number"
														hasFeedback
														validateStatus={cardError ? "error" : "success"}
														help={
															cardError ? "Your card number is incorrect" : ""
														}
													>
														{/* <Input placeholder="Card Number" size="large" /> */}
														<FloatInputMask
															label="Card Number"
															placeholder="Card Number"
															maskLabel="card_number"
															maskType="9999-9999-9999-9999"
															validateStatus={cardError}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
													<Form.Item
														name="expiration"
														hasFeedback
														validateStatus={expError ? "error" : "success"}
														help={
															expError
																? "Your card's expiration year is invalid."
																: ""
														}
													>
														<FloatInputMask
															label="Exp."
															placeholder="Exp."
															maskLabel="expiration"
															maskType="99/99"
															validateStatus={expError}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
													<Form.Item
														name="card_security_code"
														rules={[
															{
																required: true,
																message: "Required.",
															},
														]}
														hasFeedback
													>
														{/* <DatePicker picker="year" size="large" style={{ width: "100%" }} /> */}
														<FloatInputMask
															label="CVV"
															placeholder="CVV"
															maskLabel="card_security_code"
															maskType="999"
														/>
													</Form.Item>
												</Col>
											</Row>
										</div>
									</Panel>
								</Collapse>
								{/* <br></br> */}
								{/* <Collapse
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <span
                        className="ant-menu-submenu-arrow"
                        style={{ color: "#FFF", transform: "rotate(270deg)" }}
                      ></span>
                    ) : (
                      <span
                        className="ant-menu-submenu-arrow"
                        style={{ color: "#FFF", transform: "rotate(90deg)" }}
                      ></span>
                    )
                  }
                  expandIconPosition="right"
                  defaultActiveKey={["1"]}
                >
                  <Panel
                    header="Billing Information"
                    key="1"
                    className="accordion bg-darkgray-form"
                  >
                    <div>
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                        className="form-select-error"
                        hasFeedback
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={country}
                          onChange={handleCountry}
                        />
                      </Form.Item>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Street Address"
                          placeholder="Street Address"
                        />
                      </Form.Item>
                      <Form.Item name="address_extra" hasFeedback>
                        <FloatInput
                          label="Street Address 2"
                          placeholder="Street Address 2"
                        />
                      </Form.Item>
                      <Row gutter={24} style={{ marginBottom: "-27px" }}>
                        <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                          <Form.Item
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Required.",
                              },
                            ]}
                            hasFeedback
                          >
                            <FloatInput placeholder="City" label="City" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                          <Form.Item
                            name="state"
                            className="form-select-error"
                            rules={[
                              {
                                required: true,
                                message: "Required.",
                              },
                            ]}
                            hasFeedback
                          >
                            <FloatSelect
                              label={stateLabel}
                              placeholder={stateLabel}
                              options={optionState}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                          <Form.Item
                            name="zip"
                            rules={[
                              {
                                required: true,
                                message: "Required.",
                              },
                              {
                                pattern: optionZip,
                                message: "Invalid " + zipLabel,
                              },
                            ]}
                            hasFeedback
                          >
                            <FloatInput
                              label={zipLabel}
                              placeholder={zipLabel}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                    </div>
                  </Panel>
                </Collapse>
                <br></br> */}
							</div>
							<div>
								<Button
									size="large"
									className="btn-login-outline "
									style={{
										width: "100%",
										marginTop: "20px",
										marginRight: 10,
									}}
									htmlType="submit"
									isLoading={isLoadingCredit}
								>
									Submit
								</Button>
							</div>
						</Col>
					</Row>
				</Form>
			</Layout.Content>
		</Layout>
	);
}
