import React, { useState, useEffect } from "react";
import {
	Layout,
	Card,
	Form,
	// Input,
	Button,
	Row,
	Col,
	Image,
	Divider,
	notification,
	Alert,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import imageLogo from "../../../assets/img/CELIYA-Logo-Design.png";
// import companyInfo from "../../../providers/companyInfo";
import { POST } from "../../../providers/useAxiosQuery";
// import { noAuto } from "@fortawesome/fontawesome-svg-core";
import FloatInput from "../../../providers/FloatInput";
import FloatInputPassword from "../../../providers/FloatInputPassword";
import moment from "moment";
import FloatInputPasswordStrength from "../../../providers/FloatInputPasswordStrength";
import axios from "axios";
export default function PageConfirmEmail({ match }) {
	let history = useHistory();
	const [form] = Form.useForm();
	let token = match.params.token;
	let apiUrl = `${process.env.REACT_APP_API_URL}api/v1/`;
	let url = `newpassword/auth`;
	// console.log(token);

	React.useEffect(() => {
		axios
			.post(
				`${apiUrl}${url}`,
				{},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			)
			.then((res) => {
				console.log("success");
			})
			.catch((err) => {
				if (err.response.status === 401) {
					history.push("/error-500");
				}
			});
	}, []);

	const { mutate: mutateLogin, isLoading: isLoadingButtonLogin } = POST(
		"api/v1/login",
		"login"
	);
	let screenWitdh = window.innerWidth;

	const [loadButton, setLoadButton] = useState(false);
	const onFinishStepTwo = (values) => {
		console.log(values);
		setLoadButton(true);
		axios
			.post(
				`${apiUrl}${"create_pass/free"}`,
				{ password: values.password },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			)
			.then((response) => {
				notification.success({
					message: "Passoword Successfully Created!",
				});
				setLoadButton(false);
				let login = {
					email: response.data.user.email,
					password: values.password,
				};
				mutateLogin(login, {
					onSuccess: (res) => {
						if (res.token) {
							// console.log("register", res);
							localStorage.setItem("one_time_modal", 1);
							localStorage.setItem("token", res.token);
							localStorage.setItem("userdata", JSON.stringify(res.data));
							if (res.data.role === "EVENT PROVIDER") {
								window.location.href = "/event-provider/dashboard";
							} else if (res.data.role === "VENUE PROVIDER") {
								window.location.href = "/venue-provider/dashboard";
							} else if (res.data.role === "MEMBER") {
								window.location.href = "/member/dashboard";
							} else if (res.data.role === "SPEAKER") {
								window.location.href = "/speaker/dashboard";
							}
						} else {
							notification.warning({
								message: res.message,
								description: res.description,
							});
						}
					},
				});
			});
	};
	const handleBack = () => {
		history.push("/");
	};

	return (
		<Layout.Content className="login-layout login-back">
			<Row>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
				<Col
					xs={24}
					sm={16}
					md={16}
					lg={16}
					xl={10}
					xxl={8}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<div style={{ padding: 30 }}>
						<Image
							src={imageLogo}
							onClick={handleBack}
							preview={false}
							style={{ width: "100%" }}
						/>
					</div>
				</Col>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
			</Row>

			<Row className="confirmemail">
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
				<Col xs={24} sm={16} md={16} lg={16} xl={10} xxl={8}>
					<Card
						style={{
							// background: "transparent",
							border: "0px solid",
							textAlign: "center",
							height: "auto",
							borderRadius: "10px",
						}}
						headStyle={{
							borderBottom: "none",
							background: "transparent!important",
						}}
						className="login"
					>
						<Row className="flexdirection">
							<Col xs={24} md={24}>
								<Form
									name="basic"
									layout="vertical"
									className="login-form"
									onFinish={onFinishStepTwo}
									form={form}
									autoComplete="off"
								>
									<span style={{ fontSize: "20px" }}>
										Please Create Your Password
									</span>
									<br />
									<span>
										Your password must be at least 8 characters and contain at
										least one number, one uppercase letter and one special
										character.
									</span>
									<Divider style={{ background: "#293a56" }} />

									<Form.Item
										name="password"
										rules={[
											{
												required: true,
												message: "Please input your password!",
											},
											{
												min: 8,
												message: "Password must be minimum 8 characters.",
											},
											{
												pattern:
													/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
												message: "Invalid Password",
											},
										]}
										hasFeedback
									>
										<FloatInputPasswordStrength
											label="Password"
											placeholder="Password"
										/>
									</Form.Item>
									<Form.Item
										name="confirm"
										dependencies={["password"]}
										hasFeedback
										rules={[
											{
												required: true,
												message: "Please confirm your password!",
											},
											{
												min: 8,
												message: "Password must be minimum 8 characters.",
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue("password") === value) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															"The two passwords that you entered do not match!"
														)
													);
												},
											}),
										]}
									>
										<FloatInputPasswordStrength
											label="Confirm Password"
											placeholder="Confirm Password"
										/>
									</Form.Item>

									<Button
										type="primary"
										htmlType="submit"
										loading={loadButton}
										className="btn-loginNew-outline"
										style={{
											width: "100%",
											marginTop: 10,
											fontSize: "20px",
											height: "45px",
										}}
									>
										SAVE
									</Button>
									<br />
									<br />
								</Form>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs={24} sm={4} md={4} lg={4} xl={7} xxl={8}></Col>
			</Row>
			<div style={{ width: "100%", textAlign: "center" }}>
				<br />
				<br />
				<span style={{ color: "white" }}>
					© {moment().format("YYYY")} CE.LIYA. All Rights Reserved..
				</span>
				<br />
				<br />
				<br />
				<br />
			</div>
		</Layout.Content>
	);
}
