import React, { useState, useEffect, useRef } from "react";
import {
	Card,
	Layout,
	Modal,
	Typography,
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	Row,
	Col,
	Badge,
	Divider,
	Menu,
	Checkbox,
	Table,
	Tag,
	Space,
	Collapse,
	Pagination,
	Tooltip,
	Alert,
	notification,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import copyToClipboard from "../../../../providers/copyToClipboard";
import {
	CalendarOutlined,
	TwitterOutlined,
	InstagramOutlined,
	LinkedinOutlined,
	MinusOutlined,
	MailOutlined,
	TeamOutlined,
	MessageOutlined,
} from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import moment from "moment";
import $ from "jquery";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { ImMail4 } from "react-icons/im";
import getUserData from "../../../../providers/getUserData";

import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	EmailShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	EmailIcon,
} from "react-share";
import ModalPreviewProfile from "./Modals/ModalPreviewProfile";

export default function PageIndividualEvent({ match, permission }) {
	let userdata = getUserData();
	let history = useHistory();
	const { Search } = Input;
	const sub_title = "Individual";
	const { Panel } = Collapse;
	const data = [
		{
			key: "1",
			firstname: "Justin",
			lastname: "Clegg",
			phone: "602-316-6545",
			categories: "Office Coordinator,Office Manager",
			attendance_status: "Registered",
			attendance_details: "Day 1: Day 2:",
			email: "1",
		},
	];

	const [eventData, setEventData] = useState([]);

	const { data: datGetById, isLoading: isLoadingGetById } = GET(
		`api/v1/event/${match.params.id}`,
		"event",
		(res) => {
			if (res.success) {
				console.log("res", res);
				setEventData({
					user_id: res.data[0].user_id,
					event_type: res.data[0].event_type,
					cost: res.data[0].cost,
					event_time: res.data[0].event_schedules,
					rscp_datetime: res.data[0].rscp_datetime,
					public_status: res.data[0].public_status,
					meeting_url: res.data[0].event_venue.meeting_url,
					venue_name: res.data[0].event_venue.venue_name,
					country: res.data[0].event_venue.country,
					address_1: res.data[0].event_venue.address_1,
					address_2: res.data[0].event_venue.address_2,
					city: res.data[0].event_venue.city,
					state: res.data[0].event_venue.state,
					zip: res.data[0].event_venue.zip,
					map_link: res.data[0].event_venue.map_link,
					direction_details: res.data[0].event_venue.direction_details,
					meeting_details: res.data[0].meeting_details,
					title: res.data[0].event_details.title,
					sub_title: res.data[0].event_details.sub_title,
					director: res.data[0].event_details.director,
					facilitator: res.data[0].event_details.facilitator,
					summary: res.data[0].event_details.summary,
					description: res.data[0].event_details.descrition,
					restriction: res.data[0].event_details.restriction,
					what_to_bring: res.data[0].event_details.what_to_bring,
					ce_credit_hours: res.data[0].event_details.ce_credit_hours,
					seat_available: res.data[0].event_details.seat_available,
					completion_code: res.data[0].event_details.completion_code,
					subject_code: res.data[0].event_details.subject_code,
					course_topic: res.data[0].event_details.course_topic,
					attending_participants: JSON.parse(
						res.data[0].event_details.attending_participants
					),
					your_phone: res.data[0].event_details.your_phone,
					your_email: res.data[0].event_details.your_email,
					verification: JSON.parse(res.data[0].event_details.verification),
					published_status: res.data[0].event_save_status,

					event_speaker: res.data[0].event_speaker,
					event_img:
						res.data[0].event_details &&
						companyInfo().apiUrl +
							"storage/" +
							res.data[0].event_details.upload,
					company_of_venue: res.data[0].event_venue.venue
						? res.data[0].event_venue.venue.member_company.business_name
						: "",
				});

				// setVenueTBD(res.data[0].venue_tbd);
				// setAwaiting(res.data[0].event_details.allow_waiting_list);
			}
		}
	);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [searchText, setSearchText] = useState("");
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
		id: match.params.id,
	});

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataGetEvent,
		isLoading: isLoadingGetEvent,
		refetch: refetchGetEvent,
		isFetching: isFetchingGetEvent,
	} = GET(
		`api/v1/event_with_attendees?${$.param(dataTableInfo)}`,
		"event_with_attendees",
		(res) => {
			if (res.success) {
				// console.log("tae", res);
			}
		}
	);

	useEffect(() => {
		refetchGetEvent();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		// console.log("current", current);
		// console.log("pageSize", pageSize);
	};

	const [selectionType, setSelectionType] = useState("checkbox");
	const [emailCopy, setEmailCopy] = useState([]);

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			setEmailCopy(selectedRows);
		},
	};

	const emailingMembers = () => {
		const arr = [];
		emailCopy.forEach((ind, key) => {
			arr.push(ind.email);
		});
		let text = arr.join();
		copyToClipboard(text);
	};
	const tableAttending = useRef(null);

	const scrollView = () => {
		tableAttending.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	const { mutate: mutateStartNewConvo, isLoading: isLoadingStartNewConvo } =
		POST("api/v1/message", `message_${userdata.id}`);
	const handleMakeNewConvo = (user_id, messageText = "Hello!") => {
		let data = {
			to_id: user_id,
			from_id: userdata.id,
		};
		console.log(data);
		mutateStartNewConvo(data, {
			onSuccess: (res) => {
				console.log("wew", res);
				if (res.success) {
					handleNewMessageConvo(res.data.id, user_id, messageText);
				} else {
					notification.error({ message: res.data });
				}
			},
		});
	};

	const { mutate: mutateNewMessageConvo, isLoading: isLoadingNewMessageConvo } =
		POST("api/v1/message_convo");

	const handleNewMessageConvo = (message_id, user_id, messageText) => {
		// let messageText = `Hello!`;
		let data = {
			from_id: userdata.id,
			to_id: user_id,
			message_id: message_id,
			message: messageText,
		};
		console.log(data);
		mutateNewMessageConvo(data, {
			onSuccess: (res) => {
				console.log(res);
				history.push("/member/messages?message_id=" + message_id);
			},
		});
	};

	const [previewShow, setPreviewShow] = useState(false);
	const [previewShowData, setPreviewShowData] = useState(false);
	const showModal = (data) => {
		setPreviewShow(true);
		setPreviewShowData(data);
	};

	return (
		eventData.length != 0 && (
			<Layout
				className="site-layout-background"
				style={{
					padding: "0px 0px 20px 0px",
					background: "#fff",
				}}
				id="PageIndividualEvent"
			>
				<ComponentHeader
					sub_title={sub_title}
					permission={permission}
					icon={<CalendarOutlined />}
				/>
				<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
					<br></br>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
							{/* <img style={{ width: "100%" }} src={eventData.event_img}></img> */}
							<img
								style={{
									width: "100%",
									opacity: eventData.published_status == "Cancelled" ? 0.5 : 1,
								}}
								src={eventData.event_img}
							></img>

							{eventData.published_status == "Cancelled" && (
								<div
									style={{
										position: "absolute",
										top: "40%",
										left: 0,
										width: "100%",
										textAlign: "center",
									}}
								>
									<Alert
										style={{
											background: "#800000",

											borderColor: "#800000",
										}}
										message={
											<div style={{ color: "white" }}>
												Event Cancelled
												<br></br>
											</div>
										}
									/>
								</div>
							)}
						</Col>
						<Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
							<Row gutter={24}>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
									{/* <div className="indiDay">
                  </div> */}
									<div className="indiTitle">{eventData.title}</div>
									{eventData.event_type === "Virtual Event" ? (
										<div>{eventData.event_type}</div>
									) : (
										""
									)}
									{/* <br></br> */}
									{eventData.event_time.map((item, key) => {
										return (
											<div className="indiDate" key={key}>
												<span style={{ fontSize: "14px" }}>
													{moment(item.datetime_start).format("dddd")}
												</span>
												{", "}
												<b>{moment(item.datetime_start).format("MMMM Do")}</b>
											</div>
										);
									})}
								</Col>
								<Col
									xs={24}
									sm={24}
									md={12}
									lg={12}
									xl={12}
									xxl={12}
									className="buttonsSettingsIndividual"
								>
									<Button
										size="large"
										className="btn-login-outline btn-who-attend"
										onClick={() => scrollView()}
										style={{
											// width: "200px",
											// marginTop: "2px",
											// marginRight: 10,
											fontSize: 14,
										}}
										icon={<TeamOutlined />}
									>
										Who's Attending ?
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					<br />
					<div>
						<span className="indiTextShare">SHARE:</span>
						{/* {eventData.public_status === "Yes" ? (
							<> */}
						<FacebookShareButton
							url={
								process.env.REACT_APP_API_URL +
								"share-media-content/" +
								match.params.id
							}
							quote={eventData.title}
							// hashtag={"#event"}
							description={eventData.description}
							className="Demo__some-network__share-button"
						>
							<FacebookIcon
								size={25}
								style={{ marginBottom: "-5px", marginLeft: 5 }}
								round
							/>
						</FacebookShareButton>
						{/* </>
						) : (
							<>
								<FacebookIcon
									size={25}
									style={{ marginBottom: "-5px", marginLeft: 5, cursor: "not-allowed" }}
									round
								/>
							</>
						)} */}

						{/* {eventData.public_status === "Yes" ? (
							<> */}
						<TwitterShareButton
							url={
								process.env.REACT_APP_API_URL +
								"share-media-content/" +
								match.params.id
							}
							title={
								`Title ${eventData.title} \nDate: ${moment(
									eventData.date_from
								).format("YYYY/MM/DD")} - ${moment(eventData.date_to).format(
									"YYYY/MM/DD"
								)} \nDescription:${" " + eventData.description}\n`.substring(
									0,
									135
								) + "..."
							}
							className="Demo__some-network__share-button"
						>
							<TwitterIcon
								size={25}
								style={{ marginBottom: "-5px", marginLeft: 5 }}
								round
							/>
						</TwitterShareButton>
						{/* </>
						) : (
							<>
								<TwitterIcon
									size={25}
									style={{ marginBottom: "-5px", marginLeft: 5, cursor: "not-allowed" }}
									round
								/>
							</>
						)} */}

						{/* {eventData.public_status === "Yes" ? (
							<> */}
						<LinkedinShareButton
							url={
								process.env.REACT_APP_API_URL +
								"share-media-content/" +
								match.params.id
							}
							className="Demo__some-network__share-button"
						>
							<LinkedinIcon
								size={25}
								style={{ marginBottom: "-5px", marginLeft: 5 }}
								round
							/>
						</LinkedinShareButton>
						{/* </>
						) : (
							<>
								<LinkedinIcon
									size={25}
									style={{ marginBottom: "-5px", marginLeft: 5, cursor: "not-allowed" }}
									round
								/>
							</>
						)} */}

						{/* {eventData.public_status === "Yes" ? (
							<> */}
						<EmailShareButton
							url={
								process.env.REACT_APP_API_URL +
								"share-media-content/" +
								match.params.id
							}
							subject={eventData.title}
							body={
								eventData.course_topic +
								"\n" +
								"When: " +
								moment(eventData.date_from).format("DD MMM, YYYY") +
								"-" +
								moment(eventData.date_to).format("DD MMM, YYYY") +
								"\n" +
								"Description: " +
								eventData.description +
								"\n"
							}
							className="Demo__some-network__share-button"
						>
							<EmailIcon
								size={25}
								style={{ marginBottom: "-5px", marginLeft: 5 }}
								round
							/>
						</EmailShareButton>
						{/* </>
						) : (
							<>
								<EmailIcon
									size={25}
									style={{ marginBottom: "-5px", marginLeft: 5, cursor: "not-allowed" }}
									round
								/>
							</>
						)} */}
					</div>
					<br></br>
					<Row>
						<Col md={24}>
							<div className="indiSubTitle">SUMMARY</div>
							<div>{eventData.summary}</div>
						</Col>
					</Row>{" "}
					<br></br>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
							<div>
								<div className="indiSubTitle">DATE & TIME</div>

								{eventData.event_time.map((item, key) => {
									return (
										<div key={key}>
											{moment(item.datetime_start).format("ll h:mm a")} -{" "}
											{moment(item.datetime_end).format("h:mm a")}
										</div>
									);
								})}
							</div>
							<br />
						</Col>

						<Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
							<div>
								<div className="indiSubTitle">
									{eventData.event_type == "Virtual Event"
										? "MEETING URL"
										: "LOCATION"}
								</div>
								<div>
									{eventData.event_type == "Virtual Event" ? (
										<div style={{ wordBreak: "break-all" }}>
											{eventData.meeting_url}
										</div>
									) : (
										<>
											{eventData.venue_name != "" ? (
												<>
													{eventData.company_of_venue && (
														<div> {eventData.company_of_venue}</div>
													)}

													<div>{eventData.venue_name}</div>
													<div>{eventData.address_1}</div>
													<div>
														{eventData.city}, {eventData.state}, {eventData.zip}
													</div>
													<div>{eventData.country}</div>
												</>
											) : (
												<>{eventData.city}</>
											)}
										</>
									)}
								</div>
								<div></div>
							</div>
							<br />
						</Col>

						<Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
							<div className="hostedBy">
								<div className="indiSubTitle">HOSTED BY</div>
								<div>{eventData.title}</div>
								<div>
									<a href={"tel:" + eventData.your_phone}>
										{eventData.your_phone}
									</a>
								</div>

								<a href={"mailto:" + eventData.your_email}>
									{eventData.your_email}
								</a>
								<div
									className="c-danger"
									style={{ cursor: "pointer" }}
									onClick={(e) =>
										handleMakeNewConvo(
											eventData && eventData.user_id,
											"Request to cancel my registration for " +
												eventData.title +
												" Event"
										)
									}
								>
									Contact us to cancel
								</div>
							</div>
							<br />
						</Col>

						<Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
							<div>
								<div className="indiSubTitle">SPEAKER</div>
								{/* <div>{eventData.speaker_name}</div>
                <div>{eventData.speaker_credentials}</div> */}
								{eventData.event_speaker.map((item, key) => {
									return (
										<div>
											{item.speaker_name}
											<br />
											<span
											// style={{
											//   fontSize: "14px",
											//   // position: "absolute",
											//   marginTop: "-8px",
											//   color: " #293a71",
											//   fontWeight: "600",
											// }}
											>
												{item.speaker_credentials}
											</span>
										</div>
									);
								})}
							</div>
							<br />
						</Col>
						<Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
							<div>
								<div className="indiSubTitle">CREDIT HOURS</div>
								<div>{eventData.ce_credit_hours} Hours</div>
							</div>
							<br />
						</Col>
					</Row>
					{eventData.event_type == "Virtual Event" && (
						<Row>
							<Col md={24}>
								<br></br>
								<div className="indiSubTitle">VIRTUAL MEETING DETAILS</div>
								<div>
									{eventData.meeting_details
										? eventData.meeting_details
										: "Details to Follow"}
								</div>
							</Col>
						</Row>
					)}
					{eventData.description && (
						<>
							<br></br>
							<Row>
								<Col md={24}>
									<div className="indiSubTitle">DESCRIPTION</div>
									{eventData.description}
								</Col>
							</Row>
						</>
					)}
					{eventData.restriction && (
						<>
							<br></br>
							<Row>
								<Col md={24}>
									<div className="indiSubTitle">RESTRICTIONS</div>
									<div> {eventData.restriction}</div>
								</Col>
							</Row>{" "}
						</>
					)}
					{eventData.what_to_bring && (
						<>
							<br></br>
							<Row>
								<Col md={24}>
									<div className="indiSubTitle">WHAT TO BRING</div>
									<div> {eventData.what_to_bring}</div>
								</Col>
							</Row>
						</>
					)}
					<br></br> <br></br>
					<Row>
						<Col md={24}>
							<div className="indiSubTitleOrange">All Attendees</div>
						</Col>
					</Row>
					<br></br>
					<Row gutter={24} className="search-table" align="bottom">
						<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
							<Pagination
								className="paginate"
								showSizeChanger
								onChange={onChange}
								current={currentPage}
								pageSize={currentSize}
								// total={dataGetEvent && dataGetEvent.data.total}
								size="small"
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
							<FloatInputSearch
								label="Search..."
								placeholder="Search..."
								dataTableInfo={dataTableInfo}
								setDataTableInfo={setDataTableInfo}
								value={searchText}
								onChange={setSearchText}
							/>
						</Col>
					</Row>
					<br></br>
					<Table
						className="table-responsive table-thead-login"
						ref={tableAttending}
						size="small"
						rowKey={(record) => record.id}
						loading={isLoadingGetEvent}
						dataSource={dataGetEvent ? dataGetEvent.data.data : []}
						pagination={false}
					>
						<Table.Column
							key="first_name"
							title="First Name"
							dataIndex="first_name"
							sorter={(a, b) => a.first_name.localeCompare(b.first_name)}
							render={(text, record) => {
								if (record.share_info != 0) {
									return record.first_name;
								} else {
									return (
										<a
											href="#"
											onClick={(e) => {
												e.preventDefault();
												showModal(record);
											}}
										>
											{" "}
											{record.first_name}{" "}
										</a>
									);
								}
							}}
						/>
						<Table.Column
							key="last_name"
							title="Last Name"
							dataIndex="last_name"
							sorter={(a, b) => a.last_name.localeCompare(b.last_name)}
							render={(text, record) => {
								if (record.share_info != 0) {
									return record.last_name;
								} else {
									return (
										<a
											href="#"
											onClick={(e) => {
												e.preventDefault();
												showModal(record);
											}}
										>
											{" "}
											{record.last_name}{" "}
										</a>
									);
								}
							}}
						/>

						<Table.Column
							key="phone"
							title="Phone"
							dataIndex="phone"
							sorter={(a, b) => a.phone.localeCompare(b.phone)}
							render={(text, record) => {
								console.log("atay", record);
								return (
									<>
										{record.share_info == 0 ? (
											record.phone
										) : (
											<div className="c-secondary ">Private</div>
										)}
									</>
								);
							}}
						/>

						<Table.Column
							key="categories"
							title="Categories"
							dataIndex="categories"
							render={(text, record) => {
								if (record.user.business_categories) {
									var arr = JSON.parse(record.user.business_categories);
									if (arr) {
										if (record.share_info == 0) {
											return (
												<div
													dangerouslySetInnerHTML={{
														__html: arr.join("<br />"),
													}}
												></div>
											);
										} else {
											return <div className="c-secondary ">Private</div>;
										}
									}
								}
							}}
							sorter={(a, b) =>
								a.user.business_categories.localeCompare(
									b.user.business_categories
								)
							}
						/>
						<Table.Column
							key="email"
							title="Email"
							dataIndex="email"
							render={(text, record) => {
								return record.share_info == 0 ? (
									<a href={"mailto:" + text}>
										<Tooltip placement="top" title={text}>
											<MailOutlined />
										</Tooltip>
									</a>
								) : (
									<div className="c-secondary ">Private</div>
								);
							}}
						/>
						<Table.Column
							key="id"
							title="Message"
							render={(test, record) => {
								if (record.user.id != userdata.id) {
									return (
										<Button onClick={(e) => handleMakeNewConvo(record.user_id)}>
											<MessageOutlined />
										</Button>
									);
								}
							}}
						/>
					</Table>
					<ModalPreviewProfile
						previewShow={previewShow}
						setPreviewShow={setPreviewShow}
						previewShowData={previewShowData}
					/>
				</Layout.Content>
			</Layout>
		)
	);
}
