import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Layout, Card, Typography } from "antd";
import { BookOutlined } from "@ant-design/icons";
import ComponentHeader from "../Member/Components/ComponentHeader";
import { Link } from "react-router-dom";
import { GiThorHammer } from "react-icons/gi";

const ComponentCookies = ({ permission, icon }) => {
	const sub_title = "Cookie";
	useEffect(() => {
		scrollView();
	}, []);

	const divScroll = useRef(null);

	const scrollView = () => {
		divScroll.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	return (
		<Layout
			className="site-layout-background ant-page-cookies"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			ref={divScroll}
			id="ComponentCookies"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<GiThorHammer />}
			/>

			<Card bordered={false}>
				<br></br>
				<Row gutter={24}>
					<Col md={24}>
						<Typography.Title
							level={4}
							style={{ lineHeight: 0, color: "#293a56" }}
							className=" font-family-roboto tx-normal TypoClassLine"
						>
							Cookie Policy
						</Typography.Title>
					</Col>
				</Row>
				<br></br>
				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							ce.LIYA, LLC (“we” or “us” or “our”) may use cookies, web beacons,
							tracking pixels, and other tracking technologies when you visit our
							website https://www.dgrcommunications.com, including any other media
							form, media channel, mobile website, or mobile application related or
							connected thereto (collectively, the “Site”) to help customize the Site
							and improve your experience. We reserve the right to make changes to this
							Cookie Policy at any time and for any reason. We will alert you about any
							changes by updating the “Last Updated” date of this Cookie Policy. Any
							changes or modifications will be effective immediately upon posting the
							updated Cookie Policy on the Site, and you waive the right to receive
							specific notice of each such change or modification. You are encouraged
							to periodically review this Cookie Policy to stay informed of updates.
							You will be deemed to have been made aware of, will be subject to, and
							will be deemed to have accepted the changes in any revised Cookie Policy
							by your continued use of the Site after the date such revised Cookie
							Policy is posted.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Use Of Cookies.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							A “cookie” is a string of information which assigns you a unique
							identifier that we store on your computer. Your browser then provides
							that unique identifier to use each time you submit a query to the Site.
							We use cookies on the Site to, among other things, keep track of services
							you have used, record registration information, record your user
							preferences, keep you logged into the Site, facilitate purchase
							procedures, and track the pages you visit. Cookies help us understand how
							the Site is being used and improve your user experience.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							TYPES OF COOKIES
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							The following types of cookies may be used when you visit the Site:
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Advertising Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Advertising cookies are placed on your computer by advertisers and ad
							servers in order to display advertisements that are most likely to be of
							interest to you. These cookies allow advertisers and ad servers to gather
							information about your visits to the Site and other websites, alternate
							the ads sent to a specific computer, and track how often an ad has been
							viewed and by whom. These cookies are linked to a computer and do not
							gather any personal information about you.
						</p>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Analytics Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Analytics cookies monitor how users reached the Site, and how they
							interact with and move around once on the Site. These cookies let us know
							what features on the Site are working the best and what features on the
							Site can be improved.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Our Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Our cookies are “first-party cookies”, and can be either permanent or
							temporary. These are necessary cookies, without which the Site won’t work
							properly or be able to provide certain features and functionalities. Some
							of these may be manually disabled in your browser, but may affect the
							functionality of the Site.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Personalization Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Personalization cookies are used to recognize repeat visitors to the
							Site. We use these cookies to record your browsing history, the pages you
							have visited, and your settings and preferences each time you visit the
							Site.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Security Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Security cookies help identify and prevent security risks. We use these
							cookies to authenticate users and protect user data from unauthorized
							parties.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Site Management Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Site management cookies are used to maintain your identity or session on
							the Site so that you are not logged off unexpectedly, and any information
							you enter is retained from page to page. These cookies cannot be turned
							off individually, but you can disable all cookies in your browser.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Third-Party Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Third-party cookies may be place on your computer when you visit the Site
							by companies that run certain services we offer. These cookies allow the
							third parties to gather and track certain information about you. These
							cookies can be manually disabled in your browser.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Control of Cookies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							Most browsers are set to accept cookies by default. However, you can
							remove or reject cookies in your browser’s settings. Please be aware that
							such action could affect the availability and functionality of the Site.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							For more information on how to control cookies, check your browser or
							device’s settings for how you can control or reject cookies, or visit the
							following links:
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://support.apple.com/kb/ph19214?locale=en_US"
								target="_blank"
							>
								Apple Safari
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
								target="_blank"
							>
								Google Chrome
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
								target="_blank"
							>
								Microsoft Edge
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
								target="_blank"
							>
								Microsoft Internet Explorer
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
								target="_blank"
							>
								Mozilla Firefox
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="http://www.opera.com/help/tutorials/security/cookies/"
								target="_blank"
							>
								Opera
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
								target="_blank"
							>
								Android (Chrome)
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html"
								target="_blank"
							>
								Blackberry
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1"
								target="_blank"
							>
								Iphone or Ipad (Chrome)
							</a>
						</p>

						<p className="lead lead-font-size-new lead-font-size-new-bold">
							<a
								href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
								target="_blank"
							>
								Iphone or Ipad (Safari)
							</a>
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							In addition, you may opt-out of some third-party cookies through the{" "}
							<a href="http://optout.networkadvertising.org/#!/">
								Network Advertising Initiative’s Opt-Out Tool
							</a>
							.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Other Tracking Technologies
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							In addition to cookies, we may use web beacons, pixel tags, and other
							tracking technologies on the Site to help customize the Site and improve
							your experience. A “web beacon” or “pixel tag” is tiny object or image
							embedded in a web page or email. They are used to track the number of
							users who have visited particular pages and viewed emails, and acquire
							other statistical data. They collect only a limited set of data, such as
							a cookie number, time and date of page or email view, and a description
							of the page or email on which they reside. Web beacons and pixel tags
							cannot be declined. However, you can limit their use by controlling the
							cookies that interact with them.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Privacy Policy
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							For more information about how we use information collected by cookies
							and other tracking technologies, please refer to our Privacy Policy
							posted on the Site. This Cookie Policy is part of and is incorporated
							into our Privacy Policy. By using the Site, you agree to be bound by this
							Cookie Policy and our <Link to="/privacy">Privacy Policy</Link>.
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new lead-font-size-new-bold">
							Contact Us
						</p>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							If you have questions or comments about this Cookie Policy, please
							contact us at:
						</p>
					</Col>
					<Col md={24}>
						<p className="lead lead-font-size-new">
							ce.LIYA, LLC <br />
							8160 Butherus Dr #8, Scottsdale, AZ 85260 <br />
							Tel: 480-848-3197 <br />
							Email: info@celiya.com
							<br />
						</p>
					</Col>
				</Row>
			</Card>
		</Layout>
	);
};

export default ComponentCookies;
