import React, { useEffect, useState } from "react";
import {
	Card,
	Layout,
	Typography,
	Input,
	Row,
	Col,
	Divider,
	Button,
	Steps,
	Form,
	Checkbox,
	Collapse,
	Alert,
	notification,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { TextLoop } from "react-text-loop-next";
import {
	CalendarOutlined,
	UserOutlined,
	DownOutlined,
	UpOutlined,
	CreditCardOutlined,
	IdcardOutlined,
	LockOutlined,
	MinusOutlined,
} from "@ant-design/icons";

import image1 from "../../../../assets/img/image1.jpg";
import image2 from "../../../../assets/img/image2.jpg";
import image3 from "../../../../assets/img/image3.jpg";

import { Pie } from "@ant-design/charts";

import getUserData from "../../../../providers/getUserData";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputCard from "../../../../providers/FloatInputCard";
import FloatInputMM from "../../../../providers/FloatInputMM";
import FloatInputCVC from "../../../../providers/FloatInputCVC";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import companyInfo from "../../../../providers/companyInfo";
import { useHistory } from "react-router-dom";
import moment from "moment";
import states from "states-us";
import countryList from "react-select-country-list";
import termsAndCondition from "../../../../providers/termsAndCondition";
import FloatInputMask from "../../../../providers/FloatInputMask";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";

export default function PageBookEvent({ match, permission }) {
	const { Panel } = Collapse;
	const terms = termsAndCondition();
	const { Step } = Steps;
	const userdata = getUserData();
	let country_list = countryList().getData();
	const sub_title = "Book Upcoming";
	const { Search } = Input;
	const { Meta } = Card;
	const history = useHistory();
	const [form] = Form.useForm();
	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		require_false: {
			required: false,
			message: "Required",
		},
		email: {
			type: "email",
			message: "please enter a valid email",
		},
	};
	const [expError, setExpError] = useState(false);
	const [cardError, setCardError] = useState(false);
	const [cvvError, setCvvError] = useState(false);
	const [eventData, setEventData] = useState([]);
	const [state, setState] = useState([]);

	const [current, setCurrent] = useState(0);
	const [formData, setFormData] = useState();

	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const [policy, setPolicy] = useState("");

	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		form.resetFields(["billing_state", "billing_zip"]);
	};

	const { data: dataAccountType, isLoading: isLoadingAccountType } = GET(
		"api/v1/acc_type_per_user",
		"acc_type_per_user",
		(res) => {
			if (res.success) {
				console.log("acc_type", res);

				setPolicy(res.data[0].privacy.member_purchase_policy);
			}
		}
	);

	const { data: datGetById, isLoading: isLoadingGetById } = GET(
		`api/v1/event/${match.params.id}`,
		"event",
		(res) => {
			if (res.success) {
				console.log("tae", res);
				setEventData({
					event_type: res.data[0].event_type,
					event_provider_id: res.data[0].user_id,
					cost: res.data[0].cost,
					event_time: res.data[0].event_schedules,
					rscp_datetime: res.data[0].rscp_datetime,
					time_zone: res.data[0].time_zone,
					venue_name: res.data[0].event_venue.venue_name,
					country: res.data[0].event_venue.country,
					address_1: res.data[0].event_venue.address_1,
					address_2: res.data[0].event_venue.address_2,
					city: res.data[0].event_venue.city,
					state: res.data[0].event_venue.state,
					zip: res.data[0].event_venue.zip,
					map_link: res.data[0].event_venue.map_link,
					direction_details: res.data[0].event_venue.direction_details,
					company_of_venue: res.data[0].event_venue.venue
						? res.data[0].event_venue.venue.member_company.business_name
						: "",
					meeting_url: res.data[0].event_venue.meeting_url,
					title: res.data[0].event_details.title,
					sub_title: res.data[0].event_details.sub_title,
					director: res.data[0].event_details.director,
					facilitator: res.data[0].event_details.facilitator,
					summary: res.data[0].event_details.summary,
					description: res.data[0].event_details.descrition,
					restriction: res.data[0].event_details.restriction,
					what_to_bring: res.data[0].event_details.what_to_bring,
					ce_credit_hours: res.data[0].event_details.ce_credit_hours,
					seat_available: res.data[0].event_details.seat_available,
					completion_code: res.data[0].event_details.completion_code,
					subject_code: res.data[0].event_details.subject_code,
					course_topic: res.data[0].event_details.course_topic,
					attending_participants: JSON.parse(
						res.data[0].event_details.attending_participants
					),
					your_phone: res.data[0].event_details.your_phone,
					your_email: res.data[0].event_details.your_email,
					verification: JSON.parse(res.data[0].event_details.verification),
					published_status: res.data[0].event_save_status,

					speaker_name: res.data[0].event_speaker.speaker_name,
					speaker_credentials: res.data[0].event_speaker.speaker_credentials,
					event_img:
						res.data[0].event_details &&
						companyInfo().apiUrl +
							"storage/" +
							res.data[0].event_details.upload,
				});

				form.setFieldsValue({
					last_name: userdata.last_name,
					first_name: userdata.first_name,
					billing_last_name: userdata.last_name,
					billing_first_name: userdata.first_name,
					email: userdata.email,
					phone_number: userdata.cell_number,
				});

				let arr = [];
				states.map((row, key) => {
					if (
						row.name != "Federated States Of Micronesia" &&
						row.name != "Marshall Islands" &&
						row.name != "Palau" &&
						row.name != "Northern Mariana Islands"
					) {
						arr.push({
							value: row.name,
							label: row.name,
						});
					}
				});
				setState(arr);
			}
		}
	);

	const [checkBoxShared, setCheckShared] = useState(0);

	const handleChangeCheck = (e) => {
		setCheckShared(e.target.checked == true ? 1 : 0);
	};

	const [btnDisabled, setBtnDisabled] = useState(true);
	const [scroll, setScroll] = useState(false);
	const handleScroll = (e) => {
		// console.log("values");
		let element = e.target;
		if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
			setScroll(true);
		} else {
			setScroll(false);
		}
	};

	useEffect(() => {
		// if (scroll === true && accept === true) {
		if (scroll === true) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [scroll]);

	const steps = [
		{
			title: "1. REGISTRATION",
			icon: <UserOutlined />,
			content: (
				<>
					{" "}
					<Row gutter={24}>
						<Col xs={24} md={12}>
							<Form.Item
								name="first_name"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatInput label="First Name" placeholder="First Name" />
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<Form.Item
								name="last_name"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatInput label="Last Name" placeholder="Last Name" />
							</Form.Item>
						</Col>

						<Col xs={24} md={12}>
							<Form.Item name="email" hasFeedback>
								<FloatInput label="Email" placeholder="Email" disabled={true} />
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<Form.Item
								name="phone_number"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatInputMask
									label="Phone Number"
									placeholder="Phone Number"
									maskLabel="phone_number"
									maskType="999-999-9999"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={24}>
							<Form.Item name="share_info">
								<Checkbox.Group>
									{" "}
									<Checkbox style={{ lineHeight: "32px" }} value="1">
										Attendees information, to include, name, categories, phone,
										and email will be available to all event attendees. If you
										don't wish your contact information to be made available,
										please select here.
									</Checkbox>
								</Checkbox.Group>
							</Form.Item>
						</Col>
					</Row>
				</>
			),
		},
		{
			title: "2. PAYMENT",
			icon: <CreditCardOutlined />,
			content: (
				<>
					{/* <div style={{ fontSize: 12, marginBottom: 12 }}>
            <Alert
              banner
              message={
                <TextLoop mask>
                  <div>
                    A credit card is required to authenticate you are a real
                    person.
                  </div>
                  <div>
                    {" "}
                    If using a coupon and if there is no payment for your
                    account type, nothing will be charged.
                  </div>
                </TextLoop>
              }
            />
          </div> */}
					<div style={{ fontSize: 15, marginBottom: 10 }}>
						Credit Card Information
					</div>
					<Row gutter={24}>
						<Col xs={24} md={24}>
							<Form.Item
								name="card_name"
								rules={[
									{
										required: true,
										message: "This field field is required.",
									},
								]}
								hasFeedback
							>
								{/* <Input size="large" placeholder="Name on Card"/> */}
								<FloatInput label="Name on Card" placeholder="Name on Card" />
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							{" "}
							<Form.Item
								name="card_number"
								hasFeedback
								validateStatus={cardError ? "error" : "success"}
								help={cardError ? "Your card number is incorrect" : ""}
							>
								{/* <Input placeholder="Card Number" size="large" /> */}
								<FloatInputMask
									label="Card Number"
									placeholder="Card Number"
									maskLabel="card_number"
									maskType="9999-9999-9999-9999"
									validateStatus={cardError}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={6}>
							<Form.Item
								name="card_expiry_date"
								hasFeedback
								validateStatus={expError ? "error" : "success"}
								help={expError ? "Your card's expiration year is invalid." : ""}
							>
								<FloatInputMask
									label="Exp."
									placeholder="Exp."
									maskLabel="expiration"
									maskType="99/99"
									validateStatus={expError}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={6}>
							<Form.Item
								name="cvc"
								rules={[
									{
										required: true,
										message: "Required.",
									},
								]}
								hasFeedback
							>
								{/* <DatePicker picker="year" size="large" style={{ width: "100%" }} /> */}
								<FloatInputMask
									label="CVV"
									placeholder="CVV"
									maskLabel="card_security_code"
									maskType="999"
								/>
							</Form.Item>
						</Col>
					</Row>{" "}
					<div style={{ fontSize: 15, marginBottom: 10 }}>Billing Address</div>
					<Row gutter={24}>
						<Col xs={24} md={12}>
							<Form.Item
								name="billing_first_name"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatInput label="First Name" placeholder="First Name" />
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<Form.Item
								name="billing_last_name"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatInput label="Last Name" placeholder="Last Name" />
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<Form.Item
								name="billing_address"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatInput label="Address" placeholder="Address" />
							</Form.Item>
						</Col>

						<Col xs={24} md={12}>
							<Form.Item
								name="billing_city"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatInput label="City" placeholder="City" />
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								className="form-select-error"
								name="billingc_country"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatSelect
									label="Country"
									placeholder="Country"
									options={country}
									onChange={handleCountry}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								className="form-select-error"
								name="billing_state"
								rules={[validator.require]}
								hasFeedback
							>
								<FloatSelect
									label={stateLabel}
									placeholder={stateLabel}
									options={optionState}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								name="billing_zip"
								rules={[
									{
										required: true,
										message: "Required.",
									},
									{
										pattern: optionZip,
										message: "Invalid " + zipLabel,
									},
								]}
								hasFeedback
							>
								<FloatInput label={zipLabel} placeholder={zipLabel} />
							</Form.Item>
						</Col>
						<Col xs={24} md={24}>
							<div>
								<div style={{ fontSize: 15, marginBottom: 10 }}>
									Privacy Policy and Terms & Conditions
								</div>

								<div style={{ marginTop: 10 }} className="c-danger">
									<b>Please read / scroll to the end to continue.</b>
								</div>
								<div
									onScroll={handleScroll}
									className="policyDiv"
									style={{
										marginBottom: 10,
										marginTop: 10,
										height: 170,
										resize: "vertical",
										overflow: "auto",
										border: "1px solid #d9d9d9",
									}}
									dangerouslySetInnerHTML={{ __html: policy }}
								></div>
							</div>
						</Col>
					</Row>
				</>
			),
		},
		{
			title: "3. CONFIRMATION",
			icon: <LockOutlined />,
			content: (
				<>
					<div>
						<div>
							Please review the information. Click the{" "}
							<span className="c-secondary" style={{ fontWeight: "bold" }}>
								Go Back{" "}
							</span>{" "}
							button below if you need to make changes.
						</div>
						<div>
							Otherwise, click the{" "}
							<span className="c-darkgray" style={{ fontWeight: "bold" }}>
								Submit{" "}
							</span>
							button to complete your registration
						</div>
					</div>
					<br></br>
					<Collapse
						expandIcon={({ isActive }) =>
							isActive ? (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(270deg)" }}
								></span>
							) : (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(90deg)" }}
								></span>
							)
						}
						expandIconPosition="right"
						defaultActiveKey={["1"]}
					>
						<Panel
							header="Event Information"
							key="1"
							className="accordion bg-darkgray-form"
						>
							<div>
								<b className="c-lightorange ">{eventData.title}</b>
							</div>
							<div>
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									When:{" "}
								</span>
								{eventData.event_time && eventData.event_time.length && (
									<>
										{" "}
										<span>
											{eventData.event_time.length == 0 && (
												<>
													{" "}
													{moment(
														eventData.event_time[0].datetime_start
													).format("ll h:mma")}{" "}
													-{" "}
													{moment(eventData.event_time[0].datetime_end).format(
														"ll h:mma"
													)}{" "}
												</>
											)}
										</span>
										<span>
											{eventData.event_time.length != 0 && (
												<>
													{" "}
													{moment(
														eventData.event_time[0].datetime_start
													).format("ll h:mma")}{" "}
													-{" "}
													{moment(
														eventData.event_time[
															eventData.event_time.length - 1
														].datetime_end
													).format("ll h:mma")}{" "}
												</>
											)}
										</span>
									</>
								)}
							</div>
							<div style={{ display: "flex" }}>
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									{eventData.event_type == "Virtual Event"
										? "Meeting Url:"
										: "Where:"}{" "}
								</span>{" "}
								<span style={{ marginLeft: "3px" }}>
									<div>
										{eventData.event_type == "Virtual Event" ? (
											<div style={{ wordBreak: "break-all" }}>
												{eventData.meeting_url}
											</div>
										) : (
											<>
												{eventData.venue_name != "" ? (
													<>
														{eventData.company_of_venue && (
															<div> {eventData.company_of_venue}</div>
														)}

														<div>{eventData.venue_name}</div>
														<div>{eventData.address_1}</div>
														<div>
															{eventData.city}, {eventData.state},{" "}
															{eventData.zip}
														</div>
														<div>{eventData.country}</div>
													</>
												) : (
													<>{eventData.city}</>
												)}
											</>
										)}
									</div>
								</span>
							</div>
							<div>
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									Cost:{" "}
								</span>{" "}
								{eventData.cost != 0 ? "$" + eventData.cost : "FREE"}
							</div>
						</Panel>
					</Collapse>
					<br></br>
					<Collapse
						expandIcon={({ isActive }) =>
							isActive ? (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(270deg)" }}
								></span>
							) : (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(90deg)" }}
								></span>
							)
						}
						expandIconPosition="right"
						defaultActiveKey={["1"]}
					>
						<Panel
							header="Participants Information"
							key="1"
							className="accordion bg-darkgray-form"
						>
							<div>
								{" "}
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									First Name:
								</span>{" "}
								{formData && formData.first_name}{" "}
							</div>
							<div>
								{" "}
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									Last Name:
								</span>{" "}
								{formData && formData.last_name}
							</div>
							<div>
								{" "}
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									{" "}
									Email:
								</span>{" "}
								{formData && formData.email}
							</div>
							<div>
								{" "}
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									Phone:{" "}
								</span>{" "}
								{formData && formData.phone_number}
							</div>
						</Panel>
					</Collapse>
					<br></br>
					<Collapse
						expandIcon={({ isActive }) =>
							isActive ? (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(270deg)" }}
								></span>
							) : (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(90deg)" }}
								></span>
							)
						}
						expandIconPosition="right"
						defaultActiveKey={["1"]}
					>
						<Panel
							header="Billing Information"
							key="1"
							className="accordion bg-darkgray-form"
						>
							<div>
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									First Name:{" "}
								</span>
								{formData && formData.billing_first_name}{" "}
							</div>
							<div>
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									Last Name:{" "}
								</span>
								{formData && formData.billing_last_name}
							</div>
							<div>
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									Address:
								</span>{" "}
								{formData && formData.billing_address}
							</div>
							<div>
								<span className="c-darkgray" style={{ fontWeight: "bold" }}>
									City, State, Zip
								</span>
								: {formData && formData.billing_city},{" "}
								{formData && formData.billing_state},{" "}
								{formData && formData.billing_zip}
							</div>
						</Panel>
					</Collapse>
				</>
			),
		},
		{
			title: "4. DETAILS",
			icon: <IdcardOutlined />,
			content: (
				<>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={15} lg={15}>
							<div>
								<Typography.Title level={4} style={{ color: "#293a56" }}>
									{eventData.title}
								</Typography.Title>
							</div>
							{eventData.description && (
								<>
									<div>{eventData.description}</div>
									<br />
								</>
							)}
							{eventData.restriction && (
								<>
									<div style={{ textTransform: "uppercase", color: "#293a56" }}>
										<b>restriction</b>
									</div>
									<div> {eventData.restriction}</div>
									<br />
								</>
							)}
							{eventData.what_to_bring && (
								<>
									<div style={{ textTransform: "uppercase", color: "#293a56" }}>
										<b>what to bring</b>
									</div>
									<div>{eventData.what_to_bring}</div>
								</>
							)}
						</Col>
						<Col xs={24} sm={24} md={9} lg={9}>
							<div className="modalPreviewDescriptionRight">
								{" "}
								<div style={{ textTransform: "uppercase", color: "#293a56" }}>
									<b>DATE & TIME</b>
								</div>
								{eventData.length != 0 &&
									eventData.event_time.map((item, key) => {
										return (
											<div key={key}>
												{moment(item.datetime_start).format("MMMM Do h:mma")} -{" "}
												{moment(item.datetime_end).format("h:mma")}
											</div>
										);
									})}
								<br></br>
								<>
									<div style={{ textTransform: "uppercase", color: "#293a56" }}>
										<b>
											{eventData.event_type == "Virtual Event"
												? "MEETING URL"
												: "LOCATION"}
										</b>
									</div>
									<div>
										{eventData.event_type == "Virtual Event" ? (
											<div style={{ wordBreak: "break-all" }}>
												{eventData.meeting_url}
											</div>
										) : (
											<>
												{eventData.venue_name != "" ? (
													<>
														{eventData.company_of_venue && (
															<div> {eventData.company_of_venue}</div>
														)}

														<div>{eventData.venue_name}</div>
														<div>{eventData.address_1}</div>
														<div>
															{eventData.city}, {eventData.state},{" "}
															{eventData.zip}
														</div>
														<div>{eventData.country}</div>
													</>
												) : (
													<>{eventData.city}</>
												)}
											</>
										)}
									</div>
									<br></br>
								</>
								<div style={{ textTransform: "uppercase", color: "#293a56" }}>
									<b>CONTACT INFO</b>
								</div>
								<div>{eventData.your_phone}</div>
								<div>{eventData.your_email}</div>
								<br></br>
								<div style={{ textTransform: "uppercase", color: "#293a56" }}>
									<b>SPEAKER</b>
								</div>
								{datGetById &&
									datGetById.data[0].event_speaker.map((row, key) => {
										return (
											<div key={key}>
												<div>
													{/* NAME:  */}
													{row.speaker_name}
												</div>
												<div>
													{/* CRED:  */}
													{row.speaker_credentials}
												</div>
											</div>
										);
									})}
								<div>{eventData.speaker_name}</div>
								<div>{eventData.speaker_credentials}</div>
								<br></br>
								<div style={{ textTransform: "uppercase", color: "#293a56" }}>
									<b>Credit Hours</b>
								</div>
								<div>{eventData.ce_credit_hours} hrs</div>
							</div>
						</Col>
					</Row>
				</>
			),
		},
	];

	const { mutate: mutateAddBooking, isLoading: isLoadingBooking } = POST(
		"api/v1/booking",
		"event_booking"
	);

	const prev = () => {
		setCurrent(current - 1);
	};

	const onFinishMyAccount = (val) => {
		if (current < 2) {
			setCurrent(current + 1);
			setFormData({
				...formData,
				...val,
			});
		}
		if (current === 2) {
			let _data = {
				...formData,
				...val,
				user_id: userdata.id,
				user_name: userdata.first_name + " " + userdata.last_name,
				event_id: match.params.id,
				cost: eventData.cost,
				event_provider_id: eventData.event_provider_id,
				event_title: eventData.title,
			};

			mutateAddBooking(_data, {
				onSuccess: (res) => {
					if (res.success) {
						console.log("handleFinish", res);
						notification.success({
							message: "Success",
							description: "Booking  Successfully Submitted",
						});
						setCurrent(current + 1);
						// history.push("/member/ce-calendar/my-book-events");
						// form.resetFields();
					} else {
						notification.error({
							message: "Error",
							description: res.data,
						});
					}
				},
				onError: (err) => {
					console.log(err.response.data);
					if (
						err.response.data.message ==
						"Your card's expiration year is invalid."
					) {
						setExpError(true);
						setCardError(false);
						setCurrent(1);
					}
					if (
						err.response.data.message == "Your card number is incorrect." ||
						err.response.data.message ==
							"The card number is not a valid credit card number."
					) {
						setCardError(true);
						setExpError(false);
						setCurrent(1);
					}

					notification.error({
						message: "Error",
						description: err.response.data.message,
					});
				},
			});
		}
	};

	useEffect(() => {
		console.log(formData);
	}, [formData]);

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageBookEvent"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CalendarOutlined />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinishMyAccount}
				>
					<Card className="ant-card-bordered-blue no-side-border">
						<Row>
							<Col xs={24} sm={24} md={24} lg={24} xl={2} xxl={2}></Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
								<Steps current={current} className="bookSteps">
									{steps.map((item) => (
										<Step key={item.title} title={item.title} />
									))}
								</Steps>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={2} xxl={2}></Col>
						</Row>
						<br></br>
						{current != 3 ? (
							<Card>
								<Row>
									<Col md={24}>
										<div style={{ textAlign: "center", fontSize: 20 }}>
											<div>{eventData.title}</div>

											{eventData.event_time && eventData.event_time.length && (
												<>
													{" "}
													<div>
														{eventData.event_time.length == 0 && (
															<>
																{" "}
																{moment(
																	eventData.event_time[0].datetime_start
																).format("ll h:mma")}{" "}
																-{" "}
																{moment(
																	eventData.event_time[0].datetime_end
																).format("ll h:mma")}{" "}
															</>
														)}
													</div>
													<div>
														{eventData.event_time.length != 0 && (
															<>
																{" "}
																{moment(
																	eventData.event_time[0].datetime_start
																).format("ll h:mma")}{" "}
																-{" "}
																{moment(
																	eventData.event_time[
																		eventData.event_time.length - 1
																	].datetime_end
																).format("ll h:mma")}{" "}
															</>
														)}
													</div>
												</>
											)}
											<div>{eventData.time_zone}</div>
											<div>
												{" "}
												<span>
													{eventData.cost != 0 ? "$" + eventData.cost : "FREE"}
												</span>{" "}
												<span>|</span>
												<span> {eventData.ce_credit_hours} Credit Hours</span>
											</div>
										</div>
									</Col>
								</Row>
							</Card>
						) : (
							<div style={{ textAlign: "center" }}>
								<Typography.Title level={5} style={{ color: "#293a56" }}>
									Thank you for registering through CE.LIYA. The details below
									will be emailed to you and saved on your upcoming events page
								</Typography.Title>
							</div>
						)}

						<br></br>
						<div className="steps-content">{steps[current].content}</div>
						<br></br>
						<br></br>
						<div className="steps-action" style={{ textAlign: "right" }}>
							{current > 0 && current <= 2 && (
								<Button
									style={{ margin: "0 8px" }}
									onClick={() => prev()}
									className="btn-secondary-outline"
									size="large"
								>
									Back
								</Button>
							)}
							{current === 0 && (
								<Button
									htmlType="submit"
									size="large"
									className="btn-login-outline"
								>
									Continue
								</Button>
							)}
							{current === 1 && (
								<Button
									htmlType="submit"
									size="large"
									className="btn-login-outline"
									disabled={btnDisabled}
								>
									Continue
								</Button>
							)}
							{current === 2 && (
								<Button
									htmlType="submit"
									size="large"
									className="btn-login-outline"
									loading={isLoadingBooking}
								>
									Submit
								</Button>
							)}
							{current === 3 && (
								<Row>
									<Col xs={24} sm={24} md={15} lg={15}></Col>
									<Col
										xs={24}
										sm={24}
										md={9}
										lg={9}
										style={{ textAlign: "center" }}
									>
										<Button
											size="large"
											className="btn-login "
											onClick={() => {
												history.push("/member/ce-calendar/all-events");
											}}
										>
											View All Events
										</Button>
									</Col>
								</Row>
							)}
						</div>
					</Card>
				</Form>
			</Layout.Content>
		</Layout>
	);
}
