import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Layout, Collapse } from "antd";
import { DownOutlined, RightOutlined, UpOutlined } from "@ant-design/icons";
import TextEditor from "./TextEditor";

const PageEditableTemplate = ({
  title,
  formRef,
  handleSubmitEditableTemplate,
  showSubject = true,
  cardHeaderColor = "#1F5B7D",
  defaultShow,
}) => {
  const [cardPasswordReset, setCardPasswordReset] = useState(false);
  const { Panel } = Collapse;
  return (
    <Collapse
      expandIcon={({ isActive }) =>
        isActive ? (
          <span
            className="ant-menu-submenu-arrow"
            style={{ color: "#FFF", transform: "rotate(270deg)" }}
          ></span>
        ) : (
          <span
            className="ant-menu-submenu-arrow"
            style={{ color: "#FFF", transform: "rotate(90deg)" }}
          ></span>
        )
      }
      expandIconPosition="right"
      defaultActiveKey={defaultShow ? ["1"] : [""]}
    >
      <Panel header={title} key="1" className="accordion bg-darkgray-form">
        <>
          <Form
            layout="vertical"
            form={formRef}
            onValuesChange={(changedValues, values) => {
              // console.log(values);
            }}
            onFinish={(values) => {
              handleSubmitEditableTemplate({
                ...values,
                title: title,
              });
            }}
          >
            {showSubject && (
              <Form.Item label="Subject" name="subject">
                <Input />
              </Form.Item>
            )}

            <Form.Item
              label={showSubject ? "Email Content" : ""}
              name="content"
            >
              <TextEditor />
            </Form.Item>
            <div>
              <Button className="btn-danger" onClick={(e) => formRef.submit()}>
                Save / Update
              </Button>
            </div>
          </Form>
        </>
      </Panel>
    </Collapse>
  );
};

export default PageEditableTemplate;
