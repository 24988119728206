import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Divider,
  notification,
  Table,
  Popconfirm,
  Space,
  DatePicker,
  Select,
} from "antd";

import moment from "moment";

import {
  DeleteFilled,
  EditFilled,
  PlusCircleOutlined,
  PrinterOutlined,
  UploadOutlined,
  SettingOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
const FloatDateTimePicker = (props) => {
  const [focus, setFocus] = useState(false);

  let {
    label,
    value,
    placeholder,
    type,
    required,
    picker,
    format,
    disabledDate,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker
        disabledDate={(current) => {
          //   console.log(disabledDate);
          if (disabledDate) {
            return current && current > disabledDate;
          }
        }}
        onChange={props.onChange}
        defaultValue={value ? moment(value) : ""}
        value={value ? moment(value) : ""}
        size="large"
        showTime
        placeholder={[""]}
        style={{ width: "100%" }}
        className="input-date-picker"
        // picker={picker ? picker : null}
        format={format ? format : ""}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatDateTimePicker;
