import React, { useEffect } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
const Error404 = () => {
	let history = useHistory();
	if (!localStorage.userdata || !localStorage.token) {
		localStorage.removeItem("userdata");
		localStorage.removeItem("token");
		localStorage.removeItem("viewas");
		localStorage.removeItem("userdata_admin");
		window.location.replace("/");
	} else {
		return (
			<Result
				className="resultErrorPage"
				status="404"
				title="404"
				subTitle="Sorry, something went wrong."
				extra={
					<Button
						type="primary"
						onClick={(e) => history.push("/?redirect=" + window.location.href)}
						className="btn-primary-default invert"
					>
						Back Home
					</Button>
				}
			/>
		);
	}
};

export default Error404;
