import React, { useState, useEffect } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	message,
	Card,
	Form,
	Upload,
	notification,
	Typography,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
	ContainerOutlined,
	SearchOutlined,
	PlusCircleOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import FloatInput from "../../../../providers/FloatInput";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import FloatDateTimePicker from "../../../../providers/FloatDateTimePicker";
import getUserData from "../../../../providers/getUserData";
import { Preview, print } from "react-html2pdf";
import SendCertificate from "./Components/SendCertificate";
import moment from "moment";
import { Link } from "react-router-dom";
import companyInfo from "../../../../providers/companyInfo";
import { jsPDF } from "jspdf";
export default function PageCeHoursByYears({ props, permission }) {
	const sub_title = "View All";
	const userdata = getUserData();
	const [currentPage, setCurrentPage] = useState(1);
	const [hours, setHours] = useState(0);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
		user_id: userdata.id,
	});

	const handleTableChange = (pagination, filters, sorter) => {
		console.log("sorter", sorter);
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	const {
		data: dataGetEvent,
		isLoading: isLoadingGetEvent,
		refetch: refetchGetEvent,
		isFetching: isFetchingGetEvent,
	} = GET(
		`api/v1/event_certificate?${$.param(dataTableInfo)}`,
		"event_certificate",
		(res) => {
			if (res.success) {
				console.log(res);
				var hours = 0;
				res.data.data.map((item, key) => {
					hours = hours + parseInt(item["ce_hours"]);
				});
				setHours(hours);
			}
		}
	);

	useEffect(() => {
		refetchGetEvent();
		// console.log("dataTableInfo", dataTableInfo);
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		// console.log("current", current);
		// console.log("pageSize", pageSize);
	};

	const [selectedDownload, setSelectedDownload] = useState(null);
	const handleDownload = (record) => {
		console.log("record", record);

		setSelectedDownload(record);
	};
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (selectedDownload !== null) {
				let pdf = new jsPDF("portrait", "pt", "letter");
				var html_pdf = $(".preview-certificate").html();
				pdf.html(html_pdf).then(() => {
					pdf.save(selectedDownload.event.title + ".pdf");
				});
				setSelectedDownload(null);
			}
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [selectedDownload]);

	// useEffect(() => {
	//   const timeoutId = setTimeout(() => {
	//     if (selectedDownload !== null) {
	//       print(selectedDownload.event_title, "jsx-template");
	//     }
	//   }, 500);
	//   return () => {
	//     clearTimeout(timeoutId);
	//   };
	// }, [selectedDownload]);

	const onChangeDatesSearch = (val) => {
		if (val.date_from && val.date_to) {
			setDataTableInfo({
				...dataTableInfo,
				date_from: moment(val.date_from).format("YYYY-MM-DD"),
				date_to: moment(val.date_to).format("YYYY-MM-DD"),
			});
		}

		console.log(val);
	};

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();

	const [fileList, setFileList] = useState([]);
	const onSubmitNonCeliya = (val) => {
		// console.log(val);
		const data = new FormData();
		data.append("id", userdata.id);
		data.append("title", val.title);
		data.append("provider", val.provider);
		data.append("date_from", val.date_from);
		data.append("date_to", val.date_to);
		data.append(
			"event_schedule",
			JSON.stringify({
				datetime_start: val.date_from,
				datetime_end: val.date_to,
			})
		);
		data.append("ce_hours", val.ce_credit_hours);
		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append("upload", fileList[0].originFileObj, fileList[0].name);
			}
		}
		mutateAdd(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Successfully Submitted Event",
					});
					setFileList([]);
					form.resetFields();
				}
			},
		});
	};

	const { mutate: mutateAdd, isLoading: isLoading } = POST(
		"api/v1/event_certificate_add",
		"event_certificate"
	);

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageCeHoursByYears"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<ContainerOutlined />}
			/>
			<br />
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<Row gutter={24} className="search-table webMT" align="bottom">
					<Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
						<Pagination
							className="paginate paginateCEyears"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataGetEvent && dataGetEvent.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
						<Form
							onValuesChange={(changedFields, allFields) => {
								onChangeDatesSearch(allFields);
							}}
						>
							<Row gutter={12} style={{ marginBottom: -28 }}>
								<Col xs={24} sm={24} md={8}>
									{" "}
									<Form.Item name="date_from" hasFeedback>
										<FloatDatePickerForm
											label={"Date From"}
											// placeholder={"Date and Start Time"}
											mode
										/>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={8}>
									<Form.Item name="date_to" hasFeedback>
										<FloatDatePickerForm
											label={"Date To"}
											// placeholder={"Date and Start Time"}
											mode
										/>
									</Form.Item>
								</Col>

								<Col xs={24} sm={24} md={8} className="searchByYears">
									<FloatInputSearch
										label="Search..."
										placeholder="Search..."
										dataTableInfo={dataTableInfo}
										setDataTableInfo={setDataTableInfo}
										value={searchText}
										onChange={setSearchText}
									/>
								</Col>
							</Row>
						</Form>

						{/* <Form onFinish={onChangeDatesSearch}>
              <Form.Item name="year" hasFeedback>
                <FloatDateTimePicker
                  label={"Year"}
                  // placeholder={"Date and Start Time"}
                  mode
                  picker="year"
                />
              </Form.Item>
            </Form> */}
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						dataSource={dataGetEvent ? dataGetEvent.data.data : []}
						loading={isLoadingGetEvent}
						rowKey={(record) => record.id}
						className="table-responsive table-thead-login"
						pagination={false}
						size="small"
						onChange={handleTableChange}
					>
						<Table.Column
							key="date_time"
							title="Date"
							dataIndex="date_time"
							sorter
							width={200}
						/>
						<Table.Column
							key="type"
							title="CE. LIYA Event"
							dataIndex="type"
							sorter
							width={150}
						/>
						<Table.Column
							key="event_name"
							title="Name"
							dataIndex="event_name"
							sorter
						/>
						<Table.Column
							key="provider"
							title="Provider"
							dataIndex="provider"
							sorter
							width={200}
						/>
						<Table.Column
							key="ce_hours"
							title="Hours"
							dataIndex="ce_hours"
							sorter
							width={80}
						/>
						<Table.Column
							key="certification"
							title="Certification"
							dataIndex="certification"
							width={120}
							render={(text, record) => {
								if (record.type == "No") {
									return text === "Yes" ? (
										<a
											style={{ cursor: "pointer" }}
											href={companyInfo().apiUrl + record.upload}
											target="_blank"
											download
										>
											Download
										</a>
									) : (
										"No"
									);
								} else {
									return (
										<Link
											to="#"
											onClick={() => handleDownload(record)}
											style={{ cursor: "pointer" }}
										>
											Downloads
										</Link>
									);
								}
							}}
						/>
						{/* <Table.Column
              key="action"
              title="Action"
              dataIndex="action"
              render={(text, record) => {
                return (
                  <Space>
                    <Button
                      className="btn-warning-outline"
                      onClick={() => handleDownload(record)}
                    >
                      Dowload
                    </Button>
                  </Space>
                );
              }}
            /> */}
					</Table>
				</div>
			</Layout.Content>

			{selectedDownload !== null && (
				<div style={{ display: "none" }} className="preview-certificate">
					<SendCertificate dataCetificate={selectedDownload} />
				</div>
			)}
		</Layout>
	);
}
