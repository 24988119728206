import React, { useEffect, useState, useRef, memo } from "react";
import {
  Card,
  Layout,
  Typography,
  Input,
  DatePicker,
  Row,
  Col,
  Badge,
  Divider,
  Menu,
  Button,
  Alert,
  Space,
  Collapse,
  Tag,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
  HomeOutlined,
  CalendarOutlined,
  EyeOutlined,
  UpOutlined,
  DownOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import ModalContiueFill from "./Modals/ModalContiueFill";
import moment from "moment";

import image1 from "../../../../assets/img/image1.jpg";
import image2 from "../../../../assets/img/image2.jpg";
import image3 from "../../../../assets/img/image3.jpg";

import { Pie } from "@ant-design/plots";

import getUserData from "../../../../providers/getUserData";
import { GET } from "../../../../providers/useAxiosQuery";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import companyInfo from "../../../../providers/companyInfo";
import { useHistory } from "react-router-dom";

import ModalPreview from "../PageEvent/Modals/ModalPreview";
import { Link } from "react-router-dom";
import $ from "jquery";
import states from "states-us";
import FloatSelectDashboard from "../../../../providers/FloatSelectDashboard";
import FloatSelectDashboard1 from "../../../../providers/FloatSelectDashboard1";
import FloatInputDasboard2 from "../../../../providers/FloatInputDasboard2";
import FloatInputDasboard from "../../../../providers/FloatInputDasboard";
import FloatInputDasboard3 from "../../../../providers/FloatInputDasboard3";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdRefresh } from "react-icons/md";

const PageDashboardPieChart = () => {
  const userdata = getUserData();
  const sub_title = "All Features";
  const { Search } = Input;
  const { Meta } = Card;
  const history = useHistory();
  const { Panel } = Collapse;
  const getChartConfig = (type) => {
    return {
      height: 150,
      appendPadding: 0,
      angleField: "value",
      colorField: "type",
      // color: ['#293a56', '#d6ae71', '#000000'],
      color: ({ type }) => {
        let text = type;
        let result = text.includes("Available Seats");
        if (result) {
          return "#d6ae71";
        }
        return "#293a56";
      },
      label: {
        type: "inner",
        offset: "-50%",
        // content: "{value}",
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        autoRotate: false,
        position: "bottom",
        style: {
          textAlign: "center",
          fontSize: 10,
        },
      },
      // legend: {
      //   layout: "horizontal",
      //   position: "bottom",
      // },
      legend: false,
      interactions: [{ type: "element-selected" }, { type: "element-active" }],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          formatter: function formatter() {
            return type;
          },
        },
      },
    };
  };

  const [stateDataloadmore, setStateDataloadmore] = useState({
    user_id: userdata.id,
    page_size: 3,
    page_number: 1,
  });

  const [lastPage, setLastPage] = useState(false);

  const {
    data: dataEventloadmore,
    isLoading: isLoadingEventloadmore,
    refetch: refetchEvenloadmore,
  } = GET(
    `api/v1/memberAllEventDashboardCardloadmore?${$.param(stateDataloadmore)}`,
    "member_all_event_dashboard_card_loadmore",
    (res) => {
      console.log("loadmore", res);
      setLastPage(res.data.to == res.data.total ? true : false);
    }
  );

  const Loadmorehandle = () => {
    setStateDataloadmore({
      ...stateDataloadmore,
      page_size: stateDataloadmore.page_size + 3,
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetchEvenloadmore();
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [stateDataloadmore]);

  return (
    <Col xs={24} sm={24} md={9} lg={9} xl={7}>
      <Row className="dashRightRowMargin">
        {dataEventloadmore &&
          dataEventloadmore.data.data.map((item, key) => {
            // console.log("item", item);
            let count_attendee = 0;
            item.event_attendee.map((item, key) => {
              if (
                // item.status === "Registered/Not Attended" ||
                item.status === "Attended" ||
                item.status === "Registered"
              ) {
                count_attendee = count_attendee + 1;
              }
            });
            return (
              <Col xs={24} sm={24} md={24} lg={24} key={key}>
                <Collapse
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <span
                        className="ant-menu-submenu-arrow"
                        style={{
                          color: "#FFF",
                          transform: "rotate(270deg)",
                        }}
                      ></span>
                    ) : (
                      <span
                        className="ant-menu-submenu-arrow"
                        style={{
                          color: "#FFF",
                          transform: "rotate(90deg)",
                        }}
                      ></span>
                    )
                  }
                  style={{
                    boxShadow:
                      "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
                    marginTop: 10,
                  }}
                  expandIconPosition="right"
                  defaultActiveKey={["1"]}
                >
                  <Panel
                    header={item.event_details.title}
                    key="1"
                    className="accordion bg-darkgray-form"
                  >
                    <div style={{ textAlign: "right" }}>
                      <b style={{ color: "#253C56" }}>Date:</b>{" "}
                      {moment(item.date_from).format("MMM. DD YYYY")}
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <b style={{ color: "#253C56" }}>Total Seats:</b>{" "}
                      {item.event_details.seat_available}
                    </div>
                    <Pie
                      {...getChartConfig("", item)}
                      data={[
                        {
                          type:
                            "Confirmed RSVP's " + item.event_attendee.length,
                          value: item.event_attendee.length,
                        },
                        {
                          type:
                            "Available Seats " +
                            Math.abs(
                              parseInt(item.event_details.seat_available) -
                                item.event_attendee.length
                            ),
                          value: Math.abs(
                            parseInt(item.event_details.seat_available) -
                              item.event_attendee.length
                          ),
                        },
                      ]}
                    />
                    <br />
                    <Row gutter={4}>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                      <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                        <Space>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              background: "#293a56",
                            }}
                          ></div>
                          <div
                            style={{
                              lineHeight: "initial",
                              fontSize: "12px",
                            }}
                          >
                            Confirmed <br></br> RSVP's {count_attendee}
                          </div>
                        </Space>
                      </Col>
                      {/* <Col xs={24} sm={24} md={2} lg={2}></Col> */}
                      <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                        <Space>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              background: "#d6ae71",
                            }}
                          ></div>
                          <div
                            style={{
                              lineHeight: "initial",
                              fontSize: "12px",
                            }}
                          >
                            Available <br></br>Seats{" "}
                            {Math.abs(
                              parseInt(item.event_details.seat_available) -
                                item.event_attendee.length
                            )}
                          </div>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            );
          })}
        <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
          {!lastPage && (
            <Button
              className="btn-login-outline"
              icon={<EyeOutlined />}
              style={{
                marginTop: 15,
                display: dataEventloadmore
                  ? dataEventloadmore.data.data.length == 0
                    ? "none"
                    : "initial"
                  : "none",
              }}
              onClick={() => Loadmorehandle()}
              loading={isLoadingEventloadmore}
            >
              Load More
            </Button>
          )}
        </Col>
        {/* <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                style={{ textAlign: "center" }}
              >
                <Button
                  className="btn-login-outline"
                  style={{ marginTop: 15 }}
                  icon={<EyeOutlined />}
                  onClick={() =>
                    history.push(
                      "/event-provider/ce-calendar/events-with-attendees"
                    )
                  }
                >
                  View All Events
                </Button>
              </Col> */}
      </Row>
    </Col>
  );
};
export default memo(PageDashboardPieChart);
