import React, { useState, useEffect } from "react";
import { Layout, Input, Row, Col, Table, Pagination } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import { CreditCardOutlined } from "@ant-design/icons";

import FloatInputSearch from "../../../../providers/FloatInputSearch";

export default function PageAttendeesPayments({ props, permission }) {
	const { Search } = Input;
	const sub_title = "Attendees Payments";

	const data = [
		{
			key: "1",
			invoice: "#00310",
			paid_date: "Aug, 17 2020",
			event: "Teeth Whitening and Sesativity",
			attendee: "Andrea Blake",
			event_total: "$200.00",
			vender_payment: "$180.00",
		},
		{
			key: "2",
			invoice: "#00311",
			paid_date: "Aug, 17 2020",
			event: "Teeth Whitening and Sesativity",
			attendee: "Jill Smith",
			event_total: "$200.00",
			vender_payment: "$180.00",
		},
	];
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const [searchText, setSearchText] = useState("");
	const handleOnChange = (e) => {
		setSearchText(e);
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		// refetchVenue();
		console.log("dataTableInfo", dataTableInfo);
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
		console.log("current", current);
		console.log("pageSize", pageSize);
	};
	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageAttendeesPayments"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<CreditCardOutlined />}
			/>

			<Layout.Content
				title="Invoices"
				style={{ padding: "30px", paddingTop: "0px" }}
			>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/654658516?autoplay=0&muted=1"
					}
				/>{" "}
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							// total={dataGetEvent && dataGetEvent.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<Table
					className="table-responsive table-thead-login"
					dataSource={data}
					pagination={false}
					size="small"
					// onChange={handleTableChange}
				>
					<Table.Column
						key="invoice"
						title="Invoice#"
						dataIndex="invoice"
						sorter
					/>

					<Table.Column
						key="paid_date"
						title="Paid Date"
						dataIndex="paid_date"
						sorter
					/>
					<Table.Column key="event" title="Event" dataIndex="event" sorter />

					<Table.Column
						key="attendee"
						title="Attendee"
						dataIndex="attendee"
						sorter
					/>
					<Table.Column
						key="event_total"
						title="Event Total"
						dataIndex="event_total"
						sorter
					/>
					<Table.Column
						key="vender_payment"
						title="Vendor Payment"
						dataIndex="vender_payment"
						sorter
					/>
				</Table>
			</Layout.Content>
		</Layout>
	);
}
