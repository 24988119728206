import React, { useState, useEffect } from "react";
import {
	Layout,
	message,
	Form,
	Button,
	Upload,
	Row,
	Col,
	notification,
	Collapse,
	Alert,
	Radio,
	Divider,
} from "antd";
import {
	SaveOutlined,
	MinusOutlined,
	InboxOutlined,
	UpOutlined,
	DownOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShop } from "@fortawesome/free-solid-svg-icons";
import { TextLoop } from "react-text-loop-next";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatInputRate from "../../../../providers/FloatInputRate";
import FloatInputNumber from "../../../../providers/FloatInputNumber";
import { POSTFILE, GET } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import countryList from "react-select-country-list";
import states from "states-us";

import CheckSubscription from "../../../../providers/CheckSubscription";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

const PageVenueCreate = ({ match, permission, history }) => {
	const { Panel } = Collapse;
	const sub_title = "Create A";
	const [form] = Form.useForm();
	const userdata = getUserData();
	let country_list = countryList().getData();
	const [country, setCountry] = useState(optionCountryCodes);
	const [state, setState] = useState([]);
	const { Dragger } = Upload;

	const modulesToolBar = {
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			["link", "image", "video"],
			["clean"],
		],
		imageResize: {
			modules: ["Resize", "DisplaySize"],
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
		require_false: {
			required: false,
			message: "Required",
		},
		email: {
			type: "email",
			message: "please enter a valid email",
		},
	};

	const [memberCompany, setMemberCompany] = useState();

	const { data: dataMemberCompanyPrimary, isLoading: isLoadingGetAccount } =
		GET(
			`api/v1/getMemberCompanyPrimary?user_id=${userdata.id}`,
			"dataMemberCompanyPrimarysssss",
			(res) => {
				if (res.success) {
					console.log("dataMemberCompanyPrimarysssss", res.data);

					form.setFieldsValue({
						country: res.data.country,
						address_1: res.data.address_1,
						address_2: res.data.address_2,
						state: res.data.state,
						city: res.data.city,
						zip: res.data.zip,
						map_link: res.data.map_link,
					});
					setMemberCompany({
						country: res.data.country,
						address_1: res.data.address_1,
						address_2: res.data.address_2,
						city: res.data.city,
						state: res.data.state,
						zip: res.data.zip,
						map_link: res.data.map_link,
					});
				}
			}
		);

	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const [description, setDescription] = useState();
	const [direction_details, setDirection] = useState();
	const [requirements, setRequirements] = useState();

	const [fileList, setFileList] = useState([]);
	const [multifileList, setMultiFileList] = useState([]);

	const { mutate: mutateVenue, isLoading: isLoadingVenue } = POSTFILE(
		"api/v1/venue",
		"venue_create"
	);

	const handleFinish = (val) => {
		console.log("handleFinish", val);
		console.log("handleFinish", fileList);
		const data = new FormData();

		data.append("map_link", memberCompany.map_link);
		data.append("address_1", memberCompany.address_1);
		data.append("address_2", memberCompany.address_2);
		data.append("city", memberCompany.city);
		data.append("country", memberCompany.country);
		data.append("state", memberCompany.state);
		data.append("zip", memberCompany.zip);

		data.append("capacity", val.capacity);
		data.append("catering", val.catering);
		data.append("room_rate", val.room_rate);
		data.append("room_size", val.room_size);
		data.append("telephone", val.telephone);
		data.append("venue_email", val.venue_email ?? "");
		data.append("venue_name", val.venue_name);
		data.append("venue_type", val.venue_type);
		data.append(
			"website_booking_link",
			val.website_booking_link ? val.website_booking_link : ""
		);
		data.append("website_link", val.website_link ? val.website_link : "");
		data.append("description", description ?? "");
		data.append("direction_details", direction_details ?? "");
		data.append("requirements", requirements ?? "");
		if (fileList.length !== 0) {
			if (fileList[0].uid != "-1") {
				data.append("venue_logo", fileList[0].originFileObj, fileList[0].name);
			}
		}
		if (multifileList.length > 0) {
			data.append(
				"venue_images_count",
				multifileList ? multifileList.length : 0
			);
			multifileList.map((item, index) => {
				data.append("venue_images_" + index, item.originFileObj, item.name);
			});
			// console.log("venue_images", multifileList);
			// data.append("venue_images", multifileList);
		}

		if (fileList.length > 0 && multifileList.length > 0) {
			mutateVenue(data, {
				onSuccess: (res) => {
					if (res.success) {
						console.log("res", res);
						notification.success({
							message: "Success",
							description: "Successfully save!",
						});
						form.resetFields();
						setFileList([]);
						setDescription("");
						setDirection("");
						setRequirements("");
						window.location.href =
							"/venue-provider/venue-options/venue/" + res.data.id;
					}
				},
			});
		} else {
			notification.warning({
				message: "Warning",
				description: "Venue Images or Company Logo is Empty!",
			});
		}
	};

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	useEffect(() => {
		if (multifileList.length > 0) {
			console.log("multifileList", multifileList);
		}
	}, [multifileList]);

	const [subscription, setSubscription] = useState(0);
	const [subscriptionUnli, setSubscriptionUnli] = useState(false);
	const [subscriptionStatus, setSubscriptionStatus] = useState(false);
	const [subscriptionMessage, setSubscriptionMessage] = useState("");
	// const [subscriptionMessage, setSubscriptionMessage] = useState("");
	const { data: dataPlan, refetch: refetchPlan } = GET(
		`api/v1/account_subscription_venue?user_id=${userdata.id}`,
		"account_subscription_venue",
		(res) => {
			console.log("account_subscription_venue", res);
			if (res.success) {
				if (res.up_to == 0) {
					setSubscriptionUnli(false);
				} else {
					setSubscriptionUnli(true);
				}
				setSubscription(res.up_to);
				setSubscriptionMessage("");
			} else {
				setSubscription(0);
				setSubscriptionStatus(true);
				setSubscriptionMessage("No Subcriptiption Found");
			}
		}
	);

	// useEffect(() => {
	//   console.log("subscription", subscription);
	// }, [subscription]);

	const [radioData1, setRadioData1] = useState(1);
	const [imageCrop1, setImageCrop1] = useState({
		width: 1,
		height: 1,
	});

	const handleResize1 = (val) => {
		console.log("val", val.target.value);
		setRadioData1(val.target.value);
		if (val.target.value === 1) {
			setImageCrop1({
				width: 1,
				height: 1,
			});
		} else if (val.target.value === 2) {
			setImageCrop1({
				width: 3.9,
				height: 2.6,
			});
		} else if (val.target.value === 3) {
			setImageCrop1({
				width: 1,
				height: 1.5,
			});
		}
	};
	const [radioData2, setRadioData2] = useState(1);
	const [imageCrop2, setImageCrop2] = useState({
		width: 1,
		height: 1,
	});

	const handleResize2 = (val) => {
		console.log("val", val.target.value);
		setRadioData2(val.target.value);
		if (val.target.value === 1) {
			setImageCrop2({
				width: 1,
				height: 1,
			});
		} else if (val.target.value === 2) {
			setImageCrop2({
				width: 3.9,
				height: 2.6,
			});
		} else if (val.target.value === 3) {
			setImageCrop2({
				width: 1,
				height: 1.5,
			});
		}
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageVenueCreate"
		>
			<CheckSubscription user_id={userdata.id} />

			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<FontAwesomeIcon icon={faShop} style={{ width: "100%" }} />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/644213872?autoplay=0&muted=1"
					}
					col="venue_option"
					colLink="venue_option_link"
				/>
				{subscriptionMessage != "" && (
					<Alert
						banner
						type="error"
						message={
							<div>
								{subscriptionMessage + ", "}{" "}
								<Link to="/venue-provider/my-account/invoices/subscription/upgrade">
									Upgrade Subscription
								</Link>
							</div>
						}
					/>
				)}
				<br />
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={handleFinish}
					onValuesChange={(changedValues, allValues) => {
						console.log(changedValues);
						if (subscriptionUnli) {
							if (changedValues.capacity > subscription) {
								notification.error({
									message: "Error",
									description: "Beyond on the Plan Seats limit ",
								});

								form.setFieldsValue({
									...allValues,
									capacity: subscription,
								});
							}
						}
					}}
				>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Create A Venue"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="venue_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Venue Room Name"
													placeholder="Venue Room Name"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="capacity"
												rules={[validator.require]}
												hasFeedback
												className="capacity"
											>
												<FloatInputNumber
													label="Capacity"
													placeholder="Capacity"
													// limit={subscriptionUnli ? subscription : ""}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="room_size"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="Room Size (sq. ft.)"
													placeholder="Room Size (sq. ft.)"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="catering"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="Catering"
													placeholder="Catering"
													options={[
														{
															label: "Yes",
															value: "1",
														},
														{
															label: "No",
															value: "0",
														},
													]}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<label>Description / Restrictions</label>
											<ReactQuill
												theme="snow"
												className="venue-quill"
												style={{ height: 200 }}
												modules={modulesToolBar}
												formats={formats}
												onChange={(e) => {
													setDescription(e);
												}}
											/>
											<br />
											<br />
											<br />
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="country"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="Country"
													placeholder="Country"
													options={country}
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item name="address_1" rules={[validator.require]}>
												<FloatInput
													label="Address 1"
													placeholder="Address 1"
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item name="address_2">
												<FloatInput
													label="Address 2"
													placeholder="Address 2"
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
											<Form.Item
												name="state"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="State"
													placeholder="State"
													options={state}
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
											<Form.Item
												name="city"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInput
													label="City"
													placeholder="City"
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
											<Form.Item
												name="zip"
												rules={[
													validator.require,
													//   {
													//     pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
													//     message: "Invalid Zip Code",
													//   },
												]}
												hasFeedback
											>
												<FloatInput
													label="Zip Code"
													placeholder="Zip Code"
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<label>
												Please include full Google Map's "embed code", you can
												get this from Google's share feature
											</label>
											<Form.Item
												name="map_link"
												rules={[
													{
														required: true,
														message: "Required Please Edit on My Company Page",
													},
												]}
											>
												<FloatTextArea
													label="Map Embed Code"
													placeholder="Map Embed Code"
													disabled={true}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<label>Direction Details</label>
											<ReactQuill
												theme="snow"
												className="venue-quill"
												style={{ height: 200 }}
												modules={modulesToolBar}
												formats={formats}
												onChange={(e) => {
													setDirection(e);
												}}
											/>
											<br />
											<br />
											<br />
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="telephone"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputMask
													label="Contact Phone"
													placeholder="Contact Phone"
													maskLabel="telephone"
													maskType="999-999-9999"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item name="venue_email" rules={[validator.email]}>
												<FloatInput
													label="Contact Email"
													placeholder="Contact Email"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="room_rate"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputRate
													label="Room Rate"
													placeholder="Room Rate"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="venue_type"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													label="Select Hourly or Daily"
													placeholder="Select Hourly or Daily"
													options={[
														{
															label: "Hourly",
															value: "Hourly",
														},
														{
															label: "Daily",
															value: "Daily",
														},
													]}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<label>Requirements</label>
											<ReactQuill
												theme="snow"
												className="venue-quill"
												style={{ height: 200 }}
												modules={modulesToolBar}
												formats={formats}
												onChange={(e) => {
													setRequirements(e);
												}}
											/>
											<br />
											<br />
											<br />
										</Col>
									</Row>
								</Panel>
							</Collapse>
							<br />

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Venue Images"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<label className="font-red">
										<b>
											Photo upload & Cropping: Select your image orientation
										</b>
									</label>
									<br />
									<Radio.Group onChange={handleResize1} value={radioData1}>
										<Radio value={1}>Square</Radio>
										<Radio value={2}>Rectangle</Radio>
										<Radio value={3}>Portrait</Radio>
									</Radio.Group>
									<Divider />
									<ImgCrop rotate aspect={imageCrop1.width / imageCrop1.height}>
										<Upload
											className="venue-images"
											listType="picture"
											fileList={multifileList}
											onChange={({ fileList: newFileList }) => {
												var _file = newFileList;
												console.log(_file);
												_file.map((row, key) => {
													return (row.status = "done");
												});
												setMultiFileList(_file);
											}}
											beforeUpload={(file) => {
												console.log(file.size);
												let error = false;
												const isJpgOrPng =
													file.type === "image/jpeg" ||
													file.type === "image/png" ||
													file.type === "image/gif" ||
													file.type === "image/jpg";
												if (!isJpgOrPng) {
													message.error(
														"You can only upload JPG, PNG, GIF, JPEG file!"
													);
													error = Upload.LIST_IGNORE;
												}
												const isLt2M = file.size / 102400 / 102400 < 10;
												if (!isLt2M) {
													message.error("Image must smaller than 10MB!");
													error = Upload.LIST_IGNORE;
												}
												if (error === false) {
													return isJpgOrPng && isLt2M;
												}
											}}
											onPreview={async (file) => {
												let src = file.url;
												if (!src) {
													src = await new Promise((resolve) => {
														const reader = new FileReader();
														reader.readAsDataURL(file.originFileObj);
														reader.onload = () => resolve(reader.result);
													});
												}
												const image = new Image();
												image.src = src;
												const imgWindow = window.open(src);
												imgWindow.document.write(image.outerHTML);
											}}
										>
											<Button type="dashed" className="venue-images-btn">
												<p className="ant-upload-text">
													<p className="ant-upload-drag-icon">
														<InboxOutlined />
													</p>
													<p className="ant-upload-text">
														Add a new file. Unlimited number of files can be
														uploaded to this field. 10MB limit.
													</p>
													<p className="ant-upload-hint">
														Allowed types: png gif jpg jpeg
													</p>
												</p>
											</Button>
										</Upload>
									</ImgCrop>
								</Panel>
							</Collapse>
							<br />
						</Col>

						<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Company Logo"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<label className="font-red">
										<b>
											Photo upload & Cropping: Select your image orientation
										</b>
									</label>{" "}
									<br />
									<Radio.Group onChange={handleResize2} value={radioData2}>
										<Radio value={1}>Square</Radio>
										<Radio value={2}>Rectangle</Radio>
										<Radio value={3}>Portrait</Radio>
									</Radio.Group>
									<Divider />
									<div>
										<ImgCrop
											rotate
											aspect={imageCrop2.width / imageCrop2.height}
										>
											<Upload
												listType="picture-card"
												style={{ width: "200px" }}
												maxCount={1}
												fileList={fileList}
												onChange={({ fileList: newFileList }) => {
													var _file = newFileList;
													if (_file.length != 0) {
														_file[0].status = "done";
														setFileList(_file);
													} else {
														setFileList([]);
													}
												}}
												onPreview={async (file) => {
													let src = file.url;
													if (!src) {
														src = await new Promise((resolve) => {
															const reader = new FileReader();
															reader.readAsDataURL(file.originFileObj);
															reader.onload = () => resolve(reader.result);
														});
													}
													const image = new Image();
													image.src = src;
													const imgWindow = window.open(src);
													imgWindow.document.write(image.outerHTML);
												}}
												beforeUpload={(file) => {
													const isJpgOrPng =
														file.type === "image/jpeg" ||
														file.type === "image/png" ||
														file.type === "image/gif" ||
														file.type === "image/jpg";
													if (!isJpgOrPng) {
														message.error(
															"You can only upload JPG, PNG, GIF, JPEG file!"
														);
														setFileList([]);
														return;
													}
													const isLt2M = file.size / 102400 / 102400 < 10;
													if (!isLt2M) {
														message.error("Image must smaller than 10MB!");
														setFileList([]);
														return;
													}
													return isJpgOrPng && isLt2M;
												}}
											>
												{fileList.length < 1 && "+ Upload"}
											</Upload>
										</ImgCrop>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<span>One file Only. 10 MB limit.</span>
										<span>
											Your selected profile photo will be visible to all event
											organizers and event attendees. <br></br>
										</span>
									</div>
									<div className="c-secondary" style={{ fontSize: "12px" }}>
										Allowed types: png gif jpg jpeg.
									</div>
								</Panel>
							</Collapse>
							<br />

							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="Venue Link"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="website_link"
												rules={[validator.require_false]}
												hasFeedback
											>
												<FloatInput
													label="Venue Website"
													placeholder="Venue Website"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="website_booking_link"
												rules={[validator.require_false]}
												hasFeedback
											>
												<FloatInput
													label="Venue Booking Link"
													placeholder="Venue Booking Link"
												/>
											</Form.Item>
										</Col>

										<Col
											xs={24}
											sm={24}
											md={24}
											lg={24}
											xl={24}
											xxl={24}
											style={{
												fontSize: "12px",
											}}
										>
											<Button
												size="large"
												className="btn-login-outline"
												// style={{ width: 200 }}
												block
												icon={<SaveOutlined />}
												htmlType="submit"
												loading={isLoadingVenue}
												// disabled={subscriptionStatus}
											>
												Save
											</Button>
										</Col>
									</Row>
								</Panel>
							</Collapse>
						</Col>
					</Row>
				</Form>
			</Layout.Content>
		</Layout>
	);
};

export default PageVenueCreate;
