import React, { useState, useEffect } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	Tooltip,
	Collapse,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
// import ComponentFaqs from "../Components/ComponentFaqs";
import {
	EditOutlined,
	TeamOutlined,
	MailOutlined,
	PieChartOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET } from "../../../../providers/useAxiosQuery";
import { useHistory, Link } from "react-router-dom";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
export default function PageStatsAccountType({ props, permission }) {
	const sub_title = "Acccount";
	const history = useHistory();
	const { Panel } = Collapse;
	const [totalPieUser, setTotalPieUser] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
		from: "stats",
	});

	const {
		data: dataGetProfiles,
		isLoading: isLoadingGetProfiles,
		refetch: refetchGetProfiles,
	} = GET(`api/v1/users?${$.param(dataTableInfo)}`, "profiles", (res) => {
		if (res.success) {
			console.log("profiles", res);
		}
	});

	const {
		data: dataGetProfilesAll,
		isLoading: isLoadingGetProfilesAll,
		refetch: refetchGetProfilesAll,
	} = GET(`api/v1/users`, "profilesall", (res) => {
		if (res.success) {
			console.log("profiles", res);

			if (res.data) {
				var arr = [
					{
						name: "Member",
						y: 0,
						color: "#1c9a06",
					},
					{
						name: "Event Providers",
						y: 0,
						color: "#293a56",
					},
					{
						name: "Event Provider/Speaker",
						y: 0,
						color: "#560bd0",
					},
					{
						name: "Speaker",
						y: 0,
						color: "#f7a35c",
					},
					{
						name: "Venue Provider",
						y: 0,
						color: "#d6ae71",
					},
				];

				var total_user = 0;
				res.data.forEach((element) => {
					if (element.role == "MEMBER") {
						arr[0].y += 1;
						total_user += 1;
					}
					if (element.role == "EVENT PROVIDER") {
						arr[1].y += 1;
						total_user += 1;
					}
					if (element.role == "EVENT PROVIDER/SPEAKER") {
						arr[2].y += 1;
						total_user += 1;
					}
					if (element.role == "SPEAKER") {
						arr[3].y += 1;
						total_user += 1;
					}
					if (element.role == "VENUE PROVIDER") {
						arr[4].y += 1;
						total_user += 1;
					}
				});

				setTotalPieUser(total_user);

				Highcharts.chart("div_account_type", {
					credits: false,
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: "pie",
						// width: 250,
					},
					title: {
						text: null,
					},
					tooltip: {
						formatter: function () {
							console.log("this", this);
							return `<b>${this.key}: ${this.y}`;
						},
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: "pointer",
							dataLabels: {
								enabled: true,
								distance: -50,
								formatter: function () {
									return `<b style="font-size:16px;">${this.y}</b>`;
								},
							},
							showInLegend: true,
						},
					},
					legend: {
						align: "center",
						verticalAlign: "top",
					},
					accessibility: {
						enabled: false,
					},
					series: [
						{
							name: "ACCOUNT TYPE",
							colorByPoint: true,
							data: arr,
						},
					],
				});
			}
		}
	});

	const handleTableChange = (pagination, filters, sorter) => {
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchGetProfiles();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageStatsAccountType"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<PieChartOutlined />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<br />
				<Row style={{ marginTop: 20 }} gutter={12}>
					<Col xs={24} sm={24} md={24} lg={16}>
						<Collapse
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(270deg)",
										}}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(90deg)",
										}}
									></span>
								)
							}
							style={{
								boxShadow:
									"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
								marginTop: 10,
							}}
							expandIconPosition="right"
							defaultActiveKey={["1"]}
						>
							<Panel
								header={"Users"}
								key="1"
								className="accordion bg-darkgray-form"
							>
								<Row gutter={24} className="search-table" align="bottom">
									<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
										<Pagination
											className="paginate"
											showSizeChanger
											onChange={onChange}
											current={currentPage}
											pageSize={currentSize}
											total={dataGetProfiles && dataGetProfiles.data.total}
											size="small"
										/>
									</Col>
									<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
										<FloatInputSearch
											label="Search..."
											placeholder="Search..."
											dataTableInfo={dataTableInfo}
											setDataTableInfo={setDataTableInfo}
											value={searchText}
											onChange={setSearchText}
										/>
									</Col>
								</Row>
								<br />
								<div className="table-responsive">
									<Table
										className="table-responsive table-thead-login"
										size="small"
										rowKey={(record) => record.id}
										loading={isLoadingGetProfiles}
										dataSource={
											dataGetProfiles ? dataGetProfiles.data.data : []
										}
										pagination={false}
										onChange={handleTableChange}
									>
										<Table.Column
											key="first_name"
											title="First Name"
											dataIndex="first_name"
											sorter
										/>
										<Table.Column
											key="last_name"
											title="Last Name"
											dataIndex="last_name"
											sorter
										/>
										<Table.Column
											key="role"
											title="Account Type"
											dataIndex="role"
											sorter
										/>
									</Table>
								</div>
							</Panel>
						</Collapse>
					</Col>
					<Col xs={24} sm={24} md={24} lg={8}>
						<Collapse
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(270deg)",
										}}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{
											color: "#FFF",
											transform: "rotate(90deg)",
										}}
									></span>
								)
							}
							style={{
								boxShadow:
									"0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),0 5px 12px 4px rgb(0 0 0 / 9%)",
								marginTop: 10,
							}}
							expandIconPosition="right"
							defaultActiveKey={["1"]}
						>
							<Panel
								header={"Total User To Date (" + totalPieUser + ")"}
								key="1"
								className="accordion bg-darkgray-form"
							>
								<div
									id="div_account_type"
									className="highchart-responsive"
									style={{ display: "flex", justifyContent: "center" }}
								/>
							</Panel>
						</Collapse>
					</Col>
				</Row>
			</Layout.Content>
		</Layout>
	);
}
