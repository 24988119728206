import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  Badge,
  Divider,
  Menu,
  Checkbox,
  Table,
  Tag,
  Space,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import {
  HomeOutlined,
  SaveOutlined,
  FieldTimeOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  UserOutlined,
  ContainerOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { faMapLocation, faCircleDot } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import image1 from "../../../../assets/img/image1.jpg";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";

export default function PageAttendeesPayments({ props, permission }) {
  const { Search } = Input;
  const sub_title = "Attendees Payments";

  const data = [
    {
      key: "1",
      invoice: "#00310",
      paid_date: "Aug, 17 2020",
      event: "Teeth Whitening and Sesativity",
      attendee: "Andrea Blake",
      event_total: "$200.00",
      vender_payment: "$180.00",
    },
    {
      key: "2",
      invoice: "#00311",
      paid_date: "Aug, 17 2020",
      event: "Teeth Whitening and Sesativity",
      attendee: "Jill Smith",
      event_total: "$200.00",
      vender_payment: "$180.00",
    },
  ];
  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageAttendeesPayments"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<CreditCardOutlined />}
      />
      <ComponentFaqs
        linkVid={"https://player.vimeo.com/video/654658516?autoplay=0&muted=1"}
      />

      <Layout.Content
        title="Invoices"
        style={{ padding: "30px", paddingTop: "0px" }}
      >
        {" "}
        <br></br>
        <Row>
          <Col md={8} sm={0} xs={4}>
            {" "}
          </Col>
          <Col md={8} sm={0} xs={4}></Col>
          <Col md={8} sm={24} xs={16}>
            <div style={{ display: "flex" }}>
              <Input.Search
                placeholder="Search"
                // onSearch={e =>
                //     setDataTableInfo({
                //         ...dataTableInfo,
                //         filter_value: e,
                //         page_number: 1
                //     })
                // }
                // onChange={e =>
                //     setSearchText(e.target.value)
                // }
              />
            </div>
          </Col>
        </Row>
        <br></br>
        <Table
          className="table-responsive table-thead-login"
          dataSource={data}
          pagination={{
            pageSize: 20,
            current: 1,
            showSizeChanger: true,
            pageSizeOptions: [20, 50, 100, 200],
          }}
        >
          <Table.Column
            key="invoice"
            title="Invoice#"
            dataIndex="invoice"
            sorter
          />

          <Table.Column
            key="paid_date"
            title="Paid Date"
            dataIndex="paid_date"
            sorter
          />
          <Table.Column key="event" title="Event" dataIndex="event" sorter />

          <Table.Column
            key="attendee"
            title="Attendee"
            dataIndex="attendee"
            sorter
          />
          <Table.Column
            key="event_total"
            title="Event Total"
            dataIndex="event_total"
            sorter
          />
          <Table.Column
            key="vender_payment"
            title="Vendor Payment"
            dataIndex="vender_payment"
            sorter
          />
        </Table>
      </Layout.Content>
    </Layout>
  );
}
