import React, { useState, useEffect, useForm } from "react";
import {
  Layout,
  message,
  Form,
  Button,
  Switch,
  Row,
  Col,
  notification,
  Checkbox,
  Space,
  Collapse,
  Alert,
  Input,
  Divider,
  Modal,
  Radio,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { TeamOutlined } from "@ant-design/icons";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { Link } from "react-router-dom";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import ComponentFaqs from "../Components/ComponentFaqs";

import $ from "jquery";
import getUserData from "../../../../providers/getUserData";
import FloatSelectWithDangerouslySetInnerHTML from "../../../../providers/FloatSelectWithDangerouslySetInnerHTML";
import QrReader from "react-qr-reader";
import moment from "moment";
export default function PageQRreader({ props, permission }) {
  const { Panel } = Collapse;
  const { Search } = Input;
  const sub_title = "QR Code";
  const userdata = getUserData();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(50);
  const [form] = Form.useForm();
  const [eventOptions, setEventOptions] = useState([]);
  const [dataTableInfo, setDataTableInfo] = useState({
    search: "",
    page_number: 1,
    page_size: "50",
    column: "event_date",
    order: "desc",
    user_id: userdata.id,
    future_events: 1,
  });

  const [eventId, setEventId] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var id = urlParams.get("id");
    if (id) {
      setEventId(id);
      form.setFieldsValue({ event: Number(id) });
    }
  }, []);

  const {
    data: dataGetEvent,
    isLoading: isLoadingGetEvent,
    refetch: refetchGetEvent,
    isFetching: isFetchingGetEvent,
  } = GET(`api/v1/event?${$.param(dataTableInfo)}`, "event_qrcode", (res) => {
    if (res.success) {
      console.log("wew", res);
      var options = [];
      res.data.data.map((item, key) => {
        var a = {
          label:
            "<div > <b>" +
            item.event_details.title +
            "</b> </div> <div>Date Start: " +
            moment(item.date_from).format("MMMM DD, YYYY ") +
            "</div>",
          value: item.id,
        };
        options.push(a);
      });
      setEventOptions(options);

      if (options.length == 1) {
        setEventId(options[0].value);
        form.setFieldsValue({ event: Number(options[0].value) });
      }

      console.log(options);
    }
  });

  const { mutate: mutateUpdateStatus, isLoading: isLoadingMutateUpdateStatus } =
    POST(
      "api/v1/event_with_attendees/updateStatusQR",
      "event_with_attendee_status"
    );

  const handleScanErrorQrCode = (e) => {};
  const handleScanQrCode = (e) => {
    if (e != null) {
      const user_id = e.split(",");
      const _user_id = user_id[1].split(":");

      var _data = {
        event_id: eventId,
        status: "Attended",
        user_id: _user_id[1],
      };
      mutateUpdateStatus(_data, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description:
                "Scanned Successfully! " +
                res.data.first_name +
                " " +
                res.data.last_name +
                " Attended on this Event",
            });
            // refetchGetEvent();
          } else {
            notification.error({
              message: "Error",
              description: "Can't Find this User on the Registered List",
            });
          }
        },
        onError: (err) => {},
      });
    }
  };
  const handleFinish = (val) => {
    console.log(val);
  };
  const handleEventType = (e) => {
    console.log(e);
    setEventId(e);
  };

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageQRreader"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<TeamOutlined />}
      />
      <Layout.Content
        style={{ padding: "30px", paddingTop: "0px", marginTop: 46 }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              expandIconPosition="right"
              defaultActiveKey={["1"]}
            >
              <Panel
                header="Select Event"
                key="1"
                className="accordion bg-darkgray-form"
              >
                <Form
                  wrapperCol={{ span: 24 }}
                  layout="horizontal"
                  form={form}
                  onFinish={handleFinish}
                >
                  <Form.Item
                    name="event"
                    // rules={[validator.require]}
                    hasFeedback
                    className="form-select-error"
                  >
                    <FloatSelectWithDangerouslySetInnerHTML
                      label="Select Event"
                      placeholder="Select Event"
                      options={eventOptions}
                      onChange={handleEventType}
                    />
                  </Form.Item>
                </Form>
                {eventId != 0 && (
                  <>
                    <div style={{ marginTop: "30px" }}></div>
                    <QrReader
                      onError={handleScanErrorQrCode}
                      onScan={handleScanQrCode}
                      className="qrCodeReaderEvent"
                      style={{
                        height: "400px",
                        width: "400px",
                        margin: "0 auto",
                      }}
                    />
                  </>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
