import { Col, Divider, Image, Card, Row } from "antd";
import React, { useEffect, useState } from "react";

import BlackAndGold from "../../../../../assets/img/certificates/back_and_gold.jpg";
import BlackAndRed from "../../../../../assets/img/certificates/back_and_red.jpg";
import BlueAndGold from "../../../../../assets/img/certificates/blue_and_gold.jpg";
import BlueAndGreen from "../../../../../assets/img/certificates/blue_and_green.jpg";
import BlueAndRed from "../../../../../assets/img/certificates/blue_and_red.jpg";
import BlueAndRed2 from "../../../../../assets/img/certificates/blue_and_red2.jpg";
import BlueAndTeal from "../../../../../assets/img/certificates/blue_and_teal.jpg";

import BlackAndGold1 from "../../../../../assets/img/certificates/back_and_gold-1.jpg";
import BlackAndRed1 from "../../../../../assets/img/certificates/back_and_red-1.jpg";
import BlueAndGold1 from "../../../../../assets/img/certificates/blue_and_gold-1.jpg";
import BlueAndGreen1 from "../../../../../assets/img/certificates/blue_and_green-1.jpg";
import BlueAndRed1 from "../../../../../assets/img/certificates/blue_and_red-1.jpg";
import BlueAndRed21 from "../../../../../assets/img/certificates/blue_and_red2-1.jpg";
import BlueAndTeal1 from "../../../../../assets/img/certificates/blue_and_teal-1.jpg";
import { GET } from "../../../../../providers/useAxiosQuery";
import companyInfo from "../../../../../providers/companyInfo";
import moment from "moment";

const SendCertificate = ({ dataCetificate }) => {
  const [certBG, setCertBG] = useState();
  const [certSign, setCertSign] = useState();
  const [certLogo, setCertLogo] = useState();
  const [certCERP, setCertCERP] = useState();
  const [certPACE, setCertPACE] = useState();
  const [certParticipants, setCertParticipants] = useState();
  const [certSchedule, setCertSchedule] = useState();
  const [certScheduleTime, setCertScheduleTime] = useState();
  const [certVerification, setCertVerification] = useState(0);
  const [certStateLicense, setCertStateLicense] = useState();
  const [certAGD, setCertAGD] = useState();
  const [certEventSpeaker, setCertEventSpeaker] = useState();

  useEffect(() => {
    if (dataCetificate !== null) {
      let joint = 0;
      if (
        dataCetificate.event &&
        dataCetificate.event.joint_event_provider_1 !== ""
      ) {
        joint = 1;
      }
      if (
        dataCetificate.event &&
        dataCetificate.event.joint_event_provider_1 !== ""
      ) {
        joint = 1;
      }
      if (
        dataCetificate.event &&
        dataCetificate.event.joint_event_provider_1 !== ""
      ) {
        joint = 1;
      }
      if (dataCetificate.certificate.template === "Black and Gold") {
        if (joint == 1) {
          setCertBG(BlackAndGold);
        } else {
          setCertBG(BlackAndGold1);
        }
      } else if (dataCetificate.certificate.template === "Black and Red") {
        if (joint == 1) {
          setCertBG(BlackAndRed);
        } else {
          setCertBG(BlackAndRed1);
        }
      } else if (dataCetificate.certificate.template === "Blue and Gold") {
        if (joint == 1) {
          setCertBG(BlueAndGold);
        } else {
          setCertBG(BlueAndGold1);
        }
      } else if (dataCetificate.certificate.template === "Blue and Green") {
        if (joint == 1) {
          setCertBG(BlueAndGreen);
        } else {
          setCertBG(BlueAndGreen1);
        }
      } else if (dataCetificate.certificate.template === "Blue and Red 1") {
        if (joint == 1) {
          setCertBG(BlueAndRed);
        } else {
          setCertBG(BlueAndRed1);
        }
      } else if (dataCetificate.certificate.template === "Blue and Red 2") {
        if (joint == 1) {
          setCertBG(BlueAndRed2);
        } else {
          setCertBG(BlueAndRed2);
        }
      } else if (dataCetificate.certificate.template === "Blue and Teal") {
        if (joint == 1) {
          setCertBG(BlueAndTeal);
        } else {
          setCertBG(BlueAndTeal1);
        }
      }
      setCertSign(dataCetificate.signature);
      setCertLogo(dataCetificate.company_logo);
      setCertCERP(dataCetificate.ada_cerp_logo);
      setCertPACE(dataCetificate.ada_pace_logo);
      setCertParticipants(
        JSON.parse(dataCetificate.event.attending_participants)
      );

      // console.log("dataCetificates", dataCetificate);
      let speaker =
        dataCetificate &&
        dataCetificate.event_speaker
          .reduce((arr, items) => {
            let name = items.speaker_name.split(", ");
            name = name[0] + ";";
            return (arr += name);
          }, [])
          .split(";")
          .join(", ");
      setCertEventSpeaker(
        dataCetificate && dataCetificate.event_shecdule
          ? speaker.slice(0, -2)
          : "N/A"
      );

      let schedule = dataCetificate.event_shecdule
        .reduce((arr, items) => {
          let name = moment(items.datetime_start).format("L");
          name = name + ";";
          return (arr += name);
        }, [])
        .split(";")
        .join(", ");
      setCertSchedule(schedule.slice(0, -2));
      let scheduleTime = dataCetificate.event_shecdule
        .reduce((arr, items) => {
          let name = moment(items.datetime_start).format("LT");
          name = name + ";";
          return (arr += name);
        }, [])
        .split(";")
        .join(", ");
      setCertScheduleTime(scheduleTime.slice(0, -2));

      if (dataCetificate.member_ce_hours_profiles.length > 0) {
        let stateLicense =
          dataCetificate.member_ce_hours_profiles &&
          dataCetificate.member_ce_hours_profiles
            .reduce((arr, items) => {
              let name = items.dental_license;
              name = name + ";";
              return (arr += name);
            }, [])
            .split(";")
            .join(", ");
        setCertStateLicense(stateLicense.slice(0, -2));
        let agd =
          dataCetificate.member_ce_hours_profiles &&
          dataCetificate.member_ce_hours_profiles
            .reduce((arr, items) => {
              let name = items.agd_id;
              name = name + ";";
              return (arr += name);
            }, [])
            .split(";")
            .join(", ");
        setCertAGD(agd.slice(0, -2));
      } else {
        setCertStateLicense("N/A");
        setCertAGD("N/A");
      }

      let verification = JSON.parse(dataCetificate.event.verification);
      let count = 0;
      verification.map((row, key) => {
        if (row === "ADA CERP Verification") {
          count = count + 1;
          // setCertVerification(1);
        } else if (row === "ADA PACE Verification") {
          count = count + 2;
        } else {
          count = 0;
        }
      });
      setCertVerification(count);
      // console.log("count", count);
      // alert("asd");
    }
  }, [dataCetificate]);

  return (
    dataCetificate && (
      <div
        footer={false}
        style={{
          position: "absolute",
          width: "630px",
          height: "791px",
          marginLeft: "-1px",
          // border: "1px solid",
          fontSize: "12px",
        }}
      >
        <img
          src={certBG}
          style={{
            width: "630px",
            height: "791px",
            position: "absolute",
          }}
        />
        <img
          src={`data:image/png;base64,` + certLogo}
          style={{
            position: "absolute",
            width: "143px",
            height: "107px",
            top: "30px",
            left: "24px",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "129px",
            height: "169px",
            marginTop: "42px",
            marginLeft: "178px",
          }}
        >
          <span>
            <span>
              {dataCetificate.events &&
              dataCetificate.events.user &&
              dataCetificate.events.user.member_company
                ? dataCetificate.events.user.member_company.business_name
                : ""}
              {dataCetificate.event_venue
                ? dataCetificate.event_venue.address_1
                  ? ", " + dataCetificate.event_venue.address_1
                  : dataCetificate.event_venue.city
                  ? ", " + dataCetificate.event_venue.city
                  : ""
                : ""}
            </span>
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            marginTop: "42px",
            right: "34px",
            textAlign: "right",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* {certParticipants &&
            certParticipants.map((row, key) => {
              return <span key={key}>{row}</span>;
            })} */}
          <span>{dataCetificate.event.joint_event_provider_1}</span>
          <span>{dataCetificate.event.joint_event_provider_2}</span>
          <span>{dataCetificate.event.joint_event_provider_3}</span>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row gutter={24}>
          <Col xs={19} sm={19} md={19} offset={2}>
            <span className="cert-text">
              {dataCetificate.member.first_name +
                " " +
                dataCetificate.member.last_name}
            </span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              PARTICIPANT'S &nbsp;&nbsp; NAME
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={8} sm={8} md={8} offset={3}>
            <span className="cert-text">{certStateLicense}</span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              STATE &nbsp; LICENSE &nbsp;#
            </span>
          </Col>
          <Col xs={8} sm={8} md={8} offset={1}>
            <span className="cert-text">{certAGD}</span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              PARTICIPANT'S &nbsp; AGD &nbsp; ID #
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={19} sm={19} md={19} offset={2}>
            <span className="cert-text">{dataCetificate.event.title}</span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              EVENT &nbsp; NAME
            </span>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={19} sm={19} md={19} offset={2}>
            <span className="cert-text">
              {dataCetificate.event_venue
                ? dataCetificate.event_venue.meeting_url
                  ? dataCetificate.event_venue.meeting_url
                  : dataCetificate.event_venue.address_1
                  ? dataCetificate.event_venue.address_1
                  : dataCetificate.event_venue.city
                : "N/A"}
            </span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              LOCATION
            </span>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={5} sm={5} md={5} offset={2}>
            {certSchedule && <span className="cert-text">{certSchedule}</span>}
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              DATE
            </span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            {/* <span className="cert-text">12 : 00 PM</span> */}
            {certScheduleTime && (
              <span className="cert-text">{certScheduleTime}</span>
            )}
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              START &nbsp; TIME
            </span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {dataCetificate.event.ce_credit_hours}
            </span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              CE &nbsp; CREDITS
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {dataCetificate.event.subject_code}
            </span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              SUBJECT &nbsp; CODE
            </span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {dataCetificate.event.course_topic}
            </span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              EDUCATION &nbsp; METHOD
            </span>
          </Col>
          <Col xs={5} sm={5} md={5} offset={2}>
            <span className="cert-text">
              {dataCetificate.event.completion_code}
            </span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              COMPLETION &nbsp; CODE
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={8} sm={8} md={8} offset={2}>
            <span className="cert-text">{certEventSpeaker}</span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              SPEAKER
            </span>
          </Col>
          <Col xs={8} sm={8} md={8} offset={3}>
            <span className="cert-text">{dataCetificate.event.director}</span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              DIRECTOR
            </span>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={8} sm={8} md={8} offset={2}>
            <span className="cert-text">
              {dataCetificate.event.facilitator}
            </span>
            <Divider
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              FACILITATOR
            </span>
          </Col>
          <Col xs={8} sm={8} md={8} offset={3}>
            {dataCetificate && dataCetificate.signature && (
              <img
                // src={companyInfo().apiUrl + dataCetificate.signature}
                src={`data:image/png;base64,` + certSign}
                style={{
                  width: "70px",
                  height: "25px",
                  marginLeft: "65px",
                  marginTop: "-5px",
                }}
              />
            )}
            <Divider
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                borderColor: "#F0F0EF",
                background: "#F0F0EF",
              }}
            />
            <span className="cert-label" style={{ fontSize: "10px" }}>
              AUTHORIZED &nbsp; SIGNATURE
            </span>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {/*
         */}
        <Row gutter={24} style={{ position: "absolute", bottom: "32px" }}>
          {certVerification === 3 && (
            <>
              <Col md={12}>
                <Image
                  // src={certCERP}
                  src={`data:image/png;base64,` + certCERP}
                  preview={false}
                  style={{
                    width: "279px",
                    height: "55px",
                    marginLeft: "14px",
                  }}
                />
              </Col>
              <Col md={12}>
                <Image
                  // src={certPACE}
                  src={`data:image/png;base64,` + certPACE}
                  preview={false}
                  style={{
                    width: "279px",
                    height: "55px",
                    marginRight: "34px",
                    //   marginTop: "48px",
                  }}
                />
              </Col>
            </>
          )}
          {certVerification === 1 && (
            <>
              <Col xs={12} sm={12} md={12}></Col>
              <Col xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                <Image
                  // src={certCERP}
                  src={`data:image/png;base64,` + certCERP}
                  preview={false}
                  style={{
                    width: "279px",
                    height: "55px",
                    // marginLeft: "10px",
                    // marginTop: "48px",
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={12}></Col>
            </>
          )}

          {certVerification === 2 && (
            <>
              <Col xs={12} sm={12} md={12}></Col>
              <Col xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                <br></br>
                <Image
                  // src={certPACE}
                  src={`data:image/png;base64,` + certPACE}
                  preview={false}
                  style={{
                    width: "279px",
                    height: "55px",
                    // marginLeft: "10px",
                    // marginTop: "48px",
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={12}></Col>
            </>
          )}
        </Row>
      </div>
    )
  );
};

export default SendCertificate;
