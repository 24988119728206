import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Table, Pagination } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";

import { Link } from "react-router-dom";

import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET } from "../../../../providers/useAxiosQuery";
import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShop } from "@fortawesome/free-solid-svg-icons";
import getUserData from "../../../../providers/getUserData";

export default function PageVenueList({ props, permission }) {
	const sub_title = "All";
	const userdata = getUserData();
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	const [dataTableInfo, setDataTableInfo] = useState({
		user_id: userdata.id,
		search: "",
		state: "",
		page_number: 1,
		page_size: "50",
		column: "id",
		order: "desc",
	});

	const {
		data: dataGetVenue,
		isLoading: isLoadingGetVenue,
		refetch: refetchGetVenue,
	} = GET(`api/v1/venue?${$.param(dataTableInfo)}`, "venue", (res) => {
		if (res.success) {
			console.log("venue", res);
		}
	});

	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDataTableInfo({
				...dataTableInfo,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		refetchGetVenue();
		return () => {};
	}, [dataTableInfo]);

	const onChange = (current, pageSize) => {
		setDataTableInfo({
			...dataTableInfo,
			page_number: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};
	const handleTableChange = (pagination, filters, sorter) => {
		// console.log("sorter", sorter);
		setDataTableInfo({
			...dataTableInfo,
			column: sorter.columnKey,
			order: sorter.order ? sorter.order.replace("end", "") : null,
			page_number: 1,
		});
		setCurrentPage(1);
	};
	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageVenueList"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<FontAwesomeIcon icon={faShop} style={{ width: "100%" }} />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/644213903?autoplay=0&muted=1"
					}
					col="venue_option"
					colLink="venue_option_link"
				/>
				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataGetVenue && dataGetVenue.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={dataTableInfo}
							setDataTableInfo={setDataTableInfo}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>
				<br />
				<div className="table-responsive">
					<Table
						className="table-responsive table-thead-login"
						dataSource={dataGetVenue ? dataGetVenue.data.data : []}
						loading={isLoadingGetVenue}
						rowKey={(record) => record.id}
						pagination={false}
						size="small"
						onChange={handleTableChange}
					>
						<Table.Column
							key="business_name"
							title="Company"
							dataIndex="business_name"
							sorter
						/>
						<Table.Column
							key="venue_name"
							title="Venue"
							dataIndex="venue_name"
							sorter
							render={(text, record) => {
								return (
									<Link to={"/venue-provider/venue-options/venue/" + record.id}>
										{text}
									</Link>
								);
							}}
						/>
						<Table.Column key="state" title="State" dataIndex="state" sorter />

						<Table.Column key="city" title="City" dataIndex="city" sorter />
						<Table.Column
							key="capacity"
							title="Capacity"
							dataIndex="capacity"
							sorter
						/>
						<Table.Column
							key="availability"
							title="Catering"
							dataIndex="availability"
							sorter
						/>
					</Table>
				</div>
			</Layout.Content>
		</Layout>
	);
}
