import React, { useState, useEffect, useRef } from "react";
import { Input, InputNumber } from "antd";

import {
  DeleteFilled,
  EditFilled,
  PlusCircleOutlined,
  PrinterOutlined,
  UploadOutlined,
  SettingOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
const FloatInputRate = (props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    type,
    required,
    disabled,
    readOnly,
    addonAfter,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  useEffect(() => {
    // console.log(props)
  }, [props]);
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <InputNumber
        onChange={props.onChange}
        value={value}
        size="large"
        autoComplete="off"
        stringMode
        step="0.00"
        disabled={disabled}
        // formatter={(value) => formatterNumber(value)}
        // parser={(value) => parserNumber(value)}
        style={{ width: "100%" }}
        className="input-rate"
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInputRate;
