import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
	Layout,
	Menu,
	Badge,
	Image,
	Space,
	Dropdown,
	Typography,
	Modal,
	Button,
	Card,
} from "antd";
import {
	MenuUnfoldOutlined,
	UserOutlined,
	CalendarOutlined,
	MessageOutlined,
	QrcodeOutlined,
	FolderOpenOutlined,
	ClockCircleOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { BiBell } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaFileInvoice } from "react-icons/fa";
import { QRCode } from "react-qrcode-logo";

import imageLogo from "../../../assets/img/logo-with-bg.png";
import getUserData from "../../../providers/getUserData";
import companyInfo from "../../../providers/companyInfo";
import { POST, GET } from "../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";

// import getUserData from "../../providers/getUserData";
// import useAxiosQuery from "../../providers/useAxiosQuery";

import NotificationsAlert from "./Components/NotificationsAlert";
import MessagesAlert from "./Components/MessagesAlert";

export default function ComponentsEventHeader({ width, setSideMenuCollapse }) {
	// let history = useHistory();
	const userdata = getUserData();
	console.log("userdata", userdata);
	const [showModalQrCode, setShowModalQrCode] = useState(false);
	const svgRef = useRef();

	const handleLogout = (e) => {
		mutateLogout(
			{
				user_id: userdata.id,
				subcriptionData: localStorage.push_notification_subscription
					? JSON.parse(localStorage.push_notification_subscription)
					: "",
			},
			{
				onSuccess: (res) => {
					console.log("userdata", res);
					if (res.success) {
						localStorage.removeItem("viewas");
						localStorage.removeItem("token");
						localStorage.removeItem("userdata");
						localStorage.removeItem("push_notification_subscription");
						window.location.href = window.location.origin;
					}
				},
			}
		);
	};

	const { mutate: mutateLogout, isLoading: isLoadingLogout } = POST(
		"api/v1/logout",
		"logout"
	);

	const [imageProfile, setImageProfile] = useState();
	useEffect(() => {
		let image_type = userdata.upload ? userdata.upload.split("/") : "";
		if (image_type[0] !== undefined) {
			image_type =
				image_type[0] === "https:"
					? userdata.upload
					: companyInfo().apiUrl + "storage/" + userdata.upload;
		} else {
			image_type =
				"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
		}
		setImageProfile(image_type);

		return () => {};
	}, [userdata]);
	const {
		data: dataProfile,
		refetch: refetchProfile,
		isLoading: isLoadingProfile,
	} = GET(`api/v1/get_by_id?id=${userdata.id}`, `profile`, (res) => {
		let image_type = userdata.upload ? userdata.upload.split("/") : "";
		if (image_type[0] !== undefined) {
			image_type =
				image_type[0] === "https:"
					? userdata.upload
					: companyInfo().apiUrl + "storage/" + userdata.upload;
		} else {
			image_type =
				"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
		}
		setImageProfile(image_type);
	});

	const [unreadNotif, setUnreadNotif] = useState(0);
	const [notification, setnotification] = useState([]);
	const {
		data: dataNotification,
		isLoading: isLoadingNotification,
		refetch: refetchNotification,
	} = GET("api/v1/get_notification_alert", "get_notification_alert", (res) => {
		if (res.success) {
			console.log("dataNotification", res);
			setnotification(res.data);
			setUnreadNotif(res.unread);
		}
	});

	const [unreadMessages, setUnreadMessages] = useState(0);
	const [messages, setMessages] = useState([]);
	const {
		data: dataMessages,
		isLoading: isLoadingMessages,
		refetch: refetchMessages,
	} = GET("api/v1/get_messages_alert", "get_messages_alert", (res) => {
		if (res.success) {
			console.log("dataMessages", res);
			setMessages(res.data);
			setUnreadMessages(res.unread);
		}
	});

	const [memberCompany, setMemberCompany] = useState([]);
	const { data: dataMemberCompanyPrimary, isLoading: isLoadingGetAccount } =
		GET(
			`api/v1/getMemberCompanyPrimary?user_id=${userdata.id}`,
			"dataMemberCompanyPrimary",
			(res) => {
				if (res.success) {
					// console.log("dataMemberCompanyPrimary", res);
					setMemberCompany(res.data);
				}
			}
		);

	const downloadQRCode = () => {
		// var svgElement = document.getElementById("qrCodeEl");
		// let { width, height } = svgElement.getBox();
		// let clonedSvgElement = svgElement.cloneNode(true);

		// let outerHTML = clonedSvgElement.outerHTML;
		// let blob = new Blob([outerHTML], { type: "image/svg+xml;charset=utf-8" });

		// let URL = window.URL || window.webkitURL || window;
		// let blobURL = URL.createObjectURL(blob);

		// let image = new window.Image();

		// image.onload = () => {
		//   let canvas = document.createElement("canvas");
		//   canvas.widht = width;
		//   canvas.height = height;
		//   let context = canvas.getContext("2d");

		//   context.drawImage(image, 0, 0, width, height);
		//   let png = canvas.toDataURL(); // default png
		//   donwnload(png, "my_qr_code.png");
		// };
		// image.src = blobURL;

		var link = document.createElement("a");
		link.download = "my_qr_code.png";
		link.href = document.getElementById("qrCodeEl").toDataURL();
		link.click();
	};

	function donwnload(href, name) {
		var link = document.createElement("a");
		link.download = name;
		link.href = href;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const location = useLocation();
	useEffect(() => {
		refetchMessages();
	}, [location.pathname]);

	const menuProfile = (
		<Menu>
			<Menu.Item
				key="/profile/details"
				className="ant-menu-item-profile-details"
			>
				<Space>
					<Image
						src={imageProfile}
						preview={false}
						className="imageObjectfit"
					/>

					<Typography.Text className="ant-typography-profile-details">
						<Typography.Text className="nameHeaderClass">
							{userdata.first_name} {userdata.last_name}
						</Typography.Text>
						<br />
						<Typography.Text>
							{memberCompany ? memberCompany.title : ""}
						</Typography.Text>
					</Typography.Text>
				</Space>
			</Menu.Item>
			<Menu.Item key="/member/my-account/profile" icon={<UserOutlined />}>
				<Link to="/member/my-account/profile">Account Profile</Link>
			</Menu.Item>
			<Menu.Item key="/member/ce-hours/profile" icon={<ClockCircleOutlined />}>
				<Link to="/member/ce-hours/profile">CE Hours Profile</Link>
			</Menu.Item>
			<Menu.Item key="/member/my-account/invoices" icon={<FaFileInvoice />}>
				<Link to="/member/my-account/invoices">Subscriptions & Invoices</Link>
			</Menu.Item>

			<Menu.Item key="/profile/signout" icon={<AiOutlinePoweroff />}>
				<Link to="#" onClick={handleLogout}>
					Sign Out
				</Link>
			</Menu.Item>
		</Menu>
	);

	const menuNotificationBell = (
		<NotificationsAlert
			notification={notification}
			refetch={refetchNotification}
		/>
	);

	const menuMessageAlert = (
		<MessagesAlert messages={messages} refetch={refetchMessages} />
	);

	const menuLeftMenus = (
		<Menu>
			<Menu.Item
				key="/member/ce-calendar/my-book-events"
				icon={<CalendarOutlined />}
				onClick={() => setSideMenuCollapse(true)}
			>
				<Link to="/member/ce-calendar/my-book-events">My Events</Link>
			</Menu.Item>
			<Menu.Item
				key="/member/ce-hours/my-certificates"
				icon={<FolderOpenOutlined />}
				onClick={() => setSideMenuCollapse(true)}
			>
				<Link to="/member/ce-hours/my-certificates">My Certificates</Link>
			</Menu.Item>
			<Menu.Item
				key="/event-provider/my-account/qr-code"
				icon={<QrcodeOutlined className="link-icon" icon={faBuilding} />}
				onClick={() => setShowModalQrCode(true)}
			>
				<Link to="#">My QR Code</Link>
			</Menu.Item>
			<Menu.Item
				key="/member/my-account/my-company"
				icon={<FontAwesomeIcon className="link-icon" icon={faBuilding} />}
				onClick={() => setSideMenuCollapse(true)}
			>
				<Link to="/member/my-account/my-company">My Company</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<Layout.Header
				className="headerNavtop"
				style={{ position: "fixed", zIndex: 3, width: "100%" }}
			>
				<Space className="ant-space-container">
					<Space className="ant-space-left" size={5}>
						{width <= 576 && (
							//   <div className="ant-space-icon">
							<MenuUnfoldOutlined
								onClick={() => setSideMenuCollapse(false)}
								className="menuCollapseOnClose"
							/>
							//   </div>
						)}
						{width <= 768 ? (
							<>
								<div className="ant-space-icon">
									<Dropdown
										overlay={menuLeftMenus}
										placement="bottomRight"
										overlayClassName="ant-menu-submenu-left-menus-popup posFixed"
										trigger={["click", "hover"]}
									>
										<MdOutlineDashboardCustomize className="ant-menu-submenu-left-menus" />
									</Dropdown>
								</div>
							</>
						) : null}
						{width > 768 ? (
							<>
								<div className="ant-space-icon">
									<Link to="/member/ce-calendar/my-book-events">
										<CalendarOutlined />
										<Typography.Text className="ant-typography-text">
											My Events
										</Typography.Text>
									</Link>
								</div>
								<div className="ant-space-icon">
									<Link to="/member/ce-hours/my-certificates">
										<FolderOpenOutlined />
										<Typography.Text className="ant-typography-text">
											My Certificates
										</Typography.Text>
									</Link>
								</div>
								<div className="ant-space-icon">
									<Link to="/member/my-account/my-company">
										<FontAwesomeIcon className="link-icon" icon={faBuilding} />
										<Typography.Text className="ant-typography-text">
											My Company
										</Typography.Text>
									</Link>
								</div>
								<div className="ant-space-icon">
									<Link to="#" onClick={() => setShowModalQrCode(true)}>
										<QrcodeOutlined />
										<Typography.Text className="ant-typography-text">
											My QR Code
										</Typography.Text>
									</Link>
								</div>
							</>
						) : null}
					</Space>
					<Space className="ant-space-right headerRight" size={0}>
						<Dropdown
							overlay={menuProfile}
							placement="bottomRight"
							overlayClassName="ant-menu-submenu-profile-popup"
						>
							<Image
								className="ant-menu-submenu-profile imageObjectfit"
								src={imageProfile}
								preview={false}
							/>
						</Dropdown>

						<Dropdown
							overlay={menuNotificationBell}
							placement="bottomRight"
							overlayClassName="ant-menu-submenu-notification-popup scrollbar-2"
							trigger={["click"]}
						>
							<Badge
								count={unreadNotif < 99 ? unreadNotif : "99+"}
								className="ant-menu-submenu-notification"
							>
								<BiBell />
							</Badge>
						</Dropdown>

						<Dropdown
							overlay={menuMessageAlert}
							placement="bottomRight"
							overlayClassName="ant-menu-submenu-message-alert-popup scrollbar-2"
							trigger={["click"]}
						>
							<Badge
								count={unreadMessages < 99 ? unreadMessages : "99+"}
								className="ant-menu-submenu-message-alert"
							>
								<MessageOutlined />
							</Badge>
						</Dropdown>
					</Space>
				</Space>
			</Layout.Header>

			<Modal
				title={"My QR Code"}
				visible={showModalQrCode}
				width={400}
				className="modalPreview modal-login"
				onCancel={() => {
					setShowModalQrCode(false);
				}}
				centered
				style={{ top: 20 }}
				closable={false}
				footer={[
					<Button
						onClick={() => {
							setShowModalQrCode(false);
						}}
					>
						Close
					</Button>,
					<Button onClick={downloadQRCode} className="btn-login-outline">
						Download
					</Button>,
				]}
			>
				<div
					ref={svgRef}
					style={{ textAlign: "center", zIndex: 100, padding: 15 }}
				>
					<QRCode
						id="qrCodeEl"
						value={
							userdata.first_name +
							" " +
							userdata.last_name +
							", " +
							"CELIYA ID:" +
							userdata.id
						}
						logoImage={imageLogo}
						size={300}
						logoWidth={60}
						logoHeight={37}
					/>
				</div>
			</Modal>
		</>
	);
}
