import React, { useEffect, useState } from "react";
import {
  Card,
  Modal,
  Typography,
  Form,
  Button,
  Row,
  Col,
  notification,
  Collapse,
  Space,
  Radio,
} from "antd";
import FloatDatePicker from "../../../../../providers/FloatDatePicker";
import { SaveOutlined, MinusOutlined, TagOutlined } from "@ant-design/icons";
import FloatInput from "../../../../../providers/FloatInput";
import FloatInputNumber from "../../../../../providers/FloatInputNumber";
import moment, { isMoment } from "moment";
import FloatSelect from "../../../../../providers/FloatSelect";
import { POST } from "../../../../../providers/useAxiosQuery";
export default function ModalUpdate({
  isModalUpdate,
  setIsModalUpdate,
  isModalUpdateData,
  setIsModalUpdateData,
}) {
  useEffect(() => {
    console.log(isModalUpdateData);
    if (isModalUpdateData) {
      var type = "";
      if (isModalUpdateData.type == "percent") {
        type = "a";
      }
      if (isModalUpdateData.type == "fixed") {
        type = "b";
      }
      if (isModalUpdateData.type == "offer") {
        type = "c";
      }

      form.setFieldsValue({
        ...isModalUpdateData,
        role: JSON.parse(isModalUpdateData.role),
        type: type,
      });

      setIsFixed(isModalUpdateData.type);
    }
  }, [isModalUpdateData]);
  const { Panel } = Collapse;

  const [form] = Form.useForm();

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };

  const { mutate: mutateCoupon, isLoading: isLoadingCoupon } = POST(
    "api/v1/edit_coupon_system",
    "get_coupons_system"
  );

  const onFinish = (values) => {
    let data = {
      ...values,
      id: isModalUpdateData.id,
      redemption: isModalUpdateData.redemption,
      type: isFixed,
      duration_from: isMoment(values.duration_from)
        ? values.duration_from.format("YYYY-MM-DD")
        : values.duration_from,
      duration_to: isMoment(values.duration_to)
        ? values.duration_to.format("YYYY-MM-DD")
        : values.duration_to,
      max_redemptions: values.max_redemptions
        ? Math.floor(values.max_redemptions)
        : null,
    };

    mutateCoupon(data, {
      onSuccess: (res) => {
        if (res.success) {
          // console.log(res)

          notification.success({
            message: "Success",
            description: "Coupon Successfully Added",
          });
          setIsModalUpdate(false);
          setIsFixed("percent");
          // form.resetFields();
        } else {
          notification.error({
            message: "Error",
            description: "Coupon Already Exists",
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Error",
          description: err.response.data.message,
        });
      },
    });
  };

  const [isRepeating, setIsRepeating] = useState(false);
  const [isFixed, setIsFixed] = useState("percent");

  useEffect(() => {
    console.log(isFixed);
  }, [isFixed]);
  return (
    <Modal
      title="Update Coupon"
      visible={isModalUpdate}
      //   onOk={showModal}
      className="modal-login"
      onCancel={() => {
        setIsModalUpdate(false);
      }}
      footer={
        <Space>
          <Button
            onClick={(e) => {
              setIsModalUpdate(false);
              // setIsModalUpdateData([]);
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-login-outline"
            onClick={(e) => form.submit()}
            loading={isLoadingCoupon}
          >
            Submit
          </Button>
        </Space>
      }
      style={{ top: 20 }}
    >
      <Form layout="horizontal" form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="coupon_code"
              rules={[validator.require]}
              hasFeedback
            >
              <FloatInput
                label="Coupon Code"
                placeholder=" Coupon Code"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="coupon_name"
              rules={[validator.require]}
              hasFeedback
            >
              <FloatInput
                label="Coupon Name"
                placeholder="Coupon Name"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Space direction="vertical">
              <Form.Item
                name="redeem_type"
                label="Redeem type"
                style={{ marginBottom: 10 }}
              >
                <Radio.Group defaultValue={"once"} disabled={true}>
                  <Radio value="once">once </Radio>
                  <Radio value="forever">forever </Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Space direction="vertical">
              <Form.Item name="type" label="Type Discount">
                <Radio.Group defaultValue={"a"} disabled={true}>
                  <Radio value="a">Percentage </Radio>
                  <Radio value="b">Fixed </Radio>
                  <Radio value="c">Offer Free Days</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
          </Col>
          {isFixed == "percent" && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="off" rules={[validator.require]}>
                <FloatInputNumber
                  label="Percentege Off"
                  placeholder="Percentege Off"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          )}

          {isFixed == "fixed" && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="off" rules={[validator.require]}>
                <FloatInputNumber
                  label="Amount off"
                  placeholder="Amount off"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          )}
          {isFixed == "offer" && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="off" rules={[validator.require]}>
                <FloatInputNumber
                  label="No. of Days"
                  placeholder="No. of Days"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} md={12}>
            <Form.Item
              name="duration_from"
              rules={[validator.require]}
              hasFeedback
            >
              <FloatDatePicker
                label={"Duration From"}
                // placeholder={"Date and Start Time"}
                mode
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            {" "}
            <Form.Item
              name="duration_to"
              rules={[validator.require]}
              hasFeedback
            >
              <FloatDatePicker label={"Duration To"} mode />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="role"
              rules={[validator.require]}
              hasFeedback
              className="form-select-error-multi"
            >
              <FloatSelect
                disabled={true}
                label="Applied on Account Type"
                placeholder="Applied on Account Type"
                multi="multiple"
                options={[
                  {
                    label: "MEMBER",
                    value: "MEMBER",
                  },
                  {
                    label: "EVENT PROVIDER",
                    value: "EVENT PROVIDER",
                  },
                  {
                    label: "VENUE PROVIDER",
                    value: "VENUE PROVIDER",
                  },
                  {
                    label: "SPEAKER",
                    value: "SPEAKER",
                  },
                  {
                    label: "EVENT PROVIDER/SPEAKER",
                    value: "EVENT PROVIDER/SPEAKER",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item name="max" hasFeedback rules={[validator.require]}>
              <FloatInputNumber
                label="Max Redemptions"
                placeholder="Max Redemptions"
                rules={[validator.require]}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item name="days_free" hasFeedback>
              <FloatInputNumber label="Days Free" placeholder="Days Free" />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
}
