import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Collapse,
  notification,
  Switch,
  Card,
  Form,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { SettingOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInputConfig from "../../../../providers/FloatInputConfig";
import FloatInputNumber from "../../../../providers/FloatInputNumber";
const PageConfiguration = ({ match, props, permission }) => {
  const sub_title = "View My";
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [data, setData] = useState({
    split_percent_ep: "",
    split_percent_eps: "",
    system_maintenance: 0,
    free_registration: 0,
    event_company: 0,
    event_profile: 0,
    event_invoices: 0,
    event_attendees_payment: 0,
    event_search_venue: 0,
    event_certificate_template: 0,
    event_tickets: 0,
    member_company: 0,
    member_profile: 0,
    member_invoices: 0,
    member_ce_profile: 0,
    member_tickets: 0,
    venue_company: 0,
    venue_profile: 0,
    venue_invoices: 0,
    venue_option: 0,
    venue_tickets: 0,
    speaker_company: 0,
    speaker_profile: 0,
    speaker_invoices: 0,
    speaker_tickets: 0,

    event_company_link: "",
    event_profile_link: "",
    event_invoices_link: "",
    event_attendees_payment_link: "",
    event_search_venue_link: "",
    event_certificate_template_link: "",
    event_tickets_link: "",
    member_company_link: "",
    member_profile_link: "",
    member_invoices_link: "",
    member_ce_profile_link: "",
    member_tickets_link: "",
    venue_company_link: "",
    venue_profile_link: "",
    venue_invoices_link: "",
    venue_option_link: "",
    venue_tickets_link: "",
    speaker_company_link: "",
    speaker_profile_link: "",
    speaker_invoices_link: "",
    speaker_tickets_link: "",

    event_search_speaker: 0,
    event_search_speaker_link: "",
  });

  const [change, setChange] = useState("");

  const { data: dataGetConfig, refetch: refetchGetConfig } = GET(
    "api/v1/config",
    "config",
    (res) => {
      if (res.success) {
        console.log("config", res);
        setData({
          free_registration: res.data.free_registration,
          event_company: res.data.event_company,
          event_profile: res.data.event_profile,
          event_invoices: res.data.event_invoices,
          event_attendees_payment: res.data.event_attendees_payment,
          event_search_venue: res.data.event_search_venue,
          event_certificate_template: res.data.event_certificate_template,
          event_tickets: res.data.event_tickets,
          member_company: res.data.member_company,
          member_profile: res.data.member_profile,
          member_invoices: res.data.member_invoices,
          member_ce_profile: res.data.member_ce_profile,
          member_tickets: res.data.member_tickets,
          venue_company: res.data.venue_company,
          venue_profile: res.data.venue_profile,
          venue_invoices: res.data.venue_invoices,
          venue_option: res.data.venue_option,
          venue_tickets: res.data.venue_tickets,
          speaker_company: res.data.speaker_company,
          speaker_profile: res.data.speaker_profile,
          speaker_invoices: res.data.speaker_invoices,
          speaker_tickets: res.data.speaker_tickets,

          event_company_link: res.data.event_company_link,
          event_profile_link: res.data.event_profile_link,
          event_invoices_link: res.data.event_invoices_link,
          event_attendees_payment_link: res.data.event_attendees_payment_link,
          event_search_venue_link: res.data.event_search_venue_link,
          event_certificate_template_link:
            res.data.event_certificate_template_link,
          event_tickets_link: res.data.event_tickets_link,
          member_company_link: res.data.member_company_link,
          member_profile_link: res.data.member_profile_link,
          member_invoices_link: res.data.member_invoices_link,
          member_ce_profile_link: res.data.member_ce_profile_link,
          member_tickets_link: res.data.member_tickets_link,
          venue_company_link: res.data.venue_company_link,
          venue_profile_link: res.data.venue_profile_link,
          venue_invoices_link: res.data.venue_invoices_link,
          venue_option_link: res.data.venue_option_link,
          venue_tickets_link: res.data.venue_tickets_link,
          speaker_company_link: res.data.speaker_company_link,
          speaker_profile_link: res.data.speaker_profile_link,
          speaker_invoices_link: res.data.speaker_invoices_link,
          speaker_tickets_link: res.data.speaker_tickets_link,

          event_search_speaker: res.data.event_search_speaker,
          event_search_speaker_link: res.data.event_search_speaker_link,
          system_maintenance: res.data.system_maintenance,
          split_percent_ep: res.data.split_percent_ep,
          split_percent_eps: res.data.split_percent_eps,
        });

        form.setFieldsValue({
          event_company_link: res.data.event_company_link,
          event_profile_link: res.data.event_profile_link,
          event_invoices_link: res.data.event_invoices_link,
          event_attendees_payment_link: res.data.event_attendees_payment_link,
          event_search_venue_link: res.data.event_search_venue_link,
          event_certificate_template_link:
            res.data.event_certificate_template_link,
          event_tickets_link: res.data.event_tickets_link,
          member_company_link: res.data.member_company_link,
          member_profile_link: res.data.member_profile_link,
          member_invoices_link: res.data.member_invoices_link,
          member_ce_profile_link: res.data.member_ce_profile_link,
          member_tickets_link: res.data.member_tickets_link,
          venue_company_link: res.data.venue_company_link,
          venue_profile_link: res.data.venue_profile_link,
          venue_invoices_link: res.data.venue_invoices_link,
          venue_option_link: res.data.venue_option_link,
          venue_tickets_link: res.data.venue_tickets_link,
          speaker_company_link: res.data.speaker_company_link,
          speaker_profile_link: res.data.speaker_profile_link,
          speaker_invoices_link: res.data.speaker_invoices_link,
          speaker_tickets_link: res.data.speaker_tickets_link,

          event_search_speaker: res.data.event_search_speaker,
          event_search_speaker_link: res.data.event_search_speaker_link,
          system_maintenance: res.data.system_maintenance,
          split_percent_ep: res.data.split_percent_ep,
          split_percent_eps: res.data.split_percent_eps,
        });
      }
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (change !== "") {
        console.log("data", data);
        mutateConfig(data, {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Successfully updated.",
              });
              refetchGetConfig();
              setChange("");
            }
          },
        });
      }
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [data]);

  // useEffect(() => {
  //   setChange("event_company_link");
  // }, [data]);

  const event_company_link = (val) => {
    setData({ ...data, event_company_link: val });
    setChange("event_company_link");
  };
  const event_profile_link = (val) => {
    setData({ ...data, event_profile_link: val });
    setChange("event_profile_link");
  };
  const event_invoices_link = (val) => {
    setData({ ...data, event_invoices_link: val });
    setChange("event_invoices_link");
  };
  const event_attendees_payment_link = (val) => {
    setData({ ...data, event_attendees_payment_link: val });
    setChange("event_attendees_payment_link");
  };
  const event_search_venue_link = (val) => {
    setData({ ...data, event_search_venue_link: val });
    setChange("event_search_venue_link");
  };
  const event_certificate_template_link = (val) => {
    setData({ ...data, event_certificate_template_link: val });
    setChange("event_certificate_template_link");
  };
  const event_tickets_link = (val) => {
    setData({ ...data, event_tickets_link: val });
    setChange("event_tickets_link");
  };
  const member_company_link = (val) => {
    setData({ ...data, member_company_link: val });
    setChange("member_company_link");
  };
  const member_profile_link = (val) => {
    setData({ ...data, member_profile_link: val });
    setChange("member_profile_link");
  };
  const member_invoices_link = (val) => {
    setData({ ...data, member_invoices_link: val });
    setChange("member_invoices_link");
  };
  const member_ce_profile_link = (val) => {
    setData({ ...data, member_ce_profile_link: val });
    setChange("member_ce_profile_link");
  };
  const member_tickets_link = (val) => {
    setData({ ...data, member_tickets_link: val });
    setChange("member_tickets_link");
  };
  const venue_company_link = (val) => {
    setData({ ...data, venue_company_link: val });
    setChange("venue_company_link");
  };
  const venue_profile_link = (val) => {
    setData({ ...data, venue_profile_link: val });
    setChange("venue_profile_link");
  };
  const venue_invoices_link = (val) => {
    setData({ ...data, venue_invoices_link: val });
    setChange("venue_invoices_link");
  };
  const venue_option_link = (val) => {
    setData({ ...data, venue_option_link: val });
    setChange("venue_option_link");
  };
  const venue_tickets_link = (val) => {
    setData({ ...data, venue_tickets_link: val });
    setChange("venue_tickets_link");
  };
  const speaker_company_link = (val) => {
    setData({ ...data, speaker_company_link: val });
    setChange("speaker_company_link");
  };
  const speaker_profile_link = (val) => {
    setData({ ...data, speaker_profile_link: val });
    setChange("speaker_profile_link");
  };
  const speaker_invoices_link = (val) => {
    setData({ ...data, speaker_invoices_link: val });
    setChange("speaker_invoices_link");
  };
  const speaker_tickets_link = (val) => {
    setData({ ...data, speaker_tickets_link: val });
    setChange("speaker_tickets_link");
  };

  const event_search_speaker_link = (val) => {
    setData({ ...data, event_search_speaker_link: val });
    setChange("event_search_speaker_link");
  };

  const member_split_percent_ep = (val) => {
    setData({ ...data, split_percent_ep: val });
    setChange("member_split_percent_ep");
  };
  const member_split_percent_eps = (val) => {
    setData({ ...data, split_percent_eps: val });
    setChange("member_split_percent_eps");
  };

  const { mutate: mutateConfig, isLoading: isLoadingConfig } = POST(
    "api/v1/config",
    "config"
  );

  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="PageConfiguration"
    >
      <ComponentHeader
        sub_title={sub_title}
        permission={permission}
        icon={<SettingOutlined />}
      />
      <Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
        <br />
        <br />
        <Form form={form}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Page FAQ Setting"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <div className="indiSubTitle">EVENT PROVIDER</div>
                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Company{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.event_company === 1 ? true : false}
                        onChange={(e) => {
                          setData({ ...data, event_company: e ? 1 : 0 });
                          setChange("event_company");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_company_link">
                        <FloatInputConfig
                          label="Company Video Link"
                          placeholder="Company Video Link"
                          onChange={event_company_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Profile{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.event_profile === 1 ? true : false}
                        onChange={(e) => {
                          setData({ ...data, event_profile: e ? 1 : 0 });
                          setChange("event_profile");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_profile_link">
                        <FloatInputConfig
                          label="Profile Video Link"
                          placeholder="Profile Video Link"
                          onChange={event_profile_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Invoices{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.event_invoices === 1 ? true : false}
                        onChange={(e) => {
                          setData({ ...data, event_invoices: e ? 1 : 0 });
                          setChange("event_invoices");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_invoices_link">
                        <FloatInputConfig
                          label="Invoices Video Link"
                          placeholder="Invoices Video Link"
                          onChange={event_invoices_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Attendees Payment{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={
                          data.event_attendees_payment === 1 ? true : false
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            event_attendees_payment: e ? 1 : 0,
                          });
                          setChange("event_attendees_payment");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_attendees_payment_link">
                        <FloatInputConfig
                          label="Attendees Payment Video Link"
                          placeholder="Attendees Payment Video Link"
                          onChange={event_attendees_payment_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Search Venue{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.event_search_venue === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            event_search_venue: e ? 1 : 0,
                          });
                          setChange("event_search_venue");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_search_venue_link">
                        <FloatInputConfig
                          label="Search Venue Video Link"
                          placeholder="Search Venue Video Link"
                          onChange={event_search_venue_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Search Speaker{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.event_search_speaker === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            event_search_speaker: e ? 1 : 0,
                          });
                          setChange("event_search_speaker");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_search_speaker_link">
                        <FloatInputConfig
                          label="Search Speaker Video Link"
                          placeholder="Search Speaker Video Link"
                          onChange={event_search_speaker_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Certificate Template{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={
                          data.event_certificate_template === 1 ? true : false
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            event_certificate_template: e ? 1 : 0,
                          });
                          setChange("event_certificate_template");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_certificate_template_link">
                        <FloatInputConfig
                          label="Certificate Template Video Link"
                          placeholder="Certificate Template Video Link"
                          onChange={event_certificate_template_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Tickets{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.event_tickets === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            event_tickets: e ? 1 : 0,
                          });
                          setChange("event_tickets");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="event_tickets_link">
                        <FloatInputConfig
                          label="Tickets Video Link"
                          placeholder="Tickets Video Link"
                          onChange={event_tickets_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="indiSubTitle">MEMBER</div>
                  {/* <Row gutter={24} style={{ marginBottom: "10px" }}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    My Company{" "}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Switch 
                      loading={isLoadingConfig ? true : false}
                      checked={data.member_company === 1 ? true : false}
                      onChange={(e) => {
                        setData({
                          ...data,
                          member_company: e ? 1 : 0,
                        });
                        setChange("member_company");
                      }}
                    />
                  </Col>
                </Row> */}

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Profile{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.member_profile === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            member_profile: e ? 1 : 0,
                          });
                          setChange("member_profile");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="member_profile_link">
                        <FloatInputConfig
                          label="Profile Link"
                          placeholder="Profile Link"
                          onChange={member_profile_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Invoices{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.member_invoices === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            member_invoices: e ? 1 : 0,
                          });
                          setChange("member_invoices");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="member_invoices_link">
                        <FloatInputConfig
                          label="Invoices Link"
                          placeholder="Invoices Link"
                          onChange={member_invoices_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      CE Profile{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.member_ce_profile === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            member_ce_profile: e ? 1 : 0,
                          });
                          setChange("member_ce_profile");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="member_ce_profile_link">
                        <FloatInputConfig
                          label="CE Profile Link"
                          placeholder="CE Profile Link"
                          onChange={member_ce_profile_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Tickets{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.member_tickets === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            member_tickets: e ? 1 : 0,
                          });
                          setChange("member_tickets");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="member_tickets_link">
                        <FloatInputConfig
                          label="Tickets Link"
                          placeholder="Tickets Link"
                          onChange={member_tickets_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="indiSubTitle">VENUE</div>
                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      My Company{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.venue_company === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            venue_company: e ? 1 : 0,
                          });
                          setChange("venue_company");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="venue_company_link">
                        <FloatInputConfig
                          label="My Company Link"
                          placeholder="My Company Link"
                          onChange={venue_company_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Profile{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.venue_profile === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            venue_profile: e ? 1 : 0,
                          });
                          setChange("venue_profile");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="venue_profile_link">
                        <FloatInputConfig
                          label="Profile Link"
                          placeholder="Profile Link"
                          onChange={venue_profile_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Invoices{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.venue_invoices === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            venue_invoices: e ? 1 : 0,
                          });
                          setChange("venue_invoices");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="venue_invoices_link">
                        <FloatInputConfig
                          label="Invoices Link"
                          placeholder="Invoices Link"
                          onChange={venue_invoices_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Venue Option{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.venue_option === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            venue_option: e ? 1 : 0,
                          });
                          setChange("venue_option");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="venue_option_link">
                        <FloatInputConfig
                          label="Venue Option Link"
                          placeholder="Venue Option Link"
                          onChange={venue_option_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Tickets{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.venue_tickets === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            venue_tickets: e ? 1 : 0,
                          });
                          setChange("venue_tickets");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="venue_tickets_link">
                        <FloatInputConfig
                          label="Tickets Link"
                          placeholder="Tickets Link"
                          onChange={venue_tickets_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="indiSubTitle">SPEAKER</div>
                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      My Company{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.speaker_company === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            speaker_company: e ? 1 : 0,
                          });
                          setChange("speaker_company");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="speaker_company_link">
                        <FloatInputConfig
                          label="My Company Link"
                          placeholder="My Company Link"
                          onChange={speaker_company_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Profile{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.speaker_profile === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            speaker_profile: e ? 1 : 0,
                          });
                          setChange("speaker_profile");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="speaker_profile_link">
                        <FloatInputConfig
                          label="Profile Link"
                          placeholder="Profile Link"
                          onChange={speaker_profile_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Invoices{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.speaker_invoices === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            speaker_invoices: e ? 1 : 0,
                          });
                          setChange("speaker_invoices");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="speaker_invoices_link">
                        <FloatInputConfig
                          label="Invoices Link"
                          placeholder="Invoices Link"
                          onChange={speaker_invoices_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginBottom: "10px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      Tickets{" "}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Switch
                        loading={isLoadingConfig ? true : false}
                        checked={data.speaker_tickets === 1 ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            speaker_tickets: e ? 1 : 0,
                          });
                          setChange("speaker_tickets");
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="speaker_tickets_link">
                        <FloatInputConfig
                          label="Tickets Link"
                          placeholder="Tickets Link"
                          onChange={speaker_tickets_link}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Split Payment Settings (%)"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="split_percent_ep">
                        <FloatInputNumber
                          label="Event Provider (%)"
                          placeholder="Event Provider (%)"
                          onChange={member_split_percent_ep}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item name="split_percent_eps">
                        <FloatInputNumber
                          label="Event Provider/Speaker (%)"
                          placeholder="Event Provider/Speaker (%)"
                          onChange={member_split_percent_eps}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br></br>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Free Registration Setting"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Switch
                    loading={isLoadingConfig ? true : false}
                    checked={data.free_registration === 1 ? true : false}
                    onChange={(e) => {
                      setData({ ...data, free_registration: e ? 1 : 0 });
                      setChange("free_registration");
                    }}
                  />
                </Panel>
              </Collapse>
              <br></br>
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Website Maintenance"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Switch
                    loading={isLoadingConfig ? true : false}
                    checked={data.system_maintenance === 1 ? true : false}
                    onChange={(e) => {
                      setData({ ...data, system_maintenance: e ? 1 : 0 });
                      setChange("system_maintenance");
                    }}
                  />
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export default PageConfiguration;
