import React, { useState, useEffect } from "react";
import { Layout, Form, Button, Row, Col, Collapse, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentHeader from "../Components/ComponentHeader";
import ComponentFaqs from "../Components/ComponentFaqs";
import { SaveOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";

import FloatInput from "../../../../providers/FloatInput";
import FloatInputCompany from "../../../../providers/FloatInputCompany";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatInputMaskCompany from "../../../../providers/FloatInputMaskCompany";
import countryList from "react-select-country-list";
import states from "states-us";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import getUserData from "../../../../providers/getUserData";
import FloatTextArea from "../../../../providers/FloatTextArea";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";

export default function PageMyCompany({ props, permission }) {
	const [country, setCountry] = useState(optionCountryCodes);
	const stateUS = optionStateCodesUnitedState();
	const stateCA = optionStateCodesCanada();
	const stateMX = optionStateCodesMexico();

	const [stateLabel, setStateLabel] = useState("State");
	const [zipLabel, setZipLabel] = useState("Zip Code");
	const [optionState, setOptionState] = useState(stateUS);
	const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

	const [dataFetch, setDataFetch] = useState([]);
	const handleCountry = (val, opt) => {
		if (val === "United States") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		} else if (val === "Mexico") {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateMX);
			setOptionZip(/(^\d{5}$)/);
		} else if (val === "Canada") {
			setStateLabel("County");
			setZipLabel("Postal Code");
			setOptionState(stateCA);
			setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
		} else {
			setStateLabel("State");
			setZipLabel("Zip Code");
			setOptionState(stateUS);
			setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		}
		setFormData(val);
		form.resetFields(["state", "zip"]);
		if (dataFetch.length > 0) {
			if (val === dataFetch[0].country) {
				form.setFieldsValue({
					state: dataFetch[0].state,
					zip: dataFetch[0].zip,
				});
			}
		}
	};

	const { Panel } = Collapse;
	const sub_title = "My";
	let userdata = getUserData();
	const [state, setState] = useState([]);

	useEffect(() => {
		let arr = [];
		states.map((row, key) => {
			if (
				row.name != "Federated States Of Micronesia" &&
				row.name != "Marshall Islands" &&
				row.name != "Palau" &&
				row.name != "Northern Mariana Islands"
			) {
				arr.push({
					value: row.abbreviation,
					label: row.name,
				});
			}
		});
		setState(arr);
	}, []);

	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};
	const [form] = Form.useForm();

	const onFinishMyAccount = (values) => {
		let data = {
			...values,
			user_id: userdata.id,
		};
		console.log("onFinishMyAccount", data);
		mutateCreateAccount(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Success",
						description: "Successfully Saved!",
					});
				}
			},
			onError: (res) => {},
		});
	};

	const { data: dataGetAccount, isLoading: isLoadingGetAccount } = GET(
		`api/v1/member_company_account?user_id=${userdata.id}`,
		"member_company_account",
		(res) => {
			if (res.success) {
				if (res.data.length > 0) {
					setDataFetch(res.data);
					if (res.data[0].country === "United States") {
						setStateLabel("State");
						setZipLabel("Zip Code");
						setOptionState(stateUS);
						setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
					} else if (res.data[0].country === "Mexico") {
						setStateLabel("State");
						setZipLabel("Zip Code");
						setOptionState(stateMX);
						setOptionZip(/(^\d{5}$)/);
					} else if (res.data[0].country === "Canada") {
						setStateLabel("County");
						setZipLabel("Postal Code");
						setOptionState(stateCA);
						setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
					} else {
						setStateLabel("State");
						setZipLabel("Zip Code");
						setOptionState(stateUS);
						setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
					}

					form.setFieldsValue({
						additional_legal_credentials:
							res.data[0].additional_legal_credentials,
						title: res.data[0].title,
						business_email: res.data[0].business_email,
						business_fax: res.data[0].business_fax,
						business_name: res.data[0].business_name,
						business_phone: res.data[0].business_phone,
						business_phone2: res.data[0].business_phone2,
						country: res.data[0].country,
						address_1: res.data[0].address_1,
						address_2: res.data[0].address_2,
						city: res.data[0].city,
						company_agd: res.data[0].company_agd,
						service: res.data[0].service,
						state: res.data[0].state,
						zip: res.data[0].zip,
						website: res.data[0].website,
						business_categories: res.data[0].business_categories
							? JSON.parse(res.data[0].business_categories)
							: [],
						// map_link: res.data[0].map_link,
					});
					if (res.data[0].title == "" || res.data[0].title == null) {
						form.resetFields(["title"]);
					}
					if (res.data[0].website == "" || res.data[0].website == null) {
						form.resetFields(["website"]);
					}

					if (res.data[0].service == "" || res.data[0].service == null) {
						form.resetFields(["service"]);
					}

					if (res.data[0].address_2 == "" || res.data[0].address_2 == null) {
						form.resetFields(["address_2"]);
					}

					if (
						res.data[0].additional_legal_credentials == "" ||
						res.data[0].additional_legal_credentials == null
					) {
						form.resetFields(["additional_legal_credentials"]);
					}
					if (
						res.data[0].company_agd == "" ||
						res.data[0].company_agd == null
					) {
						form.resetFields(["company_agd"]);
					}
					if (
						res.data[0].business_fax == "" ||
						res.data[0].business_fax == null
					) {
						form.resetFields(["business_fax"]);
					}
					if (res.data[0].map_link == "" || res.data[0].map_link == null) {
						form.resetFields(["map_link"]);
					}
				}
			}
		}
	);

	const { mutate: mutateCreateAccount, isLoading: isLoadingCreateAccount } =
		POST("api/v1/member_company", "member_company");

	const [formData, setFormData] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (formData) {
				form.submit();
				setFormData("");
			}
		}, 3000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [formData]);

	const hanldeInput = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	const onChangeOption = (val, option) => {
		setFormData(val);
	};

	const hanldeTextArea = (val) => {
		if (val === "") {
			setFormData("empty");
		} else {
			setFormData(val);
		}
	};

	let business_category = [
		{
			label: "Dental Lab",
			value: "Dental Lab",
		},
		{
			label: "Dental Product",
			value: "Dental Product",
		},
		{
			label: "Dental Specialist",
			value: "Dental Specialist",
		},
		{
			label: "Dental Supply Company",
			value: "Dental Supply Company",
		},
		{
			label: "Dentist",
			value: "Dentist",
		},
		{
			label: "Educator",
			value: "Educator",
		},
		{
			label: "Medical Doctor",
			value: "Medical Doctor",
		},
		{
			label: "Motivational Speaker",
			value: "Motivational Speaker",
		},
		{
			label: "Other",
			value: "Other",
		},
		{
			label: "Professional Speaker",
			value: "Professional Speaker",
		},
		{
			label: "Professor",
			value: "Professor",
		},
		{
			label: "Study Club",
			value: "Study Club",
		},
	];

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageMyCompany"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<FontAwesomeIcon icon={faBuilding} />}
			/>
			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<ComponentFaqs
					linkVid={
						"https://player.vimeo.com/video/644214039?autoplay=0&muted=1"
					}
					col="event_company"
					colLink="event_company_link"
				/>
				<Form
					wrapperCol={{ span: 24 }}
					layout="horizontal"
					form={form}
					onFinish={onFinishMyAccount}
					// onFieldsChange={(e) => console.log("onFieldsChange", e)}
				>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={16} lg={16}>
							<Collapse
								expandIcon={({ isActive }) =>
									isActive ? (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(270deg)" }}
										></span>
									) : (
										<span
											className="ant-menu-submenu-arrow"
											style={{ color: "#FFF", transform: "rotate(90deg)" }}
										></span>
									)
								}
								expandIconPosition="right"
								defaultActiveKey={["1"]}
							>
								<Panel
									header="My Company Information"
									key="1"
									className="accordion bg-darkgray-form"
								>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="business_name"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputCompany
													onChange={hanldeInput}
													label="Business/Practice Name"
													placeholder="Business/Practice Name"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Form.Item
										name="title"
										rules={[validator.require]}
										hasFeedback
									>
										<FloatInputCompany
											label="Title"
											placeholder="Title"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item
										name="country"
										rules={[
											{
												required: true,
												message: "This field field is required.",
											},
										]}
										className="form-select-error"
										hasFeedback
									>
										<FloatSelect
											label="Country"
											placeholder="Country"
											options={country}
											onChange={handleCountry}
										/>
									</Form.Item>
									<Form.Item
										name="address_1"
										rules={[validator.require]}
										hasFeedback
									>
										<FloatInputCompany
											label="Address 1"
											placeholder="Address 1"
											onChange={hanldeInput}
										/>
									</Form.Item>
									<Form.Item
										name="address_2"
										// rules={[validator.require]}
										hasFeedback
									>
										<FloatInputCompany
											onChange={hanldeInput}
											label="Address 2"
											placeholder="Address 2"
										/>
									</Form.Item>
									<Row>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="city"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputCompany
													onChange={hanldeInput}
													label="City"
													placeholder="City"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="state"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error"
											>
												<FloatSelect
													onChange={onChangeOption}
													label={stateLabel}
													placeholder={stateLabel}
													options={optionState}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="zip"
												rules={[
													validator.require,
													{
														pattern: optionZip,
														message: "Invalid " + zipLabel,
													},
												]}
												hasFeedback
											>
												<FloatInputCompany
													onChange={hanldeInput}
													label={zipLabel}
													placeholder={zipLabel}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="business_phone"
												rules={[validator.require]}
												hasFeedback
											>
												<FloatInputMask
													onChange={hanldeInput}
													label="Business Phone 1"
													placeholder="Business Phone 1"
													maskLabel="business_phone"
													maskType="999-999-9999"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="business_phone2"
												// rules={[validator.require]}
												hasFeedback
											>
												<FloatInputMaskCompany
													onChange={hanldeInput}
													label="Business Phone 2"
													placeholder="Business Phone 2"
													maskLabel="business_phone2"
													maskType="999-999-9999"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item name="business_fax">
												<FloatInputMaskCompany
													onChange={hanldeInput}
													label="Business Fax"
													placeholder="Business Fax"
													maskLabel="business_fax"
													maskType="999-999-9999"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="business_email"
												rules={[
													validator.require,
													{
														type: "email",
														message: "please enter a valid email",
													},
												]}
												hasFeedback
											>
												<FloatInputCompany
													onChange={hanldeInput}
													label="Business Email"
													placeholder="Business Email"
												/>
											</Form.Item>{" "}
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="business_categories"
												rules={[validator.require]}
												hasFeedback
												className="form-select-error-multi"
											>
												<FloatSelect
													label="Business Category"
													placeholder="Business Category"
													options={business_category}
													multi="multiple"
													onChange={onChangeOption}
												/>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="website"
												// rules={[validator.require]}
												hasFeedback
											>
												<FloatInputCompany
													onChange={hanldeInput}
													label="Website"
													placeholder="Website"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item name="company_agd" hasFeedback>
												<FloatInputCompany
													onChange={hanldeInput}
													label="Company AGD#"
													placeholder="Company AGD#"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="service"
												// rules={[validator.require]}
												hasFeedback
											>
												<FloatTextArea
													onChange={hanldeTextArea}
													label="Services"
													placeholder="Services"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<Form.Item
												name="additional_legal_credentials"
												hasFeedback
											>
												<FloatTextArea
													onChange={hanldeTextArea}
													label="Credentials"
													placeholder="Credentials"
												/>
											</Form.Item>{" "}
										</Col>
										{/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <label>
                        Please include full Google Map's "embed code", you can
                        get this from Google's share feature
                      </label>
                      <Form.Item
                        name="map_link"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatTextArea
                          onChange={hanldeTextArea}
                          label="Map Embed Code"
                          placeholder="Map Embed Code"
                        />
                      </Form.Item>{" "}
                    </Col> */}
									</Row>
								</Panel>
							</Collapse>
						</Col>
					</Row>
					<br></br>

					{/* <Button
            size="large"
            className="btn-login-outline"
            style={{ width: 200 }}
            icon={<SaveOutlined />}
            loading={isLoadingCreateAccount}
            htmlType="submit"
          >
            Save
          </Button> */}
				</Form>
			</Layout.Content>
		</Layout>
	);
}
