import React, { useState, useEffect } from "react";
import {
	Layout,
	Row,
	Col,
	Table,
	Pagination,
	Space,
	Button,
	Tooltip,
	Collapse,
	Form,
} from "antd";
import ComponentHeader from "../Components/ComponentHeader";
// import ComponentFaqs from "../Components/ComponentFaqs";
import { EditOutlined, TeamOutlined, MailOutlined } from "@ant-design/icons";
import $ from "jquery";
import FloatInputSearch from "../../../../providers/FloatInputSearch";
import { GET } from "../../../../providers/useAxiosQuery";
import { useHistory, Link } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import moment from "moment";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import toCurrency from "../../../../providers/toCurrency";
export default function PageRevenue({ props, permission }) {
	const sub_title = "Total";
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(50);
	//page revenue
	const validator = {
		require: {
			required: true,
			message: "Required",
		},
	};

	const [formFilter] = Form.useForm();
	const [revenueFilter, setRevenueFilter] = useState({
		date_from: moment(1, "DD").format("YYYY-MM-DD"),
		date_to: moment().format("YYYY-MM-DD"),
		name: "",
		type: "ALL",
	});
	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "date",
		sort_order: "desc",
		date_from: moment(1, "DD").format("YYYY-MM-DD"),
		date_to: moment().format("YYYY-MM-DD"),
		name: "",
		type: "ALL",
	});

	const [toggleModalInvoice, setToggleModalInvoice] = useState({
		show: false,
		data: null,
	});

	const { data: dataRevenue, refetch: refetchRevenue } = GET(
		`api/v1/revenue_by_filter?${new URLSearchParams(revenueFilter)}`,
		"revenue_by_filter",
		(res) => {
			console.log("dataRevenue res", res.data);
		}
	);
	const { data: dataRevenueTable, refetch: refetchRevenueTable } = GET(
		`api/v1/revenue_table_by_filter?${new URLSearchParams(tableFilter)}`,
		"revenue_table_by_filter"
	);

	const handleOnFinishSearch = (values) => {
		// console.log(values);
		setRevenueFilter({
			...revenueFilter,
			date_from: moment(values.date_from).format("YYYY-MM-DD"),
			date_to: moment(values.date_to).format("YYYY-MM-DD"),
			name: values.name !== undefined ? values.name : "",
			type: values.type,
		});
		setTableFilter({
			...tableFilter,
			page: 1,
			date_from: moment(values.date_from).format("YYYY-MM-DD"),
			date_to: moment(values.date_to).format("YYYY-MM-DD"),
			name: values.name !== undefined ? values.name : "",
			type: values.type,
		});
	};

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter({
			...tableFilter,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
		});

		setCurrentPage(1);
	};
	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setTableFilter({
				...tableFilter,
				search: searchText,
				page_number: 1,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchText]);
	const handleFormClear = () => {
		formFilter.resetFields();
		setRevenueFilter({
			...revenueFilter,
			date_from: moment(1, "DD").format("YYYY-MM-DD"),
			date_to: moment().format("YYYY-MM-DD"),
			name: "",
			type: "ALL",
			loading: true,
		});
		setTableFilter({
			...tableFilter,
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "date",
			sort_order: "desc",
			date_from: moment(1, "DD").format("YYYY-MM-DD"),
			date_to: moment().format("YYYY-MM-DD"),
			name: "",
			type: "ALL",
			loading: true,
		});
	};

	useEffect(() => {
		refetchRevenue();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [revenueFilter]);
	useEffect(() => {
		if (dataRevenueTable) {
			refetchRevenueTable();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChange = (current, pageSize) => {
		setTableFilter({
			...tableFilter,
			page: current,
			page_size: pageSize,
		});
		setCurrentSize(pageSize);
		setCurrentPage(current);
	};

	return (
		<Layout
			className="site-layout-background"
			style={{
				padding: "0px 0px 20px 0px",
				background: "#fff",
			}}
			id="PageRevenue"
		>
			<ComponentHeader
				sub_title={sub_title}
				permission={permission}
				icon={<BiDollar />}
			/>

			<Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
				<Row style={{ marginTop: 20 }}>
					<Col xs={24} sm={24} md={24} lg={12}>
						<Collapse
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							expandIconPosition="right"
							defaultActiveKey={["1"]}
						>
							<Collapse.Panel
								header="Filters"
								key="1"
								className="accordion bg-darkgray-form"
							>
								<Form
									form={formFilter}
									onFinish={handleOnFinishSearch}
									initialValues={{
										date_from: moment(1, "DD").format("YYYY-MM-DD"),
										date_to: moment().format("YYYY-MM-DD"),
										type: "ALL",
									}}
								>
									<Row gutter={8}>
										<Col xs={24} sm={24} md={12}>
											<Form.Item name="date_from" rules={[validator.require]}>
												<FloatDatePickerForm
													label="From Date"
													placeholder="From Date"
													format="MM/DD/YYYY"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12}>
											<Form.Item name="date_to" rules={[validator.require]}>
												<FloatDatePickerForm
													label="To Date"
													placeholder="To Date"
													format="MM/DD/YYYY"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12}>
											<Form.Item name="name">
												<FloatInput label="Name" placeholder="Name" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12}>
											<Form.Item name="type" className="form-select-error">
												<FloatSelect
													label="Select Type"
													placeholder="Select Type"
													options={[
														{
															value: "ALL",
															label: "ALL",
														},
														{
															value: "MEMBER",
															label: "MEMBER",
														},
														{
															value: "EVENT PROVIDER",
															label: "EVENT PROVIDER",
														},
														{
															value: "EVENT PROVIDER/SPEAKER",
															label: "EVENT PROVIDER/SPEAKER",
														},
														{
															value: "SPEAKER",
															label: "SPEAKER",
														},
														{
															value: "VENUE PROVIDER",
															label: "VENUE PROVIDER",
														},
													]}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12}>
											<Button
												size="large"
												className="btn-login-outline b-r-none m-r-sm"
												htmlType="submit"
											>
												SEARCH
											</Button>
											<Button
												size="large"
												className="btn-warning-outline b-r-none"
												onClick={handleFormClear}
											>
												CLEAR
											</Button>
										</Col>
									</Row>
								</Form>
							</Collapse.Panel>
						</Collapse>
					</Col>
				</Row>
				<Row>
					<Col xs={24} sm={24} md={24} lg={24} className="m-t-md">
						<Collapse
							className="main-1-collapse border-none collapse-result-total"
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							defaultActiveKey={["1"]}
							expandIconPosition="start"
						>
							<Collapse.Panel
								header="RESULTS TOTAL"
								key="1"
								className="accordion bg-darkgray-form m-b-md border-none bgcolor-1 white"
							>
								<div className="table-responsive">
									<Table
										className="thead-light ant-table-striped"
										dataSource={dataRevenue?.data}
										rowKey={(record) => record.from_date}
										pagination={false}
										bordered={false}
									>
										<Table.Column
											title="From Date"
											key="from_date"
											dataIndex="from_date"
											render={(text, _) =>
												text ? moment(text).format("MM/DD/YYYY") : "MM/DD/YYYY"
											}
										/>
										<Table.Column
											title="To Date"
											key="to_date"
											dataIndex="to_date"
											render={(text, _) =>
												text ? moment(text).format("MM/DD/YYYY") : "MM/DD/YYYY"
											}
										/>

										<Table.Column
											title="Total Revenue"
											key="total_revenue"
											dataIndex="total_revenue"
											// sorter={true}
											render={(text, _) =>
												text ? "$" + toCurrency(text) : "$0.00"
											}
										/>
										<Table.Column
											title="Member"
											key="member"
											dataIndex="member"
										/>
										<Table.Column
											title="Event Provider"
											key="ep"
											dataIndex="ep"
										/>

										<Table.Column
											title="Venues"
											key="venue"
											dataIndex="venue"
										/>
										<Table.Column
											title="Speaker"
											key="speaker"
											dataIndex="speaker"
										/>
										<Table.Column
											title="Event Provider/Speaker"
											key="eps"
											dataIndex="eps"
										/>
										{/* <Table.Column
                    title="Print"
                    key="print"
                    align="center"
                    render={(_, record) => {
                      return (
                        <Button
                          type="link"
                          className="color-1 cursor-disabled"
                          disabled
                        >
                          <FontAwesomeIcon icon={faPrint} />
                        </Button>
                      );
                    }}
                  /> */}
									</Table>
								</div>
							</Collapse.Panel>
						</Collapse>
					</Col>
				</Row>
				<br />

				<Row gutter={24} className="search-table" align="bottom">
					<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
						<Pagination
							className="paginate"
							showSizeChanger
							onChange={onChange}
							current={currentPage}
							pageSize={currentSize}
							total={dataRevenueTable && dataRevenueTable.data.total}
							size="small"
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
						<FloatInputSearch
							label="Search..."
							placeholder="Search..."
							dataTableInfo={tableFilter}
							setDataTableInfo={setTableFilter}
							value={searchText}
							onChange={setSearchText}
						/>
					</Col>
				</Row>

				<Row>
					<Col xs={24} sm={24} md={24} lg={24} className="m-t-md">
						<div className="table-responsive">
							<Table
								className="table-responsive table-thead-login"
								dataSource={dataRevenueTable?.data?.data}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								// rowSelection={{
								//   type: selectionType,
								//   ...rowSelection,
								// }}

								onChange={onChangeTable}
							>
								<Table.Column
									title="Date"
									key="date"
									dataIndex="date"
									render={(text, _) => moment(text).format("MM/DD/YYYY")}
									sorter={true}
								/>
								<Table.Column
									title="First Name"
									key="first_name"
									dataIndex="first_name"
									sorter={true}
									// render={(text, record) => {
									//   return (
									//     <Button type="link" className="color-1 cursor-auto">
									//       {text}
									//     </Button>
									//   );
									// }}
								/>
								<Table.Column
									title="Last Name"
									key="last_name"
									dataIndex="last_name"
									sorter={true}
									// render={(text, record) => {
									//   return (
									//     <Button type="link" className="color-1 cursor-auto">
									//       {text}
									//     </Button>
									//   );
									// }}
								/>
								<Table.Column
									title="Revenue"
									key="total_amount"
									dataIndex="total_amount"
									sorter={true}
									render={(text, _) =>
										text ? "$" + toCurrency(text) : "$0.00"
									}
								/>
								<Table.Column
									title="Type"
									key="role"
									dataIndex="role"
									sorter={true}
								/>
								<Table.Column
									title="State"
									key="state"
									dataIndex="state"
									sorter={true}
								/>
								{/* <Table.Column
              title="Invoice"
              key="invoice"
              dataIndex="invoice"
              render={(text, record) => {
                return (
                  <Button
                    type="link"
                    className="color-1"
                    onClick={() =>
                      setToggleModalInvoice({
                        show: true,
                        data: record,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                );
              }}
            /> */}
							</Table>
						</div>
					</Col>
				</Row>
			</Layout.Content>
		</Layout>
	);
}
