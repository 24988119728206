import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  Space,
  notification,
} from "antd";
import image1 from "../../../../../assets/img/image1.jpg";
import image2 from "../../../../../assets/img/social_media.png";

import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import { GET, GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";

import companyInfo from "../../../../../providers/companyInfo";
import moment from "moment";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MessageOutlined,
} from "@ant-design/icons";

import { AiFillTwitterCircle } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { ImMail4 } from "react-icons/im";
import {
  FaFileInvoice,
  FaMobileAlt,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaTimes,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaVimeo,
  FaBuilding,
} from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import getUserData from "../../../../../providers/getUserData";
import { useHistory } from "react-router-dom";
export default function ModalPreview({ previewShow, setPreviewShow, match }) {
  const onClose = () => {
    setPreviewShow(false);
  };
  let userdata = getUserData();
  let history = useHistory();

  const [firstName, setFirstName] = useState("");

  const [form] = Form.useForm();

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };

  const [eventData, setEventData] = useState([]);

  const {
    data: datGetById,
    isLoading: isLoadingGetById,
    refetch: refetchGetById,
  } = GETMANUAL(`api/v1/event/${match}`, "event", (res) => {
    if (res.success) {
      console.log("res", res);
      if (res.data.length > 0) {
        let count_attendee = 0;
        res.data[0].event_attendee.map((item, key) => {
          if (
            // item.status === "Registered/Not Attended" ||
            item.status === "Attended" ||
            item.status === "Registered"
          ) {
            count_attendee = count_attendee + 1;
          }
        });
        setEventData({
          id: res.data[0].id,
          user_id: res.data[0].user_id,
          public_status: res.data[0].public_status,
          event_type: res.data[0].event_type,
          cost: res.data[0].cost,
          event_time: res.data[0].event_schedules,
          rscp_datetime: res.data[0].rscp_datetime,

          venue_name: res.data[0].event_venue.venue_name,
          country: res.data[0].event_venue.country,
          address_1: res.data[0].event_venue.address_1,
          address_2: res.data[0].event_venue.address_2,
          city: res.data[0].event_venue.city,
          state: res.data[0].event_venue.state,
          zip: res.data[0].event_venue.zip,
          map_link: res.data[0].event_venue.map_link,
          direction_details: res.data[0].event_venue.direction_details,
          event_attendee_count: count_attendee,

          allow_waiting_list: res.data[0].event_details.allow_waiting_list,
          title: res.data[0].event_details.title,
          sub_title: res.data[0].event_details.sub_title,
          director: res.data[0].event_details.director,
          facilitator: res.data[0].event_details.facilitator,
          summary: res.data[0].event_details.summary,
          description: res.data[0].event_details.descrition,
          restriction: res.data[0].event_details.restriction,
          what_to_bring: res.data[0].event_details.what_to_bring,
          ce_credit_hours: res.data[0].event_details.ce_credit_hours,
          seat_available: res.data[0].event_details.seat_available,
          completion_code: res.data[0].event_details.completion_code,
          subject_code: res.data[0].event_details.subject_code,
          course_topic: res.data[0].event_details.course_topic,
          //   attending_participants: JSON.parse(
          //     res.data[0].event_details.attending_participants
          //   ),
          your_phone: res.data[0].event_details.your_phone,
          your_email: res.data[0].event_details.your_email,
          verification: JSON.parse(res.data[0].event_details.verification),
          published_status: res.data[0].event_save_status,
          attending_participants:
            res.data[0].event_detail_attending_participants,

          speaker_name: res.data[0].event_speaker.speaker_name,
          speaker_credentials: res.data[0].event_speaker.speaker_credentials,
          event_img:
            res.data[0].event_details &&
            companyInfo().apiUrl +
              "storage/" +
              res.data[0].event_details.upload,
          company_of_venue: res.data[0].event_venue.venue
            ? res.data[0].event_venue.venue.member_company.business_name
            : "",
        });
      }

      // setVenueTBD(res.data[0].venue_tbd);
      // setAwaiting(res.data[0].event_details.allow_waiting_list);
    }
  });

  useEffect(() => {
    // console.log("indi_event", eventData);
    refetchGetById();
  }, [match]);

  const { mutate: mutateStartNewConvo, isLoading: isLoadingStartNewConvo } =
    POST("api/v1/message", `message_${userdata.id}`);
  const handleMakeNewConvo = (user_id) => {
    let data = {
      to_id: user_id,
      from_id: userdata.id,
    };
    console.log(data);
    mutateStartNewConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        if (res.success) {
          handleNewMessageConvo(res.data.id, user_id);
        } else {
          notification.error({ message: res.data });
        }
      },
    });
  };

  const { mutate: mutateNewMessageConvo, isLoading: isLoadingNewMessageConvo } =
    POST("api/v1/message_convo");

  const handleNewMessageConvo = (message_id, user_id) => {
    let messageText = `Hello! I want to inquire about ${eventData.title} Event`;
    let data = {
      from_id: userdata.id,
      to_id: user_id,
      message_id: message_id,
      message: messageText,
    };
    console.log(data);
    mutateNewMessageConvo(data, {
      onSuccess: (res) => {
        console.log(res);
        history.push("/speaker/messages?message_id=" + message_id);
      },
    });
  };
  return (
    <Modal
      visible={previewShow}
      width={600}
      className="modalPreview"
      onCancel={onClose}
      style={{ top: 20 }}
      footer={null}
      closeIcon={<FaTimes className="modal-close-icon dash-close-icon" />}
    >
      {eventData.length != 0 && (
        <div>
          <Row>
            <Col xs={24} sm={24} md={15} lg={15}>
              <img
                src={eventData.event_img}
                className="modalPreviewHeaderRightImg"
              ></img>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9}>
              <div className="modalPreviewHeaderRight modalPreviewHeaderRightDiv ">
                {" "}
                <div className="modalPreviewHeaderRightDay">
                  {" "}
                  {moment(eventData.event_time[0].datetime_start).format(
                    "dddd"
                  )}
                </div>
                <div className="modalPreviewHeaderRightDate">
                  {" "}
                  {moment(eventData.event_time[0].datetime_start).format(
                    "MMMM Do"
                  )}
                </div>
                <br></br>
                <div className="modalPreviewHeaderRightDescription">
                  {eventData.title}
                </div>{" "}
                {eventData.company_of_venue && (
                  <div className="modalPreviewHeaderRightPlace">
                    {" "}
                    {eventData.company_of_venue}
                  </div>
                )}
                <div className="modalPreviewHeaderRightPlace">
                  {eventData.venue_name}
                </div>
                <br></br>
                <div className="modalPreviewHeaderPrice">
                  COST: {eventData.cost != 0 ? "$" + eventData.cost : "FREE"}
                </div>{" "}
                <div style={{ position: "absolute", bottom: 10 }}>
                  <Button
                    tyle="link"
                    // className="btn-primary"
                    className="btn-chat-with chat-with-ep"
                    style={
                      {
                        // background: "#800000 !important",
                        // height: "100%",
                        // width: "100%",
                        // border: "none",
                        // borderBottom: "1px solid #d6ae71",
                      }
                    }
                    icon={
                      <MessageOutlined
                        style={{ marginLeft: "-8px", marginRight: "8px" }}
                      />
                    }
                    onClick={(e) => handleMakeNewConvo(eventData.user_id)}
                  >
                    Chat with Event Provider
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="modalPreviewSocial">
            <Col xs={24} sm={24} md={24} lg={24}>
              <div
                //   style={{ paddingLeft: 15, paddingTop: 6, paddingBottom: 6 }}
                className="modal-share-icon modal-share-icon-others"
              >
                <span
                  className="indiTextShare"
                  // style={{ verticalAlign: "super" }}
                >
                  SHARE:
                </span>

                {/* {eventData.public_status === "Yes" ? (
                  <> */}
                <FacebookShareButton
                  url={
                    process.env.REACT_APP_API_URL +
                    "share-media-content/" +
                    match
                  }
                  quote={eventData.title}
                  // hashtag={"#event"}
                  description={eventData.description}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </FacebookShareButton>
                {/* </>
                ) : (
                  <>
                    <FacebookIcon
                      size={25}
                      style={{ marginBottom: "-5px", marginLeft: 5 }}
                      round
                    />
                  </>
                )} */}

                {/* {eventData.public_status === "Yes" ? (
                  <> */}
                <TwitterShareButton
                  url={
                    process.env.REACT_APP_API_URL +
                    "share-media-content/" +
                    match
                  }
                  title={
                    `Title ${eventData.title} \nDate: ${moment(
                      eventData.date_from
                    ).format("YYYY/MM/DD")} - ${moment(
                      eventData.date_to
                    ).format("YYYY/MM/DD")} \nDescription:${
                      " " + eventData.description
                    }\n`.substring(0, 135) + "..."
                  }
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </TwitterShareButton>
                {/* </>
                ) : (
                  <>
                    <TwitterIcon
                      size={25}
                      style={{ marginBottom: "-5px", marginLeft: 5 }}
                      round
                    />
                  </>
                )} */}

                {/* {eventData.public_status === "Yes" ? (
                  <> */}
                <LinkedinShareButton
                  url={
                    process.env.REACT_APP_API_URL +
                    "share-media-content/" +
                    match
                  }
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </LinkedinShareButton>
                {/* </>
                ) : (
                  <>
                    <LinkedinIcon
                      size={25}
                      style={{ marginBottom: "-5px", marginLeft: 5 }}
                      round
                    />
                  </>
                )} */}

                {/* {eventData.public_status === "Yes" ? (
                  <> */}
                <EmailShareButton
                  url={
                    process.env.REACT_APP_API_URL +
                    "share-media-content/" +
                    match
                  }
                  subject={eventData.title}
                  body={
                    eventData.course_topic +
                    "\n" +
                    "When: " +
                    moment(eventData.date_from).format("DD MMM, YYYY") +
                    "-" +
                    moment(eventData.date_to).format("DD MMM, YYYY") +
                    "\n" +
                    "Description: " +
                    eventData.description +
                    "\n"
                  }
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </EmailShareButton>
                {/* </>
                ) : (
                  <>
                    <EmailIcon
                      size={25}
                      style={{ marginBottom: "-5px", marginLeft: 5 }}
                      round
                    />
                  </>
                )} */}
              </div>
            </Col>
          </Row>
          <Row
            className="scrollbar-2"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            <Col xs={24} sm={24} md={15} lg={15}>
              <div className="modalPreviewDescription modalLineheight">
                {eventData.description && (
                  <>
                    <div style={{ textTransform: "uppercase" }}>
                      <b>description</b>
                    </div>
                    {eventData.description}
                    <br />
                    <br />
                  </>
                )}

                {eventData.restriction && (
                  <>
                    <div style={{ textTransform: "uppercase" }}>
                      <b>restriction</b>
                    </div>
                    {eventData.restriction}

                    <br />
                    <br />
                  </>
                )}

                {eventData.what_to_bring && (
                  <>
                    <div style={{ textTransform: "uppercase" }}>
                      <b>what to bring</b>
                    </div>
                    {eventData.what_to_bring}
                  </>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9}>
              <div className="modalPreviewDescriptionRight modalLineheight">
                {" "}
                <div style={{ textTransform: "uppercase" }}>
                  <b>DATE & TIME</b>
                </div>
                {eventData.event_time.map((item, key) => {
                  return (
                    <div key={key}>
                      {moment(item.datetime_start).format("MMMM Do h:mma") +
                        " - " +
                        moment(item.datetime_end).format("h:mma")}
                    </div>
                  );
                })}
                <br></br>
                <div style={{ textTransform: "uppercase" }}>
                  <b>course topic</b>
                </div>
                <div> {eventData.course_topic}</div>
                <br></br>
                <div style={{ textTransform: "uppercase" }}>
                  <b>CONTACT INFO</b>
                </div>
                <div className="contactInfoWrap">
                  <div style={{ display: "flex" }}>
                    {eventData.event_type === "Live In-Person" && (
                      <div>
                        <span className="iconDefualColor">
                          <FaMapMarkerAlt></FaMapMarkerAlt>
                        </span>{" "}
                      </div>
                    )}
                    <div>
                      {eventData.event_type === "Live In-Person" && (
                        <div className="addressWrap">
                          {eventData.venue_name != "" ? (
                            <>
                              {eventData.company_of_venue && (
                                <div> {eventData.company_of_venue}</div>
                              )}

                              <div>{eventData.venue_name}</div>
                              <div>{eventData.address_1}</div>
                              <div>
                                {eventData.city}, {eventData.state},{" "}
                                {eventData.zip}
                              </div>
                              <div>{eventData.country}</div>
                            </>
                          ) : (
                            <div>{eventData.city}</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <a className="venueLink" href={"tel:" + eventData.your_phone}>
                    <span className="iconDefualColor">
                      <FaMobileAlt />
                    </span>{" "}
                    <span>{eventData.your_phone}</span>
                  </a>
                  <div>
                    <a
                      className="venueLink"
                      href={"mailto:" + eventData.your_email}
                    >
                      <span className="iconDefualColor">
                        <FaEnvelope />
                      </span>{" "}
                      <span> Email Me </span>
                    </a>
                  </div>
                </div>
                <br></br>
                <div style={{ textTransform: "uppercase" }}>
                  <b>SPEAKER</b>
                </div>
                {datGetById.data &&
                  datGetById.data[0].event_speaker.map((row, key) => {
                    return (
                      <div key={key}>
                        <div>{row.speaker_name}</div>
                        <div>{row.speaker_credentials}</div>
                      </div>
                    );
                  })}
                <br></br>
                <div style={{ textTransform: "uppercase" }}>
                  <b>Credit Hours</b>
                </div>
                <div>{eventData.ce_credit_hours} hrs</div>
                <br></br>
                <div style={{ textTransform: "uppercase" }}>
                  <b>seats available</b>
                </div>
                <div>
                  {eventData.seat_available - eventData.event_attendee_count >=
                  0
                    ? eventData.seat_available - eventData.event_attendee_count
                    : 0}
                </div>
                <br></br>
                <div style={{ textTransform: "uppercase" }}>
                  <b>Event Categories </b>
                </div>
                <div>
                  {
                    eventData.attending_participants
                      .map((item, key) => {
                        return item.category;
                      })
                      .join(", ")
                    // .slice(0, -1)
                  }
                  {console.log(
                    "attending_participants",
                    eventData.attending_participants
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
