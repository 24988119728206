import { Result, Button } from 'antd';
import React from "react";
import { useHistory } from "react-router-dom";
const Success = () => {
    let history = useHistory();
    return (
        <Result
            status="success"
            title="Successfully Register!"
            subTitle="Fugiat in ipsum enim ut consequat aute fugiat aliquip. Est irure irure ad quis aliquip eu aliqua incididunt sunt magna non excepteur. Ea irure cillum veniam commodo duis deserunt ipsum non id aute in."
            extra={<Button type="primary" onClick={e => history.push("/")}>Back to LOGIN</Button>}
        />
    )
}

export default Success;