import React, { useState } from "react";
import { Menu, Dropdown, Modal, Typography } from "antd";
import { CheckOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";

import { POST } from "../../../../providers/useAxiosQuery";
import { Link } from "react-router-dom";

const NotificationsAlert = ({ notification, refetch }) => {
	const [modal, setmodal] = useState(false);
	const [modaldata, setmodaldata] = useState("");
	const [modaltitle, setmodaltitle] = useState("");
	const [modaldescription, setmodaldescription] = useState("");
	const handleView = (item) => {
		setmodal(true);
		setmodaldata(item.id);
		setmodaltitle(item.notification.title);
		setmodaldescription(item.notification.description);
	};

	const handleCancel = () => {
		setmodal(false);
		// console.log("asd");
		mutateRead(
			{ id: modaldata, read: 1 },
			{
				onSuccess: (res) => {
					refetch();
				},
			}
		);
	};

	const { mutate: mutateRead } = POST("api/v1/read", "get_notification_alert");

	const { mutate: mutateArchive } = POST(
		"api/v1/archive",
		"get_notification_alert"
	);

	const handleRead = (item, status) => {
		console.log("handleRead", item);
		mutateRead(
			{ id: item.id, read: status === "read" ? 1 : 0 },
			{
				onSuccess: (res) => {
					refetch();
				},
			}
		);
	};
	const handleRemove = (item) => {
		console.log("handleRemove", item);
		mutateArchive(
			{ id: item.id },
			{
				onSuccess: (res) => {
					refetch();
				},
			}
		);
	};

	const menuActions = (item) => {
		return (
			<Menu>
				{item.read === 0 ? (
					<Menu.Item key="#mark-as-read" icon={<CheckOutlined />}>
						<Link to="#" onClick={() => handleRead(item, "read")}>
							Mark as read
						</Link>
					</Menu.Item>
				) : null}

				{item.read === 1 ? (
					<Menu.Item
						key="#mark-as-unread"
						icon={<CheckOutlined />}
						onClick={() => handleRead(item, "unread")}
					>
						<Link to="#">Mark as unread</Link>
					</Menu.Item>
				) : null}

				<Menu.Item
					key="#remove-notification"
					icon={<CloseSquareOutlined />}
					onClick={() => handleRemove(item)}
				>
					<Link to="#">Remove this notification</Link>
				</Menu.Item>
			</Menu>
		);
	};

	const notificationList = () => {
		if (notification && notification.length > 0) {
			return notification.map((item, index) => {
				return (
					<Menu.Item key={index}>
						<Link
							to="#"
							style={{ paddingTop: "16px" }}
							onClick={() => handleView(item)}
						>
							<Typography.Text strong>
								{item.notification.title}
							</Typography.Text>
							<Typography.Paragraph ellipsis={{ rows: 2 }}>
								{item.notification.description}
							</Typography.Paragraph>
						</Link>

						{/* <span className="ant-dropdown-container">
							<Dropdown
								overlay={(e) => menuActions(item)}
								// placement="bottomRight"
								overlayClassName="ant-menu-submenu-notification-action"
								arrow
							>
								<HiOutlineDotsCircleHorizontal />
							</Dropdown>
						</span>

						{item.read === 0 ? (
							<span className="ant-status-container">
								<GoPrimitiveDot />
							</span>
						) : null} */}
					</Menu.Item>
				);
			});
		} else {
			return (
				<Menu.Item key="#" className="text-center ant-notification">
					<Link
						to="#"
						style={{
							paddingTop: "10px",
							paddingBottom: "10px",
						}}
					>
						No notification
					</Link>
				</Menu.Item>
			);
		}
	};

	return (
		<>
			<Menu>
				<Menu.Item key="#notification" className="text-center title">
					<Link to="#" style={{ color: "#293a56" }}>
						Notifications
					</Link>
				</Menu.Item>
				<Menu.Divider
					key="#notificationdivider"
					className="dividerMenuDropdown"
				/>
				{notificationList()}
			</Menu>

			<Modal
				className="modal-login"
				title={modaltitle}
				visible={modal}
				footer={false}
				onCancel={handleCancel}
			>
				<span>{modaldescription}</span>
			</Modal>
		</>
	);
};

export default NotificationsAlert;
