import React, { useEffect, useState } from "react";

import {
	Layout,
	Card,
	Form,
	Input,
	Button,
	Row,
	Col,
	Image,
	Divider,
	Collapse,
	Select,
	notification,
} from "antd";

import $ from "jquery";
import FloatSelectRegistration from "../../../../providers/FloatSelectRegistration";
import FloatInput from "../../../../providers/FloatInput";
import { POST } from "../../../../providers/useAxiosQuery";

import { LoadingOutlined } from "@ant-design/icons";

const ComponentStepThree = ({
	setShowStepFour,
	setDataStepThree,
	setCollapse,
	memberPlan,
	accountType,
}) => {
	const [form] = Form.useForm();

	const onFinishStepThree = (values) => {
		console.log(values);
		setDataStepThree({
			...values,
			coupon_status: couponError,
			discountTotal: discountTotal > 0 ? discountTotal : 0,
			typeCoupon: typeCoupon,
			couponOff: couponOff,
		});
		setShowStepFour(true);
		setCollapse(["4"]);
	};

	const [coupon, setCoupon] = useState(false);
	const [program, setProgram] = useState(0);
	const [program1, setProgram1] = useState(0);

	const handlerMemberProgram = (value, options) => {
		setCoupon(true);
		let amount = parseFloat(options["data-price"]);
		setProgram(amount);
		setProgram1(parseFloat(options["data-price"]));
		const _memberPlan = memberPlan.find((el) => el.value == value);
		setProgramType(_memberPlan["type"]);

		form.setFieldsValue({
			price: amount,
		});

		// setProgram
		// const price = e.target.getAttribute('data-price');
		// let selectedIndex = e.target.selectedOptions[0].dataset.index];
		// var uid = obj.options[obj.selectedIndex].getAttribute('data');

		// console.log('price', event.target[event.target.selectedIndex].getAttribute('data-price'))
		// console.log('index', index)

		// console.log('price', event.currentTarget.getAttribute('data-price').value)
	};

	const formatMoney = (val) => {
		return (Math.round(val * 100) / 100).toLocaleString();
	};

	const { mutate: mutateApplyCoupon, isLoading: isLoadingApplyCoupon } = POST(
		"api/v1/apply_coupon_stripe_system",
		"apply_coupon_stripe_system"
	);

	const [couponError, setCouponError] = useState(false);
	const [couponDiscount, setCouponDiscount] = useState(0);
	const [couponDiscountTotal, setCouponDiscountTotal] = useState(0);
	const [couponDiscountTotal1, setCouponDiscountTotal1] = useState(0);
	const [couponFixedDiscount, setCouponFixedDiscount] = useState(0);
	const [isFixed, setIsFixed] = useState(false);

	const [typeCoupon, setTypeCoupon] = useState("");
	const [couponOff, setCouponOff] = useState(0);
	const [discountTotal, setDiscountTotal] = useState(0);
	const [programType, setProgramType] = useState("");

	const handleApplyCoupon = (e) => {
		var a = form.getFieldValue("coupon");
		// console.log("coupon", a);
		if (a) {
			mutateApplyCoupon(
				{ code: a, role: accountType },
				{
					onSuccess: (res) => {
						console.log("coupon_res ", res);

						if (res.success) {
							if (res.data) {
								if (parseInt(res.data.max) > parseInt(res.data.redemption)) {
									if (res.data.type == "fixed") {
										setCouponError(true);
										setTypeCoupon("fixed");
										setCouponOff(res.data.off);
										setDiscountTotal(program - res.data.off);
									}
									if (res.data.type == "percent") {
										setCouponError(true);
										setTypeCoupon("percent");
										setCouponOff(res.data.off);

										var percentage = res.data.off / 100;
										setDiscountTotal(program - program * percentage);
									}
									if (res.data.type == "offer") {
										console.log(res.data.off);
										setCouponError(true);
										setTypeCoupon("offer");
										setCouponOff(res.data.off);
										setDiscountTotal(program);
										// var days_to_divide = programType == "Monthly" ? 30 : 365;
										// var program_total_perday = program / days_to_divide;
										// var off_x_perday = res.data.off * program_total_perday;

										// var total = program - off_x_perday;

										// console.log(days_to_divide);

										// if (total >= 0) {
										//   setCouponError(true);
										//   setTypeCoupon("offer");
										//   setCouponOff(res.data.off);
										//   setDiscountTotal(total);
										// } else {
										//   setCouponError(false);
										//   notification.error({
										//     message: "Coupon Denied",
										//     description:
										//       "Can't Applied Coupon to this Subscription",
										//   });
										// }
									}
								} else {
									notification.error({
										message: "Coupon Denied",
										description: "Coupon usage limit has been reached",
									});
									setCouponError(false);
								}
							}
						} else {
							notification.error({
								message: "Coupon Denied",
								description: "No Such Coupon " + a,
							});
							setCouponError(false);
						}
					},
					onError: (err) => {
						console.log(err.response.data);
						notification.error({
							message: "Coupon Denied",
							description: err.response.data.message,
						});
						setCouponError(false);
					},
				}
			);
		} else {
			setCouponError(false);
		}
	};

	return (
		<Form
			name="form-member"
			layout="vertical"
			className="login-form"
			// style={{
			//     marginTop: '-50px'
			// }}
			onFinish={onFinishStepThree}
			form={form}
			autoComplete="off"
			//   onValuesChange={(a, b) => console.log("onFinishStepThree", b)}
		>
			<Row gutter={24}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
					{/* {accountType == "MEMBER" && (
            <>
              <div style={{ marginTop: 10 }}>
                <b>GROUP 1:</b> Dental Assistants, Front Office Staff and
                Students ($9.95/month)
              </div>
              <div>
                <b>GROUP 2:</b> Dental Hygienists, Retired Dentists and Dental
                Lab Techs ($12.95/month)
              </div>
              <div>
                <b>GROUP 3:</b> Dentists ($24.95/month)
              </div>
            </>
          )} */}
					{/* <br /> */}
					<div style={{ marginBottom: 10 }}></div>
					<Form.Item
						name="program_plan"
						hasFeedback
						className="form-select-error"
						rules={[
							{
								required: true,
								message: "This field field is required.",
							},
						]}
					>
						<FloatSelectRegistration
							label="Select Program"
							placeholder="Select Program"
							options={memberPlan}
							onChange={handlerMemberProgram}
							listHeight={600}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
					{coupon && (
						<div>
							<Form.Item
								name="coupon"
								rules={[
									{
										required: false,
										message: "This field field is required.",
									},
								]}
							>
								<FloatInput
									label="Coupon"
									placeholder="Coupon"
									addonAfter={
										<Button
											className="btn-loginNew-outline"
											disabled={isLoadingApplyCoupon}
											style={{
												height: "46px",
												marginTop: "-1px",
												borderRadius: "0px",
											}}
											onClick={(e) => handleApplyCoupon(e)}
										>
											{isLoadingApplyCoupon ? <LoadingOutlined /> : ""} APPLY
										</Button>
									}
								/>
							</Form.Item>
							{couponError && (
								<span
									style={{
										color: "#23BF08",
										marginTop: "-23px",
										position: "absolute",
									}}
								>
									Code Successfully applied (
									{typeCoupon == "fixed" && "$" + couponOff + " off"}
									{typeCoupon == "percent" && "" + couponOff + "% off"}
									{typeCoupon == "offer" && "" + couponOff + " days free"})
								</span>
							)}
							<Form.Item name="price">
								<Input
									style={{
										display: "none",
									}}
									size="large"
								/>
							</Form.Item>

							{!couponError && (
								<h3 style={{ marginTop: "-53px" }}> Total: ${program}</h3>
							)}
							{couponError && (
								<>
									{typeCoupon == "fixed" && (
										<>
											<h4 style={{ marginTop: "-53px" }}>
												{" "}
												Subtotal: ${program}
											</h4>
											<h4 style={{ marginTop: "-10px" }}>
												Discount: -${`${couponOff}`}
											</h4>
											<h3
												style={{
													marginTop: "-10px",
													fontWeight: "bold",
												}}
											>
												Total: $
												{`${
													discountTotal > 0 ? discountTotal.toFixed(2) : 0.0
												}`}
											</h3>
										</>
									)}
									{typeCoupon == "percent" && (
										<>
											<h4 style={{ marginTop: "-53px" }}>
												{" "}
												Subtotal: ${program}
											</h4>
											<h4 style={{ marginTop: "-10px" }}>
												Discount: {`${couponOff}`}%
											</h4>
											<h3
												style={{
													marginTop: "-10px",
													fontWeight: "bold",
												}}
											>
												Total: $
												{`${
													discountTotal > 0 ? discountTotal.toFixed(2) : 0.0
												}`}
											</h3>
										</>
									)}

									{typeCoupon == "offer" && (
										<>
											<h4 style={{ marginTop: "-53px" }}>
												{" "}
												Subtotal: ${program}
											</h4>
											<h4 style={{ marginTop: "-10px" }}>
												Discount: {`${couponOff}`} days free
											</h4>
											<h3
												style={{
													marginTop: "-10px",
													fontWeight: "bold",
												}}
											>
												Total: $
												{`${
													discountTotal > 0 ? discountTotal.toFixed(2) : 0.0
												}`}
											</h3>
										</>
									)}
								</>
							)}
							<Button
								type="primary"
								htmlType="submit"
								className="btn-loginNew-outline"
								style={{
									width: "100%",
									marginTop: 10,
									fontSize: "20px",
									height: "45px",
								}}
							>
								CONTINUE
							</Button>
						</div>
					)}
				</Col>
			</Row>
		</Form>
	);
};

export default ComponentStepThree;
