export default function getUserData() {
	let userdata = localStorage.getItem("userdata");

	if (userdata === null || userdata === "") {
		localStorage.removeItem("userdata");
		localStorage.removeItem("token");
		return false;
	}
	return JSON.parse(localStorage.userdata);
}
